import I18n from 'app/i18n';
import {betMoney} from 'app/utils';
import React, {ReactElement, useCallback} from 'react';
import {isPlayMoney} from './bet_maker';

interface AvailableAmountInterface {
    setAmount(limit: number, e: React.MouseEvent<HTMLAnchorElement>): void
    limit: number
    amount: number
    multiple_bet_limit_enabled: boolean
    multiple_bet_limit: number
    amount_bet_type: string
}

const AvailableAmount = (props: AvailableAmountInterface): ReactElement => {
    const {limit, amount, multiple_bet_limit_enabled, multiple_bet_limit, setAmount} = props;

    const handleSetLimit = useCallback(
        e => setAmount(limit, e),
        [setAmount, limit]
    );
    return <div className="match-details__item-form-row">
        <span>
            {I18n.t('bet_you_can_bet_more_label')}:{' '}
            <a href="#" className="match-details__bet"
                onClick={handleSetLimit}>
                {betMoney({
                    amount: limit - amount,
                    playmoney: isPlayMoney(props)
                })}
                {multiple_bet_limit_enabled
                    ? `(${betMoney({
                        amount: multiple_bet_limit - amount,
                        playmoney: isPlayMoney(props)
                    })})`
                    : null}
            </a>
        </span>
    </div>;
};

export default AvailableAmount;
