import React, {PureComponent, ReactNode} from 'react';
import {Link} from 'react-router-dom';

interface LogoLinkAsideProps {
    className: string
    link: string
    logo: string
    logoAlt: string
}

export default class LogoLinkAside extends PureComponent<LogoLinkAsideProps> {
    render(): ReactNode {
        const {className, link, logo, logoAlt} = this.props;
        return <div className={`logo-aside ${className}`}>
            <Link to={link} target="_blank">
                <img className="svg-default" src={logo} alt={logoAlt}/>
            </Link>
        </div>;
    }
}
