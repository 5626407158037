import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';

interface PropsInterface {
    children?: ReactNode | ReactNode[]
    itemClassName: string
    numberPoint: string
    titlePoint: string
    descriptionPoint: string
}

export default class PointDestination extends PureComponent<PropsInterface> {
    render(): React.ReactNode {
        const {itemClassName, numberPoint, titlePoint, descriptionPoint, children} = this.props;
        return <div className={`point-destination ${itemClassName}`}>
            <div className="inner">
                <span className="number">{numberPoint}</span>
                <span className="title-name">{I18n.t(titlePoint)}</span>
                <div className="text-description">
                    <p>{I18n.t(descriptionPoint)}</p>
                </div>
                {children}
            </div>
        </div>;
    }
}
