import React, {Component} from 'react';
import I18n from 'app/i18n';
import {getLinkPath, scrollByHash} from 'app/utils';
import ShopItems from 'app/components/shop/lootboxes/shop_items';
import wretch from 'app/utils/wretch';
import {CURRENCY_USD, POINTS} from 'app/utils/ShopItemPriceFormat';
import Bonus from './Bonus';
import {inject, observer} from 'mobx-react';

@inject('userStore', 'userBonusesStore')
@observer
class Bonuses extends Component {
    state = {bonus_lootboxes: []};

    componentDidMount() {
        scrollByHash();
        if (this.props.bonuses.length === 0) {
            wretch
                .url(getLinkPath('/shop/bonus_lootboxes'))
                .get()
                .json(data => this.init(data));
        }
    }

    init = data => {
        const bonus_lootboxes = [];
        data.forEach(item => {
            if (Boolean(Number(item.cost_money)) && Boolean(Number(item.cost_points))) {
                bonus_lootboxes.push({...item,
                    currency: CURRENCY_USD});
            }
            item.currency = POINTS;
            bonus_lootboxes.push(item);
        });
        this.setState({bonus_lootboxes});
    };

    render() {
        const {user} = this.props.userStore;
        const {bonuses} = this.props;

        return <div id="bonuses" className="bonuses">
            {bonuses && <ol className="bonuses__list">
                { bonuses.length > 0
                    ? bonuses.map(bonus => <Bonus key={bonus.id}
                        bonus={bonus} userStore={this.props.userStore}
                        userBonusesStore={this.props.userBonusesStore}/>)
                    : <div className="bonuses__list-empty">
                        <div className="smile">:(</div>
                        <div className="bonuses__list-empty__text">
                            <div className="subtitle">
                                {I18n.t('no_bonuses')}
                            </div>
                        </div>
                    </div>}
            </ol>}
            <div className="market__lootboxes">
                <ShopItems lootboxes={this.state.bonus_lootboxes} user={user} noChunks hiddenEmpty/>
            </div>
        </div>;
    }
}

export default Bonuses;
