import React, {PureComponent, ReactNode} from 'react';
import SuggestionInterface from 'app/interfaces/SuggestionInterface';
import {fixed3} from 'app/utils/fixed';
import {betMoney} from 'app/utils';

interface BetSlipSuggestionsCoefficientProps {
    suggestion: SuggestionInterface
    cancel: (ids: number[]) => void
}
export default class BetSlipSuggestionsCoefficient extends PureComponent<BetSlipSuggestionsCoefficientProps> {
    cancelSuggestion = (e: React.MouseEvent): void => {
        e && e.preventDefault();
        const {suggestion: {id}, cancel} = this.props;
        cancel([id]);
    };

    render(): ReactNode {
        const {suggestion} = this.props;
        return <div className="bet-slip-suggestion-coefficient-item">
            <span className="bet-slip-suggestion-profit">{betMoney({amount: suggestion.amount})}</span>
            <span className="bet-slip-suggestion-coefficient">{fixed3(suggestion.coef)}</span>
            <button className="button-bet-slip-remove" onClick={this.cancelSuggestion}>
                <svg className="svg-default">
                    <use xlinkHref="#svg-bet-slip-remove"/>
                </svg>
            </button>
        </div>;
    }
}
