import {action, computed, makeObservable, observable} from 'mobx';
import {getAllFiltersFlag} from 'app/utils';
import Cookies from 'js-cookie';
import GameInterface from 'app/interfaces/GameInterface';
import CollectionInterface from 'app/interfaces/CollectionInterface';
import {DEFAULT_EVENTS, DISCIPLINES} from 'app/store';
import {COOKIES_EXPIRATION_TIME} from 'app/store/constants/storeConstants';
import {prepareFilters} from 'app/lines/prepareFilters';
import EventInterface from 'app/interfaces/EventInterface';
import {sortByFilters} from 'app/utils/SortByFilters';
import {getQueryBetType} from 'app/utils/getQueryBetType';
import {prepareFiltersQuery} from 'app/utils/prepareFiltersQuery';
import {navigateGoTo} from 'app/utils/navigate/navigateGoTo';
import getCookies from 'app/utils/getCookies';

export interface FilterUpdateInterface {
    discipline?: string
    betType?: string
    currentEvents?: EventInterface[]
    events?: EventInterface[]
    filters?: GameInterface[]
    showAllFilters?: boolean
    only24h?: boolean
    onlyUpcomingMatches?: boolean
}


export class FilterStore {
    constructor(filterType: string, games: GameInterface[]) {
        this.filterType = filterType;
        this.games = games;
        this.showAllFilters = getAllFiltersFlag(this.games);
        this.filtersWithSport = this.games;
        this.filters = games.map(game => ({...game}));
        this.setProperties();
        this.updateFiltersByQuery();
        makeObservable(this);
    }

    @observable games: GameInterface[] = [];

    @observable filters: GameInterface[] = [];

    filterType = '';

    @observable betType = '';

    @observable discipline: string = null;

    @observable disciplines: CollectionInterface[] = DISCIPLINES;

    @observable showAllFilters: boolean = false;

    @observable filtersWithSport: GameInterface[] = [];

    @observable showMoreFilter = false;

    @observable events = null;

    @observable currentEvents = null;

    @observable only24h: boolean = false;

    @observable onlyUpcomingMatches: boolean = false;

    @observable filtersFromQuery: string = null;

    @observable appliedFilters: number[] = null;

    // eslint-disable-next-line max-statements
    setProperties(): void {
        this.discipline = getQueryBetType(this.filterType).discipline || getCookies(`${this.filterType}Disc`, 'all');

        this.betType = getQueryBetType(this.filterType).betType || getCookies(`${this.filterType}Type`, 'all');

        this.only24h = getQueryBetType(this.filterType).only24h
            ? getQueryBetType(this.filterType).only24h === 'true'
            : getCookies(`${this.filterType}24h`, false);

        this.onlyUpcomingMatches = getQueryBetType(this.filterType).onlyUpcomingMatches
            ? getQueryBetType(this.filterType).onlyUpcomingMatches === 'true'
            : Boolean(getCookies(`${this.filterType}Up`, false));

        this.events = DEFAULT_EVENTS;

        this.currentEvents = getQueryBetType(this.filterType).events || getCookies(`${this.filterType}CurrentEvents`, DEFAULT_EVENTS);

        this.appliedFilters = getCookies(`${this.filterType}Filters`, []);
        if (!this.filtersFromQuery) {
            this.applyFilters();
        }
    }

    @action update(data: FilterUpdateInterface): void {
        Object.keys(data).forEach(key => {
            if (typeof this[key] !== 'undefined' && typeof data[key] !== 'undefined') {
                this[key] = data[key];
            }
        });
    }

    @computed
    get queryNavigateParams(): Record<string, string> {
        const filters = prepareFiltersQuery(this.filters);

        return {betType: this.betType,
            discipline: this.discipline,
            events: this.currentEvents,
            filtersWithSport: filters,
            only24h: String(this.only24h),
            onlyUpcomingMatches: String(this.onlyUpcomingMatches)};
    }

    updateNavigateUrl = (): void => {
        navigateGoTo(window.location.pathname, this.queryNavigateParams, true);
    };

    getDisciplines = (): CollectionInterface[] => this.disciplines;

    setCurrentDiscipline = (page: string, discipline: string): void => {
        let data = discipline;
        if (data.trim().length === 0) {
            data = 'all';
        }
        this.update({discipline: data});
        this.clearFiltersByDiscipline();
        Cookies.set(`${page}Disc`, data, {expires: COOKIES_EXPIRATION_TIME});
        this.updateNavigateUrl();
    };


    @action
    clearFiltersByDiscipline = (): void => {
        if (this.discipline === 'esport') {
            const sportFilters = this.filters.filter(f => f.esports === false);
            this.changeFiltersVal(sportFilters);
        }
        if (this.discipline === 'sport') {
            const esportFilters = this.filters.filter(f => f.esports === true);
            this.changeFiltersVal(esportFilters);
        }
    };

    @action
    changeFiltersVal = (arr: GameInterface[]): void => {
        arr.forEach(f => {
            f.val = false;
        });
    };


    setCurrentBetType = (page: string, betType: string): void => {
        let data = betType;
        if (data.trim().length === 0) {
            data = 'all';
        }
        this.update({betType: data});

        Cookies.set(`${page}Type`, data, {expires: COOKIES_EXPIRATION_TIME});
        this.updateNavigateUrl();
    };


    setCurrentEvent = (currentEvents: EventInterface[]): void => {
        this.update({currentEvents});
        Cookies.set(`${this.filterType}CurrentEvents`, currentEvents, {expires: COOKIES_EXPIRATION_TIME});

        this.updateNavigateUrl();
    };

    updateFilter = (filters: GameInterface[], newStates = {}): void => {
        Cookies.set(`${this.filterType}Filters`, prepareFilters(filters), {expires: COOKIES_EXPIRATION_TIME});

        this.update({
            ...newStates,
            filters,
            showAllFilters: getAllFiltersFlag(filters)
        });

        this.updateNavigateUrl();
    };

    updateFiltersByQuery = (): void => {
        if (!this.filtersFromQuery) {
            return;
        }

        const filters = JSON.parse(this.filtersFromQuery);
        filters.forEach(record => {
            const filter = this.filters.find(f => f.slug === record);

            if (!filter) {
                return;
            }
            filter.val = true;
        });
    };

    getFilters = (): GameInterface[] => sortByFilters(this.filters);

    currentFilters = (): GameInterface[] => this.filters;

    getOnly24h = (): boolean => this.only24h;

    setOnly24h = (checked: boolean, page: string) : void => {
        this.update({only24h: checked});
        Cookies.set(`${page}24h`, checked, {expires: COOKIES_EXPIRATION_TIME});
        this.updateNavigateUrl();
    };

    getOnlyUpcomingMatches = (): boolean => this.onlyUpcomingMatches;

    setOnlyUpcomingMatches = (checked: boolean, page: string) : void => {
        this.update({onlyUpcomingMatches: checked});
        Cookies.set(`${page}Up`, checked, {expires: COOKIES_EXPIRATION_TIME});
        this.updateNavigateUrl();
    };

    getEvents = (): string[] => this.events;

    getCurrentEvents = (): string[] => this.currentEvents;

    resetSettings = (): void => {
        this.setCurrentDiscipline(`${this.filterType}`, 'all');
        this.setCurrentBetType(`${this.filterType}`, 'all');
        this.setOnly24h(false, `${this.filterType}`);
        this.setOnlyUpcomingMatches(false, `${this.filterType}`);
        Cookies.set(`${this.filterType}CurrentEvents`, DEFAULT_EVENTS);
        Cookies.set(`${this.filterType}Filters`, DEFAULT_EVENTS);
        this.update({currentEvents: DEFAULT_EVENTS, events: DEFAULT_EVENTS});
        this.updateNavigateUrl();
    };

    clearFilters = (): void => {
        this.resetSettings();
    };

    @action
    applyFilters = (): void => {
        if (Array.isArray(this.appliedFilters)) {
            this.appliedFilters.forEach(record => {
                const filter = this.filters.find(f => f.id === record);

                if (!filter) {
                    return;
                }
                filter.val = true;
            });
        }
    };

    updateCurrentEvents(currentEvents: EventInterface[], newFilters: GameInterface[]): void {
        if (currentEvents) {
            const newCurrentEvents = [];
            currentEvents.forEach(currentEvent => {
                const f = newFilters.find(newFilter => newFilter.id === currentEvent.filter);

                if (f && f.val) {
                    newCurrentEvents.push(currentEvent.tourn);
                }
            });
            this.setCurrentEvent(newCurrentEvents);
        }
    }

    updateFilters(currentEvents: EventInterface[], filters: GameInterface[]): void {
        this.updateCurrentEvents(currentEvents, filters);
        this.updateFilter(filters, {});
    }
}
