import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {linkMarketplace} from 'app/utils/app_links';
import ListArtifactEffectsButton from './ListArtifactEffectsButton';

interface GoMarketplaceProps {
    onClick: () => void
    boxClassName?: string
}

export default class GoMarketplace extends PureComponent<GoMarketplaceProps> {
    render(): React.ReactNode {
        const {boxClassName} = this.props;
        return <div className={`artifacts-go-marketplace ${boxClassName ? boxClassName : ''}`}>
            <div className="description">
                <span className="heading">{I18n.t('shop.no_items_text_1')}</span>
                <div className="text">
                    <p>{I18n.t('shop.no_items_text_2')}</p>
                </div>
            </div>
            <div className="button">
                <ListArtifactEffectsButton
                    linkMyHero={linkMarketplace}
                    onClick={this.props.onClick}
                    text={I18n.t('shop.button_go_marketplace')}/>
            </div>
        </div>;
    }
}
