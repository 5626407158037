import React from 'react';
import I18n from 'app/i18n';
import ChangedProfileFields from 'app/components/auth/ChangedProfileFields';
import {changeNick} from './user_reset_popup';
import {inject, observer} from 'mobx-react';
import {UserStore} from 'app/store/UserStore';
import ChangePassword from 'app/components/my_profile/change_password';

interface Props {
    userStore?: UserStore
}

@inject('userStore')
@observer
export default class UserInformation extends React.Component<Props> {
    render(): React.ReactNode {
        const {name, user_id} = this.props.userStore.user;
        return <>
            <div className="profile__info-user-wrap">
                <dl className="profile__info-user">
                    <dt>{I18n.t('nickname')}</dt>
                    <dd>{name} (#{user_id})</dd>
                </dl>
                <div className="profile__btn">
                    <button className="btn" onClick={changeNick}>{I18n.t('change')}</button>
                </div>
            </div>
            <div className="profile__user">
                <ChangePassword/>
            </div>
            <ChangedProfileFields {...this.props.userStore.user}/>
        </>;
    }
}
