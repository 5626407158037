import React, {Component, MouseEvent, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import {ArtifactBetStore} from 'app/store/ArtifactBetStore';
import ArtifactSlot from 'app/components/bet_form/artifact/ArtifactSlot';
import I18n from 'app/i18n';
import {goToLinkMarketplace} from 'app/utils/app_links';
import InventoryItemInterface from 'app/interfaces/InventoryItemInterface';
import {toastError} from 'app/components/toasts/liteToast';
import {scrollToTop} from 'app/utils';
import PlaceBetButton from 'app/components/bet_form/PlaceBetButton';
import {hideLastPopup} from 'app/utils/modals/ModalsService';

interface ArtifactBetFormProps {
    handlePlaceBet: (e: MouseEvent) => void
    resetCoefChanged: () => void
    artifactBetStore?: ArtifactBetStore
    coef: number
    loading: boolean
    coefChanged: boolean
}

@inject('artifactBetStore')
@observer
export default class ArtifactBetForm extends Component<ArtifactBetFormProps> {
    placeBet = (e: MouseEvent): void => this.props.handlePlaceBet(e);

    resetCoefChanged = (): void => this.props.resetCoefChanged();

    goToMarketplace = (e: MouseEvent): void => {
        e.preventDefault();
        hideLastPopup();
        scrollToTop();
        goToLinkMarketplace();
    };

    addArtifact = (artifact: InventoryItemInterface): void => {
        if (this.props.artifactBetStore.addArtifact(artifact)) {
            this.resetCoefChanged();
        } else {
            toastError(I18n.t('error_message_limitation_artifact'));
        }
    };

    render(): ReactNode {
        const {removeArtifact, artifactsToBet, availableToBetArtifacts} = this.props.artifactBetStore;
        const {coef, loading, coefChanged} = this.props;
        return <>
            <div className="match-details__skins-bets">
                {availableToBetArtifacts.length === 0 && artifactsToBet.length === 0 && <div className="message-text">
                    <span className="heading">{I18n.t('shop.no_items_text_1')}</span>
                    <div className="text">
                        <p>{I18n.t('shop.no_items_text_3')} <a href="#" onClick={this.goToMarketplace}>
                            {I18n.t('marketplace').toLowerCase()}
                        </a></p>
                    </div>
                </div>}
                {availableToBetArtifacts.length > 0 && <div className="match-details__skins">
                    <span className="match-details__skins-title">
                        {I18n.t('your_inventory')}:
                    </span>
                    <div className="hero__slot-wrap">
                        {availableToBetArtifacts.map(a => <ArtifactSlot
                            artifact={a}
                            key={a.id}
                            onClick={this.addArtifact}
                        />)}
                    </div>
                </div>}
                {artifactsToBet.length > 0 && <div className="match-details__skins">
                    <span className="match-details__skins-title">
                        {I18n.t('your_bet_label')}:
                    </span>
                    <div className="hero__slot-wrap">
                        {artifactsToBet.map(a => <ArtifactSlot
                            artifact={a}
                            key={a.id}
                            onClick={removeArtifact}
                        />)}
                    </div>
                </div>}

            </div>
            <PlaceBetButton
                coef={coef}
                disabled={loading}
                handlePlaceBet={this.placeBet}
                coefChanged={coefChanged}
                countArtifacts={artifactsToBet.length}
            />
        </>;
    }
}
