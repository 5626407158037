import React, {PureComponent} from 'react';

export default class InventoryTabLink extends PureComponent {
    onClick = e => {
        e.preventDefault();
        this.props.onClick(this.props.activation);
    };
    render() {
        return <a href="#" className={'tabs__link' + (this.props.active ? ' active-link' : '')} onClick={this.onClick}>
            {this.props.label}
        </a>;
    }
}