import React, {useCallback, useEffect, useState} from 'react';
import I18n from 'app/i18n';
import {NavLink, useNavigate} from 'react-router-dom';
import {getLinkPath, isGuest} from 'app/utils';
import {linkAndroid, linkIOSDownload} from 'app/utils/app_links';
import FastRegistration from 'app/components/auth/FastRegistration';
import {UserStore} from 'app/store/UserStore';
import LineInterface from 'app/interfaces/LineInterface';
import Get from 'app/utils/Get';
import LandingLadderGameStreamerPhoto from './LandingLadderGameStreamerPhoto';
import {navigateGoTo} from 'app/utils/navigate/navigateGoTo';
// @ts-ignore
import SvgLogoEgb from 'images/logos/egb-logo.svg';

interface LandingLadderGameProps {
    userStore?: UserStore
    game_id: number
}

const COUNT_OF_DECIMAL = 3;
const CSGO_GAME = 2;
const HEARTHSTONE_GAME = 9;
const HOMM_GAME = 56;

const LandingLadderGame: React.FC<LandingLadderGameProps> = props => {
    const [line, setLine] = useState<LineInterface | null>(null);
    const [pressedButton, setPressedButton] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isGuest()) {
            navigate('/play/bets');
            navigateGoTo(getLinkPath('/play/bets'));
        }
        getLines();
    }, []);

    const getLines = () => {
        new Get({params: {game_id: props.game_id}, url: '/api/v2/banner/landing'})
            .execute()
            .then(response => response.json())
            .then(data => {
                setLine(data);
            });
    };

    const onClickRegistration = () => {
        setPressedButton(prevState => !prevState);
    };

    const classNameByType = () => {
        switch (props.game_id) {
        case CSGO_GAME: return 'cs-go';
        case HEARTHSTONE_GAME: return 'hearthstone';
        case HOMM_GAME: return 'heroes-3';
        default: return 'dota-2';
        }
    };

    const handleOnClickRegistration = useCallback(() => onClickRegistration(), [onClickRegistration]);

    return <main className={`landing-promo-content ${classNameByType()}${pressedButton ? ' no-scroll' : ''}`}>
        <div className="left-landing-ladder-game">
            <NavLink to={getLinkPath('/')} className="logo-landing-promo ladder-game">
                <img src={SvgLogoEgb} alt="EGB.com" title="EGB.COM"/>
            </NavLink>
            <div className="middle-landing-ladder-game">
                <div className="greetings-landing-ladder-game">
                    <div className="title-and-hero-landing-ladder-game">
                        <div className="title-landing-ladder-game">
                            <span className="part-first">{I18n.t('title_landing_ladder_game_1')}</span>
                            <span className="part-second">{I18n.t('title_landing_ladder_game_2')}</span>
                        </div>
                        <span className="hero-landing-ladder-game"></span>
                    </div>
                    {line && <div className="line-landing-ladder-game">
                        <div className="line-inner-landing-ladder-game">
                            <div className="team">
                                <span className="status win">{I18n.t('status_win')}</span>
                                <span className="balance">
                                    {Number(line.banner === 1 ? line.coef_1 : line.coef_2)
                                        .toFixed(COUNT_OF_DECIMAL)}
                                </span>
                            </div>
                            <div className="streamer">
                                <LandingLadderGameStreamerPhoto
                                    gamer={line.banner === 1 ? line.gamer_1 : line.gamer_2}/>
                                <span className="streamer-name">
                                    {line.banner === 1 ? line.gamer_1.nick : line.gamer_2.nick}
                                </span>
                                <span className="line-live">{I18n.t('line_live')}</span>
                            </div>
                            <div className="team">
                                <span className="status lose">{I18n.t('status_lose')}</span>
                                <span className="balance">
                                    {Number(line.banner === 1 ? line.coef_2 : line.coef_1)
                                        .toFixed(COUNT_OF_DECIMAL)}
                                </span>
                            </div>
                        </div>
                    </div>}
                </div>
                <div className="bonus-landing-ladder-game">
                    <span className="bonus">$600!</span>
                    <div className="bonus-title">
                        <span className="part-first">{I18n.t('bonus')}</span>
                        <span className="part-second">{I18n.t('bonus_landing_ladder_game')}</span>
                    </div>
                </div>
            </div>
            <div className="landing-promo-footer">
                <div className="about-landing-ladder-game">
                    <div className="item">
                        <span className="item-num">1</span>
                        <span className="item-title">{I18n.t('about_landing_ladder_game_1')}</span>
                    </div>
                    <div className="item">
                        <span className="item-num">2</span>
                        <span className="item-title">{I18n.t('about_landing_ladder_game_2')}</span>
                    </div>
                    <div className="item">
                        <span className="item-num">3</span>
                        <span className="item-title">{I18n.t('about_landing_ladder_game_3')}</span>
                    </div>
                </div>
                <div className="landing-promo-content-rules">
                    <div className="row">
                        <NavLink
                            className="landing-promo-rules"
                            to={'/public/terms_and_conditions'}
                        >
                            {I18n.t('advertising.terms_conditions')}
                        </NavLink>
                    </div>
                    <div className="row">
                        <div className="landing-promo-list-app">
                            <a
                                rel="noopener noreferrer"
                                target="_blank"
                                className="item"
                                href={linkIOSDownload()}
                            >
                                <svg className="svg-default">
                                    <use xlinkHref="#svg-app_store"/>
                                </svg>
                            </a>
                            <NavLink className="item" to={linkAndroid()}>
                                <svg className="svg-default">
                                    <use xlinkHref="#svg-google_play"/>
                                </svg>
                            </NavLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="landing-promo-list-payment">
                            <span className="item visa">
                                <svg className="svg-default">
                                    <use xlinkHref="#svg-visa"/>
                                </svg>
                            </span>
                            <span className="item mastercard">
                                <svg className="svg-default">
                                    <use xlinkHref="#svg-master_card"/>
                                </svg>
                            </span>
                            <span className="item license">
                                <svg className="svg-default">
                                    <use xlinkHref="#svg-eighteenplus-adv"/>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={`landing-promo-center${pressedButton ? ' active' : ''}`}>
            <button
                className="button-close-registration-landing-promo"
                onClick={handleOnClickRegistration}
            >
                <svg height="21px" width="21px">
                    <use xlinkHref="#svg-close"/>
                </svg>
            </button>
            <div className="landing-promo-registration">
                <div className="landing-promo-registration-top">
                    <h2>{I18n.t('about_landing_ladder_game_1')}</h2>
                    <p>{I18n.t('form_landing_ladder_game')}</p>
                </div>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <FastRegistration />
            </div>
        </div>
        <div className="mobile-button-landing-promo">
            <button
                className="button-registration-landing-promo"
                onClick={handleOnClickRegistration}
            >{I18n.t('user.registration')}</button>
        </div>
    </main>;
};

export default LandingLadderGame;
