import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {ArtifactImage} from 'app/components/my_profile/artifacts/image';
import round from 'lodash/round';
import {stringTypeOfBonus} from 'app/utils/stringTypeOfBonus';

export default class Prize extends PureComponent {
    render() {
        const {loot: {points_amount, money_amount, bonus_amount, bonus, recipe, steam_skin}} = this.props;

        switch (true) {
        case Number(points_amount) > 0:
            return <DigitalPrizeImage
                amount={points_amount}
                measure={I18n.t('points', {count: points_amount})}
            />;
        case Number(money_amount) > 0:
            return <DigitalPrizeImage
                amount={money_amount}
                prefix="$"
            />;
        case bonus !== null:
            return <DigitalPrizeImage
                amount={bonus_amount}
                prefix="$"
                measure={stringTypeOfBonus(bonus)}
            />;
        default:
            return <ArtifactImage
                artifact={recipe || steam_skin || this.props.recipe}
                showHeroSlotCount
            />;
        }
    }
}

class DigitalPrizeImage extends PureComponent {
    get amount() {
        const {prefix = '', amount} = this.props;
        return `${prefix}${round(amount, 2)}`;
    }

    get title() {
        const {measure} = this.props;
        return `${this.amount} ${measure || ''}`;
    }

    render() {
        const {measure} = this.props;
        return <div className="hero__slot--img">
            <div className="bonus-preview" title={this.title}>
                <div className="bonus-preview__stroke">
                    <svg height="147px" width="147px">
                        <use xlinkHref="#svg-bonus-stroke"/>
                    </svg>
                </div>
                <div className="bonus-preview__wrap">
                    <div className="bonus-preview__stars bonus-preview__stars--top">
                        <svg height="16px" width="45px">
                            <use xlinkHref="#svg-bonus"/>
                        </svg>
                    </div>
                    <div className="bonus-preview__text">
                        <span className="bonus-preview__amount">{this.amount}</span>
                        {measure && <span className="bonus-preview__measure">{measure}</span>}
                    </div>
                    <div className="bonus-preview__stars bonus-preview__stars--bottom">
                        <svg height="16px" width="45px">
                            <use xlinkHref="#svg-bonus"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>;
    }
}
