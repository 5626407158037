import React from 'react';
import {PopupDialog} from './PopupDialog';
import I18n from 'app/i18n';
import SmartLoader from 'app/components/reusable/SmartLoader';

interface LoaderPopupInterface {
    popupId: string
}

export const LoaderPopup = (props: LoaderPopupInterface): React.ReactElement => <PopupDialog id={props.popupId}>
    <div className="popup__content">
        <div style={{
            marginBottom: '1em',
            textAlign: 'center'
        }}>
            {I18n.t('loading')}
        </div>
        <SmartLoader/>
    </div>
</PopupDialog>;
