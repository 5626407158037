import React, {PureComponent, ReactNode} from 'react';

interface WidgetProps {
    className?: string;
    children?: ReactNode
}

export class HelpDeskSupportLink extends PureComponent<WidgetProps> {
    onClick = (): void => {
        const btn = document.getElementById('hde-chat-init-btn-1');

        if (btn) {
            btn.click();
        }
    };

    render(): ReactNode {
        const {className, children} = this.props;
        return <a id={'helpdesk__container'} onClick={this.onClick}>
            <a
                role={'button'}
                className={className}
                id="helpdesk__button"
            >
                {children}
            </a>
        </a>;
    }
}
