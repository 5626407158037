import {Location} from 'react-router-dom';

export const locationQuery = (hookLocation?: Location): Record<string, string> => {
    const location = hookLocation ?? window.location;
    const searchParams = new URLSearchParams(location.search);
    const query = {};

    searchParams.forEach((value, key) => {
        query[key] = value;
    });

    return query;
};
