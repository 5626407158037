import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {bonusesDataToChat} from 'app/utils/share_to_chat';
import {betMoney} from 'app/utils';
import {inject, observer} from 'mobx-react';
import {UserBonusesStore} from 'app/store/UserBonusesStore';
import BonusDataInterface from 'app/interfaces/BonusDataInterface';

interface BannerButtonBlockRightProps {
    userBonusesStore?: UserBonusesStore;
    bonusesStats?: BonusDataInterface;
}

@inject('userBonusesStore')
@observer
export default class BannerButtonBlockRight extends Component<BannerButtonBlockRightProps> {
    render(): ReactNode {
        const {amount,
            turnovers:
                    {turnover = 0,
                        turnover30 = 0,
                        turnover90 = 0} = {}} = this.props?.bonusesStats || {};
        const stats = this.props.bonusesStats;


        return <>{stats && <div className="banner-button-block bonuses">
            <div className="banner-items-left">
                <div className="item">
                    <span className="title-name">{I18n.t('in_queue')}</span>
                    <div className="body">
                        <div className="row">
                            <span className="sum" id="in_queue_amount">{betMoney({amount})}</span>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <span className="title-name">{I18n.t('total_closed')}</span>
                    <div className="body">
                        <div className="row">
                            <span className="sum" id="month_amount">{betMoney({amount: turnover30})}</span>
                            <span className="period">{I18n.t('month')}</span>
                        </div>
                        <div className="row">
                            <span className="sum" id="three_month_amount">{betMoney({amount: turnover90})}</span>
                            <span className="period">3 {I18n.t('few_months')}</span>
                        </div>
                        <div className="row">
                            <span className="sum" id="all_amount_bonus">{betMoney({amount: turnover})}</span>
                            <span className="period">{I18n.t('all')}</span>
                        </div>
                    </div>
                </div>
            </div>
            <button
                className="button-adaptive button-adaptive_big"
                onClick={bonusesDataToChat}>{I18n.t('share_to_chat')}
            </button>
        </div>}</>;
    }
}
