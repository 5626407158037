import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {IncubatorCreateLineController} from 'app/controllers/IncubatorCreateLineController';
import {IncubatorUserBetInterface} from 'app/interfaces/IncubatorUserBetInterface';
import IncubatorUserBet from 'app/components/pages/incubator/line/IncubatorUserBet';
import {GAMER_1, GAMER_2} from 'app/utils/Consts';
import {isDesktop} from 'app/utils';

const AMOUNT_TR = 4;

interface Props {
    controller?: IncubatorCreateLineController
    bets: IncubatorUserBetInterface[]
    positionTable: number
}

export default class IncubatorUsersBets extends PureComponent<Props> {
    render(): ReactNode {
        const {bets, positionTable} = this.props;

        return <table>
            {isDesktop()
                ? <thead>
                    <tr>
                        {positionTable === GAMER_1 && <th/>}
                        <th>{I18n.t('tooltip_incubator_sec')}</th>
                        <th>{I18n.t('tooltip_incubator_amount')}</th>
                        <th>{I18n.t('tooltip_incubator_coef_title')}</th>
                        <th>{I18n.t('tooltip_incubator_poss_payout')}</th>
                        {positionTable === GAMER_2 && <th/>}
                    </tr>
                </thead>
                : <thead>
                    <tr>
                        <th>{I18n.t('tooltip_incubator_sec')}</th>
                        <th>{I18n.t('tooltip_incubator_amount')}</th>
                        <th>{I18n.t('tooltip_incubator_coef_title')}</th>
                    </tr>
                </thead>}
            <tbody>
                {bets.map(bet => <IncubatorUserBet positionTable={positionTable} key={bet.id} bet={bet}/>)}
                {bets.length < AMOUNT_TR && Array(AMOUNT_TR - bets.length).fill(0).map((_, i) => <tr key={i}>
                    <td/>
                    <td/>
                    <td/>
                    {isDesktop() && <td/>}
                    {isDesktop() && <td/>}
                </tr>)}
            </tbody>
        </table>;
    }
}
