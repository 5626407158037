import {isTotalLine} from './isTotalLine';
import LineInterface from 'app/interfaces/LineInterface';
import {isMainLine} from 'app/lines/isMainLine';
import {isAdvantageLine} from 'app/lines/isAdvantageLine';
import {isHandicapLine} from 'app/lines/isHandicapLine';
import {isSecondLevelNested} from 'app/lines/isSecondLevelNested';
import {lineAdditionalText} from 'app/lines/line_helpers';
import {fullNickHandicap} from 'app/lines/fullNickHandicap';
import {fullNickMainOrNested} from 'app/lines/fullNickMainOrNested';

export const formGamerSubTitle = (line: LineInterface, num: number): string => {
    if (isMainLine(line)) {
        return '';
    }
    if (isAdvantageLine(line)) {
        return line[`gamer_short_text_${num}`] + lineAdditionalText(line.parent, line.gamer);
    }
    if (isTotalLine(line)) {
        const gamer = line[`gamer_${num}`];

        return `${gamer.game_name} ${gamer.nick}`;
    }
    if (isSecondLevelNested(line) && !isHandicapLine(line)) {
        return `${fullNickMainOrNested(line, num)} (${line.parent[`gamer_${num}`].nick})`;
    }
    return isHandicapLine(line) ? fullNickHandicap(line, num) : fullNickMainOrNested(line, num);
};
