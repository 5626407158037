import React, {ReactNode, ChangeEvent} from 'react';
import I18n from 'app/i18n';

interface Props {
    changeAmount: (amount: string) => void
}

export default class PokerPanelButtonPokerGoBet extends React.Component<Props> {
    onChange = (event: ChangeEvent<HTMLInputElement>): void => this.props.changeAmount(event.target.value);

    render(): ReactNode {
        return <div className="poker-go-bet">
            <label className="field__label" htmlFor="pokerBetInput">{I18n.t('bet_amount')}</label>
            <label className="symbol-label" htmlFor="pokerBetInput">$</label>
            <input
                onChange={this.onChange}
                className="field__input bold"
                id="pokerBetInput"
                type="text"
            />
        </div>;
    }
}
