import {action, makeObservable, observable} from 'mobx';
import logEvent from 'app/amplitude/log_event';
import Websocket from 'modules/Websocket';
import I18n from 'app/i18n';
import minimizeStream from 'app/utils/stream/MinimizeStream';

const ELEMENT_NOT_FOUND = -1;
const LESS_THEN = -1;

export class StreamStore {
    @observable userStreams = JSON.parse(localStorage.getItem('userStreams')) || [];

    @observable streams = [];

    @observable stream = null;

    @observable imageLastUpdate = null;

    @observable opened = false;

    @observable scrollToBets = false;

    @observable hiddenStream = false;

    @observable fullScreen = false;

    @observable currentEvents = 0;

    @observable filterName = null;

    @observable minimize = false;

    @observable streams_by_games = [];

    constructor() {
        makeObservable(this);
    }

    @action imageLastUpdateNew(imageLastUpdate) {
        this.imageLastUpdate = imageLastUpdate;
    }

    @action init(streams, filterName, streams_by_games) {
        this.streams = streams;
        this.filterName = filterName;
        this.streams_by_games = streams_by_games;
    }

    @action add(stream) {
        this.delete(stream.id);
        let streams = this.streams.slice();
        const streams_by_games = {...this.streams_by_games};
        streams.push(stream);
        streams = this.sort_streams(streams);

        if (typeof this.streams_by_games[stream.game] === 'undefined') {
            streams_by_games[stream.game] = [];
        }
        streams_by_games[stream.game].push(stream);
        streams_by_games[stream.game] = this.sort_streams(streams_by_games[stream.game]);

        this.streams = streams;
        this.streams_by_games = streams_by_games;
    }

    @action delete(id) {
        const streams = this.streams.slice();
        const streams_by_games = {...this.streams_by_games};
        const index = streams.findIndex(item => item.id === id);

        if (index > ELEMENT_NOT_FOUND) {
            streams_by_games[streams[index].game].splice(
                streams_by_games[streams[index].game].findIndex(item => item.id === id),
                1
            );

            if (!streams_by_games[streams[index].game].length) {
                delete streams_by_games[streams[index].game];
            }
            streams.splice(index, 1);

            this.streams = streams;
            this.streams_by_games = streams_by_games;
        }
    }

    @action sort_streams(streams) {
        return streams.slice().sort((x1, x2) => {
            if (x1.author_flag !== x2.author_flag) {
                if (x1.author_flag === I18n.locale) {
                    return LESS_THEN;
                }
                if (x2.author_flag === I18n.locale) {
                    return 1;
                }
                return x2.author_flag.localeCompare(x1.author_flag);
            }
            if (x1.impt - x2.impt !== 0) {
                return x1.impt - x2.impt;
            }
            return x2.id - x1.id;
        });
    }

    @action chooseStream(stream, minimize = false) {
        if (stream && (stream.target_blank || stream.stream_url)) {
            window.open(stream.stream_url || `https://www.twitch.tv/${stream.twitch_channel}`, '_blank');
        } else {
            this.stream = stream;
            this.setMinimize(minimize);
        }
    }

    @action setMinimize(minimize) {
        if (minimize && !this.minimize) {
            Promise.resolve().then(() => minimizeStream());
        }
        this.minimize = minimize;
    }

    @action setCurrentEvents(currentEvents) {
        this.currentEvents = currentEvents;
    }

    @action openStream(opened) {
        this.opened = opened;
    }

    @action showFullScreen(value) {
        this.fullScreen = value;
    }

    @action setScrollToBet(scrollToBets) {
        this.scrollToBets = scrollToBets;
    }

    @action setHiddenStream(hiddenStream) {
        this.hiddenStream = hiddenStream;
    }

    @action handlePayload({streams, imageLastUpdate, streams_by_games}) {
        this.streams = streams;
        this.imageLastUpdate = imageLastUpdate;
        this.streams_by_games = streams_by_games;
    }

    @action createUserStream({title, twitch_channel}) {
        this.userStreams.unshift({
            id: title + twitch_channel,
            title,
            twitch_channel
        });
        this.userStreams = this.userStreams.slice();
        this.replaceStorageStreams();
        logEvent('STREAM_CREATED');
    }

    @action updateUserStream({id, title, twitch_channel}) {
        const streamIndex = this.userStreams.findIndex(s => s.id === id);
        this.userStreams[streamIndex] = {
            ...this.userStreams[streamIndex],
            id: title + twitch_channel,
            title,
            twitch_channel
        };
        this.userStreams = this.userStreams.slice();
        this.replaceStorageStreams();
    }

    @action deleteUserStream(id) {
        this.userStreams.splice(this.userStreams.findIndex(stream => stream.id === id), 1);
        this.userStreams = this.userStreams.slice();
        this.replaceStorageStreams();
    }

    replaceStorageStreams() {
        localStorage.setItem('userStreams', JSON.stringify(this.userStreams));
    }
}
