import React, {Component, ReactNode} from 'react';
import Content from 'app/components/pages/articles/Content';
import {getId} from 'app/utils/articles/getId';
import ArticleIndexInterface from 'app/interfaces/articles/ArticleIndexInterface';
import {articlesStore} from 'app/store/global';
import SmartFullPageLoader from 'app/components/reusable/smart_loaders/SmartFullPageLoader';
import I18n from 'app/i18n';

interface ArticleIndexProps {
    type: string
}

interface ArticleIndexState {
    title: string
    loading: boolean
    date: number
    content: string,
    id: string
}

export default class ArticleIndex extends Component<ArticleIndexProps, ArticleIndexState> {
    state = {
        content: '',
        date: null,
        id: getId(),
        loading: true,
        title: ''
    };

    setData = (data: ArticleIndexInterface): void => {
        this.setState({...data, loading: false});
    };

    componentDidMount(): void {
        articlesStore.loadArticleByTypeAndId(this.props.type, this.state.id, this.setData);
    }

    componentDidUpdate(): void {
        const id = getId();

        if (this.state.id !== id) {
            this.setState({id},
                () => articlesStore.loadArticleByTypeAndId(this.props.type, this.state.id, this.setData));
        }
    }

    onClickBack = (): void => {
        history.back();
    };

    render(): ReactNode {
        return this.state.loading
            ? <SmartFullPageLoader/>
            : <>
                <div className="title">
                    <a
                        className="cursor-pointer"
                        onClick={this.onClickBack}
                    >
                        <svg height="8px" width="5px">
                            <use xlinkHref="#svg-back"/>
                        </svg>
                        {I18n.t('button_back_label')}
                    </a>
                    <h1 style={{fontSize: 24, marginBottom: 1}} dangerouslySetInnerHTML={{__html: this.state.title}}/>
                </div>
                <Content date={this.state.date} content={this.state.content}/>
            </>;
    }
}
