import React from 'react';
import Image from 'app/helpers/Image';

const Prize = props => {
    const {image, section, title_number, title_place, prize, extra_prizes} = props;

    return <div
        className={`prize-item prize-item_${section}`}>
        <div className="prize-item-title">
            <span className="prize-place-number">{title_number}</span>
            {title_place && <span className="prize-place-title">{title_place}</span>}
        </div>
        <div className="prize-item-image">
            <span className="inner-image">{image && <Image
                image={image}
                title={[prize].concat(extra_prizes || []).join(' ')}
                alt={title_number}
            />}</span>
        </div>
        <div className="prize-item-description">
            <div className="main-prize">{prize}</div>
            {(extra_prizes || []).map(extra_prize => <div className="additional-prize" key={extra_prize}>
                <span className="additional-prize-title">{extra_prize}</span>
            </div>)}
        </div>
    </div>;
};

export default Prize;
