import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import {ScrolledPageContent} from 'app/components/page_content';
import {getLinkPath} from 'app/utils';
import {E_SPORT_MAIN_URL} from 'app/utils/esport';

@inject('pageContentStore')
@observer
export default class Esport extends Component {
    esports = () => this.props.pageContentStore.content.esports || [];

    render() {
        const {content: {esport_heading, esport_main}} = this.props.pageContentStore;
        return <ScrolledPageContent contentUrl={E_SPORT_MAIN_URL}>
            <div className="title">{esport_heading}</div>
            <div className="content__holder">
                <p dangerouslySetInnerHTML={{__html: esport_main}}/>
                {this.esports().map(esport => <div key={esport.slug}>
                    <h3>
                        <Link
                            to={getLinkPath(`/esports/${esport.slug}`)}>
                            {esport.title}
                        </Link>
                    </h3>
                    <p dangerouslySetInnerHTML={{__html: esport.pre_content}}/>
                </div>)}
            </div>
        </ScrolledPageContent>;
    }
}

export const gameName = game => {
    const filter = [
        {
            abbr: 'SC2',
            filter: 'StarCraft2'
        }
    ].find(f => f.filter === game);
    return filter && filter.abbr || game;
};
