import React, {ChangeEvent, KeyboardEvent} from 'react';
import SmartLabelTooltip from 'app/components/reusable/SmartLabelTooltip';
import SearchInput from 'app/components/SearchInput';

export default class GridPage2 extends React.PureComponent {
    handleChange(event: ChangeEvent<HTMLInputElement>): void {
        console.log(event);
    }

    handleKeyPress(event: KeyboardEvent<HTMLInputElement>): void {
        console.log(event);
    }

    render(): React.JSX.Element {
        return <>
            <div className="container-content">
                <span className="title-h1">Блоки и элементы</span>
            </div>
            <div className="container-line">
                <div className="container-content">
                    <SmartLabelTooltip
                        labelTooltip="tooltip_incubator_deposit_title"
                        forLabelTooltip="amountIncubatorLinePledge"
                        descriptionTooltip="tooltip_incubator_deposit"
                        classTooltip="right-position"
                    />
                    <SearchInput
                        handleChange={this.handleChange}
                        handleKeyPress={this.handleKeyPress}
                        value=""
                    />
                </div>
                <div className="container-content">
                    <SmartLabelTooltip
                        labelTooltip="tooltip_incubator_deposit_title"
                        forLabelTooltip="amountIncubatorLinePledge"
                        descriptionTooltip="tooltip_incubator_deposit"
                    />
                    <SearchInput
                        handleChange={this.handleChange}
                        handleKeyPress={this.handleKeyPress}
                        value=""
                    />
                </div>
            </div>
        </>;
    }
}
