import React, {Component} from 'react';
import I18n from 'app/i18n';
import {articleHeading} from 'app/utils/ArticleHeading';
import {inject, observer} from 'mobx-react';
import {ArticlesStore} from 'app/store/ArticlesStore';
import PromoNew from 'app/components/pages/articles/PromoNew';
import SmartFullPageLoader from 'app/components/reusable/smart_loaders/SmartFullPageLoader';

interface PromoNewProps {
    articlesStore?: ArticlesStore
}

@inject('articlesStore')
@observer
export default class PromoNewPage extends Component<PromoNewProps> {
    constructor(props: PromoNewProps) {
        super(props);
        this.props.articlesStore.initPromoNew();
    }

    render(): React.ReactNode {
        const {promoNewData: {loading, hot_promo, casino_promo, permanent_promo},
            activePromoNewCount} = this.props.articlesStore;

        return loading
            ? <SmartFullPageLoader/>
            : <>
                <div className="header-promo-articles">
                    <h1 className="name">{articleHeading('promotions')}</h1>
                    <div className="header-label">
                        <span className="label">
                            {I18n.t('promo_new_top_label', {count: activePromoNewCount})}
                        </span>
                    </div>
                </div>
                <PromoNew
                    articles={hot_promo}
                    svgNameIcon="svg-promo-topical"
                    header={I18n.t('promo_new_header_topical')}
                />
                <PromoNew
                    articles={casino_promo}
                    svgNameIcon="svg-promo-casino"
                    header={I18n.t('promo_new_header_casino')}
                />
                <PromoNew
                    articles={permanent_promo}
                    svgNameIcon="svg-promo-permanent"
                    header={I18n.t('promo_new_header_permanent')}
                />
            </>;
    }
}
