import $ from 'jquery';
import firebase from 'firebase/app';
import 'firebase/messaging';
import Manager from 'app/notifications/manager';
import FIREBASE_CONFIG from 'app/consts/firebase';
import {addGaEvent} from 'app/ga_methods';
import {userSignedIn} from 'app/utils';

const setTokenSentToServer = currentToken => {
    window.localStorage.setItem(
        'sentFirebaseMessagingToken',
        currentToken ? currentToken : ''
    );
};

const isTokenSentToServer =
        currentToken => window.localStorage.getItem('sentFirebaseMessagingToken') === currentToken;

const sendTokenToServer = currentToken => {
    if (!isTokenSentToServer(currentToken)) {
        $.post('/devices/subscribe', {token: currentToken})
            .done(() => setTokenSentToServer(currentToken));
    }
};

const sendCurrentLang = () => ({
    lang: window.CURRENT_LOCALE,
    topic: 'set_current_lang'
});

const initFirebase = (config, workerUrl) => {
    firebase.initializeApp(config);

    const messaging = firebase.messaging();

    navigator.serviceWorker.register(workerUrl).then(registration => {
        messaging.requestPermission().then(() => {
            messaging.getToken().then(sendTokenToServer);
        });

        messaging.useServiceWorker(registration);

        messaging.onMessage(payload => {
            Manager.callNotification((title, options) => new Notification(title, options), payload);
        });

        registration.addEventListener('updatefound', e => {
            e.currentTarget.installing.postMessage(sendCurrentLang());
        });

        registration.active && registration.active.postMessage(sendCurrentLang());
    });
};

export const notificationsAvailableInBrowser =
    () => window.Notification && Notification.requestPermission && userSignedIn();

export const notificationsStatusIsDefault =
    () => notificationsAvailableInBrowser() && Notification.permission === 'default';

export const notificationsStatusIsGranted =
    () => notificationsAvailableInBrowser() && Notification.permission === 'granted';

export const requestPermission = () => {
    if (window.Notification && Notification.requestPermission && userSignedIn()) {
        const {permission} = Notification;

        const requestPermissionCallBack = result => {
            if (permission !== result) {
                addGaEvent('Browser Notification', `permission ${result}`);
            }

            if (result !== 'granted') {
                return;
            }

            initFirebase(FIREBASE_CONFIG, '/service_worker');
        };

        try {
            Notification.requestPermission().then(requestPermissionCallBack);
        } catch (error) {
            if (error instanceof TypeError) {
                Notification.requestPermission(requestPermissionCallBack);
            } else {
                throw error;
            }
        }
    }
};
