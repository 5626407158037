import React, {Component, ReactNode} from 'react';
import ChatSmilesScroll from './chat/ChatSmilesScroll';
import ChatSmileItems from './chat/chat_smile_items';

interface SmileMenuProps {
    smiles: string[],
    recent_smiles: string[],
    insertSmile: (smile: string) => void
    isLoading: boolean
}

export default class SmileMenu extends Component<SmileMenuProps> {
    render(): ReactNode {
        return <>
            <ChatSmilesScroll>
                <ChatSmileItems
                    smiles={this.props.smiles}
                    insertSmile={this.props.insertSmile}
                />
            </ChatSmilesScroll>
            <ChatSmileItems
                smiles={this.props.recent_smiles}
                insertSmile={this.props.insertSmile}
                withBorder
                isLoading={this.props.isLoading}
            />
        </>;
    }
}
