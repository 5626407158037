import $ from 'jquery';
import React, {Component} from 'react';
import Cookies from 'js-cookie';
import I18n from 'app/i18n';
import {isDesktop, removeParam} from 'app/utils';
import {addGaEvent} from 'app/ga_methods';
import {DEFAULT_EVENTS} from 'app/store';
import {setStreamParam, showMoreStreams, streamMinimize} from './stream_functions';
import StreamTitle from './stream_title';
import ButtonMoreLess from './button_more_less';
import {streamStore, tableStore} from 'app/store/global';
import {openAddStreamPopup} from 'app/components/streams';
import {inject, observer} from 'mobx-react';
import getCookies from 'app/utils/getCookies';

let streamPopupElement;

const MULTIPLIER = 5,
    PERCENTS_70 = 0.7,
    YEAR = 365;
const streamWindowHeight = () => (streamPopupElement.offset().top + streamPopupElement.height()) * PERCENTS_70;

const currentPin = () => Cookies.get('pin') === 'true';

export default inject('streamStore')(observer(class StreamPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            autoMinimize: false,
            pin: currentPin(),
            stream_bets: false
        };
        this.closeStreamHandler = this.closeStreamHandler.bind(this);
        this.buttonMinimize = this.buttonMinimize.bind(this);
        this.buttonMaximize = this.buttonMaximize.bind(this);
        this.setTableFilterByStream = this.setTableFilterByStream.bind(this);
        this.setStream = this.setStream.bind(this);
        this.streamAutoSize = this.streamAutoSize.bind(this);
        this.toPin = this.toPin.bind(this);
    }

    addStreamTitle = {title: I18n.t('add_your_own')};

    rootElement = React.createRef();

    componentDidMount() {
        streamPopupElement = $(this.rootElement.current);
        showMoreStreams(this.rootElement.current);
        if (!this.props.hideQueryParams) {
            setStreamParam(this.props.stream);
        }

        if (!streamStore.minimize) {
            streamStore.showFullScreen(true);
            streamPopupElement.removeClass('popup-stream__video--minimized');
            $('.main-block').scroll(this.streamAutoSize);
        }
    }

    componentDidUpdate() {
        showMoreStreams($(this.rootElement.current));
    }

    closeStreamHandler(e) {
        e && e.preventDefault();
        this.props.closeStreamHandler();
        addGaEvent('Stream', 'close');
    }

    streamAutoSize() {
        if (!this.state.pin && streamStore.fullScreen && isDesktop()) {
            if ($(window).scrollTop() > streamWindowHeight() && this.state.autoMinimize === false) {
                this.setState({autoMinimize: true});
                streamMinimize();
            } else if ($(window).scrollTop() < streamWindowHeight() && this.state.autoMinimize === true) {
                this.setState({autoMinimize: false});
                this.maximize();
            }
        }
    }

    componentWillUnmount() {
        tableStore.update({currentEvents: getCookies('currentEvents', DEFAULT_EVENTS)});
        streamStore.showFullScreen(false);
        $('.main-block').unbind('scroll', this.streamAutoSize);
        removeParam('stream');
    }

    buttonMinimize() {
        streamStore.setMinimize(true);
        this.setState({pin: true});
    }

    buttonMaximize(e) {
        streamStore.setMinimize(false);
        this.setState({autoMinimize: $(window).scrollTop() > streamWindowHeight()});
        this.maximize();
        e.preventDefault();
        addGaEvent('Stream', 'maximize');
    }

    maximize() {
        const root = this.rootElement.current;
        const currentItemParent = $('.popup-stream__video-container', root);
        $('.popup-stream__video', root).animate({
            bottom: `${$(window).height() - $('.header').height() - currentItemParent.height() - currentItemParent.marginTop}px`,
            height: '475px',
            left: currentItemParent.offset().left - currentItemParent.marginLeft,
            width: currentItemParent.width()
        }, function() {
            $(this).removeClass('popup-stream__video--minimized')
                .css({
                    bottom: '',
                    height: '',
                    left: '',
                    width: ''
                });
        });
    }

    setTableFilterByStream(eventName, e) {
        if (eventName) {
            const event = this.isCurrentEvent() ? DEFAULT_EVENTS : [eventName];
            this.setState({stream_bets: event.length !== 0});
            streamStore.setCurrentEvents(event);
            tableStore.update({currentEvents: event});
        }
        streamStore.setScrollToBet(true);
        goToLinkSimpleBets();
        e.preventDefault();
    }

    setStream(stream) {
        streamStore.chooseStream(stream);
        setStreamParam(stream);
    }

    isCurrentEvent() {
        return this.state.stream_bets;
    }

    toPin() {
        const pin = !this.state.pin;
        Cookies.set('pin', pin, {expires: YEAR * MULTIPLIER});
        this.setState({pin});
        addGaEvent('Stream', pin ? 'pinned' : 'unpinned');
    }

    render() {
        const {opened, stream, streams, userStreams, hiddenStream} = this.props;
        const {pin} = this.state;
        const openedClass = opened ? 'stream-list--popup__container--expanded' : '';
        return <div
            ref={this.rootElement}
            style={streamStore.hiddenStream || hiddenStream ? {height: '0px', overflow: 'hidden'} : {}}
        >
            {isDesktop() &&
            <div className="stream-list--popup js-streams-list">
                <ButtonMoreLess opened={opened}/>
                <div
                    className={`stream-list--popup__container js-streams-container ${openedClass}`}>
                    <div className="stream-list--popup__container-holder js-streams-maxheight">
                        {streams.concat(userStreams).map(s => <StreamTitle key={s.id}
                            setStream={this.setStream}
                            currentStreamId={stream.id}
                            popup
                            stream={s}/>)
                        }
                        <StreamTitle
                            key="add_stream"
                            setStream={openAddStreamPopup}
                            currentStreamId="ADD_STREAM"
                            popup
                            stream={this.addStreamTitle}
                        />
                    </div>
                </div>
            </div>}
            <div className="popup-stream__video-container">
                <div className="popup-stream__video">
                    <div className="popup-stream__control">
                        {stream.tournament_name &&
                        <button className={`act popup-stream__bet${this.isCurrentEvent() ? ' active' : ''}`}
                            title={I18n.t(this.isCurrentEvent() ? 'switch_to_all_bets' : 'switch_to_stream_bets')}
                            onClick={this.setTableFilterByStream.bind(this, stream.tournament_name)}>
                            <svg height="19px" width="24px"><use xlinkHref="#svg-stream_bets"/></svg>
                        </button>}
                        <button className={`act popup-stream__pin${pin ? ' active' : ''}`}
                            onClick={this.toPin}>
                            <svg height="21px" width="21px"><use xlinkHref="#pin"/></svg>
                        </button>
                        <button type="button" onClick={this.buttonMinimize}
                            className="act popup-stream__control-link popup-stream__control-link--minimize"/>
                        <button type="button" onClick={this.buttonMaximize}
                            className="act popup-stream__control-link popup-stream__control-link--expand"/>
                        <button onClick={this.closeStreamHandler} className="act popup-stream__close">
                            <svg height="21px" width="21px"><use xlinkHref="#svg-close"/></svg>
                        </button>
                    </div>
                    {stream.twitch_channel || stream.stream_url
                        ? <iframe
                            src={stream.twitch_channel
                                ? `https://player.twitch.tv/?autoplay=true&channel=${stream.twitch_channel}&parent=${
                                    location.hostname}`
                                : stream.stream_url}
                            parent={location.hostname}
                            frameBorder="0"
                            scrolling="no"
                            allowFullScreen={true}/>
                        : <div dangerouslySetInnerHTML={{__html: stream.strim}}/>
                    }
                </div>
            </div>
        </div>;
    }
}));
