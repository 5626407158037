import React, {MouseEvent, PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import UploadButton from 'app/components/DocumentsUpload/UploadButton';
import {ScreenshotType} from 'app/store/CashoutsStore';

interface UploadScreenshotProps {
    onUpload: (number) => void
    screenshot: ScreenshotType | boolean
}

export default class UploadScreenshot extends PureComponent<UploadScreenshotProps> {
    showUpload = (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
    };

    render(): ReactNode {
        const {screenshot, onUpload} = this.props;
        return <React.Fragment>
            <div className="form-loading-screenshot">
                <div className="title-info">
                    <span className="icon">
                        <svg>
                            <use xlinkHref="#svg-qr-code"/>
                        </svg>
                    </span>
                    <span className="name">{I18n.t('qr_for_payment')}</span>
                </div>

                <UploadButton
                    created_for_type="Cashout"
                    maxDocumentsLoaded={Boolean(screenshot)}
                    justButton
                    thinButton
                    label={I18n.t('upload')}
                    onUpload={onUpload}
                    singleDocument
                    noForm
                />
            </div>
        </React.Fragment>;
    }
}
