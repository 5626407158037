export const DATE_FORMAT = 'YYYY-MM-DD HH:mm';

export const BLUE = 1;
export const RED = 3;

export const GREEN = 2;
export const NO_RESULT = 0;
export const CANCELED = -1;
export const DRAW = -2;
export const CANCEL_RESULTS = [CANCELED, DRAW];

export const ALL_BETS = 4;

export const REAL_MONEY = 0;
export const PLAY_MONEY = 1;
export const ARTIFACT_BET = 2;
export const SUGGESTION = 3;
export const REAL_MONEY_SUGGESTION = 4;
export const DEMO_MONEY = 5;
export const ARTIFACT_BET_TYPE = 'ArtifactBet';
export const USER_BET_TYPE = 'UserBet';

export const NON_LABEL = '';
export const IMPORTANT_MATCH = 0;
export const FINAL = 1;
export const IMPORTANT_LIMIT = 250;

export const STATUS_NORMAL = 0;
export const STATUS_PENDING = 1;
export const STATUS_IN_PROGRESS = -10;
export const STATUS_COMPLETED = 2;
export const STATUS_CANCEL_COMPLETED = 3;
export const STATUS_REVERTED = 4;
export const STATUS_DELAY = 5;

export const ACTIVE_STATUSES = [STATUS_NORMAL, STATUS_REVERTED];

export const CORRECT_COEF_CHANGING_LENGTH = 3;
