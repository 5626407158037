import React, {Component} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import Checkbox from 'app/components/auth/Checkbox';
import {artifactStore} from 'app/store/global';
import CopyLink from 'app/utils/copy_link';
import {showPopup} from 'app/utils/modals/ModalsService';
import OfferTradePopup from 'app/components/my_profile/trades/offer_trade_popup';

const TradeSettings = inject('artifactStore')(observer(class TradeSettingsImpl extends Component {
    static changePublicInventory(value) {
        artifactStore.changePublicInventory(value);
    }

    static changeTradeEnabled(value) {
        artifactStore.changeTradeEnabled(value);
    }

    static changeTradeTokenLink(value) {
        artifactStore.changeTradeTokenLink(value);
    }

    static showOfferTradePopup() {
        showPopup('offer-trade-redirect', props => <OfferTradePopup popupId={props.popupId}/>);
    }

    render() {
        return <div className="settings-exchange profile-tab">
            <div className="settings-exchange--private">
                <div className="settings-exchange--private-inner">
                    <div className="subtitle">{I18n.t('privacy_settings')}</div>
                    <div className="settings-exchange--private__content">
                        <div className="settings-exchange--private__content--row">
                            <Checkbox
                                name="public_inventory"
                                simple={true}
                                label={I18n.t('make_inventory_visible')}
                                onChange={TradeSettings.changePublicInventory}
                                value={artifactStore.publicInventory}
                            />
                            <div className="settings-exchange--private__options-link">
                                <CopyLink link={SITE_DATA.PUBLIC_INVENTORY_LINK}/>
                            </div>
                        </div>
                        <div className="settings-exchange--private__content--row">
                            <Checkbox
                                name="trade_enabled"
                                simple={true}
                                label={I18n.t('enable_trades')}
                                onChange={TradeSettings.changeTradeEnabled}
                                value={artifactStore.tradeEnabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="settings-exchange--third-party">
                <div className="settings-exchange--third-party-inner">
                    <div className="subtitle">{I18n.t('partner_sites')}</div>
                    <p>{I18n.t('partner_link_description')}</p>
                    <div className="settings-exchange--link">
                        {artifactStore.tradeEnabled &&
                        <CopyLink
                            link={artifactStore.tradeTokenLink}
                            label={I18n.t('trade_link')}
                        />}
                        <button
                            className={`btn btn--big-on-mobile new-link${artifactStore.tradeEnabled
                                ? ''
                                : ' btn--disable'}`}
                            disabled={!artifactStore.tradeEnabled} onClick={TradeSettings.changeTradeTokenLink}
                        >
                            {I18n.t('create_new_link')}
                        </button>
                        <p>{I18n.t('new_partner_link_description')}</p>
                        {window.SITE_DATA.MOBILE_WRAPPER && <button
                            className={`btn btn--big-on-mobile new-link${artifactStore.tradeEnabled
                                ? ''
                                : ' btn--disable'}`}
                            disabled={!artifactStore.tradeEnabled} onClick={TradeSettings.showOfferTradePopup}
                        >
                            {I18n.t('offer_trade')}
                        </button>}
                    </div>
                </div>
            </div>
        </div>;
    }
}));

export default TradeSettings;
