import ChinaDictionariesInterface from 'app/interfaces/ChinaDictionariesInterface';
import PayzenChinaBankInterface from 'app/interfaces/PayzenChinaBankInterface';
import React, {PureComponent} from 'react';
import FieldWrapInput from 'app/components/cashout/FieldWrapInput';
import FieldWrapSelectPicker from 'app/components/cashout/FieldWrapSelectPicker';

interface PropsInterface {
    detailsAttribute?: 'string'
    handleDetails: (state: unknown, stateName: string) => void
    china_banks: PayzenChinaBankInterface[]
    china_dictionaries: ChinaDictionariesInterface
    disabled: boolean
    errors: unknown
}

export default class PayzenCashoutDetails extends PureComponent<PropsInterface> {
    state = {
        bank_account_number: '',
        bank_branch: '',
        bank_id: '',
        city: '',
        customer_name: '',
        province: ''
    };

    stateChangeCallback = (): void => {
        const {handleDetails, detailsAttribute} = this.props;
        handleDetails(this.state, detailsAttribute || 'cashout_detail_attributes');
    };

    handleSelect = (stateName: string, val: number): void => this.setState(
        {[stateName]: val},
        this.stateChangeCallback
    );

    handleOnChange = (stateName: string, e: React.ChangeEvent<HTMLSelectElement>): void => this.setState(
        {[stateName]: e.target.value},
        this.stateChangeCallback
    );

    get bankList(): PayzenChinaBankInterface[] {
        const {china_banks = []} = this.props;
        return china_banks.filter(b => b.country_code === 'china' &&
            b.method === 'payout' || !b.country_code && !b.method);
    }

    render(): React.ReactNode {
        const {china_dictionaries, disabled, errors} = this.props;
        const {province = [], area = []} = china_dictionaries || {};

        return <div className="cashout__field field">
            <FieldWrapSelectPicker
                collection={this.bankList}
                value={this.state.bank_id}
                stateName="bank_id"
                attrValue="bank_id"
                translation_key="bank"
                attrDescription="description"
                handleOnChange={this.handleSelect}
                disabled={disabled}
                errors={errors}
            />

            <FieldWrapInput
                stateName="bank_branch"
                handleOnChange={this.handleOnChange}
                value={this.state.bank_branch}
                disabled={disabled}
                errors={errors}
            />

            <FieldWrapInput
                stateName="customer_name"
                handleOnChange={this.handleOnChange}
                value={this.state.customer_name}
                disabled={disabled}
                errors={errors}
            />

            <FieldWrapInput
                stateName="bank_account_number"
                handleOnChange={this.handleOnChange}
                value={this.state.bank_account_number}
                disabled={disabled}
                errors={errors}
            />

            <FieldWrapSelectPicker
                collection={province}
                value={this.state.province}
                stateName="province"
                attrValue="code"
                attrDescription="name"
                handleOnChange={this.handleSelect}
                disabled={disabled}
                errors={errors}
            />

            <FieldWrapSelectPicker
                collection={area}
                value={this.state.city}
                stateName="city"
                attrValue="code"
                attrDescription="name"
                handleOnChange={this.handleSelect}
                disabled={disabled}
                errors={errors}
            />
        </div>;
    }
}
