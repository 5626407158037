import I18n from 'app/i18n';
import {getLinkPath} from 'app/utils/link_helper';


export const artifactDescription = artifact => {
    const {description_langs, description} = artifact;
    return description_langs && description_langs[I18n.locale] || description;
};

export const artifactName = artifact => {
    const {langs, name} = artifact;
    return langs && langs[I18n.locale] || name;
};


export const artifactShopLink = (artifact, rawURL = false) => {
    const {slug, id} = artifact;
    const url = `/shop/marketplace/${slug || id}/orders`;
    return rawURL ? url : getLinkPath(url);
};

export const equippedAtDescendingSort = artifacts => artifacts.sort((a, b) => a.equipped_at && a.equipped_at.localeCompare(b.equipped_at));
