import React, {Fragment, PureComponent} from 'react';
import {translatedName} from 'app/utils/translations_helper';
import HeroSlotCount from 'app/utils/hero/hero_slot_count';
import prizeStarSvg from 'images/svg_icons/prize-star.svg';
import {fixed2int} from 'app/utils/fixed';
import {goToLinkMarketplace} from 'app/utils/app_links';
import {hideLastPopup} from 'app/utils/modals/ModalsService';

export class ArtifactImage extends PureComponent {
    render() {
        const {artifact, showHeroSlotCount, showHeroSlotCountOrange, withDescription} = this.props;

        if (!artifact) {
            return null;
        }

        const imageMarkup = <Fragment>
            <div className="hero__box">
                <div className="hero__img game game_csgo game_dota2">
                    <div className="hero__slot--img">
                        <SimpleArtifactImage artifact={artifact}/>
                    </div>
                    {
                        (showHeroSlotCount || showHeroSlotCountOrange) &&
                        <HeroSlotCount {...artifact} showHeroSlotCountOrange={showHeroSlotCountOrange}/>
                    }
                </div>
            </div>
        </Fragment>;

        if (!withDescription) {
            return imageMarkup;
        }

        return <Fragment>
            <div className="hero__slot">
                {imageMarkup}
            </div>
            <div className="lootbox-won__popup--prize-item--title">
                <img
                    src={prizeStarSvg}
                    alt="prize"
                    width="14px"
                    height="13px"
                />
                {translatedName(artifact)}
            </div>
        </Fragment>;
    }
}

export class SimpleArtifactImage extends PureComponent {
    imageUrl() {
        const {artifact} = this.props;
        return typeof artifact.image === 'string' ? artifact.image : artifact.image.url;
    }

    titleWithPrice() {
        const {artifact} = this.props;
        let title = translatedName(artifact);

        if (artifact.price) {
            title += ` $${fixed2int(artifact.price)}`;
        }
        return title;
    }

    onClick = () => {
        if (this.props.isClickable) {
            hideLastPopup();
            goToLinkMarketplace(this.props.artifact);
        }
    };

    render() {
        const {artifact, imgAttributes} = this.props;

        if (!artifact || !Object.prototype.hasOwnProperty.call(artifact, 'image')) {
            return null;
        }

        return <img
            onClick={this.onClick}
            src={this.imageUrl()}
            alt={translatedName(artifact)}
            title={this.titleWithPrice()}
            {...(imgAttributes || {})}
        />;
    }
}
