import React, {PureComponent} from 'react';
import OnClickToastInterface from '../../interfaces/OnClickToastInterface';
import logEvent from '../../amplitude/log_event';
import {NOTIFICATION_INTERACTION} from './BaseToast';

export class CloseButton extends PureComponent<OnClickToastInterface> {
    onClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();
        this.props.closeToast();
        if (this.props.data) {
            logEvent(NOTIFICATION_INTERACTION, {
                interaction: 'closed',
                types: this.props.data.type
            });
        }
        this.props.onClick && this.props.onClick();
    };

    render(): React.ReactNode {
        return <button
            className="Toastify__close-button"
            type="button"
            aria-label="close"
            onClick={this.onClick}
        >
            <svg height="12px" width="12px">
                <use xlinkHref="#svg-close"/>
            </svg>
        </button>;
    }
}
