import {action, makeObservable, observable} from 'mobx';
import {isGuest} from 'app/utils';
import Websocket from 'modules/Websocket';
import Get from 'app/utils/Get';

const ELEMENT_NOT_FOUND = -1;

export class LineStore {
    @observable line = null;

    @observable userBets = null;

    timerId = null;

    seo = false;

    subscription = null;

    constructor(line, userBets, seo = false) {
        this.init(line, userBets, seo);
        this.listen();
        this.willUpdateBetForm();
        makeObservable(this);
    }

    clear() {
        this.clearUpdateTimer();
        this.stopListening();
    }

    @action
    init(line, userBets, seo) {
        this.line = line;
        this.userBets = userBets;
        this.seo = seo;
    }


    clearUpdateTimer() {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    }

    willUpdateBetForm() {
        const FIVE_SECONDS = 5000;
        this.timerId = setTimeout(() => this.updateFormBet(), FIVE_SECONDS);
    }

    restartUpdate() {
        this.clearUpdateTimer();
        this.updateFormBet();
    }

    @action
    updateFormBet() {
        new Get({url: `/bets/${this.line.hash_id}`})
            .execute()
            .then(response => response.json())
            .then(data => {
                this.updateLine(data.bet);
                this.updateUserBets(data.user_bets);
                this.willUpdateBetForm();
            })
            .catch(() => this.willUpdateBetForm());
    }

    @action
    updateUserBets(userBets) {
        this.userBets = userBets;
    }

    @action
    updateLine(mainNewLine) {
        this.line = mainNewLine;
    }

    updateLineById(line, newLine) {
        let result = false;
        ['nested_bets', 'advantages'].forEach(key => {
            if (!Array.isArray(line[key])) {
                return;
            }

            const nestedLineIndex = line[key].findIndex(nestedLine => nestedLine.id === newLine.id);

            if (nestedLineIndex === ELEMENT_NOT_FOUND) {
                line[key].forEach(nestedLine => {
                    if (this.updateLineById(nestedLine, newLine)) {
                        result = true;
                    }
                });
            } else {
                line[key][nestedLineIndex] = {
                    ...line[key][nestedLineIndex],
                    ...newLine
                };
                result = true;
            }
            if (result) {
                line[key] = [...line[key]];
            }
        });
        return result;
    }

    @action
    updateCoefs(line) {
        if (this.line.id === line.id) {
            this.line = {
                ...this.line,
                ...line
            };

            return;
        }

        if (this.updateLineById(this.line, line)) {
            this.line = {...this.line};
        }
    }

    listen() {
        if (isGuest()) {
            return;
        }

        Websocket.subscribe(`:line-${this.line.hash_id}`, ({data: {event, payload}}) => {
            if (event === 'update_coef') {
                this.updateCoefs(payload.line);
            }
        });
    }

    stopListening() {
        if (this.subscription) {
            Websocket.unsubscribe(this.subscription);
            this.subscription = null;
        }
    }
}

export default LineStore;
