import React, {PureComponent, ReactNode} from 'react';
import TimeLeft from 'app/components/my_profile/hero/hero_scrolls/TimeLeft';
import {SimpleArtifactImage} from 'app/components/my_profile/artifacts/image';
import InventoryItemsEffectInterface from 'app/interfaces/InventoryItemsEffectInterface';

export default class HeroEffectHeader extends PureComponent<InventoryItemsEffectInterface> {
    render(): ReactNode {
        const {description, time_left, id, recipe} = this.props;
        return <li key={id}>
            <div className="artifact-user">
                <div className="artifact-user__image">
                    <SimpleArtifactImage
                        artifact={{...recipe}}
                    />
                </div>
                <div className="artifact-user__description">
                    <div className="artifact-user__description-text">
                        <p dangerouslySetInnerHTML={{__html: description}}/>
                    </div>
                    <TimeLeft time_left={time_left}/>
                </div>
            </div>
        </li>;
    }
}
