import React, {PureComponent} from 'react';

export default class Input extends PureComponent<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement>> {
    render(): React.ReactNode {
        return <input
            type="text"
            className="field__input field__input_text"
            {...this.props}
        />;
    }
}
