import React, {PureComponent} from 'react';
import {casinoGamesStore} from 'app/store/global';

interface FirstLevelItemProps {
    className?: string
    name: string
    value?: string
}

export default class FirstLevelItem extends PureComponent<FirstLevelItemProps> {
    changeSortActive = (): void => {
        casinoGamesStore.changeSelectSorting(this.props.value);
    };

    render(): React.ReactNode {
        const {className, name} = this.props;
        return <button
            onClick={this.changeSortActive}
            className={className}>
            {name}
        </button>;
    }
}
