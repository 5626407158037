import EventProperties from 'app/amplitude/event_properties';

export default class SignInEventProperties extends EventProperties {
    get errors() {
        return this.responseJSON.error || this.responseJSON.errors;
    }

    extractProperties() {
        if (!this.errors) {
            return null;
        }

        return {error: this.errors};
    }
}

