import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {sendEmailConfirmation} from 'app/utils/email/sendEmailConfirmation';

interface ConfirmEmailButtonProps {
    confirmationExpired?: boolean;
    confirmed?: boolean;
}

export class ConfirmEmailButton extends Component<ConfirmEmailButtonProps> {
    confirmEmailHandler(event: React.MouseEvent): void {
        sendEmailConfirmation();
        event && event.preventDefault();
    }

    renderButton(onClick?: (event: React.MouseEvent) => void, confirmationExpired?: boolean): JSX.Element {
        return <div className="field">
            <button
                onClick={onClick}
                className={`btn ${confirmationExpired ? '' : 'btn--disable'}`}
                disabled={!confirmationExpired}>
                {I18n.t('confirm')}
            </button>
        </div>;
    }

    render(): ReactNode {
        const {confirmationExpired, confirmed} = this.props;
        let button = null;
        if (typeof confirmed !== 'undefined' && !confirmed) {
            button = this.renderButton(this.confirmEmailHandler, confirmationExpired);
        }
        return <>{button}</>;
    }
}
