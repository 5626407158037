import EventProperties from 'app/amplitude/event_properties';

export default class DepositEventProperties extends EventProperties {
    extractProperties() {
        if (!this.errors) {
            return null;
        }

        return {error: this.errors};
    }
}
