import React, {Component, ReactNode, RefObject} from 'react';
import $ from 'jquery';

interface RowTimeZoneProps {
    timeZoneId: number;
}

export class RowTimeZone extends Component<RowTimeZoneProps> {
    timeZone: RefObject<HTMLSelectElement> = React.createRef();

    componentDidMount(): void {
        $(this.timeZone.current).chosen({search_contains: true}).trigger('chosen:updated');
    }


    render(): ReactNode {
        const {timeZoneId} = this.props;
        return <div className="field__select profile__timezone-select classical-select">
            <select className="account-chosen-select field__select-chosen"
                name="timezone_id" ref={this.timeZone} defaultValue={timeZoneId}>
                {window.TIME_ZONES.map((zone, i) => <option
                    value={zone.id}
                    key={i}>
                    {zone.name} ({zone.description})
                </option>)}
            </select>
            <div className="field__select-arrow"/>
        </div>;
    }
}
