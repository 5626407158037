import React, {MouseEvent, PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import CurrentP2pDepositInterface from 'app/interfaces/CurrentP2pDepositInterface';

interface FailedButtonProps {
    deposit: CurrentP2pDepositInterface
    cancelP2pDeposit: (e: MouseEvent<HTMLAnchorElement>, deposit: CurrentP2pDepositInterface) => void
}

export default class FailedButton extends PureComponent<FailedButtonProps> {
    cancelP2pDeposit = (e: MouseEvent<HTMLAnchorElement>): void => {
        const {cancelP2pDeposit, deposit} = this.props;
        cancelP2pDeposit(e, deposit);
    };

    render(): ReactNode {
        const {deposit: {waiting, not_valid, auto_waiting}} = this.props;
        return <div className="value-button">
            {(waiting || not_valid) && <a
                onClick={this.cancelP2pDeposit}
                href="#"
                className={`button-adaptive ${auto_waiting
                    ? 'button-adaptive_disable'
                    : 'button-adaptive_transparent'}`}
            >
                {I18n.t('i_failed')}
            </a>}
        </div>;
    }
}
