import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {StoriesStoreProps} from 'app/store/StoriesStore';
import SmartStory from 'app/components/pages/home_page/SmartStories/SmartStory';

@inject('storiesStore')
@observer
export default class SmartStoriesMobile extends Component<StoriesStoreProps> {
    render(): React.ReactNode {
        const {storiesStore} = this.props;
        const {stories} = storiesStore.data;
        return storiesStore.hasStories()
            ? <div className="smart-stories smart-stories__mobile">
                <ul className="list-smart-stories">
                    {stories.map(s => <SmartStory key={s.id} story={s}/>)}
                </ul>
            </div>
            : null;
    }
}
