import React, {Component, ReactNode} from 'react';
import BetSlipSuggestionsCoefficient from 'app/components/bet_slip/suggestions/BetSlipSuggestionsCoefficient';
import {inject, observer} from 'mobx-react';
import {BetSlipStore} from 'app/store/BetSlipStore';
import BetSlipController from 'app/components/bet_slip/BetSlipController';
import I18n from 'app/i18n';

interface BetSlipSuggestionsProps {
    betSlipStore?: BetSlipStore
    controller?: BetSlipController
}

class BetSlipSuggestionsCoefficients extends Component<BetSlipSuggestionsProps> {
    cancelSuggestions = (e: React.MouseEvent): void => {
        e && e.preventDefault();
        const {userSuggestionByEvent, cancelSuggestion} = this.props.betSlipStore;
        cancelSuggestion(userSuggestionByEvent.map(item => item.id));
    };

    render(): ReactNode {
        const {userSuggestionByEvent, cancelSuggestion} = this.props.betSlipStore;
        return userSuggestionByEvent?.length > 0 &&
                <div className="bet-slip-suggestions-coefficients">
                    <div className="bet-slip-suggestions-coefficients-list">
                        {userSuggestionByEvent.map(suggestion => <BetSlipSuggestionsCoefficient
                            key={suggestion.id}
                            suggestion={suggestion}
                            cancel={cancelSuggestion}/>)}
                    </div>
                    <div className="bets-games-number">
                        <div className="top">
                            <span className="bets-number">
                                {I18n.t('suggestions_count', {count: userSuggestionByEvent.length})}
                            </span>
                            <button onClick={this.cancelSuggestions} className="button-remove-all-bets">
                                {I18n.t('cancel_all')}
                            </button>
                        </div>
                    </div>
                </div>;
    }
}

export default inject('betSlipStore', 'controller')(observer(BetSlipSuggestionsCoefficients));
