import React, {PureComponent} from 'react';
import HeroEffectHeader from './HeroEffectHeader';
import InventoryItemsEffectInterface from 'app/interfaces/InventoryItemsEffectInterface';

interface EffectsHeaderProps {
    effects: InventoryItemsEffectInterface [];
    title: string;
}

export default class EffectsHeader extends PureComponent<EffectsHeaderProps> {
    render(): React.ReactNode {
        const {effects, title} = this.props;

        if (effects.length === 0) {
            return null;
        }

        return <div className="artifacts-user-box">
            <div className="artifacts-user-box__title">
                <span className="artifacts-user-box__title-name">{title}</span>
            </div>
            <ul className="artifacts-user-box__list">
                {effects.map(item => <HeroEffectHeader
                    key={item.description + item.id}
                    {...item}
                />)}
            </ul>
        </div>;
    }
}
