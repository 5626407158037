import React, {Component} from 'react';
import {i18n_t} from 'app/i18n';
import {fixed2int} from 'app/utils/fixed';
import {inject, observer} from 'mobx-react';
import {CashoutsStore} from 'app/store/CashoutsStore';

interface LimitsProps {
    cashoutsStore: CashoutsStore
}

@inject('cashoutsStore')
@observer
export default class Limits extends Component<LimitsProps> {
    render(): JSX.Element {
        const {cashoutsStore} = this.props;
        const system = cashoutsStore.data.selectedSystem;
        return system && <label className="control-label cashout-form__limits">
            <span className="deposit-form__limits-item">
                {i18n_t('min_dot')}: <span
                    className="label-interval"
                    onClick={cashoutsStore.setMinAmount}
                >
                    ${fixed2int(system.min_limit)}
                </span>
            </span>
            {Number(system.max_limit) > 0 && <span className="deposit-form__limits-item">
                {i18n_t('max_dot')}: <span
                    className="label-interval"
                    onClick={cashoutsStore.setMaxAmount}
                >
                    ${fixed2int(system.max_limit)}
                </span>
            </span>}
        </label>;
    }
}
