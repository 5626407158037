import ErrorsServerInterface from 'app/interfaces/ErrorsServerInterface';
import {casinoGamesStore} from 'app/store/global';
import {UNPROCESSABLE_ENTITY} from 'app/utils/HttpStatuses';
import Get from 'app/utils/Get';
import Post from 'app/utils/Post';
import {toastError} from 'app/components/toasts/liteToast';
import logEvent from 'app/amplitude/log_event';
import {CasinoGameInterface, CasinoGamesStoreInterface} from 'app/interfaces/stores/CasinoGamesStoreInterfaces';
import I18n from 'app/i18n';
import {arraySame} from 'app/utils/ArraySame';
import {ELEMENT_NOT_FOUND} from 'app/utils/Consts';

const HOME_PAGE_GAMES_LENGTH = 21;

interface LoadMoreGamesInterface {
    (data: CasinoGamesStoreInterface): void;
}

interface QueryParamsInterface {
    page?: number,
    order_by?: string,
    favorite?: boolean,
    find?: string,
    game_id?: string,
    provider?: string[],
    categories?: string[]
}

export const casinoGameById = (id: number, filterId: number): CasinoGameInterface => {
    if (filterId) {
        return casinoGamesStore.casinoFilters.find(item => item.id === filterId)
            .casinoGames.find(item => item.id === id);
    }
    return casinoGamesStore.data.casinoGames.find(item => item.id === id);
};


export const startCasinoGame = (id: number, filterId = null): void => {
    startCasino(id, filterId);
};

export const startCasino = (id: number, filterId: number, demo = false): void => {
    let casinoGame = casinoGameById(id, filterId);

    if (casinoGame) {
        if (demo) {
            startDemo(casinoGame.id, casinoGame.identifier);
        } else {
            startGame(casinoGame.id, casinoGame.identifier);
        }
    } else {
        sendRequestFindCasinoGame(id).then(() => {
            casinoGame = casinoGameById(id, filterId);
            if (casinoGame) {
                if (demo) {
                    startDemo(casinoGame.id, casinoGame.identifier);
                } else {
                    startGame(casinoGame.id, casinoGame.identifier);
                }
            }
        });
    }
};

export const sendRequestFindCasinoGame = async(id: number): Promise<void> => {
    await new Get({
        url: `/casino/games/${id}/load_game`
    })
        .execute()
        .then(response => response.json())
        .then(response => {
            if (response.data) {
                casinoGamesStore.updateGamesCollection(casinoGamesStore.data.casinoGames.concat([response.data]));
            } else if (response.message) {
                toastError(response.message);
            } else {
                toastError(I18n.t('server_error_try_later'));
            }
        });
};

export const favoriteGameChange = (id: number, favorite: boolean, filterId: number): void => {
    new Post({
        params: {
            favorite
        },
        url: `/casino/games/${id}/change_favorite`
    })
        .execute()
        .then(response => response.json())
        .then(response => {
            if (!response.success) {
                toastError(response.message);
            } else if (favorite) {
                const casinoGame = casinoGameById(id, filterId);

                logEvent('CASINO_FAVORITES_ADDED', {
                    game: casinoGame.name,
                    game_type: casinoGame.category,
                    provider: casinoGame.provider_name
                });
            }
        });
};

export const sendRequestStartCasino = (url: string, device: string): void => {
    new Post({
        params: {
            client_type: device
        },
        url
    })
        .execute()
        .then(response => {
            if (!response.ok) {
                throw response;
            }
            return response.json();
        })
        .catch((response: Response) => {
            if (response.status === UNPROCESSABLE_ENTITY) {
                response.json().then(({errors}: {errors: ErrorsServerInterface}) => {
                    toastError(errors.base[0]);
                });
            }
        });
};

export const loadGames = (
    params: QueryParamsInterface,
    savePartCasinoGames: LoadMoreGamesInterface
): void => {
    new Get({
        params: {
            device: casinoGamesStore.device,
            ...params
        },
        url: '/casino/games/load_games'
    })
        .execute()
        .then(response => response.json())
        .then(response => {
            if (!response) {
                toastError(I18n.t('server_error_try_later'));
            }
            savePartCasinoGames(response);
        });
};

export const loadHomePageGames = (callback: ()=> void): void => {
    new Get({
        params: {
            filter_id: window.settings.config.home_page_casino_filter_id || ELEMENT_NOT_FOUND
        },
        url: '/casino/games/games_by_filters'
    }).execute()
        .then(response => {
            response.json()
                .then(result => {
                    if (result && result.length > 0 && result[0]) {
                        casinoGamesStore.updateHomePageGames(result[0].casinoGames.slice(0, HOME_PAGE_GAMES_LENGTH));
                    }
                });
        }).finally(() => callback());
};

export const lastFindSame = (): boolean => {
    const {favorite, find, categories, provider} = casinoGamesStore.filteredParams;

    return casinoGamesStore.lastFind.favorite === favorite &&
        casinoGamesStore.lastFind.find === find &&
        arraySame(casinoGamesStore.lastFind.categories, categories) &&
        arraySame(casinoGamesStore.lastFind.provider, provider);
};

export const startGame = (game_id: number, filter = null): void => {
    if (casinoGamesStore.playGame({demo: false, filter, id: game_id})) {
        casinoGamesStore.openModal();
    }
};

export const startDemo = (game_id: number, filter = null): void => {
    if (casinoGamesStore.playGame({demo: true, filter, id: game_id})) {
        casinoGamesStore.openModal();
    }
};
