import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {SimpleArtifactImage} from 'app/components/my_profile/artifacts/image';
import {amountWithSign, timeStampToTimeShortFormat} from 'app/utils';
import {artifactName} from 'app/components/my_profile/artifacts/utils';
import PropTypes from 'prop-types';
import ShowMore from 'app/utils/ShowMore';

export default class LotsForSaleTable extends PureComponent {
    static propTypes = {hideHeader: PropTypes.bool};

    render() {
        const {data, has_more, handleLoadMore, cancelOrder, noLotsLabel} = this.props;
        return <div className="market__lots__items js-table">
            {!this.props.hideHeader && <div className="market__lots__items__heading tables-heading">
                <div className="market__lots__items__name-placed">
                    <div className="market__lots__items__name">{I18n.t('user.name')}</div>
                    <div className="market__lots__items__placed">{I18n.t('listed_on')}</div>
                </div>
                <div className="market__lots__items__price-actions">
                    <div className="market__lots__items__price">{I18n.t('price')}</div>
                    <div className="market__lots__items__actions"/>
                </div>
            </div>}

            {data.length > 0
                ? data.map((order, i) => <LotItem key={i} cancelOrder={cancelOrder} {...order}
                />)
                : <NoLots noLotsLabel={noLotsLabel}/>}

            <ShowMore onClickHandler={handleLoadMore} has_more={has_more}/>
        </div>;
    }
}

class NoLots extends PureComponent {
    render() {
        return <div className="market__lots__item market__lots__item-empty">
            {this.props.noLotsLabel || I18n.t('there_are_no_active_lots')}
        </div>;
    }
}

class LotItem extends PureComponent {
    handleCancelOrder = () => {
        const {artifact, id} = this.props;
        this.props.cancelOrder(artifact, id);
    };

    render() {
        const {total_price, created_at, artifact} = this.props;
        return <div className="market__lots__item">
            <div className="market__lots__item__name-placed">
                <div className="market__lots__item--img-wrap">
                    <div className="market__lots__item--img">
                        <SimpleArtifactImage
                            {...this.props}
                        />
                    </div>
                </div>
                <div className="market__lots__item--desc-placed">
                    <div className="market__lots__item--desc">
                        <div className="market__lots__item--desc-title">{artifactName(artifact)}</div>
                    </div>
                    <div className="market__lots__item__placed">{timeStampToTimeShortFormat(created_at)}</div>
                </div>
            </div>
            <div className="market__lots__item__price-actions">
                <div className="market__lots__item__price-value">{amountWithSign(total_price)}</div>
                <div className="market__lots__item__actions">
                    <button
                        className="btn--invert"
                        onClick={this.handleCancelOrder}
                    >{I18n.t('user.delete')}</button>
                </div>
            </div>
        </div>;
    }
}
