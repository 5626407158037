import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import AsideItem from 'site_version/com/AsideItem';
import AccordionItem from 'app/components/big_gains/AccordionItem';
import {inject, observer} from 'mobx-react';
import {CasinoGamesStore} from 'app/store/CasinoGamesStore';

interface Props {
    casinoGamesStore?: CasinoGamesStore
}

@inject('casinoGamesStore')
@observer
export default class CasinoWins extends Component<Props> {
    header = (): ReactNode => <div className="aside-item-heading__title">
        <h3>{I18n.t('big_gains')}</h3>
    </div>;

    render(): ReactNode {
        const {casinoGamesStore: {biggestWins}} = this.props;

        return <AsideItem header={this.header()} name="casino" itemClassName="big-gains">
            <div className="accordion-content">
                <div className="accordion-body">
                    <div className="accordion-list">
                        {biggestWins && biggestWins.map((el, index) => <AccordionItem key={index}
                            game_id={el.game_id}
                            name={el.name}
                            amount={el.amount}
                            bet_amount={el.bet_amount}
                            created_at={el.created_at}
                            provider_name={el.provider_name}
                            identifier={el.identifier}/>)}
                    </div>
                </div>
            </div>
        </AsideItem>;
    }
}
