import {FilterStore} from 'app/store/filters/FilterStore';
import GameInterface from 'app/interfaces/GameInterface';
import {prepareFiltersQuery} from 'app/utils/prepareFiltersQuery';
import {makeObservable, override} from 'mobx';
import getCookies from 'app/utils/getCookies';

export class BetsFilterStore extends FilterStore {
    constructor(games: GameInterface[]) {
        super('b', games);
        this.appliedFilters = getCookies(`${this.filterType}Filters`, []);
        makeObservable(this);
    }

    @override
    get queryNavigateParams(): Record<string, string> {
        const filters = prepareFiltersQuery(this.filters);

        return {
            b24h: this.only24h.toString(),
            bDisc: this.discipline,
            bEvent: this.currentEvents,
            bSport: filters,
            bType: this.betType,
            bUp: this.onlyUpcomingMatches.toString()
        };
    }
}

