import React, {ReactNode} from 'react';
import I18n from 'app/i18n';

interface Props {
    disabled?: boolean
    onClick: () => void
    onAllIn: () => void
    isAllIn: boolean
}

export default class PokerPanelButtonBetRaise extends React.Component<Props> {
    render(): ReactNode {
        const {onClick, onAllIn, isAllIn, disabled} = this.props;
        return <button
            className="button-adaptive button-adaptive_casino"
            disabled={disabled}
            onClick={isAllIn ? onAllIn : onClick}
        >
            {isAllIn ? I18n.t('poker_all_in') : I18n.t('poker_bet')}
        </button>;
    }
}
