import React, {PureComponent} from 'react';
import {timeStampToISODateFormat} from 'app/utils';
import I18n from 'app/i18n';
import PrizeSection from './sections/PrizeSection';
import {langsValue} from 'app/utils/langs/langsValue';

export default class UserAnswer extends PureComponent {
    answerClassNames = (defaultClasses = []) => {
        const classes = [].concat(defaultClasses);

        if (this.isCalculated()) {
            classes.push(this.isCorrectAnswer ? 'color-success' : 'color-fail');
        }
        return classes.join(' ');
    };

    get isCorrectAnswer() {
        const {rating_points} = this.props;
        return rating_points > 0;
    }

    correctAnswers = () => {
        const {correct_answers} = this.props;

        if (!this.isCalculated()) {
            return '\u2014';
        }

        return correct_answers.map(correctAnswer => langsValue(correctAnswer, 'answer')).join('; ') || '\u2014';
    };

    isCanceledByUser = () => this.isStateEq('canceled_by_user');

    isCanceledByAdmin = () => this.isStateEq('canceled') || this.isStateEq('canceled_by_admin');

    isActive = () => this.isStateEq('active');

    isCalculated = () => this.isStateEq('calculated');

    isStateEq = stateName => {
        const {state} = this.props;

        return state === stateName;
    };

    render = () => {
        const {date, prediction, answer, rating_points, recipes} = this.props;

        return <tr>
            <td className="prediction-date">{timeStampToISODateFormat(date)}</td>
            <td className="prediction-question">
                <div className="mobile-label">
                    {I18n.t('table.question_title')}
                </div>
                {langsValue(prediction, 'question')}
            </td>
            <td className="prediction-answer">
                <div className="mobile-label">
                    {I18n.t('table.answer_title')}
                </div>
                {langsValue(answer, 'answer')}
            </td>
            <td className={this.answerClassNames(['prediction-answer-correct'])}>
                <div className="mobile-label">
                    {I18n.t('table.correct_answers_title')}
                </div>
                {this.correctAnswers()}
            </td>
            <PrizeSection
                potentialPoints={answer.rating_points}
                rating_points={rating_points}
                isCorrectAnswer={this.isCorrectAnswer}
                isCalculated={this.isCalculated()}
                isActive={this.isActive()}
                isCanceledByUser={this.isCanceledByUser()}
                isCanceledByAdmin={this.isCanceledByAdmin()}
                recipes={recipes}
                prediction={prediction}
            />
        </tr>;
    };
}
