import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {SortingsInterface} from 'app/interfaces/stores/CasinoGamesStoreInterfaces';
import {casinoGamesStore} from 'app/store/global';
import FirstLevelItem from 'app/components/pages/casino/FirstLevelItem';

interface EmptyGamesProps {
    sortings?: SortingsInterface[]
    sortingValue: string
    onClick?: () => void
}

export default class FirstLevelSort extends PureComponent<EmptyGamesProps> {
    isActive = (value: string): boolean => {
        const {sortingValue} = this.props;
        return sortingValue === value;
    };

    changeSortActive = (value: string): void => {
        casinoGamesStore.changeSelectSorting(value);
    };


    render(): React.ReactNode {
        const {sortings, onClick} = this.props;
        return <div className="sorting-az">
            <span className="sorting-az__title">{I18n.t('sort_by')}</span>
            <div className="sorting-az__buttons" onClick={onClick}>
                {sortings && sortings.map(sorting => <FirstLevelItem value={sorting.value}
                    key={`sorting_first_${sorting.id}`}
                    className={`button-sorting-az ${this.isActive(sorting.value) ? 'active' : '' }`}
                    name={I18n.t(`sortings.${sorting.value}`)}
                />)}
            </div>
        </div>;
    }
}
