import React, {PureComponent} from 'react';
import ChatSmileItem from 'app/components/chat/chat_smile_item';

export default class ChatSmileItems extends PureComponent {
    get classNames() {
        const classNames = [];
        const {withBorder, smiles} = this.props;

        if (withBorder && smiles.length > 0) {
            classNames.push('chat__smile-list--recent');
        }
        return classNames.join(' ');
    }

    render() {
        const {smiles, insertSmile, isLoading} = this.props;

        return <div className={this.classNames}>
            {isLoading
                ? <div className="loader"/>
                : smiles.map(smile => <ChatSmileItem
                    key={smile}
                    smile={smile}
                    insertSmile={insertSmile}
                />)}
        </div>;
    }
}