import React, {ReactNode} from 'react';

interface Props {
    suit: string
    sign: string
}

export default class PokerCard extends React.Component<Props> {
    render(): ReactNode {
        const {suit, sign} = this.props;

        return <div className={`poker-card ${suit}`}>
            <span className="dignity">{sign}</span>
            <span className="suit">
                <svg className="svg-default">
                    <use xlinkHref={`#svg-card-${suit}`}/>
                </svg>
            </span>
        </div>;
    }
}
