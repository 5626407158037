import React, {Component} from 'react';
import I18n from 'app/i18n';
import ArticleInterface from 'app/interfaces/articles/ArticleInterface';
import PromoNewItem from 'app/components/pages/articles/PromoNewItem';
import {isDesktop} from 'app/utils';

interface PromoNewProps {
    articles: ArticleInterface[]
    svgNameIcon: string
    header: string
}

const COUNT_TWO = 2;

export default class PromoNew extends Component<PromoNewProps> {
    render(): React.ReactNode {
        const {articles} = this.props;
        const showArticlePlug = articles.length % COUNT_TWO !== 0;

        return articles.length > 0 && <>
            <div className="header-list-promo-articles">
                <span className="icon">
                    <svg className="svg-default">
                        <use xlinkHref={`#${this.props.svgNameIcon}`}/>
                    </svg>
                </span>
                <span className="name">{this.props.header}</span>
            </div>
            <div className="list-promo-articles">
                {articles.map(article => <PromoNewItem key={article.id} data={article}/>)}
                {isDesktop() && showArticlePlug && <div className="item-promo-article item-promo-article_plug">
                    <div className="description-promo-article">
                        <span className="title-promo-article_plug"></span>
                        <span className="text-promo-article_plug"></span>
                        <span className="description-promo-article_plug">{I18n.t('promo_new_text_plug')}</span>
                        <div className="labels-promo-article_plug">
                            <span className="label-promo-article_plug"></span>
                            <span className="label-promo-article_plug"></span>
                        </div>
                    </div>
                </div>}
            </div>
        </>;
    }
}
