import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import {IncubatorShiftController} from 'app/controllers/IncubatorShiftController';
import {IncubatorShiftStore} from 'app/store/IncubatorShiftStore';
import {modalConfirm} from 'app/utils/modals/popups';
import {betMoney, fixed2} from 'app/utils';
import IncubatorTopUpButton from 'app/components/pages/incubator/IncubatorTopUpButton';
import {IncubatorUserLinesStore} from 'app/store/IncubatorUserLinesStore';
import {IncubatorCurrentPledge} from 'app/components/pages/incubator/IncubatorCurrentPledge';
import {PERCENT_75} from 'app/utils/Consts';
import {UserStore} from 'app/store/UserStore';

interface Props {
    incubatorUserLinesStore?: IncubatorUserLinesStore
    shiftController: IncubatorShiftController
    incubatorShiftStore: IncubatorShiftStore
    userStore: UserStore
}

const USER_PROFIT = 0.0025;

@inject('incubatorUserLinesStore', 'incubatorShiftStore', 'shiftController', 'userStore')
@observer
export default class IncubatorShift extends Component {
    get controller(): IncubatorShiftController {
        const props = this.props as Props;
        return props.shiftController;
    }

    handleCloseShiftOk = (): void => {
        this.controller.closeShift();
    };

    get amount(): number {
        const {userStore, incubatorShiftStore} = this.props as Props;
        const {initial_bonus_balance, initial_pledge} = incubatorShiftStore.currentShift();
        const profit = incubatorShiftStore.currentShift()?.profit || 0;
        const acceptedBets = incubatorShiftStore.currentShift()?.acceptance_bets_rate ||
            userStore.user.incubator_bets_rate;
        let amount = Math.max(incubatorShiftStore.currentShift()?.pledge - initial_bonus_balance, 0);
        if (profit > 0) {
            amount = Number(amount) - profit + Number(profit * acceptedBets * USER_PROFIT);
        } else if (Number(profit) === 0.0) {
            amount = Number(initial_pledge);
        }

        return amount;
    }

    handleCloseShift = (): void => {
        const {incubatorUserLinesStore} = this.props as Props;
        const {amount} = this;

        if (incubatorUserLinesStore.hasUserLine()) {
            modalConfirm({
                element: <>
                    <div className="text-description">
                        <b>{I18n.t('incubator_has_user_line_title')}</b>
                    </div>
                    <br/>
                    <span>{I18n.t('incubator_has_user_line')}</span>
                </>,
                react: true
            }, null, null, {cancelShow: true, okText: I18n.t('confirm_button_ok')});
        } else {
            modalConfirm({
                element: <>
                    <div className="text-description">
                        <b>{I18n.t('incubator_close_shift_title')}</b>
                    </div>
                    <br/>
                    <span>{I18n.t('incubator_close_shift', {amount: betMoney({amount})})}</span>
                </>,
                react: true
            }, this.handleCloseShiftOk);
        }
    };

    hasShift = (): boolean => {
        const {incubatorShiftStore} = this.props as Props;

        return Boolean(incubatorShiftStore.currentShift());
    };

    render(): ReactNode {
        const {incubatorShiftStore, userStore} = this.props as Props;
        const {incubator_bets_rate} = userStore.user;
        const profit = incubatorShiftStore.currentShift()?.profit || 0;
        const acceptedBets = incubatorShiftStore.currentShift()?.acceptance_bets_rate || incubator_bets_rate;

        return <div className="wrapper-header-incubator-create-line">
            <div className="header-incubator-create-line">
                <div className="incubator-available-collateral">
                    <div className="incubator-profit__info">
                        <span className="name">{I18n.t('tooltip_incubator_available_pledge')}</span>
                        <b>
                            <IncubatorCurrentPledge
                                availablePledge={incubatorShiftStore.availablePledge()}
                                initialPledge={incubatorShiftStore.initialPledge()}
                                bonusBalance={incubatorShiftStore.currentShift()?.initial_bonus_balance}
                            />
                        </b>
                    </div>
                    <IncubatorTopUpButton controller={this.controller} message={I18n.t('topup_pledge_balance')}/>
                </div>
                <div className="incubator-profit-header">
                    <div className="incubator-profit__info">
                        <span className="name">{I18n.t('tooltip_incubator_profit_title')}</span>
                        <b className={`${Number(profit) >= 0 ? 'plus' : 'minus'}`}>${fixed2(profit)}</b>
                    </div>
                    <div className="incubator-profit__info">
                        <span className="name">{I18n.t('incubator_accepted_rate')}</span>
                        <b className={`${Number(acceptedBets) >= PERCENT_75 ? 'plus' : 'minus'}`}>
                            {`${acceptedBets}%`}
                        </b>
                    </div>
                    <button
                        className="button-adaptive button-adaptive_full"
                        disabled={!this.hasShift()}
                        onClick={this.handleCloseShift}>
                        {I18n.t('tooltip_incubator_close_shift')}
                    </button>
                </div>
            </div>
        </div>;
    }
}
