import React from 'react';
import I18n from 'app/i18n';

export default class TableHistoryHeadCasino extends React.Component {
    render():React.ReactNode {
        return <div className="table-content-head table-content-casino">
            <div className="table-content-cell">
                <span className="table-content-cell-name">{I18n.t('my_bets_casino_labels.game')}</span>
            </div>
            <div className="table-content-cell">
                <span className="table-content-cell-name">ID</span>
            </div>
            <div className="table-content-cell">
                <span className="table-content-cell-name">{I18n.t('my_bets_casino_labels.action')}</span>
            </div>
            <div className="table-content-cell">
                <span className="table-content-cell-name">{I18n.t('my_bets_casino_labels.amount')}</span>
            </div>
        </div>;
    }
}
