import {promoActionStore, userStore} from 'app/store/global';
import React from 'react';
import Flux from 'flux';
import EventEmitter from 'event-emitter';

export const DEFAULT_EVENTS = [];

export const DISCIPLINES = [
    {text: 'discipline_all', value: 'all'},
    {text: 'discipline_esport', value: 'esport'},
    {text: 'discipline_sport', value: 'sport'}
];

export const BET_TYPES = [
    {text: 'all', value: 'all'},
    {text: 'user_menu_live', value: 'live'},
    {text: 'single_bets', value: 'prematch'}
];

export const INCUBATOR_BET_TYPE = [{text: 'user_menu_live', value: 'live'}];

export const Dispatcher = new Flux.Dispatcher();
export const Store = {...EventEmitter.prototype,
    state: {
        common_promo_action_data: window.COMMON_PROMO_ACTION_DATA || {},

        faq: {data: [].concat(window.FAQ_DATA)},
        tournaments: {tournaments: []}
    },
    addListener(listener) {
        this.on('change', listener);
    },
    deleteListener(listener) {
        this.removeListener('change', listener);
    },
    streams() {
        return this.state.streams;
    },
    tryKey(key) {
        return typeof key === 'function' && key(this.state) || this[key] && this[key]() || this.state[key];
    },
    myBetsShowType() {
        return this.state.user_bets.showType;
    },
    userCountry(country_code) {
        const flag = window.REG_FLAGS.find(f => f.code === (country_code || userStore.user.country_code));
        return flag && flag.name;
    },
    userTradeLink() {
        return userStore.user.trade_link;
    },
    compendiumName() {
        return this.state.common_promo_action_data.name;
    },
    compendiumUrl() {
        return promoActionStore.compendium().url || this.state.common_promo_action_data.url;
    },
    compendiumStarted() {
        return this.compendiumUrl() !== -1 &&
            this.state.common_promo_action_data.promo_action_started;
    }};

Store.setMaxListeners(20);

Store.dispatchToken = Dispatcher.register(action => {
    Store.state = action(Store.state);
    Store.emit('change');
});

export function updateStore(action) {
    Dispatcher.dispatch(action);
}

export function updateSingleKeyStore(key, newState) {
    updateStore(state => ({
        ...state,
        [key]: {
            ...state[key],
            ...newState(state[key])
        }
    }));
}

export function updateKeyStore(key, newState) {
    updateStore(state => {
        const res = {};
        res[key] = {...state[key], ...typeof newState === 'function' ? newState(state) : newState};
        return Object.assign(state, res);
    });
}

export function forceUpdateKeyStore(key, newState) {
    updateStore(state => {
        const res = {};
        res[key] = typeof newState === 'function' ? newState(state) : newState;
        return {...state, ...res};
    });
}

export function createClassWithStore(key, WrappedComponent) {
    return class extends React.PureComponent {
        constructor(props) {
            super(props);
            this.state = Store.tryKey(key);
            this._onChange = this._onChange.bind(this);
        }

        _onChange() {
            this.setState(Store.tryKey(key));
        }

        componentDidMount() {
            Store.addListener(this._onChange);
        }

        componentWillUnmount() {
            Store.deleteListener(this._onChange);
        }

        render() {
            return <WrappedComponent {...this.props} {...this.state}/>;
        }
    };
}

export class ComponentStore extends React.PureComponent {
    constructor(key) {
        super();
        this.key = key;
        this.state = Store.tryKey(this.key);
        this._onChange = this._onChange.bind(this);
    }

    _onChange() {
        const storeState = Store.tryKey(this.key);

        if (storeState && typeof storeState === 'object' && Object.keys(storeState).length === 0) {
            this.forceUpdate();
        }
        this.setState(storeState);
    }

    componentDidMount() {
        Store.addListener(this._onChange);
    }

    componentWillUnmount() {
        Store.deleteListener(this._onChange);
    }
}
