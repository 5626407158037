import ChangedProfileFields from 'app/components/auth/ChangedProfileFields';
import {ReactNode} from 'react';

export default class ChangedDepositProfileFields extends ChangedProfileFields {
    fields = (): ReactNode[] => [
        this.rowEmail({...this.props}, true),
        this.rowPhone(),
        this.rowFirstName(),
        this.rowLastName(),
        this.rowCountryCode(),
        this.rowDayOfBirth()
    ];
}
