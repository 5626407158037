import React, {Component, ReactNode} from 'react';
import {goToRoute} from 'app/utils';
import {addGaEvent} from 'app/ga_methods';

interface FaqItemQuestionProps {
    title: string
    text: string
    name: string
    scroll: boolean
}

export default class FaqItemQuestion extends Component<FaqItemQuestionProps> {
    faqItemOpener: React.RefObject<HTMLDivElement> = React.createRef();

    state = {
        opened: false
    };

    openAnswer = (): void => {
        const opened = !this.state.opened;
        this.setState({opened});
        addGaEvent('FaqItemQuestion', opened ? 'closed' : 'opened');
        goToRoute(`/faq/${this.props.name}`, true);
    };

    isPageFaq = (): void => {
        const {pathname} = window.location;

        if (this.props.scroll && pathname.substring(pathname.lastIndexOf('/') + 1) === this.props.name) {
            this.faqItemOpener.current?.scrollIntoView();
            if (!this.state.opened) {
                this.setState({opened: true});
            }
        }
    };

    componentDidMount(): void {
        this.isPageFaq();
    }

    render(): ReactNode {
        const {title, text, name} = this.props;
        return <div className="faq-item-question" ref={this.faqItemOpener}>
            <div
                className="title-question"
                onClick={this.openAnswer}
                id={name}
            >
                <span>{title}</span>
            </div>
            <div className={`description-content-question${this.state.opened ? ' opened' : ''}`}>
                <div className="description-question" dangerouslySetInnerHTML={{__html: text}}/>
            </div>
        </div>;
    }
}
