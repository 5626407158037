import LineInterface from 'app/interfaces/LineInterface';
import {isAdvantageLine} from 'app/lines/isAdvantageLine';

export type GamerType = {
    nick: string
    flag: string
    race: string
    type: string
    game_name: string
    photo: {
        src: string
        type_on: boolean
    }
};

export const lineGamer = (line: LineInterface, gamerNum: number): GamerType => {
    const res = isAdvantageLine(line) ? line.parent : line;
    return res[`gamer_${isAdvantageLine(line) ? line.gamer : gamerNum}`];
};
