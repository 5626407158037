import React from 'react';
import {Link} from 'react-router-dom';
import I18n from 'app/i18n';
import {getLinkPath, betMoney, closeMenuAndScrollToTop} from 'app/utils';
import {restorePlayMoney} from './menu';
import UserBalancePoints from 'site_version/com/userBalancePoints';

export default class UserMenuBalance extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    bonusName = () => {
        const {bonuses} = this.props;

        if (!bonuses) {
            return '';
        }

        const [bonus] = bonuses;

        if (bonus) {
            switch (bonus.type) {
            case 'Bets_Advance':
                return I18n.t('bonus_types.bets_advance');
            case 'Casino_Advance':
                return I18n.t('bonus_types.casino_advance');
            default:
                return '';
            }
        }

        return '';
    };

    render() {
        const {new_year_theme} = window.settings.config;
        const bonus_name = this.bonusName();

        return <div className="balance">
            <div className="balance__heading">
                <div className="balance-user">
                    <span className="balance-user__icon">
                        <svg height="45px" width="45px">
                            <use xlinkHref={`#svg-account${new_year_theme ? '-santa' : ''}`}/>
                        </svg>
                    </span>
                    <span className="balance-user__name">{this.props.name}</span>
                </div>
                <div className="balance-status">
                    <div className="balance-status__item">
                        <div className="balance-status__item-label balance__label">{I18n.t('user_balance')}:</div>
                        <div
                            className="balance-status__item-value balance__value">
                            {betMoney({amount: this.props.balance})}
                        </div>
                    </div>
                    <UserBalancePoints
                        points= {betMoney({}, this.props.points, true, true)}
                    />
                    <div className="balance-status__item">
                        <div className="balance-status__item-label balance-playmoney__label">
                            {I18n.t('user_playmoney')}:
                        </div>
                        <div className="balance-status__item-value balance-playmoney__value">
                            {betMoney({}, this.props.play_money, false, true)}
                            <span className="balance-status__action">
                                <a
                                    href="#"
                                    onClick={restorePlayMoney}
                                    title={I18n.t('restore_play_money')}
                                >
                                    <svg height="17px" width="17px" id="prevent-close">
                                        <use xlinkHref="#svg-refresh" id="prevent-close"/>
                                    </svg>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="bonus-balance-status">
                    <div className="bonus-balance-status-label balance__label">
                        {I18n.t('user_bonus_balance')}{bonus_name ? ` (${bonus_name})` : ''}:
                    </div>
                    <div
                        className="bonus-balance-status-value bonus_balance__value">
                        {betMoney({amount: this.props.bonus_balance})}
                    </div>
                </div>
                <div className="bonus-balance-status">
                    <div className="bonus-balance-status-label balance__label">
                        {I18n.t('sum_of_active_bets')}:
                    </div>
                    <div
                        className="bonus-balance-status-value bonus_balance__value">
                        {betMoney({amount: this.props.active_bets_sum})}
                    </div>
                </div>
                <div className="balance__btns">
                    <Link
                        to={getLinkPath('/deposits')}
                        onClick={closeMenuAndScrollToTop}
                        className="btn"
                        dangerouslySetInnerHTML={{__html: I18n.t('user.deposit')}}
                    />
                    <Link
                        to={getLinkPath('/cashouts')}
                        onClick={closeMenuAndScrollToTop}
                        className="btn"
                        dangerouslySetInnerHTML={{__html: I18n.t('user.cashout')}}
                    />
                </div>
            </div>
        </div>;
    }
}
