import I18n from 'app/i18n';

export const translated = (object, key, translationsKey) => {
    const {langs, [key]: value} = object;
    const translations = translationsKey ? object[translationsKey] : langs;

    return translations && translations[I18n.locale] || value;
};

export const translatedKeyLangs = (object, key) => translated(object, key, `${key}_langs`);

export const translatedName = object => translated(object, 'name');

export const translatedDescription = object => {
    const {description_langs, description} = object;
    return description_langs && description_langs[I18n.locale] || description;
};
