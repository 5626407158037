import TimerInterface from 'app/interfaces/TimerInterface';

/* eslint-disable no-magic-numbers */
export const getValues = (countDown: number): TimerInterface => {
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(countDown % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
    const minutes = Math.floor(countDown % (1000 * 60 * 60) / (1000 * 60));
    const seconds = Math.floor(countDown % (1000 * 60) / 1000);

    return {days, hours, minutes, seconds};
};
