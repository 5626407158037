import {action, computed, makeObservable, observable} from 'mobx';
import $ from 'jquery';
import {getLinkPath} from 'app/utils/link_helper';

export class SellOrderStore {
    constructor() {
        makeObservable(this);
    }

    loadMoreLink = '/shop/marketplace/sell_orders';

    deleteLink(id) {
        return `/artifact_orders/${id}`;
    }

    @observable
        page = 0;

    @observable
        has_more = false;

    @observable
        data = [];

    @observable
        count = 0;

    @observable loading = true;

    @action
    updateStore({has_more, data, count}) {
        this.has_more = has_more;
        this.data = data;
        this.count = count;
    }

    @action updateStoreWithConcat({has_more, data, count}) {
        this.has_more = has_more;
        this.data = this.data.concat(data);
        this.count = count;
    }

    @action incrementPageNumber() {
        this.page += 1;
    }

    @action reset() {
        this.page = 0;
        this.data = [];
    }

    @action setLoadingTo(state) {
        this.loading = state;
    }

    @action loadMore() {
        return $.ajax(getLinkPath(this.loadMoreLink), {
            data: {page: this.page + 1},
            success: response => {
                this.updateStoreWithConcat(response);
                this.setLoadingTo(false);
            }
        }).done(this.incrementPageNumber.bind(this));
    }

    @action delete(orderId) {
        return $.ajax(getLinkPath(this.deleteLink(orderId)), {
            data: {page: this.page},
            success: response => this.updateStore(response),
            type: 'DELETE'
        });
    }

    @computed get asJson() {
        return {
            count: this.count,
            data: this.data,
            has_more: this.has_more
        };
    }
}
