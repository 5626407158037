import React, {useCallback, useRef, useState} from 'react';
// @ts-ignore
import Mailcheck from 'mailcheck';
import I18n from 'app/i18n';
import {getLinkPath} from 'app/utils';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {modalAlert} from '../utils/modals/popups';
import {Auth} from './auth/auth_popup';
import Post from 'app/utils/Post';
import {modalsStore} from 'app/store/global';

const InvalidEmail = (props: {popupId: string}): React.ReactElement => {
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const emailRef = useRef(null);

    const resetEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (submitting) {
            return;
        }

        setError(null);
        setSubmitting(true);

        new Post({params: {email: emailRef.current.value}, url: '/user/email'})
            .execute()
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    setSubmitting(false);
                    modalsStore.hideModal(props.popupId);
                    modalAlert(`${I18n.t('confirm_email') } ${ emailRef.current.value}`,
                        {okClick: window.location.href = getLinkPath('/play')});
                } else {
                    setError(response.error);
                    setSubmitting(false);
                }
            });
    };

    const handleResetEmail = useCallback((e: React.FormEvent<HTMLFormElement>) => resetEmail(e), [resetEmail]);

    const mailCheck = () => {
        Mailcheck.run({
            email: emailRef.current.value,
            suggested: suggestion => {
                setError(`${I18n.t('mailcheck') } ${ suggestion.full}`);
            }
        });
    };

    const handleMailCheck = useCallback(() => mailCheck(), [mailCheck]);

    const clearFieldError = (e: React.KeyboardEvent<HTMLInputElement>) => {
        Auth.clearFiledError({setState: setError}, e);
    };

    const handleClearFieldError = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => clearFieldError(e), [clearFieldError]);

    return (
        <PopupDialog id={props.popupId} setFocus>
            <div className="popup__content">
                <form className={`popup-form ${error ? 'popup-form--error' : ''}`} onSubmit={handleResetEmail}>
                    <div className="popup-form__row">{I18n.t('invalid_email_confirm')}</div>
                    <div className="popup-form__error-hint">{error}</div>
                    <div className="popup-form__row">
                        <label htmlFor="field-reset-email">{I18n.t('email')}</label>
                        <div className={`field ${error ? ' field-error' : ''}`}>
                            <input
                                ref={emailRef}
                                id="field-reset-email"
                                name="egb_user[email]"
                                className="field__input"
                                type="email"
                                onKeyUp={handleClearFieldError}
                                onBlur={handleMailCheck} />
                        </div>
                    </div>
                    <div className="popup-form__submit-row">
                        <input type="submit" className="btn btn--large" disabled={submitting} value={I18n.t('user.save')} />
                    </div>
                </form>
            </div>
        </PopupDialog>
    );
};

export default InvalidEmail;
