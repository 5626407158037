import React, {Component} from 'react';
import SmartStory from './SmartStory';
import {inject, observer} from 'mobx-react';
import {StoriesStoreProps} from 'app/store/StoriesStore';
import 'swiper/swiper.scss';
import {Swiper, SwiperSlide} from 'swiper/swiper-react';

const swiperParams = {
    allowTouchMove: true,
    direction: 'horizontal' as const,
    spaceBetween: 5
};

@inject('storiesStore')
@observer
export default class SmartStories extends Component<StoriesStoreProps> {
    render(): React.ReactNode {
        const {storiesStore} = this.props;
        const {stories} = storiesStore.data;
        return storiesStore.hasStories()
            ? <Swiper className="smart-stories" {...swiperParams} slidesPerView="auto">
                {stories.map(s => <SwiperSlide key={s.id} ><SmartStory story={s}/></SwiperSlide>)}
            </Swiper>
            : null;
    }
}
