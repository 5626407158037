import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {showPopup} from 'app/utils/modals/ModalsService';
import UploadedDocument from './UploadedDocument';
import UploadButton from './UploadButton';
import Get from 'app/utils/Get';
import SmartLoader from 'app/components/reusable/SmartLoader';

const MAX_DOCUMENTS_COUNT = 2;

export default class DocumentsUpload extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            documents: [],
            loaded: false
        };
    }

    componentDidMount() {
        new Get({
            params: {
                created_for_id: this.props.created_for_id,
                created_for_type: this.props.created_for_type
            },
            url: '/verified_documents'
        }).execute()
            .then(response => response.json())
            .then(res => this.setState({documents: res}))
            .finally(() => this.setState({loaded: true}));
    }

    maxDocumentsLoaded = () => this.state.documents.length === this.maxDocuments();

    maxDocuments = () => this.props.max_documents || MAX_DOCUMENTS_COUNT;

    popupText = () => this.props.text || I18n.t('verify_from.base_text');

    onUpload = documents => this.setState({documents});

    render() {
        return <PopupDialog id={this.props.popupId}>
            <div className="popup__content simple-popup--big">
                <div dangerouslySetInnerHTML={{__html: this.popupText()}}/>
                <br/>
                {this.state.loaded
                    ? <div style={{minHeight: '4em'}}>
                        {this.state.documents.map((d, i) => <UploadedDocument key={d.id} document={d} index={i}/>)}
                    </div>
                    : <SmartLoader />}
                <UploadButton
                    created_for_id={null}
                    created_for_type={null}
                    maxDocumentsLoaded={this.maxDocumentsLoaded()}
                    onUpload={this.onUpload}
                />
            </div>
        </PopupDialog>;
    }
}

export const showDocumentsUploadPopup = () => {
    showPopup('verify-window', props => <DocumentsUpload popupId={props.popupId}/>);
};

const handleClickUserNav = e => {
    e.preventDefault();
    showDocumentsUploadPopup();
};

export const uploadDocumentsLink = () => <a className="user-nav__item" href="#" onClick={handleClickUserNav}>
    <span className="user-nav__icon">
        <svg height="18px" width="16px"><use xlinkHref="#svg-upload-file"/></svg>
    </span>
    <span>{I18n.t('user.upload_documents')}</span>
</a>;

export const handleShowingDocumentsUploader = e => {
    e && e.preventDefault();
    showPopup('verify-window', props => <DocumentsUpload popupId={props.popupId}/>);
};
