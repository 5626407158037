import $ from 'jquery';
import React from 'react';
import CoefButton from 'app/components/table/coef_button';
import AmountBetForm from './amount_bet_form';
import BetMaker, {isPlayMoney} from './bet_maker';
import Form from './form';
import {inject, observer} from 'mobx-react';
import {
    isFinished, isLineGamerWinner, isLinesBet, isLoserClass, isOpened,
    lineCoef, lineFormResultTitle, lineFullNick, lineGamer, lineLimit, lineNickWithParent, lineScores, STATUS_OPENED
} from 'app/lines/line_helpers';
import {isParentRootLine} from 'app/utils/line/rootLine';
import {isMainLine} from 'app/lines/isMainLine';
import {isNestedLine} from 'app/lines/isNestedLine';
import {isAdvantageLine} from 'app/lines/isAdvantageLine';
import {isHandicapLine} from 'app/lines/isHandicapLine';
import {showBetSlip} from 'app/components/table';

const GAMER_1 = 1,
    GAMER_2 = 2;

const multiple_limit = that => {
    if (!that.props.bet.multiple_bet_limit_enabled) {
        return null;
    }
    return that.state.playmoney
        ? that.props.bet.play_money_multiple_limit
        : that.props.bet.real_money_multiple_limit;
};

@inject('lineStore', 'artifactBetStore')
@observer
export default class Rows extends BetMaker {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            isLoading: true,
            userBetsHidden: true
        };
    }

    rootElement = React.createRef();

    currentGamer() {
        return lineGamer(this.props.gamerNum);
    }

    selectGamer(gamerNum, e) {
        e.preventDefault();
        const {active, bet} = this.props;

        if (active || bet.incubator_2) {
            showBetSlip(bet, gamerNum, {selectedBet: bet});
        }
    }

    componentDidMount() {
        if (this.props.isHidden && !this.props.show) {
            $(this.rootElement.current).slideUp();
        }
        this.setState({isLoading: false});
    }

    componentDidUpdate(prevProps) {
        if (!this.props.show && prevProps.isHidden !== this.props.isHidden) {
            if (this.props.isHidden) {
                $(this.rootElement.current).slideUp();
            } else {
                $(this.rootElement.current).slideDown();
            }
        }
    }

    infoBets = () => {
        if (!this.props.gamerNum) {
            return;
        }

        const coef = lineCoef(this.props.bet, this.props.gamerNum);
        this.makeBet(coef);
    };

    coefButton(num) {
        const {bet, gamerNum, active} = this.props;
        let className = 'bet-rate js-bet-rate cursor-pointer';
        className += active ? '' : ' bet-rate--unavailable';
        className += gamerNum === num &&
        this.isBetEqualToSelected() &&
        bet.status === STATUS_OPENED
            ? ' active'
            : '';

        className += isLineGamerWinner(bet, num) ? ' table-bets__winner' : '';
        className += isLoserClass(bet, num);
        return <CoefButton
            changingColorByCoef={true}
            coef={lineCoef(bet, num)}
            className={className}
            onClick={this.selectGamer.bind(this, num)}
        />;
    }

    userBetsClick = () => {
        this.setState({userBetsHidden: !this.state.userBetsHidden});
    };

    hasNestedBetsType() {
        return Boolean(this.props.bet.gamer_1.type);
    }

    isBetEqualToSelected = () => this.props.selectedBet === Form.betId(this.props.bet);

    renderForm(elements, gamer_num) {
        if (this.isBetEqualToSelected() &&
            (isAdvantageLine(this.props.bet) ||
            isHandicapLine(this.props.bet) ||
                this.hasNestedBetsType()
                ? this.props.gamerNum === gamer_num
                : true)) {
            elements.push(<AmountBetForm seo={this.props.seo}
                key={`form-${this.props.bet.id}${gamer_num}${isAdvantageLine(this.props.bet)}`}
                {...this.formParams()}/>);
        }
    }

    nestedLineText() {
        const line = this.props.bet;
        const {parentLine} = this.props;

        return isParentRootLine(parentLine)
            ? lineGamer(line, 1).nick
            : lineNickWithParent(line, parentLine, 1);
    }

    renderBet() {
        const line = this.props.bet;
        const elements = [
            <div key={`head-${line.id}`} className="match-details__item-row">
                <div className="match-details__row-title">
                    {isNestedLine(line) ? this.nestedLineText(line) : null}
                </div>
                <div className="match-details__col-holder">
                    <div className="match-details__col">
                        {this.coefButton(GAMER_1)}
                    </div>
                    <div className="match-details__col">
                        {this.coefButton(GAMER_2)}
                    </div>
                </div>
            </div>
        ].concat(this.props.children);
        this.renderForm(elements);
        return elements;
    }

    renderBetType() {
        const elements = [
            <div key={`id-${this.props.bet.id}-gamer-${GAMER_1}`}
                className="match-details__item-row match-details__player-separator">
                <div className={`match-details__row-title${isLoserClass(this.props.bet, GAMER_1)}`}>
                    {lineFullNick(this.props.bet, GAMER_1)}
                </div>
                <div className="match-details__col-holder">
                    <div className="match-details__col"/>
                    <div className="match-details__col">
                        {this.coefButton(GAMER_1)}
                    </div>
                    <div className="match-details__col"/>
                </div>
            </div>
        ];
        this.renderForm(elements, GAMER_1);
        elements.push(<div key={`id-${this.props.bet.id}-gamer-${GAMER_2}`} className="match-details__item-row">
            <div className={`match-details__row-title${isLoserClass(this.props.bet, GAMER_2)}`}>
                {lineFullNick(this.props.bet, GAMER_2)}
            </div>
            <div className="match-details__col-holder">
                <div className="match-details__col"/>
                <div className="match-details__col">
                    {this.coefButton(GAMER_2)}
                </div>
                <div className="match-details__col"/>
            </div>
        </div>);
        this.renderForm(elements, GAMER_2);
        return elements;
    }

    renderAdvantageOrHandicap() {
        const {type, gamer} = this.props.bet;
        const elements = [
            <div key={`id-${this.props.bet.id}-gamer-${GAMER_1}`}
                className="match-details__item-row match-details__player-separator">
                {this.renderAdvantageGamers(type ? GAMER_1 : gamer, GAMER_1)}
            </div>
        ];
        this.renderForm(elements, GAMER_1);
        elements.push(<div key={`id-${this.props.bet.id}-gamer-${GAMER_2}`} className="match-details__item-row">
            {this.renderAdvantageGamers(type ? GAMER_2 : gamer, GAMER_2)}
        </div>);
        this.renderForm(elements, GAMER_2);
        return elements;
    }

    renderAdvantageGamers(currentGamer, on) {
        return [
            <div key={`title-id-${this.props.bet.id}-gamer-${currentGamer}-on-${on}`}
                className={`match-details__row-title${isLoserClass(this.props.bet, on)}`}>
                <div className="match-details__row-title__advantage"/>
                {lineFullNick(this.props.bet, on, this.props.parentLine)}
            </div>, <div key={`coef-id-${this.props.bet.id}-gamer-${currentGamer}-on-${on}`}
                className="match-details__col-holder">
                <div className="match-details__col">
                    {currentGamer === GAMER_1 ? this.coefButton(on) : null}
                </div>
                <div className="match-details__col">
                    {currentGamer === GAMER_2 ? this.coefButton(on) : null}
                </div>
            </div>
        ];
    }

    switchTab = amount_bet_type => {
        this.props.artifactBetStore.resetArtifacts();
        this.updateAmount(null);
        this.setState({amount_bet_type});
    };

    updateAmount = amount => this.setState({amount});

    formParams() {
        const line = this.props.bet;
        const {parentLine, gamerNum} = this.props;
        const betFormAmountParams = {
            amount: this.state.amount,
            amount_bet_type: this.state.amount_bet_type,
            classNamePrefix: 'match-details',
            date: isAdvantageLine(line) ? parentLine.date : line.date,
            description: isMainLine(line) ? null : line.text,
            gamerNum: this.props.gamerNum,
            hideDescription: !isNestedLine(line),
            live: line.live,
            loading: this.state.loading,
            multiple_bet_limit: multiple_limit(this),
            multiple_bet_limit_enabled: line.multiple_bet_limit_enabled,
            scores: lineScores(isAdvantageLine(line) ? parentLine : line),
            status: line.status,
            submitHandler: this.infoBets,
            switchTab: this.switchTab,
            type: line.type,
            updateAmount: this.updateAmount,
            winner: line.winner
        };

        if (gamerNum) {
            betFormAmountParams.title = isFinished(line.status)
                ? null
                : lineNickWithParent(line, parentLine, gamerNum);

            betFormAmountParams.coef = Number(lineCoef(line, gamerNum));
            betFormAmountParams.limit = Number(lineLimit(line, isPlayMoney(this.state), gamerNum));
        }
        if (!isOpened(line.color)) {
            betFormAmountParams.title = lineFormResultTitle(
                line,
                parentLine,
                isAdvantageLine(line) ? line.gamer : this.props.gamerNum
            );
        }
        return {
            betId: line.id,
            userBets: this.props.userBets.filter(userBet => isLinesBet(line, userBet)),
            userBetsClick: this.userBetsClick,
            userBetsHidden: this.state.userBetsHidden,
            ...betFormAmountParams
        };
    }

    renderByType() {
        const line = this.props.bet;

        if (isAdvantageLine(line) || isHandicapLine(line)) {
            return this.renderAdvantageOrHandicap();
        }

        return !isAdvantageLine(line) && lineGamer(line, GAMER_1).type ? this.renderBetType() : this.renderBet();
    }

    render() {
        const classForm = `match-details__item js-match${this.props.show ? ' js-match-active' : ''}`;
        return <div ref={this.rootElement} className={classForm}>
            {this.state.isLoading ? <></> : this.renderByType()}
        </div>;
    }
}
