import React, {Component} from 'react';
import I18n from 'app/i18n';
import Pagination from 'app/components/pagination';
import {Loader} from './common_components';
import Preview from 'app/components/pages/articles/Preview';
import SearchInput from 'app/components/SearchInput';
import EmptyResults from 'app/components/articles/EmptyResults';
import {inject, observer} from 'mobx-react';
import {locationQuery} from 'app/utils/navigate/location';
import {KEY_ENTER} from 'app/utils/Consts';

@inject('articlesStore')
@observer
export default class BlogIndex extends Component {
    constructor(props) {
        super(props);
        this.props.articlesStore.init(locationQuery, 'blog');
    }

    searchHandler = event => {
        if (event.key === KEY_ENTER) {
            this.loadData(null, event.target.value);
        }
    };

    changeHandler = event => {
        this.props.articlesStore.setSearchValue(event.target.value, 'blog');
    };

    loadData = (params, searchValue = '') => {
        this.props.articlesStore.loadData(params, searchValue, 'blog');
    };

    render() {
        const {data, loading, paginate, searchValue} = this.props.articlesStore.blogData;
        return loading
            ? <Loader/>
            : <>
                <div className="content-header">
                    <h1 className="title-h1" key="title">{I18n.t('menu_links.blog')}</h1>
                    <SearchInput
                        handleChange={this.changeHandler}
                        handleKeyPress={this.searchHandler}
                        value={searchValue}/>
                </div>
                <div className="content__holder content__holder--no-paddings">
                    {data.length > 0
                        ? data.map(blog => <Preview key={blog.id} data={blog} type="blog"/>)
                        : <EmptyResults/> }
                </div>
                <Pagination {...paginate} searchValue={searchValue} switchPage={this.loadData} key="pagination"/>
            </>;
    }
}
