import LineInterface from 'app/interfaces/LineInterface';
import {isHandicapLine} from 'app/lines/isHandicapLine';
import {isAdvantageLine} from 'app/lines/isAdvantageLine';
import {lineGamer} from 'app/lines/line_helpers';

const LESS_THEN = -1;
const GREATER_THEN = 1;

const sortDateScoreId = (a: LineInterface, b: LineInterface): number => {
    if (isHandicapLine(a) && isHandicapLine(b)) {
        if (lineGamer(a, 1).game_name === lineGamer(b, 1).game_name) {
            return a.score === b.score ? a.id - b.id : a.score - b.score;
        }

        return GREATER_THEN;
    }

    if (a.date === b.date) {
        return a.score === b.score ? a.id - b.id : a.score - b.score;
    }
    return b.date - a.date;
};

const nestedBetsSort = (lines: {[key: number]: LineInterface}): LineInterface[] => Object
    .values(lines).sort((a, b) => {
        const haFirst = handicapAdvantageFirst(a, b);

        if (haFirst !== 0) {
            return haFirst;
        }
        if (a.color === b.color) {
            if (a.priority === b.priority) {
                return sortDateScoreId(a, b);
            }
            return b.priority - a.priority;
        }
        return a.color - b.color;
    });

const handicapAdvantageFirst = (a, b) => {
    if (isHandicapLine(a) && !isHandicapLine(b)) {
        return LESS_THEN;
    }
    if (!isHandicapLine(a) && isHandicapLine(b)) {
        return GREATER_THEN;
    }
    if (isAdvantageLine(a) && !isAdvantageLine(b)) {
        return LESS_THEN;
    }
    if (!isAdvantageLine(a) && isAdvantageLine(b)) {
        return GREATER_THEN;
    }
    return 0;
};

export default nestedBetsSort;
