import React, {Component, ReactNode} from 'react';
import AsideItem from 'site_version/com/AsideItem';
import {inject, observer} from 'mobx-react';
import {ContentInterface, PageContentStore} from 'app/store/PageContentStore';

interface Props {
    pageContentStore?: PageContentStore
    headingClick?: boolean
}

@inject('pageContentStore')
@observer
export default class MainPageDescription extends Component<Props> {
    header = (content: ContentInterface): ReactNode => <div className="aside-item-heading__title">
        <h3 dangerouslySetInnerHTML={{__html: content.why_egb_heading}}/>
    </div>;

    render(): ReactNode {
        const content = this.props.pageContentStore.content || {};

        return <AsideItem
            header={this.header(content)}
            name="description"
            itemClassName="main-page-description"
            headingClick={this.props.headingClick}
            asideItemClosed
        >
            <div className="page-description">
                <div className="page-description__content" dangerouslySetInnerHTML={{__html: content.why_egb_content}}/>
            </div>
        </AsideItem>;
    }
}
