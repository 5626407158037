import React, {Component} from 'react';
import {Store} from 'app/store';
import ShopItems from 'app/components/shop/lootboxes/shop_items';
import I18n from 'app/i18n';
import {goToRoute} from 'app/utils/link_helper';
import {isUSD} from 'app/utils/ShopItemPriceFormat';
import ShopItemInterface from 'app/interfaces/shop/ShopItemInterface';
import {ShopTableInterface} from 'app/interfaces/ShopTableInterface';
import withRouter, {withRouterInterface} from 'app/utils/navigate/customWithRouter';
import {inject, observer} from 'mobx-react';
import SelectPicker from 'app/components/reusable/SelectPicker';
import CollectionInterface from 'app/interfaces/CollectionInterface';

const updateShop = (): void => Store.updateShop();

interface ShopTableState {
    currency: string
}

class ShopTableOtherItems extends Component<ShopTableInterface, ShopTableState> {
    state = {
        currency: ''
    };

    get newCurrencies() {
        return [{text: 'USD', value: 'usd'}, {text: I18n.t('user_points'), value: 'points'}];
    }

    get currencies() {
        return this.newCurrencies.map((currency): CollectionInterface => ({
            text: currency.text,
            value: currency.value
        }));
    }

    get currency() {
        return new URLSearchParams(location.search).get('currency');
    }


    constructor(props: ShopTableInterface) {
        super(props);

        this.state = {
            currency: this.currency || 'usd'
        };
    }

    handleChangeCurrency = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        const currency = e.target.value;
        this.setState({currency});
        const {location: {pathname}} = this.props as ShopTableInterface & withRouterInterface;
        goToRoute(`${pathname}?currency=${currency || ''}`);
    };

    changeNameFilter = (key: string): string => {
        if (key === '') {
            return I18n.t('new_lootboxes_header_shop');
        }

        return key;
    };

    get items(): ShopItemInterface[] {
        return this.props.shopStore.data.other_items;
    }


    get filteredByCurrency(): ShopItemInterface[] {
        return this.getItems();
    }

    getItems = (): ShopItemInterface[] => this.items &&
         this.items.filter(item => Number(item[isUSD(this.state.currency) ? 'cost_money' : 'cost_points']) > 0);

    render(): React.ReactNode {
        return <div className="market-lootboxes">
            <div className="tabs js-tabs tabs--lots">
                <div className="tabs__content">
                    <div className="tabs__content-item">
                        <div className="tabs js-tabs tabs--secondary">
                            <div className="tabs__content">
                                <div className="tabs__content--filters">
                                    <div className="select-currency classical-select field__select">
                                        <SelectPicker
                                            id="currency_select"
                                            onChange={this.handleChangeCurrency}
                                            value={this.state.currency}
                                            collection={this.currencies}
                                        />
                                    </div>
                                </div>
                                <div className="tabs__content-item">
                                    <div className="market__lootboxes">
                                        <ShopItems
                                            currency={this.state.currency}
                                            user={this.props.shopStore.data.user}
                                            lootboxes={this.filteredByCurrency}
                                            noChunks
                                            afterBuy={updateShop}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default withRouter(inject('shopStore')(observer(ShopTableOtherItems)));
