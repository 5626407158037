import React, {PureComponent} from 'react';
import {ArtifactImage} from 'app/components/my_profile/artifacts/image';

export default class TradeItem extends PureComponent {
    render() {
        return <div className="hero__slot">
            <ArtifactImage
                artifact={this.props.artifact}
                showHeroSlotCount
            />
        </div>;
    }
}