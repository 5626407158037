import React from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {Params} from 'react-router';

export interface withRouterInterface {
    location: Location
    params: Params
}
const withRouter = <Props, >(
    WrappedComponent: React.ComponentType<Props>
): React.FC<Props> => (props: Props): React.ReactElement<Props & withRouterInterface> => {
        const location = useLocation();
        const params = useParams();

        return <WrappedComponent {...props} location={location} params={params} />;
    };


export default withRouter;

