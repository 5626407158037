import ToastContent, {ToastContentProps} from 'app/components/toasts/Contents/ToastContent';
import CashoutInterface from 'app/interfaces/CashoutInterface';
import {cashoutsStore} from 'app/store/global';
import React, {Fragment} from 'react';
import I18n from 'app/i18n';
import {modalConfirm} from 'app/utils/modals/popups';
import {confirmCashoutMessage, messageInfo} from 'app/utils/p2p/confirmCashoutMessage';

interface PropsInterface extends ToastContentProps {
    cashout: CashoutInterface;
}

export default class P2pCashoutConfirmContent extends ToastContent<PropsInterface> {
    state = {disabled: false};

    componentDidMount(): void {
        const urlParams = new URLSearchParams(window.location.search);

        if (Number(urlParams.get('confirm_payment')) === this.props.cashout.id) {
            setTimeout(this.handleConfirm, 0);
        }
        if (Number(urlParams.get('invalidate_payment')) === this.props.cashout.id) {
            setTimeout(this.handleInvalidate, 0);
        }
    }

    handleConfirm = (): void => {
        this.setState({disabled: true});
        modalConfirm(
            confirmCashoutMessage(this.props.cashout),
            () => cashoutsStore.confirm(this.props.cashout.id)
                .then(this.props.closeToast)
                .finally(() => this.setState({disabled: false})),
            () => this.setState({disabled: false})
        );
    };

    handleInvalidate = (): void => {
        this.setState({disabled: true});
        modalConfirm(I18n.t('if_transfer_not_received'),
            () => cashoutsStore.invalidate(this.props.cashout.id)
                .then(this.props.closeToast)
                .finally(() => this.setState({disabled: false})),
            () => this.setState({disabled: false}));
    };

    render(): React.ReactNode {
        const {cashout} = this.props;
        const {disabled} = this.state;
        return <Fragment>
            <div className="Toastify__body-content">
                <div className="Toastify__notification">
                    <div className="Toastify__notification__icon">
                        <svg width="20px" height="20px">
                            <use xlinkHref="#svg-notification-dollar"/>
                        </svg>
                    </div>
                    <div className="Toastify__notification__content">
                        <div className="Toastify__notification__content--text" dangerouslySetInnerHTML={{__html:
                            I18n.t('did_the_cashout_go_to_your_wallet', messageInfo(cashout))}}
                        />
                    </div>
                </div>
            </div>
            <div className="Toastify__group-buttons">
                <button className="button-notification button-notification_no"
                    onClick={this.handleInvalidate}
                    disabled={disabled}>
                    {I18n.t('no')}
                </button>
                <button className="button-notification button-notification_yes"
                    onClick={this.handleConfirm}
                    disabled={disabled}>
                    {I18n.t('confirm_button_yes')}
                </button>
            </div>
        </Fragment>;
    }
}
