import LineInterface from 'app/interfaces/LineInterface';
import {isNestedLine} from 'app/lines/isNestedLine';
import {linesService} from 'app/store/global';

export const hasNestedBetWithUserBet = (line: LineInterface): boolean => {
    if (isNestedLine(line)) {
        return false;
    }

    const root = linesService.user_bets && linesService.user_bets.root;
    return root && Boolean(root.some(r => r === line.id));
};
