import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {userBetsStore} from 'app/store/global';
import TableHistoryHeadCasino from 'app/components/my_profile/table-history/TableHistoryHeadCasino';
import TableHistoryRowCasino from 'app/components/my_profile/table-history/TableHistoryRowCasino';
import UserCasinoLogInterface from 'app/interfaces/casino/CasinoLogInterface';
import EmptyResults from 'app/components/articles/EmptyResults';
import TableHistoryStubPage from 'app/components/my_profile/table-history/TableHistoryStubPage';
import SmartLoader from 'app/components/reusable/SmartLoader';
import {inject, observer} from 'mobx-react';

interface Props {
    logs: UserCasinoLogInterface[]
    hasBets: boolean
    has_more: boolean
}

@inject('userBetsStore')
@observer
export class CasinoLogs extends Component<Props> {
    componentDidMount(): void {
        userBetsStore.loadCasinoLogs();
    }

    loadMoreLogs = (): void => {
        userBetsStore.loadCasinoLogs(true);
    };

    render(): ReactNode {
        const {logs, has_more} = this.props;
        return this.props.hasBets
            ? <><div className="table-content">
                <TableHistoryHeadCasino/>
                {userBetsStore.loading
                    ? <SmartLoader/>
                    : <div className="table-content-body table-content-casino">
                        {logs && logs.length > 0 && logs.map(log => <TableHistoryRowCasino
                            key={`user_casino_log-${log.id}`}
                            id={log.id}
                            created_at={log.created_at}
                            game={log.game}
                            action={log.action}
                            amount={log.amount}
                            image={log.image}
                        />)}
                    </div>}
            </div>
            {logs && logs.length > 0 && !userBetsStore.loading
                ? has_more && <div className="table-more">
                    <button
                        className="cursor-pointer"
                        onClick={this.loadMoreLogs}
                    >
                        {I18n.t('user.show_10_more')}
                    </button>
                </div>
                : <EmptyResults/>}</>
            : <TableHistoryStubPage route={'/casino'}/>;
    }
}
