import React, {Fragment, useEffect} from 'react';
import {scrollByHash} from 'app/utils';
import {inject} from 'mobx-react';
import {observer} from 'mobx-react-lite';
import {PageContent} from 'app/components/page_content';
import SmartLoader from 'app/components/reusable/SmartLoader';
import {UserBonusesStore} from 'app/store/UserBonusesStore';
import {PageContentStore} from 'app/store/PageContentStore';
import FreespinsContent from 'app/components/my_profile/freespins/FreespinsContent';

interface MyFreeSpinsPageInterface {
    userBonusesStore?: UserBonusesStore
    pageContentStore?: PageContentStore
}

const MyFreeSpinsPage: React.FC<MyFreeSpinsPageInterface> = props => {
    useEffect(() => {
        scrollByHash();
    }, []);

    const {userBonusesStore: {bonusesLoading,
        bonusDataLoading}} = props;
    return <>
        {bonusDataLoading || bonusesLoading
            ? <SmartLoader/>
            : <>
                <div className="freespins profile-tab">
                    <FreespinsContent/>
                    <PageContent
                        className="page-content-description"
                        contentUrl="/my_profile/freespins" contentHolder contentKey="my_freespins" onlyOnce>
                        <div className="content__holder" dangerouslySetInnerHTML={{__html: ''}}/>
                    </PageContent>
                </div>
            </>}
    </>;
};
export default inject('pageContentStore', 'userBonusesStore')(observer(MyFreeSpinsPage));
