import React, {Component, ReactNode, ReactElement} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import {STATUS_DRAW} from 'app/lines/line_helpers';
import {isClosedLine} from 'utils/line_helpers';
import {IncubatorCreateLineController} from 'app/controllers/IncubatorCreateLineController';
import {isDesktop} from 'app/utils';
import IncubatorLineLeftWinner from 'app/components/pages/incubator/line/IncubatorLineLeftWinner';
import IncubatorLineRightWinner from 'app/components/pages/incubator/line/IncubatorLineRightWinner';
import IncubatorCancelCreateLineButton from 'app/components/pages/incubator/IncubatorCancelCreateLineButton';
import IncubatorVolumeButton from 'app/components/pages/incubator/IncubatorVolumeButton';

interface Props {
    controller?: IncubatorCreateLineController
}

@inject('controller')
@observer
export default class IncubatorLineFormPanel extends Component<Props> {
    get controller(): IncubatorCreateLineController {
        return this.props.controller;
    }

    handleChangeWinner = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.controller.onChangeWinner(e.target.value);
    };

    handleClickWinner = (e: React.MouseEvent<HTMLInputElement>): void => {
        this.controller.onClickDraw(e.target as HTMLInputElement);
    };

    radioDeadheat = (): ReactElement => {
        const {attributes} = this.controller.incubatorGame;

        return <div className="radio">
            <input
                id="deadheat"
                type="radio"
                name="line[winner]"
                value="-2"
                onChange={this.handleChangeWinner}
                onClick={this.handleClickWinner}
                checked={attributes.winner === STATUS_DRAW}
            />
            <label className="icon-checkbox" htmlFor="deadheat"/>
            <label
                className="field__label"
                htmlFor="deadheat"
            >{I18n.t('draw_label')}</label>
        </div>;
    };

    render(): ReactNode {
        const {attributes} = this.controller.incubatorGame;
        const {status} = this.controller.incubatorGame.lineStore.collection;

        return <>
            {isDesktop()
                ? <div className="incubator-line-panel">
                    <IncubatorVolumeButton/>
                    <div className="line-fields">
                        {this.radioDeadheat()}
                    </div>
                    <div className="group-buttons">
                        <IncubatorCancelCreateLineButton
                            disabled={attributes.accepted_bets !== 0 ||
                                this.controller.bets?.collection.length !== 0}
                            cancelLine={this.controller.cancelLine}
                        />
                        <button
                            type="submit"
                            className="button-adaptive button-adaptive_full-thin"
                            disabled={isClosedLine(status)}
                        >
                            {I18n.t('user.save')}
                        </button>
                    </div>
                </div>
                : <div className="incubator-line-panel-mobile">
                    <div className="line-fields left">
                        <IncubatorLineLeftWinner/>
                    </div>
                    <div className="line-fields middle">
                        {this.radioDeadheat()}
                    </div>
                    <div className="line-fields right">
                        <IncubatorLineRightWinner/>
                    </div>
                </div>}
        </>;
    }
}
