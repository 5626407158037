import React from 'react';
import QuestionMark from 'app/helpers/qustion_mark';

export default class Checkbox extends React.PureComponent {
    constructor(props) {
        super(props);
        if (!this.props.onChange) {
            this.state = {value: this.props.value};
        }
    }

    onChange = e => {
        const value = e.target.checked;

        if (this.props.onChange) {
            this.props.onChange(value);
        } else {
            this.setState({value});
        }
    };

    value() {
        return this.props.onChange ? this.props.value : this.state.value;
    }

    render() {
        const {questionMark, disabled} = this.props;
        const res = <div className={questionMark ? 'field field--with-icon' : 'field'}>
            <label className={`field__check${disabled ? ' field__check--disabled' : ''}`}>
                <input
                    className="field__check-hidden"
                    checked={this.value()}
                    onChange={this.onChange}
                    type="checkbox"
                    disabled={disabled}
                />
                <div className="field__check-box">
                    <svg height="11px" width="15px">
                        <use xlinkHref="#svg-check"/>
                    </svg>
                </div>
                <span className="label" dangerouslySetInnerHTML={{__html: this.props.label}}/>
            </label>
            {questionMark && <QuestionMark/>}
        </div>;

        return this.props.simple
            ? res
            : <div className="popup-form__row">
                <input name={this.props.name} type="hidden" value={this.value() ? 1 : 0}/>
                {res}
            </div>;
    }
}
