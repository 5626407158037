import {StatsType} from 'app/utils/Consts';

export const selectTypeStats = (monthly_stats: boolean, year_stats: boolean): number => {
    if (monthly_stats) {
        return StatsType.MONTHLY;
    }

    if (year_stats) {
        return StatsType.YEAR;
    }
    return StatsType.EMPTY;
};
