import React from 'react';
import BetInterface from 'app/interfaces/BetInterface';
import {fixed3} from 'app/utils/fixed';
import {GAMER_1} from 'app/utils/Consts';
import {mainLine} from 'app/utils/line/mainLine';
import {flagImage, gameImage, isDesktop, raceImage} from 'app/utils';
import {userBetsStore} from 'app/store/global';
import GamerInterface from 'app/interfaces/GamerInterface';

interface Props {
    bet: BetInterface
}

export default class TableHistoryRowExpressBet extends React.Component<Props> {
    isNestedBet = (): boolean => this.props.bet.game_id > 0;

    flag = (): string => this.gamer().flag;

    race = (): string => this.gamer().race;

    gamer = (): GamerInterface => this.props.bet[`gamer_${this.props.bet.on}`];

    rowStatusBet(): string {
        const {cancelled, won, finished} = this.props.bet;

        if (cancelled) {
            return ' match-event-canceled';
        }

        if (won && finished) {
            return ' match-event-won';
        }

        if (!won && finished) {
            return ' match-event-lost';
        }

        return ' match-event-waiting';
    }

    betMoreAvailable = (): boolean => this.props.bet && !this.props.bet.cancelled;

    betMore = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e && e.preventDefault();
        e.preventDefault();
        e.stopPropagation();
        const {bet} = this.props;
        userBetsStore.betMore({
            hash_id: bet.bet_hash_id,
            id: bet.bet_id
        });
    };

    renderMobile(): React.ReactNode {
        const {on, coef, extended_gamer, filter} = this.props.bet;
        const {gamer_1, gamer_2} = mainLine(this.props.bet);
        const gamer_nick = on === GAMER_1 ? gamer_1.nick : gamer_2.nick;

        return <div className={`history-match-event${this.rowStatusBet()}`}>
            <div className="history-match">
                <div className="history-match-team">
                    <div className="history-match-teams">
                        {gameImage(filter, 'history-match-game-logo')}
                        <span className="history-match-teams-names">{gamer_1.nick} vs {gamer_2.nick}</span>
                    </div>
                    <div className="history-match-event-coefficient">
                        <span
                            onClick={this.betMore}
                            className="history-match-coefficient more no-border"
                        >
                            {fixed3(coef)}
                        </span>
                    </div>
                </div>
                {this.isNestedBet() && <span className="history-match-details">
                    {extended_gamer}
                </span>}
                <span className="history-match-team-name">
                    {flagImage(this.flag(), 'history-match-team-country-flag')}
                    {raceImage(this.race(), 'history-match-team-country-flag')}
                    {this.isNestedBet() ? gamer_nick : extended_gamer}
                </span>
            </div>
        </div>;
    }

    render(): React.ReactNode {
        const {on, coef, extended_gamer, filter,
            finished, game_name_score} = this.props.bet;
        const {gamer_1, gamer_2} = mainLine(this.props.bet);
        const gamer_nick = on === GAMER_1 ? gamer_1.nick : gamer_2.nick;

        if (!isDesktop()) {
            return this.renderMobile();
        }

        return <div className={`history-match-event${this.rowStatusBet()}`}>
            <div className="history-match">
                <div className="history-match-team">
                    {gameImage(filter, 'history-match-game-logo')}
                    <span className="history-match-team-name">
                        {gamer_1.nick} vs {gamer_2.nick} {finished && game_name_score}
                    </span>
                </div>
                {this.isNestedBet() && <span className="history-match-details">
                    {extended_gamer}
                </span>}
                {!this.isNestedBet() && <div className="history-match-teams">
                    {flagImage(this.flag(), 'history-match-team-country-flag')}
                    {raceImage(this.race(), 'history-match-team-country-flag')}
                    <span className="history-match-teams-names">
                        {this.isNestedBet() ? gamer_nick : extended_gamer}
                    </span>
                </div>}
            </div>
            <div className="history-match-event-coefficient">
                <span
                    onClick={this.betMore}
                    className="history-match-coefficient more no-border"
                >
                    {fixed3(coef)}
                </span>
            </div>
        </div>;
    }
}
