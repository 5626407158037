const LAST_ARRAY_ELEMENT = -1;

interface extractArgumentsResult {
    attributes: unknown[]
    options: unknown
}

const extractArguments = (args: unknown[]): extractArgumentsResult => {
    const options = typeof args[args.length - 1] === 'object'
        ? args.splice(LAST_ARRAY_ELEMENT, 1)[0]
        : {};
    return {
        attributes: args,
        options
    };
};

export default extractArguments;
