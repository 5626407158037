import React, {memo} from 'react';
import I18n from 'app/i18n';
import {localDateTimeFromISO} from 'app/utils';
import TableEmptyState from 'app/components/table/empty_state';
import {artifactName} from 'app/components/my_profile/artifacts/utils';
import {Link} from 'react-router-dom';
import dateLabel from './DateLabel';
import SellNowSteamSkin from 'app/components/common/SellNowSteamSkin';
import MyHeroLink from 'app/components/common/MyHeroLink';
import {goToLinkLootboxes, linkLootboxes} from 'app/utils/app_links';
import {lootboxToChat} from 'app/utils/share_to_chat';
import ShowMore from 'app/utils/ShowMore';
import moment from 'moment';
import SmartTableLoader from 'app/components/reusable/smart_loaders/SmartTableLoader';

const DAY = 24;

const Row = memo(props => {
    const {opened_at, lootbox, prize, steam_skin, steam_trade, artifact, resetData} = props;
    const noSharing = moment().diff(moment(opened_at), 'h') >= DAY;
    return <>
        <div className="table-content-row">
            <div className="table-content-cell">
                <span className="mobile-label">{dateLabel()}:</span>
                <span className="field-value">{localDateTimeFromISO(opened_at)}</span>
            </div>
            <div className="table-content-cell">
                <span className="mobile-label">{I18n.t('lootbox')}:</span>
                <span className="field-value">{artifactName(lootbox)}</span>
            </div>
            <div className="table-content-cell">
                <span className="mobile-label">{I18n.t('prize_label')}:</span>
                <span className="field-value">{prize}</span>
            </div>
            <div className="table-content-cell">
                <div className="right-buttons">
                    <Link to={linkLootboxes()} className="button-adaptive button-adaptive_instyle">
                        {I18n.t('buy_more')}
                    </Link>
                    {artifact && <MyHeroLink btnClass="button-adaptive button-adaptive_instyle"/>}
                    {!noSharing && <button
                        className="button-adaptive button-adaptive_instyle"
                        onClick={() => lootboxToChat(props.id)}
                        disabled={noSharing}
                    >
                        {I18n.t('chat_title')}
                    </button>}
                    <SellNowSteamSkin
                        afterSell={resetData}
                        steam_skin={steam_skin}
                        steam_trade={steam_trade}
                        btnClass="button-adaptive button-adaptive_instyle"
                        soldClassName="button-adaptive button-adaptive_instyle disabled"
                    />
                </div>
            </div>
        </div>
    </>;
});

const LootboxesTable = memo(props => {
    const {data, resetData, loading, showMoreHandler, has_more} = props;
    return <>
        <div className="table-content-head table-content-lootboxs">
            <div className="table-content-cell">
                <span className="table-content-cell-name">{dateLabel()}</span>
            </div>
            <div className="table-content-cell">
                <span className="table-content-cell-name">{I18n.t('lootbox')}</span>
            </div>
            <div className="table-content-cell">
                <span className="table-content-cell-name">{I18n.t('prize_label')}</span>
            </div>
        </div>
        <div className="table-content-body table-content-lootboxs">
            {loading && <SmartTableLoader/>}
            {data && data.map(
                lootbox => <Row key={lootbox.id} {...lootbox} resetData={resetData}/>
            )}
        </div>
        <ShowMore onClickHandler={showMoreHandler} has_more={has_more}/>
        <TableEmptyState
            arrayLength={data && data.length}
            text={I18n.t('no_lootboxes')}
            buttonText={I18n.t('buy_shop_item')}
            buttonClick={goToLinkLootboxes}
            loading={loading}
        />
    </>;
});

export default LootboxesTable;
