import React, {Component, ReactNode} from 'react';
import PokerMenuButtonBalance from 'app/components/poker/poker_menu_container/PokerMenuButtonBalance';
import PokerMenuButtonDeposit from 'app/components/poker/poker_menu_container/PokerMenuButtonDeposit';
import PokerMenuButtonPause from 'app/components/poker/poker_menu_container/PokerMenuButtonPause';
import PokerMenuButtonQuit from 'app/components/poker/poker_menu_container/PokerMenuButtonQuit';
import PokerMenuButtonSound from 'app/components/poker/poker_menu_container/PokerMenuButtonSound';

interface Props {
    closeTable: () => void
    sitOut: () => void
    satOut: boolean
    isEnabledSound: boolean
    enableSound: () => void
}

export default class PokerMenuContainer extends Component<Props> {
    render(): ReactNode {
        return <div className="poker-menu-container">
            <div className="poker-menu-container__item">
                <PokerMenuButtonBalance/>
                <PokerMenuButtonDeposit/>
            </div>
            <div className="poker-menu-container__item">
                <PokerMenuButtonPause onClick={this.props.sitOut} satOut={this.props.satOut}/>
            </div>
            <div className="poker-menu-container__item">
                <PokerMenuButtonQuit onClick={this.props.closeTable}/>
            </div>
            <div className="poker-menu-container__item">
                <PokerMenuButtonSound enabled={this.props.isEnabledSound} onClick={this.props.enableSound}/>
            </div>
        </div>;
    }
}
