import React from 'react';

interface RatingPointsProps {
    isCorrectAnswer: boolean,
    isCalculated: boolean,
    isCanceledByUser: boolean,
    isCanceledByAdmin: boolean,
    rating_points: number,
    potentialPoints: number
}

export default class RatingPoints extends React.PureComponent<RatingPointsProps> {
    color = (): string => {
        if (!this.props.isCalculated) {
            return '';
        }

        return this.props.isCorrectAnswer ? ' color-success' : ' color-fail';
    };

    render(): React.ReactNode {
        const {
            isCanceledByUser,
            isCanceledByAdmin,
            isCalculated,
            isCorrectAnswer,
            rating_points,
            potentialPoints
        } = this.props;

        if (isCanceledByUser || isCanceledByAdmin) {
            return null;
        }

        return isCalculated && isCorrectAnswer
            ? <span className={`rating_points${this.color()}`}>{rating_points} RP</span>
            : <span className={`rating_points${this.color()}`}>{potentialPoints} RP</span>;
    }
}
