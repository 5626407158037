import React, {Component, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {getLinkPath, isGuest, scrollToTop} from 'app/utils';
import {openAuth} from 'app/components/auth/auth';

export default class PokerMenuButtonDeposit extends Component {
    onClick = (): void => {
        scrollToTop();

        if (isGuest()) {
            openAuth();
        }
    };

    render(): ReactNode {
        return <span className="button-go-deposit">
            <Link
                to={getLinkPath('/deposits')}
                onClick={this.onClick}
            >
                <svg className="svg-default plus"><use xlinkHref="#svg-add"/></svg>
            </Link>
        </span>;
    }
}
