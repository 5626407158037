import wretch from 'wretch';
import $ from 'jquery';
import {modalAlert} from 'app/utils/modals/popups';
import I18n from 'app/i18n';

const INTERNAL_SERVER_ERROR = 500;

const w = wretch()
    .headers({
        Accept: 'application/json',
        'X-CSRF-Token': $('meta[name=\'csrf-token\']').attr('content'),
        'X-EGB-Locale': window.CURRENT_LOCALE
    })
    .catcher(INTERNAL_SERVER_ERROR, () => modalAlert(I18n.t('server_error_try_later')));

export default w;
