import React, {Fragment, useEffect} from 'react';
import {scrollByHash} from 'app/utils';
import Bonuses from 'app/components/my_profile/bonuses';
import {inject} from 'mobx-react';
import {observer} from 'mobx-react-lite';
import {PageContent} from 'app/components/page_content';
import BannerButtonBlockRight from 'app/components/my_profile/bonuses/BannerButtonBlockRight';
import SmartLoader from 'app/components/reusable/SmartLoader';
import {UserBonusesStore} from 'app/store/UserBonusesStore';
import {PageContentStore} from 'app/store/PageContentStore';

interface MyBonusesPageInterface {
    userBonusesStore?: UserBonusesStore
    pageContentStore?: PageContentStore
}

const MyBonusesPage: React.FC<MyBonusesPageInterface> = props => {
    useEffect(() => {
        scrollByHash();
    }, []);

    const {pageContentStore,
        userBonusesStore: {bonuses,
            bonuses_data,
            bonusesLoading,
            bonusDataLoading}} = props;
    return <>
        {bonusDataLoading || bonusesLoading
            ? <SmartLoader/>
            : <><BannerButtonBlockRight bonusesStats={bonuses_data}/>
                <div className="bonuses profile-tab">
                    <Bonuses bonuses={bonuses}/>
                    <PageContent className="page-content-description" contentUrl="/my_profile/bonuses" contentHolder contentKey="my_bonuses" onlyOnce>
                        <div className="content__holder" dangerouslySetInnerHTML={{__html: pageContentStore.content?.my_bonuses}}/>
                    </PageContent>
                </div>
            </>}
    </>;
};
export default inject('pageContentStore', 'userBonusesStore')(observer(MyBonusesPage));
