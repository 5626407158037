import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {
    notificationsAvailableInBrowser,
    notificationsStatusIsDefault,
    requestPermission
} from 'app/firebase';

export default class RequestPermissionButton extends PureComponent {
    handleRequestPermission = e => {
        e.preventDefault();
        if (!this.canRequestPermission) {
            return;
        }
        requestPermission();
    };

    get canRequestPermission() {
        return notificationsAvailableInBrowser() && notificationsStatusIsDefault();
    }

    render() {
        return <div className="popup-form__row">
            <div className="field">
                <label className="field__check">
                    <button onClick={this.handleRequestPermission}
                        className={`btn browser ${this.canRequestPermission ? '' : 'btn--disable'}`}>
                        {I18n.t('enable_browser_notifications')}
                    </button>
                </label>
            </div>
        </div>;
    }
}
