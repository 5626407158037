import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import {inject, observer} from 'mobx-react';
import I18n from 'app/i18n';
import {getLinkPath, scrollToTop, userSignedIn} from 'app/utils';
import {UserStore} from 'app/store/UserStore';
import {SignUpButton} from 'app/components/auth/SignUpButton';

interface X2BonusPromotionProps {
    userStore: UserStore
}

@inject('userStore')
@observer
export default class X2BonusPromotion extends PureComponent<X2BonusPromotionProps> {
    render(): React.ReactNode {
        const hasFirstDeposit = this.props.userStore.user.first_deposit;
        const showBanner = userSignedIn() && !hasFirstDeposit || !userSignedIn();

        return showBanner && <div className="x2-bonus-promotion">
            <div className="x2-bonus-promotion__content">
                {userSignedIn()
                    ? <>
                        <p className="x2-bonus-promotion-text">{I18n.t('make_your_first_deposit_and_claim_a_bonus')}</p>
                        <Link
                            to={getLinkPath('/deposits')}
                            className="btn x2-bonus-promotion-btn"
                            onClick={scrollToTop}
                            dangerouslySetInnerHTML={{__html: I18n.t('user.deposit')}}
                        />
                    </>
                    : <>
                        <p className="x2-bonus-promotion-text">{I18n.t('register_and_claim_bonus_on_first_deposit')}</p>
                        <SignUpButton className="btn x2-bonus-promotion-btn" usingOnTab="X2_BONUS_PROMOTION" title={I18n.t('user.registration')}/>
                    </>}
            </div>
        </div>;
    }
}
