import React, {ReactNode} from 'react';
import {i18n_t} from 'app/i18n';

interface SendArtifactSuccessDataType {
    artifacts_count?: number
    artifact_name: string
    receiver: string
}

const SendArtifactSuccess = (data: SendArtifactSuccessDataType): ReactNode => <div>
    {
        i18n_t('send_artifact_success',
            {artifact: data.artifact_name,
                count: data.artifacts_count,
                quantity: data.artifacts_count,
                user: data.receiver})
    }{'\u00A0'}
</div>;

export default SendArtifactSuccess;
