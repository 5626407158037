import {isGuest} from 'app/utils';
import {openRegistration} from 'app/components/auth/auth';

export const showCasinoSignUp = (): boolean => {
    if (isGuest()) {
        openRegistration('CASINO');
        return true;
    }
    return false;
};
