import React, {PureComponent, ReactNode} from 'react';
import {fixed2} from 'app/utils/fixed';

interface IncubatorPledgeAddProps {
    pledge: string
    onClick(): void
}

export default class IncubatorPledgeAdd extends PureComponent<IncubatorPledgeAddProps> {
    render(): ReactNode {
        return <div className="wrapper-incubator-pledge-adding" onClick={this.props.onClick}>
            <label className="symbol-label">$</label>
            <input
                className="field__input bold field-number__input field-number__pledge"
                disabled
                value={fixed2(this.props.pledge)}
            />
            <span className="incubator-pledge-adding">
                <svg className="svg-default">
                    <use xlinkHref="#svg-add"/>
                </svg>
            </span>
        </div>;
    }
}
