import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import {IncubatorCreateLineController} from 'app/controllers/IncubatorCreateLineController';
import {PERCENT_75} from 'app/utils/Consts';
import IncubatorLineControlButtons from 'app/components/pages/incubator/line/IncubatorLineControlButtons';
import {isDesktop} from 'app/utils';

interface Props {
    controller: IncubatorCreateLineController
}

@inject('controller')
@observer
export default class IncubatorLineMiddle extends Component {
    get controller(): IncubatorCreateLineController {
        const props = this.props as Props;
        return props.controller;
    }

    render(): ReactNode {
        const {lineStore} = this.controller.incubatorGame;
        const {accepted_bets} = lineStore.collection;
        return <div className="part middle">
            <div className="incubator-profit-middle">
                <div className="incubator-profit__info">
                    <b className={`${Number(accepted_bets) >= PERCENT_75 ? 'plus' : 'minus'}`}>
                        {`${accepted_bets}%`}
                    </b>
                    {isDesktop() && <span className="name">{I18n.t('incubator_accepted_per_line')}</span>}
                </div>
            </div>
            <IncubatorLineControlButtons/>
        </div>;
    }
}
