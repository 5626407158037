import React, {ReactNode, PureComponent} from 'react';
import I18n from 'app/i18n';
import {isDesktop, scrollToTop} from 'app/utils';
import {Link} from 'react-router-dom';
import {getLinkPath} from 'app/utils/link_helper';
import CryptoInformationLogo from 'app/components/deposit/information/CryptoInformationLogo';
import CryptoLogoBitcoin from 'images/crypto-logotypes/bitcoin-btc-logo.png';
import CryptoLogoDash from 'images/crypto-logotypes/dash-dash-logo.png';
import CryptoLogoDogecoin from 'images/crypto-logotypes/dogecoin-doge-logo.png';
import CryptoLogoEthereum from 'images/crypto-logotypes/ethereum-eth-logo.png';
import CryptoLogoLitecoin from 'images/crypto-logotypes/litecoin-ltc-logo.png';
import CryptoLogoTether from 'images/crypto-logotypes/tether-usdt-logo.png';
import ExchangeLogoBinance from 'images/crypto-exchange/binance.png';
import ExchangeLogoCrypto from 'images/crypto-exchange/crypto.png';
import ExchangeLogoHuobi from 'images/crypto-exchange/huobi.png';
import ExchangeLogoKraken from 'images/crypto-exchange/kraken.png';
import ExchangeLogoKucoin from 'images/crypto-exchange/kucoin.png';
import logEvent from 'app/amplitude/log_event';

export default class CryptoInformation extends PureComponent {
    onClick = (): void => {
        scrollToTop();
        logEvent('BANNER_OPENED', {type: 'crypto'});
    };

    render(): ReactNode {
        return <div className="payment-systems__list">
            <Link
                onClick={this.onClick}
                className="crypto-information"
                to={getLinkPath('/deposits/coinspaid')}
            >
                <div className="crypto-information-text">
                    <h1>{I18n.t('crypto_banner_title')}</h1>
                    <div className="text">
                        <p>{I18n.t('crypto_banner_text')}</p>
                    </div>
                    <button className="button-adaptive button-adaptive_bigger-one">
                        {I18n.t('user.deposit')}
                    </button>
                </div>
                <div className="list-crypto-currency">
                    <CryptoInformationLogo altLogo="Bitcoint" srcLogo={CryptoLogoBitcoin}/>
                    <CryptoInformationLogo altLogo="Ethereum" srcLogo={CryptoLogoEthereum}/>
                    <CryptoInformationLogo altLogo="Litecoin" srcLogo={CryptoLogoLitecoin}/>
                    <CryptoInformationLogo altLogo="Tether" srcLogo={CryptoLogoTether}/>
                    <CryptoInformationLogo altLogo="Dogecoin" srcLogo={CryptoLogoDogecoin}/>
                    <CryptoInformationLogo altLogo="Dash" srcLogo={CryptoLogoDash}/>
                    <span className="crypto-logotype">
                        <b className="number-crypto">{isDesktop() ? '+7' : '+9'}</b>
                    </span>
                </div>
                <div className="crypto-information-footer">
                    <div className="list-crypto-exchange">
                        <CryptoInformationLogo altLogo="Binance" srcLogo={ExchangeLogoBinance}/>
                        <CryptoInformationLogo altLogo="Kucoin" srcLogo={ExchangeLogoKucoin}/>
                        <CryptoInformationLogo altLogo="Kraken" srcLogo={ExchangeLogoKraken}/>
                        <CryptoInformationLogo altLogo="Huobi" srcLogo={ExchangeLogoHuobi}/>
                        <CryptoInformationLogo altLogo="Crypto.com" srcLogo={ExchangeLogoCrypto}/>
                    </div>
                    <div className="text-footer">
                        <svg className="svg-default">
                            <use xlinkHref="#svg-promotion-plus"/>
                        </svg>
                        <span className="text">{I18n.t('crypto_banner_exchange')}</span>
                    </div>
                </div>
            </Link>
        </div>;
    }
}
