import React, {memo} from 'react';
import I18n from 'app/i18n';
import TableEmptyState from 'app/components/table/empty_state';
import {dateLabel} from 'app/utils/dateLabel';
import {goToLinkSimpleBets} from 'app/utils/app_links';
import ShowMore from 'app/utils/ShowMore';
import {PromoActionsTableRow} from './PromoActionsTableRow';
import SteamTradeInterface from 'app/interfaces/SteamTradeInterface';
import SteamSkinInterface from 'app/interfaces/SteamSkinInterface';
import SmartTableLoader from 'app/components/reusable/smart_loaders/SmartTableLoader';

export interface PromoActionDataInterface {
    id: number
    promo_action_name: string
    prizes: {
        name: string
        artifact: {
            langs: string[]
            name: string
        }
    }[]
    created_at: string
    steam_trades: {
        steam_skin: SteamSkinInterface
        steam_trade: SteamTradeInterface
    }[]
}
interface PromoActionsTableInterface {
    data?: PromoActionDataInterface[]
    resetData?(): void
    loading?: boolean
    showMoreHandler?(): Promise<void>
    has_more?: boolean
}

const PromoActionsTable: React.FC<PromoActionsTableInterface> = memo(props => {
    const {data, resetData, loading, showMoreHandler, has_more} = props;
    return <>
        <div className="table-content-head table-content-promotions">
            <div className="table-content-cell">
                <span className="table-content-cell-name">{dateLabel()}</span>
            </div>
            <div className="table-content-cell">
                <span className="table-content-cell-name">{I18n.t('promo_action_title')}</span>
            </div>
            <div className="table-content-cell">
                <span className="table-content-cell-name">{I18n.t('prize_label')}</span>
            </div>
        </div>
        <div className="table-content-body table-content-promotions">
            {loading && <SmartTableLoader/>}
            {data && data.map(rating => <PromoActionsTableRow key={rating.id} {...rating} resetData={resetData}/>)}
        </div>
        <ShowMore onClickHandler={showMoreHandler} has_more={has_more}/>
        <TableEmptyState
            arrayLength={data.length}
            text={I18n.t('no_prizes')}
            buttonText={I18n.t('play')}
            buttonClick={goToLinkSimpleBets}
            loading={loading}
        />
    </>;
});

export default PromoActionsTable;
