import React, {Fragment, PureComponent} from 'react';
import I18n from 'app/i18n';
import {getLinkPath} from 'app/utils/link_helper';
import logoFramedSvg from 'images/svg_icons/logo-framed.svg';
import appPng from 'images/app/app.png';
import appNativePng from 'images/app/app-native.png';
import appMobilePng from 'images/app/app-mob.png';
import appBetTypesSvg from 'images/svg_icons/app-bet-types.svg';
import appEventsSvg from 'images/svg_icons/app-events.svg';
import appShopSvg from 'images/svg_icons/app-shop.svg';
import appWideLineSvg from 'images/svg_icons/app-wide-line.svg';
import appStreamSvg from 'images/svg_icons/app-stream.svg';
import appChatSvg from 'images/svg_icons/app-chat.svg';
import appHeroSvg from 'images/svg_icons/app-hero-no-fill.svg';
import appExchangeSvg from 'images/svg_icons/app-exchange-no-fill.svg';
import appIosSvg from 'images/svg_icons/app-apple.svg';
import appAndroidSvg from 'images/svg_icons/app-android.svg';
import {Link} from 'react-router-dom';
import {scrollToTop} from 'app/utils';
import logEvent from 'app/amplitude/log_event';
import {PageContent} from 'app/components/page_content';

export default class AppsPage extends PureComponent {
    rootElement = React.createRef();

    handleIosDownload = () => logEvent('MOBILE_APP_DOWNLOADED');

    renderIosWrapperButton() {
        return <a
            href={getLinkPath('/apps/ios/download/')}
            rel="noopener noreferrer"
            target="_blank"
            className="btn btn--large btn--big-on-mobile"
            onClick={this.handleIosDownload}
        >
            <img
                src={appIosSvg}
                alt="iOS"
                width="25px"
                height="29px"
            />
            iOS
        </a>;
    }

    static renderAndroidWrapperButton() {
        return <Link
            to={getLinkPath('/apps/android')}
            className="btn btn--large btn--big-on-mobile"
            onClick={scrollToTop}
        >
            <img
                src={appAndroidSvg}
                alt="Android"
                width="25px"
                height="29px"
            />
            Android
        </Link>;
    }

    render() {
        return <Fragment>
            <div ref={this.rootElement} className="app-info column--1">
                <div className="app-info__column">
                    <div className="app-info__column--wrap">
                        <div className="app-info__column--header">
                            <div className="app-info__column--header--img logo-framed logo-framed--brown">
                                <Link to={getLinkPath('/')}/>
                                <img src={logoFramedSvg} alt="EGB.com"/>
                            </div>
                            <div className="app-info__column--header--text">
                                <div className="app-info__column--title text-highlighted">EGB</div>
                                <div className="app-info__column--subtitle">
                                    {I18n.t('mobile_web-app_with_whole_site_functional')}
                                </div>
                            </div>
                        </div>
                        <div className="app-info__column--content">
                            <div className="app-info__column--img img--zIndex">
                                <picture>
                                    <source srcSet={appNativePng} media="(min-width: 1024px)"/>
                                    <source srcSet={appPng} media="(min-width: 667px)"/>
                                    <img src={appMobilePng} alt="Android app"/>
                                </picture>
                            </div>
                            <div className="app-info__column--features--wrap">
                                <div className="app-info__column--features">
                                    <div className="app-info__column--features__column">
                                        <div className="app-info__column--feature">
                                            <div className="app-info__column--feature--icon">
                                                <img src={appBetTypesSvg} alt="Bet types"/>
                                            </div>
                                            <div className="app-info__column--feature--title">
                                                {I18n.t('all_bet_types')}
                                            </div>
                                        </div>
                                        <div className="app-info__column--feature">
                                            <div className="app-info__column--feature--icon">
                                                <img src={appEventsSvg} alt="App events"/>
                                            </div>
                                            <div className="app-info__column--feature--title">
                                                {I18n.t('all_events_and_markets')}
                                            </div>
                                        </div>
                                        <div className="app-info__column--feature">
                                            <div className="app-info__column--feature--icon">
                                                <img src={appShopSvg} alt={I18n.t('menu_links.shop')}/>
                                            </div>
                                            <div className="app-info__column--feature--title">
                                                {I18n.t('menu_links.shop')}
                                            </div>
                                        </div>
                                        <div className="app-info__column--feature mobile--hidden">
                                            <div className="app-info__column--feature--icon">
                                                <img
                                                    src={appHeroSvg}
                                                    alt="Hero icon"
                                                    width="12px"
                                                    height="17px"
                                                />
                                            </div>
                                            <div className="app-info__column--feature--title">
                                                {I18n.t('trade_market')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="app-info__column--features__column">
                                        <div className="app-info__column--feature">
                                            <div className="app-info__column--feature--icon">
                                                <img src={appWideLineSvg} alt={I18n.t('wide_line')}/>
                                            </div>
                                            <div className="app-info__column--feature--title">
                                                {I18n.t('wide_line')}
                                            </div>
                                        </div>
                                        <div className="app-info__column--feature">
                                            <div className="app-info__column--feature--icon">
                                                <img src={appStreamSvg} alt={I18n.t('streams')}/>
                                            </div>
                                            <div className="app-info__column--feature--title">
                                                {I18n.t('streams')}
                                            </div>
                                        </div>
                                        <div className="app-info__column--feature">
                                            <div className="app-info__column--feature--icon">
                                                <img src={appChatSvg} alt={I18n.t('chat_title')}/>
                                            </div>
                                            <div className="app-info__column--feature--title">
                                                {I18n.t('chat_title')}
                                            </div>
                                        </div>
                                        <div className="app-info__column--feature mobile--show">
                                            <div className="app-info__column--feature--icon">
                                                <img
                                                    src={appHeroSvg}
                                                    alt="Hero icon"
                                                    width="12px"
                                                    height="17px"
                                                />
                                            </div>
                                            <div className="app-info__column--feature--title">
                                                {I18n.t('trade_market')}
                                            </div>
                                        </div>
                                        <div className="app-info__column--feature">
                                            <div className="app-info__column--feature--icon">
                                                <img
                                                    src={appExchangeSvg}
                                                    alt="Exchange icon"
                                                    width="12px"
                                                    height="17px"
                                                />
                                            </div>
                                            <div className="app-info__column--feature--title">
                                                {I18n.t('swap_skins_and_trades')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="app-info__column--footer">
                            <div className="app-info__column--footer__column">
                                <div className="app-info__column--footer--text">{I18n.t('download_on')}:</div>
                                <div className="app-info__column--footer--actions">
                                    <div className="mobile--hidden">
                                        <div className="mobile--hidden--item">
                                            {this.renderIosWrapperButton()}
                                        </div>
                                        <div className="mobile--hidden--item">
                                            {this.constructor.renderAndroidWrapperButton()}
                                        </div>
                                    </div>
                                    <div className="mobile--show">
                                        <div className="mobile--hidden--item">
                                            {this.renderIosWrapperButton()}
                                        </div>
                                        <div className="mobile--show--item">
                                            {this.constructor.renderAndroidWrapperButton()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile mobile--hidden">
                <PageContent
                    contentUrl={'/apps/content'}
                    contentKey={'apps'}
                    contentHolder/>
            </div>
        </Fragment>;
    }
}
