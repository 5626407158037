import React, {PureComponent} from 'react';
import LeaderLine from './leader_line';

export default class LeaderLines extends PureComponent {
    render() {
        const {ratings} = this.props;
        return ratings.map((rating, i) => <LeaderLine
            key={i}
            {...rating}
        />);
    }
}
