import SignInEventProperties from 'app/amplitude/sign_in_event_properties';
import SignUpEventProperties from 'app/amplitude/sign_up_event_properties';
import DepositEventProperties from 'app/amplitude/deposit_event_properties';
import amplitude from './amplitude';


export default function logEvent(eventType, eventProperties) {
    if (!window.AMPLITUDE_API_KEY) {
        return;
    }

    const PropertiesHandler = {
        INVOICE_FORM_FAILURE: DepositEventProperties,
        REGISTRATION_FORM_FAILURE: SignUpEventProperties,
        USER_AUTHORIZATION_FAILURE: SignInEventProperties
    }[eventType];

    const newEventProperties = PropertiesHandler
        ? new PropertiesHandler(eventProperties).extractProperties()
        : eventProperties;

    amplitude.logEvent(eventType, newEventProperties);
}
