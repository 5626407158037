import $ from 'jquery';
import React, {Component} from 'react';
import I18n from 'app/i18n';
import {slowConnectionAndError} from 'app/utils';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {modalAlert} from 'app/utils/modals/popups';
import {showPopup} from 'app/utils/modals/ModalsService';
import {modalsStore} from 'app/store/global';

export const showUserBanPopup = (userId, userName) => {
    showPopup('block-user', props => <UserBanPopup userId={userId} userName={userName} popupId={props.popupId}/>);
};

export default class UserBanPopup extends Component {
    time = React.createRef();

    describe = React.createRef();

    componentDidMount() {
        $(this.time.current).chosen({disable_search: true}).trigger('chosen:updated');
    }

    userBan = e => {
        e.preventDefault();
        slowConnectionAndError($.ajax({
            data: {
                descban: this.describe.current.value,
                id: this.props.userId,
                sec: this.time.current.value
            },
            success: data => {
                if (data.success) {
                    this.close();
                } else if (data.alert) {
                    modalAlert(data.alert);
                }
            },
            type: 'POST',
            url: '/chat/ban'
        }));
    };

    close = () => {
        modalsStore.hideModal(this.props.popupId);
    };

    handleClose = e => {
        e.preventDefault();
        this.close();
    };

    render() {
        return <PopupDialog id={this.props.popupId}>
            <div className="popup__content popup__content--small">
                <div className="block-user">
                    <form onSubmit={this.userBan}>
                        <div className="title">Ban user</div>
                        <p className="block-user__text">Are you sure you want to ban this user?</p>

                        <div className="block-user__user">{this.props.userName}</div>

                        <div className="block-user__text">
                            {I18n.t('time_limit')}:
                            <select ref={this.time} name="sec" className="chosen-select">
                                <option value="900">15 {I18n.t('minutes')}</option>
                                <option value="1800">30 {I18n.t('minutes')}</option>
                                <option value="3600">{I18n.t('hours', {count: 1})}</option>
                                <option value="10800">{I18n.t('hours', {count: 3})}</option>
                                <option value="21600">{I18n.t('hours', {count: 6})}</option>
                                <option value="43200">{I18n.t('hours', {count: 12})}</option>
                                <option value="86400">{I18n.t('days', {count: 1})}</option>
                                <option value="259200">{I18n.t('days', {count: 3})}</option>
                                <option value="518400">{I18n.t('days', {count: 6})}</option>
                                <option value="1036800">{I18n.t('days', {count: 12})}</option>
                                <option value="2073600">{I18n.t('days', {count: 24})}</option>
                                <option value="2678400">{I18n.t('days', {count: 31})}</option>
                                <option value="5356800">2 {I18n.t('few_months')}</option>
                                <option value="10713600">4 {I18n.t('few_months')}</option>
                                <option value="16070400">6 {I18n.t('other_months')}</option>
                                <option value="21427200">8 {I18n.t('other_months')}</option>
                                <option value="26784000">10 {I18n.t('other_months')}</option>
                                <option value="32140800">12 {I18n.t('other_months')}</option>
                            </select>
                        </div>

                        <div className="block-user__text ">
                            {I18n.t('reason_for_blocking')}:
                            <input type="text" name="descban" className="field__input" ref={this.describe}/>
                        </div>

                        <div className="block-user__submit-row">
                            <button type="button" className="btn btn--invert" onClick={this.handleClose}>
                                {I18n.t('confirm_button_no')}
                            </button>
                            <button type="submit" className="btn">{I18n.t('yes_block')}</button>
                        </div>
                    </form>
                </div>
            </div>
        </PopupDialog>;
    }
}
