import React, {MouseEvent, PureComponent, ReactElement} from 'react';
import PopupImage from '../popup/popup_image';
import Smiles from '../popup/smiles';
import PopupContentProps from 'app/interfaces/popups/scroll_popup/PopupContentProps';
import PopupActionButton from './PopupActionButton';
import {translatedDescription} from 'app/utils/translations_helper';
import {i18n_t} from 'app/i18n';
import Post from 'app/utils/Post';
import {modalAlert, modalConfirm} from 'app/utils/modals/popups';
import {showPopup} from 'app/utils/modals/ModalsService';
import SendArtifactPopup from '../send_artifact_popup';
import {goToRoute} from 'app/utils/link_helper';
import {artifactShopLink} from '../utils';
import SellArtifactPopup from '../sell_artifact_popup';
import {artifactStore, userStore} from 'app/store/global';
import Notice from '../popup/notice';

interface ResponseJSONInterface {
    message: string;
    error: string | { error: string };
}

export default class PopupContent extends PureComponent<PopupContentProps> {
    use = (e: MouseEvent<HTMLButtonElement>, continue_using = false): void => {
        const {public_view, id, useable, closePopup} = this.props;

        if (public_view || !useable) {
            return;
        }

        new Post({
            params: {continue_using},
            url: `/inventory/items/scrolls/${id}/use`
        })
            .execute()
            .then(response => {
                if (response.ok) {
                    response.json()
                        .then(responseJSON => artifactStore.updateStore(responseJSON))
                        .then(closePopup);
                } else {
                    response.json()
                        .then(responseJSON => {
                            const [[message, error]] = Object.values(responseJSON as ResponseJSONInterface);

                            if (error.error === 'similar_effect_value_exceeded') {
                                modalConfirm(message, () => this.use(e, true));
                            } else {
                                modalAlert(message);
                            }
                        });
                }
            });
    };

    send = (): void => {
        const {
            sending_prohibited,
            total_inventory_items,
            total_sending_prohibited_items,
            public_view,
            recipe,
            id
        } = this.props;
        const {restrict_artifact} = userStore.user;

        if (sending_prohibited || public_view || restrict_artifact) {
            return;
        }

        showPopup('inventory-popup', (props: {popupId: string}) => <SendArtifactPopup artifact={{
            ...recipe,
            id,
            total_inventory_items,
            total_sending_prohibited_items
        }}
        popupId={props.popupId}
        />);
    };

    sell = (): void => {
        const {public_view, in_market, closePopup, recipe} = this.props;
        const {restrict_artifact} = userStore.user;

        if (public_view || restrict_artifact) {
            return;
        }

        if (in_market) {
            closePopup();
            goToRoute(artifactShopLink(recipe, true), true);
        } else {
            showPopup('sell-inventory',
                (props: {popupId: string}) => <SellArtifactPopup
                    artifact={{
                        ...recipe,
                        ...this.props
                    }}
                    recipe={recipe}
                    popupId={props.popupId}
                />,
                ['inventory-popup']);
        }
    };

    render(): ReactElement {
        const {
            recipe,
            item_type,
            public_view,
            useable,
            sending_prohibited,
            transferable,
            in_market,
            salable
        } = this.props;
        const {restrict_artifact} = userStore.user;
        const {smiles} = recipe;

        return <div className="inventory__popup">
            <div className="inventory__popup--description">
                <div className="inventory__popup--img-wrap">
                    <div className="inventory__popup--img">
                        <PopupImage
                            item_type={item_type}
                            image={recipe.image}
                        />
                    </div>
                </div>
                <div className="inventory__popup--desc">
                    <div className="inventory__popup--desc-title-gray">{i18n_t('overview')}</div>
                    {translatedDescription(recipe)}
                    <Smiles smiles={smiles}/>
                </div>
            </div>
            {public_view
                ? null
                : <div className="inventory__popup--actions">
                    <PopupActionButton
                        onClickHandler={this.use}
                        disabled={!useable}
                        text={i18n_t('apply')}
                    />
                    <PopupActionButton
                        onClickHandler={this.send}
                        disabled={sending_prohibited || !transferable || restrict_artifact}
                        text={i18n_t('send_as_gift')}
                    />
                    <PopupActionButton
                        onClickHandler={this.sell}
                        disabled={!salable || restrict_artifact}
                        text={in_market ? i18n_t('show_now') : i18n_t('sell')}
                    />
                </div>}
            <Notice {...this.props}/>
        </div>;
    }
}
