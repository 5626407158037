import React, {PureComponent, ReactNode} from 'react';
import {IncubatorShiftController} from 'app/controllers/IncubatorShiftController';
import {incubatorTopUpModal} from 'app/components/pages/incubator/IncubatorTopUpModal';
import I18n from 'app/i18n';

interface IncubatorTopUpButtonProps {
    controller: IncubatorShiftController
    message: string
}

export default class IncubatorTopUpButton extends PureComponent<IncubatorTopUpButtonProps> {
    onClick = (): void => {
        const {controller, message} = this.props;
        incubatorTopUpModal(controller, message);
    };

    render(): ReactNode {
        return <button
            className="button-adaptive button-adaptive_casino"
            onClick={this.onClick}
        >
            {I18n.t('tooltip_incubator_top_up')}
        </button>;
    }
}
