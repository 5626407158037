import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import {handleShowingDocumentsUploader} from 'app/components/DocumentsUpload/upload_documents';
import CashoutInterface from 'app/interfaces/CashoutInterface';
import DetailInterface from 'app/interfaces/DetailInterface';
import ErrorsServerInterface from 'app/interfaces/ErrorsServerInterface';
import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';
import CashoutCreateInterface from 'app/interfaces/queries/CashoutCreateInterface';
import SteamSkinInterface from 'app/interfaces/SteamSkinInterface';
import WalletInterface from 'app/interfaces/WalletInterface';
import WalletDetailInterface from 'app/interfaces/WalletDetailInterface';
import {modalConfirm} from 'app/utils/modals/popups';
import NotificationStore from 'app/store/notifications';
import {userStore} from './global';
import {fixed2, flatten, isGuest} from 'app/utils';
import Post from 'app/utils/Post';
import {addOrUpdateObject} from 'app/utils/updateArraysObject';
import CashoutValidator from 'app/validators/CashoutValidator';
import CombinedCashoutValidator from 'app/validators/CombinedCashoutValidator';
import {action, makeObservable, observable} from 'mobx';
import I18n, {i18n_t} from 'app/i18n';
import {cashoutExchangeAmount} from 'app/components/cashout/cashoutExchangeAmount';
import {getLinkPath} from 'app/utils/link_helper';
import $ from 'jquery';
import Websocket from 'modules/Websocket';
import React, {Fragment} from 'react';
import BaseValidator from 'validators/BaseValidator';
import {goToCashouts} from 'app/utils/app_links';
import {RatesType} from './DepositsStore';
import {fixed2int} from 'app/utils/fixed';

const COMMISSION_PERCENT = 0.96;

export interface CashoutsFormInterface {
    last_used_wallets: number[],
    amount: string,
    cashouts: CashoutInterface[],
    commission: boolean,
    errors: ErrorsServerInterface,
    has_more: boolean,
    page: number,
    cashout_detail_attributes: DetailInterface,
    rates: RatesType,
    selectedSystem: PaymentSystemInterface,
    selectedWallet: WalletInterface,
    selectedWallets: WalletInterface[],
    steam_skins: SteamSkinInterface[],
    submitting: boolean,
    systems: {[key: number]: PaymentSystemInterface},
    systems_order: number[],
    wallet_attributes: {
        account: string,
        wallet_detail_attributes: WalletDetailInterface,
    }
    wallets: { [key: string]: WalletInterface[] }
    screenshot: ScreenshotType
}

export interface ScreenshotType {
    id: number
    url: string
}

export class CashoutsStore {
    constructor() {
        makeObservable(this);
    }

    @observable data: CashoutsFormInterface = {
        amount: '',
        cashout_detail_attributes: {
            accept_show_wallet: false,
            bank_account_number: '',
            bank_branch: '',
            bank_id: '',
            city: '',
            customer_name: '',
            ifsc_code: '',
            province: ''
        },
        cashouts: [],
        commission: false,
        errors: {},
        has_more: false,
        last_used_wallets: [],
        page: 1,
        rates: {} as RatesType,
        screenshot: null,
        selectedSystem: null,
        selectedWallet: null,
        selectedWallets: [],
        steam_skins: [],
        submitting: false,
        systems: {},
        systems_order: [],
        wallet_attributes: {
            account: '',
            wallet_detail_attributes: {
                currency: '',
                tag: ''
            }
        },
        wallets: {}
    };

    @observable p2pCashouts: CashoutInterface[] = [];

    subscription = null;

    @action
    init = (data: CashoutsFormInterface): void => {
        this.data = Object.assign(this.data, data);
        this.useSavedWallets();
    };

    @action
    update = (data: CashoutsFormInterface, p2pCashouts?: CashoutInterface[]): void => {
        this.data = Object.assign(this.data, data);
        if (p2pCashouts) {
            p2pCashouts.forEach(cashout => {
                this.addP2PCashout(cashout);
            });
        }
    };

    @action
    setWallets = (wallets: WalletInterface[]): void => {
        this.data.wallets = Object.assign(this.data.wallets, wallets);
    }

    @action
    amountChanged = (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>): string => {
        const AMOUNT_REGEX = /^\d+[,.]?\d{0,2}$/u;
        const target = e.target as HTMLInputElement;
        let newAmount = target.value;
        if (newAmount && !AMOUNT_REGEX.test(newAmount)) {
            return this.data.amount;
        }

        if (typeof newAmount === 'string') {
            newAmount = newAmount.replace(',', '.');
        }

        this.data.amount = target.value;
        this.resetError();
        return newAmount;
    };

    @action
    setServerErrors = (errors: ErrorsServerInterface): void => {
        Object.entries(errors).forEach(([key, [firstMessage]]) => {
            this.setError(key, firstMessage);
        });
        this.data.errors = {...this.data.errors};
    };

    @action
    setError = (key: string, message: string): void => {
        if (!Array.isArray(this.data.errors[key])) {
            this.data.errors[key] = [];
        }
        this.data.errors[key].push(message);
    };

    @action
    resetError = (): void => {
        this.data.errors = {};
    };

    validator(type: string): BaseValidator<CashoutsFormInterface> {
        return type === 'CombinedCashout'
            ? new CombinedCashoutValidator(this.data)
            : new CashoutValidator(this.data);
    }

    @action
    validate = (type: string): boolean => {
        this.resetError();

        const validator = this.validator(type);

        if (validator.isValid()) {
            return true;
        }

        this.data.errors = validator.errors;

        if (this.data.errors.qr_code_document) {
            toastError(this.data.errors.qr_code_document[0]);
        }
        return false;
    };

    @action
    updateDetails = (details: {cashout_detail_attributes: DetailInterface}): void => {
        this.data.cashout_detail_attributes = details.cashout_detail_attributes;
    };

    @action
    updateWalletDetails = (details: {wallet_detail_attributes: WalletDetailInterface}): void => {
        this.data.wallet_attributes.wallet_detail_attributes = details.wallet_detail_attributes;
    };

    updateAttributeCashout(id: number, block: (cashout: CashoutInterface) => void): void {
        const {cashouts} = this.data;
        this.data.cashouts = cashouts.map(v => {
            if (v.id === id) {
                block(v);
            }
            return v;
        });
    }

    @action
    addP2PCashout(cashout: CashoutInterface): void {
        if (['completed', 'not_valid'].includes(cashout.state)) {
            NotificationStore.removeActiveKeys(`p2p_cashout_confirmation-${cashout.id}`);
            NotificationStore.removeKnownKeys(`p2p_cashout_confirmation-${cashout.id}`);
        }
        if (cashout.state !== 'waiting') {
            return;
        }
        this.p2pCashouts.push(cashout);
        NotificationStore.add({
            cashout,
            force: true,
            key: `p2p_cashout_confirmation-${cashout.id}`,
            timeout: false,
            type: 'p2p_cashout_confirmation'
        });
    }

    @action
    setCashoutCancelled = (id: number, commission: boolean): void => {
        this.updateAttributeCashout(id, cashout => {
            cashout.status = i18n_t('user.status_cancelled');
            cashout.cancellable = false;
        });
        this.data.commission = commission;
    };

    @action
    updateScreenshot(screenshot: ScreenshotType): void {
        this.data.screenshot = screenshot;
    }

    @action
    setCashoutCompleted = (id: number): void => {
        this.updateAttributeCashout(id, cashout => {
            cashout.status = i18n_t('user.status_completed');
            cashout.state = 'completed';
        });
    };

    @action
    setCashoutNotValid = (id: number): void => {
        this.updateAttributeCashout(id, cashout => {
            cashout.status = i18n_t('user.status_invalid');
            cashout.state = 'not_valid';
        });
    };

    @action
    setSavedWallets = (wallets: number[]): void => {
        this.data.last_used_wallets = wallets;
    };

    @action
    setAmount = (amount: string): void => {
        this.data.amount = amount;
    };

    @action
    setMinAmount = (): void => {
        this.data.amount = fixed2int(this.data.selectedSystem.min_limit);
        this.resetError();
    };

    @action
    setMaxAmount = (): void => {
        this.data.amount = fixed2int(this.data.selectedSystem.max_limit);
        this.resetError();
    };

    useSavedWallets = (): void => {
        const currentWallets = [];
        const {last_used_wallets} = this.data;

        if (last_used_wallets.length > 0) {
            last_used_wallets.forEach(walletId => {
                const wallet = this.findWalletById(Number(walletId));

                if (wallet) {
                    currentWallets.push(wallet);
                }
            });
        }
        this.selectWallets(currentWallets);
    };

    exchangeAmount = (): (string | null) => {
        const {amount, rates, selectedSystem: {exchange_currency}} = this.data;

        if (exchange_currency && Number(amount) !== 0) {
            return exchange_currency === 'USD' ? null : cashoutExchangeAmount(rates, amount, exchange_currency);
        }

        return null;
    };

    loadMore = (reset: boolean): void => {
        const page = reset ? 1 : this.data.page + 1;
        return $.ajax({
            data: {page},
            success: response => {
                if (response.success) {
                    this.loadMoreSuccess({
                        page,
                        reset,
                        ...response
                    });
                }
            },
            type: 'GET',
            url: getLinkPath('/cashouts/list')
        });
    };

    @action
    loadMoreSuccess = ({reset, cashouts, has_more, page}:
                           {reset: boolean, cashouts: CashoutInterface[], has_more: boolean, page: number}): void => {
        this.data.cashouts = reset ? cashouts : this.data.cashouts.concat(cashouts);
        this.data.has_more = has_more;
        this.data.page = page;
    };

    getSelectedWallets = (): WalletInterface[] => {
        const wallets = this.data.wallets[this.data.selectedSystem.id];

        if (!wallets) {
            goToCashouts();
            return [];
        }
        return wallets.filter(w => w.masked_account) || [];
    };

    @action
    selectWallet = (wallet: WalletInterface): void => {
        this.data.selectedWallet = wallet;
    };

    @action
    selectWallets = (wallets: WalletInterface[]): void => {
        this.data.selectedWallets = wallets;
        this.resetError();
    };

    get wallets(): WalletInterface[] {
        return flatten(Object.values(this.data.wallets));
    }

    get combinedCashoutsWallets(): WalletInterface[] {
        return this.wallets
            .filter(wallet => {
                const paymentSystem = this.data.systems[wallet.payment_system_id];
                return wallet.id &&
                    paymentSystem &&
                    paymentSystem.enabled &&
                    !paymentSystem.hidden &&
                    !paymentSystem.p2p &&
                    !paymentSystem.cashout_details_ui_component &&
                    !paymentSystem.payment_screenshot;
            });
    }

    findWalletById(walletId: number): WalletInterface {
        return this.wallets.find(wallet => wallet.id === walletId);
    }

    @action
    selectFirstWallet = (): void => {
        [this.data.selectedWallet] = this.getSelectedWallets();
    };

    @action
    resetInputWallet = (): void => {
        if (this.data.wallet_attributes.account) {
            this.data.wallet_attributes.account = null;
            this.selectFirstWallet();
        }
    };

    @action
    selectSystem = (system: PaymentSystemInterface): void => {
        this.data.amount = '';
        this.data.errors = {};
        if (!system) {
            this.data.cashout_detail_attributes = {
                accept_show_wallet: false,
                bank_account_number: '',
                bank_branch: '',
                bank_id: '',
                city: '',
                customer_name: '',
                ifsc_code: '',
                province: ''
            };
            this.data.selectedWallet = null;
            this.data.wallet_attributes = {
                account: '',
                wallet_detail_attributes: {
                    currency: '',
                    tag: ''
                }
            };
            goToCashouts();
            this.useSavedWallets();
        }
        this.data.selectedSystem = system;
    };

    selectedSystemData = (): PaymentSystemInterface => this.data.systems[this.data.selectedSystem.id];

    selectSystemBySlug = (slug: string): void => {
        this.selectSystem(Object.values(this.data.systems).find(system => system.slug === slug));
    };

    confirm = (id: number): Promise<Response> => new Post({
        params: {id},
        url: '/cashouts/confirm'
    })
        .execute()
        .then(response => {
            if (response.ok) {
                this.setCashoutCompleted(id);
            }
            return response;
        });

    invalidate = (id: number): Promise<Response> => new Post({
        params: {id},
        url: '/cashouts/invalidate'
    })
        .execute()
        .then(response => {
            if (response.ok) {
                this.setCashoutNotValid(id);
            }
            return response;
        });

    @action
    updateCashout(payload: {cashout: CashoutInterface, commission: boolean }): void {
        addOrUpdateObject(this.data, 'cashouts', payload.cashout);
        this.data.commission = payload.commission;
        this.addP2PCashout(payload.cashout);
    }

    get BTCMessage(): JSX.Element | string {
        const {selectedSystem} = this.data;

        if (!selectedSystem.verification) {
            return null;
        }

        switch (userStore.user.verified_status) {
        case 'need_verify':
        case 'not_verified':
            return <Fragment>
                {I18n.t('cashouts.verification_required.message_1')}
                <a onClick={handleShowingDocumentsUploader} href="#">
                    &nbsp;{I18n.t('cashouts.verification_required.message_2')}
                </a>
            </Fragment>;
        case 'documents_received':
            return I18n.t('cashouts.waiting_verification');
        default:
            return null;
        }
    }

    get isCashoutDisabled(): JSX.Element | string | boolean {
        const {selectedSystem, selectedWallets} = this.data;

        if (selectedWallets.length > 0) {
            return false;
        }
        return this.BTCMessage || !selectedSystem.enabled;
    }

    @action
    startCashout(handleSendCashout: (e: React.FormEvent<HTMLFormElement>) => void, type?: string): void {
        if (!type && this.isCashoutDisabled) {
            return;
        }

        if (!this.validate(type)) {
            return;
        }

        const {amount, commission} = this.data;

        if (commission) {
            modalConfirm(
                I18n.t('cashout_commission', {
                    amount: fixed2(Number(amount) * COMMISSION_PERCENT),
                    cashout_faq_link: `<a target="_blank" href="/faq#cash_out">${I18n.t('read_more')}</a>`
                }),
                () => setTimeout(() => this.askPassword(handleSendCashout, type), 700)
            );
        } else {
            this.askPassword(handleSendCashout, type);
        }
    }

    askPassword = (handleSendCashout: (e: React.FormEvent<HTMLFormElement>) => void, type: string): void => {
        modalConfirm({
            element: <form className="form-confirm-cashout" onSubmit={handleSendCashout}>
                <div className="text-description">
                    <p>{I18n.t('cashout_confirm')}</p>
                </div>
                <label className="field-label">{I18n.t('enter_password')}</label>
                <div className="field-input">
                    <input
                        type="password"
                        id="cashout_password"
                        name="cashout_password"
                        autoComplete="on"
                        className="field__input"
                    />
                </div>
            </form>,
            react: true
        },
        () => this.sendCashout(type),
        null,
        {setFocus: true});
    };

    cashoutParams = (type?: string): {
        amount: string
        password: string
        cashout_detail_attributes?: DetailInterface
        system?: number
        type?: string
        wallet_id?: number
        wallet_ids?: number[]
        wallet_attributes?: {
            account: string,
            wallet_detail_attributes?: WalletDetailInterface
        }
    } => {
        const password = $('#cashout_password').val() as string;
        const {amount, cashout_detail_attributes, selectedSystem, selectedWallet,
            selectedWallets,
            wallet_attributes} = this.data;

        if (type === 'CombinedCashout') {
            return {
                amount,
                password,
                type: 'CombinedCashout',
                wallet_ids: selectedWallets.map(wallet => wallet.id)
            };
        }

        const data = {
            amount,
            cashout_detail_attributes,
            password,
            system: selectedSystem.id,
            wallet_attributes: null,
            wallet_id: selectedWallet ? selectedWallet.id : null
        };

        if (wallet_attributes.account && !selectedWallet) {
            data.wallet_attributes = wallet_attributes;
        }

        return data;
    };

    sendCashout = (type?: string): Promise<CashoutCreateInterface> => {
        const data = this.cashoutParams(type);

        return new Post({
            params: data,
            url: getLinkPath('/cashouts')
        })
            .execute()
            .then(response => response.json())
            .then((response: CashoutCreateInterface) => {
                if (response.success) {
                    this.handleCreateSuccess(response);
                } else {
                    if (response.message) { 
                        toastError(response.message);
                    }
                    else {
                        this.setServerErrors(response.errors);
                        if (response.errors.base) {
                            toastError(response.errors.base[0]);
                        }
                    }
                }
                return response;
            });
    };

    handleCreateSuccess = (response: CashoutCreateInterface): void => {
        toastSuccess(response.message);
        userStore.update({user: response.user});
        this.setSavedWallets(response.last_used_wallets);
        this.setAmount('');
        this.loadMore(true);
        this.update({
            commission: response.commission,
            wallets: response.wallets
        } as CashoutsFormInterface);

        this.resetInputWallet();

        if (this.data.selectedSystem && this.data.selectedSystem.payment_screenshot) {
            this.updateScreenshot(null);
        }
    };

    @action
    updateWalletAccount = (account: string): void => {
        this.data.wallet_attributes.account = account;
    };

    changeCurrencyToCryptoWallet = (wallet_id: number, new_currency: string): void => {
        new Post({params: {new_currency, wallet_id}, url: '/cashouts/change_currency'})
            .execute()
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    this.update({
                        wallets: response.wallets
                    } as CashoutsFormInterface);
                    this.selectFirstWallet();
                }
            });
    };
}
