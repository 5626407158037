import PayzenBaseClass, {ErrorFieldWrapper, ErrorHint} from './PayzenBaseClass';
import pick from 'lodash/pick';
import I18n from 'app/i18n';
import React from 'react';
import $ from 'jquery';

export default class OnlineBankingDeposit extends PayzenBaseClass {
    bank = React.createRef();

    constructor(props) {
        super(props);
        this.current_country_code = 'china';
        const [defaultBank] = this.bankList;
        this.state.invoice_detail_attributes.bank_id = defaultBank && defaultBank.bank_id;
    }

    componentDidMount() {
        const element = $(this.bank.current);
        element.chosen({search_contains: true});
        element.on('change', event => {
            Object.keys(event.target.selectedOptions).forEach(optionName => {
                this.setState({
                    invoice_detail_attributes: {
                        ...this.state.invoice_detail_attributes,
                        bank_id: Number(event.target.selectedOptions[optionName].value)
                    }
                });
            });
        });
    }

    get bankList() {
        const {bankList = []} = this.props.depositsStore;
        return bankList.filter(bank => bank.country_code === this.current_country_code && bank.method === 'payin' ||
            !bank.country_code && !bank.method);
    }

    bankSelect() {
        const {invoice_detail_attributes} = this.state;
        const field = 'bank_id';
        const funcProps = {...pick(this.state, ['errors']),
            field: `invoice_detail.${field}`};
        return <div className="form__row" key="bankSelect">
            <div className="form__row-item">
                <label className="form__row-label" key={field}>{I18n.t('deposit_fields.bank')}</label>

                <ErrorFieldWrapper {...funcProps}>
                    <select
                        ref={this.bank}
                        className="field__input"
                        value={invoice_detail_attributes[field]}
                        onKeyUp={this.cleanError.bind(this, field)}
                        onChange={this.handleChangeInput.bind(this, field)}>
                        {this.bankList.map(bank => <option
                            key={bank.bank_id}
                            value={bank.bank_id}
                        >{bank.description}</option>)}
                    </select>
                    <ErrorHint {...funcProps}/>
                </ErrorFieldWrapper>
            </div></div>;
    }

    renderInputs() {
        const inputs = super.renderInputs();
        inputs.push(this.bankSelect());
        return inputs;
    }
}
