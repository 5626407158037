import React, {ReactNode} from 'react';
import I18n from 'app/i18n';

interface Props {
    onCheck: () => void
    onCall: () => void
    isCheck: boolean
    disabled?: boolean
}

export default class PokerPanelButtonCheckCall extends React.Component<Props> {
    render(): ReactNode {
        const {onCheck, onCall, disabled, isCheck} = this.props;

        return <button
            className="button-adaptive button-adaptive_casino"
            onClick={isCheck ? onCheck : onCall}
            disabled={disabled}
        >
            {isCheck ? I18n.t('poker_check') : I18n.t('poker_call')}
        </button>;
    }
}
