import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {isGuest, localDateTimeFromISO} from 'app/utils';
import TableEmptyState from '../table/empty_state';
import {signedAmount} from 'app/utils/signedAmount';
import ShowMore from 'app/utils/ShowMore';
import {ShopsStoreProps} from 'app/store/ShopStore';
import {inject, observer} from 'mobx-react';
import SmartLoader from 'app/components/reusable/SmartLoader';
import {goToLinkSimpleBets} from 'app/utils/app_links';
import dateLabel from 'app/components/my_profile/prizes/DateLabel';

@inject('shopStore')
@observer
export default class MyPurchases extends Component<ShopsStoreProps> {
    componentDidMount(): void {
        if (isGuest()) {
            goToLinkSimpleBets();
        }
    }

    render(): ReactNode {
        const {loadMore, loading, data: {userPurchases, hasMorePurchases}} = this.props.shopStore;
        return <div className="purchases">
            <h2 className="title">{I18n.t('user.history_header')}</h2>
            {loading
                ? <div className="table-more">
                    <SmartLoader />
                </div>
                : <div className="table-purchases">
                    <div className="table-purchases__heading tables-heading">
                        <div className="table-purchases__col-1">
                            <div className="table-purchases__id">ID</div>
                            <div className="table-purchases__date">{dateLabel()}</div>
                        </div>
                        <div className="table-purchases__name">{I18n.t('name')}</div>
                        <div className="table-purchases__status">{I18n.t('user.status_label')}</div>
                        <div className="table-purchases__price">{I18n.t('price')}</div>
                    </div>
                    {userPurchases &&
                        userPurchases.map((val, index) => <div className="table-purchases__main-row" key={index}>
                            <div className="table-purchases__col-1">
                                <div className="table-purchases__id">{val.id}</div>
                                <div className="table-purchases__date">{localDateTimeFromISO(val.created_at)}</div>
                            </div>
                            <div className="table-purchases__name">{val.purchase_name}</div>
                            <div className="table-purchases__status">{val.status}</div>
                            <div className="table-purchases__price">
                                <div className="mobile-label">
                                    {I18n.t('price')}
                                </div>
                                {signedAmount(Number(val.money_paid) || Number(val.points_paid),
                                    Number(val.points_paid) &&
                            I18n.t('points', {count: Number(val.points_paid)}) || '$')}
                            </div>
                        </div>)}
                    <ShowMore onClickHandler={loadMore} has_more={hasMorePurchases}/>
                    <TableEmptyState arrayLength={userPurchases?.length}
                        text={I18n.t('user.you_have_no_purchases')}/>
                </div>
            }
        </div>;
    }
}
