import React from 'react';

import {HIDDEN_TEMPLE_EN, HIDDEN_TEMPLE_RU} from 'app/consts';
import I18n from 'app/i18n';

const RU_LOCALE = 'ru';

export default class HiddenTemple extends React.Component {
    renderRu() {
        return <div>
            <div className="title">Hidden Temple: Абсолютное знание в ответах Потаенных!</div>
            <div className="content__holder">
                <img
                    alt="Hidden Temple: Абсолютное знание в ответах Потаенных!"
                    src={HIDDEN_TEMPLE_RU}
                    width="100%"
                />
                <p>В далеком прошлом Lanaya the Templar Assassin открыла скрытый проход в параллельный мир, именуемый Hidden Temple.
                    Это место оказалось огромной библиотекой, домом для Потаенных — загадочных и могущественных существ, несущих абсолютное знание.
                    Lanaya провела много времени в их обители, раскрывая их тайны и получая ответы на свои вопросы. </p>
                <p>Теперь и нам посчастливилось обнаружить скрытую дверь в Hidden Temple.
                    С поддержкой Потаенных мы получили доступ к высшей мудрости и готовы ответить на все ваши вопросы.
                    Даже самые сложные! </p>
                <p>Однако Потаенные очень заняты и предпочли бы не отвлекаться по мелочам.
                    Поэтому мы создали специальную форму. Отправляйте свои вопросы в один клик.
                    Мы отберем самые интересные из них и опубликуем ответы Потаенных в одноименной рубрике.</p>
                <p>С нетерпением ждем ваших лучших вопросов.</p>
            </div>
        </div>;
    }

    render() {
        if (I18n.locale === RU_LOCALE) {
            return this.renderRu();
        }

        return <div>
            <div className="title">Hidden Temple: Absolute knowledge in the answers of the Hidden Ones!</div>
            <div className="content__holder">
                <img
                    alt="Hidden Temple: Absolute knowledge in the answers of the Hidden Ones!"
                    src={HIDDEN_TEMPLE_EN}
                    width="100%"
                />
                <p>Long ago, Lanaya, the Templar Assassin, discovered a secret entryway to the parallel world called the Hidden Temple.
                    This place turned out to be a huge library, home to the Hidden Ones, mysterious and powerful beings who carry absolute knowledge.
                    Lanaya spent a lot of time in their service, uncovering their secrets and getting answers to her questions. </p>
                <p>Now, we have been fortunate enough to discover a secret door to the Hidden Temple, too.
                    With the support of the Hidden Ones, we have gained access to the ultimate wisdom and are ready to answer all your questions.
                    Even the most difficult ones! </p>
                <p>However, the Hidden Ones are very busy.
                    They would prefer not to be distracted by trivial matters.
                    Therefore, we have created a special form.
                    Send your questions in one click.
                    We will select the most interesting of them and publish the answers of the Hidden Ones in the column of the same name.</p>
                <p>We look forward to your best questions.</p>
            </div>
        </div>;
    }
}
