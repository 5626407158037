import {BaseController} from 'app/controllers/BaseController';
import Get from 'app/utils/Get';
import {action} from 'mobx';
import {ArtifactStore} from 'app/store/ArtifactStore';

export class ArtifactStoreController extends BaseController {
    artifactStore: ArtifactStore;

    userName: string;

    constructor(userName: string, artifactStore: ArtifactStore) {
        super();
        this.userName = userName;
        this.artifactStore = artifactStore;
    }

    @action
    setUserName = (username: string): void => {
        this.userName = username;
    };

    @action
    loadProfile = (): void => {
        const {artifactStore} = this;

        artifactStore.setLoading(true);
        new Get({
            params: {name: this.userName},
            url: '/artifacts/inventory'
        })
            .execute()
            .then(response => response.json())
            .then(response => {
                if (response.denied) {
                    artifactStore.updateArtifacts([]);
                } else {
                    const inventory_items = response.artifacts;
                    const inventory_items_effects = response.artifacts_effects;
                    artifactStore.updateStore({inventory_items,
                        inventory_items_effects});
                }
                artifactStore.setDenied(response.denied);
                artifactStore.setTradeEnabled(response.tradeEnabled);
                artifactStore.setUser(response.user);
            }).finally(() => {
                artifactStore.setLoading(false);
            });
    };
}
