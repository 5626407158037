import {action, makeObservable, observable} from 'mobx';
import Get from 'app/utils/Get';
import {getLinkPath} from 'app/utils';

export interface FaqInterface {
    title: string
    text: string
    name: string
}

export class FaqStore {
    @observable faqs: FaqInterface[] = [];

    @observable loading: boolean = true;

    constructor() {
        makeObservable(this);
    }

    @action
    init(faqs: FaqInterface[]): void {
        this.faqs = faqs;
        this.setLoading(false);
    }

    @action
    setLoading(loading: boolean): void {
        this.loading = loading;
    }

    loadData(): void {
        new Get({
            url: getLinkPath('/faq')
        })
            .execute()
            .then(response => response.json())
            .then(response => this.init(response));
    }
}
