import LineInterface from 'app/interfaces/LineInterface';
import {isHandicapLine} from 'app/lines/isHandicapLine';
import {isAdvantageLine} from 'app/lines/isAdvantageLine';
import {lineGamer} from 'app/lines/lineGamer';
import {GAMER_1} from 'app/utils/Consts';

export const formGamerTitle = (parentLine: LineInterface, selectedLine: LineInterface, num: number): string => {
    if (isHandicapLine(selectedLine) || lineGamer(selectedLine, GAMER_1).type) {
        return '';
    }
    if (isAdvantageLine(selectedLine)) {
        return parentLine[`gamer_${selectedLine.gamer}`].nick;
    }
    return parentLine[`gamer_${num}`].nick;
};
