import {ELEMENT_NOT_FOUND} from 'app/utils/Consts';

const findObjectById = newObject => object => object?.id === newObject?.id;

export interface ObjectWithId {
    id: number | string;
}

export const addOrUpdateObject = <T>(context: T, key: string, newObject: ObjectWithId): void => {
    const array: ObjectWithId[] = context[key];
    const index = array.findIndex(findObjectById(newObject));

    if (index === ELEMENT_NOT_FOUND) {
        array.push(newObject);
        context[key] = [...array];
        return;
    }
    updateObject(context, key, newObject);
};

export const updateObject = <T>(context: T, key: string, newObject: ObjectWithId): boolean => {
    const array: ObjectWithId[] = context[key];
    const index = array ? array.findIndex(findObjectById(newObject)) : ELEMENT_NOT_FOUND;

    if (index === ELEMENT_NOT_FOUND) {
        return false;
    }

    array[index] = {
        ...array[index],
        ...newObject
    };
    context[key] = [...array];
    return true;
};

export const updateOrAddObject = <T>(context: T, key: string, newObject: ObjectWithId): void => {
    if (!updateObject(context, key, newObject)) {
        const array: ObjectWithId[] = context[key];
        context[key] = [...array, newObject];
    }
};

export const updateOrAddObjectToStart = <T>(context: T, key: string, newObject: ObjectWithId): void => {
    if (!updateObject(context, key, newObject)) {
        const array: ObjectWithId[] = context[key];
        context[key] = [newObject, ...array];
    }
};

export const deleteObjectBy = <T, U>(context: T, key: string, fn: (obj: U) => boolean): void => {
    const index = context[key].findIndex(fn);

    if (index === ELEMENT_NOT_FOUND) {
        return;
    }
    context[key].splice(index, 1);
    context[key] = [...context[key]];
};

export const deleteObjectById = <T>(context: T, key: string, objectId: number | string): void => {
    deleteObjectBy(context, key, findObjectById({id: objectId}));
};

export const hasElementInObject = <T>(context: T, key: string, objectId: number | string): boolean => {
    if (!context || !Array.isArray(context[key])) {
        return false;
    }

    const array: ObjectWithId[] = context[key];
    const index = array.findIndex(element => element.id === objectId);

    return index !== ELEMENT_NOT_FOUND;
};

export const getObjectById = <T>(objects: T[], objectId: number | string): T => objects.find(findObjectById({id: objectId}));
