import React, {PureComponent} from 'react';
import UserInventoryLink from 'app/helpers/UserInventoryLink';
import isHiddenName from 'app/utils/isHiddenName';

export default class TableLeadersRow extends PureComponent {
    render() {
        const {
            place,
            rating_points,
            user: {name, public_inventory}
        } = this.props;

        return <div className="aside-table__row table-leaders__row">
            <div className="aside-table__col table-leaders__col table-leaders__col--place">
                {place}
            </div>
            <div className="aside-table__col table-leaders__col table-leaders__col--nickname">
                <span className="ellipsis">
                    <UserInventoryLink
                        user_nickname={name}
                        public_inventory={!isHiddenName(name) && public_inventory}
                    />
                </span>
            </div>
            <div className="aside-table__col table-leaders__col table-leaders__col--rp">
                {rating_points}
            </div>
        </div>;
    }
}
