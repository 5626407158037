import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {ajaxError, betMoney, getLinkPath, isGuest, slowConnection} from 'app/utils';
import {SimpleArtifactImage} from 'app/components/my_profile/artifacts/image';
import $ from 'jquery';
import {orderStore, shopStore} from 'app/store/global';
import {hideLastPopup} from 'app/utils/modals/ModalsService';
import {modalAlert, modalConfirm} from 'app/utils/modals/popups';
import {artifactName} from 'app/components/my_profile/artifacts/utils';
import {openAuth} from 'app/components/auth/auth';
import UserInventoryLink from 'app/helpers/UserInventoryLink';
import {confirmGoToDeposits} from 'app/utils/app_links';
import {toastError} from 'app/components/toasts/liteToast';

export default class Order extends PureComponent {
    handleBuy = () => {
        if (isGuest()) {
            openAuth();
            return;
        }

        const {query, id, artifact} = this.props;

        if (this.disableButton) {
            return;
        }

        modalConfirm(I18n.t('buy_artifact_confirm', {artifact: artifactName(artifact)}),
            () => slowConnection($.ajax(getLinkPath(`/artifact_orders/${id}/buy`), {
                data: query,
                error: this.handleError,
                success: response => {
                    orderStore.updateOrders(response);
                    shopStore.updatePurchasesData(response.purchases, response.has_more, 1);
                },
                type: 'POST'
            })));
    };

    handleError = xhr => {
        const {responseJSON} = xhr;

        // WTF is that?
        if (responseJSON &&
            responseJSON['bid_user.bets'] &&
            responseJSON['bid_user.bets'][0] === I18n.t('insufficient_funds')) {
            return confirmGoToDeposits();
        } else if (responseJSON) {
            const unprocessableEntity = 422;

            orderStore.updateOrders(responseJSON);
            hideLastPopup();
            if (xhr.status === unprocessableEntity) {
                const message = responseJSON[Object.keys(responseJSON)[0]];

                if (message) {
                    return toastError(message[0]);
                }
            }
            modalAlert(I18n.t('there_was_a_problem_purchasing_your_item'));
        } else {
            ajaxError(xhr);
        }
    };

    get disableButton() {
        return this.props.self_order || this.props.restrict_artifact;
    }

    render() {
        const {price, artifact, recipe, ask_user: {name, public_inventory}} = this.props;

        return <div className="market-details__item">
            <div className="market-details__item__name-seller">
                <div className="market-details__item--img-wrap">
                    <div className="market-details__item--img">
                        <SimpleArtifactImage artifact={artifact}/>
                    </div>
                </div>
                <div className="market-details__item--desc-seller">
                    <div className="market-details__item--desc">
                        <div className="market-details__item--desc-title">
                            {artifactName(recipe)}
                        </div>
                    </div>
                    <div className="market-details__item__seller">
                        <span className="mobile-label">{I18n.t('seller')}:</span>
                        <span className="field-value">
                            <UserInventoryLink
                                user_nickname={name}
                                public_inventory={public_inventory}
                            />
                        </span>
                    </div>
                </div>
            </div>
            <div className="market-details__item__price-actions">
                <div className="market-details__item__price-value">{betMoney({amount: price})}</div>
                <div className="market-details__item__actions">
                    <button className={`btn${this.disableButton ? ' btn--disable' : ''}`}
                        onClick={this.handleBuy}
                    >{I18n.t('buy_now')}</button>
                </div>
            </div>
        </div>;
    }
}
