import React, {PureComponent, ReactNode} from 'react';

interface SmartLoaderProps {
    loaderClassName?: string
}

export default class SmartLoader extends PureComponent<SmartLoaderProps> {
    render(): ReactNode {
        const {loaderClassName} = this.props;
        return <div className={`smart-loader ${loaderClassName ? loaderClassName : ''}`}>
            <svg className="svg-default svg-loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 260">
                <g className="svg-animate-g-1" transform="translate(131,130.5) translate(-132.5,-132)">
                    <ellipse className="svg-animate-e-1" rx="55" ry="55"
                        fill="#fed631" transform="translate(61.5,61.5)"/>
                    <ellipse className="svg-animate-e-2" rx="55" ry="55"
                        fill="#fed631" transform="translate(201.5,61.5)"/>
                    <ellipse className="svg-animate-e-3" rx="55" ry="55"
                        fill="#fed631" transform="translate(61.5,201.5)"/>
                    <ellipse className="svg-animate-e-4" rx="55" ry="55"
                        fill="#fed631" transform="translate(201.5,201.5)"/>
                </g>
            </svg>
        </div>;
    }
}
