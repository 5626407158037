import {modalConfirm} from 'app/utils/modals/popups';
import I18n from 'app/i18n';
import {P2pDepositsStore} from 'app/store/P2pDepositsStore';
import {betMoney} from 'app/utils';

export const leavePage = (p2pDepositsStore: P2pDepositsStore): boolean => {
    const {current_p2p_deposit} = p2pDepositsStore;

    if (!current_p2p_deposit || !current_p2p_deposit.working) {
        return false;
    }

    modalConfirm(
        I18n.t('undo_deposit', {amount: betMoney({amount: current_p2p_deposit.grouped_amount})}),
        () => {
            p2pDepositsStore.cancelP2pDeposit(current_p2p_deposit.id);
        }
    );
    return true;
};
