import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import DepositWallet from './DepositWallet';
import {inject, observer} from 'mobx-react';
import {DepositsStoreProps} from 'app/store/DepositsStore';
import WalletInterface from 'app/interfaces/WalletInterface';

@inject('depositsStore')
@observer
export default class DepositWallets extends Component<DepositsStoreProps> {
    selectWallet = (wallet: WalletInterface): void => {
        this.props.depositsStore.selectWallet(wallet);
    };

    render(): ReactNode {
        const wallets = this.props.depositsStore.currentSystemWallets;
        return wallets.length > 0 && <div className="deposit__wallets">
            {wallets.map((w, i) => <DepositWallet
                key={i}
                wallet={w}
                defaultChecked={i === 0}
                onClick={this.selectWallet.bind(null, w)}
            />)}
            <DepositWallet
                key="w0"
                wallet={{masked_account: I18n.t('new_wallet')} as WalletInterface}
                defaultChecked={false}
                onClick={this.selectWallet.bind(null, null)}
            />
        </div>;
    }
}
