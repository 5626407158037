import React, {Component, Fragment} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';

import {checkingPages, contentOnly, isDesktop} from 'app/utils';
import {goToLinkSimpleBets} from 'app/utils/app_links';
import logEvent from 'app/amplitude/log_event';
import {setStreamsTimeout, showMoreStreams} from 'app/components/streams/stream_functions';
import Stream from 'app/components/streams/stream';
import StreamPopup from 'app/components/streams/stream_popup';
import {streamStore} from 'app/store/global';
import ButtonMoreLess from './button_more_less';
import {openAddStreamPopup} from 'app/components/streams';

export default inject('streamStore', 'userStore')(observer(class StreamsPlayPage extends Component {
    constructor() {
        super();
        this.closeStreamHandler = this.closeStreamHandler.bind(this);
    }

    rootElement = React.createRef();

    get userStreams() {
        return this.props.streamStore.userStreams;
    }

    get streams() {
        return this.props.streamStore.streams;
    }

    componentDidMount() {
        this.showMoreHandler();
        setStreamsTimeout();
    }

    componentDidUpdate() {
        this.showMoreHandler();
    }

    showMoreHandler() {
        showMoreStreams(this.rootElement.current);
    }

    openStreamHandler = stream => {
        logEvent('STREAM_OPENED');
        if (checkingPages.isHomePage()) {
            goToLinkSimpleBets();
        }
        streamStore.chooseStream(stream);
    };

    closeStreamHandler() {
        this.openStreamHandler(null);
    }

    handleStreamCreationPopup = e => {
        e.preventDefault();
        openAddStreamPopup();
    };

    render() {
        const {stream, hideQueryParams, opened, imageLastUpdate} = this.props.streamStore;
        const {hiddenStream} = this.props;
        const style = {backgroundColor: '#2C2D2E'};
        const hasAnyStreams = this.userStreams.length > 0 || this.streams.length > 0;
        return <Fragment>
            {stream && <div className="popup-stream" style={{display: 'block'}}>
                <StreamPopup
                    hideQueryParams={hideQueryParams}
                    opened={opened}
                    userStreams={this.userStreams}
                    streams={this.streams}
                    stream={stream}
                    closeStreamHandler={this.closeStreamHandler}
                    hiddenStream={!isDesktop() && hiddenStream}/>
            </div>}
            {isDesktop() && !contentOnly() && !stream && !hiddenStream &&
            <div className="stream-list stream-list--play" ref={this.rootElement}>
                <div className="stream-list__heading">
                    {I18n.t('stream_title')}
                    {hasAnyStreams && <ButtonMoreLess opened={opened}/>}
                </div>
                <div
                    className={`stream-list__streams js-streams-container ${opened
                        ? 'stream-list__streams--expanded'
                        : ''}`}>
                    <div className="stream-list__streams-holder js-streams-maxheight">
                        {this.streams.map(s => <Stream
                            stream={s}
                            preview="play"
                            key={s.id}
                            imageLastUpdate={imageLastUpdate}
                            onClick={this.openStreamHandler}
                        />)}
                        {this.userStreams.map(s => <Stream
                            stream={s}
                            preview="play"
                            key={s.id}
                            imageLastUpdate={imageLastUpdate}
                            userStream
                            onClick={this.openStreamHandler}
                        />)}
                        <a
                            href="#"
                            data-popup="#stream-add"
                            style={style}
                            onClick={this.handleStreamCreationPopup}
                            className="js-streams-minheight media-preview media-preview--play"
                        >
                            <span title={I18n.t('add_your_own')}>
                                <svg height="50px" width="50px">
                                    <use xlinkHref="#svg-stream-add"/>
                                </svg>
                            </span>
                            <span className="media-preview__name">
                                <span className="media-preview__name-text text-center">{I18n.t('add_your_own')}</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>}
            <div id="dividerStream"/>
            {this.props.children}
        </Fragment>;
    }
}));
