import React, {PureComponent} from 'react';
import {SimpleArtifactImage} from 'app/components/my_profile/artifacts/image';
import TimeLeft from './hero_scrolls/TimeLeft';

export default class HeroEffect extends PureComponent {
    render() {
        const {recipe, description, time_left} = this.props;

        return <div className="hero__scroll--item">
            <div className="hero__scroll--item__img">
                <SimpleArtifactImage
                    artifact={{...recipe}}
                />
            </div>
            <div className="hero__scroll--item__descr user-color user-color__yellow">
                <div
                    className="hero__scroll--item__title"
                    dangerouslySetInnerHTML={{__html: description}}
                />
                <TimeLeft time_left={time_left}/>
            </div>
        </div>;
    }
}
