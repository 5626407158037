import React, {ReactNode} from 'react';
import PokerPanelButtonFold from 'app/components/poker/poker_controller_panel/PokerPanelButtonFold';
import PokerPanelButtonCheckCall from 'app/components/poker/poker_controller_panel/PokerPanelButtonCheckCall';
import PokerPanelButtonBetRaise from 'app/components/poker/poker_controller_panel/PokerPanelButtonBetRaise';
import PokerPanelButtonPokerGoBet from 'app/components/poker/poker_controller_panel/PokerPanelButtonPokerGoBet';
import {inject, observer} from 'mobx-react';
import {PokerStore} from 'app/store/PokerStore';
import {PokerController} from 'app/controllers/PokerController';

interface Props {
    pokerStore?: PokerStore
    controller?: PokerController
}

@inject('pokerStore', 'controller')
@observer
export default class PokerControllerPanel extends React.Component<Props> {
    state = {
        amount: '0'
    };

    check = (): void => {
        this.props.controller.check(this.props.pokerStore.currentTable.table_id);
    };

    call = (): void => {
        this.props.controller.call(this.props.pokerStore.currentTable.table_id);
    };

    fold = (): void => {
        this.props.controller.fold(this.props.pokerStore.currentTable.table_id);
    };

    bet = (): void => {
        this.props.controller.bet(this.props.pokerStore.currentTable.table_id, this.state.amount);
    };

    allIn = (): void => {
        this.props.controller.allIn(this.props.pokerStore.currentTable.table_id);
    };

    changeAmount = (amount: string): void => {
        this.setState({amount});
    };

    isCheck = (): boolean => {
        const maxBet = Math.max(...this.props.pokerStore.currentTable.players?.map(item => item.bet) || [0]);

        return this.props.pokerStore.player.bet >= maxBet;
    };

    isAllIn = (): boolean => {
        const {player, currentTable} = this.props.pokerStore;
        const maxBet = Math.max(...currentTable.players?.map(item => item.bet) || [0]);

        return player.chips < maxBet && player.bet < maxBet || Number(this.state.amount) >= player.chips;
    };

    render(): ReactNode {
        const {player} = this.props.pokerStore;

        if (!player) {
            return <></>;
        }

        return <div className="poker-controller-panel">
            <PokerPanelButtonFold disabled={player.waiting} onClick={this.fold}/>
            <PokerPanelButtonCheckCall onCheck={this.check} onCall={this.call} isCheck={this.isCheck()} disabled={player.waiting}/>
            <PokerPanelButtonBetRaise disabled={player.waiting} onClick={this.bet} onAllIn={this.allIn} isAllIn={this.isAllIn()} />
            <PokerPanelButtonPokerGoBet changeAmount={this.changeAmount}/>
        </div>;
    }
}
