import React, {PureComponent, ReactNode} from 'react';
import {fixed2, localDateTimeFromISO} from 'app/utils';
import PublicInventoryLink from 'app/utils/PublicInventoryLink';
import {dateLabel} from 'app/utils/dateLabel';
import I18n from 'app/i18n';

interface User {
    name: string;
    referred_from: string;
}

interface RowProps {
    user: User;
    paid_amount: number;
}

export default class Row extends PureComponent<RowProps> {
    render(): ReactNode {
        const {user, paid_amount} = this.props;

        return <div className="table-content-row">
            <div className="table-content-cell">
                <span className="mobile-label">{dateLabel()}:</span>
                {localDateTimeFromISO(user.referred_from)}
            </div>
            <div className="table-content-cell">
                <span className="mobile-label">{I18n.t('table.nickname_title')}:</span>
                <PublicInventoryLink user={user}/>
            </div>
            <div className="table-content-cell">
                <span className="mobile-label">{I18n.t('table.earned')}:</span>
                {fixed2(paid_amount)}
            </div>
        </div>;
    }
}
