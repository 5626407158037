import React from 'react';
import I18n from 'app/i18n';
import {isDesktop} from 'app/utils';
import UserInformation from './UserInformation';
import {resetStat} from './user_reset_popup';
import UserStats from './user_stats';
import {inject, observer} from 'mobx-react';
import SmartLoader from 'app/components/reusable/SmartLoader';
import UserBalance from 'site_version/common/UserBalance';
import {UserStore} from 'app/store/UserStore';
import {UserBonusesStore} from 'app/store/UserBonusesStore';

interface Props {
    userStore?: UserStore,
    userBonusesStore?: UserBonusesStore
}

@inject('userStore', 'userBonusesStore')
@observer
export default class Settings extends React.Component<Props> {
    componentDidMount = (): void => {
        this.props.userStore.updateProfile();
    };

    render(): React.ReactNode {
        const {
            active_bets_sum,
            bet_stats,
            balance,
            play_money,
            points,
            bonus_balance,
            verified_status
        } = this.props.userStore.user;
        const {bonuses} = this.props.userBonusesStore;
        return <div id="profile" className="profile profile-tab">
            <div className="profile__balance row-columns">
                <div className="column-half">
                    {isDesktop() && <UserBalance
                        balance={balance}
                        bonus_balance={bonus_balance}
                        active_bets_sum={active_bets_sum}
                        bonuses={bonuses}
                        noWrap
                        play_money={play_money}
                        points={points}
                        verified_status={verified_status}/>
                    }
                    <div className="profile__stat-wrap">
                        <div className="profile__stat-dl">
                            <dl className="profile__stat">
                                <dt>{I18n.t('user.bets_count_label')}</dt>
                                <dd>{bet_stats && bet_stats.bets_count}</dd>
                            </dl>
                            <dl className="profile__stat">
                                <dt>{I18n.t('user.won')}</dt>
                                <dd>{bet_stats && bet_stats.count_of_wins} ({bet_stats && bet_stats.win_rate}%)</dd>
                            </dl>
                        </div>
                        <div className="profile__btn">
                            <button className="btn" onClick={resetStat}>{I18n.t('user.reset')}</button>
                        </div>
                    </div>
                    {bet_stats ? <UserStats data={bet_stats}/> : <SmartLoader/>}
                    <UserInformation/>
                </div>
            </div>
        </div>;
    }
}
