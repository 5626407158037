import {getNavigate} from 'app/utils/navigation';

export const navigateGoTo = (
    url: Location | string,
    queryParam?: string[][] | Record<string, string> | string | URLSearchParams,
    replace :boolean = false
): void => {
    const navigate = getNavigate();
    const params = new URLSearchParams(queryParam);
    const urlParams = params.size === 0 ? '' : `?${params}`;

    return navigate
        ? navigate(`${url}${urlParams}`, {replace})
        : window.history.pushState(null, '', `${url}${urlParams}`);
};
