import React, {Component, Fragment} from 'react';
import {inject, observer} from 'mobx-react';
import {PageContent} from 'app/components/page_content';
import I18n from 'app/i18n';

const About = inject('pageContentStore')(observer(class AboutImpl extends Component {
    // eslint-disable-next-line class-methods-use-this
    render() {
        return <Fragment>
            <h1 className="title">{I18n.t('about_us_menu')}</h1>
            <PageContent contentUrl="/about" contentHolder contentKey="about"/>
        </Fragment>;
    }
}));

export default About;
