import {action, makeObservable, observable} from 'mobx';
import GiveawayInterface from 'app/interfaces/GiveawayInterface';
import Post from 'app/utils/Post';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import I18n from 'app/i18n';
import logEvent from 'app/amplitude/log_event';
import NotificationStore from 'app/store/notifications';
import {GiveawayUserPayloadInterface} from 'app/interfaces/GiveawayUserPayloadInterface';

const BAD_REQUEST = 400;

export class GiveawayStore {
    @observable giveaway: GiveawayInterface = null;

    @observable subscription = null;

    constructor() {
        makeObservable(this);
    }

    @action
    init(giveaway: GiveawayInterface): void {
        this.giveaway = giveaway;
    }

    takePart(): Promise<void|Response> {
        return new Post({
            params: {giveaway_id: this.giveaway.giveaway.id},
            url: '/giveaways'
        })
            .execute()
            .then(response => {
                if (response.ok) {
                    toastSuccess(I18n.t('part_taken'));
                    logEvent('giveaway_take_a_part');
                } else if (response.status === BAD_REQUEST) {
                    return response.json();
                }
                return {};
            }).then((data: {error?: string}): void => {
                if (data.error === 'giveaway_confirm_user') {
                    NotificationStore.add({type: 'giveaway_confirm_user'});
                } else if (data.error === 'giveaway_turnover') {
                    toastError(I18n.t('giveaway_turnover'));
                }
            });
    }

    @action
    updateGiveaway(giveaway: GiveawayInterface): void {
        this.giveaway = {...this.giveaway, ...giveaway};
    }

    @action
    updateUserInfo(giveaway_id: number, participated: boolean): void {
        if (this.giveaway.giveaway.id === giveaway_id) {
            this.giveaway.participated = participated;
        }
    }

    listenUserGiveaway(payload: GiveawayUserPayloadInterface): void {
        this.updateUserInfo(payload.giveaway_id, payload.participated);
    }
}
