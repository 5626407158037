import React, {ReactNode, Component, ChangeEvent} from 'react';
import I18n from 'app/i18n';
import InputError, {fieldErrorClass} from '../InputError';
import {inject, observer} from 'mobx-react';
import {DepositsStoreProps} from 'app/store/DepositsStore';

@inject('depositsStore')
@observer
export default class DepositPromoCode extends Component<DepositsStoreProps> {
    depositUpdatePromoCode = (event: ChangeEvent<HTMLInputElement>): void => {
        this.props.depositsStore.updatePromoCode(event.target.value);
    };

    render(): ReactNode {
        const {errors} = this.props.depositsStore;
        const error = errors['deposit_promo_code.promo_code'] || errors.promo_code;
        const {promoCode} = this.props.depositsStore;
        return <div className="form__row">
            <div className="form__row-item">
                <label className="form__row-label">{I18n.t('user.promo_code_label')}</label>
                <div className={fieldErrorClass(error)}>
                    <div className="field__wrap">
                        <input
                            className="field__input"
                            value={promoCode}
                            onChange={this.depositUpdatePromoCode}
                            autoComplete="none"
                        />
                        {error && <InputError error={error}/>}
                    </div>
                </div>
            </div>
        </div>;
    }
}
