import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {CopyToClipboard} from 'react-copy-to-clipboard';

interface CopyableAddressProps {
    label: string
    address: string
    grayLabel?: boolean
}

export default class CopyableAddress extends PureComponent<CopyableAddressProps> {
    render(): ReactNode {
        const {address, label, grayLabel} = this.props;
        return <div className="card-qr-info">
            <span className={grayLabel ? 'card-deposit-limits__title' : 'caption'}>{label}:</span>
            <span className="total">{address}</span>
            <div className="copy-button">
                <svg className="icon">
                    <use xlinkHref="#svg-copy-icon" fill="none"/>
                </svg>
                <CopyToClipboard text={address}>
                    <span className="name">{I18n.t('copy')}</span>
                </CopyToClipboard>
            </div>
        </div>;
    }
}
