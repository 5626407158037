import React from 'react';
import I18n from 'app/i18n';
import Get from 'app/utils/Get';

export default class FormProgressBar extends React.PureComponent {
    constructor(props) {
        super();
        this.state = {timeRemaining: props.delayTime, message: ''};
        this.timerProgressBar = null;
        this.timerQuery = null;
    }

    clearTimers() {
        clearInterval(this.timerProgressBar);
        clearInterval(this.timerQuery);
    }

    componentDidMount() {
        this.timerProgressBar = setInterval(() => {
            this.setState({timeRemaining: this.state.timeRemaining - 1}, () => {
                if (this.state.timeRemaining <= 0) {
                    clearInterval(this.timerProgressBar);
                    this.scheduleClose(1000);
                }
            });
        }, 1000);

        if (!this.props.id) {
            return;
        }
        this.timerQuery = setInterval(() => {
            new Get({url: `/bets/delayed_bet?id=${this.props.id}`})
                .execute()
                .then(response => response.json())
                .then(response => {
                    if (response.success) {
                        this.clearTimers();
                        this.setState({message: response.message});
                        this.scheduleClose();
                    }
                });
        }, 2000);
    }

    componentWillUnmount() {
        this.clearTimers();
    }

    getPercentProgressBar() {
        return ((this.props.delayTime - this.state.timeRemaining) / this.props.delayTime * 100).toFixed(0);
    }

    scheduleClose(timeout = 30000) {
        const {afterFinish} = this.props;

        if (afterFinish) {
            setTimeout(afterFinish, timeout);
        }
    }

    render() {
        const progressBarStyle = {width: `${this.getPercentProgressBar() }%`};
        return <div>
            <div className="simple-popup__text center">{I18n.t('common_log.live_bet')}</div>
            <div className="progress-tiny progress-tiny--big">
                <div style={progressBarStyle} className="progress-tiny__done"/>
            </div>
            <div className="simple-popup__text center">
                {this.state.message ? this.state.message : this.state.timeRemaining}
            </div>
        </div>;
    }
}
