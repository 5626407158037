/* eslint-disable max-classes-per-file */
import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import SitePagination from 'app/components/pagination';

export default class Pagination extends SitePagination {
    render() {
        if (!this.needThePaginate()) {
            return null;
        }

        const {current_page, total_pages} = this.props;
        return <ul className="smart-pagination marketplace">
            <li className={current_page === 1 ? 'disabled' : null}>
                <Link to={{
                    ...location,
                    query: this.queryParams(current_page - 1)
                }}
                onClick={this.previousPage}
                className="button-pagination prev">
                    <svg className="svg-default">
                        <use xlinkHref="#pag-arr-left"/>
                    </svg>
                </Link>
            </li>
            {this.enumeration().map((page, index) => typeof page === 'number'
                ? <Page key={index} current_page={current_page} page={page} location={location}
                    query={this.queryParams(page)} changePage={this.changePage} queryParams={this.queryParams}/>
                : <li key={index} className="disabled">
                    <Link className="button-adaptive button-adaptive_instyle">{page}</Link>
                </li>)}

            <li className={current_page === total_pages ? 'disabled' : null}>
                <Link to={{
                    ...location,
                    query: this.queryParams(current_page + 1)
                }}
                onClick={this.nextPage}
                className="button-pagination next">
                    <svg className="svg-default">
                        <use xlinkHref="#pag-arr-right"/>
                    </svg>
                </Link>
            </li>
        </ul>;
    }
}

class Page extends PureComponent {
    handleChangePage = e => {
        e.preventDefault();
        this.props.changePage(this.props.query);
    };

    render() {
        const {current_page, page, location, query} = this.props;
        return <li className={current_page === page ? 'active' : null}>
            <Link to={{
                ...location,
                query
            }}
            onClick={this.handleChangePage}
            className="button-adaptive button-adaptive_instyle">{page}</Link>
        </li>;
    }
}
