import React from 'react';
import I18n from 'app/i18n';
import {IncubatorShiftController} from 'app/controllers/IncubatorShiftController';
import {modalConfirm} from 'app/utils/modals/popups';
import {betMoney} from 'app/utils';
import IncubatorAmountInput from 'app/components/pages/incubator/IncubatorAmountInput';
import {confirmGoToDeposits} from 'app/utils/app_links';
import ModalResponseInterface from 'app/interfaces/ModalResponseInterface';
import {IncubatorCurrentPledge} from 'app/components/pages/incubator/IncubatorCurrentPledge';
import {toastError} from '../../toasts/liteToast';

export const incubatorTopUpModal = (controller: IncubatorShiftController, message: string): void => {
    modalConfirm({
        element: <>
            <p>{message}</p>
            <p>{I18n.t('main_balance')}:&nbsp;{betMoney({amount: controller.userStore.user.balance})}</p>
            <IncubatorAmountInput controller={controller}/>
        </>,
        react: true
    }, () => handleTopUpOk(controller));
};

export const incubatorTopUpErrorModal = (controller: IncubatorShiftController, error: string): void => {
    modalConfirm({
        element: <>
            <p>{I18n.t('not_enough_funds_on_your_pledge_balance')}</p>
            <br/>
            <p>{error}</p>
            <br/>
            <p>
                {I18n.t('current_pledge_balance')}:&nbsp;
                <IncubatorCurrentPledge
                    availablePledge={controller.incubatorShiftStore.availablePledge()}
                    initialPledge={controller.incubatorShiftStore.initialPledge()}
                />
            </p>
            <p>{I18n.t('main_balance')}:&nbsp;{betMoney({amount: controller.userStore.user.balance})}</p>
            <IncubatorAmountInput controller={controller}/>
        </>,
        react: true
    }, () => handleTopUpOk(controller));
};

const handleTopUpOk = (controller: IncubatorShiftController): ModalResponseInterface => {
    const {shiftPledge, userStore} = controller;

    if (Number(shiftPledge) > Number(userStore.user.balance)) {
        confirmGoToDeposits();
        return {finished: true};
    }

    if (!Number(shiftPledge) || Number(shiftPledge) === 0) {
        toastError(I18n.t('pledge_must_be_greater', {amount: 0}));
        return {finished: false};
    }
    controller.startShift();

    return {finished: false};
};
