import React, {Component, MouseEvent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {showPopup} from 'app/utils/modals/ModalsService';
import {ChatStore} from 'app/store/ChatStore';
import {modalsStore} from 'app/store/global';

interface Props {
    popupId: string
    chatStore: ChatStore,
    id?: number
}

interface State {
    mutedList: number[]
}

export const showMutedUsersListPopup = (chatStore: ChatStore): void => {
    showPopup('block-list', (props: {popupId: string}) => <MutedUsersListPopup chatStore={chatStore} popupId={props.popupId}/>);
};

export default class MutedUsersListPopup extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            mutedList: this.props.chatStore.mutedUsersList
        };
    }

    updateMutedList = (): void => this.setState({mutedList: this.props.chatStore.mutedUsersList});

    removeMutedUser(userId: number, event: MouseEvent): void {
        event.preventDefault();
        const {chatStore} = this.props;
        chatStore.removeUserMute(userId, this.updateMutedList);
    }

    close = (): void => {
        modalsStore.hideModal(this.props.popupId);
    };

    render(): ReactNode {
        return <PopupDialog id={this.props.popupId}>
            <div className="popup__content popup__content--small">
                <div className="block-user">
                    <div className="title">{I18n.t('muted_users')}</div>
                    <ul className="block-user__list">
                        {Object.keys(this.state.mutedList).map(key => <li key={`user-${key}`}
                            className="block-user__list-item">
                            <span className="block-user__list-name">{this.state.mutedList[key]}</span>
                            <a href="#" className="block-user__list-unblock"
                                onClick={this.removeMutedUser.bind(this, key)}>{I18n.t('unblock')}</a>
                        </li>)}
                    </ul>
                </div>
            </div>
        </PopupDialog>;
    }
}
