import React from 'react';
import PokerCard from 'app/components/poker/poker_cards/PokerCard';
import {CARDS} from 'app/consts/poker-cards';

const SIGN_POSITION = 0;
const SUIT_POSITION = 2;

export const selectCard = (card: string): React.ReactElement => {
    const card_strings = card.split('_');
    const sign = card_strings[SIGN_POSITION];
    const suit = card_strings[SUIT_POSITION];

    return <PokerCard sign={CARDS[sign]} suit={suit.toLowerCase()} />;
};
