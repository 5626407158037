import React from 'react';

const ActionButton = props => {
    const {onClickHandler, btnText, public_view, btnClass, openingLootbox, disabled = false, btnRemoveClassBtn} = props;
    return !(public_view || openingLootbox) && <button
        disabled={disabled}
        className={`${btnRemoveClassBtn ? '' : 'btn'} ${(disabled ? ' btn--disable' : '')}${(btnClass ? ` ${btnClass}` : '')}`}
        onClick={onClickHandler}
    >
        {btnText}
    </button>;
};

export default ActionButton;
