import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import {Store} from 'app/store';
import {scrollToTop, goToRoute, userSignedIn} from 'app/utils';
import I18n from 'app/i18n';
import {openRegistration} from '../auth/auth';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

export default class TakePartButton extends PureComponent {
    static propTypes = {
        isRenderable: PropTypes.bool,
        promo_action_url: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.onClickHandler = this.onClickHandler.bind(this);
    }

    get promoActionUrl() {
        return `/promo/${this.props.promo_action_url || Store.compendiumUrl()}/predictions`;
    }

    onClickHandler(e) {
        e && e.preventDefault();

        if (userSignedIn()) {
            scrollToTop();
            goToRoute(this.promoActionUrl);
        } else {
            openRegistration('PROMO_LANDING');
        }
    }

    get isRenderable() {
        const {isRenderable} = this.props;

        if (typeof isRenderable === 'undefined') {
            return Store.compendiumStarted();
        }
        return isRenderable;
    }

    render() {
        if (!this.isRenderable) {
            return null;
        }

        return <Link
            to={this.promoActionUrl}
            className="button-adaptive"
            onClick={this.onClickHandler}
            {...omit(this.props, 'isRenderable')}
        >
            {this.props.children || I18n.t('prizes_texts.take_part')}
        </Link>;
    }
}
