import {action, makeObservable, observable} from 'mobx';
import Websocket from 'modules/Websocket';
import {ObjectWithId} from 'app/utils/updateArraysObject';
import {RestApiService} from 'app/services/RestApiService';

export default abstract class BaseStore<T> {
    abstract channel: () => string;

    abstract feedUrl: () => string | null;

    abstract updateElementCollection(item: ObjectWithId | T): void;

    abstract deleteElementCollection(item: ObjectWithId | T): void;

    constructor() {
        this.setCollection([]);
        makeObservable(this);
    }

    @observable collection: T[] | T = null;

    subscription = null;

    connection = null;

    restApiService: RestApiService;

    @action init(collection: T[] | T): void {
        this.setCollection(collection);
    }

    @action
    setCollection(collection: T[] | T): void {
        this.collection = collection;
    }

    @action
    loadCollection(): void {
        if (!this.feedUrl() || !this.restApiService) {
            return;
        }

        this.restApiService
            .index()
            .then(response => response.json())
            .then((collection: T[] | T) => this.setCollection(collection));
    }

    listen(): void {
        if (!this.channel()) {
            return;
        }

        this.subscription = Websocket.subscribe(this.channel(), ({data: {event, payload}}) => {
            switch (event) {
            case 'load':
                this.setCollection(payload);
                break;
            case 'update':
                this.updateElementCollection(payload);
                break;
            case 'delete':
                this.deleteElementCollection(payload);
                break;
            default:
                throw new Error('Unknown event');
            }
        });

        this.loadCollection();
    }

    stopListening(): void {
        if (this.subscription) {
            Websocket.unsubscribe(this.subscription);
            this.subscription = null;
        }
    }
}
