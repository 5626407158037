import React, {PureComponent} from 'react';
import {translatedKeyLangs} from 'app/utils/translations_helper';

interface PropsInterface {
    system: {
        id: number
    },
    disabled: boolean,
    errors: [],
    handleDetails: (state: {[key: string]: string | boolean | number}, details: string) => void
}

export default class P2PCashoutDetails extends PureComponent<PropsInterface> {
    state = {
        accept_show_wallet: true
    };

    render(): React.ReactNode {
        const {system} = this.props;
        this.props.handleDetails(this.state, 'cashout_detail_attributes');

        return <div className="description-text">
            <p dangerouslySetInnerHTML={{__html: translatedKeyLangs(system, 'cashout_description')}}/>
        </div>;
    }
}
