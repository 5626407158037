import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import {BetSlipStore} from 'app/store/BetSlipStore';
import BetSlipController from 'app/components/bet_slip/BetSlipController';

interface BetSlipStatsInterface {
    betSlipStore?: BetSlipStore
    controller?: BetSlipController
}

@inject('betSlipStore', 'controller')
@observer

export default class BetSlipStats extends Component<BetSlipStatsInterface> {
    componentWillUnmount():void {
        this.props.betSlipStore.switchShowStatsLink(false);
        this.props.betSlipStore.setStatsLink('');
    }

    showStats = (): void => {
        if (this.props) {
            const showScoreboard = !this.props.betSlipStore.showStatsLink;
            this.props.betSlipStore.switchShowStatsLink(showScoreboard);
            const {bet: {selectedLine}} = this.props.controller;
            this.props.controller.makeLinkRequest(selectedLine.id);
        }
    };

    showText = (): string => this.props.betSlipStore.showStatsLink ? I18n.t('betslip_stats_hide') : I18n.t('betslip_stats_show');

    render(): ReactNode {
        const {bet: {selectedLine}} = this.props.controller;
        const {statsLink} = this.props.betSlipStore;

        return selectedLine && selectedLine.has_scoreboard && selectedLine.live
            ? <>
                <div className="bet-slip-line-description middle-icon">
                    <svg className="svg-default">
                        <use xlinkHref="#svg-round-warning"/>
                    </svg>
                    <div>
                        <a onClick={this.showStats}>{this.showText()}</a>
                    </div>
                </div>
                {statsLink && this.props.betSlipStore.showStatsLink && <div className="bet-slip-stats">
                    <iframe width={'100%'} src={statsLink}/>
                </div>}
            </>
            : <></>;
    }
}
