import React, {Component, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import SmartFullPageLoader from 'app/components/reusable/smart_loaders/SmartFullPageLoader';
import {ShopTableInterface} from 'app/interfaces/ShopTableInterface';
import ShopTableOtherItems from 'app/components/shop/ShopTableOtherItems';

@inject('shopStore')
@observer
export default class OtherItemsContainer extends Component<ShopTableInterface> {
    render(): ReactNode {
        const {loading, data} = this.props.shopStore;
        return <>
            {loading || !data.other_items
                ? <SmartFullPageLoader />
                : <ShopTableOtherItems {...this.props} />}
        </>;
    }
}

