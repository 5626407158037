import TimerInterface from 'app/interfaces/TimerInterface';
import toInteger from 'lodash/toInteger';

const LIMIT_TO_DIGIT = 10;
const DAY = 24;

export const DEFAULT_MESSAGE = '00:00:00';

export const simpleFormat = (timer: TimerInterface, exception = DEFAULT_MESSAGE): string => {
    const {days, hours, minutes, seconds} = timer;
    return isPositiveTimer(timer) && defaultMessageException(exception)
        ? `${formatNumber(toInteger(days * DAY + hours))}:${formatNumber(toInteger(minutes))}:${formatNumber(seconds)}`
        : exception;
};

const formatNumber = (number: number): string => number < LIMIT_TO_DIGIT ? `0${number}` : number.toString();

const defaultMessageException = (exception: string): boolean => exception === DEFAULT_MESSAGE;

const isPositiveTimer = (timer: TimerInterface): boolean => timer.days >= 0 &&
    timer.hours >= 0 && timer.minutes >= 0 && timer.seconds >= 0;
