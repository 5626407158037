import React, {Component, MouseEventHandler} from 'react';
import {Link} from 'react-router-dom';
import {i18n_t} from 'app/i18n';
import {getLinkPath, closeMobileMenu} from 'app/utils';
import {inject, observer} from 'mobx-react';
import {countedTitle} from 'app/utils/countedTitle';
import {UserStore} from 'app/store/UserStore';

interface NotificationsLinkProps {
    onClose: MouseEventHandler<HTMLAnchorElement>;
    userStore?: UserStore;
}

export default inject('userStore')(observer(class NotificationsLink extends Component<NotificationsLinkProps> {
    render() {
        const {onClose, userStore: {messagesCount}} = this.props as NotificationsLinkProps;

        return <Link
            className="user-nav__item"
            to={getLinkPath('/messages')}
            onClick={onClose || closeMobileMenu}
        >
            <span className="user-nav__icon">
                <svg height="12px" width="18px">
                    <use xlinkHref="#svg-messages"/>
                </svg>
            </span>
            <span>
                {countedTitle(i18n_t('user.messages'), messagesCount)}
            </span>
        </Link>;
    }
}));
