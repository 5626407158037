import React, {ChangeEvent, KeyboardEvent, Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import Limits from '../Limits';
import InputError, {fieldErrorClass} from '../InputError';
import {depositExchangedAmount, depositP2PExcangedAmount, isoCurrencyToHtml} from '../depositExchangeAmount';
import {inject, observer} from 'mobx-react';
import {DepositsStoreProps} from 'app/store/DepositsStore';
import {getRateByCurrency} from 'app/utils/deposits/getRateByCurrency';

interface DepositAmountProps extends DepositsStoreProps {
    isHiddenForRedirect?: boolean
    name?: string,
    currency?: string
    exchange_currency?: string
}

@inject('depositsStore')
@observer
export default class DepositAmount extends Component<DepositAmountProps> {
    depositUpdateAmount = (event: KeyboardEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>): void => {
        const target = event.target as HTMLInputElement;
        this.props.depositsStore.updateAmount(target.value);
    };

    amount = (): string => {
        const {amount, rates} = this.props.depositsStore;
        const validAmount = amount && Number(amount) !== 0 && !isNaN(Number(amount));
        const {currency, exchange_currency} = this.props;
        const exchange = exchange_currency || this.props.depositsStore.currentPaymentSystem.exchange_currency;
        const exchangedAmount = exchange && validAmount ? depositExchangedAmount(amount, exchange, rates) : '';

        if (currency && currency !== 'USD') {
            const exchangedP2PAmount = validAmount ? depositP2PExcangedAmount(amount, currency, rates) : '';
            return `${isoCurrencyToHtml(currency)} ${exchangedP2PAmount}`;
        }

        return `$ ${exchangedAmount}`;
    };

    render(): ReactNode {
        const {amount, rates, errors: {error}} = this.props.depositsStore;
        const {isHiddenForRedirect, name, currency} = this.props;
        const rate = getRateByCurrency(currency, rates);
        return <div className="form__row">
            <div className="form__row-item">
                <label className="form__row-label">
                    {I18n.t('amount')}, {this.amount()}
                </label>
                <div className={fieldErrorClass(Boolean(error))}>
                    <div className="field__wrap">
                        <input
                            id="deposit_amount"
                            name={isHiddenForRedirect ? null : name || 'amount'}
                            className="field__input"
                            maxLength={7}
                            value={amount}
                            onKeyUp={this.depositUpdateAmount}
                            onChange={this.depositUpdateAmount}
                        />
                        {error && <InputError error={error}/>}
                    </div>
                </div>

                <Limits currency_rate={rate} currency={rate ? currency : null}/>
            </div>
        </div>;
    }
}
