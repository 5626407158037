import Get from 'app/utils/Get';
import {getLinkPath} from 'app/utils/link_helper';
import {modalConfirm} from 'app/utils/modals/popups';
import I18n from 'app/i18n';
import {ajaxError} from 'app/utils';
import $ from 'jquery';
import {Store} from 'app/store';
import {toastSuccess} from 'app/components/toasts/liteToast';

export default class CancelPrediction {
    id = null;

    onSuccess = null;

    constructor(id, onSuccess = null) {
        this.id = id;
        this.onSuccess = onSuccess;
    }

    cancelAnswer = () => {
        new Get({
            params: {},
            url: getLinkPath(`/promo_actions/${Store.compendiumUrl()}/predictions/${this.id}/cancel_points`)
        }).execute()
            .then(response => response.json())
            .then(response => modalConfirm(
                I18n.t('would_you_like_remove_prediction',
                    {
                        br: '<br/>',
                        cost: `${response.points}&nbsp;${I18n.t('table.points_title')}`,
                        span: '<span>',
                        span_close: '</span>'
                    }), this.makeCancellation
            ));
    };

    makeCancellation = () => {
        $.ajax({
            error: response => ajaxError(response),
            method: 'PUT',
            success: () => {
                toastSuccess(I18n.t('cancelled_prediction'));
                if (this.onSuccess) {
                    this.onSuccess();
                }
            },
            url: getLinkPath(`/promo_actions/${Store.compendiumUrl()}/predictions/${this.id}/cancel`)
        });
    };
}
