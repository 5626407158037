import BaseLandingPage from 'app/components/compendium/landing_pages/BaseLandingPage';
import {inject, observer} from 'mobx-react';
import React from 'react';
import I18n from 'app/i18n';
import CompendiumHero from 'app/components/compendium/CompendiumHero';
import CommonPointsBets from 'app/components/compendium/common_points_bets';
import PrizePool from 'app/components/compendium/prize_pool';

const t = (key, values) => I18n.t(`compendium.${key}`, values);

@inject('pageContentStore')
@observer
export default class Compenigma2021Index extends BaseLandingPage {
    render() {
        const promoClassName = 'compenigma2021';

        return <div className={`promo-battle promo-battle_${promoClassName}`}>
            <div className="promo-battle-top">
                <CompendiumHero
                    promoClassName={promoClassName}
                    predictionsCount={this.predictionsCount}
                    url={this.props.url}
                    heroLogo={''}
                    translations={t}
                />
            </div>
            <div className="promo-battle-main">
                <div className="promo-text">
                    <div className="text-title">
                        <p>{I18n.t('compenigma21.title_1')}</p>
                    </div>
                </div>
                <div className="promo-motivation-points">
                    <div className="promo-text promo-text_first">
                        <div className="text-description">
                            <p>{I18n.t('compenigma21.description_1_2')}</p>
                        </div>
                    </div>
                    <div className="promo-text promo-text_second">
                        <div className="text-description">
                            <p>{I18n.t('compenigma21.description_2')}</p>
                        </div>
                        <div className="text-title">
                            <p>{I18n.t('compenigma21.title_2')}</p>
                        </div>
                    </div>
                    <div className="promo-points">
                        <span className="promo-points-title">{I18n.t('compendium.cost_points')}:</span>
                        <CommonPointsBets promoClassName={promoClassName}/>
                    </div>
                    <div className="promo-text promo-text_third">
                        <div className="text-description">
                            <p>{I18n.t('compenigma21.description_1_1')}</p>
                        </div>
                    </div>
                </div>
                <PrizePool prizes={this.prizes} promoClassName={promoClassName}>
                    <div className="prize-section prize-bonuses"/>
                </PrizePool>
            </div>
        </div>;
    }
}
