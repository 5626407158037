import React, {ReactNode} from 'react';
import PointDestination from 'app/components/reusable/list_destinations/PointDestination';
import CopySharingLink from 'app/components/my_profile/referrals/CopySharingLink';

export default class ListDestinations extends React.Component {
    render(): ReactNode {
        const {REFERRAL_LINK} = window.SITE_DATA;

        return <div className="list-destinations">
            <PointDestination
                itemClassName="recomendation"
                numberPoint="1"
                titlePoint="referral_point_title_1"
                descriptionPoint="referral_point_text_1"
            >
                <CopySharingLink link={REFERRAL_LINK}/>
            </PointDestination>
            <PointDestination
                itemClassName="registered"
                numberPoint="2"
                titlePoint="referral_point_title_2"
                descriptionPoint="referral_point_text_2"
            />
            <PointDestination
                itemClassName="profit"
                numberPoint="3"
                titlePoint="referral_point_title_3"
                descriptionPoint="referral_point_text_3"
            />
        </div>;
    }
}
