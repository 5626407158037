import React, {useCallback} from 'react';

interface WidgetProps {
    show: boolean;
    icon: React.ReactElement;
    behavior: ScrollBehavior;
    element: HTMLElement| Element;
}

export const ScrollUp = (props: WidgetProps): React.ReactElement<HTMLDivElement> => {
    const {show, element, icon, behavior} = props;

    const scrollIntoView = (): void => {
        if (!element) {
            return;
        }

        const upperElementWidth = 30;
        const y = element.getBoundingClientRect().top;
        window.scrollTo({
            behavior,
            top: y + upperElementWidth
        });
    };

    const handleScrollIntoView = useCallback(() => scrollIntoView(), [scrollIntoView]);

    return show
        ? <div className="content-scroll-up" onClick={handleScrollIntoView}>
            <div className="content-scroll-up__inner">
                {icon}
            </div>
        </div>
        : <></>;
};
