import React, {PureComponent, ReactNode} from 'react';
import {inject} from 'mobx-react';
import BetSlipController from 'app/components/bet_slip/BetSlipController';

interface BetSlipMenuControllerProps {
    controller?: BetSlipController
}


@inject('controller')
export default class BetSlipMenuController extends PureComponent<BetSlipMenuControllerProps> {
    handleMinimize = (): void => this.props.controller.setMinimize(true);

    betRemove = (): void => this.props.controller.removeAllGames();

    render(): ReactNode {
        return <div className="bet-slip-controller">
            <button className="bet-slip-controller-button draggable-coupon">
                <svg className="svg-default">
                    <use xlinkHref="#svg-bet-slip-controller-draggable"/>
                </svg>
            </button>
            <button className="bet-slip-controller-button minimize-coupon" onClick={this.handleMinimize}>
                <svg className="svg-default">
                    <use xlinkHref="#svg-bet-slip-controller-minimize-1"/>
                </svg>
            </button>
            <button className="bet-slip-controller-button close-coupon" onClick={this.betRemove}>
                <svg className="svg-default">
                    <use xlinkHref="#svg-bet-slip-controller-close"/>
                </svg>
            </button>
        </div>;
    }
}
