
export const getDistinctRandomIndex = (elements: [], oldIndex: number | null): number => {
    if (oldIndex) {
        const nextIndex = Math.floor(Math.random() * elements.length);

        if (nextIndex === oldIndex) {
            return nextIndex + 1 >= elements.length ? nextIndex - 1 : nextIndex + 1;
        }
        return nextIndex;
    }
    return Math.floor(Math.random() * elements.length);
};
