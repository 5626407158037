import React, {PureComponent} from 'react';
import {i18n_t} from 'app/i18n';
import SitemapLink from '../common/SitemapLink';

class Sitemap extends PureComponent {
    render = (): React.JSX.Element => <div className="main-block">
        <div className="wrap wrap--content">
            <div className="content">
                <div className="title">{i18n_t('menu_links.sitemap')}</div>
                <div className="content__holder">
                    <div className="sitemap">
                        <div className="sitemap__groups">
                            <div className="sitemap__links-group">
                                <div className="sitemap__links-group-title">EGB.com</div>

                                <SitemapLink link={'/'} title={i18n_t('menu_links.home')}/>
                                <SitemapLink link={'/about'} title={i18n_t('menu_links.about')}/>
                                <SitemapLink link={'/contacts'} title={i18n_t('menu_links.contacts')}/>
                                <SitemapLink link={'/faq'} title={i18n_t('menu_links.faq')}/>
                            </div>
                            <div className="sitemap__links-group">
                                <div className="sitemap__links-group-title">{i18n_t('play')}</div>

                                <SitemapLink link={'/play/bets'} title={i18n_t('user_menu_table')}/>
                                <SitemapLink link={'/play/express'} title={i18n_t('user_menu_express')}/>
                                <SitemapLink link={'/play/live'} title={i18n_t('user_menu_live')}/>
                            </div>
                            <div className="sitemap__links-group">
                                <div className="sitemap__links-group-title">
                                    {i18n_t('home_play_favourite_game')}
                                </div>
                                {window.SITE_DATA.INDEX_GAMES.map((game, i) => game.name && <div key={i}>
                                    <SitemapLink
                                        link={`/play/bets/${game.slug}`}
                                        title={i18n_t('bet_on', {game: game.name})}/>
                                </div>)}
                            </div>
                            <div className="sitemap__links-group">
                                <div className="sitemap__links-group-title">{i18n_t('menu_links.shop')}</div>

                                <SitemapLink link={'/shop/items'} title={i18n_t('items_header')}/>
                                <SitemapLink link={'/shop/money'} title={i18n_t('money_header')}/>
                                <SitemapLink link={'/shop/marketplace'} title={i18n_t('marketplace')}/>
                                <SitemapLink link={'/shop/lootboxes'} title={i18n_t('lootboxes')}/>
                            </div>
                            <div className="sitemap__links-group">
                                <div className="sitemap__links-group-title">{i18n_t('articles_title')}</div>

                                <SitemapLink link={'/news'} title={i18n_t('news_title')}/>
                                <SitemapLink link={'/promotions'} title={i18n_t('promotions_title')}/>
                                <SitemapLink link={'/news/hidden_temples'} title={'Hidden Temple'}/>
                                <SitemapLink link={'/blog'} title={i18n_t('menu_links.blog')}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default Sitemap;
