import React, {PureComponent, ReactNode} from 'react';
import {getLinkPath, scrollToTop} from 'app/utils';
import {Link} from 'react-router-dom';

interface Props {
    public_inventory: boolean,
    user_nickname: string
}

export default class UserInventoryLink extends PureComponent<Props> {
    render(): ReactNode {
        const {user_nickname, public_inventory} = this.props;
        return public_inventory
            ? <Link
                to={getLinkPath(`/profile/${user_nickname}`)}
                onClick={scrollToTop}
                className="name"
            >
                {user_nickname}
            </Link>
            : <span className="name">{user_nickname}</span>;
    }
}
