import React, {PureComponent} from 'react';
import {timeStampToTime} from 'app/utils';

export default class Content extends PureComponent {
    render() {
        const {date, content} = this.props;
        return <div className="content__holder">
            <div className="article">
                <div className="content__date">{timeStampToTime(date)}</div>
                <div dangerouslySetInnerHTML={{__html: content}}/>
            </div>
        </div>;
    }
}
