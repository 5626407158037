import React, {ReactElement} from 'react';
import {i18n_t} from 'app/i18n';
import {SimpleGamerDescription} from 'app/utils/gamer_descriptions_helper';
import LineInterface from 'app/interfaces/LineInterface';

const LimitIncreasedOrEnabled = (data: LineInterface): ReactElement => <div>
    {i18n_t(data.type)}{'\u00A0'}
    <SimpleGamerDescription {...data.gamer_1} game={data.game}/>
    {'\u00A0'}VS{'\u00A0'}
    <SimpleGamerDescription {...data.gamer_2} game={data.game}/>
    {i18n_t(`${data.type}_tap`,
        {
            line_limit: data.type === 'line_limit_increased'
                ? data.max_high_limit
                : data.multiple_bet_limit
        })}
</div>;

export default LimitIncreasedOrEnabled;
