import BaseLandingPage from 'app/components/compendium/landing_pages/BaseLandingPage';
import EnigmaIndexStyle from 'app/components/compendium/landing_pages/EnigmaIndexStyle';
import {inject, observer} from 'mobx-react';
import React from 'react';
import I18n from 'app/i18n';
import CompendiumHero from 'app/components/compendium/CompendiumHero';
import CommonPointsBets from 'app/components/compendium/common_points_bets';
import PrizePool from 'app/components/compendium/prize_pool';

const t = (key, values) => I18n.t(`enigma21.${key}`, values);

@inject('pageContentStore')
@observer
export default class EnigmaIndex extends BaseLandingPage {
    render() {
        const {landing_page = {
            background_image: null,
            background_mobile_image: null,
            content: null,
            main_image: null,
            main_mobile_image: null,
            mini: false,
            title_1: null,
            title_2: null
        }} = this.props.pageContentStore.content;
        const {
            title_1,
            title_2,
            content,
            main_image,
            main_mobile_image,
            mini,
            background_image,
            background_mobile_image,
            promo_action_name,
            promo_action_received_total,
            text_color,
            second_text_color,
            prizes_text_color
        } = landing_page || {};
        const promoClassName = 'color_enigma_251021';
        return <>
            {this.props.pageContentStore.content.landing_page &&
            <EnigmaIndexStyle
                main_image={main_image}
                main_mobile_image={main_mobile_image}
                background_image={background_image}
                background_mobile_image={background_mobile_image}
                text_color={text_color}
                second_text_color={second_text_color}
                prizes_text_color={prizes_text_color}/>
            }
            <div className={`enigma_index ${promoClassName}`}>
                <div className="promo-battle">
                    <div className="promo-battle-top">
                        <CompendiumHero
                            promoClassName={promoClassName}
                            predictionsCount={this.predictionsCount}
                            url={this.props.url}
                            heroLogo={''}
                            translations={t}
                            promo_action_name={promo_action_name}
                            promo_action_received_total={promo_action_received_total}
                        />
                    </div>
                    <div className="promo-battle-main">
                        <div className="promo-text">
                            {title_1
                                ? <div className="text-title" dangerouslySetInnerHTML={{__html: title_1}}/>
                                : <div className="text-title">
                                    <p>{I18n.t('enigma21.title_1_1')}</p>
                                    <p>{I18n.t('enigma21.title_1_2')}</p>
                                </div>
                            }
                        </div>
                        <div className="promo-motivations-points">
                            <div className="promo-edition">
                                <div className="text-description">
                                    <div className="promo-points">
                                        <span className="promo-points-title">{I18n.t('compendium.cost_points')}:</span>
                                        <CommonPointsBets promoClassName={promoClassName} mini={mini}/>
                                    </div>
                                    {content
                                        ? <div className="text-title" dangerouslySetInnerHTML={{__html: content}}/>
                                        : <>
                                            <p>{I18n.t('enigma21.description_1')}</p>
                                            <p>{I18n.t('enigma21.description_2')}</p>
                                            <p>{I18n.t('enigma21.description_3')}</p>
                                            <p>{I18n.t('enigma21.description_4')}</p>
                                            <p>{I18n.t('enigma21.description_5')}</p>
                                            <p>{I18n.t('enigma21.description_6')}</p>
                                        </>
                                    }
                                </div>
                                {title_2
                                    ? <div className="text-title" dangerouslySetInnerHTML={{__html: title_2}}/>
                                    : <div className="text-title">
                                        <p>{I18n.t('enigma21.title_2')}</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <PrizePool prizes={this.prizes} promoClassName={promoClassName}>
                            <div className="prize-section prize-bonuses"/>
                        </PrizePool>
                    </div>
                </div>
            </div>
        </>;
    }
}
