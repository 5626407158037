import React, {Component, Fragment} from 'react';
import $ from 'jquery';
import I18n from 'app/i18n';
import {ajaxError, scroll, isDesktop} from 'app/utils';
import InventoryTabLink from './inventory_tab_link';
import InventoryItem from './inventory_item';
import {showPopup} from 'app/utils/modals/ModalsService';
import {openAuth} from 'app/components/auth/auth';
import Checkbox from 'app/components/auth/Checkbox';
import InventoryCountEditPopup from './inventoryCountEditPopup';
import pick from 'lodash/pick';
import {userStore} from 'app/store/global';
import SmartLoader from 'app/components/reusable/SmartLoader';
import Post from 'app/utils/Post';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import withRouter from 'app/utils/navigate/customWithRouter';

class TradeOffer extends Component {
    constructor(props) {
        super(props);
        this.state = TradeOffer.initialState();
        this.rootElement = React.createRef();
    }

    static initialState = () => ({
        denied: false,
        loading: true,
        my_inventory: [],
        my_items: [],
        opened_my_inventory: true,
        timerId: 0,
        trade_ready: false,
        trade_token: null,
        user_inventory: [],
        user_items: [],
        waitingForResponse: false
    });

    // eslint-disable-next-line no-magic-numbers
    static TRADE_OFFER_RESPONSE_WAITING_TIME = 1000;

    componentDidMount() {
        this.loadData();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.forceUpdate();
    };


    componentDidUpdate(prevProps) {
        if (this.props.params.name !== prevProps.params.name || this.props.params.token !== prevProps.params.token) {
            this.loadData();
        }
    }

    loadData = () => {
        this.setState({loading: true});
        $.ajax('/trades', {
            data: this.props.params.token
                ? {token: this.props.params.token}
                : {partner_user_name: this.props.params.name},
            error: xhr => {
                // eslint-disable-next-line no-magic-numbers
                if (xhr.status === 401) {
                    this.setState({denied: true});
                    openAuth(window.location.pathname);
                } else {
                    ajaxError(xhr);
                }
            },
            success: response => this.setState({
                ...TradeOffer.initialState(),
                ...response
            }, this.scrollToRoot)
        }).always(() => this.setState({loading: false}));
    };

    scrollToRoot = () => scroll($(this.rootElement.current));

    toggleOpenedInventory = val => {
        if (this.state.opened_my_inventory !== val) {
            this.setState({opened_my_inventory: val});
        }
    };

    myInventory = () => this.inventory('my_inventory', 'my_items');

    myItems = () => this.items('my_items');

    addToMyItems = (artifact, quantity = 1) => this.addToItems(artifact, quantity, 'my_items');

    addToMyInventory = (artifact, quantity = 1) => this.addToInventory(artifact, quantity, 'my_items');

    userInventory = () => this.inventory('user_inventory', 'user_items');

    userItems = () => this.items('user_items');

    addToUserItems = (artifact, quantity = 1) => this.addToItems(artifact, quantity, 'user_items');

    addToUserInventory = (artifact, quantity = 1) => this.addToInventory(artifact, quantity, 'user_items');

    addToOnClickHandler = (artifact, handler) => () => {
        const {total_inventory_items} = artifact;

        if (total_inventory_items > 1) {
            return showPopup('inventory-count-edit', props => <InventoryCountEditPopup
                {...artifact}
                popupId={props.popupId}
                confirm={handler}
                partner_user_name={this.props.params.name || this.state.partner_user_name}
                opened_my_inventory={this.state.opened_my_inventory}
            />);
        }

        handler(artifact);
    };

    inventory = (inventoryKey, itemsKey) => this.state[inventoryKey]
        .map(inventoryItem => {
            const foundTradeItem = this.state[itemsKey].find(tradeItem => tradeItem.id === inventoryItem.id);

            if (foundTradeItem) {
                return {
                    ...inventoryItem,
                    total_inventory_items: inventoryItem.total_inventory_items - foundTradeItem.quantity
                };
            }
            return inventoryItem;
        })
        .filter(inventoryItem => inventoryItem.total_inventory_items > 0);

    items = itemsKey => this.state[itemsKey];

    addToItems = (artifact, quantity, itemsKey) => {
        const myItems = [...this.state[itemsKey]];
        const myItemIndex = myItems.findIndex(myItem => myItem.id === artifact.id);

        // eslint-disable-next-line no-magic-numbers
        if (myItemIndex > -1) {
            let myItem = myItems[myItemIndex];
            const addition = myItem.quantity + quantity;
            myItem = {
                ...myItem,
                quantity: myItem.total_inventory_items > addition
                    ? addition
                    : myItem.total_inventory_items
            };
            myItems.splice(myItemIndex, 1, myItem);
        } else {
            const {total_inventory_items} = artifact;
            myItems.push({
                ...artifact,
                quantity: total_inventory_items > quantity ? quantity : total_inventory_items
            });
        }

        this.setState({
            [itemsKey]: myItems,
            trade_ready: false
        });
    };

    addToInventory = (artifact, quantity, itemsKey) => {
        const myItems = [...this.state[itemsKey]];
        const myItemIndex = myItems.findIndex(myItem => myItem.id === artifact.id);
        const myItem = myItems[myItemIndex];
        const subtraction = myItem.quantity - quantity;

        if (subtraction > 0) {
            myItems.splice(myItemIndex, 1, {
                ...myItem,
                quantity: subtraction
            });
        } else {
            myItems.splice(myItemIndex, 1);
        }

        this.setState({
            [itemsKey]: myItems,
            trade_ready: false
        });
    };

    tradeReady = () => this.setState(prevState => ({trade_ready: !prevState.trade_ready}));

    offerTrade = () => {
        const {trade_ready, trade_token, partner_user_name, timerId} = this.state;
        this.setState({waitingForResponse: true});
        new Post({
            params: {
                ask_item_ids: JSON.stringify(
                    this.state.my_items.map(myItem => pick(myItem, ['id', 'quantity']))
                ),
                partner_item_ids: JSON.stringify(
                    this.state.user_items.map(userItem => pick(userItem, ['id', 'quantity']))
                ),
                partner_user_name,
                trade_ready,
                trade_token
            },
            url: '/trades'
        })
            .execute()
            .then(response => {
                if (response.ok) {
                    if (timerId) {
                        this.setState({timerId: 0}, () => clearTimeout(timerId));
                    }
                    response.json().then(response => this.setState(() => ({
                        key: response.key,
                        timerId: this.tradeOfferResponse(),
                        waitingForResponse: true
                    })));
                }
            });
    };

    tradeOfferResponse = () => setTimeout(() => $.ajax('/trades/create_result', {
        data: {key: this.state.key},
        error: xhr => {
            toastError(xhr);
            this.setState({waitingForResponse: false});
        },
        success: response => {
            if (response) {
                this.setState({
                    my_items: [],
                    trade_ready: false,
                    user_items: [],
                    waitingForResponse: false
                });
                toastSuccess(response.message);
            } else {
                this.tradeOfferResponse();
            }
        }
    }), this.constructor.TRADE_OFFER_RESPONSE_WAITING_TIME);

    render() {
        if (this.state.loading) {
            return <div className="content__holder"><SmartLoader/></div>;
        }

        const {restrict_artifact} = userStore.user;
        const name = this.props.params.name || this.state.partner_user_name;
        const tradeReadyDisabled = this.state.my_items.length === 0 && this.state.user_items.length === 0;
        const offerDisabled = !this.state.trade_ready ||
            tradeReadyDisabled ||
            name === userStore.user.name ||
            restrict_artifact;

        const {opened_my_inventory, waitingForResponse, denied} = this.state;

        return denied
            ? <div className="content__holder">
                <div className="exchange-link exchange-link--inactive text-center">
                    <p>:(</p>
                    <div className="subtitle">{I18n.t('inactive_link')}</div>
                </div>
            </div>
            : <Fragment>
                <h1 className="title" ref={this.rootElement}>
                    {I18n.t('trade_with', {name})}
                </h1>
                <div className="content__holder content__holder--no-paddings">
                    <div className="exchange">
                        <div className="exchange--offer-page">
                            <div className="exchange--inventory-available">
                                <div className="tabs">
                                    <div className="tabs__header">
                                        <InventoryTabLink
                                            activation={true}
                                            active={opened_my_inventory}
                                            label={I18n.t('your_inventory')}
                                            onClick={this.toggleOpenedInventory}
                                        />
                                        <InventoryTabLink
                                            activation={false}
                                            active={!opened_my_inventory}
                                            label={I18n.t('partner_inventory')}
                                            onClick={this.toggleOpenedInventory}
                                        />
                                    </div>
                                    {isDesktop() && <div className="tabs__content">
                                        <div
                                            className="tabs__content-item"
                                            style={{display: opened_my_inventory ? 'block' : 'none'}}
                                        >
                                            <div className="mobile-label">{I18n.t('your_inventory')}</div>
                                            <div className="offer-items">
                                                <div className="offer-items--wrap">
                                                    {this.myInventory().map(artifact => <InventoryItem
                                                        key={artifact.id}
                                                        artifact={artifact}
                                                        onClick={this.addToOnClickHandler(artifact, this.addToMyItems)}
                                                    />)}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="tabs__content-item"
                                            style={{display: !opened_my_inventory ? 'block' : 'none'}}
                                        >
                                            <div className="mobile-label">{I18n.t('partner_inventory')}</div>
                                            <div className="offer-items">
                                                <div className="offer-items--wrap">
                                                    {this.userInventory().map(artifact => <InventoryItem
                                                        key={artifact.id}
                                                        artifact={artifact}
                                                        onClick={this.addToOnClickHandler(artifact, this.addToUserItems)}
                                                    />)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div className="exchange--inventory-proposed">
                                <div className="offer offer--sent">
                                    {!isDesktop() && <div
                                        className="tabs__content-item"
                                    >
                                        <div className="mobile-label">{I18n.t('your_inventory')}</div>
                                        <div className="offer-items">
                                            <div className="offer-items--wrap">
                                                {this.myInventory().map(artifact => <InventoryItem
                                                    key={artifact.id}
                                                    artifact={artifact}
                                                    onClick={this.addToOnClickHandler(artifact, this.addToMyItems)}
                                                />)}
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="subtitle">{I18n.t('your_items')}</div>
                                    <div className="offer-content">
                                        <div className="offer-items">
                                            <div className="offer-items--wrap">
                                                {this.myItems().map(artifact => <InventoryItem
                                                    key={artifact.id}
                                                    artifact={artifact}
                                                    onClick={this.addToOnClickHandler(artifact, this.addToMyInventory)}
                                                />)}
                                            </div>
                                            <Checkbox
                                                name="trade_ready"
                                                simple={true}
                                                label={I18n.t('trade_ready')}
                                                onChange={this.tradeReady}
                                                value={this.state.trade_ready}
                                                disabled={tradeReadyDisabled}
                                            />
                                        </div>
                                        <div className="offer-separator">
                                            <div className="line"/>
                                            <svg height="40px" width="42px">
                                                <use xlinkHref="#svg-exchange"/>
                                            </svg>
                                            <div className="line"/>
                                        </div>
                                        <div className="offer-items offer-items--desired">
                                            {!isDesktop() && <div
                                                className="tabs__content-item"
                                            >
                                                <div className="mobile-label">{I18n.t('partner_inventory')}</div>
                                                <div className="offer-items">
                                                    <div className="offer-items--wrap">
                                                        {this.userInventory().map(artifact => <InventoryItem
                                                            key={artifact.id}
                                                            artifact={artifact}
                                                            onClick={this.addToOnClickHandler(artifact, this.addToUserItems)}
                                                        />)}
                                                    </div>
                                                </div>
                                            </div>}
                                            <div className="subtitle">{I18n.t('partner_items', {name})}</div>
                                            <div className="offer-items--wrap">
                                                {this.userItems().map(artifact => <InventoryItem
                                                    key={artifact.id}
                                                    artifact={artifact}
                                                    onClick={
                                                        this.addToOnClickHandler(artifact, this.addToUserInventory)
                                                    }
                                                />)}
                                            </div>
                                            <div className="offer-label">
                                                {I18n.t('trade_notify', {name})}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="offer-actions">
                                        {waitingForResponse
                                            ? <SmartLoader/>
                                            : <button
                                                className={`btn btn--large btn--big-on-mobile${
                                                    offerDisabled ? ' btn--disable' : ''
                                                }`}
                                                disabled={offerDisabled}
                                                onClick={this.offerTrade}
                                            >
                                                {I18n.t('trade_offer_start')}
                                            </button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>;
    }
}

export default withRouter(TradeOffer);
