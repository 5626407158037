import {action, makeObservable, observable} from 'mobx';

export class FlagsStore {
    @observable flags: {[key: string]: string}[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    init(flags: {[key: string]: string}[]): void {
        this.flags = flags;
    }
}
