import {userStore} from 'app/store/global';
import ChangedDepositProfileFields from 'app/components/auth/ChangedDepositProfileFields';
import CombinedCashout from 'app/components/cashout/CombinedCashout';
import $ from 'jquery';
import React, {Component, Fragment} from 'react';
import I18n from 'app/i18n';
import {getLinkPath} from 'app/utils';
import {Link} from 'react-router-dom';
import CashoutHistory from './cashout_history';
import CashoutSystemTabs from './cashout_system_tabs';
import {CashoutForm} from './cashout_form';
import {inject, observer} from 'mobx-react';
import SmartPageLoader from 'app/components/reusable/smart_loaders/SmartPageLoader';
import Post from 'app/utils/Post';
import {toastError} from 'app/components/toasts/liteToast';
import withRouter from 'app/utils/navigate/customWithRouter';

class CashoutPage extends Component {
    state = {
        cancellingCashoutId: null,
        loading: true,
        submitting: false,
        success: null
    };

    componentDidMount() {
        $.ajax(getLinkPath('/cashouts'), {
            dataType: 'json',
            success: data => {
                this.setState({
                    ...data,
                    loading: false
                });
                this.props.cashoutsStore.init(data);
                this.props.cashoutsStore.selectSystemBySlug(this.props.params.system_slug);
            }
        });
    }

    componentDidUpdate(oldProps) {
        const oldSlug = oldProps.params.system_slug;
        const newSlug = this.props.params.system_slug;
        const {cashoutsStore} = this.props;

        if (oldSlug && !newSlug) {
            cashoutsStore.selectSystem(null);
        }
        if (!oldSlug && newSlug) {
            cashoutsStore.selectSystemBySlug(newSlug);
        }
    }

    cancelCashout = id => {
        this.setState({cancellingCashoutId: id, submitting: true});
        new Post({
            params: {
                id
            },
            url: '/cashouts/cancel'
        })
            .execute()
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    this.props.cashoutsStore.setCashoutCancelled(id, response.commission);
                } else {
                    toastError(response.message);
                }
                this.setState({cancellingCashoutId: null, submitting: false});
            });
    };

    get isMainPage() {
        return location.pathname === '/cashouts';
    }

    render() {
        const {loading} = this.state;

        if (loading) {
            return <div className="cashout">
                <div className="title">
                    <h2>{I18n.t('user.cashout')}</h2>
                </div>
                <SmartPageLoader/>
            </div>;
        }
        if (!this.state.success) {
            return <div>
                <div className="cashout">
                    <div className="title">
                        <h2>{I18n.t('user.cashout')}</h2>
                    </div>
                    <div className="cashout__text">{this.state.message}</div>
                </div>
            </div>;
        }

        if (!userStore.fullRegistered()) {
            return <div className="deposit">
                <div className="title">
                    <h1>{I18n.t('user.fill_in_fields_to_make_a_cashout')}:</h1>
                </div>
                <div className="form-content deposit-form form form-egb used-chosen">
                    <div className="deposit-form__inputs">
                        <ChangedDepositProfileFields depositsStore={this.props.depositsStore} {...userStore.user}/>
                    </div>
                </div>
            </div>;
        }

        const {cashoutsStore} = this.props;
        const {china_dictionaries, china_banks, submitting, cancellingCashoutId} = this.state;
        const {loadMore} = cashoutsStore;
        const {cashouts, has_more, systems, systems_order, wallets, selectedSystem} = cashoutsStore.data;

        return <Fragment>
            <div className={`cashout${selectedSystem ? ' cashout__selectedSystem' : ''}`}>
                <div className={`title${selectedSystem ? ' title__selectedSystem' : ''}`}>
                    {selectedSystem && <Fragment>
                        <Link to={getLinkPath('/cashouts')}>
                            <svg height="8px" width="5px">
                                <use xlinkHref="#svg-back"/>
                            </svg>
                            {I18n.t('button_back_label')}
                        </Link>
                    </Fragment>}
                    <h2>
                        {selectedSystem
                            ? `${I18n.t('user.cashout')} ${selectedSystem.name}`
                            : I18n.t('user.cashout')}
                    </h2>
                </div>

                {!selectedSystem && <div className="payment-systems payment-systems__cashout">

                    {this.isMainPage && <div className="payment-systems__list payment-systems__list__cashout">
                        <CombinedCashout/>
                    </div>}

                    <CashoutSystemTabs
                        systems={systems}
                        systems_order={systems_order}
                        wallets={wallets}
                        active
                    />

                    <CashoutSystemTabs
                        systems={systems}
                        systems_order={systems_order}
                        wallets={wallets}
                        active={false}
                    />
                </div>}
                {selectedSystem && <CashoutForm
                    china_dictionaries={china_dictionaries}
                    china_banks={china_banks}
                />}
            </div>
            <CashoutHistory
                latest_cashouts={cashouts}
                cancelCashout={this.cancelCashout}
                has_more={has_more}
                loadMore={loadMore}
                submitting={submitting}
                cancellingCashoutId={cancellingCashoutId}
            />
        </Fragment>;
    }
}

export default withRouter(inject('cashoutsStore', 'userStore')(observer(CashoutPage)));
