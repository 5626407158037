import React, {Fragment, PureComponent} from 'react';

interface InputErrorProps {
    error: string | string[]
}

export interface ErrorsType {
    errors?: ErrorReturnType
}

export interface ErrorReturnType {
    error?: string
    code?: string
    promo_code?: string
}

export const fieldErrorClass = (hasError: boolean): string => `field ${hasError ? 'field-error' : ''}`;

export default class InputError extends PureComponent<InputErrorProps> {
    get errorMessage(): string {
        const {error} = this.props;
        return Array.isArray(error) ? error[0] : error;
    }

    render(): React.ReactNode {
        return <Fragment>
            <span className="field__state">
                <svg height="13px" width="13px">
                    <use xlinkHref="#svg-icon-error"/>
                </svg>
            </span>
            <span className="field__state-description">
                <span className="field__state-description__content">
                    {this.errorMessage}
                </span>
            </span>
        </Fragment>;
    }
}
