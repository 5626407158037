import React, {ReactNode} from 'react';
import {checkElementCookies, setElementCookies} from 'app/utils';
import {addGaEvent} from 'app/ga_methods';

interface PropsInterface {
    header: ReactNode | ReactNode[]
    children: ReactNode | ReactNode[]
    itemClassName: string
    name: string
    headingClick?: boolean
    asideItemClosed?: boolean
}

export default class AsideItem extends React.PureComponent<PropsInterface> {
    state = {
        opened: !this.props.asideItemClosed && checkElementCookies(`button-aside-arrow_${this.props.name}`)
    };

    openAsideItem = (): void => {
        const opened = !this.state.opened;
        this.setState({opened});
        addGaEvent('AsideItem', opened ? 'closed' : 'opened');
        setElementCookies(`button-aside-arrow_${this.props.name}`, opened);
    };

    render(): ReactNode {
        const {itemClassName, headingClick, header, children} = this.props;

        return <div className={`aside-item ${itemClassName}`}>
            <div className={`aside-item-heading${headingClick ? ' clickable' : ''}`} onClick={headingClick && this.openAsideItem}>
                {header}
                <div className="aside-item-heading__button">
                    <button className={`button-aside-arrow${this.state.opened ? ' button-aside-arrow_opened' : ''}`}
                        onClick={this.openAsideItem}>
                        <span className="button-aside-arrow__icon">
                            <svg className="svg-default">
                                <use xlinkHref="#svg-button-open-aside"/>
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
            <div className={`aside-item-body${this.state.opened ? ' aside-item-body_opened' : ''}`}>
                {children}
            </div>
        </div>;
    }
}
