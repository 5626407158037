import SmartLabelTooltip from 'app/components/reusable/SmartLabelTooltip';
import {LiveSelectPicker} from 'app/components/reusable/LiveSelectPicker';
import I18n from 'app/i18n';
import React, {Component, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import {IncubatorCreateLineController} from 'app/controllers/IncubatorCreateLineController';
import OptionInterface from 'app/interfaces/OptionInterface';
import {RestApiService} from 'app/services/RestApiService';
import {ELEMENT_NOT_FOUND} from 'app/utils/Consts';
import {incubatorGameAttributesDefault} from 'app/models/incubatorGameAttributesDefault';
import changeCoefByStep from 'app/utils/ChangeCoefByStep';
import IncubatorLineFormLineType from 'app/components/pages/incubator/IncubatorLineFormLineType';
import {isDesktop} from 'app/utils';
import {ElementWithError} from 'app/components/reusable/ElementWithError';
import {GamesStore} from 'app/store/GamesStore';
import IncubatorLineFormChangeCoef from 'app/components/pages/incubator/IncubatorLineFormChangeCoef';
import {UserStore} from 'app/store/UserStore';
import {goToLinkIncubator} from 'app/utils/app_links';

interface Props {
    controller?: IncubatorCreateLineController
    gamesStore?: GamesStore
    lines_type_id: number
    isLineTypeEditable: (game_id: number) => boolean
    esports: boolean
    params: { id?: number }
    userStore?: UserStore
}

@inject('controller', 'gamesStore', 'userStore')
@observer
export class IncubatorLineNewForm extends Component<Props> {
    componentDidMount(): void {
        const {incubator_last_game_id} = this.controller.userStore.user;
        const {esports} = this.props;

        this.controller.incubatorGame.setAttribute(
            incubatorGameAttributesDefault(
                null,
                incubator_last_game_id,
                esports
            )
        );
    }

    get controller(): IncubatorCreateLineController {
        const props = this.props as Props;
        return props.controller;
    }

    liveSearchApiService = (inputValue: string): Promise<Response> => new RestApiService('/incubator/players')
        .index({
            game_id: this.controller.incubatorGame.attributes.game_id,
            nickname: inputValue
        });

    selectedValue = (selected?: OptionInterface | OptionInterface[] | null): number => Array.isArray(selected)
        ? selected.find(Boolean).value
        : selected.value;

    handleChangeGamer1Id = (selected?: OptionInterface | OptionInterface[] | null): void => {
        this.controller.onChangeGamer1Id(this.selectedValue(selected));
        this.controller.incubatorGame.clearError('gamer_1_id');
    };

    handleChangeCoef1 = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.controller.onChangeCoef1(e.target.value);
    };

    handleChangeGamer2Id = (selected?: OptionInterface | OptionInterface[] | null): void => {
        this.controller.onChangeGamer2Id(this.selectedValue(selected));
        this.controller.incubatorGame.clearError('gamer_2_id');
    };

    handleChangeCoef2 = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.controller.onChangeCoef2(e.target.value);
    };

    handleSelectLinesType = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        this.controller.onSelectLinesType(Number(e.target.value));
    };

    errorHint = (field: string): ReactNode => this.error(field);

    error = (field: string): string => this.controller.incubatorGame.getError(field);

    render(): ReactNode {
        const {controller, controller: {disabled}, params, userStore} = this.props as Props;
        const {
            game_id, gamer_1_id, gamer_2_id, coef_1, coef_2
        } = controller.incubatorGame.attributes;
        const key = game_id ? game_id : ELEMENT_NOT_FOUND;
        const game = this.props.gamesStore.findGameById(game_id) || this.props.gamesStore.lastGame();
        const {esports} = game;
        this.controller.setEsportsAttribute(esports);
        const isEditable = Boolean(params && params.id);
        const enableIncubator = userStore && userStore.user ? userStore.user.enable_incubator : false;

        if (!enableIncubator) {
            goToLinkIncubator();
            return null;
        }

        return <>
            <div className="field-row">
                {!isDesktop() && <div className="field-cell">
                    <IncubatorLineFormLineType
                        lines_type_id={this.props.lines_type_id}
                        isEditable={isEditable || this.props.isLineTypeEditable(game_id)}
                    />
                </div>}
                <div className="field-cell">
                    <div className="field-item column-1">
                        <SmartLabelTooltip
                            labelTooltip="tooltip_incubator_first_team_title"
                            descriptionTooltip="tooltip_incubator_teams"
                            classTooltip="right-position"
                        />
                        <ElementWithError
                            errorHint={this.errorHint('gamer_1_id')}
                            className={this.error('gamer_1_id') ? 'field-error' : ''}
                        >
                            <div className="classical-select" id="first_team_name">
                                <LiveSelectPicker
                                    keyForSelect={key}
                                    request={this.liveSearchApiService}
                                    value={{icon: null, label: null, value: gamer_1_id}}
                                    placeholder={I18n.t('tooltip_incubator_team_placeholder')}
                                    onChange={this.handleChangeGamer1Id}
                                />
                            </div>
                        </ElementWithError>
                    </div>
                    <div className="field-item column-2">
                        <label
                            className="field__label"
                            htmlFor="firstCoefficientIncubator">{I18n.t('tooltip_incubator_coef_title')} 1</label>
                        <input
                            className="field__input bold coefficient field-number__input"
                            id="firstCoefficientIncubator"
                            type="text"
                            min="0"
                            step="0.001"
                            value={coef_1}
                            onKeyDown={changeCoefByStep}
                            onChange={this.handleChangeCoef1}
                        />
                    </div>
                </div>
                <div className="field-cell">
                    <div className="field-item column-1">
                        <div className="field-item">
                            <SmartLabelTooltip
                                labelTooltip="tooltip_incubator_second_team_title"
                                descriptionTooltip="tooltip_incubator_teams"
                            />
                            <ElementWithError
                                errorHint={this.errorHint('gamer_2_id')}
                                className={this.error('gamer_2_id') ? 'field-error' : ''}
                            >
                                <div className="classical-select" id="second_team_name">
                                    <LiveSelectPicker
                                        keyForSelect={key}
                                        request={this.liveSearchApiService}
                                        value={{icon: null, label: null, value: gamer_2_id}}
                                        placeholder={I18n.t('tooltip_incubator_team_placeholder')}
                                        onChange={this.handleChangeGamer2Id}
                                    />
                                </div>
                            </ElementWithError>
                        </div>
                    </div>
                    <div className="field-item column-2">
                        <div className="field-item">
                            <label
                                className="field__label"
                                htmlFor="secondCoefficientIncubator">
                                {I18n.t('tooltip_incubator_coef_title')} 2</label>
                            <input
                                className="field__input bold coefficient field-number__input"
                                id="secondCoefficientIncubator"
                                type="text"
                                min="0"
                                value={coef_2}
                                step="0.001"
                                onKeyDown={changeCoefByStep}
                                onChange={this.handleChangeCoef2}
                            />
                        </div>
                    </div>
                    <div className="field-item column-3">
                        <IncubatorLineFormChangeCoef
                            controller={controller}
                        />
                    </div>
                </div>
            </div>
            <div className="field-row">
                <button
                    className={'button-adaptive button-adaptive_casino' +
                        `${disabled ? 'button-adaptive_disable' : ''}`}
                    disabled={disabled}
                >
                    {I18n.t('create')}
                </button>
            </div>
        </>;
    }
}
