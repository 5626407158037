import {action, makeObservable, observable} from 'mobx';
import TableInterface from 'app/interfaces/poker/TableInterface';
import Get from 'app/utils/Get';
import {
    subcribePokerTables,
    subscribePokerTable,
    unsubscribe
} from 'modules/WsSubscription';
import PlayerInterface from 'app/interfaces/poker/PlayerInterface';
import musicPlayer from 'app/components/sounds';

export class PokerStore {
    @observable tables: TableInterface[] = [];

    @observable currentTable: TableInterface = null;

    @observable player: PlayerInterface = null;

    @observable isEnabledSound = true;

    @observable subscription = null;

    @observable subcriptionOnTables = null;

    constructor() {
        makeObservable(this);
    }

    @action
    openTable(table_id: string): void {
        const table = this.tables.find(x => x.table_id === table_id);

        this.currentTable = table;
        this.player = table.player;
        this.subcribeChannel();
    }

    @action
    closeTable(): void {
        this.currentTable = null;
        this.ubsubcribeChannel();
    }

    @action
    toggleSound = (): void => {
        this.isEnabledSound = !this.isEnabledSound;
    };

    @action
    setTables = (tables: TableInterface[]): void => {
        this.tables = tables;
    };

    @action
    updateCurrentTable = (table: TableInterface): void => {
        this.currentTable = table;
    };

    @action
    updatePlayer(player: PlayerInterface): void {
        this.player = player;

        if (player.winner) {
            musicPlayer.play('poker_win_sound', this.isEnabledSound);
        }
    }

    @action
    ubsubcribeChannel = (): void => {
        this.subscription = unsubscribe(this.subscription);
    };

    @action
    subcribeChannel = (): void => {
        this.subscription = subscribePokerTable(this.currentTable.table_id, ({data: {event, payload}}) => {
            this.updateCurrentTable(payload.table);
            musicPlayer.play(`poker_${event}_sound`, this.isEnabledSound);
        });
    };

    listen(): void {
        this.subcriptionOnTables = subcribePokerTables(({data: {event, payload}}) => {
            if (event === 'update') {
                this.setTables(payload.tables);
            }
        });
    }

    loadTables = (): void => {
        new Get({
            url: '/poker/tables'
        }).execute()
            .then(response => response.json())
            .then(response => {
                this.setTables(response);
            });
    };
}
