import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import {IncubatorCreateLineController} from 'app/controllers/IncubatorCreateLineController';
import {langsValue} from 'app/utils/langs/langsValue';
import IncubatorLinesTypeInterface from 'app/interfaces/IncubatorLinesTypeInterface';
import {ElementWithError} from 'app/components/reusable/ElementWithError';
import SmartSelect from 'app/components/reusable/SmartSelect';

interface Props {
    controller?: IncubatorCreateLineController
    lines_type_id: number
    isEditable: boolean
}

@inject('controller')
@observer
export default class IncubatorLineFormLineType extends Component<Props> {
    get controller(): IncubatorCreateLineController {
        return this.props.controller;
    }

    get linesTypes(): IncubatorLinesTypeInterface[] {
        const {controller} = this.props;
        return controller.linesTypeBySport();
    }

    handleSelectLinesType = (newValue: string): void => {
        this.controller.onSelectLinesType(Number(newValue));
        this.controller.incubatorGame.clearError('lines_type_id');
    };

    errorHint = (field: string): ReactNode => this.error(field);

    error = (field: string): string => this.controller.incubatorGame.getError(field);

    render(): ReactNode {
        const {lines_type_id, isEditable} = this.props;

        return <div className="field-item column-3">
            <label className="field__label">{I18n.t('type_line')}</label>
            <ElementWithError
                errorHint={this.errorHint('lines_type_id')}
                className={this.error('lines_type_id') ? 'field-error' : ''}
            >
                <SmartSelect
                    key="incubator_type_line"
                    collection={this.linesTypes && this.linesTypes.map(el => ({
                        text: langsValue(el, 'name'),
                        text2: '',
                        value: el.id.toString()
                    }))}
                    disabled={isEditable}
                    onChange={this.handleSelectLinesType}
                    value={lines_type_id && lines_type_id.toString()}
                    id="line_type_id"
                    placeholder_text_multiple={I18n.t('tooltip_incubator_game_placeholder')}
                    search
                />
            </ElementWithError>
        </div>;
    }
}
