import {FcmConfigInterface} from 'app/interfaces/FcmConfigInterface';

const config: FcmConfigInterface = {
    apiKey: null,
    appId: null,
    authDomain: null,
    databaseURL: null,
    messagingSenderId: null,
    projectId: null,
    storageBucket: null
};

export const setFCMConfig = (newConfig: FcmConfigInterface): void => {
    Object.keys(config).forEach(key => {
        config[key] = newConfig[key];
    });
};

export default config;
