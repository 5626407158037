import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {modalAlert, modalConfirm} from 'app/utils/modals/popups';
import I18n from 'app/i18n';
import {modalsStore, orderStore} from 'app/store/global';
import AmountInput from 'app/components/my_profile/artifacts/amount_input';
import WithChart from 'app/components/my_profile/artifacts/with_chart';
import {artifactName} from 'app/components/my_profile/artifacts/utils';
import {fixed2} from 'app/utils/fixed';

export default class PlaceByOrderArtifactPopup extends PureComponent {
    static MIN_TOTAL_PRICE = 0.1;

    state = {
        total_price: ''
    };

    static propTypes = {recipe: PropTypes.object};

    handlePriceChange = e => {
        const {value} = e.target;
        this.setState({total_price: value});
    };

    handleSubmit = e => {
        e && e.preventDefault();
        if (!this.validate()) {
            return;
        }

        const {recipe} = this.props;

        modalConfirm(
            I18n.t('buy_order_artifact_confirm',
                {artifact: artifactName(recipe)}),
            () => orderStore.request(recipe, {
                ...this.state,
                recipe_id: recipe.id
            }, () => {
                modalAlert(I18n.t('buy_order_artifact_success', {artifact: artifactName(recipe)}));
                modalsStore.hideModal(this.props.popupId);
            })
        );
    };

    validate() {
        const {total_price} = this.state;
        return Number(total_price) >= this.minChangePrice();
    }

    minChangePrice = () => {
        let minChangePrice = Number(fixed2(1.01 * this.props.max_price));
        if (minChangePrice === Number(this.props.max_price)) {
            minChangePrice += 0.01;
        }
        return Number(fixed2(Math.max(minChangePrice, this.constructor.MIN_TOTAL_PRICE)));
    };

    render() {
        const {recipe} = this.props;
        return <PopupDialog
            id={this.props.popupId}
            setFocus={true}
        >
            <WithChart artifact={recipe} recipe={recipe} header={I18n.t('place_buy_order')}>
                <form className="sell-inventory__popup--form" onSubmit={this.handleSubmit}>
                    <div className="sell-inventory__popup--form-content">
                        <div className="sell-inventory__popup--form__row">
                            <AmountInput
                                onChangeHandler={this.handlePriceChange}
                                value={this.state.total_price}
                                min={this.minChangePrice()}
                                placeholder={`${I18n.t('min')} ${fixed2(this.minChangePrice())}`}
                                stateName="total_price"
                                label={I18n.t('you_pay')}
                            />
                        </div>
                    </div>
                    <div className="sell-inventory__popup--form-footer">
                        <div className="sell-inventory__popup--form-footer__row">
                            <div className="sell-inventory__popup--actions margin_left_a">
                                <button className={`btn${this.validate() ? '' : ' btn--disable'}`} type="submit">
                                    {I18n.t('place_buy_order')}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </WithChart>
        </PopupDialog>;
    }
}
