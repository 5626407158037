import React, {Component, ChangeEvent, KeyboardEvent} from 'react';
import {isArticlePromotion, isArticleNews, articleHeading} from 'app/utils/ArticleHeading';
import SearchInput from 'app/components/SearchInput';
import EmptyResults from 'app/components/articles/EmptyResults';
import Pagination from 'app/components/pagination';
import {inject, observer} from 'mobx-react';
import {ArticlesStore} from 'app/store/ArticlesStore';
import PaginateInterface from 'app/interfaces/articles/PaginateInterface';
import ParamsDataInterface from 'app/interfaces/articles/ParamsDataInterface';
import Preview from 'app/components/pages/articles/Preview';
import {getPage} from 'app/utils/articles/getPage';
import {KEY_ENTER} from 'app/utils/Consts';
import SmartFullPageLoader from 'app/components/reusable/smart_loaders/SmartFullPageLoader';

interface ArticleProps {
    articlesStore?: ArticlesStore
    type: string
    paginate?: PaginateInterface
}

@inject('articlesStore')
@observer
export default class Articles extends Component<ArticleProps> {
    constructor(props: ArticleProps) {
        super(props);
        this.props.articlesStore.init({page: getPage() || 1}, this.props.type);
    }

    clearSearch = (): void => this.props.articlesStore.setSearchValue('', this.props.type);

    handleRefreshSearch = (): void => {
        this.clearSearch();
        this.loadData();
    };

    searchHandler = (event: KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === KEY_ENTER) {
            this.loadData(null, event.currentTarget.value);
        }
    };

    searchValueHandler = (event: ChangeEvent<HTMLInputElement>): void => {
        this.props.articlesStore.setSearchValue(event.currentTarget.value, this.props.type);
    };

    loadData = (params?: ParamsDataInterface, searchValue = ''): void => {
        this.props.articlesStore.loadData(params, searchValue, this.props.type);
    };

    render(): React.ReactNode {
        const {type} = this.props;
        const {data, loading, paginate, searchValue} = this.props.articlesStore.getDataByType(type);
        return loading
            ? <SmartFullPageLoader/>
            : <>
                <div className="content-header">
                    <h1 className="title-h1" key="title">{articleHeading(type)}</h1>
                    {(isArticlePromotion(type) || isArticleNews(type)) && <SearchInput
                        handleKeyPress={this.searchHandler}
                        handleChange={this.searchValueHandler}
                        value={searchValue}
                        onClick={this.handleRefreshSearch}/>}
                </div>
                <div className="content__holder content__holder--no-paddings">
                    {data.length > 0
                        ? data.map(article => <Preview key={article.id} data={article} type={type}/>)
                        : <EmptyResults/>}
                </div>
                <Pagination {...paginate} searchValue={searchValue} switchPage={this.loadData}/>
            </>;
    }
}
