import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {NavLink} from 'react-router-dom';
import {getTableLinkPath} from 'app/utils/link_helper';
import ListOfLanguages from '../langs';
import {SignUpButton} from '../auth/log_in_sign_up_button';
import FastRegistration from 'app/components/auth/FastRegistration';

import devicesPng from 'images/referrals/devices.png';
import devicesMobPng from 'images/referrals/devices_mobile.png';
import iconEPng from 'images/referrals/icon-e.png';
import iconGiftPng from 'images/referrals/icon-gift.png';
import iconPhonePng from 'images/referrals/icon-phone.png';
import JoinUs from 'app/components/pages/referrals/JoinUs';
import Slider from 'react-slick';
import Footer from 'site_version/common/Footer';
import LogoLink from 'site_version/com/logoLink';

export default class Referrals extends PureComponent {
    render = () => <div className="referrals">
        <div className="header header--simple">
            <div className="wrap wrap--header">
                <LogoLink/>
                <div className="header__content">

                    <div className="header__controls">
                        <div className="header__control">
                            <ListOfLanguages/>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div className="main-block">
            <div className="wrap wrap--content">
                <div id="react-content" className="content">
                    <div className="referrals-register">
                        <div className="referrals-register__inner">
                            <div className="your-day">
                                <div>
                                    <h2 className="your-day__title">{I18n.t('referrals.your_day__title')}</h2>
                                    <p>
                                        {I18n.t('referrals.your_day__text1')}
                                        <span className="color-orange">
                                            {I18n.t('referrals.your_day__text2')}
                                        </span>
                                    </p>
                                </div>
                                <picture>
                                    <source media="(min-width: 568px)" srcSet={devicesPng}/>
                                    <source media="(max-width: 567px)" srcSet={devicesMobPng}/>
                                    <img src={devicesPng} alt="Devices"/>
                                </picture>
                                <div className="reg-button">
                                    <SignUpButton usingOn="REFERRAL_PAGE"/>
                                </div>
                            </div>
                        </div>
                        <div className="form-register">
                            <h2 className="popup-header">{I18n.t('register')}</h2>
                            <FastRegistration usingOnTab="REFERRAL_PAGE"/>
                        </div>
                    </div>

                    <div className="why-egb">
                        <div className="why-egb__title">{I18n.t('referrals.why_egb__title')}</div>
                        <div className="why-egb__items">
                            <div className="why-egb__item">
                                <div className="why-egb__item-header">
                                    <div className="why-egb__item-img">
                                        <img
                                            src={iconEPng}
                                            alt="E"
                                        />
                                    </div>
                                    <div className="why-egb__item-title">
                                        {I18n.t('referrals.why_egb__item1_title')}
                                    </div>
                                </div>
                                <div className="why-egb__item-text">
                                    {I18n.t('referrals.why_egb__item1_text')}
                                </div>
                            </div>
                            <div className="why-egb__item">
                                <div className="why-egb__item-header">
                                    <div className="why-egb__item-img">
                                        <img
                                            src={iconGiftPng}
                                            alt="Gift"
                                        />
                                    </div>
                                    <div className="why-egb__item-title">
                                        {I18n.t('referrals.why_egb__item2_title')}
                                    </div>
                                </div>
                                <div className="why-egb__item-text">
                                    {I18n.t('referrals.why_egb__item2_text')}
                                </div>
                            </div>
                            <div className="why-egb__item">
                                <div className="why-egb__item-header">
                                    <div className="why-egb__item-img">
                                        <img src={iconPhonePng} alt="Mobile"/>
                                    </div>
                                    <div className="why-egb__item-title">
                                        {I18n.t('referrals.why_egb__item3_title')}
                                    </div>
                                </div>
                                <div className="why-egb__item-text">
                                    {I18n.t('referrals.why_egb__item3_text')}
                                </div>
                            </div>
                        </div>

                        <Slider slidesToShow={1} arrows={false} dots={true} infinite={false}>
                            <div className="why-egb__item">
                                <div className="why-egb__item-header">
                                    <div className="why-egb__item-img">
                                        <img
                                            src={iconEPng}
                                            alt="E"
                                        />
                                    </div>
                                    <div className="why-egb__item-title">
                                        {I18n.t('referrals.why_egb__item1_title')}
                                    </div>
                                </div>
                                <div className="why-egb__item-text">
                                    {I18n.t('referrals.why_egb__item1_text')}
                                </div>
                            </div>
                            <div className="why-egb__item">
                                <div className="why-egb__item-header">
                                    <div className="why-egb__item-img">
                                        <img
                                            src={iconGiftPng}
                                            alt="Gift"
                                        />
                                    </div>
                                    <div className="why-egb__item-title">
                                        {I18n.t('referrals.why_egb__item2_title')}
                                    </div>
                                </div>
                                <div className="why-egb__item-text">
                                    {I18n.t('referrals.why_egb__item2_text')}
                                </div>
                            </div>
                            <div className="why-egb__item">
                                <div className="why-egb__item-header">
                                    <div className="why-egb__item-img">
                                        <img src={iconPhonePng} alt="Mobile"/>
                                    </div>
                                    <div className="why-egb__item-title">
                                        {I18n.t('referrals.why_egb__item3_title')}
                                    </div>
                                </div>
                                <div className="why-egb__item-text">
                                    {I18n.t('referrals.why_egb__item3_text')}
                                </div>
                            </div>
                        </Slider>
                    </div>
                    <div className="egb-your-guide">
                        <div className="egb-your-guide__title">
                            <a href="/">EGB</a> – {I18n.t('referrals.egb_your_guide__title1')}<br/>
                            {I18n.t('referrals.egb_your_guide__title2')}
                        </div>
                        <NavLink
                            to={getTableLinkPath('/play/bets')}
                            className="btn btn--big-on-mobile"
                        >
                            {I18n.t('referrals.egb_your_guide__btn')}
                        </NavLink>
                    </div>
                    <JoinUs/>
                    <Footer/>
                </div>
            </div>
        </div>
    </div>;
}
