import React from 'react';
import {i18n_t} from 'app/i18n';
import ArtifactImages from '../../rating/artifact_images';
import CancelPrediction from 'app/components/compendium/CancelPrediction';
import RatingPoints from './RatingPoints';
import InventoryItemInterface from 'app/interfaces/InventoryItemInterface';

interface PredictionInterface {
    id: number,
    expired: boolean
}

interface PrizeSectionProps {
    potentialPoints: number,
    rating_points: number,
    isCorrectAnswer: boolean,
    isCalculated: boolean,
    isActive: boolean,
    isCanceledByUser: boolean,
    isCanceledByAdmin: boolean,
    recipes: InventoryItemInterface[],
    prediction: PredictionInterface
}

export default class PrizeSection extends React.PureComponent<PrizeSectionProps> {
    cancelPrediction = (): void => {
        const {prediction: {id}} = this.props;

        new CancelPrediction(id).cancelAnswer();
    };


    render(): React.ReactNode {
        const {
            isCalculated,
            isCorrectAnswer,
            isActive,
            isCanceledByUser,
            isCanceledByAdmin,
            rating_points,
            prediction,
            potentialPoints
        } = this.props;

        const renderState = () => {
            if (isCanceledByUser) {
                return <span className="color-gray">{i18n_t('canceled_by_user')}</span>;
            } else if (isCanceledByAdmin) {
                return <span className="color-gray">{i18n_t('canceled_by_admin')}</span>;
            }

            return <ArtifactImages {...this.props}/>;
        };

        return <td className="prediction-prize">
            <div className="mobile-label">
                <span>
                    <svg height="14px" width="15px">
                        <use xlinkHref="#svg-cup-table"/>
                    </svg>
                </span>
                <span>{i18n_t('table.prize_title')}</span>
            </div>

            <RatingPoints
                potentialPoints={potentialPoints}
                isCalculated={isCalculated}
                isCanceledByUser={isCanceledByUser}
                isCanceledByAdmin={isCanceledByAdmin}
                isCorrectAnswer={isCorrectAnswer}
                rating_points={rating_points}
            />

            {renderState()}

            {isActive && !prediction.expired && <span className="btn-wrap">
                <button className="btn" onClick={this.cancelPrediction}>
                    {i18n_t('button_cancel')}
                </button>
            </span>}
        </td>;
    }
}
