import React, {Component, Fragment} from 'react';
import TradeSettings from './trades/trade_settings';
import {Outlet} from 'react-router';

export default class Trades extends Component {
    render() {
        return <Fragment>
            <TradeSettings/>
            <Outlet />
        </Fragment>;
    }
}
