import {BetStrategy} from 'app/components/bet_slip/strategies/Bet.strategy';
import {fixed2, fixed3} from 'app/utils/fixed';
import {isPlayMoney} from 'app/lines/isPlayMoney';
import MakeBetInterface from 'app/interfaces/MakeBetInterface';
import {ParamsInterface} from 'app/utils/Get';
import {gamerCoef} from 'app/lines/gamerCoef';

const MIN_COEF = 1.25;

export class IncubatorStrategy extends BetStrategy {
    get postUrl(): string {
        return '/incubator2/bets';
    }

    getParams = (params: MakeBetInterface): ParamsInterface => {
        const {amount, coef, line, num, type} = params;
        return {
            bet: {
                amount: fixed2(amount),
                coef,
                event: num,
                line_id: line.id,
                playmoney: isPlayMoney(type)
            }
        };
    };

    setFormCoefSingle = (firstOpening = false): void => {
        const {setFormCoef, formCoef, coefFocus} = this.betSlipStore;
        const coefSingle = this.getCoefSingle();
        const lessMin = Number(coefSingle) < MIN_COEF;
        const newCoefSingle = !coefFocus && lessMin ? fixed3(MIN_COEF) : coefSingle;

        if ((firstOpening || Number(coefSingle) !== 0 && lessMin) && Number(formCoef) !== Number(newCoefSingle)) {
            setFormCoef(newCoefSingle);
        }
    };

    placeBetSuccess = (params: MakeBetInterface): void => {
        super.placeBetSuccess(params);
        this.betSlipStore.setIncubator2Form(false);
    };

    setCoef = (newCoef: string): void => {
        const {setFormCoef, setNetPrize, amount, formCoef, setWin} = this.betSlipStore;
        const validatedCoef = this.validationCoef(newCoef, String(formCoef));
        const validatedCoefNumber = Number(validatedCoef);

        const netPrize = Number(this.betSlipStore.amount) * (validatedCoefNumber - 1);
        setFormCoef(validatedCoef);
        setNetPrize(fixed2(netPrize > 0 ? netPrize : 0));
        setWin(fixed2(Number(amount) * validatedCoefNumber));
    };

    getFormCoef = (): string => {
        const {formCoef} = this.betSlipStore;
        return formCoef && parseFloat(formCoef) > 0 ? formCoef : null;
    };

    getCoefSingle = (): string => fixed3(this.getFormCoef() ||
            String(gamerCoef(this.betSlipStore.selectedLine, this.betSlipStore.num)));

    getLimit = (type: string): number => {
        const {user: {balance, play_money}} = this.betSlipController.userStore;

        return Number(isPlayMoney(type) ? play_money : balance);
    };

    maxWin = (): number => null;

    removeOnclick = (): void => {
        super.removeOnclick();
        this.betSlipStore.setIncubator2Form(false);
    };

    private validationCoef = (newCoef: string, coef: string): string => {
        let tempNewCoef = newCoef;

        if (tempNewCoef === '') {
            return '';
        }

        if (tempNewCoef && !(/^[1-9]\d*[,.]?\d{0,3}$/u).test(tempNewCoef)) {
            return coef;
        }

        if (typeof tempNewCoef === 'string') {
            tempNewCoef = tempNewCoef.replace(',', '.');
        }
        return tempNewCoef;
    };
}
