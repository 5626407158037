import ObmenkaDepositClass from 'app/components/deposit/obmenka/ObmenkaDepositClass';
import {inject, observer} from 'mobx-react';
import {ResponseType} from '../form/DepositForm';

@inject('depositsStore')
@observer
export default class PiastrixDeposit extends ObmenkaDepositClass {
    amountParam = (response: ResponseType): string => {
        const parameters = response.start_result.parameters as Array<{name: string, value: string}>;
        return parameters.find(p => p.name === 'amount').value;
    };
}
