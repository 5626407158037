import React, {Component} from 'react';
import {i18n_t} from 'app/i18n';
import {inject, observer} from 'mobx-react';
import {artifactName, artifactShopLink} from 'app/components/my_profile/artifacts/utils';
import {ArtifactImage} from 'app/components/my_profile/artifacts/image';
import {betMoney, scrollToTop} from 'app/utils';
import {getLinkPath, goToRoute} from 'app/utils/link_helper';
import {Link} from 'react-router-dom';
import {goToShopLootbox, goToShopOtherItem} from 'app/utils/app_links';

interface MarketAsideProps {
    recipeStore: any;
}

const Market = inject('recipeStore')(observer(class MarketAside extends Component {
    render() {
        const {onSale} = (this.props as MarketAsideProps).recipeStore;
        return <div className="market-aside">
            <div className="chat__heading">
                <div className="chat__title__title">
                    <div className="chat__title-text">
                        <span className="aside-preview-post__title">
                            <Link to={getLinkPath('/shop/marketplace')} onClick={scrollToTop}>
                                {i18n_t('marketplace')}
                            </Link>
                        </span>
                    </div>
                </div>
            </div>
            <div className="js-container-active">
                <div className="market-aside__content">
                    {onSale.map(a => <ArtifactAside {...a} key={a.id}/>)}
                </div>
            </div>
        </div>;
    }
}));

interface ArtifactAsideProps {
    lootbox: any;
    slug: string;
    start_price: any;
    type_shop?: string;
    id?: number;
}

class ArtifactAside extends Component<ArtifactAsideProps, {}> {
    onClick = () => {
        if (this.props.lootbox) {
            goToShopLootbox(this.props.slug);
        }
        if (this.props.type_shop === 'other') {
            goToShopOtherItem(this.props.id);
        } else {
            goToRoute(artifactShopLink(this.props, true));
        }
        scrollToTop();
    };

    render() {
        return <div className="market-aside__item" onClick={this.onClick}>
            <div className="market-aside__item--img-wrap">
                <div className="market-aside__item--img"><ArtifactImage artifact={this.props}/></div>
            </div>
            <div className="market-aside__item--title">{artifactName(this.props)}</div>
            <div className="market-aside__item--price">
                {i18n_t('starting_at')} {betMoney({amount: this.props.start_price})}
            </div>
        </div>;
    }
}

export default Market;
