import CashoutSystemTab from 'app/components/cashout/CashoutSystemTab';
import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {getLinkPath} from 'app/utils/link_helper';
import {Link} from 'react-router-dom';

const activeFilter = (systemId, props) => props.systems[systemId] &&
    !props.systems[systemId].hidden &&
    (props.active && Boolean(props.wallets[systemId]) || !props.active && !props.wallets[systemId]);

export default class CashoutSystemTabs extends PureComponent {
    render() {
        const {active} = this.props;
        const systems = this.props.systems_order.filter(s => activeFilter(s, this.props));
        return systems.length > 0 && <div className="payment-systems__list">
            <h3 className="payment-systems__cashout__heading">
                {active ? I18n.t('registered_payment_method') : I18n.t('available_payment_systems')}
            </h3>
            {!active && <Link
                className="link_cashout_deposit_first"
                to={getLinkPath('/deposits')}
            >
                <svg height="16px" width="16px">
                    <use xlinkHref="#svg-info"/>
                </svg>
                {I18n.t('user.cashout_deposit_first')}
            </Link>}
            <div className="cashout__systems payment-systems__cashout__wrap">
                {systems.map((v, i) => {
                    const system = this.props.systems[v];
                    return <CashoutSystemTab
                        key={i}
                        id={v}
                        system={system}
                        slug={system.slug}
                        active={this.props.active}
                    />;
                })}
            </div>
        </div>;
    }
}

export const systemClassName = function(systemName) {
    return `cashout__icon cashout__icon--${systemName === 'coinify' ? 'bitcoin' : systemName}`;
};
