import React from 'react';
import {hideLastPopup, showPopup} from 'app/utils/modals/ModalsService';
import {modalAlert} from '../utils/modals/popups';
import {i18n_t} from '../i18n';
import Post from '../utils/Post';
import SendSteamSkinPopup from 'app/components/my_profile/artifacts/SendSteamSkinPopup';
import {updateInfo} from 'app/common';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import CollectionStore from 'stores/CollectionStore';
import SteamTradeInterface from 'app/interfaces/SteamTradeInterface';
import {action, computed, makeObservable, observable, override} from 'mobx';
import {updateOrAddObject} from '../utils/updateArraysObject';
import Get from 'app/utils/Get';

const STEAM_SKINS_FEED_STATES = [
    'in_user_inventory',
    'in_user_inventory_auto',
    'waiting_for_send',
    'transferring',
    'pending_edits'
];

export class SteamTradeStore extends CollectionStore<SteamTradeInterface> {
    @observable steam_trades_count: number = null;

    constructor() {
        super();
        makeObservable(this);
    }

    channel = (): string => null;

    feedUrl = (): string => null;

    @override
    updateElementCollection(item): void {
        updateOrAddObject(this, 'collection', item);
    }

    get steamTrades(): SteamTradeInterface[] {
        if (this.collection) {
            return this.collection.filter(steamTrade => STEAM_SKINS_FEED_STATES.includes(steamTrade.state));
        }
        return [];
    }

    @computed
    get steamTradesOnApproval(): SteamTradeInterface[] {
        if (this.collection) {
            return this.collection.filter(steamTrade => steamTrade.state === 'on_approval');
        }
        return [];
    }

    hasSteamTradesWithWrongLink(): SteamTradeInterface {
        if (this.collection) {
            return this.collection.find(a => a.state === 'pending_edits');
        }
        return null;
    }


    @action
    getUserSteamTrades(): void {
        new Get({url: '/my_profile/steam_trades'})
            .execute()
            .then(data => data.json())
            .then(data => {
                this.updateStore(data.steam_trades);
            });
    }


    @action
    updateStore(steam_trades: SteamTradeInterface[]): void {
        this.collection = steam_trades;
        this.steam_trades_count = steam_trades ? steam_trades.length : 0;
    }


    send(id: number, popupId = null): Promise<any> {
        return new Post({
            params: {id},
            url: `/steam/trades/${id}/send_skin`
        }).execute()
            .then(response => {
                if (response.ok) {
                    hideLastPopup(popupId || 'gift-popup');
                    toastSuccess(i18n_t('artifact_transferring_is_accepted_for_transferring'));
                    updateInfo();
                } else {
                    response
                        .json()
                        .then(responseJSON => Object.values(responseJSON).map((error: string) => toastError(error)));
                    return updateInfo();
                }
                return response;
            });
    }

    showSendAsGiftPopup(steamTrade): void {
        showPopup('inventory-popup', props => <SendSteamSkinPopup artifact={steamTrade} popupId={props.popupId}/>);
    }

    sendAsGift(artifact, user, quantity): Promise<any> {
        return new Post({
            params: {name: user},
            url: `/steam/trades/${artifact.id}/send_as_gift`
        }).execute()
            .then((response: Response) => {
                if (response.ok) {
                    updateInfo();
                    modalAlert(
                        i18n_t('send_artifact_success', {
                            artifact: artifact.steam_skin.name,
                            quantity,
                            user
                        })
                    );
                }
            });
    }

    sell(id, steamSkinName, price, afterSell = null, popupId = null): Promise<any> {
        return new Post({
            params: {id},
            url: `/steam/trades/${id}/sell`
        }).execute()
            .then(response => {
                if (response.ok) {
                    hideLastPopup(popupId || 'gift-popup');
                    toastSuccess(i18n_t('sell_steam_skin_success', {amount: price,
                        artifact: steamSkinName}));
                    updateInfo();
                    afterSell && afterSell();
                }
                return response;
            });
    }

    confirmSteamTrade(id: number): Promise<any> {
        return new Post({
            params: {id},
            url: `/steam/trades/${id}/confirm_trade_link`
        }).execute();
    }
}
