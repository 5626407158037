import React from 'react';
import Row from './index';

export default class HandicapLine extends Row {
    renderTableExpand = (): void => null;

    renderGameImage = (): void => null;

    render(): React.ReactNode {
        const {id, color, active_nested_bets_count} = this.props.bet;
        const colorClass = `table-bets__inner-row-holder holder--color${color}`;
        return <div className="table-bets__inner1-row" key={`nested_bet_${id}`}>
            <div className={colorClass}>
                <span className="table-expand table-expand--main table-bets__border-bottom"/>

                <div className="table-bets__content table-bets__border-bottom">
                    {this.renderButtonPlus('table-expand--inner js-expand-inner', active_nested_bets_count)}
                    {this.renderBody(true)}
                </div>
            </div>
        </div>;
    }
}
