import {RatesType} from 'app/store/DepositsStore';
import {roundAmount} from './roundAmount';

export const CURRENCY_TO_ROUND = [
    'RUB',
    'UAH',
    'BYN'
];

interface ParamsInterface {
    currency: string
    currency_amount: string
    amount: string
    rates: RatesType
}

export const roundByCurrency = (params: ParamsInterface): string => CURRENCY_TO_ROUND.includes(params.currency)
    ? roundAmount(Number(params.amount), params.currency, params.rates).toString()
    : params.currency_amount;
