import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {betMoney} from 'app/utils';
import BonusInterface, {Row} from 'app/interfaces/BonusInterface';
import {ADVANCE_TYPES} from 'app/utils/balance/betsBalance';

const bonusProgress = part => Math.floor(part.score / part.score_all * 100);

interface Props {
    bonus: BonusInterface
}

export default class BonusProgress extends PureComponent<Props> {
    renderRow = (part: Row, key = 1): ReactNode => <div className="table-bonuses__row" key={key}>
        <div className="table-bonuses__col-1">
            {betMoney({amount: part.sum || part.sum_part})}
        </div>
        <div className="table-bonuses__col-2">{betMoney({amount: part.score_all.toString()})}</div>
        <div className="table-bonuses__col-3">{betMoney({amount: part.score.toString()})}</div>
        <div className="table-bonuses__col-4">
            <div className="progress">
                <progress value={bonusProgress(part)} max="100"
                    className="progress__default"/>
                <div className="progress__bg">
                    <div className="progress__scale">
                        <div className="progress__scale-item"/>
                    </div>
                    <div style={{width: `${bonusProgress(part)}%`}}
                        className="progress__bar"/>
                    <svg height="11px" width="15px">
                        <use xlinkHref="#svg-check"/>
                    </svg>
                </div>
            </div>
        </div>
    </div>;

    render(): ReactNode {
        const {bonus} = this.props;
        return <div className="table-bonuses">
            <div className="tables-heading table-bonuses__heading">
                <div className="table-bonuses__col-1">
                    {I18n.t('user.amount_advance_label_bonuses')}
                </div>
                <div className="table-bonuses__col-2">
                    {I18n.t('user.label_bonuses.needed_turnover')}
                </div>
                <div className="table-bonuses__col-3">
                    {I18n.t('user.label_bonuses.turnover')}
                </div>
                <div className="table-bonuses__col-4"/>
            </div>
            {ADVANCE_TYPES.includes(bonus.type)
                ? this.renderRow(bonus)
                : bonus.rows.map((part, i) => this.renderRow(part, i))}
        </div>;
    }
}
