import React, {PureComponent} from 'react';
import I18n from 'app/i18n';

export class DoubleDepositBanner extends PureComponent {
    render(): React.ReactNode {
        return <div className="double-deposit-banner">
            <span className="icon">
                <svg className="svg-default"><use xlinkHref="#svg-x2-icon"/></svg>
            </span>
            <div className="description">
                <p>{I18n.t('user_promotion.first_deposit_title')}</p>
                <p>{I18n.t('user_promotion.first_deposit_banner_title')}</p>
            </div>
        </div>;
    }
}
