import {BaseModel} from 'app/models/BaseModel';
import IncubatorGameValidator from 'app/validators/IncubatorGameValidator';
import {RestApiService} from 'app/services/RestApiService';
import {IncubatorLineStore} from 'app/store/IncubatorLineStore';
import {UserStore} from 'app/store/UserStore';
import {action, makeObservable, override} from 'mobx';
import I18n from 'app/i18n';
import {goToRoute} from 'app/utils/link_helper';

interface GamerInterface {
    country_code: string
    nickname: string
    race: string
}

export interface IncubatorGameAttributes {
    id?: number
    hash_id?: string
    game_id?: number
    stream_link?: string
    pledge?: string
    gamer_1_id?: number
    coef_1?: string
    gamer_2_id?: number
    coef_2?: string
    winner?: number
    gamer_1_score: number
    gamer_2_score: number
    date: number
    gamer_1: GamerInterface
    gamer_2: GamerInterface
    coef1_win: string
    coef2_win: string
    color: number
    status: number
    hidden: boolean
    lines_type_id: number
    user_bets_sum_1: string
    user_bets_sum_2: string
    accepted_bets: number
    esports: boolean
    max: number
    max_high_limit: number
    isCreator?: boolean
    coef_changing: number
}

export class IncubatorGameModel extends BaseModel<IncubatorGameAttributes> {
    url = '/incubator/lines';

    paramsKey = 'line';

    Validator = IncubatorGameValidator;

    SportValidator = IncubatorGameValidator;

    apiService = new RestApiService(this.url);

    lineStore: IncubatorLineStore;

    userStore: UserStore;

    updateText = I18n.t('update_line');

    constructor(attributes: IncubatorGameAttributes, userStore: UserStore) {
        super(attributes);
        makeObservable(this);
        this.lineStore = new IncubatorLineStore(userStore, attributes, this);
    }

    @override
    setAttribute(attributes: IncubatorGameAttributes): void {
        super.setAttribute(attributes);
        this.lineStore?.setCollection(attributes);
    }

    @action
    setUpdateText(text: string): void {
        this.updateText = text;
    }

    @override
    load(): Promise<IncubatorGameAttributes> {
        return this.apiService.show(this.attributes.id.toString())
            .then(response => response.json())
            .then<IncubatorGameAttributes>(data => {
                if (data.success) {
                    this.setAttribute(data.line);
                    return data.line;
                }

                goToRoute('/play/incubator/all');
                return null;
            });
    }

    @action
    cancel(): Promise<void> {
        return this.apiService.postAction('cancel', {line_id: this.attributes.id.toString()}).then();
    }

    @action
    clearError(field: string): void {
        const error = this.errors && this.errors[field];

        if (error) {
            delete this.errors[field];
        }
    }

    getError = (field: string): string => {
        const error = this.errors && this.errors[field];

        if (error) {
            return typeof error === 'object'
                ? error.join('\r\n')
                : error;
        }
        return null;
    };
}
