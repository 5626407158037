interface QueryObjectInterface{
    betType: string,
    discipline: string,
    betEvents: string,
    only24h: string,
    onlyUpcomingMatches: string,
    filtersWithSport: string,
    events: string[]
}

export const getQueryBetType = function(filterType = null): QueryObjectInterface {
    const query = new URLSearchParams(window.location.search);

    const getQueryParam = key => query.get(key);

    const events = getQueryParam(`${filterType}Events`);

    const eventsArray = () => {
        if (events) {
            if (typeof events === 'string') {
                return [events];
            }
            return events;
        }
        return null;
    };

    return {
        betEvents: getQueryParam(`${filterType}Event`),
        betType: getQueryParam(`${filterType}Type`),
        discipline: getQueryParam(`${filterType}Disc`),
        events: eventsArray(),
        filtersWithSport: getQueryParam(`${filterType}Sport`),
        only24h: getQueryParam(`${filterType}24h`),
        onlyUpcomingMatches: getQueryParam(`${filterType}Up`)
    };
};

