import React from 'react';
import I18n from 'app/i18n';

export function inStockOnly(colors) {
    return colors.filter(color => color.in_stock);
}

export function colorDropDown(color) {
    const {in_stock, id, color_name} = color;
    return [in_stock
        ? null
        : <span className="product-description__color-cross-icon" key={`icon_${id}`}>
            <svg height="15px" width="15px">
                <use xlinkHref="#svg-close"/>
            </svg>
        </span>,
    <span className="product-description__colors-item-details" key={`details_${id}`}>
        <p className="product-description__color-text">{color_name}</p>
        <p className="product-description__color-availability">{I18n.t(`shop.${in_stock ? 'in_stock' : 'out_of_stock'}_text`)}</p>
    </span>];
}

export const imageLoader = (imageUrl, onLoadCallback) => {
    const image = new Image;
    image.onload = onLoadCallback;
    image.src = imageUrl;
};
