import SiteNotificationInterface from 'app/interfaces/SiteNotificationInterface';
import NotificationStore from 'app/store/notifications';
import Post from 'app/utils/Post';
import {deleteObjectById} from 'app/utils/updateArraysObject';
import {action, makeObservable, observable} from 'mobx';

export class SiteNotificationStore {
    @observable siteNotifications: SiteNotificationInterface[] = [];

    constructor() {
        makeObservable(this);
    }

    @action init(notifications: SiteNotificationInterface[]): void {
        notifications.forEach(notification => this.add(notification));
    }

    @action
    add(notification: SiteNotificationInterface): void {
        this.siteNotifications.push(notification);
        NotificationStore.add({
            data: notification,
            force: true,
            key: `site_notification-${notification.id}`,
            timer: false,
            type: 'site_notification'
        });
    }

    @action
    deleteById(id: number): void {
        deleteObjectById(this, 'siteNotifications', id);
    }

    @action sendLog(id: number, event_type: string): Promise<Response> {
        return new Post({
            params: {
                browser_notification_id: id,
                event_type
            },
            url: '/devices/notification_log'
        })
            .execute()
            .then((response): Response => {
                if (response.ok) {
                    this.deleteById(id);
                }
                return response;
            });
    }
}
