import Tournament from './tournament';
import SimpleNotification from './simple_notification';
import CustomNotification from './custom_notification';

export default class Manager {

    static callNotification(notification, {data}) {
        let {topic} = data;
        const notificationClass = this.findByTopic(topic, data);

        if (notificationClass) {
            return new notificationClass(notification, data);
        }
    }

    static findByTopic(topic, {title, message}) {
        switch (topic) {
        case 'tournaments':
            return Tournament;
        case 'custom':
            return CustomNotification;
        default:
            if (!message && !title) return null;
            return SimpleNotification;
        }
    }
}
