import I18n from 'app/i18n';
import React, {useCallback} from 'react';
import {DigitalAmountButton} from './DigitalAmountButton';

const BET_SUM_FIFTY = 50,
    BET_SUM_FIVE = 5,
    BET_SUM_ONE_HUNDRED = 100,
    BET_SUM_TEN = 10,
    BET_SUM_TWENTY = 20,
    DECREASE_AMOUNT_ON_ONE = -1,
    INCREASE_AMOUNT_ON_ONE = 1;

export const InputAmountForm = props => {
    const {handleIncrementAmount, maxAvailableAmount, setAmount} = props;
    const handleDecrementAmountOnOne = useCallback(
        () => handleIncrementAmount(DECREASE_AMOUNT_ON_ONE),
        [handleIncrementAmount]
    );

    const handleIncrementAmountOnOne = useCallback(
        () => handleIncrementAmount(INCREASE_AMOUNT_ON_ONE),
        [handleIncrementAmount]
    );

    const handleSetMaxAmount = useCallback(
        e => setAmount(maxAvailableAmount(), e),
        [setAmount, maxAvailableAmount]
    );

    return <div className="match-details__item-form-row">
        <div className="match-details__bets">
            <div className="field">
                <div className="field-number field-number--bet">
                    <div className="field-number__decrease field-number__decrease--bet"
                        onClick={handleDecrementAmountOnOne}>
                        <svg height="11px" width="11px">
                            <use xlinkHref="#svg-plus-gor"/>
                        </svg>
                    </div>
                    <input type="number"
                        step="0.01"
                        placeholder="0"
                        value={props.amount}
                        className="field-number__input field-number__input--bet"
                        name="bet[amount]"
                        onChange={props.handleChangeAmount}/>
                    <div className="field-number__increase field-number__increase--bet"
                        onClick={handleIncrementAmountOnOne}>
                        <svg height="11px" width="11px">
                            <use xlinkHref="#svg-plus"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="match-details__bets-holder">
                {[
                    BET_SUM_FIVE,
                    BET_SUM_TEN,
                    BET_SUM_TWENTY,
                    BET_SUM_FIFTY,
                    BET_SUM_ONE_HUNDRED
                ].map(amount => <DigitalAmountButton
                    {...props}
                    key={amount}
                    setAmount={props.setAmount}
                    amount={amount}/>)}
                {<a href="#" className="match-details__bet" onClick={handleSetMaxAmount}>
                    {I18n.t('max')}
                </a>}
            </div>
        </div>
    </div>;
};
