import React, {PureComponent} from 'react';
import {ArtifactImage} from '../artifacts/image';
import {openPopupClickable} from './clickable';

export default class InventorySlot extends PureComponent {
    openPopup = () => openPopupClickable(this.props.artifact);

    render() {
        const {artifact, onClick} = this.props;
        return <div className={`hero__slot${artifact && artifact.in_market ? ' hero__slot--unavailable' : ''}`}
            onClick={onClick || this.openPopup}
        >
            <ArtifactImage
                artifact={artifact}
                showHeroSlotCountOrange
            />
        </div>;
    }
}
