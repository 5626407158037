import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import I18n from 'app/i18n';
import {betMoney, faqButton, getLinkPath, scrollToTop, isDesktop} from 'app/utils';
import {restorePlayMoney} from '../user/menu';
import {inject, observer} from 'mobx-react';
import {LoginSignUpButtons} from 'app/components/auth/log_in_sign_up_button';
import {betsBalance} from 'app/utils/balance/betsBalance';

@inject('userStore')
@observer
export default class Footer extends Component {
    render() {
        const {user} = this.props.userStore;
        const {play_money, points, active_bets_count, active_bets_sum} = user;

        return user.user_id
            ? <div className="bets-footer">
                <div className="bets-footer__balance">
                    <div className="bets-footer__title">
                        {isDesktop() && `${I18n.t('user_balance')}: `}
                        {betMoney({amount: betsBalance(user)})}
                    </div>
                    <Link
                        to={getLinkPath('/deposits')}
                        className={isDesktop() ? 'btn' : 'mobile-deposit-button'}
                        onClick={scrollToTop}
                    >{isDesktop() ? I18n.t('user.deposit') : <svg><use xlinkHref="#svg-add"/></svg>}</Link>
                </div>
                <div className="bets-footer__play-money">
                    <div className="bets-footer__title">
                        {isDesktop() ? `${I18n.t('user_playmoney')}: ` : `${I18n.t('pm')}: `}
                        {betMoney({}, play_money, false, true)}
                    </div>
                    <a href="#" className="restore-playmoney-button" onClick={restorePlayMoney}>
                        <svg><use xlinkHref="#svg-reboot"/></svg>
                    </a>
                </div>
                <div className="bets-footer__bets">
                    <NavLink
                        to={{pathname: getLinkPath('/my_bets')}}
                        state={{bet_type: 'active'}}
                        className="active-bets-button"
                        onClick={scrollToTop}>
                        <span className="icon">
                            <svg>
                                <use xlinkHref="#svg-tap_bar_history"/>
                            </svg>
                        </span>
                        <span className="column">
                            <b className="bets">{`${I18n.t('active_bets')}: ${active_bets_count}`}</b>
                            <b className="total">{`(${betMoney({amount: active_bets_sum})})`}</b>
                        </span>
                    </NavLink>
                </div>
                {isDesktop() &&
                <div className="bets-footer__points">
                    <div className="bets-footer__title">
                        <span>
                            {I18n.t('user_points')}: {betMoney({}, points, true, true)}
                        </span>
                        {faqButton('/faq/cashback-points')}
                    </div>
                </div>}
            </div>
            : <div className="bets-footer">
                <LoginSignUpButtons
                    usingOn="TABLE_BALANCE_LINE"
                    redirectUrl="/play/bets"
                    className="auth-links"
                />
            </div>;
    }
}
