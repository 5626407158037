import React, {MouseEvent, ReactElement, useCallback} from 'react';
import {activeTab} from 'app/components/chat/chat_utils';
import {ChatStore} from '../store/ChatStore';

interface ChatSelectTabProps {
    lang: string,
    chatStore?: ChatStore
}
const ChatSelectTab = (props: ChatSelectTabProps): ReactElement => {
    const {lang, chatStore} = props;

    const selectTab = useCallback((e: MouseEvent<HTMLButtonElement>): void => {
        e.stopPropagation();
        chatStore.setCurrentLanguage(lang);
    }, [lang]);

    return <button
        key={`tab-${lang}`}
        className={`act chat__lang chat__lang--${lang}${activeTab(chatStore, lang)}`}
        onClick={selectTab}>{lang}</button>;
};

export default ChatSelectTab;
