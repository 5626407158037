import GameInterface from 'app/interfaces/GameInterface';

const LESS = -1;
const GREATER = 1;

export const sortByFilters = (arr: GameInterface[]): GameInterface[] => arr.slice().sort((a, b) => {
    if (Number(a.esports) < Number(b.esports)) {
        return GREATER;
    }
    if (Number(a.esports) > Number(b.esports)) {
        return LESS;
    }
    if (a.order > b.order) {
        return GREATER;
    }
    if (a.order < b.order) {
        return LESS;
    }
    return 0;
});
