import React, {Component, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import {currentStream} from 'app/utils/currentStream';
import {createSerialRendering} from 'app/lines/createSerialRendering';
import IncubatorStubPage from 'app/components/pages/incubator/IncubatorStubPage';
import I18n from 'app/i18n';
import {IncubatorUserLinesStore} from 'app/store/IncubatorUserLinesStore';
import {UserStore} from 'app/store/UserStore';
import ShowMore from 'app/utils/ShowMore';
import MainLine from 'app/components/table/row/main_line';

interface IncubatorMyLinesProps {
    incubatorUserLinesStore?: IncubatorUserLinesStore
    userStore?: UserStore
}

@inject('incubatorUserLinesStore', 'userStore')
@observer
export default class IncubatorMyLines extends Component<IncubatorMyLinesProps> {
    componentDidMount(): void {
        const {userStore, incubatorUserLinesStore} = this.props;
        incubatorUserLinesStore.start(userStore.user.id);
    }

    componentWillUnmount(): void {
        this.props.incubatorUserLinesStore.stopListening();
    }

    loadMore = (): Promise<void> => this.props.incubatorUserLinesStore.loadData(true);

    render(): ReactNode {
        const {lines, hasMore} = this.props.incubatorUserLinesStore;
        const {userStore} = this.props;

        return <>
            {lines.length > 0
                ? <>
                    <div className="table-bets table-bets--full table-bets--compact table-incubator-lines">
                        {lines.map(bet => <MainLine
                            key={`bet-${bet.id}`}
                            bet={bet}
                            ut={bet.ut}
                            color={bet.color}
                            serial={createSerialRendering(bet)}
                            isExpress={false}
                            seo={false}
                            currentStream={currentStream(bet)}
                            showNestedBets={false}
                            incubatorPage
                            isCreator={userStore.isIncubatorLineCreator(bet.id)}/>)}
                    </div>
                    <ShowMore onClickHandler={this.loadMore} has_more={hasMore}/>
                </>
                : <IncubatorStubPage message={I18n.t('empty_created_lines')}/>}
        </>;
    }
}
