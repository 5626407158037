import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import TimeToStartTimer from 'app/utils/TimeToStartTimer';

interface Props {
    date: number
    lineClosed: boolean
}

export default class IncubatorLineTimer extends PureComponent<Props> {
    render(): ReactNode {
        const {lineClosed, date} = this.props;
        return <div className="incubator-line-timer">
            {lineClosed
                ? <p className="p-timer">{I18n.t('line_closed_for_bets')}</p>
                : <div className="div-timer">
                    <div className="div-icon-timer">
                        <svg className="svg-default">
                            <use xlinkHref="#svg-timer-icon"/>
                        </svg>
                        <TimeToStartTimer plainText showColorRed formatMinSec time_to_start={date}/>
                    </div>
                    <div className="div-span-title">
                        <span className="span-title">
                            {I18n.t('tooltip_incubator_line_closure')}
                        </span>
                    </div>
                </div>}
        </div>;
    }
}
