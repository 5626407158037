import React, {PureComponent, ReactNode} from 'react';
import {GAMER_NO_IMAGE, TEAM_NO_IMAGE} from 'app/consts';
import {getImageDomain} from 'app/utils';

interface GamerPhotoProps {
    line_gamer: GamerType
    className?: string
    default?: boolean
}

interface ImageProps {
    className: string
    src: string
}

interface ImageOnloadEventType {
    target: {
        src: string
    }
}

interface GamerType {
    photo: {
        src: string
        type_on: boolean
    }
}

export default class GamerPhoto extends PureComponent<GamerPhotoProps> {
    prepareImgProps = (image: string, gamer: GamerType): ImageProps => {
        const {className} = this.props;
        return {
            className: `${className ? className : '' } ${gamer.photo.type_on ? 'gamer_img' : 'team_img'}`,
            src: image
        };
    };

    defaultImage = (gamer: GamerType, e: ImageOnloadEventType): ImageProps => {
        const image = gamer.photo.type_on || this.props.default ? GAMER_NO_IMAGE : TEAM_NO_IMAGE;

        if (e) {
            e.target.src = image;
            return null;
        }
        return this.prepareImgProps(image, gamer);
    };

    detectImage = (gamer: GamerType, e = null): ImageProps => {
        if (!this.props.default && gamer.photo.src) {
            const src = gamer.photo.src.indexOf(getImageDomain()) > 0
                ? gamer.photo.src
                : `${getImageDomain()}/players/${gamer.photo.src}`;
            return this.prepareImgProps(src, gamer);
        }
        return this.defaultImage(gamer, e);
    };

    render(): ReactNode {
        const {line_gamer} = this.props;
        return <img {...this.detectImage(line_gamer)} onError={this.defaultImage.bind(this, line_gamer)}/>;
    }
}
