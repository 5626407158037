import React, {PureComponent, ReactNode} from 'react';
import PopupActionButtonProps from 'app/interfaces/popups/scroll_popup/PopupActionButtonProps';

export default class PopupActionButton extends PureComponent<PopupActionButtonProps> {
    className = (): string => {
        const classes = ['btn'];

        if (this.props.disabled) {
            classes.push('btn--disable');
        }

        return classes.join(' ');
    };

    render(): ReactNode {
        const {onClickHandler, disabled, text} = this.props;

        return <button
            onClick={onClickHandler}
            disabled={disabled}
            className={this.className()}
        >
            {text}
        </button>;
    }
}
