import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
// Import EmptyOffers from 'app/components/my_profile/trades/empty_offers.jsx';
import FreespinsContentItem from 'app/components/my_profile/freespins/FreespinsContentItem';

/*
 * Import TableFreespins from 'app/components/my_profile/freespins/TableFreespins';
 * import PopupFreespins from 'app/components/my_profile/freespins/PopupFreespins';
 */

export default class FreespinsContent extends PureComponent {
    render(): ReactNode {
        return <>
            <h1 className="title-h1">{I18n.t('freespins_available')}</h1>
            <div className="freespins-content">
                <FreespinsContentItem/>
            </div>
            {/* <TableFreespins/>
            <PopupFreespins/> */}
        </>;
    }
}
