import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';

interface GameCheckBoxProps {
    filter_image: string
    id: number
    name: string
    val: boolean
    switchGame: (id: number) => void
}

export default class GameCheckBox extends PureComponent<GameCheckBoxProps> {
    static defaultProps = {val: false};

    switchGame = (): void => this.props.switchGame(this.props.id);

    render(): ReactNode {
        return <div className="button-filter-game" title={this.props.name}>
            <input
                type="checkbox"
                checked={this.props.val}
                onChange={this.switchGame}
                className="field__invisible"
            />
            {this.props.id === 0
                ? <div className="button-filter-selects">{I18n.t('other')}</div>
                : <div className="button-input-game">
                    <img
                        alt={this.props.name}
                        title={this.props.name}
                        src={this.props.filter_image}/>
                </div>}
        </div>;
    }
}
