import {useNavigate} from 'react-router';

const BACK_ONE_STEP = -1;

export const goBackNavigate = (hook: boolean = true): void => {
    if (hook) {
        const navigate = useNavigate();
        navigate(BACK_ONE_STEP);
    } else {
        window.history.back();
    }
};
