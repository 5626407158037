import React, {Component, Fragment, ReactNode} from 'react';
import {scrollByHash} from 'app/utils';
import {i18n_t} from 'app/i18n';
import {inject, observer} from 'mobx-react';
import NotificationSetting from 'app/components/my_profile/NotificationSetting';
import {notificationSettingsStore} from 'app/store/global';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import NotificationsSettingsStoreInterface from 'app/interfaces/stores/NotificationsSettingsStoreInterface';

@inject('notificationSettingsStore')
@observer
export default class MenuAlertPoint extends Component<NotificationsSettingsStoreInterface> {
    state = {
        artifacts: false,
        bet: false,
        data: [],
        giveaway: false,
        line_limits: false,
        match: false,
        news: false,
        notification_names: [],
        other: false,
        submit_disabled: true,
        user_menu: false
    };

    componentDidMount(): void {
        this.saveNotificationsInState();
        scrollByHash();
    }

    saveNotificationsInState = (): void => {
        this.setState({
            notification_names: Object.keys(this.props.notificationSettingsStore.notificationSettings),
            ...this.props.notificationSettingsStore.notificationSettings
        });
    };

    handleChangeSettings = (value: boolean, stateName: string): void => {
        this.setState({
            [stateName]: value,
            submit_disabled: false
        });
    };

    saveSettings = (): void => {
        const {
            artifacts,
            bet,
            line_limits,
            match,
            news,
            other,
            user_menu,
            giveaway
        } = this.state;
        notificationSettingsStore.saveSettings({
            artifacts,
            bet,
            giveaway,
            line_limits,
            match,
            news,
            other,
            user_menu
        }).then(data => {
            if (data) {
                toastSuccess(i18n_t('settings_successfully'));
                this.setState({submit_disabled: true});
            } else {
                toastError(i18n_t('server_error_try_later'));
            }
        });
    };

    render(): ReactNode {
        const {notification_names} = this.state;

        return <Fragment>
            <div className="settings__notifications">
                <div className="title">{i18n_t('notification_settings.title')}</div>
                <form className="form form-settings form-settings__notifications">
                    <div className="form__item-list">
                        {notification_names.map((name, i) => <NotificationSetting
                            handleChangeSettings={this.handleChangeSettings}
                            id={i}
                            key={i}
                            name={name}
                            status={this.state[name]}
                        />)}
                    </div>

                    <div className="form__row form__row__actions">
                        <label
                            id="save_settings_button"
                            className={
                                `button btn form__submit-btn ${this.state.submit_disabled ? 'btn--disable' : ''}`
                            }
                            onClick={this.state.submit_disabled ? null : this.saveSettings}
                        >
                            {i18n_t('save')}
                        </label>
                    </div>
                </form>
            </div>
        </Fragment>;
    }
}
