import React, {PureComponent} from 'react';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {hideLastPopup} from 'app/utils/modals/ModalsService';
import {artifactName} from 'app/components/my_profile/artifacts/utils';
import I18n from 'app/i18n';

export default class InventoryCountEditPopup extends PureComponent {
    // eslint-disable-next-line no-undefined
    state = {itemsCount: undefined};

    changeItemsCountHandler = e => this.setState({itemsCount: e.target.value});

    confirm = e => {
        e && e.preventDefault();

        const {confirm} = this.props;
        let {itemsCount} = this.state;

        itemsCount = Number(itemsCount);

        hideLastPopup();
        if (itemsCount) {
            confirm(this.props, itemsCount);
        }
    };

    get quantityOfItemsText() {
        return I18n.t('quantity_of_items');
    }

    get youHaveItemsText() {
        const {
            total_inventory_items, total_sending_prohibited_items,
            quantity, opened_my_inventory, partner_user_name
        } = this.props;

        if (opened_my_inventory) {
            return I18n.t(
                'you_have_available_items',
                {count: quantity || total_inventory_items - total_sending_prohibited_items}
            );
        }

        return I18n.t(
            'user_has_items',
            {
                count: quantity || total_inventory_items,
                user: partner_user_name
            }
        );
    }

    render() {
        const {itemsCount} = this.state;

        return <PopupDialog
            id={this.props.popupId}
            setFocus
        >
            <div className="popup__content">
                <h2 className="popup__header">
                    {artifactName(this.props)}
                </h2>
                <div className="inventory-count-edit__popup">
                    <form className="inventory-count-edit__popup--form">
                        <div className="inventory-count-edit__popup--form-content">
                            <div className="inventory-count-edit__popup--form__item">
                                <div className="inventory-count-edit__popup--form__item-wrap">
                                    <label className="label-fee">
                                        <span>{this.quantityOfItemsText}</span>
                                    </label>
                                    <div className="field">
                                        <div className="field__wrap">
                                            <input
                                                type="text"
                                                value={itemsCount}
                                                className="field__input"
                                                onChange={this.changeItemsCountHandler}
                                            />
                                            <div className="field__icon">
                                                <svg height="12px" width="12px">
                                                    <use xlinkHref="#svg-inventory-count"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <label className="inventory-count-edit__popup--form-hint">
                                {this.youHaveItemsText}
                            </label>
                        </div>
                        <div className="inventory-count-edit__popup--actions">
                            <button
                                onClick={this.confirm}
                                className="btn btn--big-on-mobile"
                            >
                                {I18n.t('confirm')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </PopupDialog>;
    }
}
