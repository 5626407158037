import React, {PureComponent, ReactNode} from 'react';
import {betMoney} from 'app/utils';

interface UserMenuBalanceProps {
    full_balance: string
}

export default class UserMenuBalance extends PureComponent<UserMenuBalanceProps> {
    render(): ReactNode {
        return <div className="user__balance">
            <span className="user__balance--value">{betMoney({amount: this.props.full_balance})}</span>
            <svg height="12px" width="12px">
                <use xlinkHref="#svg-add"/>
            </svg>
        </div>;
    }
}
