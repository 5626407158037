import React, {ReactNode} from 'react';
import PokerBoard from 'app/components/poker/poker_board/PokerBoard';
import PokerPot from 'app/components/poker/poker_pot/PokerPot';
import PokerPlayers from 'app/components/poker/poker_players/PokerPlayers';
import PokerControllerPanel from 'app/components/poker/poker_controller_panel/PokerControllerPanel';
import {inject, observer} from 'mobx-react';
import {PokerStore} from 'app/store/PokerStore';

interface Props {
    pokerStore?: PokerStore
}

@inject('pokerStore')
@observer
export default class PokerRoom extends React.Component<Props> {
    render(): ReactNode {
        const {currentTable: {cards, pot, players, dealer_place, max_players, count_players, action_index}} = this.props.pokerStore;

        return <div className="poker-room">
            <div className="poker-table">
                <PokerPot pot={pot}/>
                <PokerBoard cards={cards}/>
                <PokerPlayers
                    players={players}
                    dealer_place={dealer_place}
                    max_players={max_players}
                    count_players={count_players}
                    action_index={action_index}
                />
            </div>
            <PokerControllerPanel/>
        </div>;
    }
}
