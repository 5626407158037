import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import $ from 'jquery';
import {streamStore} from 'app/store/global';
import doubleArrDownSvg from 'images/svg_icons/double-arr-down';

export default class ButtonMoreLess extends PureComponent {
    openedHandler = e => {
        streamStore.openStream(!this.props.opened);
        $(e.currentTarget).toggleClass('opener-active');
    };

    render() {
        const {opened} = this.props;
        return <span onClick={this.openedHandler}
            className="game-list__more cursor-pointer"
            style={{display: 'flex'}}>{I18n.t(`stream.${[opened ? 'less' : 'more']}`)}
            <button className={`act stream-list__more-button js-opener-active ${opened ? 'opener-active' : ''}`}>
                <img src={doubleArrDownSvg} className="opener-icon" height="12px" width="12px"/>
            </button>
        </span>;
    }
}
