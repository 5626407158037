import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {IncubatorCreateLineController} from 'app/controllers/IncubatorCreateLineController';
import {inject, observer} from 'mobx-react';
import {GAMER_2} from 'app/utils/Consts';

interface Props {
    controller?: IncubatorCreateLineController
}

@inject('controller')
@observer
export default class IncubatorLineRightWinner extends Component<Props> {
    get controller(): IncubatorCreateLineController {
        return this.props.controller;
    }

    handleChangeWinner2 = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.controller.onChangeWinner(e.target.value);
    };

    handleClickWinner2 = (e: React.MouseEvent<HTMLInputElement>): void => {
        const {controller} = this.props;
        const {gamer_2_score} = controller.incubatorGame.attributes;
        const min_win_score = 1;

        this.controller.onClickWinner2(e.target as HTMLInputElement);
        if (Number(gamer_2_score) < min_win_score) {
            this.controller.onChangeScore1('0');
            this.controller.onChangeScore2(min_win_score.toString());
        }
    };

    handleChangeScore2 = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.controller.onChangeScore2(e.target.value);
    };

    render(): ReactNode {
        const {controller} = this.props;
        const {incubatorGame} = controller;
        const {gamer_2_score, winner} = incubatorGame.attributes;

        return <>
            <div className="input-line w60">
                <label className="field__label">{I18n.t('tooltip_incubator_score')}</label>
                <input
                    className="field__input bold field-number__input"
                    value={gamer_2_score}
                    name="line[gamer_2_score]"
                    onChange={this.handleChangeScore2}
                />
            </div>
            <div className="radio">
                <input
                    id="win2"
                    type="radio"
                    name="line[winner]"
                    value="2"
                    onChange={this.handleChangeWinner2}
                    onClick={this.handleClickWinner2}
                    checked={winner === GAMER_2}
                />
                <label className="icon-checkbox" htmlFor="win2"/>
                <label
                    className="field__label"
                    htmlFor="win2"
                >{I18n.t('tooltip_incubator_win')} 2</label>
            </div>
        </>;
    }
}
