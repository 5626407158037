import $ from 'jquery';
import React from 'react';
import I18n from 'app/i18n';
import {hideLastPopup, showPopup} from './ModalsService';
import {ModalAlert} from './ModalAlert';
import {ModalConfirm} from './ModalConfirm';
import {ESC_KEY_CODE} from 'app/utils/Consts';

const BODY = 'body';

export const preventEscPopupClose = () => $(BODY).data('no-close-popup', true);
export const enableEscPopupClose = () => $(BODY).data('no-close-popup', false);

$(BODY).on('keyup', e => {
    if (e.key === ESC_KEY_CODE && !$('body').data('no-close-popup')) {
        hideLastPopup();
    }
});

// eslint-disable-next-line max-params
export const modalConfirm = (html, okClick, cancelClick, modalConfirmProps) => {
    showPopup('modal-confirm', props => <ModalConfirm
        okClick={okClick}
        cancelClick={cancelClick}
        popupId={props.popupId}
        {...modalConfirmProps}
    >
        {html}
    </ModalConfirm>);
};

export const modalAlert = (html, modalAlertProps = {}) => {
    showPopup('modal-alert', props => <ModalAlert popupId={props.popupId} {...modalAlertProps}>{html}</ModalAlert>);
};

export const showTerms = (agreeHandler, disagreeHandler) => {
    showPopup('terms-popup', props => <ModalConfirm
        okClick={agreeHandler}
        cancelClick={disagreeHandler}
        okText={I18n.t('agree')}
        cancelText={I18n.t('disagree')}
        popupId={props.popupId}
        hideCloseButton
        contentScroll
    >
        {{
            element: <div className="terms-content" dangerouslySetInnerHTML={{__html: window.SIGN_UP_USER.terms}}/>,
            react: true
        }}
    </ModalConfirm>);
};

export const showTermsCommon = okButtonCallback => {
    const handleCommonTermClick = e => {
        e.preventDefault();
        showTermsCommon();
    };
    showTerms(() => {
        if (okButtonCallback) {
            okButtonCallback();
        }
        $.ajax({
            type: 'POST',
            url: '/user/terms'
        });
        window.TERMS_FLAG = false;
    }, () => modalAlert(
        <span>
            {I18n.t('decline_terms_and_conditions_message_part_1')} <a
                href="#"
                onClick={handleCommonTermClick}
            >
                {I18n.t('decline_terms_and_conditions_link')}
            </a>
            {I18n.t('decline_terms_and_conditions_message_part_2')}
        </span>,
        {isReactElement: true}
    ));
};
