import React, {PureComponent, ReactNode} from 'react';
import LineInterface from 'app/interfaces/LineInterface';
import TimerComponentInterface from 'app/interfaces/TimerComponentInterface';
import {getValues} from 'app/utils/timer/getValues';
import {simpleFormat, DEFAULT_MESSAGE} from 'app/utils/timer/simpleFormat';
import {getCountDown} from 'app/utils/timer/getCountDown';

interface BaseTimerProps {
    express: boolean
    selectedLine: LineInterface
}

const ONE_SECOND = 1000;

export default class BetSlipTimer extends PureComponent<BaseTimerProps, TimerComponentInterface> {
    constructor(props: BaseTimerProps) {
        super(props);

        const {express, selectedLine: {date, live}} = this.props;
        this.state = {
            countDown: express || live ? 0 : getCountDown(date),
            timer: express || live
                ? null
                : setInterval(() => {
                    this.setState({countDown: getCountDown(date)});
                }, ONE_SECOND)
        };
    }

    componentDidUpdate(prevProps: BaseTimerProps): void {
        if (prevProps.express !== this.props.express || prevProps.selectedLine !== this.props.selectedLine) {
            const {express, selectedLine: {date, live}} = this.props;
            clearInterval(this.state.timer);
            this.setState({countDown: express || live ? 0 : getCountDown(date),
                timer: express || live
                    ? null
                    : setInterval(() => {
                        this.setState({countDown: getCountDown(date)});
                    }, ONE_SECOND)});
        }
    }

    componentWillUnmount = (): void => clearInterval(this.state.timer);

    getMessage = (): string => {
        if (this.props.express) {
            return '';
        }

        if (this.props.selectedLine.live) {
            return 'Live';
        }
        return DEFAULT_MESSAGE;
    };

    render(): ReactNode {
        return <span className="bet-slip-timer">
            {simpleFormat(getValues(this.state.countDown), this.getMessage())}
        </span>;
    }
}
