import React, {MouseEvent, PureComponent, ReactNode} from 'react';

interface BetSlipLatestBetsProps {
    onClick: (e: MouseEvent<HTMLButtonElement>) => void
    title: string
    disabled?: boolean
}

export default class BetSlipLatestBet extends PureComponent<BetSlipLatestBetsProps> {
    render(): ReactNode {
        const {title, onClick, disabled} = this.props;
        return <button className="button-latest-bet" onClick={onClick} disabled={disabled}>
            {title}
        </button>;
    }
}
