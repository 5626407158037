import I18n from 'app/i18n'
import BaseNotification from './index';
import {goToRoute, getPath} from 'app/utils/link_helper';

export default class Tournament extends BaseNotification {

    onClick(e) {
        e.preventDefault();
        super.onClick(e);
        if (this.data.url) {
            goToRoute(getPath(this.data.url));
        }
    }

    params({url, image, topic}) {
        return {
            icon: image,
            body: I18n.t('tournament_has_started_label'),
            data: {url, topic}
        };
    }
}
