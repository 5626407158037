import {addGaEvent} from 'app/ga_methods';
import I18n from 'app/i18n';

export default class BaseNotification {
    constructor(notification, data) {
        this.onShow = this.onShow.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onClose = this.onClose.bind(this);
        this.data = data;
        if (notification === null) {
            return this;
        }
        this.initNotify(notification, data);
    }

    initNotify(notification, data) {
        const n = notification(this.title(data), this.params(data));

        if (this.isServiceWorkerNotification(notification)) {
            this.serviceWorkerOnShow();
        } else {
            n.onclick = this.onClick;
            n.onshow = this.onShow;
            n.onclose = this.onClose;
        }
    }

    onShow() {
        addGaEvent('Browser Notification', `show ${this.data.topic}`);
    }

    onClick(e) {
        e.target.close();
        addGaEvent('Browser Notification', `click ${this.data.topic}`);
    }

    onClose() {
        addGaEvent('Browser Notification', `close ${this.data.topic}`);
    }

    serviceWorkerOnShow() {
        addGaEvent('Service Worker Notification', `show ${this.data.topic}`);
    }

    serviceWorkerOnClick(e) {
        addGaEvent('Service Worker Notification', `click ${this.data.topic}`);
        const {clients} = e.target;
        e.waitUntil(clients.matchAll({
            includeUncontrolled: true,
            type: 'window'
        }).then(clientList => {
            const {url} = e.notification.data;
            const focusedClient = clientList.find(client => {
                if (client.url === url && client.focus) {
                    client.focus();
                    return true;
                }
            });

            if (focusedClient) {
                return;
            }
            clients.openWindow(url);
        }));
    }

    serviceWorkerOnClose() {
        addGaEvent('Service Worker Notification', `close ${this.data.topic}`);
    }

    tag({id, topic}) {
        return `${this.constructor.name}, topic: ${topic}, id: ${id}`;
    }

    title({title}) {
        return this.tryLangContent(title);
    }

    tryLangContent(data) {
        const content = JSON.parse(data);

        if (typeof content === 'string') {
            return content;
        }
        if (content[I18n.locale]) {
            return content[I18n.locale];
        }
        return content.en;
    }

    params({icon, message, url, url_browser, topic, require_interaction, id}) {
        return {
            body: this.tryLangContent(message),
            data: {
                id,
                topic,
                url: url_browser
                    ? this.tryLangContent(url_browser)
                    : this.tryLangContent(url)
            },
            icon,
            requireInteraction: require_interaction === 'true'
        };
    }

    isServiceWorkerNotification() {
        return typeof ServiceWorkerGlobalScope !== 'undefined';
    }
}
