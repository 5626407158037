import LineInterface from 'app/interfaces/LineInterface';
import React from 'react';
import {i18n_t} from 'app/i18n';
import {SimpleGamerDescription} from 'app/utils/gamer_descriptions_helper';

const NewBet = (data: LineInterface): React.ReactNode => <div>
    {i18n_t('new_line_added')} {'\u00A0'}
    <SimpleGamerDescription {...data.gamer_1} game={data.game}/>
    {'\u00A0'}VS{'\u00A0'}
    <SimpleGamerDescription {...data.gamer_2} game={data.game} withoutGameImg/>
    {i18n_t('multiple_bets_limit_have_been_enabled_tap')}
</div>;

export default NewBet;
