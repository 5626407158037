import BaseValidation from 'validations/BaseValidation';

interface OptionsInterface {
    limit: number
}

export default class LessOrEqualValidation extends BaseValidation<number, OptionsInterface> {
    execute(): boolean {
        return this.attribute <= this.options.limit;
    }
}
