import React, {Component, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import {IncubatorUserBetInterface} from 'app/interfaces/IncubatorUserBetInterface';
import {CurrentTimeStore} from 'stores/current_time_store';
import {betMoney, betMoneyCurrency, fixed2, fixed3, isDesktop} from 'app/utils';
import {GAMER_1, GAMER_2} from 'app/utils/Consts';

interface Props {
    bet: IncubatorUserBetInterface
    currentTimeStore?: CurrentTimeStore
    positionTable: number
}

@inject('currentTimeStore')
@observer
export default class IncubatorUserBet extends Component<Props> {
    timeBeforeAccepting(): string {
        const {currentTimeStore, bet} = this.props;
        const {will_be_accepted_at} = bet;
        const timer = will_be_accepted_at - currentTimeStore.t;

        if (timer < 0) {
            return null;
        }

        return timer.toString();
    }

    render(): ReactNode {
        const {bet, positionTable} = this.props;

        return <>
            {isDesktop()
                ? <tr>
                    {positionTable === GAMER_1 && <td/>}
                    <td>{this.timeBeforeAccepting()}</td>
                    <td>{betMoney(bet, null, false, false)}</td>
                    <td>{fixed3(bet.coef)}</td>
                    <td>{betMoneyCurrency(false, fixed2(bet.winning_money))}</td>
                    {positionTable === GAMER_2 && <td/>}
                </tr>
                : <tr>
                    <td>{this.timeBeforeAccepting()}</td>
                    <td>{betMoney(bet, null, false, false)}</td>
                    <td>{fixed3(bet.coef)}</td>
                </tr>}
        </>;
    }
}
