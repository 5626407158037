import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {getLinkPath} from 'app/utils/link_helper';

export default class OfferTradePopup extends PureComponent {
    state = {url: ''};

    handleUrl = e => {
        this.setState({url: e.target.value});
    };

    handleSubmit = e => {
        e.preventDefault();
        const {url} = this.state;

        try {
            const urlComponent = new URL(url);
            location.href = getLinkPath(urlComponent.pathname);
        } catch (e) {}
    };

    isValidUrl() {
        try {
            const url = new URL(this.state.url);
            return true;
        } catch (e) {
            return false;
        }
    }

    render() {
        const {url} = this.state;
        return <PopupDialog id={this.props.popupId}>
            <div className="popup__content">
                <h2 className="popup__header">{I18n.t('offer_trade')}</h2>
                <div className="popup-form">
                    <form onSubmit={this.handleSubmit}>
                        <div className="popup-form__row">
                            <label htmlFor="field-url">{I18n.t('paste_here_a_trade_link')}</label>
                            <div className="field__wrap">
                                <input type="text"
                                    id="field-url"
                                    className={`field__input field__input--${this.isValidUrl()
                                        ? 'exist'
                                        : 'inexist'}-state`}
                                    onChange={this.handleUrl}
                                    value={url}
                                />
                                <span className="field__state">
                                    <svg height="12px" width="12px">
                                        <use xlinkHref={this.isValidUrl() ? '#svg-check' : '#svg-close'}/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div className="popup-form__submit-row">
                            <button type="submit"
                                className="btn btn--middle btn--big-on-mobile"
                            >
                                {I18n.t('continue')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </PopupDialog>;
    }
}
