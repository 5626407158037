import React from 'react';
import HeroEffect from './HeroEffect';

interface BaseProps {
    title: string,
    effects: {
        description: string,
        id: number
    }[]
}

export default class Effects extends React.PureComponent<BaseProps> {
    render(): React.ReactNode {
        const {title, effects} = this.props;

        if (effects.length === 0) {
            return null;
        }

        return <div className="hero__artefact--list--wrap">
            <div className="hero__artefact--list__title hero__subtitle">{title}</div>
            <div className="hero__artefact--list">
                {effects.map(effect => <HeroEffect
                    key={effect.description + effect.id}
                    {...effect}
                />)}
            </div>
        </div>;
    }
}
