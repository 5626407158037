import $ from 'jquery';
import React from 'react';
import I18n from 'app/i18n';
import {modalAlert} from 'app/utils/modals/popups';
import {Auth} from './auth_popup';
import Captcha from './captcha';
import SmartLoader from 'app/components/reusable/SmartLoader';
import {toastError} from 'app/components/toasts/liteToast';

export default class ForgotPassword extends React.PureComponent {
    state = {error: null, success: null, submitting: false, captcha_id: undefined};

    captchaError() {
        return this.state.error && this.state.captcha;
    }

    errorMessage(error) {
        if (typeof error.responseJSON.errors === 'object') {
            return Object.values(JSON.parse(error.responseText).errors)[0][0];
        }
        return error.responseJSON.errors;
    }

    remindPassword = e => {
        e.preventDefault();

        if (this.state.submitting) {
            return;
        }

        this.setState({error: null, submitting: true});

        $.ajax({
            url: '/egb_users/password',
            type: 'POST',
            data: $(this.formRef).serialize(),
            dataType: 'JSON',
            statusCode: {
                422: error => {
                    toastError(this.errorMessage(error));
                    this.setState({
                        error: this.errorMessage(error),
                        captcha: error.responseJSON.captcha,
                        submitting: false
                    });
                },
                500: () => {
                    toastError(I18n.t('server_error_try_later'));
                    this.setState({error: I18n.t('server_error_try_later'), submitting: false});
                }
            },
            success: response => {
                if (response.success) {
                    this.setState({success: response.text});
                } else if (response.error) {
                    toastError(response.text);
                    this.setState({error: response.text});
                } else {
                    modalAlert(response.text);
                }
                this.setState({submitting: false});
            }
        });
    };

    setFormRef = x => {
        this.formRef = x;
    };

    render() {
        const {success, error, submitting} = this.state;
        return success
            ? <div className="popup-form__row">{success}</div>
            : <form ref={this.setFormRef}
                className={`popup-form${error ? ' popup-form--error' : ''}`}
                onKeyUp={e => Auth.clearFiledError(this, e)} onSubmit={this.remindPassword}>
                <div className="popup-form__row">
                    <a className="popup-form__back" href="#" onClick={this.props.back} style={{marginLeft: 0}}>
                        <svg height="8px" width="5px">
                            <use xlinkHref="#svg-back"/>
                        </svg>
                        <span>{I18n.t('button_back_label')}</span>
                    </a>
                </div>
                <div className="popup-form__row">{I18n.t('forgot_password_title')}</div>
                <div className="popup-form__error-hint">{this.state.error}</div>
                <div className="popup-form__row">
                    <label htmlFor="field-forgot-email">{I18n.t('email_label')}</label>
                    <div className={`field${error ? ' field-error' : ''}`}>
                        <input
                            type="email"
                            className="field__input"
                            id="field-forgot-email"
                            name="egb_user[email]" />
                    </div>
                </div>
                <div className={`popup-form__row captcha__row${this.captchaError() ? ' field-error' : ''}`}>
                    <div className={this.captchaError() ? ' field__input' : ''}>
                        <Captcha reset={error} />
                    </div>
                </div>
                {submitting
                    ? <SmartLoader loaderClassName="button-adaptive login-loader"/>
                    : <div className="popup-form__submit-row">
                        <input
                            className="button-adaptive"
                            disabled={submitting}
                            type="submit"
                            value={I18n.t('remind_password')} />
                    </div>
                }
            </form>;
    }
}
