import React, {ReactNode} from 'react';
import PokerButtonTakePlace from 'app/components/poker/poker_players/poker_take_place/PokerButtonTakePlace';
import PokerDealer from 'app/components/poker/poker_players/poker_dealer/PokerDealer';
import PlayerInterface from 'app/interfaces/poker/PlayerInterface';
import {selectCard} from 'app/utils/poker/selectCard';
import {betMoney} from 'app/utils';
import {observer} from 'mobx-react';
import PokerCard from 'app/components/poker/poker_cards/PokerCard';

interface Props {
    position: number
    isBigBlind: boolean
    active?: boolean
    player?: PlayerInterface
    onSitIn?: () => void
    satOut?: boolean
}

const HALF_OF_PLAYERS = 3;

@observer
export default class PokerPlace extends React.Component<Props> {
    render(): ReactNode {
        const {position, player, isBigBlind, onSitIn, active, satOut} = this.props;
        const bet_position = position <= HALF_OF_PLAYERS ? 'bet-bottom' : 'bet-top';
        const activeClassName = active ? ' active' : '';
        const depositAmount = betMoney({amount: player?.chips || 0});

        return <div className={`poker-player place-${position} ${bet_position}${activeClassName}`}>
            {onSitIn && <PokerButtonTakePlace onSitIn={onSitIn}/>}
            {player && <>
                <div className="player-cards">
                    {player.cards
                        ? <>
                            <div className="player-card-first animate">
                                <div className="player-card-inner">
                                    <span className="player-card-face-first"></span>
                                    {selectCard(player.cards[0])}
                                </div>
                            </div>
                            <div className="player-card-second animate">
                                <div className="player-card-inner">
                                    <span className="player-card-face-second"></span>
                                    {selectCard(player.cards[1])}
                                </div>
                            </div>
                        </>
                        : <>
                            <div className="player-card-first">
                                <div className="player-card-inner">
                                    <span className="player-card-face-first"></span>
                                    <PokerCard suit={'hearts'} sign={'K'}/>
                                </div>
                            </div>
                            <div className="player-card-second">
                                <div className="player-card-inner">
                                    <span className="player-card-face-second"></span>
                                    <PokerCard suit={'clubs'} sign={'K'}/>
                                </div>
                            </div>
                        </>}
                </div>
                <div className={`poker-player-about my-player${satOut ? ' sitout' : ''}`}>
                    <span className="name" title={player.name}>{player.name}</span>
                    <span className="deposit">{depositAmount}</span>
                </div>
                {player.bet && player.bet > 0 && <div className="poker-player-bet">
                    <span className="amount">{betMoney({amount: player.bet})}</span>
                </div>}
                {isBigBlind && <PokerDealer/>}
                {satOut && <span className="poker-player-sitout">
                    <svg className="svg-default">
                        <use xlinkHref="#svg-sleep-icon"/>
                    </svg>
                </span>}
                {player.winner && <span className="poker-player-win">
                    <svg className="svg-default">
                        <use xlinkHref="#svg-win-icon"/>
                    </svg>
                </span>}
            </>}
        </div>;
    }
}
