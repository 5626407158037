import React, {PureComponent, ReactNode} from 'react';
import SocialLink from 'app/components/social_link';

export default class SocialsList extends PureComponent {
    render(): ReactNode {
        return <div className="socials">
            <div className="socials__list">
                <SocialLink link="https://t.me/joinchat/AAAAAFFxeFHPEyTGoQYXMQ" svgId="#svg-telegram"/>
                <SocialLink link="https://www.facebook.com/EgamingBets" svgId="#svg-facebook"/>
                <SocialLink link="https://twitter.com/EgamingBets" svgId="#svg-twitter"/>
                <SocialLink link="https://vk.com/egamingbets" svgId="#svg-vk"/>
                <SocialLink link="https://www.youtube.com/user/egamingbets" svgId="#svg-youtube"/>
                <SocialLink link="https://www.instagram.com/egb_esports" svgId="#svg-instagram"/>
            </div>
        </div>;
    }
}
