import React from 'react';
import DepositSystem from '../form/DepositSystem';
import DepositSystemForm from '../form/DepositSystemForm';
import DepositForm from '../form/DepositForm';
import I18n from 'app/i18n';
import pick from 'lodash/pick';

export const ErrorFieldWrapper = props => <div
    className={props.errors && props.errors[props.field] ? 'field field-error' : 'field'}
>
    <div className="field__wrap">
        {props.children}
    </div>
</div>;

export const ErrorHint = props => <div className="field__error-hint">{props.errors && props.errors[props.field]}</div>;

export default class PayzenBaseClass extends DepositForm {
    constructor(props) {
        super(props);
        this.state = {
            invoice_detail_attributes: {}
        };
    }

    systemForm = React.createRef();

    requiredFields = () => [];

    handleChangeInput = (key, e) => {
        const {invoice_detail_attributes} = this.state;
        this.setState({
            invoice_detail_attributes: {
                ...invoice_detail_attributes,
                [key]: e.target.value
            }
        });
    };

    cleanError = field => {
        const {errors} = this.props.depositsStore;
        this.props.depositsStore.resetErrors({...errors, [`invoice_detail.${field}`]: false});
    };

    handleSuccessResponse = (response, intervalTimerId) => {
        clearInterval(intervalTimerId);
        this.setState({
            isHiddenForRedirect: true,
            parameters: response.parameters,
            url: response.url
        }, () => this.systemForm.current.submit());
    };

    renderInputs() {
        return this.requiredFields().map(field => {
            const funcProps = {...pick(this.state, ['errors']),
                field: `invoice_detail.${field}`};
            return <div key={field} className="form__row">
                <div className="form__row-item">
                    <label className="form__row-label" key={field}>{I18n.t(`deposit_fields.${field}`)}</label>
                    <ErrorFieldWrapper {...funcProps}>
                        <input
                            className="field__input" value={this.state.invoice_detail_attributes[field]}
                            onKeyUp={this.cleanError.bind(this, field)}
                            onChange={this.handleChangeInput.bind(this, field)}
                        />
                        <ErrorHint {...funcProps}/>
                    </ErrorFieldWrapper>
                </div>
            </div>;
        });
    }

    submitHandler = event => {
        if (!this.props.url) {
            event.preventDefault();
        }

        const {route_path} = this.props.depositsStore.currentPaymentSystem;
        this.submitRedirect(
            `/deposits/${route_path}`,
            `/deposits/${route_path}/prepared`,
            pick(this.state, ['invoice_detail_attributes', 'amount'])
        );
    };

    render() {
        const {currentPaymentSystem: {amount_input}} = this.props.depositsStore;
        return <DepositSystem>
            <DepositSystemForm
                {...this.state}
                noAmount={!amount_input}
                formRef={this.systemForm}
                submitHandler={this.submitHandler}
            >
                {this.renderInputs()}
                {this.state.parameters && this.state.parameters.map(params => <input key={JSON.stringify(params)}
                    type="hidden"
                    {...params}/>)}
            </DepositSystemForm>
        </DepositSystem>;
    }
}
