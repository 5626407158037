import React, {PureComponent} from 'react';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {translatedName} from 'app/utils/translations_helper';
import PopupFooter from './popup/popup_footer';
import InventoryPopupContent from './InventoryPopupContent';
import OpenedLootboxPopupContent from './OpenedLootboxPopupContent';
import Conversion from './artifact_popup/Conversion';
import {Provider} from 'mobx-react';
import {modalsStore, promoActionStore} from 'app/store/global';
import {onCloseItemIntentory} from 'app/utils/CloseInventoryPopup';
import NotificationStore from 'app/store/notifications';

export default class ArtifactPopup extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            animationOpenLootboxInvEnd: !(Object.keys(this.props.options).length === 0),
            disassembling: false,
            disassemblingError: false,
            openingLootbox: false,
            responseJSON: this.props.options
        };
    }

    isLootbox = () => {
        const {artifact} = this.props;
        const {item_type} = artifact;

        return ['lootbox', 'shard_lootbox'].includes(item_type);
    };

    isArtifact = () => !this.isLootbox();

    close = () => {
        modalsStore.hideModal(this.props.popupId);
    };

    onCloseInventoryPopup = () => {
        const {animationOpenLootboxInvEnd, openingLootbox} = this.state;

        if (!animationOpenLootboxInvEnd && openingLootbox) {
            NotificationStore.add({
                type: 'leave_lootbox_page'
            });
        }
    };

    onClose = () => onCloseItemIntentory();

    publicView = () => {
        const {artifact: {public_view}} = this.props;
        return public_view;
    };

    handleAnimationEnded = () => {
        this.setState({animationOpenLootboxInvEnd: true});
    };

    propsUpdateState = state => this.setState(state);

    render() {
        const {artifact, useNow, popupId} = this.props;
        const {openingLootbox, animationOpenLootboxInvEnd,
            responseJSON:
                {extra_data, steam_trade, steam_skin, win_artifact}} = this.state;

        const commonProperties = {
            ...artifact,
            animationOpenLootboxInvEnd,
            extra_data,
            handleAnimationEnded: this.handleAnimationEnded,
            handleModalClose: this.handleModalClose,
            isArtifact: this.isArtifact(),
            isLootbox: this.isLootbox(),
            onCloseInventoryPopup: this.onCloseInventoryPopup,
            openingLootbox,
            steam_skin,
            steam_trade,
            useNow,
            win_artifact
        };

        return <PopupDialog id={popupId} onClose={this.onClose}>
            <div className="popup__content">
                <h2 className="popup__header">
                    {!animationOpenLootboxInvEnd && translatedName(artifact)}
                </h2>
                {animationOpenLootboxInvEnd
                    ? <OpenedLootboxPopupContent
                        commonProperties={commonProperties}
                    />
                    : <InventoryPopupContent
                        commonProperties={commonProperties}
                        artifact={artifact}
                        close={this.close}
                        propsUpdateState={this.propsUpdateState}
                    />}
            </div>
            <PopupFooter
                {...artifact}
                publicView={this.publicView}
                close={this.close}
            />
            {!this.publicView() && <Provider promoActionStore={promoActionStore}>
                <Conversion
                    artifact={{
                        id: artifact.id,
                        item_type: artifact.item_type,
                        langs: artifact.langs,
                        name: artifact.name
                    }}
                    close={this.close}
                />
            </Provider>}
        </PopupDialog>;
    }
}
