import React, {Fragment, PureComponent} from 'react';
import Image from 'app/helpers/Image';

const FieldName = ({value}) => {
    switch (value) {
    case 'dota':
        return <Fragment>DOTA 2 <br/> SKIN</Fragment>;
    case 'cs':
        return <Fragment>CS <br/> SKIN</Fragment>;
    case 'artifact':
        return <Fragment>EGB <br/> SKIN</Fragment>;
    case 'money':
        return <Fragment>Money</Fragment>;
    default:
        return null;
    }
};

export default class ChooseField extends PureComponent {
    handleChoosing = e => {
        e && e.preventDefault();

        const {chooseSkin, value} = this.props;
        return chooseSkin(value, e);
    };

    render() {
        const {skin_gift_images, value} = this.props;
        return (
            <div className="field__choose" id={`skin-${this.props.value}`}>
                <input
                    type="radio"
                    name="game-choice"
                    className="field__invisible"
                    onClick={this.handleChoosing}
                />
                <div className="field__choose-item">
                    <div className="gift-choose__content">
                        <div className="gift-choose__icon--wrap">
                            <Image
                                image={{
                                    name: value,
                                    url: skin_gift_images[value]
                                }}
                                className="gift-choose__icon"
                            />
                        </div>
                        <div className="gift-choose__name">
                            <FieldName {...this.props}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
