import {UserStore} from 'app/store/UserStore';
import SingleRecordStore from 'stores/SingleRecordStore';
import {IncubatorGameAttributes, IncubatorGameModel} from 'app/models/IncubatorGameModel';
import {action, makeObservable, override} from 'mobx';

export class IncubatorLineStore extends SingleRecordStore<IncubatorGameAttributes> {
    channel = (): string => null;

    feedUrl = (): null => null;

    model: IncubatorGameModel;

    constructor(userStore: UserStore, attributes: IncubatorGameAttributes, model: IncubatorGameModel) {
        super();
        this.setCollection(attributes);
        this.model = model;
        makeObservable(this);
    }

    @action
    setNewLine(lineId: number, userId: number): void {
        this.startListen(lineId, userId);
    }

    startListen(lineId: number, userId: number): void {
        this.channel = (): string => `$incubator-line-${lineId}-${userId}`;
        this.listen();
    }

    @override
    updateElementCollection(item: IncubatorGameAttributes): void {
        this.setCollection(item);
        this.model.setAttribute(item);
    }
}
