import React, {PureComponent, ReactNode} from 'react';
import {BR_VERIFIED} from 'app/consts';
import I18n from 'app/i18n';

export default class BookmakerRating extends PureComponent {
    render(): ReactNode {
        if (I18n.locale !== 'ru') {
            return null;
        }
        return <>
            <div className="bookmaker-stamp">
                <img src={BR_VERIFIED} alt="Рейтинг букмекеров" title="Проверено Рейтингом Букмекеров"/>
            </div>
            <div className="bookmaker-rating">
                <span className="bookmaker-rating__logo">
                    <svg className="svg-default">
                        <use xlinkHref="#svg-raiting"/>
                    </svg>
                </span>
                <span className="bookmaker-rating__rating">Оценка: 5</span>
            </div>
        </>;
    }
}
