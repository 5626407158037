import React from 'react';
import {giftOpening} from './gift';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import {openRegistration} from '../auth/auth';
import {userSignedIn} from 'app/utils';

interface Props {
    isStarted: () => boolean
    imagePropName: string
}

export default class CheersButton extends React.PureComponent<Props> {
    onClick = (): void => userSignedIn()
        ? giftOpening(upperFirst(camelCase(this.props.imagePropName)))
        : openRegistration('PROMO_RIGHT_BLOCK');

    render(): React.ReactNode {
        return this.props.isStarted() && <div className="promo-aside__cheers beer-aside__cheers">
            <a className="btn" onClick={this.onClick}>Cheers</a>
        </div>;
    }
}
