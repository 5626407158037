import LazyImage from 'app/helpers/LazyImage';
import {getLinkPath, localDateTimeFromISO8601, scrollToTop} from 'app/utils';
import {LOGO_IMAGE} from 'app/consts';
import {Link} from 'react-router-dom';
import I18n from 'app/i18n';
import React, {Component, ReactNode} from 'react';
import ArticleInterface from 'app/interfaces/articles/ArticleInterface';
import Holder from 'app/components/pages/articles/Holder';
import {getAuthor} from 'app/utils/articles/getAuthor';

interface SeoItemInterface {
    data: ArticleInterface,
    type: string
}

export default class SeoItem extends Component<SeoItemInterface> {
    render(): ReactNode {
        const {id, preview_image, title, author, date, article_type, date_update, pre_content, slug} = this.props.data;
        const {type} = this.props;
        return <div
            className="article--preview"
            key={id}
            itemScope
            itemType="https://schema.org/Article"
        >
            {preview_image && <div
                itemProp="image"
                itemScope
                itemType="https://schema.org/ImageObject"
                className="article--preview__image"
            >
                <LazyImage attributes={{itemProp: 'url'}} image={{name: title, url: preview_image}}/>
                <span itemProp="width" className="article--preview__img_size">720</span>
                <span itemProp="height" className="article--preview__img_size">400</span>
            </div>}
            <Holder>
                <meta itemProp="headline" content={title}/>
                <div itemProp="name" className="article--preview__title" dangerouslySetInnerHTML={{__html: title}}/>
                <div className="article--preview__date">
                    {getAuthor(author, date, article_type)}
                </div>
                <div itemProp="datePublished"
                    className="article--preview__date--published">{localDateTimeFromISO8601(date)}</div>
                <div itemProp="dateModified"
                    className="article--preview__date--updated">{localDateTimeFromISO8601(date_update)}</div>
                <span className="article--preview__author"
                    itemProp="author">{author}</span>
                <span className="article--preview__published"
                    itemProp="publisher" itemScope itemType="https://schema.org/Organization">
                    <span itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
                        <img itemProp="url" src={LOGO_IMAGE} alt="EGB.com" title="EGB"/>
                        <LazyImage
                            attributes={{itemProp: 'url'}}
                            image={{name: 'EGB.com', url: preview_image}}
                            title="EGB"
                        />
                    </span>
                    <meta itemProp="name" content={I18n.t('egb_team')}/>
                </span>
                <div className="article--preview__text" dangerouslySetInnerHTML={{__html: pre_content}}/>
                <div className="article--preview__links">
                    <Link
                        itemProp="mainEntityOfPage"
                        itemScope
                        itemType="https://schema.org/WebPage"
                        itemID={getLinkPath(`/${type}/${slug}`)}
                        to={getLinkPath(`/${type}/${slug}`)}
                        onClick={scrollToTop}
                        className="btn btn--middle btn--invert">
                        {I18n.t('read_more')}
                    </Link>
                </div>
            </Holder>
        </div>;
    }
}
