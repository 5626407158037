import LineInterface from 'app/interfaces/LineInterface';

export const gamerCoef = (line: LineInterface, gamerNum: number): string | boolean => {
    const res = line[`coef_${gamerNum}`];

    if (!res || res === '' || res === 0) {
        return false;
    }
    return res;
};
