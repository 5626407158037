import React, {ReactNode} from 'react';
import DefaultRegistration from './DefaultRegistration';

export default class FastRegistration extends DefaultRegistration {
    static LEAD_FIELDS = [
        'name',
        'password',
        'email'
    ];

    static METHOD = 'fast';

    render(): ReactNode {
        return this.state.success
            ? <div>{this.state.success}</div>
            : <form id="fast-sign-up" onSubmit={this.signUp} ref={this.rootForm}
                className="form-popup popup-form--error">
                <input
                    name="site_lang"
                    type="hidden"
                    value={window.settings.config.lang}
                />
                <input name="full_registered" type="hidden" value="0"/>
                {[
                    this.rowName(),
                    this.rowPassword(true),
                    this.rowEmail(),
                    this.rowNoMarketingConsent(),
                    this.rowTermsOfUse(),
                    this.rowCaptcha()
                ]}
                <div className="popup-form__submit-row">
                    {this.renderRegisterButton()}
                </div>
            </form>;
    }
}
