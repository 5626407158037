import React, {PureComponent} from 'react';

export interface ImageProps {
    image: {
        url: string,
        name?: string
    },
    className?: string;
    alt?: string;
    title?: string;
}

export default class Image<T extends ImageProps> extends PureComponent<T> {
    render(): React.ReactNode {
        const {image: {url, name}, className, alt, title} = this.props;
        return <img
            src={url}
            className={className}
            alt={alt || name}
            title={title || name}
        />;
    }
}
