import BaseToast from 'app/components/toasts/BaseToast';
import P2pCashoutConfirmContent from 'app/components/toasts/Contents/P2pCashoutConfirmContent';
import React from 'react';
import {toast} from 'react-toastify';
import {MINIMIZED_CONTAINER_ID} from 'app/utils/Consts';

export default class P2PCashoutConfirm extends BaseToast {
    rootToastElement: React.RefObject<P2pCashoutConfirmContent> = React.createRef();

    notify = (): React.ReactText => {
        const {cashout} = this.props.data;
        return toast.warning(<P2pCashoutConfirmContent ref={this.rootToastElement} cashout={cashout}/>,
            this.options({
                autoClose: false,
                bodyClassName: 'body-class',
                className: 'Toastify__toast--p2p',
                closeButton: false,
                closeOnClick: false,
                containerId: MINIMIZED_CONTAINER_ID,
                onOpen: this.playSound
            }));
    };

    onClick = null;
}
