import React, {PureComponent, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {i18n_t} from 'app/i18n';
import {HeaderMenu} from 'site_version/com/HeaderMenu';
import {getLinkPath, isGuest, scrollToTop} from 'app/utils';
import {PageContent} from 'app/components/page_content';

const PARTNER_LIST = [
    {
        icon_height: '16px',
        icon_width: '50px',
        svg: 'visa'
    },
    {
        icon_height: '24px',
        icon_width: '31px',
        svg: 'mastercard'
    },
    {
        icon_height: '22px',
        icon_width: '130px',
        svg: 'paysafecard'
    },
    {
        icon_height: '18px',
        icon_width: '53px',
        svg: 'skrill'
    },
    {
        icon_height: '22px',
        icon_width: '94px',
        svg: 'ecopayz'
    },
    {
        icon_height: '15px',
        icon_width: '81px',
        svg: 'neteller'
    },
    {
        icon_height: '22px',
        icon_width: '84px',
        svg: 'webmoney'
    },
    {
        icon_height: '22px',
        icon_width: '23px',
        svg: 'btc'
    },
    {
        icon_height: '22px',
        icon_width: '18px',
        svg: 'yandexmoney'
    },
    {
        icon_height: '22px',
        icon_width: '35px',
        svg: 'unionpay'
    },
    {
        icon_height: '21px',
        icon_width: '47px',
        svg: 'boleto'
    },
    {
        icon_height: '21px',
        icon_width: '46px',
        svg: 'p4f'
    },
    {
        icon_height: '22px',
        icon_width: '60px',
        svg: 'paypal'
    },
    {
        icon_height: '32px',
        icon_width: '56px',
        svg: 'revolut'
    },
    {
        icon_height: '17px',
        icon_width: '58px',
        svg: 'papara'
    },
    {
        icon_height: '18px',
        icon_width: '68px',
        svg: 'gcash'
    },
    {
        icon_height: '18px',
        icon_width: '90px',
        svg: 'wechatpay'
    }
];

const LICENSE_IMAGE_URL = 'https://seal.cgcb.info/8b089e55-4795-425a-6bf6-fdb75eba6900';
// eslint-disable-next-line max-len
const LICENSE_CLICK_URL = 'https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJbXB3ZUhScU5TOWpiRWxWVkZSQk1uUnJhM2hoWVdjOVBTSXNJblpoYkhWbElqb2ljVUpxVWxsVVJFeElXVGxVT0VkemJVMVRlVk5sUVQwOUlpd2liV0ZqSWpvaU9URm1aVE0wWXpZMlptTmlPVFZoWlRCak5qazFZakEzTm1abFpURmtZV1JoWmprMVpUZzVZamsyTm1RNU5UZGpZakZqWW1OaE5tVmlPVEU0WVRjd01TSXNJblJoWnlJNklpSjk=';

export default class Footer extends PureComponent {
    componentDidMount = (): void => {
        if (window.SHOW_LICENSE) {
            this.createScriptToElement('second-license',
                'https://a3ccd1f4-e8b2-4443-8523-479da29966ed.seals-emr.certria.com/emr-seal.js');
        }
    };

    createScriptToElement = (elementId: string, src: string): void => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        document.getElementById(elementId).appendChild(script);
    };

    render(): ReactNode {
        return <div className="footer">
            <div className="footer__inner">
                <div className="footer__nav">
                    <nav className="footer-nav">
                        <Link
                            to={getLinkPath('/esports')}
                            className="footer-nav__link"
                            onClick={scrollToTop}
                        >
                            {i18n_t('menu_links.esport')}
                        </Link>

                        {HeaderMenu.map(item => <a
                            key={item.section}
                            className="footer-nav__link"
                            href={getLinkPath(item.path)}
                        >
                            {i18n_t(`menu_links.${item.section}`)}
                        </a>)}
                        <Link
                            className="footer-nav__link"
                            to={getLinkPath('/about')}
                            onClick={scrollToTop}
                        >
                            {i18n_t('menu_links.about')}
                        </Link>
                        <Link
                            className="footer-nav__link"
                            to={getLinkPath('/contacts')}
                            onClick={scrollToTop}
                        >
                            {i18n_t('menu_links.contacts')}
                        </Link>
                        <Link
                            className="footer-nav__link"
                            to={getLinkPath('/sitemap.html')}
                            onClick={scrollToTop}
                        >
                            {i18n_t('menu_links.sitemap')}
                        </Link>
                        <a
                            className="footer-nav__link"
                            href="https://egbaffiliates.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {i18n_t('menu_links.affiliate')}
                        </a>
                        <Link
                            to={getLinkPath('/careers')}
                            className="footer-nav__link"
                            onClick={scrollToTop}
                        >
                            {i18n_t('menu_links.careers')}
                        </Link>
                    </nav>
                </div>
                <div className="footer__text footer__text__top">
                    <div className="footer__plain-text">
                        <PageContent contentUrl="/" contentKey="footer_content"/>
                    </div>
                </div>
                <div className="partner-list-license">
                    <div className="partner-list">
                        {PARTNER_LIST.map(partner => <span
                            key={partner.svg}
                            className="partner-list__link"
                        >
                            <svg height={partner.icon_height} width={partner.icon_width}>
                                <use xlinkHref={`#svg-payment-${partner.svg}`}/>
                            </svg>
                        </span>)}
                    </div>
                    <div className="footer__share-license">
                        <div className="footer__share-license">
                            <div className="license-list">
                                {window.SHOW_LICENSE && <>
                                    <div id="license">
                                        <div id="apg-seal-container" className="apg-license">
                                            <div>
                                                <a target="_blank" rel="noreferrer" href={LICENSE_CLICK_URL}>
                                                    <img style={{maxHeight: '100%', maxWidth: '100%'}} src={LICENSE_IMAGE_URL}/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="second-license">
                                        <div
                                            id="emr-a3ccd1f4-e8b2-4443-8523-479da29966ed"
                                            data-emr-seal-id="a3ccd1f4-e8b2-4443-8523-479da29966ed"
                                            data-emr-image-size="27"
                                            data-emr-image-type="basic-light-large"
                                            className="em-license"
                                        />
                                    </div>
                                </>}
                                <svg height="27px" width="27px"><use xlinkHref={'#svg-eighteenplus'}/></svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`footer__text footer__text__bottom${isGuest() ? ' padding_bottom' : ''}`}>
                    <div className="footer__plain-text">
                        <PageContent contentUrl="/" contentKey="footer_content_license"/>
                    </div>
                </div>
            </div>
        </div>;
    }
}
