import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

export default class SocialLink extends PureComponent {
    static propTypes = {
        href: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        xlinkHref: PropTypes.string.isRequired
    };

    render() {
        const {href, xlinkHref, text} = this.props;

        return <a href={href}
            target="_blank"
            className="btn btn--invert socials__item--with-text"
            rel="noreferrer"
        >
            <svg height="30px" width="30px">
                <use xlinkHref={xlinkHref}/>
            </svg>
            {text}
        </a>;
    }
}
