import React, {PureComponent} from 'react';
import {PageContent} from 'app/components/page_content';
import PlayPage from '../play';

export default class PlayContentImpl extends PureComponent {
    render() {
        const {filter = '', urlPrefix, keyPrefix, tableProps, tableStore} = this.props;
        const contentKey = keyPrefix + (filter === '' ? '' : '_') + filter;
        return <>
            {this.props.children || <PlayPage.Table
                tableStore={tableStore}
                {...tableProps}
            />}
            <div className="mobile mobile--hidden">
                <PageContent
                    contentUrl={urlPrefix + filter}
                    contentKey={contentKey}
                    contentHolder/>
            </div>
        </>;
    }
}
