import React, {PureComponent} from 'react';

export default class HoneycombGroup extends PureComponent {
    honeyCombClass = () => {
        const {type, multi} = this.props;

        if (!multi) {
            return '';
        }

        switch (type) {
        case 'helmet':
            return ' honeycomb-arrow-down';
        case 'armor':
            return ' honeycomb-yellow honeycomb-arrow-up';
        case 'sword':
            return ' honeycomb-arrow-right';
        case 'shield':
            return ' honeycomb-arrow-right';
        case 'cloak':
            return ' honeycomb-yellow honeycomb-arrow-down';
        case 'boots':
            return 'honeycomb-horizontal';
        default:
            return '';
        }
    };

    render() {
        const {type, multi} = this.props;

        if (!multi) {
            return this.props.children;
        }
        return <div className={`honeycomb--group hero__inventory--${type}${this.honeyCombClass()}`}>
            {this.props.children}
        </div>;
    }
}
