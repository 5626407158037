import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import SelectPicker from 'app/components/reusable/SelectPicker';
import CollectionInterface from 'app/interfaces/CollectionInterface';
import P2pDepositsStoreProps from 'app/store/P2pDepositsStoreProps';
import {DepositsStoreProps} from 'app/store/DepositsStore';
import {inject, observer} from 'mobx-react';
import {ALL_BANKS} from 'app/store/P2pDepositsStore';

interface SelectProps extends P2pDepositsStoreProps, DepositsStoreProps {}

@inject('p2pDepositsStore', 'depositsStore')
@observer
export default class CountrySelect extends Component<SelectProps> {
    handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        const {p2pDepositsStore} = this.props;
        p2pDepositsStore.selectBankById(ALL_BANKS, true);
        p2pDepositsStore.selectCountry(e.target.value);
    };

    get countryCollection(): CollectionInterface[] {
        const {p2p_countries} = this.props.p2pDepositsStore;
        return p2p_countries.map(({country_code, title}) => ({text: title, value: country_code}));
    }

    render(): ReactNode {
        const {currentCountry, p2p_countries} = this.props.p2pDepositsStore;

        if (!this.props.p2pDepositsStore.currentPaymentSystem || !p2p_countries) {
            return null;
        }

        return <div className="form__row form__row-select">
            <SelectPicker
                onChange={this.handleCountryChange}
                value={currentCountry && currentCountry.country_code}
                collection={this.countryCollection}
                placeholder={I18n.t('select_country')}
                className="select-bank"
            />
        </div>;
    }
}
