import React, {ReactNode} from 'react';
import DefaultRegistration from './DefaultRegistration';

export default class FullRegistration extends DefaultRegistration {
    static LEAD_FIELDS = [
        'name',
        'password',
        'email',
        'first_name',
        'last_name'
    ];

    static METHOD = 'full';

    render(): ReactNode {
        return this.state.success
            ? <div>{this.state.success}</div>
            : <form id="full-sign-up" onSubmit={this.signUp} ref={this.rootForm}
                className="form-popup popup-form--error">
                <input name="site_lang" type="hidden" value={window.settings.config.lang}/>
                <input name="full_registered" type="hidden" value="1"/>
                {[
                    this.rowName(),
                    this.rowPassword(true),
                    this.rowEmailFull(),
                    this.rowPhone(),
                    this.rowFirstName(),
                    this.rowLastName(),
                    this.rowDayOfBirth(),
                    this.rowCountryCode(),
                    this.rowNoMarketingConsent(),
                    this.rowTermsOfUse(),
                    this.rowCaptcha()
                ]}
                <div className="popup-form__submit-row">
                    {this.renderRegisterButton()}
                </div>
            </form>;
    }
}
