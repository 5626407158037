import $ from 'jquery';
import React, {Component} from 'react';
import I18n from 'app/i18n';
import {closeMobileMenu, streamFlagImage, streamGameImage} from 'app/utils';
import {streamStore} from 'app/store/global';

export default class Stream extends Component {
    rootElement = React.createRef();

    componentDidMount() {
        this.updateBackgroundImage();
    }

    componentDidUpdate() {
        this.updateBackgroundImage();
    }

    channelName() {
        return this.props.stream.twitch_channel && this.props.stream.twitch_channel.toLowerCase();
    }

    updateBackgroundImage() {
        const {imageLastUpdate} = this.props;
        const img = new Image();

        img.onload = () => {
            if (this.rootElement.current) {
                $(this.rootElement.current).css('background-image', `url(${img.src})`);
            }
        };
        img.src = `https://static-cdn.jtvnw.net/previews-ttv/live_user_${this.channelName()}-250x140.jpg?${imageLastUpdate}`;
    }

    handleDeleteUserStream = e => {
        e.preventDefault();
        e.stopPropagation();
        streamStore.deleteUserStream(this.props.stream.id);
    };

    onClick = event => {
        const {stream} = this.props;
        this.props.onClick(stream);
        closeMobileMenu(event);
    };

    render() {
        const {stream, userStream, mobile} = this.props;
        const iconSize = mobile ? '20px' : '50px';
        const svg = mobile ? 'play-mobile' : 'play-movie';
        return <a href="#"
            style={{display: this.props.preview === 'tournaments' ? null : 'inline-block'}}
            onClick={event => this.onClick(event)}
            className={`${mobile ? '' : 'js-streams-minheight'} media-preview media-preview--${this.props.preview}`}
            ref={this.rootElement}
        >
            {userStream && <span className="stream--actions">
                <span
                    onClick={this.handleDeleteUserStream}
                    className="stream--action stream--action-delete"
                    title={I18n.t('user.delete')}
                >
                    <svg height="19px" width="19px">
                        <use xlinkHref="#svg-stream-delete-mobile"/>
                    </svg>
                </span>
            </span>}
            <span title={I18n.t('play_stream')} className={`${mobile ? 'media-preview--play-stream-mobile' : ''}`}>
                <svg height={iconSize} width={iconSize}>
                    <use xlinkHref={`#svg-${stream.target_blank ? 'play-youtube' : svg}`}/>
                </svg>
            </span>
            <span className="media-preview__name">
                <span className="media-preview__icons">
                    {streamGameImage(stream.game, true, 'media-preview__name--game')}
                    {streamFlagImage(stream.author_flag, true, 'media-preview__name--flag')}
                </span>
                <span className="media-preview__name-text" title={stream.title}>{stream.title}</span>
            </span>
        </a>;
    }
}
