import $ from 'jquery';
import React, {Fragment, PureComponent} from 'react';
import I18n from 'app/i18n';
import {localePromoName, serverError} from 'app/utils';
import TableEmptyState from '../table/empty_state';
import concat from 'lodash/concat';
import UserAnswers from './my_predictions/user_answers';
import ShowMore from 'app/utils/ShowMore';
import SmartLoader from 'app/components/reusable/SmartLoader';

export default class MyPredictions extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            has_more: false,
            loading: true,
            page: 1
        };
        this.loadMoreHandler = this.loadMoreHandler.bind(this);
    }

    componentDidMount = () => this.loadMoreHandler();

    loadMoreHandler() {
        const {page, data} = this.state;

        return $.ajax(window.location.pathname, {
            data: {page},
            error: () => serverError(),
            success: response => this.setState({
                ...response,
                data: concat(data, response.data),
                page: page + 1
            })
        }).always(() => this.setState({loading: false}));
    }

    render() {
        const {data, has_more, loading} = this.state;

        return <Fragment>
            <div className="title">
                {I18n.t(`user.promo_action.${localePromoName()}.my_predictions_title`)}
            </div>
            {loading
                ? <SmartLoader/>
                : <Fragment>
                    <table className="compendium-table prediction-table">
                        <thead>
                            <tr>
                                <th className="prediction-date">{I18n.t('date_label')}</th>
                                <th className="prediction-question">{I18n.t('table.question_title')}</th>
                                <th className="prediction-answer">{I18n.t('table.answer_title')}</th>
                                <th className="prediction-answer-correct">{I18n.t('table.correct_answers_title')}</th>
                                <th className="prediction-prize">
                                    <span>
                                        <svg height="14px" width="15px">
                                            <use xlinkHref="#svg-cup-table"/>
                                        </svg>
                                    </span>
                                    <span>{I18n.t('table.prize_title')}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <UserAnswers {...this.state}/>
                        </tbody>
                    </table>
                    <ShowMore onClickHandler={this.loadMoreHandler} has_more={has_more}/>
                    <TableEmptyState
                        arrayLength={data.length}
                        text={I18n.t(`user.promo_action.${localePromoName()}.you_dont_have_any_predictions_yet`)}
                    />
                </Fragment>}
        </Fragment>;
    }
}
