import React, {PureComponent, ReactNode} from 'react';
import {lineGamer} from 'app/lines/lineGamer';
import {fixed3, flagImage, gameImage} from 'app/utils';
import GamerPhoto from 'app/components/bet_form/GamerPhoto';
import BetSlipItem from 'app/interfaces/BetSlipItem';
import LineInterface from 'app/interfaces/LineInterface';
import {coefColorClass} from 'app/lines/coefColorClass';
import {gamerCoef} from 'app/lines/gamerCoef';
import {formGamerTitle} from 'app/lines/formGamerTitle';
import {formVsTitle} from 'app/lines/formVsTitle';
import {formGamerSubTitle} from 'app/lines/formGamerSubTitle';
import I18n from 'app/i18n';
import {FIVE_SECONDS, STATUS_OPENED} from 'app/utils/Consts';
import {checkOwnLine} from 'app/lines/checkOwnLine';
import {isRedLine} from 'app/lines/line_helpers';
import {inject, observer} from 'mobx-react';
import BetSlipController from 'app/components/bet_slip/BetSlipController';
import {BetSlipStore} from 'app/store/BetSlipStore';

interface BetSlipMatchGameProps {
    bet: BetSlipItem
    controller?: BetSlipController
    betSlipStore?: BetSlipStore
    showCoefInList: boolean
}

const formWarning = (line: LineInterface, incubator2_0) => {
    if (incubator2_0) {
        return isRedLine(line.color) ? null : I18n.t('bet_unavailable');
    }
    return line.status === STATUS_OPENED ? checkOwnLine(line.isCreator) : I18n.t('bet_unavailable');
};

@inject('controller', 'betSlipStore')
@observer
export default class BetSlipMatchGame extends PureComponent<BetSlipMatchGameProps> {
    state = {
        coef: null,
        coefIncreased: null,
        warning: null
    };

    deleteCoefColorTimer = null;

    componentDidMount = (): void => {
        if (this.props.showCoefInList) {
            this.setState({coef: this.getCoef()});
        }
    };

    componentDidUpdate = (): void => {
        const {bet, controller, showCoefInList, betSlipStore} = this.props;
        const {incubator2} = betSlipStore;
        const warning = formWarning(bet.selectedLine, incubator2);

        if (!incubator2 && showCoefInList) {
            this.checkCoefIncreased();

            if (this.state.warning !== warning) {
                this.setState({warning});
                controller.removeClosedLines();
            }
            return;
        }
        controller.setWarning(warning);
    };

    deleteCoefColor = (): void => {
        if (this.deleteCoefColorTimer) {
            clearTimeout(this.deleteCoefColorTimer);
        }

        this.deleteCoefColorTimer = setTimeout(() => {
            this.setState({coefIncreased: null});
        }, FIVE_SECONDS);
    };

    checkCoefIncreased = (): void => {
        const prevCoef = Number(this.state.coef);
        const coef = this.getCoef();

        if (coef !== prevCoef) {
            this.setState({coef, coefIncreased: coef > prevCoef});
            this.deleteCoefColor();
        }
    };

    getCoef = (): number => {
        const {selectedLine, num} = this.props.bet;
        return Number(gamerCoef(selectedLine, num));
    };

    betRemove = (): void => this.props.controller.removeOnclick(this.props.bet.selectedLine);

    render(): ReactNode {
        const {rootLine, selectedLine, num} = this.props.bet;
        const gamer = lineGamer(selectedLine, num);
        const {showCoefInList} = this.props;
        const {coefIncreased, warning} = this.state;
        const coef = showCoefInList ? this.getCoef() : '';
        const gamerTitle = formGamerTitle(rootLine, selectedLine, num);
        const formGamerSubTitleText = formGamerSubTitle(selectedLine, num);
        return <div className="bet-slip-match-game">
            <div className="inner">
                <div className="left">
                    <GamerPhoto
                        line_gamer={gamer}
                        className="bet-slip-match-team-logo"
                    />
                </div>
                <div className="center">
                    <div className="bet-slip-match-team">
                        {gamerTitle.length > 0 && <div className="bet-slip-match-team-information">
                            {flagImage(gamer.flag, 'bet-slip-match-team-country-flag')}
                            <span className="bet-slip-match-team-name">
                                {gamerTitle}
                            </span>
                        </div>}
                        {formGamerSubTitleText.length > 0 &&
                        <span className="bet-slip-match-description">
                            {gamerTitle.length === 0 &&
                            flagImage(gamer.flag, 'bet-slip-match-team-country-flag')}
                            {formGamerSubTitleText}
                        </span>}
                        {showCoefInList && <span
                            className={`bet-slip-match-team-coefficient${coefColorClass(coefIncreased)}`}>
                            {fixed3(coef)}
                        </span>}
                    </div>
                    <div className="bet-slip-match-teams">
                        {gameImage(rootLine.filter, 'bet-slip-match-game-logo')}
                        <span className="bet-slip-match-teams-names">{formVsTitle(rootLine)}</span>
                        {showCoefInList && <button
                            className="button-bet-slip-remove with-title"
                            onClick={this.betRemove}
                        >
                            {warning && <span className="button-title">{I18n.t('user.delete')}</span>}
                            <svg className="svg-default">
                                <use xlinkHref="#svg-bet-slip-remove"/>
                            </svg>
                        </button>}
                    </div>
                </div>
            </div>
            {rootLine.text && <div className="bet-slip-line-description">
                <svg className="svg-default">
                    <use xlinkHref="#svg-round-warning"/>
                </svg>
                <div><p>{rootLine.text}</p></div>
            </div>}
            {warning && <div className="bet-slip-match-warning">
                <p>{warning}</p>
            </div>}
        </div>;
    }
}
