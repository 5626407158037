import React from 'react';
import I18n from 'app/i18n';
import WarningMessage from './warning_message';
import {localePromoName} from 'app/utils';

export default class NoPredictions extends React.PureComponent {
    render() {
        return <WarningMessage>
            <div>{I18n.t(`user.promo_action.${localePromoName()}.warning.no_predictions`)}</div>
        </WarningMessage>;
    }
}
