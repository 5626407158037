import React, {Component, ReactNode} from 'react';
import SmartLabelTooltip from 'app/components/reusable/SmartLabelTooltip';
import {inject, observer} from 'mobx-react';
import {IncubatorCreateLineController} from 'app/controllers/IncubatorCreateLineController';
import {ElementWithError} from 'app/components/reusable/ElementWithError';

interface Props {
    controller?: IncubatorCreateLineController;
    stream_link: string
    isEditable: boolean
    esports?: boolean
}

@inject('controller')
@observer
export default class IncubatorLineFormLineStream extends Component<Props> {
    handleChangeStreamLink = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.props.controller.onChangeStreamLink(e.target.value);
        this.props.controller.incubatorGame.clearError('stream_link');
    };

    errorHint = (field: string): ReactNode => this.error(field);

    error = (field: string): string => this.props.controller.incubatorGame.getError(field);

    render(): ReactNode {
        const {esports, stream_link, isEditable} = this.props;

        return <div className="field-item column-4">
            <SmartLabelTooltip
                forLabelTooltip="linkToStreamIncubator"
                labelTooltip={`tooltip_incubator_stream_title_${esports
                    ? 'esport'
                    : 'sport'}`}
                descriptionTooltip={`tooltip_incubator_stream_platforms_${esports
                    ? 'esport'
                    : 'sport'}`}
            />
            <ElementWithError
                errorHint={this.errorHint('stream_link')}
                className={this.error('stream_link') ? 'field-error' : ''}
            >
                <div className="div-input-stream">
                    <input
                        className="field__input"
                        placeholder={esports
                            ? 'https://www.twitch.tv/channel'
                            : 'https://www.flashscore.com/match/match_id'}
                        type="text"
                        id="linkToStreamIncubator"
                        name="link[ToStreamIncubator]"
                        disabled={isEditable}
                        value={stream_link}
                        onChange={this.handleChangeStreamLink}
                    />
                    <label className="icon-stream" htmlFor="linkToStreamIncubator">
                        <svg className="svg-default">
                            <use xlinkHref="#svg-movie"/>
                        </svg>
                    </label>
                </div>
            </ElementWithError>
        </div>;
    }
}
