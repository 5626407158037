import React, {useCallback, useEffect, useState} from 'react';
import I18n from 'app/i18n';
import {NavLink, useNavigate} from 'react-router-dom';
import {getLinkPath, isGuest} from 'app/utils';
import {linkAndroid, linkIOSDownload} from 'app/utils/app_links';
import FastRegistration from 'app/components/auth/FastRegistration';
import {navigateGoTo} from 'app/utils/navigate/navigateGoTo';
// @ts-ignore
import SvgLogoEgb from 'images/logos/egb-com-black.svg';

const WelcomeLanding: React.FC = () => {
    const [pressedButton, setPressedButton] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isGuest()) {
            navigate('/play/bets');
            navigateGoTo(getLinkPath('/play/bets'));
        }
    }, []);

    const onClickRegistration = () => {
        setPressedButton(prevState => !prevState);
    };

    const handleOnClickRegistration = useCallback(() => onClickRegistration(), [onClickRegistration]);

    return <main className={`landing-promo-content welcome${pressedButton ? ' no-scroll' : ''}`}>
        <div className="landing-promo-header">
            <NavLink to={getLinkPath('/')} className="logo-landing-promo">
                <img src={SvgLogoEgb} alt="EGB.com" title="EGB.COM"/>
            </NavLink>
        </div>
        <div className="landing-promo-middle">
            <div className="landing-promo-left">
                <div className="landing-promo-slogan-top">
                    <span className="title-thin">{I18n.t('landing_promo_welcome_title_1')}</span>
                    <span className="title-bold">{I18n.t('landing_promo_welcome_title_2')}</span>
                    <span className="title-thin">{I18n.t('landing_promo_welcome_title_3')}</span>
                </div>
            </div>
            <div className={`landing-promo-center${pressedButton ? ' active' : ''}`}>
                <button
                    className="button-close-registration-landing-promo"
                    onClick={handleOnClickRegistration}
                >
                    <svg height="21px" width="21px">
                        <use xlinkHref="#svg-close"/>
                    </svg>
                </button>
                <div className="landing-promo-registration">
                    <div className="landing-promo-registration-top">
                        <h2>{I18n.t('about_landing_ladder_game_1')}</h2>
                        <p>{I18n.t('landing_promo_welcome_play_slot')} </p>
                    </div>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <FastRegistration redirectUrl={'/'}/>
                </div>
            </div>
            <div className="landing-promo-right">
                <div className="landing-promo-slogan-top">
                    <span className="title-thin">{I18n.t('landing_promo_welcome_title_2_1')}</span>
                    <span className="title-bold">{I18n.t('landing_promo_welcome_title_2_2')}</span>
                    <span className="title-thin">{I18n.t('bonus_landing_ladder_game')}</span>
                </div>
                <div className="landing-promo-slogan-get">
                    <span className="landing-promo-get">{I18n.t('landing_promo_welcome_get')}!</span>
                </div>
            </div>
        </div>
        <div className="landing-promo-footer">
            <div className="landing-promo-content-rules">
                <div className="row">
                    <NavLink
                        className="landing-promo-rules"
                        to={'/public/terms_and_conditions'}
                    >
                        {I18n.t('advertising.terms_conditions')}
                    </NavLink>
                </div>
                <div className="row">
                    <div className="landing-promo-list-app">
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            className="item"
                            href={linkIOSDownload()}
                        >
                            <svg className="svg-default">
                                <use xlinkHref="#svg-app_store"/>
                            </svg>
                        </a>
                        <NavLink className="item" to={linkAndroid()}>
                            <svg className="svg-default">
                                <use xlinkHref="#svg-google_play"/>
                            </svg>
                        </NavLink>
                    </div>
                </div>
                <div className="row">
                    <div className="landing-promo-list-payment">
                        <span className="item visa">
                            <svg className="svg-default">
                                <use xlinkHref="#svg-visa"/>
                            </svg>
                        </span>
                        <span className="item mastercard">
                            <svg className="svg-default">
                                <use xlinkHref="#svg-master_card"/>
                            </svg>
                        </span>
                        <span className="item license">
                            <svg className="svg-default">
                                <use xlinkHref="#svg-eighteenplus-adv"/>
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div className="mobile-button-landing-promo">
            <button
                className="button-registration-landing-promo"
                onClick={handleOnClickRegistration}
            >{I18n.t('user.registration')}</button>
        </div>
    </main>;
};

export default WelcomeLanding;
