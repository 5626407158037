import React, {Component, ReactNode} from 'react';

export default class PokerMoveButtonMinimize extends Component {
    render(): ReactNode {
        return <button className="poker-move-button minimize-poker">
            <svg className="svg-default">
                <use xlinkHref="#svg-bet-slip-controller-minimize-1"/>
            </svg>
        </button>;
    }
}
