import LazyImage from 'app/helpers/LazyImage';
import {getLinkPath, scrollToTop, timeStampToTime} from 'app/utils';
import {Link} from 'react-router-dom';
import I18n from 'app/i18n';
import React, {Component, ReactNode} from 'react';
import Holder from 'app/components/pages/articles/Holder';
import ArticleInterface from 'app/interfaces/articles/ArticleInterface';
import {isHiddenTemple} from 'app/utils/articles/isHiddenTemple';

interface ItemProps {
    data: ArticleInterface
    type: string
}

export default class Item extends Component<ItemProps> {
    render(): ReactNode {
        const {preview_image, title, id, date, pre_content, slug} = this.props.data;
        const type = isHiddenTemple(this.props.type) ? 'news' : this.props.type;
        return <div className="article--preview" key={id}>
            {preview_image && <div className="article--preview__image">
                <LazyImage image={
                    {
                        name: title,
                        url: preview_image
                    }
                }/> </div>}
            <Holder>
                <div className="article--preview__title" dangerouslySetInnerHTML={{__html: title}}/>
                <div className="article--preview__date">{timeStampToTime(date)}</div>

                <div className="article--preview__text" dangerouslySetInnerHTML={{__html: pre_content}}>
                </div>
                <div className="article--preview__links">
                    <Link
                        to={getLinkPath(`/${type}/${slug}`)}
                        className="btn btn--middle btn--invert"
                        onClick={scrollToTop}
                    >
                        {I18n.t('read_more')}
                    </Link>
                </div>
            </Holder>
        </div>;
    }
}
