import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';


interface ConfirmEmailSignProps {
    confirmed: boolean
}

export class ConfirmEmailSign extends Component<ConfirmEmailSignProps> {
    renderSign(confirmed: boolean): JSX.Element {
        return <span className="field__state"
            title={confirmed
                ? I18n.t('email_confirmed')
                : I18n.t('email_not_confirmed')}>
            <svg height="12px" width="12px">
                <use xlinkHref={confirmed ? '#svg-check' : '#svg-close'}/>
            </svg>
        </span>;
    }

    render(): ReactNode {
        let sign = null;
        if (typeof this.props.confirmed !== 'undefined') {
            sign = this.renderSign(this.props.confirmed);
        }

        return <>{sign}</>;
    }
}
