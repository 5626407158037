import React from 'react';
import I18n from 'app/i18n';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import LogIn from 'app/components/auth/log_in';
import FullRegistration from 'app/components/auth/FullRegistration';
import FastRegistration from 'app/components/auth/FastRegistration';
import ForgotPassword from './forgot_password';
import {DoubleDepositBanner} from 'app/components/advertising_banners/DoubleDepositBanner';
import logEvent from 'app/amplitude/log_event';
import {iOSDevice} from 'app/utils';
import {KEY_ENTER} from 'app/utils/Consts';

class DefaultAuthPopup extends React.PureComponent {
    static clearFiledError(component, e) {
        if (e.key !== KEY_ENTER) {
            component.setState({error: null});
        }
    }

    constructor(props) {
        super(props);
        this.activeLink = this.activeLink.bind(this);
        this.changeTab = this.changeTab.bind(this);
        this.renderForm = this.renderForm.bind(this);
    }

    activeLink(tab) {
        return tab === this.state.selectedTab ? ' active-link' : '';
    }

    changeTab(tab, e) {
        e.preventDefault();
        this.setState({selectedTab: tab});
    }

    changeTabToForgotten = e => this.changeTab('forgotten', e);

    changeTabToLogin = e => this.changeTab('login', e);

    renderForm() {
        const {selectedTab} = this.state;
        const {redirectUrl} = this.props;

        switch (selectedTab) {
        case 'login':
            return <LogIn
                forgotPassword={this.changeTabToForgotten}
                redirectUrl={redirectUrl}
            />;
        case 'sign_up':
            return <FastRegistration usingOnTab={this.props.usingOnTab}/>;
        case 'full_sign_up':
            return <FullRegistration usingOnTab={this.props.usingOnTab}/>;
        case 'forgotten':
            return <ForgotPassword back={this.changeTabToLogin} />;
        }
    }
}

export class RegistrationPopup extends DefaultAuthPopup {
    constructor(props) {
        super(props);
        this.state = {
            iOSDevice: iOSDevice(),
            selectedTab: this.props.selectedTab || 'sign_up'
        };
    }

    componentDidMount = () => {
        logEvent('JOIN_PAGE_VISIT', {SOURCE: this.props.usingOnTab});
    };

    render() {
        return <PopupDialog id={this.props.popupId} setFocus>
            <div className="popup__content">
                <div className="tabs">
                    <div className="tabs__header tabs__header--auth">
                        <div className="tabs__inner__header">
                            {[
                                {
                                    tabName: 'sign_up',
                                    tabText: 'fast_registration_tab'
                                }, {
                                    tabName: 'full_sign_up',
                                    tabText: 'full_registration_tab'
                                }
                            ].map(tab => <a key={tab.tabName} href="#" onClick={this.changeTab.bind(this, tab.tabName)}
                                className={`tabs__link tabs__link--btn tabs__link--popup ${this.activeLink(tab.tabName)}`}>
                                {I18n.t(tab.tabText)}
                            </a>)}
                        </div>
                    </div>
                    <DoubleDepositBanner/>
                    <div className="tabs__content">
                        {this.renderForm()}
                    </div>
                </div>
            </div>
        </PopupDialog>;
    }
}

export class Auth extends DefaultAuthPopup {
    constructor(props) {
        super(props);
        this.state = {selectedTab: this.props.selectedTab || 'login'};
    }

    render() {
        return <PopupDialog id={this.props.popupId} setFocus>
            <div className="popup__content">
                <div className="tabs__content">
                    {this.renderForm()}
                </div>
            </div>
        </PopupDialog>;
    }
}
