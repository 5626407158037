import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';
import React, {useCallback, useEffect} from 'react';
import DepositSystem from '../form/DepositSystem';
import P2pDepositsStoreProps from 'app/store/P2pDepositsStoreProps';
import {DepositsStoreProps} from 'app/store/DepositsStore';
import {inject, observer} from 'mobx-react';
import FilteredDeposits from './FilteredDeposits';
import CurrentP2pDeposit from './CurrentP2pDeposit';
import DepositAmount from 'app/components/deposit/form/DepositAmount';
import DepositSubmit from 'app/components/deposit/form/DepositSubmit';
import SelectPicker from 'app/components/reusable/SelectPicker';
import {calculateAmountBySelectedCurrency} from 'app/utils/deposits/calculateAmountBySelectedCurrency';
import RelatedBankSelect from './RelatedBankSelect';
import CountrySelect from './CountrySelect';
import SelectBonusType from 'app/components/user/SelectBonusType';
import {userStoreInterface} from 'app/store/UserStore';
import DepositPromoCode from 'app/components/deposit/form/DepositPromoCode';
import {leavePage} from './LeavePage';
import {unstable_useBlocker as useBlocker} from 'react-router';
import {ALL_BANKS} from 'app/store/P2pDepositsStore';

interface P2pDepositProps extends P2pDepositsStoreProps, DepositsStoreProps, userStoreInterface {}

const THREE_SECOND = 3000;

const P2pDeposit: React.FC<P2pDepositProps> = ({depositsStore, p2pDepositsStore, userStore}) => {
    useEffect(() => {
        p2pDepositsStore.loadP2PCountries(system().id);
        p2pDepositsStore.selectBankById(ALL_BANKS, true);

        if (p2pDepositsStore.p2p_countries.length > 0) {
            p2pDepositsStore.selectCountry(p2pDepositsStore.p2p_countries[0].country_code);
        } else {
            p2pDepositsStore.setCurrencies();
            p2pDepositsStore.loadP2pDeposits(system().id);
        }

        const updateInterval = setInterval(
            () => p2pDepositsStore.loadP2pDeposits(system().id),
            THREE_SECOND
        );

        return () => {
            clearInterval(updateInterval);
        };
    }, [p2pDepositsStore, depositsStore]);

    useBlocker(() => leavePage(p2pDepositsStore));


    useEffect(() => {
        const preventUnload = (event: BeforeUnloadEvent) => {
            const {current_p2p_deposit} = p2pDepositsStore;

            if (!current_p2p_deposit || !current_p2p_deposit.working) {
                return false;
            }

            event.preventDefault();
            event.returnValue = '';
            return true;
        };

        window.addEventListener('beforeunload', preventUnload);

        return () => {
            window.removeEventListener('beforeunload', preventUnload);
        };
    }, []);

    const system = (): PaymentSystemInterface => depositsStore.currentPaymentSystem;

    const onSubmit = (): void => {
        const {amount, rates, bonus_type, promoCode} = depositsStore;
        const {currency, startDepositByAmount} = p2pDepositsStore;
        const newAmount = calculateAmountBySelectedCurrency(amount, currency, rates);
        startDepositByAmount(newAmount, bonus_type, promoCode);
    };

    const handleOnSubmit = useCallback(() => onSubmit(), [onSubmit]);

    const currencyChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        p2pDepositsStore.selectCurrency(e.target.value);
    };

    const handleCurrencyChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => currencyChange(e), [currencyChange]);

    const {firstDeposit, rates} = depositsStore;
    const {user} = userStore;
    const {filteredDeposits, current_p2p_deposit, currency, currencies, currentCountry} = p2pDepositsStore;
    const systemData = system();
    const {amount_input} = systemData;

    return current_p2p_deposit
        ? <CurrentP2pDeposit current_p2p_deposit={current_p2p_deposit} system={systemData} firstDeposit={firstDeposit} />
        : <>
            <DepositSystem>
                {system().p2p_same_country && <CountrySelect />}
                {currencies &&
                            <div className="form__row form__row-select">
                                <SelectPicker
                                    onChange={handleCurrencyChange}
                                    value={currency}
                                    collection={currencies}
                                    className="select-bank" />
                            </div>
                }
                <RelatedBankSelect />
                {amount_input && <>
                    <DepositAmount currency={currency} exchange_currency={currentCountry?.currency}/>
                    {!user.first_deposit && <SelectBonusType/>}
                    <DepositPromoCode/>
                    <DepositSubmit onSubmit={handleOnSubmit}/>
                </>}
                {!user.first_deposit && !amount_input && <SelectBonusType />}
            </DepositSystem>
            <FilteredDeposits filteredDeposits={filteredDeposits} system={systemData} rates={rates} />
        </>;
};

export default inject('depositsStore', 'p2pDepositsStore', 'userStore')(observer(P2pDeposit));
