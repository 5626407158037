import React from 'react';
import {Link} from 'react-router-dom';
import {eSportBetPath} from 'app/utils/esport';

export default class OpenBetLinkCover extends React.PureComponent {
    onClickHandler = e => {
        this.props.onClickHandler();
        e && e.preventDefault();
    };

    render() {
        const line = this.props.bet.parent || this.props.bet;
        return this.props.seo
            ? <Link to={eSportBetPath(line)} className={this.props.className}>{this.props.children}</Link>
            : <div className={this.props.className}>{this.props.children}</div>;
    }
}
