import React, {Component} from 'react';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {modalConfirm} from 'app/utils/modals/popups';
import {findUserOrEmail} from 'app/components/promo_action/gift/SendForm';
import I18n from 'app/i18n';
import {artifactStore, modalsStore} from 'app/store/global';
import {artifactName} from './utils';
import {SimpleArtifactImage} from './image';
import {ajaxError} from 'app/utils';
import HeroSlotCount from 'app/utils/hero/hero_slot_count';
import InventoryItemDescription from 'app/components/my_profile/artifacts/popup/inventory_item_description';
import SmartLoader from 'app/components/reusable/SmartLoader';
import NotificationStore from 'app/store/notifications';

const MAX_QUANTITY_LIMIT = 100;

export const inputClassNames = ({checked_name}) => {
    const classes = ['field__input'];
    classes.push(checked_name ? 'field__input--exist-state' : 'field__input--inexist-state');
    return classes.join(' ');
};

export const buttonClassNames = ({checked_name, checked_quantity, input_empty}) => {
    const classes = ['btn', 'btn--big-on-mobile'];

    if (!checked_name || !checked_quantity || input_empty) {
        classes.push('btn--disable');
    }
    return classes.join(' ');
};

const MAX_USER_NAME_LENGTH = 32;

export default class SendArtifactPopup extends Component {
    state = {
        checked_name: false,
        checked_quantity: true,
        error: null,
        input_empty: false,
        loading: false,
        quantity: '1',
        user: ''
    };

    changeUserHandler = e => {
        const {target: {value}} = e;

        if (value.length <= MAX_USER_NAME_LENGTH) {
            this.setState({user: value});
            findUserOrEmail(value, success => this.setState({checked_name: success}));
        }
    };

    sendArtifact = e => {
        const {artifact} = this.props;
        const {checked_name, checked_quantity, user} = this.state;
        const quantity = this.state.quantity.replace(/^0+/u, '');

        e && e.preventDefault();
        if (!checked_name || !checked_quantity) {
            return;
        }

        modalConfirm(I18n.t('send_artifact_confirm', {
            artifact: artifactName(artifact),
            quantity,
            user
        }),
        () => {
            artifactStore.sendToUser(artifact, user, quantity, () => {
                modalsStore.hideModal(this.props.popupId);
                NotificationStore.add({
                    artifact_name: artifactName(artifact),
                    artifacts_count: quantity,
                    receiver: user,
                    type: 'send_artifact_success'
                });
            }, ajaxError);
            this.setState({loading: true});
        },
        () => {
            this.setState({loading: false});
        });
    };

    get quantityOfItemsText() {
        return `${I18n.t('quantity_of_items')} (${I18n.t('quantity_limit', {count: MAX_QUANTITY_LIMIT})})`;
    }

    get youHaveItemsText() {
        const {artifact: {total_inventory_items, total_sending_prohibited_items}} = this.props;

        return I18n.t(
            'you_have_available_items',
            {count: total_inventory_items - total_sending_prohibited_items}
        );
    }

    inputIsEmpty = input => input.length === 0 || input === '0' || input === '00' || input === '000';

    handlePaste = event => {
        if (event.clipboardData.getData('text').match(/[1-9][0-9]{0,2}$/gui)) {
            this.setState({
                quantity: event.clipboardData.getData('text')
            });
        } else {
            event.preventDefault();
        }
    };

    changeItemsCountHandler = e => {
        const {value} = e.target;

        if (value.match(/^\D+$/gu)) {
            return;
        }

        const {artifact: {total_inventory_items, total_sending_prohibited_items}} = this.props;
        const check = value > total_inventory_items - total_sending_prohibited_items || value > MAX_QUANTITY_LIMIT;
        this.setState({
            checked_quantity: !check,
            input_empty: this.inputIsEmpty(value),
            quantity: e.target.validity.valid ? value : this.state.quantity
        });
    };


    render() {
        const {artifact} = this.props;
        const {checked_name, checked_quantity, error, user, quantity, loading, input_empty} = this.state;
        const sign = checked_name ? '#svg-check' : '#svg-close';

        return <PopupDialog id={this.props.popupId} setFocus={true}>
            <div className="popup__content popup--send-gift">
                <h2 className="popup__header">{I18n.t('send_as_gift')}</h2>
                <div className="send-gift__popup">
                    <div className="send-gift__popup--description">
                        <div className="send-gift__popup--img-wrap">
                            <div className="send-gift__popup--img">
                                <SimpleArtifactImage artifact={artifact}/>
                                <HeroSlotCount {...artifact}/>
                            </div>
                        </div>
                        <div className="send-gift__popup--desc">
                            <div className="send-gift__popup--desc-title">
                                {artifactName(artifact)}
                            </div>
                            <InventoryItemDescription {...artifact}/>
                        </div>
                    </div>
                    <form
                        className="send-gift__popup--form"
                        onSubmit={this.sendArtifact}
                    >
                        <div className="send-gift__popup--form-content">
                            <div className="send-gift__popup--form__item">
                                <div className="send-gift__popup--form__item-wrap">
                                    <label className="label-fee">
                                        <span>{I18n.t('please_enter_user_nickname')}</span>
                                    </label>
                                    <div className="field">
                                        <div className="field__wrap">
                                            <input
                                                type="text"
                                                name="nickname"
                                                id="nickname"
                                                className={inputClassNames(this.state)}
                                                value={user}
                                                onChange={this.changeUserHandler}
                                            />
                                            <span className="field__state">
                                                <svg height="12px" width="12px">
                                                    <use xlinkHref={sign}/>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="send-gift__popup--form__item">
                                <div className="send-gift__popup--form__item-wrap">
                                    <label className="label-fee">
                                        <span>{this.quantityOfItemsText}</span>
                                    </label>
                                    <div className="field">
                                        <div className="field__wrap">
                                            <input
                                                type="text"
                                                name="quantity_of_items"
                                                id="quantity_of_items"
                                                maxLength="3"
                                                pattern="[1-9][0-9]{0,2}$"
                                                value={quantity}
                                                className="field__input"
                                                onChange={this.changeItemsCountHandler}
                                                onPaste={this.handlePaste}
                                            />
                                            <div className="field__icon">
                                                <svg height="12px" width="12px">
                                                    <use xlinkHref={'#svg-inventory-count'}/>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <label className="inventory-count-edit__popup--form-hint">
                                        {this.youHaveItemsText}
                                    </label>
                                    <div className="field__error-hint">{error}</div>
                                </div>
                            </div>
                        </div>
                        <div className="send-gift__popup--form-footer">
                            <div className="send-gift__popup--actions">
                                {loading
                                    ? <SmartLoader loaderClassName="button-adaptive smart-loader_theme-dark"/>
                                    : <button
                                        className={buttonClassNames(this.state)}
                                        disabled={!checked_name || input_empty || !checked_quantity }
                                    >
                                        {I18n.t('send_gift')}
                                    </button>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </PopupDialog>;
    }
}
