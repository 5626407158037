import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {toastSuccess} from 'app/components/toasts/liteToast';
import {CopyToClipboard} from 'react-copy-to-clipboard';

interface CopySharingLinkProps {
    link: string
}

export default class CopySharingLink extends PureComponent<CopySharingLinkProps> {
    onClickCopyMessageCall = (): void => {
        toastSuccess(I18n.t('referral_copy_notification'));
    };

    render(): React.ReactNode {
        const {link} = this.props;

        return <div className="copy-sharing-link">
            <div className="copy-sharing-link__adds">
                <svg className="svg-default">
                    <use xlinkHref="#svg-copy-link-icon"/>
                </svg>
                <span className="copy-sharing-link__adds-link">{link}</span>
            </div>
            <CopyToClipboard text={link} onCopy={this.onClickCopyMessageCall}>
                <button className="button-adaptive">{I18n.t('share_link')}</button>
            </CopyToClipboard>
        </div>;
    }
}
