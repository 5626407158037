import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import {getPromoLinkPath} from 'app/utils';
import {i18n_t} from 'app/i18n';

interface AsideLinksProps {
    predictions_available: number
    prediction_points: number
}

export default class AsideLinks extends PureComponent<AsideLinksProps> {
    render(): React.ReactNode {
        const {prediction_points, predictions_available} = this.props;

        return <div className="compendium-aside__links">
            <span>
                <Link
                    to={getPromoLinkPath('/predictions')}
                >
                    {`${i18n_t('compendium.compendium_table')} (${predictions_available})`}
                </Link>
            </span>
            <span>
                <Link
                    to={getPromoLinkPath('/my_predictions')}
                >
                    {i18n_t('compendium.my_predictions', {count: prediction_points})}
                </Link>
            </span>
            <span>
                <Link
                    to={getPromoLinkPath('/rating')}
                >
                    {i18n_t('compendium.results_table')}
                </Link>
            </span>
        </div>;
    }
}
