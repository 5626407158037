import React, {Component, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import BetSlipController from 'app/components/bet_slip/BetSlipController';
import {BetSlipStore} from 'app/store/BetSlipStore';
import BetSlipMinimize from 'app/components/bet_slip/minimize_bet_slip/BetSlipMinimize';

interface BetSlipMinimizeProps {
    controller?: BetSlipController
    betSlipStore?: BetSlipStore
}

@inject('controller', 'betSlipStore')
@observer
export default class BetSlipMinimizeContainer extends Component<BetSlipMinimizeProps> {
    handleMaximize = (): void => this.props.controller.setMinimize(false);

    render(): ReactNode {
        const {betSlipStore: {warning, getCoef,
            coefIncreasedForm, showCoefInList}, controller: {bets}} = this.props;
        const hasWarning = Boolean(!showCoefInList && warning);
        return <BetSlipMinimize
            coef={getCoef()}
            coefIncreased={coefIncreasedForm}
            handleMaximize={this.handleMaximize}
            warning={hasWarning}
            count={showCoefInList && bets ? bets.length : 1}
            single={!showCoefInList}
        />;
    }
}
