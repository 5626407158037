import BetSlip from 'app/components/bet_slip/BetSlip';
import {BaseStrategy} from 'app/components/bet_slip/strategies/Base.strategy';
import I18n from 'app/i18n';
import LineInterface from 'app/interfaces/LineInterface';
import MakeBetInterface from 'app/interfaces/MakeBetInterface';
import MakeBetParamsInterface from 'app/interfaces/MakeBetParamsInterface';
import {checkOwnLine} from 'app/lines/checkOwnLine';
import {gamerCoef} from 'app/lines/gamerCoef';
import {isPlayMoney} from 'app/lines/isPlayMoney';
import {lineLimit} from 'app/lines/lineLimit';
import {STATUS_OPENED} from 'app/utils/Consts';
import {fixed2, fixed3} from 'app/utils/fixed';
import {ParamsInterface} from 'app/utils/Get';

export class BetStrategy extends BaseStrategy {
    get postUrl(): string {
        return '/bets';
    }

    betSlipDidUpdate = (betSlip: BetSlip): void => {
        const {setLimitBetSlip} = this.betSlipController;

        if (!betSlip || this.checkEmptySelectedLine()) {
            return;
        }
        if (this.checkNewOrClear()) {
            return;
        }
        this.betSlipController.strategy.setCoefSingle(true);
        setLimitBetSlip();
    };

    setFormCoefSingle = (): void => {
        super.setFormCoef();
    };

    maxWin = (): number => {
        const {getMultipleBetLimit} = this.betSlipController;

        const {limit, type} = this.betSlipStore;
        return getMultipleBetLimit && getMultipleBetLimit(type) || limit;
    };

    makeBetParams(params: MakeBetParamsInterface): MakeBetInterface {
        const {amount, coef, changeAmount, loading, setLoading, type, incubatorBetId} = params;
        const {selectedLine, num} = this.betSlipStore;
        const artifactsToBet = this.artifactBetStore.quantifiedArtifactsToBet();

        return {
            amount,
            artifactsToBet,
            changeAmount,
            coef,
            express: false,
            incubatorBetId,
            line: selectedLine,
            loading,
            num,
            setLoading,
            type
        };
    }

    placeBetSuccess(params: MakeBetInterface): void {
        this.betSlipController.placeBetSingleSuccess(params);
        this.betSlipController.logEventBet(params);
    }

    getParams = (params: MakeBetInterface): ParamsInterface => {
        const {amount, artifactsToBet, coef, incubatorBetId, line, num, type} = params;
        return {
            bet: {
                amount: fixed2(amount),
                bet_recipes: artifactsToBet,
                coef,
                id: line.id,
                incubator_bet_id: incubatorBetId,
                on: num,
                playmoney: isPlayMoney(type)
            }
        };
    };

    removeOnclick(): void {
        this.betSlipStore.setShowForm(false);
        this.betSlipStore.reset();
    }

    getCoefSingle = (): string => {
        const {incubator2WithCoef, selectedLine, num} = this.betSlipStore;
        return fixed3(incubator2WithCoef
            ? incubator2WithCoef as string
            : String(gamerCoef(selectedLine, num)));
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setCoef = (coef: string): void => null;

    getLimit = (type: string): number => {
        const {selectedLine, num} = this.betSlipStore;

        return Number(lineLimit(selectedLine, isPlayMoney(type), num));
    };

    bets(): {num: number, rootLine: LineInterface, selectedLine: LineInterface}[] {
        const {num, rootLine, selectedLine} = this.betSlipStore;

        return [{num, rootLine, selectedLine}];
    }

    private checkEmptySelectedLine = (): boolean => {
        const {setWarning} = this.betSlipController;
        const warning = I18n.t('bet_unavailable');
        const {selectedLine} = this.betSlipController.betSlipStore;

        if (!selectedLine) {
            setWarning(warning);
            return true;
        }
        setWarning(selectedLine.status === STATUS_OPENED ? checkOwnLine(selectedLine.isCreator) : warning);

        return false;
    };

    private checkNewOrClear = (): boolean => {
        const {
            coefIncreased, coefIncreasedForm, setCoefIncreasedForm
        } = this.betSlipStore;

        const isCoefIncreased = coefIncreasedForm !== coefIncreased;

        if (this.checkClearForm() || this.checkNewForm() || isCoefIncreased) {
            this.betSlipController.strategy.setCoefSingle(isCoefIncreased, true);
            this.betSlipController.setLimitBetSlip();
            if (isCoefIncreased) {
                setCoefIncreasedForm(coefIncreased);
            }
            return true;
        }
        return false;
    };

    private checkClearForm = (): boolean => {
        const {artifactBetStore, betSlipStore, clearForm} = this.betSlipController;

        if (betSlipStore.clearForm) {
            clearForm();
            betSlipStore.setClearForm(false);
            artifactBetStore.resetArtifacts();
            return true;
        }
        return false;
    };

    private checkNewForm = (): boolean => {
        const {artifactBetStore, betSlipStore, clearForm, clearType} = this.betSlipController;

        if (betSlipStore.newForm) {
            clearForm();
            clearType();
            betSlipStore.setNewForm(false);
            artifactBetStore.resetArtifacts();
            return true;
        }
        return false;
    };
}
