import {inject, observer} from 'mobx-react';
import React, {Component, ReactNode} from 'react';
import {StoriesStoreProps} from 'app/store/StoriesStore';
import {StoriesSlider, Stories, Story} from 'app/components/stories/stories-slider-react';
import Swiper from 'swiper';
import {EffectCube, Lazy} from 'swiper/modules';
import SmartStoryImagePopup from './SmartStoryImagePopup';
import {selectTypeStats} from 'app/utils/stories/selectTypeStats';
import {ELEMENT_NOT_FOUND} from 'app/utils/Consts';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import StoryImageInterface from 'app/interfaces/StoryImageInterface';
import StoryInterface from 'app/interfaces/StoryInterface';
import {isDesktop} from 'app/utils';
import {modalsStore} from 'app/store/global';

const AUTOPLAY_TIME = 7000;

interface StoriesPopupState {
    countStories: number
    currentIndex: number
    currentIndexOpen: number
    currentStoryId: number
    loading: boolean
    subCurrentIndex: number
}

interface StoriesPopupInterface {
    popupId: string
}

@inject('storiesStore')
@observer
export default class StoriesPopup extends Component<StoriesPopupInterface & StoriesStoreProps, StoriesPopupState> {
    constructor(props: StoriesPopupInterface & StoriesStoreProps) {
        super(props);
        const {openStories, findIndexOpenStoryById, currentStoryId} = props.storiesStore;
        this.state = {
            countStories: openStories.length,
            currentIndex: findIndexOpenStoryById(this.props.storiesStore.currentStoryId),
            currentIndexOpen: ELEMENT_NOT_FOUND,
            currentStoryId,
            loading: true,
            subCurrentIndex: 0
        };
    }

    storiesSlider = React.createRef();

    onSlidesIndexesChange = (mainIndex: number, subIndex: number): void => {
        const {openStories, setCurrentStoryId} = this.props.storiesStore;
        const story = openStories[mainIndex];

        if (story) {
            setCurrentStoryId(story.id);
            this.setLoadedSlider(!story.story_images[subIndex].loaded);
            this.setState({currentIndex: mainIndex,
                currentIndexOpen: mainIndex,
                currentStoryId: story.id,
                subCurrentIndex: subIndex});
            this.sendView(story);
            this.sendViewImage(story, subIndex);
        }
    };

    sendView = (story: StoryInterface): void => this.props.storiesStore.viewUserStory(story.id);

    sendViewImage = (story: StoryInterface, index: number): void => {
        const story_image: StoryImageInterface = story.story_images[index];

        if (!story_image) {
            return;
        }
        this.props.storiesStore.viewUserStoryImage(story_image.id, story.id);
    };

    openStory = (userIndex: number): void => {
        const {currentIndexOpen, currentIndex} = this.state;

        if (currentIndex === currentIndexOpen) {
            return;
        }
        this.slideToStory(userIndex, 0);
    };

    slideToStory = (storyIndex: number, storyImageIndex: number, loading = true): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this.storiesSlider && this.storiesSlider.el) {
            if (loading) {
                const {openStories} = this.props.storiesStore;
                this.setLoadedSlider(openStories[storyIndex]
                    ? !openStories[storyIndex].story_images[storyImageIndex].loaded
                    : true);
            } else {
                this.setLoadedSlider(loading);
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.storiesSlider.slideTo(storyIndex, storyImageIndex);
        }
    };

    setLoadedSlider = (loading = true): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.storiesSlider && this.storiesSlider.el && this.storiesSlider.setLoaded(loading);
    };

    closePopup = (): void => {
        this.props.storiesStore.updateOnboardingStories();
        modalsStore.hideModal(this.props.popupId);
    };

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    onStoriesSlider = (instance): void => {
        this.storiesSlider = instance;
        const {loading, currentIndex} = this.state;

        if (!loading) {
            this.openStory(currentIndex);
        }
    };

    onStart = (storyIndex: number, storyImageIndex: number): void => {
        this.setState({loading: false});
        const {currentIndex, subCurrentIndex} = this.state;
        this.openStory(currentIndex);

        if (currentIndex === storyIndex && subCurrentIndex === storyImageIndex) {
            this.slideToStory(currentIndex, subCurrentIndex, false);
        }
    };

    onNext = (): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this.storiesSlider && this.storiesSlider.slideNext) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.storiesSlider.slideNext();
        }
    };

    onPrev = (): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (this.storiesSlider && this.storiesSlider.slidePrev) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.storiesSlider.slidePrev();
        }
    };

    render(): ReactNode {
        const {storiesStore, popupId} = this.props;
        const {currentIndex, subCurrentIndex} = this.state;
        const first = currentIndex === 0 && subCurrentIndex === 0;
        return <PopupDialog fullscreen id={popupId}>
            <span className={`stories-slider-prev${first ? ' first-slide' : ''}`} onClick={this.onPrev}>
                <svg className="svg-default">
                    <use xlinkHref="#svg-button-next-slide"/>
                </svg>
            </span>
            <StoriesSlider
                className="stories-slider-popup"
                Swiper={Swiper}
                EffectCube={isDesktop() ? EffectCube : null}
                Lazy={Lazy}
                enabled={false}
                autoplayDuration={AUTOPLAY_TIME}
                onSlidesIndexesChange={this.onSlidesIndexesChange}
                onStoriesSlider={this.onStoriesSlider}
                onEnd={this.closePopup}
            >
                { storiesStore.openStories.map((story, storyIndex) => <Stories key={storyIndex}>
                    {story.story_images.map((storyImage, storyImageIndex) => <Story
                        key={storyImageIndex}
                        name={story.name}
                    >
                        {storyImage.video
                            ? <video src={storyImage.video} playsInline preload="metadata" />
                            : <SmartStoryImagePopup
                                storyIndex={storyIndex}
                                storyImageIndex={storyImageIndex}
                                story={story}
                                story_image={storyImage}
                                statsType={selectTypeStats(story.monthly_stats, story.year_stats)}
                                imageStatsType={storyImageIndex}
                                onImageLoad={this.onStart}
                                onNextStory={this.onNext}/>
                        }
                    </Story>)}
                </Stories>)}
            </StoriesSlider>
            <span className="stories-slider-next" onClick={this.onNext}>
                <svg className="svg-default">
                    <use xlinkHref="#svg-button-next-slide"/>
                </svg>
            </span>
        </PopupDialog>;
    }
}
