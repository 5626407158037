import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import I18n from 'app/i18n';
import CountDownPromo from './count_down_promo';
import {fixed2, getLinkPath, TimeToStartTimerOld, userSignedIn} from 'app/utils';
import {BEER_IMAGE} from 'app/consts';
import AsideHeader from 'app/modules/AsideHeader';
import nextPrizeProgress from './modules/next_prize_progress';
import FAQButton from './modules/FAQButton';
import {promoActionStore} from 'app/store/global';
import {Provider} from 'mobx-react';
import {goToMyHero} from 'app/utils/app_links';
import {getPromoUrl} from 'app/utils/getPromoUrl';
import CheersButton from './CheersButton';

export default class PromoActionBeer extends CountDownPromo {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            time_to_start: props.time_to_start
        };
    }

    static imagePropName = 'PROMO_ACTION_BEER';

    UNSAFE_componentWillMount() {
        this.isMountedComponent = true;
    }

    componentDidMount() {
        super.componentDidMount();
    }

    componentDidUpdate(nextProps, nextState) {
        super.componentDidUpdate(nextProps, nextState);
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.isMountedComponent = false;
    }

    promoActionTitle = () => <Fragment>
        <span>
            {I18n.t('st_patrick_header')}
        </span>
        <FAQButton FAQPath="/faq/stpatrick"/>
    </Fragment>;

    get imagePropName() {
        return this.constructor.imagePropName;
    }

    beerInnerComponent = () => <div className="beer-glass__inner">{this.beerGlassParts()}</div>;

    beerGlassParts = () => <Fragment>
        <div className="beer-glass__background">
            <img src={BEER_IMAGE[this.imagePropName].BACKGROUND} alt="Beer background"/>
        </div>
        <div className="beer-glass__vessel">
            <img src={BEER_IMAGE[this.imagePropName].VESSEL} alt="Beer vessel"/>
        </div>
        <div style={{bottom: `${this.nextPrizeProgress()}%`}} className="beer-glass__liquid">
            <img src={BEER_IMAGE[this.imagePropName].LIQUID} alt="Beer liquid"/>
        </div>
    </Fragment>;

    nextPrizeProgress = () => nextPrizeProgress({
        ...this.props,
        isStarted: this.isStarted
    });

    static get promoUrl() {
        const {promoAction} = promoActionStore;

        return `${getPromoUrl()}/${promoAction.url}`;
    }

    render() {
        const ConvertButton = promoActionStore.convertButton;
        const {conversion_available} = promoActionStore.promoAction;

        return <AsideHeader
            cookieName="beer-menu"
            title={this.promoActionTitle()}
            isStarted={this.isStarted}
            componentName="beer">
            <div className="promo-aside">
                <div className="promo-aside__content beer-aside__content">
                    <CheersButton
                        {...this.state}
                        imagePropName={this.imagePropName}
                        isStarted={this.isStarted}
                    />
                    <div className="beer-aside__beer-glass">
                        <div className="beer-glass">
                            <Link to={getLinkPath(this.constructor.promoUrl)}>
                                {this.beerInnerComponent()}
                            </Link>
                        </div>
                    </div>
                    {this.isStarted() && userSignedIn() && <Fragment>
                        <div className="beer-aside__together">
                            <span className="user__balance">
                                {fixed2(this.nextPrizeProgress())}%
                            </span>
                            <div className="beer-aside__count">
                                x{this.props.count}
                            </div>
                        </div>
                    </Fragment>}
                    {!this.isStarted() && <TimeToStartTimerOld {...this.state}/>}
                </div>
                {userSignedIn() && this.isStarted() && <div className="promo-aside__footer beer-aside__footer">
                    <Provider promoActionStore={promoActionStore}>
                        <ConvertButton onClickHandler={goToMyHero}/>
                    </Provider>
                    <div className="color-gray-light">
                        {I18n.t(`${promoActionStore.i18nKey}.${conversion_available
                            ? 'scatter.promo_text'
                            : 'scatter.once_a_day'}`)}
                    </div>
                </div>}
            </div>

        </AsideHeader>;
    }
}
