import React, {Component, ReactNode} from 'react';
import SmartLoader from 'app/components/reusable/SmartLoader';
import {inject, observer} from 'mobx-react';
import {BetSlipStore} from 'app/store/BetSlipStore';

interface BetSlipLoadingProps {
    betSlipStore?: BetSlipStore
}

@inject('betSlipStore')
@observer
export default class BetSlipLoading extends Component<BetSlipLoadingProps> {
    render(): ReactNode {
        const {warning, loadingForm} = this.props.betSlipStore;
        const showLoader = !warning || loadingForm;
        return <div className="bet-slip-loading">
            {showLoader && <SmartLoader loaderClassName="bet-slip-loader"/>}
            <div className="box-first">
                <span className="lg"/>
                <div className="b">
                    <span className="d-1"/>
                    <div className="c-1">
                        <span className="i-1"/>
                        <span className="t-1"/>
                    </div>
                </div>
            </div>
            <div className="box-second">
                <div className="c-1">
                    <div className="b-l">
                        <span className="l"/>
                    </div>
                    <span className="v"/>
                </div>
                <div className="c-1">
                    <span className="c"/>
                </div>
                <div className="c-1">
                    <div className="b-l">
                        <span className="l"/>
                    </div>
                    <span className="v"/>
                </div>
            </div>
            <div className="box-third">
                <div className="l-1">
                    <div className="s-1">
                        <span className="a-1"/>
                    </div>
                    <div className="s-1">
                        <span className="a-1"/>
                    </div>
                    <div className="s-1">
                        <span className="a-1"/>
                    </div>
                </div>
                <div className="l-1">
                    <div className="s-2">
                        <span className="a-1"/>
                        <span className="b-1"/>
                    </div>
                    <div className="s-2">
                        <span className="a-1"/>
                        <span className="b-1"/>
                    </div>
                </div>
            </div>
        </div>;
    }
}
