import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';

export default class DisabledCasino extends PureComponent {
    render(): ReactNode {
        return <div className="casino-page">
            <div className="search-no-found casino-games">
                <span className="background-image"/>
                <div className="container-text">
                    <p dangerouslySetInnerHTML={{__html: I18n.t('casino_disabled')}}/>
                </div>
            </div>
        </div>;
    }
}
