import React, {ChangeEvent, FocusEvent, PureComponent, ReactNode} from 'react';
import {coefColorClass} from 'app/lines/coefColorClass';
import {isDesktop} from 'app/utils';

interface BetSlipInputAmountProps {
    active: boolean
    amount: string
    autoFocus: boolean
    onChange: (e: ChangeEvent<HTMLInputElement> | string) => void
    onFocus: (e: FocusEvent<HTMLInputElement>) => void
    coefIncreased: boolean
    id: string
}

export default class BetSlipInputCoef extends PureComponent<BetSlipInputAmountProps> {
    inputAmount: React.RefObject<HTMLInputElement> = React.createRef();

    setFocus = (): void => this.inputAmount.current.focus();

    render(): ReactNode {
        const {active, amount, autoFocus, coefIncreased, id, onFocus, onChange} = this.props;
        const coefClass = active ? 'active' : '';
        return <div className="cell-inner-box">
            <input id={id}
                autoComplete={'off'}
                type="string"
                step="0.01"
                name={id}
                maxLength={8}
                className={`input-number${coefColorClass(coefIncreased)} ${coefClass}`}
                autoFocus={autoFocus}
                placeholder="0.000"
                value={amount}
                onFocus={onFocus}
                onChange={onChange}
                readOnly={!isDesktop()}
                ref={this.inputAmount}/>
        </div>;
    }
}
