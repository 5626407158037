import React, {ChangeEvent, Component, FocusEvent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {BetSlipStore} from 'app/store/BetSlipStore';
import BetSlipInputAmount from 'app/components/bet_slip/amount_money/BetSlipInputAmount';
import BetSlipController from 'app/components/bet_slip/BetSlipController';
import {inject, observer} from 'mobx-react';
import {BetSlipFocus} from 'app/utils/Consts';

interface BetSlipInputAmountProps {
    betSlipStore?: BetSlipStore
    controller?: BetSlipController
}

@inject('betSlipStore', 'controller')
@observer
export default class BetSlipInputAmountContainer extends Component<BetSlipInputAmountProps> {
    inputAmount: React.RefObject<BetSlipInputAmount> = React.createRef();

    componentDidMount(): void {
        this.props.betSlipStore.setFocus(BetSlipFocus.AMOUNT);
    }

    handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
        this.props.controller.changeAmount(e.target.value);
    };

    setFocus = (): void => this.inputAmount.current.setFocus();

    handelOnFocus = (e: FocusEvent<HTMLInputElement>): void => {
        const val = e.target.value;
        e.target.value = '';
        e.target.value = val;
        this.props.betSlipStore.setFocus(BetSlipFocus.AMOUNT);
    };

    render(): ReactNode {
        const {amount, type, amountFocus} = this.props.betSlipStore;
        return <BetSlipInputAmount
            active={amountFocus}
            amount={amount}
            autoFocus
            onFocus={this.handelOnFocus}
            onChange={this.handleOnChange}
            coefIncreased={null}
            id="betSlipSum"
            ref={this.inputAmount}
            title={I18n.t('bet_amount')}
            type={type}
        />;
    }
}
