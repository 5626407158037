import React from 'react';
import LogoLinkAside from 'site_version/common/aside/LogoLinkAside';
import LogoClashSvg from 'images/svg_icons/image-clash-casino.svg';
import LogoTipsGGSvg from 'images/tipsgg/logo.png';
import BookmakerRating from 'site_version/common/aside/BookmakerRating';
import {userStore} from 'app/store/global';

const banners = (): React.ReactNode => {
    if (userStore.user.is_admin) {
        return <></>;
    }

    return <>
        <LogoLinkAside
            className="tips"
            link="https://tips.gg/"
            logo={LogoTipsGGSvg}
            logoAlt="Logo tipsgg"
        />
        <LogoLinkAside
            className="clash"
            link="https://clashofslots.com/casinos/egb-casino/"
            logo={LogoClashSvg}
            logoAlt="Logo clash"
        />
        <BookmakerRating/>
    </>;
};

export default banners;
