import React from 'react';
import PayzenCashoutDetails from 'app/components/cashout/PayzenCashoutDetails';
import FieldWrapInput from 'app/components/cashout/FieldWrapInput';
import FieldWrapSelectPicker from 'app/components/cashout/FieldWrapSelectPicker';

export default class ThailandCashoutDetails extends PayzenCashoutDetails {
    state = {
        bank_account_number: '',
        bank_id: '',
        customer_name: ''
    };

    get bankList() {
        const {china_banks = []} = this.props;
        return china_banks.filter(b => b.country_code === 'thailand' &&
            b.method === 'payout');
    }

    render() {
        const {disabled, errors} = this.props;

        return <div className="cashout__field field">
            <FieldWrapSelectPicker
                collection={this.bankList}
                value={this.state.bank_id}
                stateName="bank_id"
                attrValue="bank_id"
                translation_key="bank"
                attrDescription="description"
                handleOnChange={this.handleSelect}
                disabled={disabled}
                errors={errors}
            />

            <FieldWrapInput
                stateName="customer_name"
                handleOnChange={this.handleOnChange}
                value={this.state.customer_name}
                disabled={disabled}
                errors={errors}
            />

            <FieldWrapInput
                stateName="bank_account_number"
                handleOnChange={this.handleOnChange}
                value={this.state.bank_account_number}
                disabled={disabled}
                errors={errors}
            />
        </div>;
    }
}
