import {placeCaretAtEnd} from '../utils';
import $ from 'jquery';

const CHAT_BUFFER = [];

const appendToChat = message => {
    const chatInput = $('.chat__input-text');

    if (chatInput.length) {
        chatInput.text(`${chatInput.text()} ${message}`);
        placeCaretAtEnd(chatInput.get(0));
    } else {
        CHAT_BUFFER.push(message);
    }
};

export const lootboxToChat = id => appendToChat(`:l-${id}:`);

export const betToChat = id => appendToChat(`:${id}:`);

export const suggestionToChat = id => appendToChat(`:s-${id}:`);

export const expressToChat = id => appendToChat(`:e-${id}:`);

export const casinoLogToChat = id => appendToChat(`:cl-${id}:`);

export const bonusesDataToChat = () => appendToChat(':bonuses:');

export const appendToChatFromBuffer = () => {
    CHAT_BUFFER.forEach(appendToChat);
    CHAT_BUFFER.length = 0;
};
