import React, {PureComponent} from 'react';
import TakePartButton from './take_part_button';
import I18n from 'app/i18n';
import Prize from './new_prize';
import Place from 'app/components/compendium/place';

export default class PrizePool extends PureComponent {
    get prizes() {
        return this.props.prizes;
    }

    prize(number) {
        return this.prizes[number] || {
            image: {url: null},
            prizes: [{name: null}]
        };
    }

    place(section, prize) {
        return <Place section={section}
            promoClassName={this.promoClassName}
            PrizeClass={Prize} {...prize}
        />;
    }

    get promoClassName() {
        return this.props.promoClassName;
    }

    render() {
        const {promoClassName} = this;
        const {url, children, prizes} = this.props;
        return <div className={`prize-pool prize-pool__${promoClassName}`}>
            <div className="prize-title prize-title__first">
                <h2>{I18n.t('prizes_texts.prize_pool')}</h2>
            </div>
            <div
                className="prize-section prize-section_first">
                {this.place('first', prizes[0])}
                {this.place('first', prizes[1])}
                {this.place('first', prizes[2])}
            </div>
            <div
                className="prize-section prize-section_second">
                {this.place('second', prizes[3])}
                {this.place('second', prizes[4])}
                {this.place('second', prizes[5])}
                {this.place('second', prizes[6])}
            </div>
            <div className="prize-section take-a-part take-a-part_first">
                <TakePartButton promo_action_url={url}/>
            </div>
            <div
                className="prize-section prize-section_third">
                {this.place('third', prizes[7])}
                {this.place('third', prizes[8])}
                {this.place('third', prizes[9])}
                {this.place('third', prizes[10])}
                {this.place('third', prizes[11])}
                {this.place('third', prizes[12])}
                {this.place('third', prizes[13])}
                {this.place('third', prizes[14])}
                {this.place('third', prizes[15])}
            </div>
            <div className="prize-section take-a-part take-a-part_second">
                <TakePartButton promo_action_url={url}/>
            </div>
            <div
                className="prize-section prize-section_fourth">
                {this.place('fourth', prizes[16])}
                {this.place('fourth', prizes[17])}
                {this.place('fourth', prizes[18])}
                {this.place('fourth', prizes[19])}
                {this.place('fourth', prizes[20])}
                {this.place('fourth', prizes[21])}
                {this.place('fourth', prizes[22])}
                {this.place('fourth', prizes[23])}
            </div>
            <div
                className="prize-section prize-section_fourth">
                {this.place('fourth', prizes[24])}
                {this.place('fourth', prizes[25])}
                {this.place('fourth', prizes[26])}
                {this.place('fourth', prizes[27])}
                {this.place('fourth', prizes[28])}
                {this.place('fourth', prizes[29])}
                {this.place('fourth', prizes[30])}
                {this.place('fourth', prizes[31])}
                {this.place('fourth', prizes[32])}
                {this.place('fourth', prizes[33])}
                {this.place('fourth', prizes[34])}
                {this.place('fourth', prizes[35])}
                {this.place('fourth', prizes[36])}
                {this.place('fourth', prizes[37])}
                {this.place('fourth', prizes[38])}
            </div>
            {children}
        </div>;
    }
}
