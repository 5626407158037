import React, {Fragment, PureComponent} from 'react';
import I18n from 'app/i18n';
import {ArtifactImage} from 'app/components/my_profile/artifacts/image';
import {translatedName} from 'app/utils/translations_helper';
import ActionButton from 'app/components/my_profile/artifacts/action_button';
import {artifactStore} from 'app/store/global';
import {modalAlert} from 'app/utils/modals/popups';

export default class PopupFooter extends PureComponent {
    state = {disassembling: false};

    disassemble = () => {
        const {publicView, close} = this.props;

        if (publicView()) {
            return;
        }

        artifactStore.disassemble(this.props,
            () => modalAlert(I18n.t('disassemble_success', {name: translatedName(this.props)})),
            close,
            error => {
                this.setState({disassemblingError: error});
            });
    };

    startDisassemble = () => {
        const {publicView} = this.props;

        if (publicView()) {
            return;
        }

        this.setState({
            disassembling: true,
            disassemblingError: false
        });
    };

    cancelDisassembling = () => {
        this.setState({
            disassembling: false,
            disassemblingError: false
        });
    };

    render() {
        const {components, craft_points} = this.props;
        const {disassembling, disassemblingError} = this.state;

        if (!components || components.length === 0) {
            return null;
        }

        return <div className="popup__footer">
            <div className="inventory__popup__footer">
                {!disassembling && <Fragment>
                    <p>{I18n.t('disassemble_to')}</p>
                    <div className="inventory__popup__footer--row">
                        <div className="inventory__popup__footer--items">
                            {components.map((c, i) => <div
                                key={`c${i}`}
                                className="inventory__popup__footer--item"
                            >
                                <div className="hero__slot">
                                    <ArtifactImage artifact={c}/>
                                </div>
                                <div className="inventory__popup__footer--item--title">{translatedName(c)}</div>
                            </div>)}
                        </div>
                        <div className="inventory__popup__footer--actions">
                            <ActionButton
                                {...this.props}
                                onClickHandler={this.startDisassemble}
                                btnText={I18n.t('disassemble')}
                                btnClass="btn--invert"
                            />
                        </div>
                    </div>
                </Fragment>}
                {disassembling && <div className="inventory__popup__footer--row">
                    {disassemblingError
                        ? <div
                            className="inventory__popup__footer--message inventory__popup__footer--message--error"
                        >
                            <svg height="32px" width="32px">
                                <use xlinkHref="#svg-warning"/>
                            </svg>
                            <div className="inventory__popup__footer--message--error--content">
                                <div className="inventory__popup__footer--message--error--title">
                                    {I18n.t('error')}
                                </div>
                                <p>{disassemblingError}</p>
                            </div>
                        </div>
                        : <div
                            className="inventory__popup__footer--message"
                            dangerouslySetInnerHTML={{
                                __html: I18n.t('disassemble_confirm',
                                    {points: `<span class="price">${craft_points}</span>`})
                            }}/>}
                    <div className="inventory__popup__footer--actions">
                        <ActionButton
                            {...this.props}
                            onClickHandler={this.disassemble}
                            btnText={I18n.t('confirm_button_yes')}
                        />
                        <ActionButton
                            {...this.props}
                            onClickHandler={this.cancelDisassembling}
                            btnText={I18n.t('confirm_button_no')}
                            btnClass="btn--invert"
                        />
                    </div>
                </div>}
            </div>
        </div>;
    }
}
