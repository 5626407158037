import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';
import React, {PureComponent} from 'react';

interface CustomEWalletProps {
    selectedSystem: PaymentSystemInterface
    onChange: (value) => void
    value: string
}

export default class CustomEWallet extends PureComponent<CustomEWalletProps> {
    get isDisabled(): boolean {
        return !this.props.selectedSystem.input_wallet;
    }

    updateAccount(newValue: string): void {
        const {onChange} = this.props;
        onChange(newValue);
    }

    onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.updateAccount(e.target.value);
    };

    onKeyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        const target = e.target as HTMLInputElement;
        this.updateAccount(target.value);
    };

    render(): React.ReactNode {
        const {value, selectedSystem: {wallet_placeholder}} = this.props;
        return <input
            name="wallet.account"
            onKeyUp={this.onKeyUpHandler}
            onChange={this.onChangeHandler}
            disabled={this.isDisabled}
            placeholder={wallet_placeholder}
            className="field__input field__input_text field-number__input"
            value={value}
        />;
    }
}
