import React from 'react';
import I18n from 'app/i18n';
import {date, time} from 'app/utils';
import Row from './index';
import OpenBetLinkCover from './open_bet_link_cover';
import {GAMER_1, GAMER_2} from 'app/lines/line_helpers';

export default class AdvantageLine extends Row {
    renderDate() {
        const {bet} = this.props;
        return bet.live
            ? <div className="table-bets__live">
                <span className="table-bets__live-text">{I18n.t('live_label')}</span>
            </div>
            : <div className="table-bets__date">
                <span>{date(bet.date)}</span>
                <span>{time(bet.date)}</span>
            </div>;
    }

    isActive(on) {
        const active = typeof this.props.gamerActiveCoef === 'undefined' ? null : this.props.gamerActiveCoef;
        return active && active.on === on;
    }

    renderAdvantageBody(on) {
        const {bet} = this.props;
        const colorClass = `table-bets__inner-row-holder holder--color${bet.color}`;

        return <div key={`advantage-body-${on}`} className="table-bets__inner1-row">

            <div className={colorClass}>
                <span className="table-expand table-expand--main table-bets__border-bottom"/>
                <div className="table-bets__content table-bets__border-bottom">
                    <div className="table-expand table-expand--inner">
                        <div className="table-expand__advantage-icon"/>
                    </div>
                    <div className="table-bets__content">
                        <OpenBetLinkCover className="table-bets__content-row" seo={this.props.seo}
                            bet={bet}>
                            <div className="table-bets__mobile-row">
                                {this.renderDate()}
                                <div className="table-bets__event">
                                    <span/>
                                </div>
                            </div>
                            <div className="table-bets__cols-holder">
                                {bet.gamer === GAMER_1
                                    ? this.renderGamer(on, false, this.isActive(on))
                                    : <div className="table-bets__col-1"/>}
                                {bet.gamer === GAMER_2
                                    ? this.renderGamer(on, false, this.isActive(on))
                                    : <div className="table-bets__col-3"/>}
                            </div>
                        </OpenBetLinkCover>
                        {this.renderStreamIcon()}
                        <div className="table-bets__stat">
                            <button className="button-stats-line">
                                <svg className="svg-default">
                                    <use xlinkHref="#svg-stat"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    render() {
        return [this.renderAdvantageBody(GAMER_1), this.renderAdvantageBody(GAMER_2)];
    }
}
