import React, {PureComponent} from 'react';
import {Provider} from 'mobx-react';
import BetForm from 'app/components/bet_form';
import {currentTimeStore} from 'stores/global';
import LineStore from 'app/store/line_store';
import {ArtifactBetStore} from 'app/store/ArtifactBetStore';

export default class LineProvider extends PureComponent {
    constructor(props) {
        super(props);
        const {bet, userBets, seo = false} = props;
        this.lineStore = new LineStore(bet, userBets, seo);
        this.artifactBetStore = new ArtifactBetStore();
    }

    componentWillUnmount() {
        this.lineStore.clear();
    }

    render() {
        const {formIdShow, gamerNum, afterBet, idBetShow, selectedBet, popupId} = this.props;
        return <Provider
            lineStore={this.lineStore}
            artifactBetStore={this.artifactBetStore}
            currentTimeStore={currentTimeStore}
        >
            <BetForm
                selectedBet={selectedBet}
                formIdShow={formIdShow}
                gamerNum={gamerNum}
                afterBet={afterBet}
                idBetShow={idBetShow}
                popupId={popupId}
            />
        </Provider>;
    }
}
