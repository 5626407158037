import {action, observable} from 'mobx';


export class CasinoFilterStore {
    constructor(filterGroupId: number) {
        this.filterGroupId = filterGroupId;
    }

    @observable hasMore = false;

    @observable loading = false;

    filterGroupId: number = null;

    page = 1;

    @action
    changeHasMore(value: boolean): void {
        this.hasMore = value;
    }

    @action
    changePageNumber(): void {
        this.page += 1;
    }

    @action
    setLoading(value: boolean): void {
        this.loading = value;
    }
}
