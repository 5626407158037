import $ from 'jquery';
import {streamStore} from 'app/store/global';
import {navigateGoTo} from 'app/utils/navigate/navigateGoTo';
import {locationQuery} from 'app/utils/navigate/location';

const MILLISECONDS_MULTIPLIER = 1000,
    TWO_MINUTES = 120;

export const streamMinimize = () => {
    const currentItem = $('.popup-stream__video');
    const currentItemParent = $('.popup-stream__video-container');
    currentItem.addClass('popup-stream__video--minimized').css({
        bottom: `${$(window).height() - $('.header').height() - currentItemParent.height()}px`,
        height: '475px',
        left: currentItemParent.offset().left,
        width: currentItemParent.width()
    }).animate({
        bottom: '5px',
        height: '200px',
        left: '5px',
        width: '350px'
    });
};

export const setStreamParam = stream => {
    if (stream && stream.id) {
        navigateGoTo(location.pathname, {...locationQuery(), stream: stream.id});
    }
};

export const scrollToBottomStream = () => {
    if (streamStore.scrollToBets) {
        const [divider] = $('#dividerStream');

        if (typeof divider !== 'undefined') {
            const yOffset = -$('.header').outerHeight();
            const y = divider.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y});
        }
        streamStore.setScrollToBet(false);
    }
};

export const showMoreStreams = component => {
    const config = {
        container: $('.js-streams-container', component),
        containerMaxHeight: $('.js-streams-maxheight', component),
        containerMinHeight: $('.js-streams-minheight', component),
        opener: $('.stream-list__more-button', component)
    };

    const opener = () => {
        if (config.containerMaxHeight.outerHeight() > 0) {
            const containerItemMargin = parseFloat(config.containerMinHeight.css('margin-bottom'));
            const SIZE_MULTIPLIER = 2;

            if (config.containerMaxHeight.outerHeight() >=
                (config.containerMinHeight.outerHeight() + containerItemMargin) * SIZE_MULTIPLIER) {
                config.opener.parent().css('display', 'flex');
            } else {
                config.opener.parent().css('display', 'none');
            }
        }
    };

    $(window).resize(opener);
    opener();
};

let updateImageLastUpdate = null;

export const setStreamsTimeout = () => setTimeout(updateImageLastUpdate, TWO_MINUTES * MILLISECONDS_MULTIPLIER);

updateImageLastUpdate = () => {
    streamStore.imageLastUpdateNew(new Date().getTime());
};
