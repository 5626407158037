import {getLinkPath} from 'app/utils/link_helper';
import {timeStampToDashDate} from 'app/utils';
import LineInterface from 'app/interfaces/LineInterface';

export const E_SPORT_MAIN_URL = '/esports';

export const lineTournament = (line: LineInterface): string => line.tourn;

const gameByFilterName = (line: LineInterface): string => {
    if (!line) {
        return '';
    }
    return line.game.replace(' ', '').replace(':', '').toLowerCase();
};

const betStringId = (line: LineInterface): string => {
    if (!line) {
        return '';
    }

    const date = timeStampToDashDate(line.date);
    return `${line.gamer_1.nick}-vs-${line.gamer_2.nick}-${line.tourn}-${date}-${line.hash_id}`
        .replace(/\W+/ug, '-').toLowerCase();
};

export const eSportBetPath = (line: LineInterface): string => {
    const game = gameByFilterName(line);
    return getLinkPath(`${E_SPORT_MAIN_URL}/bets/${game}/${betStringId(line)}`);
};
