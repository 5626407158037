import {BaseController} from 'app/controllers/BaseController';
import Post from 'app/utils/Post';
import {action} from 'mobx';
import {PokerStore} from 'app/store/PokerStore';
import {toastError} from 'app/components/toasts/liteToast';

export class PokerController extends BaseController {
    store: PokerStore;

    constructor(store: PokerStore) {
        super();
        this.store = store;
    }

    @action
    sitOut(): void {
        new Post({
            url: `/poker/tables/${this.store.currentTable.table_id}/sit_out`
        }).execute()
            .then(response => response.json())
            .then(response => {
                if (!response.success) {
                    toastError(response.message);
                }
            });
    }

    @action
    sitIn(table_id: string): void {
        this.make_action(table_id, 'sit_in');
    }

    @action
    check(table_id: string): void {
        this.make_action(table_id, 'check');
    }

    @action
    call(table_id: string): void {
        this.make_action(table_id, 'call');
    }

    @action
    fold(table_id: string): void {
        this.make_action(table_id, 'fold');
    }

    @action
    allIn(table_id: string): void {
        this.make_action(table_id, 'all_in');
    }

    @action
    bet(table_id: string, amount: string): void {
        this.make_action(table_id, 'bet', {amount});
    }

    @action
    make_action(table_id: string, action: string, params = {}): void {
        new Post({
            params,
            url: `/poker/tables/${table_id}/${action}`
        }).execute()
            .then(response => response.json())
            .then(response => {
                if (!response.success) {
                    toastError(response.message);
                }
            });
    }

    @action
    loadTable = (table_id: string): void => {
        new Post({
            params: {id: table_id},
            url: '/poker/tables'
        }).execute()
            .then(response => response.json())
            .then(response => {
                this.store.updateCurrentTable(response);
            });
    };
}
