import I18n from 'app/i18n';
import CollectionInterface from 'app/interfaces/CollectionInterface';
import React, {PureComponent, RefObject} from 'react';
import $ from 'jquery';
import isEqual from 'lodash/isEqual';

interface Props {
    value: string | string[] | number
    onChange: (value: React.ChangeEvent<HTMLSelectElement>) => void
    collection?: CollectionInterface[]
    disabled?: boolean
    name?: string
    translation_key?: string
    placeholder?: string
    children?: React.ReactNode
    className?: string
    multiple?: boolean
    placeholder_text_multiple?: string
    id?: string
}

export default class SelectPicker extends PureComponent<Props> {
    select: RefObject<HTMLSelectElement> = React.createRef();

    componentDidMount(): void {
        const {placeholder, placeholder_text_multiple} = this.props;
        $(this.select.current)
            .chosen({
                allow_single_deselect: true,
                display_disabled_options: false,
                no_results_text: I18n.t('no_results_match'),
                placeholder_text: placeholder,
                placeholder_text_multiple,
                search_contains: true
            })
            .change(this.handleOnChange);
    }

    componentDidUpdate = (prevProps: Props): void => {
        if (!isEqual(this.props.collection, prevProps.collection) ||
            !isEqual(this.props.children, prevProps.children) ||
            this.props.disabled !== prevProps.disabled ||
            this.props.value !== prevProps.value) {
            $(this.select.current).trigger('chosen:updated');
        }
    };

    handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        this.props.onChange(e);
        this.forceUpdate();
        $(this.select.current).trigger('chosen:updated');
    };

    render(): React.ReactNode {
        const {
            value,
            collection,
            disabled,
            name,
            placeholder,
            children,
            className,
            multiple,
            id
        } = this.props;
        return <div className="field__wrap">
            <select
                ref={this.select}
                className={className}
                value={value}
                disabled={disabled}
                name={name}
                multiple={multiple}
                onChange={this.handleOnChange}
                id={id}
            >

                {placeholder && <option disabled selected hidden>{placeholder}</option>}
                {children || collection.map(({value: v, text}) => <option
                    key={v}
                    value={v}
                >
                    {text}
                </option>)}
            </select>
        </div>;
    }
}
