import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {storiesStore} from 'app/store/global';
import {showPopup} from 'app/utils/modals/ModalsService';
import SmartStoryInterface from 'app/interfaces/SmartStoryInterface';
import StoriesPopup from 'app/components/pages/home_page/SmartStories/StoriesPopup';

export const openStoryPopup = (): void => {
    storiesStore.setOpenStories();
    showPopup('story-popup', props => <StoriesPopup popupId={props.popupId}/>);
};

@inject('storiesStore')
@observer
export default class SmartStory extends Component<SmartStoryInterface> {
    openPopup = (): void => {
        storiesStore.setCurrentStoryId(this.props.story.id);
        openStoryPopup();
    };

    render(): React.ReactNode {
        const {story} = this.props;
        const classViewed = storiesStore.viewedBasic(story) ? 'smart-story-item_viewed' : '';
        return <li onClick={this.openPopup}>
            <div className="wrapper-smart-story-item">
                <div className={`smart-story-item ${classViewed} effect-no-tap`}>
                    <div className="smart-story-item__image">
                        <img className="smart-story-item__image-preview"
                            src={story.image ? story.image.url : ''}
                            alt="preview"/>
                    </div>
                </div>
            </div>
        </li>;
    }
}
