import React from 'react';
import {observer} from 'mobx-react-lite';
import {inject} from 'mobx-react';

const ModalsContainer = props => <>
    {props.modalsStore.collection && props.modalsStore.collection.map(modal => {
        const PopupComponent = modal.Component;
        return <PopupComponent key={modal.key} popupId={modal.id}/>;
    })}
</>;

export default inject('modalsStore')(observer(ModalsContainer));

