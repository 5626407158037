import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {Link} from 'react-router-dom';
import {betMoney} from 'app/utils';
import {artifactDescription, artifactName, artifactShopLink} from 'app/components/my_profile/artifacts/utils';
import {SimpleArtifactImage} from 'app/components/my_profile/artifacts/image';
import DefaultSign from 'app/components/shop/DefaultSign';

export default class Recipe extends PureComponent {
    render() {
        const {start_price, max_order_price, quantity, artifact_price} = this.props;
        return <div className="market__item">
            <div className="market__item__name-quantity">
                <div className="market__item--img-wrap">
                    <div className="market__item--img">
                        <Link to={artifactShopLink(this.props)}>
                            <SimpleArtifactImage artifact={this.props}/>
                        </Link>
                    </div>
                </div>
                <div className="market__item--desc-quantity">
                    <div className="market__item--desc">
                        <div className="market__item--desc-title">
                            <Link to={artifactShopLink(this.props)}>{artifactName(this.props)}</Link>
                        </div>
                        <div className="market__item--desc-text">
                            {artifactDescription(this.props)}
                        </div>
                    </div>
                    <div className="market__item__quantity">
                        <span className="mobile-label">{I18n.t('quantity')}:</span>
                        {quantity || 0}
                    </div>
                </div>
            </div>
            <div className="market__item__price-col">
                <DefaultSign condition={quantity || max_order_price && max_order_price > 0} className="market__item__price">
                    <div className="market__item__sell-order">
                        <span className="market__item__label">{I18n.t('sell_order_from')}:</span>
                        <DefaultSign condition={quantity} className="market__item__price-value">
                            {betMoney({amount: start_price})}
                        </DefaultSign>
                    </div>
                    <div className="market__item__sell-order">
                        <span className="market__item__label">{I18n.t('buy_request_from')}:</span>
                        <DefaultSign condition={max_order_price && max_order_price > 0}
                            className="market__item__price-value">
                            {betMoney({amount: max_order_price})}
                        </DefaultSign>
                    </div>
                    <span className="market__item__label">{I18n.t('average_price')}:</span>
                    <DefaultSign condition={artifact_price && artifact_price > 0}
                        className="market__item__price-value">
                        {betMoney({amount: artifact_price})}
                    </DefaultSign>
                </DefaultSign>
            </div>
        </div>;
    }
}
