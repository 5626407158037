import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import DepositCard from './DepositCard';
import EmptyBgItemDeposit from './EmptyBgItemDeposit';
import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';
import AvailableP2pDeposit from 'app/interfaces/AvailableP2pDeposit';
import AvailableDepositsFilter from './AvailableDepositsFilter';
import {RatesType} from 'app/store/DepositsStore';

const EMPTY_DEPOSIT_ITEMS = 10;

interface FilteredDepositsProps {
    filteredDeposits: AvailableP2pDeposit[]
    system: PaymentSystemInterface
    rates: RatesType
}

export default class FilteredDeposits extends PureComponent<FilteredDepositsProps> {
    render(): ReactNode {
        const {filteredDeposits, system, rates} = this.props;
        return <div className="left-inner-padding deposit-cards">
            <AvailableDepositsFilter/>
            {filteredDeposits.length === 0
                ? <div className="box-list box-list_5 box-list box-list_no-result">
                    {Array.from({length: EMPTY_DEPOSIT_ITEMS}, (a, i) => <EmptyBgItemDeposit key={i}/>)}
                    <div className="box-message">
                        <p>{I18n.t('n_a_p2p_deposits')}</p>
                    </div>
                </div>
                : <div className="box-list box-list_5">
                    {filteredDeposits.map(d => <DepositCard
                        key={d.id}
                        deposit={d}
                        system={system}
                        rates={rates}
                    />)}
                </div>}
        </div>;
    }
}
