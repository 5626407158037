import LineInterface from 'app/interfaces/LineInterface';
import {BLUE, WHITE} from 'app/lines/line_helpers';
import getMomentDiffHours from 'app/utils/time/getMomentDiffHours';

const DAY = 24;

const is24hLine = (line: LineInterface, results: boolean): boolean => {
    const diff = getMomentDiffHours(line.date);

    if (!results) {
        return !(line.color === BLUE && Math.abs(diff) > DAY && diff < 0);
    }
    return !(line.color === WHITE && (Math.abs(diff) > DAY && diff > 0));
};

export default is24hLine;
