import React, {Component, ReactNode} from 'react';
import {IncubatorCreateLineController} from 'app/controllers/IncubatorCreateLineController';
import {inject, observer, Provider} from 'mobx-react';
import {UserStore} from 'app/store/UserStore';
import {IncubatorShiftController} from 'app/controllers/IncubatorShiftController';
import {IncubatorShiftStore} from 'app/store/IncubatorShiftStore';
import {goToRoute, isGuest} from 'app/utils';
import {linkSimpleBets} from 'app/utils/app_links';
import {Outlet} from 'react-router';
import withRouter from 'app/utils/navigate/customWithRouter';

interface Props {
    incubatorShiftStore?: IncubatorShiftStore
    title?: string
    userStore?: UserStore
    params?: {id?: string}
    children?: React.ReactNode
}

class Incubator extends Component<Props> {
    controller: IncubatorCreateLineController;

    shiftController: IncubatorShiftController;

    constructor(props: Props) {
        super(props);
        this.shiftController = new IncubatorShiftController(props.userStore, props.incubatorShiftStore);
        this.controller = new IncubatorCreateLineController(
            props.userStore,
            Number(props.params.id),
            this.shiftController
        );
    }

    componentDidMount(): void {
        this.props.incubatorShiftStore.listen();
        if (isGuest()) {
            goToRoute(linkSimpleBets());
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        const newId = Number(this.props.params.id);
        const prevId = Number(prevProps.params.id);

        if (newId > 0 && prevId !== newId) {
            this.controller.setNewLine(newId);
        }
    }

    render(): ReactNode {
        return <div className="left-inner-padding incubator">
            <Provider controller={this.controller} shiftController={this.shiftController}>
                <>
                    <Outlet/>
                </>
            </Provider>
        </div>;
    }
}

export default withRouter(inject('userStore', 'incubatorShiftStore')(observer(Incubator)));

