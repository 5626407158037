import React, {Component, Fragment, ReactNode} from 'react';
import I18n from 'app/i18n';
import Checkbox from 'app/components/auth/Checkbox';
import P2pDepositsStoreProps from 'app/store/P2pDepositsStoreProps';
import {inject, observer} from 'mobx-react';
import {betMoney, remainingTimeUntil15Minutes, upToMinutes} from 'app/utils';
import {hideLastPopup} from 'app/utils/modals/ModalsService';
import {modalConfirm} from 'app/utils/modals/popups';
import {isoCurrencyToHtml} from '../depositExchangeAmount';
import {translatedKeyLangs} from 'app/utils/translations_helper';
import bankLogotypeSvg from 'images/p2p/bank-logotype.svg';
import ScreenshotPayment from './ScreenshotPayment';
import CopyButton from 'app/components/deposit/p2p/CopyButton';
import ScreenshotLoader from './ScreenshotLoader';
import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';
import {DepositsStoreProps} from 'app/store/DepositsStore';
import {roundByCurrency} from 'app/utils/deposits/roundByCurrency';

interface MinutesSeconds {
    minutes: number,
    seconds: number
}

interface CurrentDepositState {
    remainingTime: MinutesSeconds,
    transferred: boolean,
    show_modal: boolean
}

interface CurrentDepositProps extends P2pDepositsStoreProps, DepositsStoreProps {
    system: PaymentSystemInterface
}

const DEFAULT_MINUTES = 15;
const EXTRA_MINUTES = 30;
const HALF_A_SECOND = 500;

@inject('depositsStore', 'p2pDepositsStore')
@observer
export default class CurrentDeposit extends Component<CurrentDepositProps> {
    state: CurrentDepositState = {
        remainingTime: {
            minutes: DEFAULT_MINUTES,
            seconds: 0
        },
        show_modal: false,
        transferred: false
    };

    interval = null;

    componentDidMount = (): void => {
        if (remainingTimeUntil15Minutes(this.props.p2pDepositsStore.current_p2p_deposit.accepted_at)) {
            this.setState({show_modal: true});
        }
        if (this.updateRemainingTime()) {
            this.interval = setInterval(this.updateRemainingTime, HALF_A_SECOND);
        }
    };

    componentWillUnmount = (): void => {
        hideLastPopup('modal-confirm');
        clearInterval(this.interval);
        this.props.p2pDepositsStore.update(null, null, null);
        this.props.depositsStore.resetInputs();
    };

    updateRemainingTime = (): boolean => {
        const {current_p2p_deposit: {waiting, not_valid, accepted_at}} = this.props.p2pDepositsStore;

        if (waiting || not_valid) {
            return false;
        }

        const remainingTime = upToMinutes(accepted_at, DEFAULT_MINUTES) as unknown as MinutesSeconds;

        if (remainingTime.minutes <= 0 && remainingTime.seconds <= 0) {
            return this.firstTimeoutEnd(accepted_at);
        }

        this.setState({remainingTime});
        return true;
    };

    firstTimeoutEnd = (accepted_at: string):boolean => {
        const {show_modal} = this.state;

        if (show_modal) {
            this.endedTimeout();
            this.setState({show_modal: false});
        }

        const remainingTime = upToMinutes(accepted_at, EXTRA_MINUTES) as unknown as MinutesSeconds;

        this.setState({remainingTime});
        if (remainingTime.minutes <= 0 && remainingTime.seconds <= 0) {
            clearInterval(this.interval);
            return false;
        }
        return true;
    };

    endedTimeout = (): void => {
        const {p2pDepositsStore} = this.props;
        modalConfirm(
            I18n.t('made_payment'),
            () => p2pDepositsStore.confirmP2pTransfer(),
            null,
            {cancelText: I18n.t('confirm_button_no_continue')}
        );
    };

    transferred = (transferred: boolean): void => this.setState({transferred});

    amount = (): string => {
        const {current_p2p_deposit: {amount,
            grouped_currency_amount,
            grouped_amount,
            currency}} = this.props.p2pDepositsStore;
        const {rates} = this.props.depositsStore;
        const {system} = this.props;

        return roundByCurrency({amount: grouped_amount || amount,
            currency: currency || system.exchange_currency,
            currency_amount: grouped_currency_amount,
            rates});
    };

    render(): ReactNode {
        const {transferred, remainingTime} = this.state;

        const {
            current_p2p_deposit:
            {
                id, cashout_id, account, currency, waiting,
                user_verified_documents, bank, not_valid, screenshot_url
            },
            confirmP2pTransfer,
            submitting,
            currentPaymentSystem: {related_to_bank_bins, payment_screenshot}
        } = this.props.p2pDepositsStore;
        const amount = this.amount();
        const disabled = waiting || !transferred || submitting || not_valid;
        const withTimerAndPromo = !waiting && !not_valid;
        const transferMade = waiting || transferred || not_valid;
        const {system} = this.props;
        const receiverAccount = translatedKeyLangs(system, 'account_label') || I18n.t('receiver_account');
        const comment = translatedKeyLangs(system, 'p2p_deposit_comment') || cashout_id;
        return <Fragment>
            <div className="description">
                <p dangerouslySetInnerHTML={{__html: translatedKeyLangs(system, 'deposit_description')}}/>
            </div>
            {withTimerAndPromo && <div className="timer-deposit">
                <span className="text">{I18n.t('you_have')}</span>
                <span className="timer">
                    <svg className="icon">
                        <use xlinkHref="#svg-timer-icon" fill="none"/>
                    </svg>
                    <b>
                        {remainingTime.minutes}{I18n.t('min_short')} {remainingTime.seconds}{I18n.t('sec_short')}
                    </b>
                </span>
                <span className="text">{I18n.t('to_make_transfer')}</span>
            </div>}
            <div className={`card-transfer-info form-egb ${payment_screenshot && 'card-screenshot-info'}`}>
                {related_to_bank_bins && <div className="bank-logotype">
                    <span className="logotype">
                        {bank
                            ? <img src={bank.logo.url} title={bank.name} alt={bank.name}/>
                            : <img src={bankLogotypeSvg} title="bankLogo" alt="bankLogo"/>
                        }
                    </span>
                </div>}
                <div className="rows rows__info">
                    <div className="row">
                        <span className="caption">{I18n.t('user.amount_label')}:</span>
                        <span className="total">
                            {betMoney({}, amount, false, true)}
                            &nbsp;
                            {isoCurrencyToHtml(currency)}
                        </span>
                        {!transferred && <CopyButton value={amount}/>}
                    </div>
                    {!payment_screenshot && <div className="row">
                        <span className="caption">{receiverAccount}:</span>
                        <span className="total">{account}</span>
                        {!transferred && <CopyButton value={account}/>}
                    </div>}
                    {!payment_screenshot && <div className="row">
                        <span className="caption">{I18n.t('payment_comment')}:</span>
                        <span className="total">{comment}</span>
                    </div>}
                </div>
                {payment_screenshot && <ScreenshotPayment screenshot_url={screenshot_url}/>}
                <ScreenshotLoader
                    id={id}
                    user_verified_documents={user_verified_documents}
                    loadCurrentP2pDeposits={this.props.p2pDepositsStore.loadCurrentP2pDeposits}/>
            </div>
            <div className="card-transfer-proof">
                <Checkbox
                    label={I18n.t('transfer_made')}
                    onChange={this.transferred}
                    value={transferMade}
                />
                <button
                    className={`button-adaptive ${disabled ? 'button-adaptive_disable' : ''}`}
                    disabled={disabled}
                    onClick={confirmP2pTransfer}
                >
                    {I18n.t('confirm_transfer')}
                </button>
            </div>
        </Fragment>;
    }
}
