import {ScrolledPageContent} from 'app/components/page_content';
import {TimerPage} from 'app/components/pages/timer_page';
import PlaceInterface from 'app/interfaces/PlaceInterface';
import {PageContentStore} from 'app/store/PageContentStore';
import {goToRoute} from 'app/utils/link_helper';
import {inject, observer} from 'mobx-react';
import React, {Component, ComponentClass, lazy, Suspense} from 'react';
import withRouter from 'app/utils/navigate/customWithRouter';

interface PropsInterface {
    params?: {
        url: string
    }
    pageContentStore?: PageContentStore
}

interface ContentInterface {
    component: string
    prizes: PlaceInterface[]
}

interface LandingPagePropsInterface {
    url: string
}

const componentIndexes = {
    AnicompendiumIndex: () => import('app/components/compendium/landing_pages/AnicompendiumIndex'),
    BaseLandingPage: () => import('app/components/compendium/landing_pages/BaseLandingPage'),
    Cologne010721: () => import('app/components/compendium/landing_pages/Cologne010721'),
    CompendiumIndex: () => import('app/components/compendium/landing_pages/CompendiumIndex'),
    Compenigma2021Index: () => import('app/components/compendium/landing_pages/Compenigma2021Index'),
    EnigmaIndex: () => import('app/components/compendium/landing_pages/EnigmaIndex'),
    International2021Index: () => import('app/components/compendium/landing_pages/International2021Index'),
    PathoflegendsIndex: () => import('app/components/compendium/landing_pages/PathoflegendsIndex')
};

@inject('pageContentStore')
@observer
class LandingPageContainer extends Component<PropsInterface> {
    constructor(props: PropsInterface) {
        super(props);
        props.pageContentStore.startLoading();
    }

    get page(): React.LazyExoticComponent<ComponentClass<LandingPagePropsInterface>> {
        const {pageContentStore: {content}} = this.props;

        if (!content) {
            return null;
        }

        const {component} = content as ContentInterface;

        if (component) {
            return lazy(() => componentIndexes[component]().catch(this.redirectToMain));
        }
        return null;
    }


    redirectToMain = (): void => goToRoute('/');

    render(): React.ReactNode {
        const {params: {url}} = this.props;

        const Page = this.page;

        const page = <ScrolledPageContent
            contentUrl={`/promo/${url}`}
            failCallBack={this.redirectToMain}
        >
            <Suspense fallback={<div className="loader"/>}>
                {Page && <Page url={url}/>}
            </Suspense>
        </ScrolledPageContent>;
        return page || <TimerPage message="The requested page does not exist."/>;
    }
}

export default withRouter(LandingPageContainer);
