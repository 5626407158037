import $ from 'jquery';
import React from 'react';
import Cookies from 'js-cookie';
import {userSignedIn} from 'app/utils';
import musicPlayer from './sounds';

const
    MAX_PLAYER_VOLUME = 1,
    MAX_VOLUME = 100,
    MIN_VOLUME = 0;

export default class VolumeChanger extends React.PureComponent {
    constructor(props) {
        super(props);

        let volume = Number(Cookies.get('volume'));
        if (isNaN(volume)) {
            volume = MAX_PLAYER_VOLUME;
        }
        volume *= MAX_VOLUME;
        this.state = {
            volume: settings.user.sound_enabled && volume !== 0 ? volume : 0,
            showSoundRange: false
        };
        this.volumeHandler = this.volumeHandler.bind(this);
        this.volumeMouseUpHandle = this.volumeMouseUpHandle.bind(this);
    }

    soundOpener = React.createRef();

    soundContainer = React.createRef();

    componentDidMount() {
        document.body.addEventListener('click', this.handlerClose);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.handlerClose);
    }

    handlerClose = e => {
        if (!this.state.showSoundRange) {
            return;
        }
        if (!this.soundOpener.current.contains(e.target) && !this.soundContainer.current.contains(e.target)) {
            this.toggleSoundContainer();
        }
    }

    toggleSoundContainer = () => {
        if (this.state.showSoundRange) {
            $(this.soundContainer.current).slideUp();
        } else {
            $(this.soundContainer.current).stop().slideToggle();
        }
        this.setState({showSoundRange: !this.state.showSoundRange});
    }

    volumeHandler(e) {
        const volume = Math.ceil(e.target.value);
        musicPlayer.volume(volume / MAX_VOLUME);
        this.setState({volume});
    }

    muteSound = () => {
        musicPlayer.volume(MIN_VOLUME);
        this.setState({volume: MIN_VOLUME});
        this.volumeMouseUpHandle(MIN_VOLUME);
    };

    loudSound = () => {
        musicPlayer.volume(MAX_PLAYER_VOLUME);
        this.setState({volume: MAX_VOLUME});
        this.volumeMouseUpHandle(MAX_VOLUME);
    };

    volumeMouseUpHandle = volume => {
        musicPlayer.play('line_closed_sound');
        const soundVolume = !isNaN(volume) ? volume : this.state.volume;
        if (settings.user.sound_enabled !== Boolean(soundVolume)) {
            if (userSignedIn()) {
                $.ajax('/user/sound', {
                    success: response => {
                        settings.user.sound_enabled = response.sound;
                    }
                });
            } else {
                settings.user.sound_enabled = !settings.user.sound_enabled;
            }
        }
    }

    renderIconSvg = volume => {
        switch (true) {
        case volume > 70:
            return '#svg-volume70more';
        case volume > 35:
            return '#svg-volume70less';
        case volume > 0:
            return '#svg-volume35less';
        default:
            return '#svg-volume0';
        }
    }

    soundIcon = () => <use xlinkHref={ this.renderIconSvg(this.state.volume)}/>

    render() {
        return this.props.mobile
            ? <div className="profile__sound">
                <button className="btn-volume" onClick={this.muteSound}>
                    <svg height="24px" width="24px">
                        <use xlinkHref="#svg-volume0"/>
                    </svg>
                </button>
                <div className="profile__field profile__field--volume field">
                    <input
                        type="range"
                        id="volume-hor"
                        className="field__range volume volume-hor"
                        onChange={this.volumeHandler}
                        onMouseUp={this.volumeMouseUpHandle}
                        value={this.state.volume}/>
                </div>
                <button className="btn-volume" onClick={this.loudSound}>
                    <svg height="24px" width="24px">
                        <use xlinkHref="#svg-volume70more"/>
                    </svg>
                </button>
            </div>
            : <div ref={this.soundOpener} onClick={this.toggleSoundContainer}
                className={`sound sound__opener ${this.state.showSoundRange ? 'js-opener-active' : ''}`}>
                <div className="sound__icon">
                    <svg>{this.soundIcon()}</svg>
                </div>
                <div ref={this.soundContainer}
                    className={`sound__container ${this.state.showSoundRange ? 'sound-range-showed' : ''}`}>
                    <div className="sound__field field">
                        <input
                            type="range"
                            className="field__range field__range--vert volume"
                            onChange={this.volumeHandler}
                            onMouseUp={this.volumeMouseUpHandle}
                            value={this.state.volume}
                        />
                    </div>
                </div>
            </div>;
    }
}
