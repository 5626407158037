import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';
import WalletInterface from 'app/interfaces/WalletInterface';
import {action, observable, computed, makeObservable} from 'mobx';
import Get from 'app/utils/Get';
import {ErrorReturnType} from 'app/components/deposit/InputError';
import {getLinkPath, isGuest} from 'app/utils';
import HistoricalDepositInterface from 'app/interfaces/HistoricalDepositInterface';
import {addOrUpdateObject} from '../utils/updateArraysObject';

const AMOUNT_REGEX = /^\d+[,.]?\d{0,2}$/u;

export interface DepositsStoreProps {
    depositsStore?: DepositsStore
}

export interface RatesType {
    cny_rate: number
    btc_rate: number
    krw_rate: number
    inr_rate: number
}

type DepositSuggestions = {
    [key: number]: number
};

type Bank = {
    bank_id: number
    country_code: string
    method: string
};

interface DepositsStoreData {
    deposit_suggestions?: DepositSuggestions
    systems: PaymentSystemInterface[]
    latest_deposits: {
        deposits: HistoricalDepositInterface[]
        has_more: boolean
    }
    wallets: PaymentSystemWallets
    rates: RatesType
    success: boolean
    message: string
    first_deposit: boolean
    available_deposit_limit: AvailableDepositLimit
    bank_list: Bank[]
}

interface DataWallets {
    success: boolean
    message: string
    wallets: PaymentSystemWallets
    systems: PaymentSystemInterface[]
}

type PaymentSystemWallets = {
    [key: number]: WalletInterface[]
};

type WithPromise = {
    promise: () => Promise<void>
};

type AvailableDepositLimit = {
    amount: number
    date: string
};

export default class DepositsStore {
    constructor() {
        this.init();
        makeObservable(this);
    }

    @observable currentPaymentSystem: PaymentSystemInterface = null;

    @observable depositSuggestions: DepositSuggestions = null;

    @observable systems: PaymentSystemInterface[] = [];

    @observable giftCardCode: string = '';

    @observable promoCode: string = '';

    @observable historyDeposits: HistoricalDepositInterface[] = [];

    @observable currentOfelospayDeposits: HistoricalDepositInterface[] = [];

    @observable historyPage: number = 1;

    @observable historyHasMore: boolean = false;

    @observable historyLoadingMore: boolean = false;

    @observable submitting: boolean = false;

    @observable loading: boolean = true;

    @observable wallets: PaymentSystemWallets = null;

    @observable wallet_id: number = null;

    @observable amount: string = '';

    @observable bonus_type: number = 0;

    @observable rates: RatesType = null;

    @observable success: boolean = false;

    @observable message: string = null;

    @observable firstDeposit: boolean = false;

    @observable availableDepositLimit: AvailableDepositLimit = null;

    @observable bankList: Bank[] = [];

    @observable errors: ErrorReturnType = null;

    @action
    init(): void {
        this.resetErrors();
        this.loadCurrentOfelospayDeposits();
    }

    @action
    selectPaymentSystem(paymentSystem: PaymentSystemInterface): void {
        this.currentPaymentSystem = paymentSystem;
        this.resetInputs();

        if (paymentSystem && paymentSystem.wallets_enabled) {
            const wallets = this.wallets[paymentSystem.id];

            if (wallets && wallets.length > 0) {
                this.wallet_id = wallets[0].id;
            }
        } else {
            this.wallet_id = null;
        }
    }

    @action
    update(data: DepositsStoreData): void {
        this.resetInputs();
        this.loading = false;
        this.success = data.success;
        this.message = data.message;
        this.systems = data.systems;
        this.updateData(data);
    }

    @action
    updateSystemsAndWallets(data: DataWallets): void {
        this.resetInputs();
        this.systems = data.systems;
        this.wallets = data.wallets;
        if (data.message) {
            this.message = data.message;
            this.success = data.success;
        }
    }

    updateData(data: DepositsStoreData): void {
        this.depositSuggestions = data.deposit_suggestions;
        if (data.latest_deposits) {
            this.historyDeposits = data.latest_deposits.deposits;
            this.historyHasMore = data.latest_deposits.has_more;
        }
        this.wallets = data.wallets;
        this.rates = data.rates;
        this.firstDeposit = data.first_deposit;
        this.availableDepositLimit = data.available_deposit_limit;
        this.bankList = data.bank_list;
    }

    @action
    resetInputs(): void {
        this.amount = '';
        this.updateGiftCardCode('');
        this.updatePromoCode('');
        this.stopSubmitting();
    }

    @action
    unLoad(): void {
        this.loading = true;
    }

    @action
    startSubmitting(): void {
        this.submitting = true;
        this.resetErrors();
    }

    @action
    stopSubmitting(errors?: ErrorReturnType): void {
        this.submitting = false;
        if (errors) {
            this.errors = errors;
        }
    }

    @action
    updateGiftCardCode(code: string): void {
        this.giftCardCode = code;
        this.resetErrors();
    }

    @action
    updatePromoCode(code: string): void {
        this.promoCode = code;
        this.resetErrors();
    }

    @action
    updateHistoryLoadingMore(val: boolean): void {
        this.historyLoadingMore = val;
    }

    @action
    resetErrors(errors?: ErrorReturnType): void {
        if (errors) {
            this.errors = errors;
        } else {
            this.errors = {};
        }
    }

    @action
    updateLatestDeposits(page: number, deposits: HistoricalDepositInterface[], hasMore: boolean): void {
        const d = page === 1 ? deposits : this.historyDeposits.concat(deposits);
        this.historyPage = page;
        this.historyDeposits = d;
        this.historyHasMore = hasMore;
        this.updateHistoryLoadingMore(false);
    }

    @computed
    get currentSystemWallets(): WalletInterface[] {
        return this.wallets[this.currentPaymentSystem.id] || [];
    }

    @action
    selectWallet(wallet: WalletInterface): void {
        this.wallet_id = wallet === null ? null : wallet.id;
    }

    loadMoreHistory = (resetPage?: boolean): WithPromise => {
        const page = resetPage ? 1 : this.historyPage + 1;
        this.updateHistoryLoadingMore(true);
        const res = new Get({
            params: {page},
            url: '/deposits/list'
        }).execute()
            .then(response => response.json())
            .then(response => this.updateLatestDeposits(page, response.deposits, response.has_more));
        return {
            promise: () => res
        };
    };

    @action
    updateAmount(amount: string): void {
        this.errors = {};
        if (amount && !AMOUNT_REGEX.test(amount)) {
            return;
        }

        if (typeof amount === 'string') {
            this.amount = amount.replace(',', '.');
            return;
        }
        this.amount = amount;
    }

    @action
        updateBonusType = (bonus: number): void => {
            this.bonus_type = bonus;
        };

    @action
    updateAmountDirect(amount: string): void {
        this.amount = amount;
    }

    loadCurrentOfelospayDeposits = (): void => {
        if (isGuest()) {
            return;
        }
        new Get({url: getLinkPath('/deposits/current_ofelospay_deposits')})
            .execute()
            .then(data => data.json())
            .then(data => this.updateCurrentOfelospayDeposits(data.current_ofelospay_deposits));
    };

    @action
    updateCurrentOfelospayDeposits(deposits: HistoricalDepositInterface[]): void {
        this.currentOfelospayDeposits = deposits;
    }

    @action
    updateCurrentOfelospayDeposit(deposit: HistoricalDepositInterface): void {
        addOrUpdateObject(this, 'currentOfelospayDeposits', deposit);
    }
}
