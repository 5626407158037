import React from 'react';
import {modalsStore} from 'app/store/global';

const MODAL_FADE_TIMER = 500;


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const showPopup = (id: string, Component: React.FC<{popupId: string}>, hidePopupByIds = []): void => {
    const {body} = document;
    const header: HTMLElement = document.querySelector('.header');
    const app = document.querySelector('#app');
    const beforeWidth = body.clientWidth;

    /*
     * I'm not sure if it's necessary
     * hidePopupByIds && hidePopupByIds.concat([id]).forEach(popupId => {
     *     hideLastPopup(popupId);
     * });
     */

    body.style.overflow = 'hidden';
    body.style.width = `${beforeWidth}px`;
    if (header) {
        header.style.width = `${beforeWidth}px`;
    }

    app.classList.add('popup-has-show');

    // eslint-disable-next-line no-magic-numbers
    const uniqModalId = `${id}-${Math.random().toString(36).substring(2)}`.toString();

    modalsStore.showModal({Component, id, key: uniqModalId, visible: true});
};


// eslint-disable-next-line max-statements
export const hideLastPopup = (modalId?: string): void => {
    if (!modalsStore.collection || modalsStore.collection.length === 0) {
        return;
    }
    let modal = null;

    if (modalId) {
        modal = modalsStore.isModalOpen(modalId) ? modalsStore.getById(modalId) : null;
    } else {
        modal = modalsStore.lastModal();
    }

    if (!modal) {
        return;
    }

    if (modal.cancelClick) {
        modal.cancelClick();
    } else {
        modalsStore.hideModal(modal.id);
    }

    if (modalsStore.collection.length === 0) {
        deleteModalStyles();
    }
};

export const deleteModalStyles = (): void => {
    const {body} = document;
    const header: HTMLElement = document.querySelector('.header');
    const app = document.querySelector('#app');

    header?.removeAttribute('style');
    body.removeAttribute('style');
    app?.classList.remove('popup-has-show');
};

export const hideModalWithFade = (modal_id: string): void => {
    setTimeout(() => {
        modalsStore.deleteModal(modal_id);
        if (modalsStore.collection.length === 0) {
            deleteModalStyles();
        }
    }, MODAL_FADE_TIMER);
};
