import React, {Component, ReactNode} from 'react';
import SmartLabelTooltip from 'app/components/reusable/SmartLabelTooltip';
import {ElementWithError} from 'app/components/reusable/ElementWithError';
import {IncubatorCreateLineController} from 'app/controllers/IncubatorCreateLineController';
import {inject, observer} from 'mobx-react';

const MAXIMUM_OF_SIZE = 3;
const SECOND_INDEX_OF_ARRAY = 2;

interface Props {
    controller: IncubatorCreateLineController
}

@inject('controller')
@observer
export default class IncubatorLineFormChangeCoef extends Component<Props> {
    get controller(): IncubatorCreateLineController {
        const props = this.props as Props;
        return props.controller;
    }

    changeValue = (value: string): number | string => {
        const {coef_changing} = this.controller.incubatorGame.attributes;
        let strValue = value.replace(/[^0-9.]/gu, '').replace(/[.*?]\..*/gu, '.');

        if (strValue.length === 1 && strValue.substring(0, 1) === '.') {
            return coef_changing;
        }

        if (strValue.length > 1 && strValue.substring(1, SECOND_INDEX_OF_ARRAY) !== '.') {
            strValue = strValue[0].concat('.', strValue[1]);
        }

        return strValue.length <= MAXIMUM_OF_SIZE ? strValue : coef_changing;
    };

    handleChangeCoefChanger = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.controller.onChangeCoefChanging(this.changeValue(e.target.value));
        this.controller.incubatorGame.clearError('coef_changing');
    };

    errorHint = (field: string): ReactNode => this.error(field);

    error = (field: string): string => this.controller.incubatorGame.getError(field);

    render(): ReactNode {
        return <>
            <SmartLabelTooltip
                labelTooltip="title_incubator_change_coefficient"
                forLabelTooltip="changeCoefficientIncubator"
                descriptionTooltip="tooltip_incubator_change_coefficient_1"
                additionalDescriptionTooltip="tooltip_incubator_change_coefficient_2"
            />
            <ElementWithError
                errorHint={this.errorHint('coef_changing')}
                className={this.error('coef_changing') ? 'field-error' : ''}
            >
                <input
                    className="field__input field-number__input"
                    id="changeCoefficientIncubator"
                    name="changeCoefficientIncubator"
                    type="text"
                    value={this.controller.incubatorGame.attributes.coef_changing}
                    onChange={this.handleChangeCoefChanger}
                />
            </ElementWithError>
        </>;
    }
}
