import React, {Fragment} from 'react';
import PromoActionBeer from './beer';
import I18n from 'app/i18n';
import {Link} from 'react-router-dom';
import {getLinkPath} from 'app/utils';

export default class PromoActionOktoberfest extends PromoActionBeer {
    promoActionTitle = () => <Fragment>
        {I18n.t('oktoberfest_header')}
        <div className="faq-button">
            <Link
                to={getLinkPath('/faq/therese_von_bayern')}
                target="_blank"
                className="act faq-button__icon"
                onClick={e => e.stopPropagation()}>
                <svg height="20px" width="20px">
                    <use xlinkHref="#svg-question"/>
                </svg>
            </Link>
        </div>
    </Fragment>;

    get imagePropName() {
        return 'PROMO_ACTION_OKTOBERFEST';
    }

    beerInnerComponent = () => <div className="beer-glass__inner beer-glass__inner__oktoberfest">
        {this.beerGlassParts()}
    </div>;
}

