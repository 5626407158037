import React, {Component, ReactNode, RefObject} from 'react';
import I18n from 'app/i18n';
import {ConfirmEmailSign} from 'app/components/auth/ConfirmEmailSign';
import {ConfirmEmailButton} from 'app/components/auth/ConfirmEmailButton';


interface RowEmailProps {
    email: string;
    confirmed: boolean;
    confirmationExpired: boolean;
    rowClass: string;
    hidden?: boolean
    reference?: RefObject<HTMLInputElement>;
    errorHint: ReactNode;
    mailCheck: () => void;
    cleanError: (field: string) => void;
    onChangeHandler: () => void;
}

export class RowEmail extends Component<RowEmailProps> {
    assignClassname(confirmed: boolean): string {
        if (typeof confirmed !== 'undefined') {
            if (confirmed === true) {
                return 'field__input--exist-state';
            }
            return 'field__input--inexist-state';
        }
        return '';
    }

    cleanError = (): void => {
        this.props.cleanError('email');
    };

    render(): ReactNode {
        const {email, confirmed, confirmationExpired, rowClass, mailCheck, reference,
            onChangeHandler, errorHint, hidden} = this.props;

        return <div className={rowClass} hidden={hidden}>
            <label className="field-label" htmlFor="field-email">{I18n.t('email_label')}</label>
            <div className="field">
                <div className="field__wrap">
                    <input
                        ref={reference}
                        className={`field__input ${this.assignClassname(confirmed)}`}
                        id="field-email"
                        name="email"
                        defaultValue={email}
                        type="email"
                        onKeyUp={this.cleanError}
                        onBlur={mailCheck}
                        onChange={onChangeHandler}
                    />
                    {<ConfirmEmailSign confirmed={confirmed}/>}
                </div>
                {<ConfirmEmailButton
                    confirmed={confirmed}
                    confirmationExpired={confirmationExpired}
                />}
            </div>
            {errorHint}
        </div>;
    }
}
