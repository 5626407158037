import React, {Component} from 'react';
import {i18n_t} from 'app/i18n';
import {inject, observer} from 'mobx-react';
import Post from 'app/utils/Post';
import {modalConfirm} from 'app/utils/modals/popups';
import {artifactName} from '../utils';
import {artifactStore, promoActionStore} from 'app/store/global';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import {updateInfo} from 'app/common';
import TPromoActionStore from 'app/interfaces/stores/TPromoActionStore';
import {getLinkPath} from 'app/utils';

interface ConversionProps {
    artifact: {
        id: number,
        item_type: string,
        name: string,
        langs: { [key: string]: string }
    },
    promoActionStore?: TPromoActionStore,
    close():void
}

class Conversion extends Component<ConversionProps> {
    isArtifact = (): boolean => this.props.artifact.item_type === 'artifact';

    sendRequest = (): void => {
        const {artifact: {id}} = this.props;
        const {id: promoActionId, url} = promoActionStore.promoAction;

        new Post({
            params: {artifact_id: id},
            url: getLinkPath(`/promo/${url || promoActionId}/convert_artifact`)
        })
            .execute()
            .then(response => {
                if (!response.ok) {
                    response
                        .json()
                        .then(responseJSON => Object.values(responseJSON).map((error: string) => toastError(error)));
                    return updateInfo();
                }

                return response
                    .json()
                    .then(responseJSON => {
                        promoActionStore.update(responseJSON.promo_actions);
                        artifactStore.updateInventory(responseJSON);

                        this.props.close();

                        toastSuccess(i18n_t(
                            'conversion.successfully_converted',
                            {artifact_name: artifactName(this.props.artifact)}
                        ));
                    });
            });
    };

    onClickHandler = (): void => {
        modalConfirm(
            i18n_t('conversion.conversion_confirm', {artifact_name: artifactName(this.props.artifact)}),
            this.sendRequest
        );
    };

    render() {
        if (!this.isArtifact() || !promoActionStore.promoAction) {
            return null;
        }

        const ConvertButton = promoActionStore.convertButton;

        return (
            <div className="popup__footer">
                <div className="inventory__popup__footer">
                    <div className="inventory__popup__footer--row">
                        <div className="inventory__popup__footer--items">
                            <div className="inventory__popup__footer--items-row">
                                <div className="inventory__popup__footer--items-row-icon">
                                    <svg height="27px" width="27px">
                                        <use xlinkHref="#svg-scatter"/>
                                    </svg>
                                </div>
                                <div className="inventory__popup__footer--items-row-text">
                                    {i18n_t('scatter.you_can')}
                                </div>
                            </div>
                        </div>
                        <div className="inventory__popup__footer--actions">
                            <ConvertButton onClickHandler={this.onClickHandler}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default inject('promoActionStore')(observer(Conversion));
