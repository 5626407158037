import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {IncubatorCreateLineController} from 'app/controllers/IncubatorCreateLineController';
import {inject, observer} from 'mobx-react';
import IncubatorUsersBets from 'app/components/pages/incubator/line/IncubatorUsersBets';
import {betMoney, fixed2, flagImage, isDesktop} from 'app/utils';
import {GAMER_2, KEY_DOWN, KEY_UP} from 'app/utils/Consts';
import changeCoefByStep from 'app/utils/ChangeCoefByStep';
import {MAX_COEF, MIN_COEF, STEP, THREE} from './ControlsCommonConstants';
import IncubatorLineRightWinner from 'app/components/pages/incubator/line/IncubatorLineRightWinner';

interface Props {
    controller?: IncubatorCreateLineController
}

const STEP_ARROW = 0.2;
const SCALE = 3;

@inject('controller')
@observer
export default class IncubatorLineRight extends Component<Props> {
    get controller(): IncubatorCreateLineController {
        return this.props.controller;
    }

    handleInputChange = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        let initValue: string = e.currentTarget.value;
        let currentCoef = Number(initValue);
        if (e.key === KEY_UP && currentCoef < MAX_COEF) {
            currentCoef += STEP;
            initValue = currentCoef >= MAX_COEF ? '9.999' : String(currentCoef.toFixed(THREE));
            this.controller.onChangeCoef2(String(initValue));
        }
        if (e.key === KEY_DOWN && currentCoef > MIN_COEF) {
            currentCoef -= STEP;
            initValue = currentCoef <= MIN_COEF ? '1.01' : String(currentCoef.toFixed(THREE));
            this.controller.onChangeCoef2(String(initValue));
        }
    };

    handleChangeCoef2 = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.controller.onChangeCoef2(e.target.value);
    };

    onClickChangeCoef = (plus = true): void => {
        const {coef_2} = this.controller.incubatorGame.attributes;

        if (coef_2) {
            const num_coef2 = Number(coef_2);
            const res = plus ? num_coef2 + STEP_ARROW : num_coef2 - STEP_ARROW;
            this.controller.onChangeCoef2(res.toFixed(SCALE));
        }
    };

    onClickUpArrow = (): void => {
        this.onClickChangeCoef();
    };

    onClickDownArrow = (): void => {
        this.onClickChangeCoef(false);
    };

    render(): ReactNode {
        const {controller} = this.props;
        const {bets, incubatorGame, profitClass} = controller;
        const {coef_2, gamer_2, coef2_win, user_bets_sum_2} = incubatorGame.attributes;
        const show_coef_2 = coef_2 ? coef_2 : '';


        return <div className="part right">
            <div className="line-top">
                <div className="line-team">
                    {isDesktop() && flagImage(gamer_2.country_code, 'line-team-flag')}
                    <span className="name">{gamer_2.nickname}</span>
                </div>
                <div className="line-about-sum">
                    <span className="line-sum">{betMoney({amount: user_bets_sum_2})}</span>
                    {!isDesktop() && flagImage(gamer_2.country_code, 'line-team-flag')}
                </div>
            </div>
            <div className="line-fields">
                <div className="input-line w57">
                    <input
                        className="field__input bold field-number__input"
                        type="text"
                        min="0"
                        step="0.001"
                        value={show_coef_2}
                        name="line[coef_2]"
                        id="line[coef_2]"
                        disabled={false}
                        onKeyDown={changeCoefByStep}
                        onKeyUp={this.handleInputChange}
                        onChange={this.handleChangeCoef2}
                    />
                    {!isDesktop() && <>
                        <span className="incubator-mobile-button-coef plus" onClick={this.onClickUpArrow}>
                            <svg className="svg-default">
                                <use xlinkHref="#svg-triangle-full"/>
                            </svg>
                        </span>
                        <span className="incubator-mobile-button-coef minus" onClick={this.onClickDownArrow}>
                            <svg className="svg-default">
                                <use xlinkHref="#svg-triangle-full"/>
                            </svg>
                        </span>
                    </>}
                </div>
                <div className="input-line w110 incubator-profit-line">
                    <label className="field__label">{I18n.t('tooltip_incubator_prize')}</label>
                    <input
                        className={`field__input bold field-number__input ${profitClass(coef2_win)}`}
                        type="number"
                        step="0.001"
                        min="0"
                        disabled
                        value={fixed2(coef2_win)}
                    />
                </div>
                {isDesktop() && <IncubatorLineRightWinner/>}
            </div>
            <div className="incubator-line-table">
                <IncubatorUsersBets
                    positionTable={GAMER_2}
                    bets={bets ? bets.gamer2Bets() : []}
                />
            </div>
        </div>;
    }
}
