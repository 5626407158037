import PromoActionCompendiumInterface from 'app/interfaces/PromoActionCompendiumInterface';
import PromoActionPresentInterface from 'app/interfaces/PromoActionPresentInterface';
import {action, computed, makeObservable, observable} from 'mobx';
import BeerConvertButton from 'app/components/my_profile/artifacts/artifact_popup/beer/ConvertButton';
import CompendiumConvertButton from 'app/components/my_profile/artifacts/artifact_popup/compendium/ConvertButton';
import snakeCase from 'lodash/snakeCase';
import ConvertButtonBase from 'app/components/my_profile/artifacts/artifact_popup/ConvertButtonBase';

interface PromoActionsInterface {
    PromoActionPresent?: PromoActionPresentInterface
    PromoActionCompendium?: PromoActionCompendiumInterface
    PromoActionCompenigma?: PromoActionCompendiumInterface
}

interface WSPromoActionEvent {
    data: {
        event: string
        payload: PromoActionsInterface
    }
}

export class PromoActionStore {
    constructor() {
        makeObservable(this);
    }

    @observable promoActions: PromoActionsInterface = {};

    subscription = null;

    subscriptionCommon = null;

    @computed
    get promoAction(): PromoActionPresentInterface | PromoActionCompendiumInterface {
        const [promoAction] = Object.values(this.promoActions);
        return promoAction;
    }

    @computed
    get isConversionAvailableNow(): boolean {
        if (!this.promoAction) {
            return false;
        }

        const {conversion_available} = this.promoAction;

        return conversion_available;
    }

    @computed
    get i18nKey(): string {
        const [promoActionKey] = Object.keys(this.promoActions);
        return snakeCase(promoActionKey);
    }

    @computed
    get convertButton(): typeof ConvertButtonBase {
        const [promoActionKey] = Object.keys(this.promoActions);

        switch (promoActionKey) {
        case 'PromoActionPresent':
        case 'PromoActionOktoberfest':
        case 'PromoActionBeer':
        case 'PromoActionCocktail':
            return BeerConvertButton;
        default:
            return CompendiumConvertButton;
        }
    }

    @action
    update(promoActions: PromoActionsInterface): void {
        this.promoActions = Object.keys(promoActions).reduce((object, key) => {
            object[key] = {
                ...this.promoActions[key] ? this.promoActions[key] : {},
                ...promoActions[key]
            };
            return object;
        }, {});
    }

    handleWebsocketEvent = ({data: {event, payload}}: WSPromoActionEvent): void => {
        if (event === 'update') {
            this.update(payload);
        }
    };

    compendiumIsFinished(): boolean {
        return this.promoActions.PromoActionCompendium &&
            this.promoActions.PromoActionCompendium.finished;
    }

    compendium(): (PromoActionCompendiumInterface | unknown) {
        const {PromoActionCompendium, PromoActionCompenigma} = this.promoActions;

        return PromoActionCompendium || PromoActionCompenigma || {};
    }

    currentPromoAction(): PromoActionsInterface {
        return this.promoActions;
    }
}
