const MAX_COEF = '10';

export const validationCoef = (newAmount: string, amount: string, limitCoef = MAX_COEF): string => {
    let tempNewAmount = newAmount;

    if (tempNewAmount && !(/^\d+[,.]?\d{0,3}$/u).test(tempNewAmount)) {
        return amount;
    }

    if (Number(tempNewAmount) > Number(limitCoef)) {
        return limitCoef;
    }

    if (typeof tempNewAmount === 'string') {
        tempNewAmount = tempNewAmount.replace(',', '.');
    }
    return tempNewAmount;
};
