import React, {PureComponent} from 'react';
import dateLabel from 'app/components/my_profile/prizes/DateLabel';
import I18n from 'app/i18n';

export default class Header extends PureComponent {
    render() {
        return <div className="table-content-head table-content-referrals">
            <div className="table-content-cell">
                <span className="table-content-cell-name">{dateLabel()}</span>
            </div>
            <div className="table-content-cell">
                <span className="table-content-cell-name">{I18n.t('table.nickname_title')}</span>
            </div>
            <div className="table-content-cell">
                <span className="table-content-cell-name">$, {I18n.t('table.earned')}</span>
            </div>
        </div>;
    }
}
