import {
    articleStore,
    promoActionStore,
    recipeStore,
    userStore
} from 'app/store/global';
import $ from 'jquery';
import 'app/chosen';
import I18n from './i18n';
import {getLinkPath, newsArticles, promotionArticles, userSignedIn} from './utils';
import {forceUpdateKeyStore, updateKeyStore} from './store';
import {modalAlert} from './utils/modals/popups';
import Mailcheck from 'mailcheck';
import Websocket from 'modules/Websocket';
import NotificationStore from 'app/store/notifications';
import {notificationsStatusIsGranted, requestPermission} from 'app/firebase';
import httpFetch from 'app/utils/httpFetch';
import {toastError} from 'app/components/toasts/liteToast';
import {setFCMConfig} from 'app/consts/firebase';
import {listenGeneral, listenNotifications, listenUser, listenUserNotifications} from 'modules/WsSubscription';

export const padZero = function(v) {
    return v < 10 ? `0${v}` : v;
};

export const UpdateCommonData = function() {
    $.ajax(getLinkPath('/common_data?new=1'), {
        success: response => {
            const {js_ver, articles, online, guests, active_survey, market_prices} = response;

            if (js_ver > window.current_js_ver) {
                NotificationStore.add({
                    force: true,
                    timeout: false,
                    type: 'version'
                });
                window.current_js_ver = js_ver;
            }

            updateKeyStore('news_aside', {articles: newsArticles(articles)});
            updateKeyStore('promotions_aside', {articles: promotionArticles(articles)});
            updateKeyStore('chat', {online: online + guests});

            forceUpdateKeyStore('active_survey', active_survey);

            recipeStore.updateRecipes(market_prices);
        }
    });
};

export const updateInfo = function() {
    $.ajax('/user/info', {
        data: {
            gifts: 1,
            hasChat: 1,
            msg: 1
        },
        dataType: 'json',
        success: response => {
            if (!response.success) {
                $('.user-menu, .header__aside-opener, .gifts-menu').hide();
                $('meta[name=csrf-token]').attr('content', response.csrf_token);
                window.settings.user.id = null;
                window.settings.user.group = null;
                window.settings.user.name = null;
            }
            if (response.messages_count) {
                userStore.update({messagesCount: response.messages_count});
            }
            if (response.promo_actions) {
                promoActionStore.update(response.promo_actions);
            }

            userStore.update({user: response.user});
            updateKeyStore('common_promo_action_data', response.common_promo_action_data);

            if (typeof response.pinned_promotions_count !== 'undefined') {
                articleStore.update({pinnedPromotionsCount: response.pinned_promotions_count});
            }
        },
        type: 'GET'
    });
};

export const handleError = function(errorObj) {
    if (errorObj.status === 401) {
        modalAlert(I18n.t('user.not_logged_in'));
    }
};

export const dateFormat = function(date, type, t_int_zone) {
    const offset = t_int_zone * 3600;
    const d = new Date(date * 1000 + offset * 1000);
    const year = d.getUTCFullYear();
    const day = padZero(d.getUTCDate());
    const month = padZero(d.getUTCMonth() + 1);
    const hours = padZero(d.getUTCHours());
    const minutes = padZero(d.getUTCMinutes());
    const seconds = padZero(d.getUTCSeconds());

    switch (type) {
    case 'table':
        return `${day}.${month} ${hours}:${minutes}`;
    case 'chat':
        return `${hours}:${minutes}`;
    case 'stream':
        return `${day}.${month}.${year} ${hours}:${minutes}`;
    case 'message':
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    case 'tournament':
        return `${hours}:${minutes}:${seconds} ${day}.${month}.${year}`;
    case 'date':
        return `${day}.${month}`;
    case 'time':
        return `${hours}:${minutes}`;
    default:
        return '';
    }
};

export const init = function() {
    const defaultHeaders = {
        'X-CSRF-Token': $('meta[name=\'csrf-token\']').attr('content'),
        'X-EGB-Locale': window.CURRENT_LOCALE
    };

    $.ajaxSetup({
        data: {ajax: ''},
        dataType: 'json',
        headers: defaultHeaders
    });

    httpFetch.headers = defaultHeaders;
    httpFetch.errorStatusCallback = response => {
        let csrfToken = null;

        switch (response.statusText) {
        case 'Internal Server Error':
            return toastError(I18n.t('server_error_try_later'));
        case 'Unprocessable Entity':
            csrfToken = response.headers.get('Csrf-Token');

            if (csrfToken) {
                const headers = {
                    ...$.ajaxSetup().headers,
                    'X-CSRF-Token': csrfToken
                };
                $.ajaxSetup({headers});

                httpFetch.headers = {'X-CSRF-Token': csrfToken};
            }
            return null;
        default:
            return null;
        }
    };

    setInterval(updateInfo, 60000);
    setInterval(UpdateCommonData, 90000);

    const userID = userSignedIn();

    Websocket.init(window.SITE_DATA.WS_URL, window.USER_UI_DATA.token, '/user/ws_token');

    if (userID) {
        setFCMConfig(window.SITE_DATA.FCM_CONFIG);
        if (notificationsStatusIsGranted()) {
            requestPermission();
        }

        listenUser();
        listenUserNotifications();
    }
    listenGeneral();
    listenNotifications();
};
// eslint-disable-next-line max-len
Mailcheck.defaultDomains.push('gmail.com', 'mail.ru', 'hotmail.com', 'bk.ru', 'inbox.ru', 'list.ru', 'rambler.ru', 'ukr.net', 'mailondandan.com', 'ya.ru', 'wp.pl', 'mail.ua', 'icloud.com', 'web.de', 'o2.pl', 'mailabconline.com', 'aol.com', 'inbox.lv', 'mail.com', 'abv.bg', 'seznam.cz', 'thebest4ever.com', 'i.ua', 'qq.com', 'googlemail.com', 'interia.pl', 'mailwithyou.com', 'ymail.com', 'bigmir.net', 'big-post.com', 'tut.by', '163.com', 'me.com', 'msn.com', 'sina.com', 'onet.pl', 'sibmail.com', 'meta.ua', 'ro.ru', 'op.pl', 'windowslive.com', 'rocketmail.com', 'naver.com', 'freemail.hu', 'tlen.pl', 'azet.sk', 't-online.de', 'qip.ru', 'vp.pl', 'mailymail.co.cc', 'email.cz', 'mail.bg', 'lenta.ru', 'spaces.ru', 'citromail.hu', '126.com', 'ua.fm', 'protonmail.com', 'walla.com', 'ngs.ru', 'orange.fr', 'pochta.ru', 'ex.ua');

Mailcheck.defaultSecondLevelDomains.push('yandex', 'hotmail', 'live', 'yahoo', 'outlook', 'centrum', 'gmx');
