import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import ChangedDepositProfileFields from 'app/components/auth/ChangedDepositProfileFields';
import UserInterface from 'app/interfaces/UserInterface';

interface NotFullRegisteredCasinoProps {
    user: UserInterface
}

export default class NotFullRegisteredCasino extends PureComponent<NotFullRegisteredCasinoProps> {
    render(): ReactNode {
        return <div className="deposit">
            <div className="not-full-registered casino-games">
                <div className="title">
                    <h1>{I18n.t('user.fill_in_fields_to_play_casino')}:</h1>
                </div>
                <div className="deposit-form form-casino-game">
                    <ChangedDepositProfileFields {...this.props.user}/>
                </div>
            </div>
        </div>;
    }
}
