import React, {PureComponent} from 'react';
import {openPopupClickable} from './clickable';
import {ArtifactImage} from '../artifacts/image';

export default class ArtifactSlot extends PureComponent {
    openPopup = () => openPopupClickable(this.props.artifact);

    render() {
        const {artifact} = this.props;
        return <div
            id={`inventory_item_${artifact.body_part}`}
            className={`hero__slot${ artifact && artifact.in_market ? ' hero__slot--unavailable' : ''}`}
            onClick={this.openPopup}
        >
            <ArtifactImage artifact={artifact} showHeroSlotCount/>
        </div>;
    }
}
