import CollectionStore from 'stores/CollectionStore';
import {RestApiService} from 'app/services/RestApiService';
import LineInterface from 'app/interfaces/LineInterface';
import {action, computed, makeObservable, observable, override} from 'mobx';
import {linesSortIncubator} from 'app/lines/linesSortIncubator';
import {ObjectWithId, updateOrAddObject} from 'app/utils/updateArraysObject';

interface Response {
    data: LineInterface[]
    has_more: boolean
}

export class IncubatorUserLinesStore extends CollectionStore<LineInterface> {
    @observable page: number = 0;

    @observable hasMore: boolean = false;

    channel: () => string;

    feedUrl: () => string | null;

    @observable userId: number = null;

    @observable esports = false;

    constructor() {
        super();
        makeObservable(this);
    }

    @computed
    get sportLines(): LineInterface[] {
        return this.collection.filter(b => !b.esports);
    }

    @computed
    get egamingLines(): LineInterface[] {
        return this.collection.filter(b => b.esports);
    }

    @computed
    get lines(): LineInterface[] {
        return this.collection;
    }

    @action
    start(userId: number): void {
        this.channel = () => `$incubator-user-lines-${userId}`;
        this.feedUrl = () => '/incubator/lines/user_lines';
        this.setPage(1);
        this.restApiService = new RestApiService(this.feedUrl());
        this.userId = userId;
        this.setCollection([]);
        this.listen();
    }

    @override
    loadCollection(): void {
        this.loadData();
    }

    loadData(loadMore = false): Promise<void> {
        if (!this.feedUrl() || !this.restApiService) {
            return null;
        }
        if (loadMore) {
            this.setPage(this.page + 1);
        }

        return this.restApiService
            .index({page: this.page})
            .then(response => response.json())
            .then((response: Response): void => {
                this.setCollection(loadMore ? this.concatCollection(response.data) : response.data);
                this.setHashMore(response.has_more);
            });
    }

    @action
    concatCollection(collection: LineInterface[]): LineInterface[] {
        return this.collection.concat(collection.filter(item => !this.collection.find(item2 => item2.id === item.id)));
    }

    @override
    setCollection(collection: LineInterface[]): void {
        this.collection = linesSortIncubator(collection.slice());
    }

    @override
    updateElementCollection(item: ObjectWithId): void {
        updateOrAddObject(this, 'collection', item);
        this.setCollection(this.collection);
    }

    @action
    setHashMore(hasMore: boolean): void {
        this.hasMore = hasMore;
    }

    @action
    setPage(page: number): void {
        this.page = page;
    }

    hasUserLine(): boolean {
        if (this.userId) {
            return Boolean(this.lines.find(line => line.isCreator && line.winner === 0));
        }
        return false;
    }
}
