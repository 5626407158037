import React, {Fragment} from 'react';
import {ComponentStore} from 'app/store';
import {COMPENDIUM_ASSETS} from 'app/consts';
import {imageTag} from 'app/utils';
import I18n from 'app/i18n';

export default class CompendiumRedeemPrize extends ComponentStore {
    render() {
        return <Fragment>
            <h1 className="title" key="title">{I18n.t('promo_action.redeem_prize.how_to_redeem')}</h1>
            <div className="content__holder" key="content">
                <p>
                    {I18n.t('promo_action.redeem_prize.description3')}
                </p>
                <p>
                    {I18n.t('promo_action.redeem_prize.description4')}
                </p>
                <div className="post-center">
                    {imageTag(COMPENDIUM_ASSETS.INSTRUCTIONS.INVENTORY, {width: '80%'})}
                </div>
                <p>
                    {I18n.t('promo_action.redeem_prize.description5')}
                </p>
                <div className="post-center">
                    {imageTag(COMPENDIUM_ASSETS.INSTRUCTIONS.INVENTORY_SETTINGS, {width: '80%'})}
                </div>
                <p>
                    {I18n.t('promo_action.redeem_prize.description6')}
                </p>
                <div className="post-center">
                    {imageTag(COMPENDIUM_ASSETS.INSTRUCTIONS.MY_PROFILE_PUBLIC, {width: '80%'})}
                </div>
                <p>
                    {I18n.t('promo_action.redeem_prize.description7')}
                </p>
                <div className="post-center">
                    {imageTag(COMPENDIUM_ASSETS.INSTRUCTIONS.TRADE_OFFERS, {width: '80%'})}
                </div>
                <p>
                    {I18n.t('promo_action.redeem_prize.description8')}
                </p>
                <div className="post-center">
                    {imageTag(COMPENDIUM_ASSETS.INSTRUCTIONS.TRADE_OFFERS_LINK, {width: '80%'})}
                </div>
                <p>
                    {I18n.t('promo_action.redeem_prize.description9')}
                </p>
                <div className="post-center">
                    {imageTag(COMPENDIUM_ASSETS.INSTRUCTIONS.CREATE_NEW_URL, {width: '80%'})}
                </div>
            </div>
        </Fragment>;
    }
}
