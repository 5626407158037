import React, {ReactNode} from 'react';
import {i18n_t} from 'app/i18n';
import {SimpleGamerDescription} from 'app/utils/gamer_descriptions_helper';
import {betMoney} from 'app/utils';

interface BetAcceptedDataType {
    artifacts_count?: number
    bet_sum: number
    game: string
}

const BetAccepted = (data: BetAcceptedDataType): ReactNode => <div>
    {!data.artifacts_count || data.artifacts_count < 1
        ? i18n_t('bet_accepted', {bet_sum: betMoney(data, data.bet_sum, false)})
        : i18n_t('artifact_bet_accepted', {count: data.artifacts_count})}{'\u00A0'}
    <SimpleGamerDescription {...data} game={data.game}/>
</div>;

export default BetAccepted;
