import React, {Component, MouseEvent, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import BetSlipController from 'app/components/bet_slip/BetSlipController';
import I18n from 'app/i18n';
import {BetSlipStore} from 'app/store/BetSlipStore';
import {fixed2} from 'app/utils/fixed';
import BetSlipLimitButton from 'app/components/bet_slip/latest_bets/BetSlipLimitButton';

interface BetSlipLimitButtonProps {
    controller?: BetSlipController
    betSlipStore?: BetSlipStore
}

@inject('controller', 'betSlipStore')
@observer
export default class BetSlipLimitButtonContainer extends Component<BetSlipLimitButtonProps> {
    onClick = (e: MouseEvent<HTMLSpanElement>): void => {
        e.preventDefault();
        const {max, changeInput} = this.props.controller;

        if (changeInput) {
            changeInput(fixed2(max));
        }
    };

    render(): ReactNode {
        const {betSlipStore: {amount, type}, controller: {getLimit}} = this.props;
        return <BetSlipLimitButton
            amount={fixed2(getLimit(type) - Number(amount))}
            subClass="max-limit"
            title={I18n.t('line_limit_increased')}
            type={type}
            onClick={this.onClick}
        />;
    }
}
