import React, {Component, ReactNode} from 'react';
import Item from 'app/components/pages/articles/Item';
import SeoItem from 'app/components/pages/articles/SeoItem';
import ArticleInterface from 'app/interfaces/articles/ArticleInterface';
import {isArticle} from 'app/utils/articles/isArticle';

interface PreviewProps {
    data: ArticleInterface
    type: string
}

export default class Preview extends Component<PreviewProps> {
    render(): ReactNode {
        const {type, data} = this.props;
        return <>
            {isArticle(type)
                ? <SeoItem data={data} type={type}/>
                : <Item data={data} type={type}/>}
        </>;
    }
}
