import musicPlayer from 'app/components/sounds';
import {updateOrAddObject} from 'app/utils/updateArraysObject';
import CollectionStore from 'stores/CollectionStore';
import {IncubatorUserBetInterface} from 'app/interfaces/IncubatorUserBetInterface';
import {RestApiService} from 'app/services/RestApiService';
import {GAMER_1, GAMER_2} from 'app/utils/Consts';
import {makeObservable, override} from 'mobx';
import {STATUS_DELAY} from 'app/lines/bet_consts';

export class IncubatorUsersBetsStore extends CollectionStore<IncubatorUserBetInterface> {
    @override collection: IncubatorUserBetInterface[] = [];

    channel: () => string;

    feedUrl: () => string | null;

    constructor(userId: number, lineId: number) {
        super();
        this.channel = () => `$incubator-line-${lineId}-bets-${userId}`;
        this.feedUrl = () => `/incubator/lines/${lineId}/bets`;
        this.restApiService = new RestApiService(this.feedUrl());
        this.setCollection([]);
        this.listen();
        makeObservable(this);
    }

    @override
    updateElementCollection(item: IncubatorUserBetInterface): void {
        this.betSound(item);
        updateOrAddObject(this, 'collection', item);
    }

    gamer1Bets(): IncubatorUserBetInterface[] {
        return this.collection.filter(bet => bet.status === STATUS_DELAY && bet.on === GAMER_1);
    }

    gamer2Bets(): IncubatorUserBetInterface[] {
        return this.collection.filter(bet => bet.status === STATUS_DELAY && bet.on === GAMER_2);
    }

    betSound(item: IncubatorUserBetInterface): void {
        if (item.status !== STATUS_DELAY) {
            return;
        }

        const {high_user_bet} = item;

        if (high_user_bet) {
            musicPlayer.play('incubator_bet_max');
        } else {
            musicPlayer.play('incubator_bet');
        }
    }
}
