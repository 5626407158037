import React from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastContent from 'app/components/toasts/Contents/ToastContent';
import BaseToast from './BaseToast';

export default class WarningToast extends BaseToast {
    notify = (): React.ReactText => toast.warning(<ToastContent
        content={this.props.content}>
        <use xlinkHref="#svg-notif-system"/>
    </ToastContent>, this.options());
}
