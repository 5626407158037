import SimpleNotification from './simple_notification';
import {sendNotificationLog} from 'app/notifications/notification_log';

export default class CustomNotification extends SimpleNotification {
    onShow(e) {
        super.onShow(e);
        this.sendNotificationLog('show');
    }

    onClick(e) {
        super.onClick(e);
        this.sendNotificationLog('click');
    }

    onClose(e) {
        super.onClose(e);
        this.sendNotificationLog('close');
    }

    serviceWorkerOnShow(e) {
        super.serviceWorkerOnShow(e);
        this.sendNotificationLog('show');
    }

    serviceWorkerOnClick(e) {
        super.serviceWorkerOnClick(e);
        this.sendNotificationLog('click');
    }

    serviceWorkerOnClose(e) {
        super.serviceWorkerOnClose(e);
        this.sendNotificationLog('close');
    }

    sendNotificationLog(eventType) {
        const {id, topic} = this.data;
        sendNotificationLog(id, topic, eventType);
    }
}
