import React, {PureComponent, ReactNode} from 'react';
import {getLinkPath} from 'app/utils/link_helper';
import {Link} from 'react-router-dom';
import {scrollToTop} from 'app/utils';
import {linkSimpleBets} from 'app/utils/app_links';

interface LogoLinkInterface {
    logo_image: string,
    redirectToTable?: boolean,
    alt: string,
    title: string,
    onClick?: () => void
}

export default class LogoLink extends PureComponent<LogoLinkInterface> {
    onClick = (): void => {
        const {onClick} = this.props;
        onClick && onClick();
        scrollToTop();
    };

    render(): ReactNode {
        const {logo_image, alt, redirectToTable, title} = this.props;
        return <Link
            to={redirectToTable ? linkSimpleBets() : getLinkPath('/')}
            className="logo"
            onClick={this.onClick}
        >
            <img src={logo_image} alt={alt} title={title}/>
        </Link>;
    }
}
