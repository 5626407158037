import LineInterface from 'app/interfaces/LineInterface';

export const rootLine = (bet: LineInterface): LineInterface => {
    if (isRootLine(bet)) {
        return bet;
    }
    if (bet.parent && Number(bet.parent.id) === Number(bet.root_line_id)) {
        return bet.parent;
    }

    if (!bet.parent) {
        return bet;
    }
    return rootLine(bet.parent);
};

export const isParentRootLine = (parentLine: LineInterface): boolean => parentLine && isRootLine(parentLine);

export const isRootLine = (line: LineInterface): boolean => Number(line.root_line_id) === Number(line.id);

