import {steamTradeStore, userStore} from 'app/store/global';
import React, {Component, Fragment} from 'react';
import {modalAlert, modalConfirm} from 'app/utils/modals/popups';
import {translatedName} from 'app/utils/translations_helper';
import {i18n_t} from 'app/i18n';
import ActionButton from './action_button';
import {ArtifactImage} from './image';
import SteamSkinInterface from 'app/interfaces/SteamSkinInterface';
import TimeToStartTimer from 'app/utils/TimeToStartTimer';
import {artifactName} from './utils';
import {amountWithSign, betMoney, momentObj} from 'app/utils';
import {updateInfo} from 'app/common';
import {inject, observer} from 'mobx-react';
import {PopupDialog} from 'app/utils/modals/PopupDialog';

const timerFormat = (currentTime: number, endAt: number): string => {
    const timeObject = momentObj(endAt - currentTime);
    const days = Number(momentObj(endAt).diff(momentObj(currentTime), 'days'));
    return `${days}${i18n_t('days_short')} 
             ${timeObject.format('HH')}${i18n_t('hours_short')} 
             ${timeObject.format('mm')}${i18n_t('minutes_short')}`;
};

interface SteamTradePopupProps {
    id: number;
    popupId: string
    state: string;
    will_sold_at?: number;
    steam_skin?: SteamSkinInterface;
}

@inject('userStore')
@observer
export default class SteamTradePopup extends Component<SteamTradePopupProps> {
    state: {[state: string]: string} = {};

    constructor(props: SteamTradePopupProps) {
        super(props);
        this.state.state = props.state;
    }

    getSkin = (): void => {
        steamTradeStore.send(this.props.id, 'inventory-popup');
    };

    skinIsSent(): boolean {
        return ![
            'in_user_inventory',
            'in_user_inventory_auto',
            'pending_edits'
        ].includes(this.state.state);
    }

    skinSalable(): boolean {
        return ![
            'on_approval',
            'in_user_inventory',
            'in_user_inventory_auto',
            'pending_edits'
        ].includes(this.state.state);
    }

    warningText(): string {
        const {steam_skin} = this.props;

        switch (this.state.state) {
        case 'pending_edits':
            return i18n_t('warning_unable_transfer_skin');
        case 'on_approval':
            return i18n_t('steam_trade_wait_approve', {skin_name: steam_skin.name});
        default:
            return '';
        }
    }

    description(): string {
        const {steam_skin} = this.props;

        switch (this.state.state) {
        case 'in_user_inventory':
        case 'in_user_inventory_auto':
        case 'pending_edits':
            return `${i18n_t('get_skin_name_on_your_steam_account',
                {skin_name: steam_skin.name})} ${i18n_t('steam_trade_description')}`;
        case 'on_approval':
            return i18n_t('get_skin_name_on_your_steam_account', {skin_name: steam_skin.name});
        default:
            return i18n_t('steam_trade_in_progress', {skin_name: steam_skin.name});
        }
    }

    sendAsGift = (): void => {
        steamTradeStore.showSendAsGiftPopup(this.props);
    };

    sellSkin = (): void => {
        const {id, steam_skin} = this.props;
        modalConfirm(i18n_t('sell_steam_skin_confirm', {
            amount: amountWithSign(steam_skin.price),
            artifact: artifactName(steam_skin)
        }), () => steamTradeStore.sell(
            id,
            steam_skin.name,
            betMoney({amount: steam_skin.price}),
            null,
            'inventory-popup'
        ));
    };

    isButtonGetDisabled(): boolean {
        return !userStore.user.cashout_enabled || this.skinIsSent();
    }

    handleConfirmTradeLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        e.preventDefault();
        const {id} = this.props;
        steamTradeStore.confirmSteamTrade(id).then(response => {
            if (response.ok) {
                modalAlert(i18n_t('trade_link_updated_successfully'));
                this.setState({state: 'waiting_for_send'});
                updateInfo();
            }
            return response;
        });
    };

    render(): React.ReactNode {
        const {steam_skin, will_sold_at, popupId} = this.props;
        const {state} = this.state;
        return <PopupDialog id={popupId}>
            <div id="popup__content--steam-trade" className="popup__content">
                <h2 className="popup__header popup__header-left">
                    {translatedName(steam_skin)}
                </h2>

                <div className="inventory__popup">
                    <div className="inventory__popup--description">
                        <div className="inventory__popup--img-wrap">
                            <div className="inventory__popup--img">
                                <ArtifactImage artifact={steam_skin}/>
                            </div>
                        </div>
                        <div className="inventory__popup--desc">
                            <div className="inventory__popup--desc-title-gray">
                                {i18n_t('overview')}
                            </div>
                            <p>{this.description()}</p>
                            {!this.skinIsSent() && <p>
                                {i18n_t('autosale_timer')}: <TimeToStartTimer
                                    plainText
                                    time_to_start={will_sold_at}
                                    format={timerFormat}
                                />
                            </p>}
                            {['pending_edits', 'on_approval'].includes(state) &&
                                <div className="warning warning--attention">
                                    <div className="warning__icon warning--attention__icon">
                                        <svg height="23px" width="25px"><use
                                            xlinkHref="#svg-sign-attention"/></svg>
                                    </div>
                                    <div className="warning__content">
                                        <div className="warning__text">
                                            {this.warningText()}
                                        </div>
                                        {state === 'pending_edits' &&
                                        <div className="warning__link">
                                            <a
                                                href="#"
                                                onClick={this.handleConfirmTradeLinkClick}
                                            >
                                                {i18n_t('confirm_link_update')}
                                            </a>
                                        </div>
                                        }
                                    </div>
                                </div>}
                        </div>
                    </div>
                    <div className="inventory__popup--actions">
                        <Fragment>
                            <ActionButton
                                {...steam_skin}
                                disabled={this.isButtonGetDisabled()}
                                onClickHandler={this.getSkin}
                                openingLootbox={false}
                                btnText={i18n_t('get')}
                            />
                            <ActionButton
                                {...steam_skin}
                                disabled
                                openingLootbox={false}
                                btnText={i18n_t('send_as_gift')}
                            />
                            <ActionButton
                                {...steam_skin}
                                disabled={this.skinSalable()}
                                onClickHandler={this.sellSkin}
                                openingLootbox={false}
                                btnText={i18n_t('sell_for', {amount: amountWithSign(steam_skin.price)})}
                            />
                        </Fragment>
                    </div>
                </div>
            </div>
        </PopupDialog>;
    }
}
