import React, {PureComponent, ReactNode} from 'react';
import {modalConfirm} from 'app/utils/modals/popups';
import I18n from 'app/i18n';

interface IncubatorCancelCreateLineButtonProps {
    disabled: boolean
    cancelLine: () => void
}

export default class IncubatorCancelCreateLineButton extends PureComponent<IncubatorCancelCreateLineButtonProps> {
    onClickCancel = (): void => {
        modalConfirm(
            I18n.t('cancel_incubator_line'),
            () => this.props.cancelLine()
        );
    };

    render(): ReactNode {
        return <button
            type="button"
            onClick={this.onClickCancel}
            disabled={this.props.disabled}
            className="button-adaptive button-adaptive_full-thin"
        >
            {I18n.t('user.cancel')}
        </button>;
    }
}
