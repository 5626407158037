import LineInterface from 'app/interfaces/LineInterface';
import MakeBetInterface from 'app/interfaces/MakeBetInterface';
import MakeBetParamsInterface from 'app/interfaces/MakeBetParamsInterface';
import {isPlayMoney} from 'app/lines/isPlayMoney';
import {fixed2} from 'app/utils/fixed';
import {ParamsInterface} from 'app/utils/Get';
import BetSlip from 'app/components/bet_slip/BetSlip';
import {BaseStrategy} from 'app/components/bet_slip/strategies/Base.strategy';

export class ExpressStrategy extends BaseStrategy {
    get postUrl(): string {
        return '/express';
    }

    betSlipDidUpdate = (betSlip: BetSlip): void => {
        const {setLimit, getCoef} = this.betSlipStore;
        const {setCoefSingle, getCoefSingle} = this.betSlipController.strategy;

        if (betSlip && setLimit) {
            if (setCoefSingle && getCoefSingle() !== getCoef()) {
                setCoefSingle(true);
            }
            this.betSlipController.setLimitBetSlip();
        }
        this.checkExpressChangedCoef(Number(getCoef()));
    };

    setFormCoefSingle = (): void => {
        super.setFormCoef();
    };

    maxWin = (): number => null;

    makeBetParams(params: MakeBetParamsInterface): MakeBetInterface {
        const {amount, coef, changeAmount, loading, setLoading, type} = params;
        const {expressBetsIdOn, isEsport} = this.betSlipStore;
        const artifactsToBet = this.artifactBetStore.quantifiedArtifactsToBet();

        return {
            amount,
            artifactsToBet,
            changeAmount,
            coef,
            express: true,
            expressBetsIdOn,
            isEsport,
            loading,
            setLoading,
            type
        };
    }

    placeBetSuccess = (params: MakeBetInterface): void => {
        this.betSlipController.logEventBetExpress(params);
        this.betSlipStore.setShowForm(false);
        this.betSlipStore.setExpress(false);
        this.betSlipStore.reset();
    };

    getParams = (params: MakeBetInterface): ParamsInterface => {
        const {amount, artifactsToBet, coef, expressBetsIdOn, type} = params;
        return {
            bet: fixed2(amount),
            bet_recipes: artifactsToBet,
            coef,
            list: expressBetsIdOn,
            play_money: isPlayMoney(type)
        };
    };

    removeOnclick(line: LineInterface): void {
        this.betSlipStore.removeSameLineIfAny(line);
    }

    getCoefSingle = (): string => this.betSlipStore.expressCoef;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-inline-comments
    setCoef = (coef: string): void => { /**/ };

    getLimit = (type: string): number => this.betSlipStore.getExpressLimit(type);

    bets(): {num: number, rootLine: LineInterface, selectedLine: LineInterface}[] {
        const {betSlipStore: {lines}, betSlipController: {tableStore}} = this;

        return lines.map(i => ({
            num: i.on,
            rootLine: tableStore.findBetById(i.line.root_line_id),
            selectedLine: i.line
        }));
    }

    private checkExpressChangedCoef = (coef: number): void => {
        const {checkChangedCoef, lines, count} = this.betSlipStore;

        if (!checkChangedCoef(coef, count)) {
            this.betSlipStore.setCount(lines.length);
        }
    };
}
