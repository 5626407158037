import React, {PureComponent} from 'react';
import SwiperCore from 'swiper';
import {Swiper} from 'swiper/swiper-react';
import {Navigation, Pagination} from 'swiper/modules';

const swiperParams = {
    allowTouchMove: true,
    freeMode: {
        momentum: false,
        momentumBounce: false
    },
    observeParents: true,
    observer: true,
    slidesPerGroup: 3,
    speed: 400,
    touchRatio: 0.65,
    touchReleaseOnEdges: false,
    touchStartForcePreventDefault: true
};

interface PopularGamesListProps {
    isSwiper: boolean
    onCheckReached?: (swiper: SwiperCore) => void
    children?: React.ReactNode
}

export default class PopularGamesList extends PureComponent<PopularGamesListProps> {
    render(): React.ReactNode {
        const {children, onCheckReached} = this.props;

        return this.props.isSwiper
            ? <>
                <Swiper className="slider-container-swiper" {...swiperParams}
                    slidesPerView="auto"
                    freeMode
                    preventInteractionOnTransition
                    preventClicksPropagation
                    resistance={false}
                    watchSlidesProgress
                    onTouchEnd={onCheckReached}
                    onAfterInit={onCheckReached}
                    modules={[Pagination, Navigation]}
                    pagination={{
                        clickable: true,
                        el: '.pagination-v2'
                    }}
                    navigation={{
                        nextEl: '.button-v2-next',
                        prevEl: '.button-v2-prev'
                    }}>{children}</Swiper>
                <div className="slider-pagination-control">
                    <button className="swiper-button-slider-prev button-v2-prev">
                        <svg className="svg-default">
                            <use xlinkHref="#svg-swiper-arrow"/>
                        </svg>
                    </button>
                    <div className="swiper-pagination-group pagination-v2"/>
                    <button className="swiper-button-slider-next button-v2-next">
                        <svg className="svg-default">
                            <use xlinkHref="#svg-swiper-arrow"/>
                        </svg>
                    </button>
                </div>
            </>
            : <div className="slider-container-free">{children}</div>;
    }
}
