import React, {memo, useCallback} from 'react';
import I18n from 'app/i18n';
import {goToMyHero} from 'app/utils/app_links';
import {hideLastPopup} from 'app/utils/modals/ModalsService';

const MyHeroLink: React.FC<{btnClass?: string}> = memo(props => {
    const handleButtonClick = useCallback(() => buttonClick(), []);

    const buttonClick = (): void => {
        goToMyHero();
        hideLastPopup();
    };

    const {btnClass} = props;

    return <button
        className={`btn ${btnClass}`}
        onClick={handleButtonClick}
    >
        {I18n.t('user.my_hero')}
    </button>;
});

export default MyHeroLink;
