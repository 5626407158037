import {promoActionStore, userStore} from 'app/store/global';
import $ from 'jquery';
import React, {Fragment, PureComponent} from 'react';
import I18n from 'app/i18n';
import {localePromoName, promoUrl, serverError, isGuest} from 'app/utils';
import TableEmptyState from 'app/components/table/empty_state';
import TradeLink from './rating/trade_link';
import LeaderLines from './rating/leader_lines';
import omit from 'lodash/omit';
import SmartLoader from 'app/components/reusable/SmartLoader';
import Post from 'app/utils/Post';
import BannerButtonPrize from 'app/components/compendium/banner/BannerButtonPrize';

export default class Rating extends PureComponent {
    constructor() {
        super();
        this.state = {
            loadInfo: false,
            loading: true,
            promo_action: {total_place_count: 0},
            ratings: [],
            user_rating: {}
        };

        this.showNickHandler = this.showNickHandler.bind(this);
    }

    componentDidMount() {
        $.get(window.location.pathname, data => this.setState(data))
            .fail(() => serverError())
            .always(() => this.setState({loading: false}));
    }

    showNickHandler(e) {
        e && e.preventDefault();
        this.setState({loadInfo: true});

        new Post({
            url: promoUrl('show_nick')
        })
            .execute()
            .then(response => response.json())
            .then(response => {
                userStore.update({user: response.user});
                this.setState(omit(response, 'user'));
                this.setState({loadInfo: false});
            });
    }

    get personal() {
        return this.state.user_rating;
    }

    get remainsForTop() {
        let {ratings} = this.state;
        ratings = ratings.filter(rating => !rating.personal);
        return ((ratings[ratings.length - 1] || {}).rating_points || 0) - (this.personal.rating_points || 0);
    }

    inTop = () => this.personal.place <= this.state.promo_action.total_place_count;

    render() {
        const {promo_action: {total_place_count, place_count}, loading, loadInfo} = this.state;
        const {place, rating_points, show_nick, prize} = this.state.user_rating;

        return <div className="compendium">
            <div className="compendium__title">{I18n.t(`user.promo_action.${localePromoName()}.rating`)}</div>
            {loading
                ? <SmartLoader/>
                : <Fragment>
                    {!isGuest() && place && <BannerButtonPrize
                        points={rating_points}
                        place={place}
                        showName={show_nick}
                        prize={prize}
                        loading={loadInfo}
                        showNickHandler={this.showNickHandler}
                        totalPlaceCount={total_place_count}
                        placeCount={place_count}
                    />}
                    <TradeLink inTop={this.inTop()}/>
                    <div className="compendium__leaders-table">
                        <table className="compendium-leaders-table">
                            <thead>
                                <tr>
                                    <th>{I18n.t('table.place_title')}</th>
                                    <th>{I18n.t('table.nickname_title')}</th>
                                    <th>{promoActionStore.compendiumIsFinished()
                                        ? I18n.t('table.prize')
                                        : I18n.t('table.potential_prize')}</th>
                                    <th>RP</th>
                                </tr>
                            </thead>
                            <tbody>
                                <LeaderLines {...this.state}/>
                            </tbody>
                        </table>
                        <TableEmptyState
                            arrayLength={this.state.ratings.length}
                            text={I18n.t('empty_table.there_are_no_leaders_here_yet')}
                        />
                    </div>
                </Fragment>}
        </div>;
    }
}
