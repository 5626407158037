import React, {ReactNode, PureComponent} from 'react';
import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';
import {fixed2} from 'app/utils/fixed';

interface SystemCardProps {
    system: PaymentSystemInterface
}

export default class SystemCard extends PureComponent<SystemCardProps> {
    render(): ReactNode {
        const {system, system: {large_picture, deposit_bonus_amount_percent}} = this.props;

        return <div className="deposit-system-card">
            <div className="deposit-system-card__logo">
                {large_picture && large_picture.url && <img
                    src={large_picture.url}
                    alt={system.name}
                />}
            </div>
            {Number(deposit_bonus_amount_percent) > 0 && <div className="bonus-card">
                <svg className="icon">
                    <use xlinkHref="#svg-bonus-icon" fill="none"/>
                </svg>
                <span className="total">+{Number(fixed2(deposit_bonus_amount_percent * 100))}%</span>
            </div>}
        </div>;
    }
}
