import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from './bet_form/form';
import {inject, observer} from 'mobx-react';

@inject('lineStore')
@observer
export default class BetForm extends Component {
    static propTypes = {
        afterBet: PropTypes.func,
        formIdShow: PropTypes.string,
        gamerNum: PropTypes.number
    };

    render() {
        const {afterBet, formIdShow, idBetShow, gamerNum, selectedBet, lineStore: {line, userBets, seo}, popupId} = this.props;
        return <Form
            afterBet={afterBet}
            bet={line}
            formIdShow={formIdShow}
            selectedBet={selectedBet}
            idBetShow={idBetShow}
            gamerNum={gamerNum}
            seo={seo}
            userBets={userBets}
            popupId={popupId}
        />;
    }
}
