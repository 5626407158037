import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import SelectPicker from 'app/components/reusable/SelectPicker';
import CollectionInterface from 'app/interfaces/CollectionInterface';
import P2pDepositsStoreProps from 'app/store/P2pDepositsStoreProps';
import {DepositsStoreProps} from 'app/store/DepositsStore';
import {inject, observer} from 'mobx-react';

interface RelatedBankSelectProps extends P2pDepositsStoreProps, DepositsStoreProps {}

@inject('p2pDepositsStore', 'depositsStore')
@observer
export default class RelatedBankSelect extends Component<RelatedBankSelectProps> {
    handleBankChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        this.props.p2pDepositsStore.selectBankById(Number(e.target.value), true);
    };

    get banksCollection(): CollectionInterface[] {
        const {banks} = this.props.p2pDepositsStore;
        return banks.map(({name, id}) => ({text: name, value: id}));
    }

    render(): ReactNode {
        const {currentBank} = this.props.p2pDepositsStore;
        const {currentPaymentSystem} = this.props.depositsStore;

        if (!this.props.p2pDepositsStore.currentPaymentSystem || !currentPaymentSystem.related_to_bank_bins) {
            return null;
        }

        return <div className="form__row form__row-select">
            <SelectPicker
                onChange={this.handleBankChange}
                value={currentBank && currentBank?.id}
                collection={this.banksCollection}
                placeholder={I18n.t('select_bank')}
                className="select-bank"
            />
        </div>;
    }
}
