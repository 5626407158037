import React, {ReactNode, PureComponent} from 'react';
import {LoginSignUpButtons} from 'app/components/auth/log_in_sign_up_button';

export default class BarLoginMobile extends PureComponent {
    render(): ReactNode {
        return <div className="table-bets__footer-group">
            <div className="bets-footer">
                <LoginSignUpButtons
                    usingOn="TABLE_BALANCE_LINE"
                    redirectUrl="/play/bets"
                    className="auth-links"
                />
            </div>
        </div>;
    }
}
