import React, {PureComponent} from 'react';
import {smileImage} from 'app/utils';

export default class ChatSmileItem extends PureComponent {
    onClickHandler = e => {
        const {smile, insertSmile} = this.props;

        e && e.preventDefault();
        insertSmile(smile);
    };

    render() {
        const {smile} = this.props;
        return <a href="#"
            className="chat__smile-item"
            onClick={this.onClickHandler}>
            {smileImage(smile, 'chat__smile-image')}
        </a>;
    }
}
