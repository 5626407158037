import LineInterface from 'app/interfaces/LineInterface';
import {Lines} from 'app/lines/LinesService';
import EventInterface from 'app/interfaces/EventInterface';

type LinesUpdate = {
    bets: LineInterface[]
    events: EventInterface[]
};

export const linesDataForUpdate = (newLines: Lines, results: boolean = false): LinesUpdate => {
    const bets = lineSort(newLines, results);
    const events = [];
    bets.forEach(bet => {
        if (!events.find(event => event.tourn === bet.tourn)) {
            events.push({
                esport: bet.esports,
                filter: bet.filter,
                tourn: bet.tourn
            });
        }
    });
    return {
        bets,
        events
    };
};

const lineSort = (obj: Lines, results: boolean): LineInterface[] => {
    const lines = Object.values(obj) as unknown as LineInterface[];
    const sort_up = -1;
    return lines.sort((a, b) => {
        if (a.live && b.live) {
            return sortToLivesLines(a, b, results);
        }
        if (!a.live && !b.live) {
            return sortToNotLivesLines(a, b);
        }
        if (a.live) {
            return sort_up;
        }

        return 1;
    });
};

export const sortToLivesLines = (a: LineInterface, b: LineInterface, results: boolean): number => {
    if (results) {
        return b.id - a.id;
    }

    return Number(a.incubator) - Number(b.incubator) || Number(b.esports) - Number(a.esports) ||
        a.game_priority - b.game_priority ||
        a.tourn.toLowerCase().localeCompare(b.tourn.toLowerCase()) ||
        b.id - a.id;
};

export const sortToNotLivesLines = (a: LineInterface, b: LineInterface, desc = true): number => {
    if (b.date === a.date) {
        return Number(b.esports) - Number(a.esports) || b.id - a.id;
    }

    if (desc) {
        return Number(b.esports) - Number(a.esports) || b.date - a.date;
    }

    return Number(b.esports) - Number(a.esports) || a.date - b.date;
};
