import React, {Fragment, PureComponent} from 'react';
import {translatedDescription} from 'app/utils/translations_helper';
import isEqual from 'lodash/isEqual';
import Prize from './prize';
import {PrizeWrapper} from 'app/components/shop/lootboxes/lootbox_item';

export default class InventoryItemDescription extends PureComponent {
    state = {randomPrize: null};

    componentWillUpdate(nextProps) {
        const {isLootbox, openingLootbox} = this.props;

        if (isLootbox && nextProps.openingLootbox && !openingLootbox) {
            this.selectRandomPrize();
        }
    }

    selectRandomPrize = () => {
        const {randomPrize, animationOpenLootboxInvEnd} = this.state;
        let {loot} = this.props;

        if (animationOpenLootboxInvEnd) {
            return null;
        }

        if (randomPrize) {
            loot = loot.filter(l => !isEqual(l, randomPrize));
        }

        return this.setState(
            {randomPrize: loot[Math.floor(Math.random() * loot.length)]},
            () => setTimeout(this.selectRandomPrize, 250)
        );
    };

    render() {
        let {isLootbox} = this.props;
        const {children, item_type} = this.props;

        if (typeof isLootbox === 'undefined') {
            isLootbox = ['lootbox', 'shard_lootbox'].includes(item_type);
        }

        if (isLootbox) {
            return <Fragment>
                {children}
                <LootboxPrizes
                    {...this.props}
                    {...this.state}
                />
            </Fragment>;
        }

        return <p>{translatedDescription(this.props)}</p>;
    }
}

class LootboxPrizes extends PureComponent {
    render() {
        const {loot, animationOpenLootboxInvEnd, extra_data = [], randomPrize} = this.props;

        return <div className="lootbox__slots hero__slot-wrap">
            {loot.map((loot, i) => {
                const options = {loot};
                return <PrizeWrapper
                    key={i}
                    {...options}
                    extra_data={extra_data}
                    animationOpenLootboxInvEnd={animationOpenLootboxInvEnd}
                    randomPrize={randomPrize}
                >
                    <Prize {...options}/>
                </PrizeWrapper>;
            })}
        </div>;
    }
}
