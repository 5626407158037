import React, {PureComponent} from 'react';
import Header from './Header';
import Body from './Body';
import PropTypes from 'prop-types';
import SmartLoader from 'app/components/reusable/SmartLoader';

export default class ReferralsTable extends PureComponent {
    static propTypes = {data: PropTypes.array.isRequired};

    render() {
        const {data, loading} = this.props;

        return <div className="table-content">
            <Header/>
            {loading
                ? <SmartLoader/>
                : <div className="table-content-body table-content-referrals">
                    <Body data={data}/>
                </div>}
        </div>;
    }
}
