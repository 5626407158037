import $ from 'jquery';
import React, {Component, ReactNode, RefObject} from 'react';
import I18n from 'app/i18n';
import {getLinkPath, localDateTimeTzFromISO, slowConnection} from 'app/utils';
import {modalAlert, modalConfirm} from 'app/utils/modals/popups';

interface RowSelfExclusionProps {
 rowClass: string;
 errorHint: ReactNode
}

export class RowSelfExclusion extends Component<RowSelfExclusionProps> {
    selfExclusionSelect: RefObject<HTMLSelectElement> = React.createRef();

    componentDidMount(): void {
        $(this.selfExclusionSelect.current).chosen({disable_search: true}).trigger('chosen:updated');
    }

    selfExclude = (event: React.MouseEvent): void => {
        event.preventDefault();

        modalConfirm({
            element: <form onSubmit={this.doSelfExclude}>
                <div dangerouslySetInnerHTML={{__html: I18n.t('self_exclusion_message',
                    {date: I18n.t($('#field-self_exclusion').val())})}}/>
                <div>{I18n.t('enter_password')}</div>
                <div><input type="password" id="self_exclusion_password" className="field__input"/></div>
            </form>,
            react: true
        },
        this.doSelfExclude,
        null,
        {setFocus: false});
    };

    doSelfExclude = (event: React.FormEvent<HTMLFormElement>): void => {
        event && event.preventDefault();
        slowConnection($.ajax('/egb_users/self_exclude', {
            data: {
                password: $('#self_exclusion_password').val(),
                period: $('#field-self_exclusion').val()
            },
            error: () => modalAlert(I18n.t('user.incorrect_password')),
            success: data => modalAlert(data.date
                ? I18n.t('self_excluded', {date: localDateTimeTzFromISO(data.date)})
                : I18n.t('self_excluded_forever'),
            {okClick: () => {
                location.href = getLinkPath('/');
            }}),
            type: 'POST'
        }));
    };


    render(): ReactNode {
        const {rowClass, errorHint} = this.props;
        return <div className={rowClass}>
            <label className="field-label" id="self-exclusion" htmlFor="field-self_exclusion">
                {I18n.t('self_exclusion')}
            </label>
            <div className="field self-exclusion-selects">
                <div className="field__select self-exclusion-period classical-select">
                    <select ref={this.selfExclusionSelect}
                        className="chosen-select field__select-chosen"
                        id="field-self_exclusion"
                        name="self_exclusion_period">
                        {window.SELF_EXCLUSION_VALUES.map(v => <option value={v} key={v}>{I18n.t(v)}</option>)}
                    </select>
                    <div className="field__select-arrow"/>
                </div>
                <div className="field__select self-exclusion-exclude">
                    <button className="btn like-field-input"
                        onClick={this.selfExclude}>
                        {I18n.t('exclude')}
                    </button>
                </div>
            </div>
            {errorHint}
        </div>;
    }
}


