import React from 'react';
import TableHistoryHead from 'app/components/my_profile/table-history/TableHistoryHead';
import TableHistoryRow from 'app/components/my_profile/table-history/TableHistoryRow';
import {inject, observer} from 'mobx-react';
import {UserBetsStore} from 'app/store/UserBetsStore';
import {isDesktop} from 'app/utils';
import EmptyResults from 'app/components/articles/EmptyResults';
import TableHistoryStubPage from './TableHistoryStubPage';
import {betSlipStore} from 'app/store/global';
import ShowMore from 'app/utils/ShowMore';

interface TableHistoryProps {
    userBetsStore?: UserBetsStore
    hasBets: boolean
    reloadBets?: () => void
}

@inject('userBetsStore')
@observer
export default class TableHistory extends React.Component<TableHistoryProps> {
    render(): React.ReactNode {
        const {userBetsStore} = this.props;
        const {userBets} = userBetsStore;

        return this.props.hasBets
            ? <div className="table-content">
                {isDesktop() && <TableHistoryHead/>}
                <div className="table-content-body table-content-history">
                    {userBets && userBets.map(userBet => <TableHistoryRow
                        isOpened={userBetsStore.openedUserBetId === Number(userBet.id) &&
                                betSlipStore.selectedLine?.id === Number(userBet.bet.id)}
                        actionsButtons
                        key={userBet.id}
                        betMore
                        {...userBet}
                        reloadBets={this.props.reloadBets}/>)}
                </div>
                {userBets.length > 0
                    ? <ShowMore
                        onClickHandler={userBetsStore.updateLoadedBetsHandler}
                        has_more={userBetsStore.filter.has_more}/>
                    : <EmptyResults/>}
            </div>
            : <TableHistoryStubPage route={'/play/bets'}/>;
    }
}
