import $ from 'jquery';
import React, {Component, ReactNode, RefObject} from 'react';
import I18n from 'app/i18n';
import moment from 'moment/moment';
import DayOfBirthInterface from 'app/interfaces/DayOfBirthInterface';

const ADULT = 18;

const NUMBEROFMONTH = 12;

const DAYSINMONTH = 31;

const YEARS = 101;

interface RowDayOfBirthProps {
    rowClass: string;
    errorHint: ReactNode;
    cleanError: (field: string) => void;
    dayOfBirth?: DayOfBirthInterface
    disabled?: boolean
}

export const defaultBirthDay = (): DayOfBirthInterface => [
    {
        format: 'DD',
        key: 'day'
    },
    {
        format: 'MM',
        key: 'month'
    },
    {
        format: 'YYYY',
        key: 'year'
    }
].map(obj => ({[obj.key]: moment({year: new Date().getFullYear() - ADULT}).format(obj.format)}))
    .reduce((a, b) => ({
        ...a,
        ...b
    }));

export class RowDayOfBirth extends Component<RowDayOfBirthProps> {
    daySelect: RefObject<HTMLSelectElement> = React.createRef();

    monthSelect: RefObject<HTMLSelectElement> = React.createRef();

    yearSelect: RefObject<HTMLSelectElement> = React.createRef();

    componentDidMount(): void {
        [
            this.daySelect,
            this.monthSelect,
            this.yearSelect
        ].forEach(s => {
            $(s.current).chosen({disable_search: true}).trigger('chosen:updated');
        });
    }

    get monthNames(): string[] {
        return Array(...Array(NUMBEROFMONTH)).map((_, i) => I18n.t(`global.month_${i + 1}_1`));
    }

    cleanError = (): void => {
        this.props.cleanError('base');
    };

    render(): ReactNode {
        const yearNow = new Date().getFullYear();
        const {rowClass, errorHint, disabled, dayOfBirth: {day, month, year} = defaultBirthDay()} = this.props;

        return <div className={rowClass} onClick={this.cleanError} key="birthDay">
            <label className="field-label">{I18n.t('day_of_birth')}</label>
            <div className="date-selects classical-select">
                <div className="field">
                    <div className="field__select date-selects_day">
                        <select ref={this.daySelect} className="chosen-select field__select-chosen"
                            id="egb_user_day_of_birth_3i" name="day_of_birth(3i)"
                            defaultValue={Number(day)} disabled={disabled}>
                            {Array(...Array(DAYSINMONTH)).map((_, i) => <option value={i + 1} key={i}>{i + 1}
                            </option>)}
                        </select>
                        <div className="field__select-arrow"/>
                    </div>
                </div>
                <div className="field">
                    <div className="field__select date-selects_month">
                        <select ref={this.monthSelect} className="chosen-select field__select-chosen"
                            id="egb_user_day_of_birth_2i" name="day_of_birth(2i)"
                            defaultValue={Number(month)} disabled={disabled}>
                            {this.monthNames.map((m, i) => <option value={i + 1} key={i}>{m}</option>)}
                        </select>
                        <div className="field__select-arrow"/>
                    </div>
                </div>
                <div className="field">
                    <div className="field__select date-selects_year">
                        <select ref={this.yearSelect} className="chosen-select field__select-chosen"
                            id="egb_user_day_of_birth_1i" name="day_of_birth(1i)"
                            defaultValue={Number(year)} disabled={disabled}>
                            {Array(...Array(YEARS)).map((_, i) => <option value={yearNow - 100 + i}
                                key={i}>{yearNow - 100 + i}</option>)}
                        </select>
                        <div className="field__select-arrow"/>
                    </div>
                </div>
            </div>
            {errorHint}
        </div>;
    }
}
