import React, {PureComponent, ReactNode} from 'react';
import {getLinkPath} from 'app/utils/link_helper';
import {scrollToTop} from 'app/utils';
import {Link} from 'react-router-dom';
import I18n from 'app/i18n';

interface AppLinkProps {
    handleAppLinkClose: () => void
}

export default class AppLink extends PureComponent<AppLinkProps> {
    render(): ReactNode {
        return <div className="app__link__holder">
            <button
                className="act app__link__close js-close-block-btn"
                onClick={this.props.handleAppLinkClose}>
                <svg height="8px" width="8px">
                    <use xlinkHref="#svg-close"/>
                </svg>
            </button>
            <Link
                title={I18n.t('open_apps_page')}
                to={getLinkPath('/apps')} className="app__link" onClick={scrollToTop}>
                <svg height="16px" width="13px">
                    <use xlinkHref="#svg-ios"/>
                </svg>
                <svg height="16px" width="15px">
                    <use xlinkHref="#svg-android"/>
                </svg>
            </Link>
        </div>;
    }
}

