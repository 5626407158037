import React, {Component} from 'react';
import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';
import I18n from 'app/i18n';
import {Link} from 'react-router-dom';
import {linkDepositSystem} from 'app/utils/app_links';
import {inject, observer} from 'mobx-react';
import {DepositsStoreProps} from 'app/store/DepositsStore';

@inject('depositsStore')
@observer
export default class SuggestedDeposit extends Component<DepositsStoreProps> {
    suggestedSystem(): PaymentSystemInterface {
        const {systems, depositSuggestions} = this.props.depositsStore;
        const system = this.props.depositsStore.currentPaymentSystem;

        if (!system || !depositSuggestions || !depositSuggestions[system.id]) {
            return null;
        }

        const suggestedDepositId = depositSuggestions[system.id];
        return systems.find(s => s.id === suggestedDepositId);
    }

    render(): React.ReactNode {
        const system = this.suggestedSystem();

        if (!system) {
            return null;
        }
        return <div className="left-inner-padding left-inner-padding_message">
            <div className="message-for-users">
                <span className="message-for-users__icon">
                    <svg className="svg-default">
                        <use xlinkHref="#svg-message-warning"/>
                    </svg>
                </span>
                <div className="message-for-users__message">
                    <p>{I18n.t('deposit_suggestion')} <Link to={linkDepositSystem(system.slug)}>{system.name}</Link></p>
                </div>
            </div>
        </div>;
    }
}
