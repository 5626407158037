import $ from 'jquery';
import React, {Fragment, PureComponent} from 'react';
import I18n from 'app/i18n';
import {localePromoName, promoUrl, serverError} from 'app/utils';
import Rating from './rating';
import MyPredictions from './my_predictions';
import NoPredictions from './predictions/no_predictions';
import ConversionCard from './predictions/ConversionCard';
import Prediction from './prediction';
import SmartLoader from 'app/components/reusable/SmartLoader';

const FIVE_SECONDS = 5000;

class Predictions extends PureComponent {
    constructor() {
        super();
        this.state = {
            loading: true,
            predictionId: null,
            predictions: [],
            showAnswers: false
        };
        this.showAnswersHandler = this.showAnswersHandler.bind(this);
        this.overlayClickHandler = this.overlayClickHandler.bind(this);
        this.updatePredictionsHandler = this.updatePredictionsHandler.bind(this);
        this.submitAnswer = this.submitAnswer.bind(this);
        this.timerId = null;
    }

    componentDidMount() {
        this.updatePredictionsHandler(() => $.get(promoUrl('predictions'), data => this.setState(data))
            .fail(() => serverError())
            .always(() => this.setState({loading: false})));
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
    }

    updatePredictionsHandler(func) {
        if (func) {
            func();
        } else {
            $.get(promoUrl('predictions'), data => this.setState(data));
        }
        this.timerId = setTimeout(this.updatePredictionsHandler, FIVE_SECONDS);
    }

    submitAnswer(predictionId) {
        const prediction = this.state.predictions.find(p => p.id === predictionId);
        prediction.answer_was_given = true;
        this.setState({predictions: [].concat(this.state.predictions)});
    }

    showAnswersHandler(predictionId) {
        this.setState({
            predictionId,
            showAnswers: !this.state.showAnswers
        });
    }

    overlayClickHandler() {
        this.state.predictionId && this.refs[this.state.predictionId].showAnswersHandler();
    }

    overlayClass() {
        const classes = ['compendium-grid__global-overlay'];

        if (this.state.showAnswers) {
            classes.push('compendium-grid__global-overlay--shown');
        }
        return classes.join(' ');
    }

    render() {
        const {predictions, predictionId, showAnswers, loading} = this.state;

        return <div className="compendium">
            <div className="compendium__title">{I18n.t(`user.promo_action.${localePromoName()}.predictions`)}</div>
            {loading
                ? <SmartLoader/>
                : <Fragment>
                    {predictions.length === 0 && <NoPredictions/>}
                    <div className="compendium-grid">
                        <div className={this.overlayClass()} onClick={this.overlayClickHandler}/>
                        <div className="compendium-grid__items">
                            <ConversionCard/>
                            {predictions.map(prediction => <Prediction key={prediction.id}
                                ref={prediction.id}
                                showAnswersHandler={this.showAnswersHandler}
                                reward={prediction.reward}
                                submitAnswer={this.submitAnswer}
                                answerWasGiven={prediction.answer_was_given}
                                selected={predictionId === prediction.id && showAnswers}
                                {...prediction}/>)}
                        </div>
                    </div>
                </Fragment>}
        </div>;
    }
}

export default {
    MyPredictions,
    Predictions,
    Rating
};
