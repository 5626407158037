import React from 'react';
import {inject, observer} from 'mobx-react';
import {BetSlipStore} from 'app/store/BetSlipStore';
import BetSlipController from 'app/components/bet_slip/BetSlipController';
import {BetSlipBody} from 'app/components/bet_slip/BetSlipBody/BetSlipBody';

interface BetSlipBodyContainerInterface {
    betSlipStore?: BetSlipStore
    controller?: BetSlipController
}

export const BetSlipBodyContainer = inject('controller', 'betSlipStore')(
    observer(
        (props: BetSlipBodyContainerInterface): JSX.Element => {
            const {type, loadingForm, showGamerNumber} = props.betSlipStore;
            const {bet} = props.controller;

            return <BetSlipBody
                loading={loadingForm || !bet || !bet.selectedLine}
                type={type}
                showGamerNumber={showGamerNumber}
            />;
        }
    )
);
