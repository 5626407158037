import React from 'react';

const GridPage1 = (): React.JSX.Element => <>
    <div className="container-content">
        <span className="title-h1">Кнопки (заголовок первого уровня)</span>
    </div>
    <div className="container-content">
        <span className="title-h2">Различные варианты кнопок (заголовок второго уровня)</span>
    </div>
    <div className="container-content">
        <span className="title-h3">Различные варианты кнопок (заголовок третьего уровня)</span>
    </div>
    <div className="container-content">
        <span className="title-h4">Различные варианты кнопок (заголовок четвертого уровня)</span>
    </div>
    <div className="container-content">
        <span className="button-adaptive">Кнопка (вид 1)</span>
    </div>
    <div className="container-content">
        <span className="button-adaptive button-adaptive_uppercase">Кнопка (вид 2)</span>
    </div>
    <div className="container-content">
        <span className="button-adaptive button-adaptive_transparent">Кнопка (вид 3)</span>
    </div>
    <div className="container-content">
        <span className="button-adaptive button-adaptive_link">Кнопка (вид 4)</span>
    </div>
    <div className="container-content">
        <span className="button-adaptive button-adaptive_full">Кнопка (вид 5)</span>
    </div>
    <div className="container-content">
        <span className="button-adaptive button-adaptive_full-thin">Кнопка (вид 6)</span>
    </div>
    <div className="container-content">
        <span className="button-adaptive button-adaptive_full-thin-icon">Кнопка (вид 7)</span>
    </div>
    <div className="container-content">
        <div className="button-adaptive button-adaptive_full-thin-icon">
            <span className="button-icon">
                <svg>
                    <use xlinkHref="#svg-arrow-link"/>
                </svg>
            </span>
            <span className="button-name">Кнопка c иконкой (вид 8)</span>
        </div>
    </div>
    <div className="container-content">
        <span className="button-adaptive button-adaptive_full-radius">Кнопка (вид 9)</span>
    </div>
    <div className="container-content">
        <div className="button-adaptive button-adaptive_crystal">
            <span className="icon">
                <svg>
                    <use xlinkHref="#svg-zoom-icon"/>
                </svg>
            </span>
            <span className="name">Кнопка с иконкой (вид 10)</span>
        </div>
    </div>
    <div className="container-content">
        <span className="button-adaptive button-adaptive_gray">Кнопка (вид 11)</span>
    </div>
    <div className="container-content">
        <span className="button-adaptive_casino-icon">
            <svg className="svg-default">
                <use xlinkHref="#svg-button-eye"/>
            </svg>
            <span className="button-adaptive__name">Кнопка с иконкой (вид 14)</span>
        </span>
    </div>
    <div className="container-content">
        <span className="button-adaptive button-adaptive_big">Кнопка (вид 15)</span>
    </div>
    <div className="container-content">
        <span className="button-adaptive button-adaptive_bigger-one">Кнопка (вид 17)</span>
    </div>
    <div className="container-content">
        <div className="button-adaptive button-adaptive_instyle">
            <svg className="svg-default button-icon">
                <use xlinkHref="#svg-arrow-link-new"/>
            </svg>
            <span className="button-name">Кнопка (вид 18)</span>
        </div>
    </div>
    <div className="container-content">
        <span className="button-adaptive button-adaptive_disable">Кнопка (вид 19)</span>
    </div>
</>;

export default GridPage1;
