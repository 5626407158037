import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {getLinkPath} from 'app/utils/link_helper';

export default class PublicInventoryLink extends PureComponent {
    static propTypes = {user: PropTypes.object.isRequired};

    render() {
        const {user: {name}} = this.props;

        return <Link to={getLinkPath(`/profile/${name}`)}>
            {name}
        </Link>;
    }
}
