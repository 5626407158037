import UserInterface from 'app/interfaces/UserInterface';

export const userBalance = (user: UserInterface): string => {
    const {balance, demo_balance, demo} = user;

    if (demo) {
        return String(demo_balance);
    }

    return balance;
};
