import CollectionStore from 'stores/CollectionStore';
import {UserStore} from 'app/store/UserStore';
import {makeObservable} from 'mobx';

export interface IncubatorShiftInterface {
    id: number
    pledge: number
    initial_pledge: number
    initial_bonus_balance: number
    profit: number
    acceptance_bets_rate: number
}

export class IncubatorShiftStore extends CollectionStore<IncubatorShiftInterface> {
    channel = (): string => null;

    feedUrl = (): null => null;

    constructor(userStore: UserStore) {
        super();
        makeObservable(this);
        if (userStore.user.id) {
            this.channel = (): string => `$incubator-shifts-${userStore.user.id}`;
        }
    }

    currentShift(): IncubatorShiftInterface {
        if (this.collection.length === 0) {
            return null;
        }
        return this.collection[this.collection.length - 1];
    }

    initialPledge(): number {
        const currentShift = this.currentShift();

        if (!currentShift) {
            return 0;
        }
        return this.currentShift().initial_pledge;
    }


    availablePledge(): number {
        const currentShift = this.currentShift();

        if (!currentShift) {
            return 0;
        }

        return currentShift.pledge;
    }
}
