import React, {ReactElement} from 'react';
import {streamFlagImage, streamGameImage} from 'app/utils';
import I18n from 'app/i18n';

interface StreamNotificationProps {
    data: {
        game: string
        author_flag: string
        title: string
    }
}

const Stream = (data: StreamNotificationProps): ReactElement => <div className="notification__text">
    {I18n.t('stream_one')}{'\u00A0'}
    {streamGameImage(data.data.game, true)}{streamFlagImage(data.data.author_flag, true)}
    {I18n.t('stream_add', {stream_name: data.data.title})}
</div>;

export default Stream;
