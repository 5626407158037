import React, {ReactNode} from 'react';
import I18n from 'app/i18n';

interface PropsInterface {
    labelTooltip: string
    forLabelTooltip?: string
    descriptionTooltip: string
    additionalDescriptionTooltip?: string
    classTooltip?: string
}

export default class SmartLabelTooltip extends React.PureComponent<PropsInterface> {
    render(): ReactNode {
        const {
            forLabelTooltip,
            labelTooltip,
            descriptionTooltip,
            additionalDescriptionTooltip,
            classTooltip
        } = this.props;

        return <div className="label-tooltip">
            <label
                className="field__label"
                htmlFor={forLabelTooltip}
            >{I18n.t(labelTooltip)}</label>
            <div className={`field__tooltip ${classTooltip ? classTooltip : 'default-position'}`}>
                <span className="icon">
                    <svg className="svg-default">
                        <use xlinkHref="#svg-icon-tooltip"/>
                    </svg>
                </span>
                <div className="container-description">
                    <div className="description">
                        <p>{I18n.t(descriptionTooltip)}</p>
                        {additionalDescriptionTooltip && <p>{I18n.t(additionalDescriptionTooltip)}</p>}
                    </div>
                </div>
            </div>
        </div>;
    }
}
