import React, {Component} from 'react';
import TimeToStartTimer from 'app/utils/TimeToStartTimer';
import Progress from './progress';

export default class ScheduledComponent extends Component {
    get isRenderable() {
        const {start_date, currentTimeStore: {t}} = this.props;

        return start_date > t;
    }

    render() {
        if (!this.isRenderable) return null;

        const {start_date} = this.props;

        return <div className="compendium-aside__content">
            <div className="compendium-aside__row">
                <div className="compendium-aside__progress-column only-timer">
                    <Progress
                        {...this.props}
                        styleTop={0}
                    />
                </div>
                <div className="compendium-aside__info-column">
                    <TimeToStartTimer
                        {...this.props}
                        time_to_start={start_date}
                        daysComponentName="with_zero_days"
                        textClass="compendium-aside__item-timer"
                        timerClass="compendium-aside__item-time-left"
                    />
                </div>
            </div>
        </div>;
    }
}