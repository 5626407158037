import {processingMessage} from 'app/components/chat/chat_utils';
import {ResponseInterface, LanguageInterface} from 'app/store/ChatStore';
import {MessageInterface} from 'app/interfaces/stores/chat/MessageInterface';
import {PreparedMessages} from 'app/interfaces/chat/PreparedMessages';
import forEachRight from 'lodash/forEachRight';

interface prepareParams {
    messages: MessageInterface[],
    langParams: LanguageInterface,
    mutedUsersList: number[]
}

const NO_FOUND_MESSAGE_INDEX = -1;

const ifMsgExistsPushOrDelete = (message: MessageInterface, messages: MessageInterface[]): MessageInterface[] => {
    if (message.d) {
        const index = messages.findIndex(m => m.id === message.id);

        if (index > NO_FOUND_MESSAGE_INDEX) {
            messages.splice(index, 1);
        }
    } else {
        messages.push(processingMessage(message));
        if (messages.length > 100) {
            messages.shift();
        }
    }
    return messages;
};

const isNotMutedUser = (params: prepareParams, user_id: number): boolean => {
    if (params.mutedUsersList.length > 0) {
        return !params.mutedUsersList[user_id];
    }

    return true;
};

export const prepareMessages = (data: ResponseInterface, params: prepareParams): PreparedMessages => {
    let tempUpdatedAt = 0;
    let update = false;
    let newMessages = params.messages;

    forEachRight(data.m, message => {
        if ((message.ua > params.langParams.currentTime ||
                message.ua === params.langParams.currentTime && !params.messages.find(m => m.id === message.id)) &&
            isNotMutedUser(params, message.uid)) {
            tempUpdatedAt = message.ua;
            newMessages = ifMsgExistsPushOrDelete(message, newMessages);
            update = true;
        }
    });

    return {messages: newMessages, tempUpdatedAt, update};
};

export default prepareMessages;
