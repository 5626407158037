import React from 'react';
import I18n from 'app/i18n';
import {Link} from 'react-router-dom';
import SmartLoader from 'app/components/reusable/SmartLoader';

interface ShowMorePropsInterface {
    has_more: boolean,
    onClickHandler: () => Promise<void>
}

interface ShowMoreStateInterface {
    mounted: boolean,
    loading: boolean,
}

export default class ShowMore extends React.Component<ShowMorePropsInterface, ShowMoreStateInterface> {
    constructor(props: ShowMorePropsInterface) {
        super(props);
        this.state = {
            loading: false,
            mounted: true
        };
    }

    componentWillUnmount(): void {
        this.setState({mounted: false});
    }

    disableLoading = (): void => {
        if (this.state.mounted) {
            this.setState({loading: false});
        }
    };

    loadMore = (e: React.MouseEvent): void => {
        e && e.preventDefault();

        this.setState({loading: true});
        this.props
            .onClickHandler()
            .then(this.disableLoading)
            .catch(this.disableLoading);
    };

    render(): React.ReactNode {
        const {has_more} = this.props;

        if (!has_more) {
            return null;
        }

        const {loading} = this.state;

        return <div className="table-more">
            {loading
                ? <SmartLoader />
                : <Link
                    to="#"
                    className="cursor-pointer"
                    onClick={this.loadMore}>
                    {I18n.t('user.show_10_more')}
                </Link>}
        </div>;
    }
}
