import {fixed2} from 'app/utils';

export const validationAmount = (newAmount: string, amount: string, limit: number): string => {
    let tempNewAmount = newAmount;

    if (tempNewAmount && !(/^\d+[,.]?\d{0,2}$/u).test(tempNewAmount)) {
        return amount;
    }

    if (Number(tempNewAmount) > limit) {
        return fixed2(limit);
    }

    if (typeof tempNewAmount === 'string') {
        tempNewAmount = tempNewAmount.replace(',', '.');
    }
    return tempNewAmount;
};
