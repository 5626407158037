import $ from 'jquery';
import React, {ReactNode} from 'react';
import I18n from 'app/i18n';
import MyBetInterface from 'app/interfaces/MyBetInterface';
import {betMoney, date, fixed3, isDesktop, time} from 'app/utils';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import TableHistoryRowExpressBet from './TableHistoryRowExpressBet';
import {unixNow} from 'app/utils/time/unixNow';
import {expressToChat} from 'app/utils/share_to_chat';
import SmartTooltipReason from 'app/components/reusable/SmartTooltipReason';
import {SimpleArtifactImage} from '../artifacts/image';
import {toastSuccess} from 'app/components/toasts/liteToast';

const CHAT_AVAILABLE_TIME_SECONDS = 86400;

const toggleExpress = e => {
    e.preventDefault();
    const parentElement = $(e.target).closest('.table-content-row-events').find('.history-match-events');
    $(e.target).closest('.button-match-events').toggleClass('active');
    parentElement.toggleClass('active');
};

const toggleExpressMobile = e => {
    e.preventDefault();
    const parentElement = $(e.target).closest('.table-content-row-mobile').find('.history-match-events');
    $(e.target).closest('.button-match-events').toggleClass('active');
    parentElement.toggleClass('active');
};

export default class TableHistoryRowExpress extends React.Component<MyBetInterface> {
    chatButtonClick = (e: React.MouseEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        expressToChat(this.props.id);
    };

    buttonsAvailable(): boolean {
        return this.chatButtonAvailable() || this.cancelReasonButtonAvailable();
    }

    cancelReasonButtonAvailable(): boolean {
        return this.props.cancelled && this.props.cancelled_reason !== null;
    }

    chatButtonAvailable(): boolean {
        const {cancelled, closed, cancelled_date} = this.props;

        if (closed) {
            return unixNow() - cancelled_date < CHAT_AVAILABLE_TIME_SECONDS && !cancelled;
        }
        return !cancelled;
    }

    shareInChatButton(): ReactNode {
        return this.chatButtonAvailable() && <button
            key={`share_chat_button_${this.props.id}`}
            className="button-adaptive button-adaptive_instyle"
            onClick={this.chatButtonClick}
        >
            <svg className="svg-default button-icon">
                <use xlinkHref="#svg-arrow-link-new"/>
            </svg>
            <span className="button-name">{I18n.t('chat_title')}</span>
        </button>;
    }

    colorClassName = (): string => {
        if (this.props.cancelled) {
            return ' match-canceled';
        }

        if (this.props.closed && this.props.win > 0) {
            return ' match-won';
        }

        if (this.props.closed && this.props.win <= 0) {
            return ' match-lost';
        }

        return '';
    };

    onClickCopyMessage = (): void => {
        toastSuccess(I18n.t('express_bet_copied'));
    };

    renderMobile(): ReactNode {
        const {playmoney, amount, coef, id, bets} = this.props;

        return <div className={`table-content-row-mobile${this.colorClassName()}`}>
            <div className="history-match">
                <div className="history-match-date">
                    <span>{date(this.props.date)}</span>
                    <span>{time(this.props.date)}</span>
                    <div className="history-match-id">
                        <span className="history-match-id-number">#{id}</span>
                        <div className="copy-button">
                            <CopyToClipboard text={id} onCopy={this.onClickCopyMessage}>
                                <span className="icon">
                                    <svg className="svg-default">
                                        <use xlinkHref="#svg-copy-icon"/>
                                    </svg>
                                </span>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
                <div className="history-details-events">
                    <span className="history-details-events-title">{I18n.t('express')}</span>
                    <button className="button-match-events" onClick={toggleExpressMobile}>
                        {I18n.t('events', {count: bets ? bets.length : 0})}
                        <svg className="svg-default icon">
                            <use xlinkHref="#svg-arrow-for-menu"/>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="table-content-inner-row-mobile">
                <div className="cell">
                    <span className="cell-title">{I18n.t('bet')}:</span>
                    <span className="history-sum">{betMoney({amount, playmoney})}</span>
                </div>
                <div className="cell">
                    <span className="history-match-coefficient">{fixed3(coef)}</span>
                </div>
                <div className="cell">
                    <span className="cell-title">{I18n.t('won')}:</span>
                    <span className="history-match-sum">{this.props.win > 0 ||
                    !this.props.cancelled && !this.props.closed
                        ? betMoney({amount: amount * coef, playmoney})
                        : '-'}</span>
                </div>
            </div>
            <div className="history-match-events">
                {this.props.bets.map(express => <TableHistoryRowExpressBet key={express.id} bet={express}/>)}
            </div>
            {this.buttonsAvailable() && <div className="history-right-buttons">
                {this.cancelReasonButtonAvailable() ? <SmartTooltipReason {...this.props}/> : this.shareInChatButton()}
            </div>}
        </div>;
    }

    render(): React.ReactNode {
        const {playmoney, amount, bet_artifacts, coef, id, bets, win_artifacts} = this.props;

        if (!isDesktop()) {
            return this.renderMobile();
        }

        return <div className={`table-content-row table-content-row-events${this.colorClassName()}`}>
            <div className="table-content-inner-row">
                <div className="table-content-cell">
                    <div className="table-events">
                        <span className="history-match-date">
                            <span>{date(this.props.date)}</span>
                            <span>{time(this.props.date)}</span>
                        </span>
                        <button className="button-match-events" onClick={toggleExpress}>
                            {I18n.t('events', {count: bets ? bets.length : 0})}
                            <svg className="svg-default icon">
                                <use xlinkHref="#svg-arrow-for-menu"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="table-content-cell">
                    <span className="history-match-sum"> {bet_artifacts
                        ? <div className="history-match-artifacts">
                            {bet_artifacts.map((a, i) => <>
                                {Array.from({length: a.total_inventory_items
                                    ? a.total_inventory_items
                                    : 1}, (val, index) => <span
                                    key={`artifact_item_${index}`}
                                    className="history-match-artifact">
                                    <SimpleArtifactImage
                                        isClickable
                                        imgAttributes={{className: 'image-inventory cursor-pointer'}}
                                        artifact={a}
                                        key={index + i}
                                    />
                                </span>)}
                            </>)}
                        </div>
                        : <span className="history-match-sum">{betMoney({amount, playmoney})}</span>}</span>
                </div>
                <div className="table-content-cell">
                    <span className="history-match-coefficient">{fixed3(coef)}</span>
                </div>
                <div className="table-content-cell">
                    {win_artifacts
                        ? <div className="history-match-artifacts">
                            {win_artifacts.map((a, i) => <>
                                {Array.from({length: a.total_inventory_items
                                    ? a.total_inventory_items
                                    : 1}, (val, index) => <span
                                    key={`win_artifact_item_${index}`}
                                    className="history-match-artifact">
                                    <SimpleArtifactImage
                                        isClickable
                                        imgAttributes={{className: 'image-inventory cursor-pointer'}}
                                        artifact={a}
                                        key={index + i}
                                    />
                                </span>)}
                            </>)}
                        </div>
                        : <span className="history-match-sum">{this.props.win > 0 ||
                        !this.props.cancelled && !this.props.closed
                            ? betMoney({amount: amount * coef, playmoney})
                            : '-'}</span>}
                </div>
                <div className="table-content-cell">
                    <div className="history-match-id">
                        <span className="history-match-id-number">{id}</span>

                        <div className="copy-button">
                            <CopyToClipboard text={id} onCopy={this.onClickCopyMessage}>
                                <span className="icon">
                                    <svg className="svg-default">
                                        <use xlinkHref="#svg-copy-icon"/>
                                    </svg>
                                </span>
                            </CopyToClipboard>
                        </div>
                    </div>
                    {this.buttonsAvailable() && <div className="right-side-cell">
                        {this.cancelReasonButtonAvailable()
                            ? <SmartTooltipReason {...this.props}/>
                            : this.shareInChatButton()}
                    </div>}
                </div>
            </div>
            <div className="history-match-events">
                {this.props.bets.map(express => <TableHistoryRowExpressBet key={express.id} bet={express}/>)}
            </div>
        </div>;
    }
}
