/* eslint "react/jsx-no-bind": "error" */
import $ from 'jquery';
import React, {MouseEvent, PureComponent} from 'react';
import I18n from 'app/i18n';
import {passwordOpener, SubmitButton} from 'app/utils';
import Post from 'app/utils/Post';
import {toastSuccess} from 'app/components/toasts/liteToast';

const FIELDS = [
    {
        field: 'current_password',
        translate: 'your_password'
    },
    {
        field: 'password',
        translate: 'new_password'
    },
    {
        field: 'password_confirmation',
        translate: 'password_once_again'
    }
];

interface IFormData {
    [key: string]: unknown;
}

interface IResponseFailed {
    [k:string]: { string };
}

interface RefsInterface {
    current_password?: React.RefObject<HTMLInputElement>
    password?: React.RefObject<HTMLInputElement>
    password_confirmation?: React.RefObject<HTMLInputElement>
}

class ChangePassword extends PureComponent {
    state = {errors: {}};

    references = this.refsByField();

    refsByField(): RefsInterface {
        const refs: RefsInterface = {};
        FIELDS.forEach(item => {
            refs[item.field] = React.createRef();
        });
        return refs;
    }

    componentDidMount(): void {
        passwordOpener().init();
    }

    obtainFormData = (): IFormData => (
        {user: {
            current_password: this.references.current_password.current.value,
            password: this.references.password.current.value,
            password_confirmation: this.references.password_confirmation.current.value
        }}
    );

    emptyInputError = (): IResponseFailed => {
        const errors = {};

        FIELDS.forEach(el => {
            if (this.references[el.field].current.value.length === 0) {
                errors[el.field] = {0: I18n.t('required_field')};
            }
        });

        return errors;
    };

    onClickUpdateProfile = (onSubmit:(x: boolean) => void): void => {
        const errors = this.emptyInputError();

        if (Object.keys(errors).length > 0) {
            this.handleFailedResponse(errors);
            onSubmit(false);
        } else {
            this.updateProfile(onSubmit);
        }
    };

    updateProfile = (onSubmit:(x: boolean) => void): void => {
        const data = this.obtainFormData();
        new Post({
            params: data,
            url: '/user'
        })
            .execute()
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    this.handleSuccessResponse();
                } else {
                    this.handleFailedResponse(response.errors);
                }
            })
            .finally(() => onSubmit(false));
    };

    handleSuccessResponse = (): void => {
        this.clearForm();
        this.setState({errors: {}});
        toastSuccess(I18n.t('user.profile_has_changed_successfully'));
    };

    handleFailedResponse = (errors: IResponseFailed): void => {
        this.setState({errors: {...errors}});
    };

    clearForm(): void {
        if (this.references.current_password.current.value !== '') {
            $('.js-password-btn').click();
        }
        FIELDS.forEach(field_obj => {
            this.references[field_obj.field].current.value = '';
        });
    }

    checkError(field: string): boolean {
        return this.state.errors[field] ? this.state.errors[field][0] : false;
    }


    removeError = (prop: string) => (e: MouseEvent<HTMLInputElement>): void => {
        e.preventDefault();
        const obj = {...this.state.errors};

        if (this.checkError(prop)) {
            delete obj[prop];
            this.setState({errors: obj});
        }
    };

    render(): React.ReactElement {
        return <div className="profile__settings row-columns">
            <div className="profile__buttons-holder js-buttons-hide">
                <button className="btn js-password-btn" type="button">{I18n.t('user.change_of_password')}</button>
            </div>

            <div className="column-half password-fields js-password-fields">
                <form>
                    {FIELDS.map(field => <div key={field.field} className="popup-form__row">
                        <div className={`profile__field field${ this.checkError(field.field) ? ' field-error' : ''}`}>
                            <div className="field__wrap">
                                <input className={`field__input ${field.field}`}
                                    name={`user[${field.field}]`}
                                    ref={this.references[field.field]}
                                    autoComplete={field.field}
                                    required={true}
                                    type="password"
                                    onClick={this.removeError(field.field)}/>
                                <span className="field__placeholder">{I18n.t(`user.${field.translate}`)}</span>
                                {this.checkError(field.field) &&
                                <div className="field__error-hint">{this.checkError(field.field)}</div>}
                            </div>
                        </div>
                    </div>)}
                    <SubmitButton title={I18n.t('user.save')} okClick={this.onClickUpdateProfile}/>
                </form>
            </div>
        </div>;
    }
}

export default ChangePassword;
