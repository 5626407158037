import {isGuest} from 'app/utils';
import ConvertButtonBase from '../ConvertButtonBase';
import {inject, observer} from 'mobx-react';

class ConvertButton extends ConvertButtonBase {
    defaultClassNames = (): string[] => ['btn btn--invert'];

    isTimerUnavailable = (): boolean => {
        const {promoAction: {conversion_available, next_conversion_in}} = this.props.promoActionStore;

        return isGuest() || conversion_available || !next_conversion_in;
    };
}

export default inject('promoActionStore')(observer(ConvertButton));
