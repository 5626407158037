import $ from 'jquery';
import React, {Component} from 'react';
import Cookies from 'js-cookie';

export default class ChatScroll extends Component {
    state = {
        hideScroll: true,
        iOSDevice: Boolean(navigator.platform.match(/iPhone|iPod|iPad/u))
    };

    rootElement = React.createRef();

    componentDidMount() {
        const scrollElement = $(this.rootElement.current);

        $(this.rootElement.current).on('ps-y-reach-end', () => $('.custom-scroll-shadow').removeClass('has-shadow'));

        $(this.rootElement.current).on('ps-scroll-up', () => {
            const $scrollShadow = $('.custom-scroll-shadow');

            if (!$scrollShadow.hasClass('has-shadow')) {
                $scrollShadow.addClass('has-shadow');
            }
        });

        const resizableInit = () => {
            const DAYS_IN_YEAR = 365;
            const FIVE_YEAR = 5;
            const resizableElement = $('.chat__message-list');
            resizableElement.css('height', `${Number(Cookies.get('chat-height'))}px`);
            resizableElement.resizable({
                direction: 'bottom',
                resize: () => {
                    this.scrollDown();
                },
                stop: () => Cookies.set('chat-height', resizableElement.outerHeight(), {
                    expires: DAYS_IN_YEAR * FIVE_YEAR
                })
            });
        };

        if (!this.props.fullScreen) {
            resizableInit();
        }

        $(window).resize(() => {
            this.scrollDown();
        });

        scrollElement.scroll(() => {
            const scrollTopCeil = Math.ceil(scrollElement.scrollTop());
            const scrollTop = scrollElement.scrollTop();
            let hideScroll = scrollElement[0].scrollHeight - (scrollTop + scrollElement[0].clientHeight);
            const FIVE_PIXELS = 5;

            if (hideScroll > FIVE_PIXELS) {
                hideScroll = scrollElement[0].scrollHeight - (scrollTopCeil + scrollElement[0].clientHeight);
            }
            hideScroll = hideScroll <= FIVE_PIXELS;
            if (this.state.hideScroll !== hideScroll) {
                this.setState({hideScroll});
            }
        });
        this.scrollDown();
    }

    componentDidUpdate() {
        this.scrollDown();
    }

    scrollDown() {
        if (this.state.hideScroll || this.props.scrollDown) {
            const scrollElement = $(this.rootElement.current);

            if (scrollElement[0]) {
                scrollElement.scrollTop(scrollElement[0].scrollHeight);
            }
            $(this.rootElement.current).trigger('ps-y-reach-end');
        }
    }

    render() {
        const {children} = this.props;
        return <div className="chat__scroll" ref={this.rootElement}>
            {children}
        </div>;
    }
}
