import {
    BLUE, DRAW, NO_RESULT, RED, GREEN,
    STATUS_CANCEL_COMPLETED,
    STATUS_COMPLETED,
    STATUS_DELAY,
    STATUS_NORMAL,
    STATUS_REVERTED
} from 'admin/components/bet_form/utils/LineConsts';
import LineType from 'admin/types/LineType';

export const isOpened = (color: number): boolean => color === BLUE;

export const isRedOrGreen = (color: number): boolean => color === RED || color === GREEN;

export const isOpenedStatus = (status: number): boolean => STATUS_NORMAL === status || STATUS_REVERTED === status;

export const isNotOpened = (status: number): boolean => !isOpenedStatus(status);

export const isDelayed = (status: number): boolean => status === STATUS_DELAY;

export const valueWithSign = (value: string, play_money = false): string => `${play_money ? '' : '$'}${value}`;

export const isActiveStatus = (status: number): boolean => [STATUS_NORMAL, STATUS_REVERTED].includes(status);

export const isActiveLine = (status: number, deleted: boolean): boolean => isActiveStatus(status) && !deleted;

export const isClosedLine = (status: number): boolean => [STATUS_COMPLETED, STATUS_CANCEL_COMPLETED].includes(status);

export const disableClass = (val: boolean): string => val ? 'disabled-element' : '';

export const isHandicap = ({type}: {type: string | LineType}): boolean => ['Advantage', 'Handicap'].includes(type);

const COEF_CHANGING_VALUE_LENGTH_TO_FLOAT = 2;
const MAX_COEF_CHANGING = 9.9;

export const checkedCoefChanging = (value: number): number => {
    let res = value;
    if (res.toString().length === COEF_CHANGING_VALUE_LENGTH_TO_FLOAT && !(/[.]/u).test(res.toString())) {
        res = parseFloat(res.toString().split('').join('.'));
    }
    if (res > MAX_COEF_CHANGING) {
        res = MAX_COEF_CHANGING;
    }

    return res;
};

export const canChangeScore = (winner: number): boolean => winner >= NO_RESULT || winner === DRAW;
