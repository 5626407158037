import BaseValidation from 'validations/BaseValidation';

export default class UrlValidation extends BaseValidation<string, null> {
    execute(): boolean {
        try {
            return Boolean(new URL(this.attribute));
        } catch (e) {
            return false;
        }
    }
}
