import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {getLinkPath} from 'app/utils/link_helper';
import appAndroidSvg from 'images/svg_icons/app-android.svg';
import appInfoSvg from 'images/svg_icons/app-info.svg';
import appManual1Png from 'images/app/manual/manual1.png';
import appManual2Png from 'images/app/manual/manual2.png';
import appManual3Png from 'images/app/manual/manual3.png';
import appManual4Png from 'images/app/manual/manual4.png';
import appManual5Png from 'images/app/manual/manual5.png';
import appManual6Png from 'images/app/manual/manual6.png';
import {Link} from 'react-router-dom';
import logEvent from 'app/amplitude/log_event';
import appNativeGifRu from 'images/app/app-gif-ru.gif';
import appNativeGifEng from 'images/app/app-gif-eng.gif';

export default class AndroidManual extends PureComponent {
    rootElement = React.createRef();

    handleDownload = () => {
        logEvent('MOBILE_WRAPPER_DOWNLOADED');
    };

    render() {
        return <div ref={this.rootElement} className="app-manual">
            <div className="app-manual--item app-manual--item-header">
                <div className="app-manual--item--text">
                    <div className="app-manual--item--title">
                        <h1>{I18n.t('android_installation_guide')}</h1>
                    </div>
                    <div className="app-manual--item--descr app-manual--item--descr-gray">
                        <p>{I18n.t('to_start_downloading_app')}:</p>
                        <p>{I18n.t('do_long_tap_download_button')} <span className="highlight">
                            «{I18n.t('download_app')}»</span>,<br/>
                        {I18n.t('and_then_press')} <span className="highlight">«{I18n.t('open_in_incognito')}»</span>
                        </p>
                    </div>
                    <div className="app-manual--item--actions mobile--hidden">
                        <a
                            href={getLinkPath('/apps/android/download/')}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="btn btn--large btn--invert btn--big-on-mobile"
                            onClick={this.handleDownload}
                        >
                            <img
                                src={appAndroidSvg}
                                alt={I18n.t('download_app')}
                                width="25px"
                                height="29px"
                            />
                            {I18n.t('download_app')}
                        </a>
                    </div>
                </div>
                <div className="app-manual--item--img">
                    {window.CURRENT_LOCALE === 'ru'
                        ? <img src={appNativeGifRu} alt="Android приложение"/>
                        : <img src={appNativeGifEng} alt="Android app"/>}
                </div>
                <div className="app-manual--item--actions mobile--show">
                    <a
                        href={getLinkPath('/apps/android/download/')}
                        rel="noopener noreferrer"
                        target="_blank"
                        className="btn btn--large btn--invert btn--big-on-mobile"
                        onClick={this.handleDownload}
                    >
                        <img
                            src={appAndroidSvg}
                            alt={I18n.t('download_app')}
                            width="25px"
                            height="29px"
                        />
                        {I18n.t('download_app')}
                    </a>
                </div>
            </div>
            <div className="app-manual--items">
                <div className="app-manual--item">
                    <div className="app-manual--item--text">
                        <div className="app-manual--item--title">
                            <h2>{I18n.t('download_app')}</h2>
                        </div>
                        <div className="app-manual--item--descr app-manual--item--descr-short">
                            {I18n.t('android_installation_guide_1')}
                        </div>
                        <div className="app-manual--item--info app-manual--item--info-icon">
                            <img
                                src={appInfoSvg}
                                alt="App info"
                                width="20px"
                                height="20px"
                            />
                            <div>
                                {I18n.t('android_installation_note_1')}
                            </div>
                        </div>
                    </div>
                    <div className="app-manual--item--img">
                        <img src={appManual1Png} alt={I18n.t('download_app')}/>
                    </div>
                </div>
                <div className="app-manual--item">
                    <div className="app-manual--item--text">
                        <div className="app-manual--item--title">
                            <h2>{I18n.t('settings')}</h2>
                        </div>
                        <div className="app-manual--item--descr">
                            {I18n.t('android_installation_guide_2')}
                        </div>
                        <div className="app-manual--item--info">
                            {I18n.t('android_installation_note_2')}
                        </div>
                    </div>
                    <div className="app-manual--item--img">
                        <img src={appManual2Png} alt={I18n.t('settings')}/>
                    </div>
                </div>
                <div className="app-manual--item">
                    <div className="app-manual--item--text">
                        <div className="app-manual--item--title">
                            <h2>{I18n.t('security')}</h2>
                        </div>
                        <div className="app-manual--item--descr">
                            {I18n.t('android_installation_guide_3')}
                        </div>
                    </div>
                    <div className="app-manual--item--img">
                        <img src={appManual3Png} alt={I18n.t('security')}/>
                    </div>
                </div>
                <div className="app-manual--item">
                    <div className="app-manual--item--text">
                        <div className="app-manual--item--title">
                            <h2>{I18n.t('unknown_sources')}</h2>
                        </div>
                        <div className="app-manual--item--descr">
                            {I18n.t('android_installation_guide_4')}
                        </div>
                    </div>
                    <div className="app-manual--item--img">
                        <img src={appManual4Png} alt={I18n.t('unknown_sources')}/>
                    </div>
                </div>
                <div className="app-manual--item">
                    <div className="app-manual--item--text">
                        <div className="app-manual--item--title">
                            <h2>{I18n.t('downloads_app')}</h2>
                        </div>
                        <div className="app-manual--item--descr">
                            {I18n.t('android_installation_guide_5')}
                        </div>
                    </div>
                    <div className="app-manual--item--img">
                        <img src={appManual5Png} alt={I18n.t('downloads_app')}/>
                    </div>
                </div>
                <div className="app-manual--item">
                    <div className="app-manual--item--text">
                        <div className="app-manual--item--title">
                            <h2>{I18n.t('access_permission')}</h2>
                        </div>
                        <div className="app-manual--item--descr">
                            {I18n.t('android_installation_guide_6')}
                        </div>
                    </div>
                    <div className="app-manual--item--img">
                        <img src={appManual6Png} alt={I18n.t('access_permission')}/>
                    </div>
                    <div className="app-manual--item--footer">
                        <a
                            href={getLinkPath('/apps/android/download/')}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="btn btn--invert btn--large btn--big-on-mobile"
                            onClick={this.handleDownload}
                        >
                            <img
                                src={appAndroidSvg}
                                alt={I18n.t('download_app')}
                                width="25px"
                                height="29px"
                            />
                            {I18n.t('download_app')}
                        </a>
                    </div>
                </div>
            </div>
            <div className="app-manual--footer">
                <div className="app-manual--footer--wrap">
                    <div className="app-manual--footer--title">
                        {I18n.t('you_can_now_enjoy_the_egb_android_app')}
                    </div>
                    <div className="app-manual--footer--text">
                        {I18n.t('android_installation_footer')}
                    </div>
                    <div className="app-manual--footer--actions">
                        <Link
                            to={getLinkPath('/contacts')}
                            className="btn btn--invert btn--large btn--big-on-mobile"
                        >
                            {I18n.t('menu_links.contacts')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>;
    }
}
