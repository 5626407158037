import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import AlertPoint from './AlertPoint';
import {SteamTradeStore} from 'app/store/SteamTradeStore';

interface MenuAlertPointProps {
    steamTradeStore?: SteamTradeStore
}

export default inject('steamTradeStore')(observer(class MenuAlertPoint extends Component<MenuAlertPointProps> {
    render() {
        return this.props.steamTradeStore.hasSteamTradesWithWrongLink() ? <AlertPoint/> : null;
    }
}));
