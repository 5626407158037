import React, {Component, ReactNode} from 'react';
import {UserStore} from 'app/store/UserStore';
import {inject, observer} from 'mobx-react';
import {betMoney} from 'app/utils';

interface Props {
    userStore?: UserStore
}

@inject('userStore')
@observer
export default class PokerMenuButtonBalance extends Component<Props> {
    render(): ReactNode {
        const {user: {balance}} = this.props.userStore;

        return <button className="poker-menu-container-button balance">
            <span className="value">{betMoney({amount: balance})}</span>
        </button>;
    }
}
