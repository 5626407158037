import React, {PureComponent} from 'react';
import I18n from 'app/i18n';

interface EmptyResultsProps {
    text?: string
}

export default class EmptyResults extends PureComponent<EmptyResultsProps> {
    render(): React.ReactNode {
        return <div className="search-no-found no-results">
            <span className="background-image"/>
            <div className="container-text">
                <p>{this.props.text || I18n.t('empty_results')}</p>
            </div>
        </div>;
    }
}
