import React, {PureComponent} from 'react';
import concat from 'lodash/concat';
import chunk from 'lodash/chunk';
import ShopItem from './shop_item';
import EmptyShopResult from './EmptyShopResult';

export default class ShopItems extends PureComponent {
    render() {
        const {lootboxes = [], user, currency, noChunks, hiddenEmpty, game, afterBuy} = this.props;

        if (lootboxes.length === 0) {
            return hiddenEmpty ? null : <EmptyShopResult currency={this.props.currency}/>;
        }

        if (noChunks) {
            return <div className="market__lootboxes--row">
                {lootboxes.map(lootbox => <ShopItem
                    key={lootbox.id}
                    game={game}
                    currency={currency}
                    {...lootbox}
                    afterBuy={afterBuy}
                    user={user}
                />)}
            </div>;
        }

        return concat(chunk(lootboxes, 4).map((chunk, i) => <div
            key={i}
            className="market__lootboxes--row"
        >
            {chunk.map(lootbox => <ShopItem
                key={lootbox.id}
                game={game}
                currency={currency}
                {...lootbox}
                user={user}
            />)}
        </div>));
    }
}
