import {action, computed, makeObservable, observable} from 'mobx';
import PlaceInterface from 'app/interfaces/PlaceInterface';
import ImageInterface from 'app/interfaces/ImageInterface';
import {EsportContent} from 'app/components/pages/esport/EsportShow';

export interface ContentInterface {
    my_bonuses?: unknown
    my_freespins?: unknown
    contacts?: unknown
    esports?: unknown
    esport?: EsportContent
    component?: string
    prizes?: PlaceInterface[]
    predictions_count?: number
    footer_content?: unknown
    footer_content_license?: unknown
    why_egb_content?: string
    why_egb_heading?: string
    play_favorite_game?: unknown
    landing_page?: {
        id: number,
        page_component: string
        title_1: string
        title_2: string
        content: string
        main_image: ImageInterface
        main_mobile_image: ImageInterface
        background_image: ImageInterface
        background_mobile_image: ImageInterface
        promo_action_name: string
        promo_action_received_total: string
        prizes_text_color: string
        second_text_color: string
        text_color: string
    }
}

export class PageContentStore {
    @observable content: ContentInterface = null;

    @observable contentKey: string = '';

    @observable justLoaded = false;

    @observable loading = true;

    constructor() {
        makeObservable(this);
    }

    @computed
    get contentByKey(): unknown {
        return this.content[this.contentKey];
    }

    @action
    contentByContentKey(contentKey?: string): unknown {
        if (!this.content) {
            return this.content;
        }
        return contentKey ? this.content[contentKey] : this.contentByKey;
    }

    @action
    initUpdate(content: ContentInterface): void {
        if (content) {
            this.content = content;
            this.justLoaded = true;
        }
    }

    @action
    updateContent(content: unknown): void {
        this.content = Object.assign(this.content || {}, content);
        this.shownLoaded();
        this.loading = false;
    }

    @action updateContentKey(contentKey: string, force = false): void {
        if (contentKey || force) {
            this.contentKey = contentKey;
        }
    }

    @action
    shownLoaded(): void {
        this.justLoaded = false;
    }

    @action
    startLoading(): void {
        this.loading = true;
    }
}
