import React, {ReactNode} from 'react';
import PokerMainPot from 'app/components/poker/poker_pot/PokerMainPot';

interface Props {
    pot: string
}

export default class PokerPot extends React.Component<Props> {
    render(): ReactNode {
        return <div className="poker-pot">
            <PokerMainPot value={this.props.pot}/>
            {/* <div className="poker-side-pots-group">
                <div className="poker-side-pots">
                    <PokerSidePot/>
                    <PokerSidePot/>
                </div>
                <div className="poker-side-pots">
                    <PokerSidePot/>
                    <PokerSidePot/>
                    <PokerSidePot/>
                </div>
            </div> */}
        </div>;
    }
}
