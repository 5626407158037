import React, {Component} from 'react';
import StreamerPhoto from 'images/landing-ladder/streamers/streamer-photo.svg';
import GamerInterface from 'app/interfaces/GamerInterface';

interface LandingLadderGameStreamerPhotoProps {
    gamer: GamerInterface
}

export default class LandingLadderGameStreamerPhoto extends Component<LandingLadderGameStreamerPhotoProps> {
    render(): React.ReactNode {
        const {gamer} = this.props;
        return <span className={`streamer-photo${gamer.photo.src ? '' : ' avatar'}`}>
            <img
                src={gamer.photo.src ? gamer.photo.src : StreamerPhoto}
                alt={gamer.nick}
                title={gamer.nick}
            />
        </span>;
    }
}
