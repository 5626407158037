import React from 'react';

export default class WarningMessage extends React.PureComponent {
    render() {
        return <div className="compendium__warning compendium__warning--shown">
            <div className="compendium__warning-content">
                <div className="compendium__warning-icon">
                    <svg height="65px" width="70px">
                        <use xlinkHref="#svg-warning"/>
                    </svg>
                </div>
                {this.props.children}
            </div>
        </div>;
    }
}
