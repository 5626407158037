import React, {ReactNode} from 'react';
import I18n from 'app/i18n';

interface PropsInterface {
    itemClassName: string
    localePromotionTitle: string
    svgIconName: string
    localePromotionText: string
    bannerType: string
    svgIconGameType?: string
    active: boolean
    onClick: () => void
}

export default class DepositBonusTypeButton extends React.Component<PropsInterface> {
    onClick = (): void => {
        this.props.onClick();
    };

    render(): ReactNode {
        const {active} = this.props;

        return <div className="user-promotion">
            <div
                className={`user-promotion-content ${this.props.itemClassName}${active ? ' active' : ''}`}
                onClick={this.onClick}
            >
                <div className="user-promotion-content__title">
                    {this.props.svgIconGameType && <svg className="svg-default promotion-icon-type">
                        <use xlinkHref={`#${this.props.svgIconGameType}`}/>
                    </svg>}
                    <span className="promotion-name">{I18n.t(this.props.localePromotionTitle)}</span>
                    <svg className="svg-default promotion-icon">
                        <use xlinkHref={`#svg-promotion-${this.props.svgIconName}`}/>
                    </svg>
                </div>
                <div className="user-promotion-content__text">
                    <p>{I18n.t(this.props.localePromotionText)}</p>
                </div>
            </div>
        </div>;
    }
}
