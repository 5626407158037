import React, {Component, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import {uploadDocumentsLink} from 'app/components/DocumentsUpload/upload_documents';
import {ArticlesStore} from 'app/store/ArticlesStore';
import UserNavBottomOriginal from 'site_version/com/userNavBottom';

interface UserNavBottomProps {
    articlesStore?: ArticlesStore
}

@inject('userStore', 'articlesStore')
@observer
export default class UserNavBottom extends Component<UserNavBottomProps> {
    constructor(props: UserNavBottomProps) {
        super(props);
        this.props.articlesStore.initPromoNew();
    }

    render(): ReactNode {
        return <div className="user-nav">
            {uploadDocumentsLink()}
            <UserNavBottomOriginal/>
        </div>;
    }
}
