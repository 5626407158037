import React, {Fragment} from 'react';
import PromoActionBeer from 'app/components/promo_action/beer';
import Image from 'app/helpers/Image';
import I18n from 'app/i18n';
import FAQButton from 'app/components/promo_action/modules/FAQButton';
import {COCKTAIL_IMAGE} from 'app/consts';

export default class PromoActionCocktail extends PromoActionBeer {
    beerGlassParts = () => <Fragment>
        <div className="beer-glass__background beer-glass__cocktail">
            <Image image={{url: COCKTAIL_IMAGE[this.imagePropName].BACKGROUND}}/>
        </div>
        <div style={{bottom: `${this.nextPrizeProgress()}%`}} className="beer-glass__liquid beer-glass__cocktail">
            <Image image={{url: COCKTAIL_IMAGE[this.imagePropName].LIQUID}}/>
        </div>
    </Fragment>;

    promoActionTitle = () => <Fragment>
        <span>
            {I18n.t('promo_action.cocktail.header')}
        </span>
        <FAQButton FAQPath="/faq/egb-sunshine-party"/>
    </Fragment>;

    get imagePropName() {
        return 'PROMO_ACTION_COCKTAIL';
    }
}
