import React, {ReactNode, MouseEvent, PureComponent} from 'react';
import I18n from 'app/i18n';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {showPopup} from 'app/utils/modals/ModalsService';
import {showMutedUsersListPopup} from 'app/components/chat/MutedUsersListPopup';
import {ChatStore} from 'app/store/ChatStore';
import {modalsStore} from 'app/store/global';

interface Props {
    chatStore: ChatStore,
    userName: string,
    userId: number,
    id?: number
    popupId: string
}

export const showUserMutePopup = (userId: number, userName: string, chatStore: ChatStore): void => {
    showPopup('mute-user', (props: {popupId: string}) => <UserMutePopup
        popupId={props.popupId}
        userId={userId}
        userName={userName}
        chatStore={chatStore}/>);
};

export default class UserMutePopup extends PureComponent<Props> {
    userMute = (e: MouseEvent): void => {
        e.preventDefault();
        this.props.chatStore.userMute(this.props.userName, this.props.userId, this.close);
    };

    close = (): void => {
        modalsStore.hideModal(this.props.popupId);
    };

    showMutedUsersListPopup = (e: MouseEvent): void => {
        e.preventDefault();
        this.close();
        showMutedUsersListPopup(this.props.chatStore);
    };

    render(): ReactNode {
        const {userName, popupId} = this.props;
        return <PopupDialog id={popupId}>
            <div className="popup__content popup__content--small">
                <div className="block-user">
                    <div className="title">{I18n.t('mute_user')}</div>
                    <p className="block-user__text">{I18n.t('are_you_sure_you_want_to_mute_this_user')}</p>

                    <div className="block-user__user">{userName}</div>
                    <a href="#" className="block-user__link-list" onClick={this.showMutedUsersListPopup}>
                        {I18n.t('see_list_of_all_muted')}</a>

                    <div className="block-user__submit-row">
                        <button className="btn btn--invert" onClick={this.close}>{I18n.t('no_cancel')}</button>
                        <button className="btn" onClick={this.userMute}>{I18n.t('yes_block')}</button>
                    </div>
                </div>
            </div>
        </PopupDialog>;
    }
}
