import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {fixed2} from 'app/utils/fixed';
import {modalConfirm} from 'app/utils/modals/popups';
import {steamTradeStore} from 'app/store/global';
import {amountWithSign} from 'app/utils/format';

export default class SellNowSteamSkin extends PureComponent {
    handleSell = () => {
        const {afterSell, steam_skin: {price, name}, steam_trade} = this.props;

        modalConfirm(I18n.t('sell_steam_skin_confirm', {
            amount: amountWithSign(price),
            artifact: name
        }),
        () => steamTradeStore.sell(steam_trade.id, name, amountWithSign(price), afterSell, this.props.popupId));
    };

    render() {
        const {steam_skin, steam_trade, btnClass, btnRemoveClassBtn} = this.props;

        if (!steam_skin || !steam_trade) {
            return null;
        }

        const {price} = steam_skin;

        if (steam_trade.sold) {
            const {soldClassName} = this.props;
            return <span className={soldClassName}>{I18n.t('sold_for', {amount: fixed2(steam_trade.price)})}</span>;
        }
        return <button
            className={`${btnRemoveClassBtn ? '' : 'btn'} ${steam_trade.sellable ? '' : 'btn--disable'} ${btnClass}`}
            onClick={this.handleSell}
            disabled={!steam_trade.sellable}
        >
            {I18n.t('sell_for', {amount: amountWithSign(price)})}
        </button>;
    }
}
