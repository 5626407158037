import React, {ReactNode} from 'react';
import I18n from 'app/i18n';

interface Props {
    disabled?: boolean
    onClick?: () => void
}

export default class PokerPanelButtonFold extends React.Component<Props> {
    render(): ReactNode {
        return <button
            className="button-adaptive button-adaptive_casino"
            disabled={this.props.disabled}
            onClick={this.props.onClick}
        >
            {I18n.t('poker_fold')}
        </button>;
    }
}
