import React, {PureComponent, ReactNode} from 'react';

interface BetSlipMenuTabsProps{
    active: boolean
    type: string
    switchTab: (type: string) => void
    disabled: boolean
    children?: React.ReactNode
}

export default class BetSlipMenuButton extends PureComponent<BetSlipMenuTabsProps> {
    switchTab = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        const {switchTab, type} = this.props;
        switchTab(type);
    };

    render(): ReactNode {
        const {active, children, disabled} = this.props;
        return <button disabled={disabled} className={`bet-slip-tab-menu-button ${active ? 'active' : ''}`}
            onClick={this.switchTab}>
            {children}
        </button>;
    }
}
