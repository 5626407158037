import {betMoney} from 'app/utils';
import React, {useCallback} from 'react';
import {isPlayMoney} from './bet_maker';

export const DigitalAmountButton = props => {
    const {setAmount, amount} = props;
    const handleSetAmount = useCallback(e => {
        setAmount(amount, e);
    }, [setAmount, amount]);

    return <a href="#"
        className="match-details__bet"
        onClick={handleSetAmount}>
        {betMoney({playmoney: isPlayMoney(props)}, props.amount, !props.float)}
    </a>;
};
