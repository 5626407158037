import $ from 'jquery';
import {inject, observer} from 'mobx-react';
import React, {Component, ReactNode, RefObject, MouseEvent} from 'react';
import ChatMessage from 'app/components/chat/ChatMessage';
import ChatScroll from 'app/components/chat/chat_scroll';
import {continueRender, isComponentLoaded} from 'app/utils/listLazyRendering';
import {MessageInterface} from 'app/interfaces/stores/chat/MessageInterface';
import LazyRendererStatesInterface from 'app/interfaces/LazyRendererStatesInterface';
import {promoActionStore} from 'app/store/global';

const PIXELS = 24;
const FIRST_MESSAGES = 25;

export interface MessagesPropsInterface {
    messages: MessageInterface[],
    scrollDown: boolean,
    fullScreen: boolean,
    insertUserName: (name: string) => void,
    currentLang: string
}

@inject('promoActionStore')
@observer
export default class Messages extends Component<MessagesPropsInterface, LazyRendererStatesInterface> {
    state = {itemsRendered: false};

    rootElement: RefObject<ChatScroll> = React.createRef();

    componentDidMount(): void {
        $(this.rootElement.current.rootElement.current).on('click', '.chat__actions-toggle', (e: MouseEvent): void => {
            const actions = $(e.target).closest('.chat__actions');
            $('.chat__actions.active').not(actions).removeClass('active');
            actions.toggleClass('active');

            const $chatActions = actions.closest('div.chat__message').find('.chat__actions-list'),
                $chatContainer = actions.closest('div.chat__scroll'),
                chatActionChildCount = $chatActions.children().length,
                chatActionChildHeight = $('.chat__actions-item', actions).outerHeight().toFixed(),
                chatActionsHeight = chatActionChildHeight * (chatActionChildCount ? chatActionChildCount : 1) + PIXELS;

            let topOpened = actions.closest('div.chat__message').position().top +
                chatActionsHeight > $chatContainer.height();
            if (!topOpened) {
                topOpened = actions.closest('div.chat__message').offset().top +
                    chatActionsHeight > $(window).height() + $(window).scrollTop();
            }

            $chatActions.removeClass('open-up');
            if (topOpened) {
                $chatActions.addClass('open-up');
            }
        });

        if (this.props.messages.length !== 0) {
            continueRender(this as Component<MessagesPropsInterface, LazyRendererStatesInterface>);
        }
    }

    componentDidUpdate(prevProps: MessagesPropsInterface): void {
        if (prevProps.messages.length === 0 && this.props.messages.length !== 0) {
            continueRender(this as Component<MessagesPropsInterface, LazyRendererStatesInterface>);
        }
    }

    giftsEnabled = (): boolean => promoActionStore.promoActions !== null &&
        'PromoActionPresent' in promoActionStore.promoActions &&
        promoActionStore.promoActions.PromoActionPresent.time_to_start === 0;

    get messages(): MessageInterface[] {
        return isComponentLoaded(this as Component<MessagesPropsInterface, LazyRendererStatesInterface>)
            ? this.props.messages
            : this.props.messages.slice(this.props.messages.length - FIRST_MESSAGES);
    }

    render(): ReactNode {
        return <ChatScroll
            ref={this.rootElement}
            scrollDown={this.props.scrollDown}
            fullScreen={this.props.fullScreen}
        >
            {this.messages.map(message => <ChatMessage
                key={`message-${message.id}-${this.props.currentLang}`}
                insertUserName={this.props.insertUserName}
                giftsEnabled={this.giftsEnabled()}
                message={message}
            />)}
        </ChatScroll>;
    }
}
