import React, {memo, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import I18n from 'app/i18n';
import InnerTabs from 'app/components/InnerTabs';
import GiftsTable from 'app/components/my_profile/prizes/GiftsTable';
import LootboxesTable from 'app/components/my_profile/prizes/LootboxesTable';
import PromoActionsTable from 'app/components/my_profile/prizes/PromoActionsTable';
import GiveawayTable from './prizes/GiveawayTable';
import TableSource from '../TableSource';

const tabs = () => [
    {
        query: {type: 'gifts'},
        title: I18n.t('gifts_title')
    },
    {
        query: {type: 'lootboxes'},
        title: null
    },
    {
        query: {type: 'promo_actions'},
        title: I18n.t('my_prizes.promo_title')
    },
    {
        query: {type: 'giveaway'},
        title: I18n.t('giveaway')
    }
];

const Prizes = memo(() => {
    const [searchParams] = useSearchParams();
    const type = searchParams.get('type');
    const [selectedTab, setSelectedTab] = useState(type || 'gifts');

    const handleChangeTab = tab => {
        setSelectedTab(tab);
    };

    return (
        <div className="tabs tabs--top-slim profile-tab">
            <div className="tabs__content-item">
                <div className="prizes">
                    <div className="tabs js-tabs tabs--secondary">
                        <div className="tabs__inner__header">
                            <InnerTabs
                                queryParam="type"
                                selectedTab={selectedTab}
                                tabs={tabs()}
                                handleChange={handleChangeTab}
                            />
                        </div>
                    </div>
                    {selectedTab === 'gifts' && <TableSource sourceUrl="/my_prizes">
                        <GiftsTable/>
                    </TableSource>}
                    {selectedTab === 'lootboxes' && <TableSource sourceUrl="/my_prizes/lootboxes">
                        <LootboxesTable key="lootboxes"/>
                    </TableSource>}
                    {selectedTab === 'promo_actions' && <TableSource sourceUrl="/my_prizes/promo_actions">
                        <PromoActionsTable/>
                    </TableSource>}
                    {selectedTab === 'giveaway' && <TableSource sourceUrl="/my_prizes/giveaway">
                        <GiveawayTable/>
                    </TableSource>}
                </div>
            </div>
        </div>);
});
export default Prizes;
