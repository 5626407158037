import $ from 'jquery';
import React, {useState, useRef} from 'react';
import I18n from 'app/i18n';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {Auth} from './auth_popup';
const INTERNAL_SERVER_ERROR = 500;

const ResetPassword = ({popupId, token}) => {
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const passwordRef = useRef(null);
    const passwordConfirmationRef = useRef(null);

    const resetPassword = e => {
        e.preventDefault();

        if (submitting) {
            return;
        }

        setError(null);
        setSubmitting(true);

        $.ajax({
            url: '/egb_users/password',
            type: 'PUT',
            data: {
                egb_user: {
                    password: passwordRef.current.value,
                    password_confirmation: passwordConfirmationRef.current.value,
                    reset_password_token: token
                }
            },
            dataType: 'json',
            success: data => {
                if (data.success) {
                    location.href = '/play/bets';
                }
                setSubmitting(false);
            },
            error: err => {
                if (err.status === INTERNAL_SERVER_ERROR) {
                    setError(I18n.t('server_error_try_later'));
                } else {
                    const parsedError = JSON.parse(err.responseText);
                    setError(parsedError.error || Object.values(parsedError.errors)[0][0]);
                }
                setSubmitting(false);
            }
        });
    };

    return (
        <PopupDialog id={popupId} setFocus>
            <div className="popup__content">
                <form
                    className={`popup-form${error ? ' popup-form--error' : ''}`}
                    onKeyUp={e => Auth.clearFiledError(this, e)}
                    onSubmit={resetPassword}>
                    <div className="popup-form__row">{I18n.t('forgot_password_title')}</div>
                    <div className="popup-form__error-hint">{error}</div>
                    <div className="popup-form__row">
                        <label htmlFor="field-forgot-password">{I18n.t('password')}</label>
                        <div className={`field${error ? ' field-error' : ''}`}>
                            <input ref={passwordRef}
                                id="field-forgot-password"
                                name="egb_user[password]"
                                className="field__input"
                                type="password"/>
                        </div>
                    </div>
                    <div className="popup-form__row">
                        <label htmlFor="field-forgot-password-confirm">{I18n.t('user.password_once_again')}</label>
                        <div className={`field${error ? ' field-error' : ''}`}>
                            <input ref={passwordConfirmationRef}
                                id="field-forgot-password-confirm"
                                name="egb_user[password_confirmation]"
                                className="field__input"
                                type="password"/>
                        </div>
                    </div>
                    <div className="popup-form__submit-row">
                        <input type="submit"
                            className="btn btn--large"
                            disabled={submitting}
                            value={I18n.t('user.save')}/>
                    </div>
                </form>
            </div>
        </PopupDialog>
    );
};

export default ResetPassword;

