import $ from 'jquery';
import {userSignedIn} from 'app/utils';

export const USER_GROUP = 4;
export const MAX_TEXT_LENGTH = 255;

export const hideChatActions = () => {
    $('.chat__actions.active').removeClass('active');
};

export const getLangMessages = (props, lang) => props[`messages_${lang || props.currentLang}`];

export const fetchMessages = props => {
    const newArray = [];
    const messages = getLangMessages(props);
    const {mutedUsersList} = props;
    const countMutedUsers = Object.keys(mutedUsersList).length;

    if (messages.length > 0) {
        messages.forEach(message => {
            if (countMutedUsers > 0 && !mutedUsersList[message.uid] || countMutedUsers <= 0) {
                newArray.push(message);
            }
        });
    }

    return newArray;
};

export const getLangParams = (props, lang) => props[`params_${lang || props.currentLang}`];

export const rowColor = (userGroup, userId, receiver) => {
    const className = 'chat__';

    if (userGroup === 1) {
        return `${className}admin`;
    } else if (userGroup === 11) {
        return `${className}moderator`;
    }
    return userSignedIn() && userId === window.settings.user.id
        ? 'chat__message-to-nickname--current'
        : receiver ? `${className}user-receiver` : '';
};

export const getColorByName = (name, receiver) => { // TODO move to server
    let userGroup = USER_GROUP;
    if (name === 'admin') {
        userGroup = 1;
    } else if (name === 'moderator') {
        userGroup = 11;
    }
    return rowColor(userGroup, userSignedIn() && name === window.settings.user.name
        ? window.settings.user.id
        : null,
    receiver);
};

export const processingMessage = message => {
    message.m = message.m.replace(/\[b\](.*?)\[\/b\]/g, (match, content) => `<span class="chat__message-to-nickname ${getColorByName(content, true)}" data-nickname="${content}">${content}</span>`);
    message.m = message.m.replace(/\[u\](.*?)\[\/u\]/g, '<u style="text-decoration:underline;">$1</u>');
    message.m = message.m.replace(/\[i\](.*?)\[\/i\]/g, '<em>$1</em>');
    return message;
};

export const cyrillic = str => (/[а-яА-Я]/).test(str);

export const activeTab = (props, lang) => props.currentLang === lang ? ' active' : '';
