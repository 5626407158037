import React from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastContent from 'app/components/toasts/Contents/ToastContent';
import BaseToast from './BaseToast';
import {MAIN_CONTAINER_ID} from 'app/utils/Consts';

export default class ErrorToast extends BaseToast {
    notify = (): React.ReactText => toast.error(<ToastContent
        content={this.props.content}>
        <use xlinkHref="#svg-notif-error"/>
    </ToastContent>, this.options({containerId: MAIN_CONTAINER_ID}));
}
