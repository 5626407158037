import BaseStrategy from 'app/components/reusable/WalletSelect/BaseStrategy';
import MultipleStrategy from 'app/components/reusable/WalletSelect/MultipleStrategy';
import SingleStrategy from 'app/components/reusable/WalletSelect/SingleStrategy';
import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {ELEMENT_NOT_FOUND, KEY_ENTER} from 'app/utils/Consts';

interface CollectionInterface {
    value: string | number
    text: string
    text2: string | number
}

interface PropsInterface {
    value: string | string[]
    onChange: (value: string | string[]) => void
    collection: CollectionInterface[]
    disabled?: boolean
    name?: string
    id?: string
    translation_key?: string
    placeholder?: string
    children?: React.ReactNode
    className?: string
    multiple?: boolean
    placeholder_text_multiple?: string
    errors?: string[],
    eventWithSelect?: (id: string) => void
    empty_collection_text?: string
    search?: boolean
}

interface StateInterace {
    multiple: boolean
    opened: boolean
    searchCollection: CollectionInterface[]
    searchValue: string
}

export default class SmartSelect extends PureComponent<PropsInterface, StateInterace> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            multiple: props.multiple,
            opened: false,
            searchCollection: props.collection,
            searchValue: ''
        };

        this.strategy = props.multiple ? new MultipleStrategy(this) : new SingleStrategy(this);
    }

    strategy: BaseStrategy;

    rootElement: React.RefObject<HTMLDivElement> = React.createRef();

    componentDidMount(): void {
        document.addEventListener('click', this.handleCloseSelect, true);
    }

    componentWillUnmount(): void {
        document.removeEventListener('click', this.handleCloseSelect, true);
    }

    componentDidUpdate(prevProps: PropsInterface): void {
        if (prevProps.collection !== this.props.collection) {
            this.setSearchCollection(this.state.searchValue);
        }
    }

    handleCloseSelect = (e: MouseEvent): void => {
        if (this.rootElement.current && !this.rootElement.current.contains(e.target as HTMLDivElement)) {
            this.setState({opened: false});
            this.setSearchCollection('');
        }
    };

    handleOpenSelect = (e: React.MouseEvent<HTMLDivElement>): void => {
        e.preventDefault();
        this.setState({opened: !this.state.opened});
    };

    handleSelectItem = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();
        const {value} = e.target;
        const currentValues = this.strategy.selectValue(value);
        this.eventWithSelectValue(currentValues, value);
        this.props.onChange(currentValues);
    };

    singleHandleSelectItem = (value: string): void => {
        const currentValues = this.strategy.selectValue(value);
        this.eventWithSelectValue(currentValues, value);
        this.props.onChange(currentValues);
        this.setState({opened: false});
    };

    handleSearchValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();
        this.setSearchCollection(e.target.value);
    };

    handleSearchData = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === KEY_ENTER) {
            this.setSearchCollection('');
        }
    };

    handleClearSearch = (): void => this.setSearchCollection('');

    setSearchCollection = (value: string): void => {
        this.setState({
            searchCollection: this.props.collection.filter(item => item.text.toLowerCase()
                .indexOf(value.toLowerCase()) !== ELEMENT_NOT_FOUND),
            searchValue: value
        });
    };

    eventWithSelectValue(currentValues: string | string[], value: string): void {
        if (Array.isArray(currentValues) &&
            currentValues.findIndex(item => item === value) !== ELEMENT_NOT_FOUND &&
            this.props.eventWithSelect) {
            this.props.eventWithSelect(value);
        }
    }

    isCheckedItem(value: string | string[]): boolean {
        return this.strategy.isCheckedItem(value);
    }

    selectedItemsMessage(): string {
        return this.strategy.selectedItemsMessage();
    }

    placeHolderMessage(): string {
        return this.strategy.placeHolderMessage();
    }

    errorMessage(): string {
        const {errors} = this.props;
        return errors && errors[0];
    }

    renderOptions(): React.ReactNode {
        if (this.props.multiple) {
            return <>
                {this.state.searchCollection.map(item => {
                    const checked = this.isCheckedItem(item.value.toString());
                    return <li key={`${item.value}-${checked}`}>
                        <div className="select-body__list-item">
                            <label className="label-checkbox">
                                <input
                                    className="label-checkbox__input"
                                    name="smart-select"
                                    type="checkbox"
                                    checked={checked}
                                    onChange={this.handleSelectItem}
                                    value={item.value}
                                />
                                <span className="label-checkbox__icon">
                                    <svg>
                                        <use xlinkHref="#svg-check"/>
                                    </svg>
                                </span>
                                <span className="label-checkbox__label" title={item.text}>{item.text}</span>
                                {item.text2 && <span className="label-checkbox__info">{item.text2}</span>}
                            </label>
                        </div>
                    </li>;
                })}
            </>;
        }

        return <>
            {this.state.searchCollection.map(item => <li key={`${item.value}`}>
                <div className="select-body__list-item">
                    <label className="label-checkbox">
                        <button
                            className="label-checkbox__input"
                            onClick={() => this.singleHandleSelectItem(item.value.toString())}
                        >{item.value}</button>
                        <span className="label-checkbox__label" title={item.text}>{item.text}</span>
                        {item.text2 && <span className="label-checkbox__info">{item.text2}</span>}
                    </label>
                </div>
            </li>)}
        </>;
    }

    renderHolder = (): React.ReactNode => {
        if (this.props.multiple) {
            return this.state.opened
                ? <span className="select-header__value">{this.selectedItemsMessage()}</span>
                : <span className="select-header__title">{this.placeHolderMessage()}</span>;
        }

        return <span className="select-header__value">{this.selectedItemsMessage()}</span>;
    };

    render(): React.ReactNode {
        const {errors, search, multiple, disabled, id} = this.props;
        const {opened, searchCollection, searchValue} = this.state;
        return <div ref={this.rootElement}
            className={`select-container${multiple ? '' : ' select-singular'}${disabled
                ? ' disabled'
                : ''}`}
            id={id}>
            <div onClick={this.handleOpenSelect}
                className={`select-header${opened ? ' select-header_active' : ''}` +
                `${errors ? ' select-validation_active' : ''}`}>
                {this.renderHolder()}

                {errors && <div className="select-validation">
                    <span className="select-validation__icon">
                        <svg height="13px" width="13px">
                            <use xlinkHref="#svg-icon-error"/>
                        </svg>
                    </span>
                    <span className="select-validation__text">{this.errorMessage()}</span>
                </div>}
            </div>
            {opened && <div className="select-body select-body_active">
                {search && <div className="select-body__search">
                    <label className="select-search-icon" htmlFor="select-search-input">
                        <svg className="svg-default">
                            <use xlinkHref="#svg-search-icon"/>
                        </svg>
                    </label>
                    <input
                        id="select-search-input"
                        className="select-search-input"
                        type="text"
                        placeholder={I18n.t('search')}
                        onChange={this.handleSearchValue}
                        onKeyPress={this.handleSearchData}
                        value={searchValue}
                    />
                    {searchValue.length > 0 && <button
                        className="select-search-clear"
                        onClick={this.handleClearSearch}
                    >
                        <svg className="svg-default">
                            <use xlinkHref="#svg-close"/>
                        </svg>
                    </button>}
                </div>}
                <ul className="select-body__list">
                    {searchCollection.length < 1 && <li>
                        <span className="select-body__message">{
                            this.strategy.emptyItemsMessage()
                        }</span>
                    </li>}
                    {this.renderOptions()}
                </ul>
            </div>}
        </div>;
    }
}
