import I18n from 'app/i18n';

export const isArticleNews = function(type: string): boolean {
    if (type === 'news') {
        return true;
    }

    return false;
};

export const isArticlePromotion = function(type: string): boolean {
    if (type === 'promotions_old') {
        return true;
    }

    return false;
};

export const articleHeading = function(type: string): string {
    switch (type) {
    case 'news':
        return I18n.t('news_header');
    case 'promotions':
    case 'promotions_old':
        return I18n.t('promotions_header');
    case 'careers':
        return I18n.t('menu_links.careers');
    case 'hidden_temples':
        return I18n.t('hidden_temples');
    default:
        throw new Error(type);
    }
};
