import {Component} from 'react';
import {fixed2, getLinkPath} from 'app/utils';
import {modalConfirm, showTermsCommon} from 'app/utils/modals/popups';
import NotificationStore from 'app/store/notifications';
import logEvent from 'app/amplitude/log_event';
import {toastError} from 'app/components/toasts/liteToast';
import Post from 'app/utils/Post';
import {artifactStore, userStore} from 'app/store/global';

export const REAL_MONEY = 'real_money';
export const PLAY_MONEY = 'play_money';
export const ARTIFACT_MONEY = 'artifact_money';

export const isPlayMoney = ({amount_bet_type}) => amount_bet_type === PLAY_MONEY;
export const isArtifactMoney = ({amount_bet_type}) => amount_bet_type === ARTIFACT_MONEY;

export default class BetMaker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: '',
            amount_bet_type: userStore.fullRegistered() ? REAL_MONEY : PLAY_MONEY,
            loading: false
        };
    }

    makeBet(coef) {
        if (this.state.loading) {
            return;
        }

        this.setState({loading: true});

        const id = this.props.id || this.props.bet.id;
        const on = this.props.on || this.props.gamerNum;

        const artifactsToBet = this.props.artifactBetStore.quantifiedArtifactsToBet();
        const isArtifactBet = artifactsToBet.length > 0;
        const {bet, parentLine} = this.props;

        new Post({
            params: {
                bet: {
                    amount: fixed2(this.state.amount),
                    bet_recipes: artifactsToBet,
                    coef,
                    id,
                    on,
                    playmoney: isPlayMoney(this.state)
                }
            },
            url: '/bets'
        })
            .execute()
            .then(response => response.json()).then(data => {
                if (data.terms) {
                    showTermsCommon();
                    return;
                }
                if (data.success) {
                    const {amount, amount_bet_type} = this.state;
                    const {bet: {tourn, game, live, esports}} = this.props;
                    logEvent(
                        'SINGLE_BET_PLACED',
                        {
                            artifact_bet: isArtifactBet,
                            esports,
                            event: tourn,
                            live,
                            num: isArtifactBet ? artifactsToBet.length : fixed2(amount),
                            real_money: amount_bet_type === REAL_MONEY,
                            sport: game
                        }
                    );
                    if (data.delay) {
                        NotificationStore.add({
                            betId: data.user_bet_id,
                            coef: data.coef,
                            delayTime: this.props.bet.delay_for_bets_time,
                            force: true,
                            gamer: this.currentGamer(),
                            line: bet,
                            on,
                            parentLine,
                            timeout: false,
                            type: 'betProgress'
                        });
                    } else {
                        NotificationStore.add({
                            ...data.notification,
                            type: 'bet_accepted'
                        });
                    }

                    if (typeof this.props.afterBet === 'function') {
                        this.props.afterBet();
                    }
                    if (data.inventory_items) {
                        this.props.artifactBetStore.initArtifacts();
                        artifactStore.updateInventoryItems(data);
                    }
                    userStore.update({user: data.balance});
                    userStore.updateFirstBet(true);
                    this.setState({amount: ''});
                } else if (data.confirm) {
                    this.props.lineStore.restartUpdate();
                } else {
                    logEvent('PLACE_BET_ERROR', {error: data.message});
                    if (data.action === 'get_deposit') {
                        modalConfirm(data.message, () => {
                            location.href = getLinkPath('/deposits');
                        });
                    } else if (data.action === 'cancel_cashout') {
                        modalConfirm(data.message, () => {
                            new Post({url: '/cashouts/cancel_oldest'})
                                .execute()
                                .then(() => this.makeBet(coef));
                        });
                    } else if (data && data.message) {
                        toastError(data.message);
                    }
                }
            }).finally(() => this.setState({loading: false}));
    }
}
