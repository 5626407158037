import React, {PureComponent, ReactNode} from 'react';
import {Swiper, SwiperSlide} from 'swiper/swiper-react';
import BetSlipObjectInventory from 'app/components/bet_slip/inventory/BetSlipObjectInventory';
import InventoryItemInterface from 'app/interfaces/InventoryItemInterface';
import {isDesktop} from 'app/utils/windowFunctions';

const SWIPER_ITEMS_COUNT = 5;

const swiperParams = {
    allowTouchMove: true,
    spaceBetween: 5
};

interface BetSlipListInventoryProps {
    artifacts: InventoryItemInterface[]
    onClick: (artifact: InventoryItemInterface) => void
}

export default class BetSlipListInventory extends PureComponent<BetSlipListInventoryProps> {
    render(): ReactNode {
        const {artifacts, onClick} = this.props;
        return <div className="list-inventories">
            {isDesktop() && artifacts.length > SWIPER_ITEMS_COUNT
                ? <Swiper className="slider-inventories" {...swiperParams} slidesPerView="auto">
                    {artifacts.map(a => <SwiperSlide key={a.id}>
                        <BetSlipObjectInventory artifact={a} onClick={onClick}/>
                    </SwiperSlide>)}
                </Swiper>
                : artifacts.map(a => <BetSlipObjectInventory artifact={a} key={a.id} onClick={onClick}/>)}
        </div>;
    }
}
