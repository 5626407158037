import React, {Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import {getLinkPath} from 'app/utils/link_helper';
import Slider from 'react-slick';
import {isNewNews} from 'app/utils/articles/home_page/isNewNews';
import {scrollToTop} from 'app/utils';
import ArticleAsideContent from 'site_version/common/aside/ArticleAsideContent';
import {articlesStore} from 'app/store/global';
import {inject, observer} from 'mobx-react';
import {ArticlesStore} from 'app/store/ArticlesStore';
import {getType} from 'app/utils/articles/home_page/getType';

interface PropsInterface {
    title: string
    type: string
    articlesStore?: ArticlesStore
}

@inject('articlesStore')
@observer
export default class ArticleAside extends Component<PropsInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.props.articlesStore.loadMainArticles();
    }

    render(): React.ReactNode {
        const type = getType(this.props.type);
        const articles = articlesStore.getArticlesToAside(this.props.type);
        return <ArticleAsideContent link={getLinkPath(`/${type}`)} title={this.props.title}>
            <Slider slidesToShow={1} arrows={false} dots={true} infinite={false}>
                {articles.map(article => <Fragment key={article.id}>
                    <Link className="aside-preview-post-item"
                        key={article.id}
                        to={getLinkPath(`/${type}/${article.slug}`)}
                        onClick={scrollToTop}>
                        <span className="aside-preview-post-item__img">
                            <img
                                data-src={article.preview_image}
                                alt={article.title}
                                title={article.title}
                                className="lazyload"
                            />
                        </span>
                        <span className={`aside-preview-post-item__title${isNewNews(article.date)
                            ? ' preview-post__indicator'
                            : ''}`}>
                            {article.title}
                        </span>
                    </Link>
                    <div className="aside-preview-post-item__text"
                        dangerouslySetInnerHTML={{__html: article.pre_content}}
                    />
                </Fragment>)}
            </Slider>
        </ArticleAsideContent>;
    }
}
