import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {SimpleArtifactImage} from 'app/components/my_profile/artifacts/image';
import round from 'lodash/round';
import HeroSlotCount from 'app/utils/hero/hero_slot_count';
import {translatedName} from 'app/utils/translations_helper';
import {FILTER_GAME_CS, FILTER_GAME_DOTA} from 'app/consts';
import {stringTypeOfBonus} from 'app/utils/stringTypeOfBonus';

export default class Prize extends PureComponent {
    render() {
        const {loot: {points_amount, money_amount, bonus_amount, bonus, recipe, steam_skin},
            changePrizeArray, keyArray} = this.props;

        switch (true) {
        case Number(points_amount) > 0:
            return <DigitalPrizeImage
                amount={points_amount}
                measure={I18n.t('points', {count: points_amount})}
                changePrizeArray={changePrizeArray}
                keyArray={keyArray}
            />;
        case Number(money_amount) > 0:
            return <DigitalPrizeImage
                amount={money_amount}
                prefix="$"
                changePrizeArray={changePrizeArray}
                keyArray={keyArray}
            />;
        case bonus !== null:
            return <DigitalPrizeImage
                amount={bonus_amount}
                prefix="$"
                measure={stringTypeOfBonus(bonus)}
                changePrizeArray={changePrizeArray}
                keyArray={keyArray}
            />;
        default:
            return <ArtifactPrizeImage
                artifact={recipe || steam_skin || this.props.recipe}
                showHeroSlotCount
                changePrizeArray={changePrizeArray}
                keyArray={keyArray}
            />;
        }
    }
}

class DigitalPrizeImage extends PureComponent {
    get amount() {
        const {prefix = '', amount} = this.props;
        return `${prefix}${round(amount, 2)}`;
    }

    get title() {
        const {measure} = this.props;
        return `${this.amount} ${measure || ''}`;
    }

    render() {
        const {measure, changePrizeArray, keyArray} = this.props;
        const prizeMarkup = <div className="bonus-box" title={this.title}>
            <svg className="bonus-icon">
                <use xlinkHref="#svg-icon-bonus"/>
            </svg>
            <div className="bonus-description">
                <span className="bonus-preview__amount">{this.amount}</span>
                {measure && <span className="bonus-preview__measure">{measure}</span>}
            </div>
        </div>;
        return <div className="hero__box">
            <div
                name={'prize'}
                ref={prize => changePrizeArray ? changePrizeArray(prize, keyArray) : null
                } className={'hero__img game '}>
                <div className="hero__slot--img">
                    {prizeMarkup}
                </div>
            </div>
            <div className="hero__box--title">{this.title}</div>
        </div>;
    }
}

class ArtifactPrizeImage extends PureComponent {
    gameStyle() {
        switch (this.props.artifact.game_id) {
        case FILTER_GAME_CS:
            return 'game_csgo';
        case FILTER_GAME_DOTA:
            return 'game_dota2';
        default:
            return '';
        }
    }

    render() {
        const {artifact, showHeroSlotCount, showHeroSlotCountOrange, changePrizeArray, keyArray} = this.props;

        if (!artifact) {
            return null;
        }

        return <div className="hero__box">
            <div
                name={'prize'}
                ref={prize => changePrizeArray ? changePrizeArray(prize, keyArray) : null}
                className={`hero__img game ${this.gameStyle()}`}
            >
                <div className="hero__slot--img">
                    <SimpleArtifactImage artifact={artifact}/>
                </div>
                {
                    (showHeroSlotCount || showHeroSlotCountOrange) &&
                    <HeroSlotCount {...artifact} showHeroSlotCountOrange={showHeroSlotCountOrange}/>
                }
            </div>
            <div className="hero__box--title">{translatedName(artifact)}</div>
        </div>;
    }
}
