import React, {PureComponent} from 'react';

export default class VerifiedStatusMessage extends PureComponent {
    render() {
        const {message, children} = this.props;

        if (!message) {
            return null;
        }

        return <div className="form__row field-error">
            <div className="field__error-hint">{children || message}</div>
        </div>;
    }
}
