import React from 'react';
import I18n from 'app/i18n';

export default class TableHistoryHead extends React.Component {
    render():React.ReactNode {
        return <div className="table-content-head table-content-history">
            <div className="table-content-cell">
                <span className="table-content-cell-name">{I18n.t('player_label')}</span>
            </div>
            <div className="table-content-cell">
                <span className="table-content-cell-name">{I18n.t('bet_label')}</span>
            </div>
            <div className="table-content-cell">
                <span className="table-content-cell-name">{I18n.t('odds_label')}</span>
            </div>
            <div className="table-content-cell">
                <span className="table-content-cell-name">{I18n.t('prize_label')}</span>
            </div>
            <div className="table-content-cell">
                <span className="table-content-cell-name">ID</span>
            </div>
        </div>;
    }
}
