import React, {PureComponent, ReactNode} from 'react';
import {getLinkPath} from 'app/utils/link_helper';
import {scrollToTop} from 'app/utils';
import {i18n_t} from 'app/i18n';
import {Link} from 'react-router-dom';

export default class Apps extends PureComponent {
    render(): ReactNode {
        return <div className="app">
            <Link to={getLinkPath('/apps')} className="app__link" onClick={scrollToTop}>
                <svg height="24px" width="30px">
                    <use xlinkHref="#svg-ios"/>
                </svg>
                <svg height="26px" width="29px">
                    <use xlinkHref="#svg-android"/>
                </svg>
                {i18n_t('mobile_app_button')}
            </Link>
        </div>;
    }
}
