import React, {Component, MouseEvent, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import {BetSlipStore} from 'app/store/BetSlipStore';
import {ELEMENT_NOT_FOUND} from 'app/utils/Consts';
import BetSlipController from 'app/components/bet_slip/BetSlipController';
import MobileKeyboard from 'app/components/bet_slip/mobile_keyboard/MobileKeyboard';

interface MobileKeyboardProps {
    betSlipStore?: BetSlipStore
    controller?: BetSlipController
}

@inject('betSlipStore', 'controller')
@observer
export default class MobileKeyboardContainer extends Component<MobileKeyboardProps> {
    handleBackspace = (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        this.handleChangeAmount(this.getValue().slice(0, ELEMENT_NOT_FOUND));
    };

    handleChangeAmountInsertLast = (symbol: string): void => {
        this.handleChangeAmount(this.getValue() + symbol);
    };

    handleChangeAmount = (value: string): void => {
        const {coefFocus} = this.props.betSlipStore;
        const {changeCoef, changeInput} = this.props.controller;

        if (coefFocus) {
            changeCoef(value);
            return;
        }
        changeInput(value);
    };

    getValue = (): string => {
        const {amountFocus, amount, netPrize, coefFocus, formCoef} = this.props.betSlipStore;

        if (coefFocus) {
            return formCoef;
        } else if (amountFocus) {
            return amount;
        }
        return netPrize;
    };

    render(): ReactNode {
        const {betSlipStore, controller: {max}} = this.props;
        const {type, coefFocus} = betSlipStore;
        return <MobileKeyboard
            handleBackspace={this.handleBackspace}
            handleChangeAmountInsertLast={this.handleChangeAmountInsertLast}
            changeAmount={this.handleChangeAmount}
            disableButtons={coefFocus}
            max={max}
            type={type}
            amount={this.getValue()}
        />;
    }
}
