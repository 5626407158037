import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import {IncubatorCreateLineController} from 'app/controllers/IncubatorCreateLineController';
import {GAMER_1} from 'app/utils/Consts';

interface Props {
    controller?: IncubatorCreateLineController
}

@inject('controller')
@observer
export default class IncubatorLineLeftWinner extends Component<Props> {
    get controller(): IncubatorCreateLineController {
        return this.props.controller;
    }

    handleChangeWinner1 = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.controller.onChangeWinner(e.target.value);
    };

    handleClickWinner1 = (e: React.MouseEvent<HTMLInputElement>): void => {
        this.controller.onClickWinner1(e.target as HTMLInputElement);
    };

    handleChangeScore1 = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.controller.onChangeScore1(e.target.value);
    };

    render(): ReactNode {
        const {controller} = this.props;
        const {incubatorGame} = controller;
        const {gamer_1_score, winner} = incubatorGame.attributes;

        return <>
            <div className="radio">
                <input
                    id="win1"
                    type="radio"
                    name="line[winner]"
                    value="1"
                    onChange={this.handleChangeWinner1}
                    onClick={this.handleClickWinner1}
                    checked={winner === GAMER_1}
                />
                <label className="icon-checkbox" htmlFor="win1"/>
                <label
                    className="field__label"
                    htmlFor="win1"
                >
                    {I18n.t('tooltip_incubator_win')} 1
                </label>
            </div>
            <div className="input-line w60">
                <label className="field__label">
                    {I18n.t('tooltip_incubator_score')}
                </label>
                <input
                    className="field__input bold field-number__input"
                    value={gamer_1_score}
                    name="line[gamer_1_score]"
                    onChange={this.handleChangeScore1}
                />
            </div>
        </>;
    }
}
