import {MessagesPropsInterface} from 'app/components/chat/ChatMessages';
import LazyRendererStatesInterface from 'app/interfaces/LazyRendererStatesInterface';
import {Component} from 'react';

export const isComponentLoaded = (
    component: Component<MessagesPropsInterface, LazyRendererStatesInterface>
): boolean => component
    .state.itemsRendered;

const LOADING_TIMEOUT = 150;

export const continueRender = (component: Component<MessagesPropsInterface, LazyRendererStatesInterface>): void => {
    setTimeout(() => {
        component.setState({itemsRendered: true});
    }, LOADING_TIMEOUT);
};
