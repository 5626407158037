import React, {PureComponent} from 'react';
import {NavLink} from 'react-router-dom';
import I18n from 'app/i18n';
import {initPerfectScroll} from 'app/utils';

export default class InnerTabs extends PureComponent {
    componentDidMount() {
        initPerfectScroll();
    }

    render() {
        return <div className="tabs__header js-custom-scroll">
            {this.props.label && <span>{this.props.label}</span>}
            <Tabs {...this.props}/>
        </div>;
    }
}

class Tabs extends PureComponent {
    render() {
        const {selectedTab, handleChange, queryParam, tabs} = this.props;
        return tabs && tabs.map((tab, i) => <Tab
            key={i}
            selectedTab={selectedTab}
            thisTab={tab}
            handleChange={handleChange}
            queryParam={queryParam}
        />);
    }
}

class Tab extends PureComponent {
    handleChange = () => {
        const {handleChange, thisTab: {query}, queryParam} = this.props;
        handleChange(query[queryParam]);
    };

    get classNames() {
        const {selectedTab, thisTab: {query}, queryParam} = this.props;
        const classNames = ['tabs__link', 'tabs__link--text'];

        if (selectedTab === query[queryParam]) {
            classNames.push('active-link');
        }
        return classNames.join(' ');
    }

    render() {
        const {thisTab: {query, title}, queryParam} = this.props;
        return <NavLink
            to={{pathname: window.location.pathname}}
            state={query}
            className={this.classNames}
            onClick={this.handleChange}
        >
            {title || I18n.t(query[queryParam])}
        </NavLink>;
    }
}
