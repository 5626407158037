import I18n from 'app/i18n';
import BaseValidator from 'validators/BaseValidator';
import {IncubatorGameAttributes} from 'app/models/IncubatorGameModel';
import {MIN_PLEDGE, MIN_COEF_CHANGING, MAX_COEF_CHANGING} from 'app/utils/Consts';

export default class IncubatorGameValidator extends BaseValidator<IncubatorGameAttributes> {
    // eslint-disable-next-line max-statements
    validations(esports?: boolean): void {
        this.presence('game_id', {message: I18n.t('required_field')});
        this.presence('coef_1', {message: I18n.t('required_field_coef')});
        this.presence('coef_2', {message: I18n.t('required_field_coef')});
        this.presence('gamer_1_id', {message: I18n.t('required_field')});
        this.presence('gamer_2_id', {message: I18n.t('required_field')});
        this.presence('gamer_1_score', {message: I18n.t('required_field')});
        this.presence('gamer_2_score', {message: I18n.t('required_field')});
        this.presence('lines_type_id', {message: I18n.t('select_value_line_type')});
        this.validationStream(esports);
        this.validationPledge();
        this.validationCoefChanging();
    }

    validationCoefChanging(): void {
        this.greaterOrEqual('coef_changing', {limit: MIN_COEF_CHANGING,
            message: I18n.t('coef_changing_error',
                {max_amount: MAX_COEF_CHANGING, min_amount: MIN_COEF_CHANGING})});
        this.lessOrEqual('coef_changing', {limit: MAX_COEF_CHANGING,
            message: I18n.t('coef_changing_error',
                {max_amount: MAX_COEF_CHANGING, min_amount: MIN_COEF_CHANGING})});
    }

    validationPledge(): void {
        this.presence('pledge', {message: I18n.t('required_field')});
        this.greaterOrEqual('pledge',
            {limit: MIN_PLEDGE, message: I18n.t('pledge_must_be_greater', {amount: MIN_PLEDGE})});
    }

    validationStream(esports?: boolean): void {
        this.presence('stream_link', {message: I18n.t('add_link_stream')});
        this.isValidStream(esports, 'stream_link', {message: I18n.t('wrong_url')});
    }
}
