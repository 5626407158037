import React, {Component, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import {getLinkPath} from 'app/utils/link_helper';
import StoryImageInterface from 'app/interfaces/StoryImageInterface';
import {i18n_t} from 'app/i18n';
import SmartStoryImageStatistic from './SmartStoryImageStatistic';
import {StatsType} from 'app/utils/Consts';
import {StoriesStoreProps} from 'app/store/StoriesStore';
import StoryInterface from 'app/interfaces/StoryInterface';
import {isGuest} from 'app/utils';

interface SmartStoryImagePopupProps extends StoriesStoreProps {
    storyIndex?: number
    storyImageIndex?: number
    story: StoryInterface
    story_image: StoryImageInterface
    onNextStory: () => void
    imageStatsType: number
    statsType: number
    onImageLoad?: (storyIndex: number, storyImageIndex: number) => void
}

export const ImageStatsType = {
    BEST_TOURNAMENT: 1,
    FAVORITE_GAMER: 0,
    POINTS: 2
};

@inject('storiesStore')
@observer
export default class SmartStoryImagePopup extends Component<SmartStoryImagePopupProps> {
    handleRedirectClick = (): void => {
        const redirectUrl = this.props.story_image.url;

        if (redirectUrl) {
            location.href = getLinkPath(this.props.story_image.url);
            this.props.storiesStore.clickRedirectUrl(this.props.story.id);
        }
    };

    handleUserStoryHide = (): void => {
        const {story, storiesStore: {close_button}, onNextStory} = this.props;

        if (close_button(story)) {
            this.props.storiesStore.clickChangeStoryHide(this.props.story.id, onNextStory);
        }
    };

    isStats = (): boolean => {
        const {storiesStore, statsType} = this.props;
        return statsType === StatsType.MONTHLY && storiesStore.monthlyStats !== null ||
            statsType === StatsType.YEAR && storiesStore.yearlyStats !== null;
    };

    onImageLoad = (): void => {
        const {storiesStore, story_image, storyIndex, storyImageIndex, onImageLoad} = this.props;
        storiesStore.setLoadedStoryImage(story_image);
        onImageLoad(storyIndex, storyImageIndex);
    };

    like = (): void => {
        const {storiesStore, story} = this.props;
        storiesStore.like(story.id);
    };

    dislike = (): void => {
        const {storiesStore, story} = this.props;
        storiesStore.dislike(story.id);
    };

    render(): ReactNode {
        const {statsType, imageStatsType, story_image: {image, url},
            storiesStore: {close_button, userStoryLike, userStoryDislike}, story} = this.props;
        return <>
            <img className="slide-smart-storie-image" loading="lazy"
                src={image ? image.url : ''}
                alt="" onLoad={this.onImageLoad}
            />
            {this.isStats() && <SmartStoryImageStatistic
                type={statsType}
                imageType={imageStatsType}
            />}
            {!isGuest() &&
                <div className="buttons-like-storie">
                    <div className="wrapper-button-like" >
                        <span onClick={this.like}
                            className={`button-like-storie button-like-storie_yes
                                    ${userStoryLike(story.id) ? 'button-like-storie_active' : ''}
                                    effect-no-tap`}>
                            <svg>
                                <use xlinkHref="#svg-button-like"/>
                            </svg>
                        </span>
                    </div>
                    <div className="wrapper-button-like">
                        <span onClick={this.dislike}
                            className={`button-like-storie button-like-storie_no
                                    ${userStoryDislike(story.id) ? 'button-like-storie_active' : ''}
                                     effect-no-tap`}>
                            <svg>
                                <use xlinkHref="#svg-button-like"/>
                            </svg>
                        </span>
                    </div>
                </div>}
            {(url || close_button(story)) && <div className="slide-smart-storie-button">
                {close_button(story) && <button
                    onClick={this.handleUserStoryHide}
                    className="button-storie-more effect-no-tap">
                    <span className="button-storie-more__span">
                        {i18n_t('hide')}
                    </span>
                </button> }

                {url && <button onClick={this.handleRedirectClick} className="button-storie-more effect-no-tap">
                    <span className="button-storie-more__span">
                        {i18n_t('open')}
                    </span>
                </button> }
            </div>}
            <div className="swiper-lazy-preloader swiper-lazy-preloader-white"/>
        </>;
    }
}
