import {inject, observer} from 'mobx-react';
import React, {Component, ReactNode} from 'react';
import PlayContentImpl from './index';
import PlayPage from 'app/components/pages/play';
import {IncubatorStore} from 'app/store/IncubatorStore';
import {CurrentTimeStore} from 'stores/current_time_store';
import {ArtifactStore} from 'app/store/ArtifactStore';
import withRouter from 'app/utils/navigate/customWithRouter';

interface ParamsInterfaceProps {
    params: { filter: string }
}
interface IncubatorContentProps {
    incubatorStore?: IncubatorStore
    currentTimeStore?: CurrentTimeStore
    artifactStore?: ArtifactStore
}

class IncubatorContent extends Component<IncubatorContentProps> {
    componentDidMount(): void {
        this.props.incubatorStore.startUpdateLines();
        this.props.incubatorStore.filterStore.setCurrentBetType('incubator', 'live');
    }

    componentWillUnmount(): void {
        this.props.incubatorStore.stopUpdateLines();
    }

    render(): ReactNode {
        const {params: {filter = ''}, incubatorStore} = this.props as ParamsInterfaceProps & IncubatorContentProps;
        return <PlayContentImpl
            filter={filter}
            urlPrefix={'/play/incubator/'}
            keyPrefix={'incubator'}
            tableStore={incubatorStore}
            tableProps={{
                filter: PlayPage.incubatorFilter
            }}
        />;
    }
}

export default withRouter(inject('incubatorStore', 'currentTimeStore', 'artifactStore')(observer(IncubatorContent)));
