import ErrorsServerInterface from 'app/interfaces/ErrorsServerInterface';
import React, {PureComponent, Fragment} from 'react';
import I18n from 'app/i18n';
import InputError from 'app/components/deposit/InputError';

interface PropsInterface {
    errors: ErrorsServerInterface
    attributeName: string
    children?: React.ReactNode
}

export default class InputErrorWrapper extends PureComponent<PropsInterface> {
    render(): React.ReactNode {
        const {errors, attributeName, children} = this.props;
        const {[attributeName]: errorsByAttribute} = errors;
        const [error] = errorsByAttribute || [];
        return <Fragment>
            <label className="field__label" htmlFor={attributeName}>{I18n.t(attributeName)}</label>
            <div className={`field ${error ? 'field-error' : ''}`}>
                <div className="field__wrap">
                    {children}
                    {error && <InputError error={error}/>}
                </div>
            </div>
        </Fragment>;
    }
}
