import $ from 'jquery';
import React from 'react';
import I18n from 'app/i18n';
import {getLinkPath, timeStampToTimeShortFormat} from 'app/utils';
import {updateInfo} from 'app/common';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import {modalsStore} from 'app/store/global';

export default class SwapGift extends React.PureComponent {
    constructor(props) {
        super(props);
        const {userName} = props;
        this.state = {nameExist: typeof userName !== 'undefined' || undefined, name: userName, gifts: {}};
        this.name = React.createRef();
    }

    componentDidMount() {
        this.updateRequests();
    }

    updateRequests() {
        $.ajax({
            url: getLinkPath('/gift/form_of_swap'),
            dataType: 'json',
            success: response => {
                if (response.gifts.sender.length === 0 &&
                    response.gifts.receiver.length === 0 &&
                    response.gifts.swapable === 0) {
                    toastError(I18n.t('no_gifts'));
                    modalsStore.hideModal(this.props.popupId);
                } else {
                    this.setState(response);
                }
            }
        });
    }

    requestToSwap() {
        $.ajax({
            type: 'POST',
            url: getLinkPath('/gift/swap'),
            data: {receiver: this.state.name},
            success: response => {
                if (response.success) {
                    this.updateRequests();
                    toastSuccess(response.message);
                } else if (response.hasOwnProperty('error')) {
                    toastError(response.error);
                }
                updateInfo();
            }
        });
    }

    findUser(name) {
        if (name.length >= 4) {
            $.ajax({
                data: {id: name},
                url: getLinkPath('/egb_users/check_name'),
                success: response => this.setState({nameExist: !response.success}),
                error: () => this.setState({nameExist: undefined})
            });
        } else {
            this.setState({nameExist: undefined});
        }
    }

    handleChange(state, e) {
        if (state === 'name' && e.target.value.length > 32) {
            return;
        }

        const obj = {...this.state};
        obj[state] = e.target.value;
        this.setState(obj);
        if (state === 'name') {
            this.findUser(obj[state]);
        }
    }

    inputClass() {
        return this.state.nameExist ? 'field__input--exist-state' : 'field__input--inexist-state';
    }

    isUserSender(obj) {
        const senders = this.state.gifts.sender;
        return senders.filter(sender => sender === obj).length > 0;
    }

    mergeGifts() {
        const gifts = {...this.state.gifts};

        if (gifts.hasOwnProperty('sender') && gifts.hasOwnProperty('receiver')) {
            return gifts.receiver.concat(gifts.sender);
        }
        return [];
    }

    swapGiftAction(data, gift) {
        $.ajax(getLinkPath('/gift/swap_action'), {
            type: 'POST',
            data,
            success: response => {
                if (response.hasOwnProperty('error')) {
                    toastError(response.error);
                } else {
                    if (data.action_type === 'reject' && gift.sender !== settings.user.name) {
                        toastError(I18n.t('gifts.swap_was_denied'));
                    } else if (data.action_type === 'reject' && gift.sender === settings.user.name) {
                        toastError(I18n.t('gifts.swap_was_denied'));
                    } else if (data.action_type === 'accept') {
                        toastSuccess(I18n.t('gifts.swap_was_successful'));
                    }
                    this.updateRequests();
                }
                updateInfo();
            }
        });
    }

    reject(gift) {
        return this.swapGiftAction({id: gift.id, action_type: 'reject'}, gift);
    }

    render() {
        return <PopupDialog id={this.props.popupId}>
            <div className="simple-popup simple-popup--big">
                {this.mergeGifts().length > 0
                    ? <div className="gift-table">
                        <div className="gift-table__heading tables-heading">
                            <div className="gift-table__mobile-row">
                                <div className="gift-table__col-1">{I18n.t('gifts.date')}</div>
                                <div className="gift-table__col-2">{I18n.t('gifts.expires')}</div>
                            </div>
                            <div className="gift-table__cols-holder">
                                <div className="gift-table__col-3">{I18n.t('gifts.sender')}</div>
                                <div className="gift-table__col-4">{I18n.t('gifts.receiver')}</div>
                                <div className="gift-table__col-5">{I18n.t('gifts.action')}</div>
                            </div>
                        </div>
                        {this.mergeGifts().map(gift => <div className="gift-table__main-row" key={gift.id}>
                            <div className="gift-table__mobile-row">
                                <div className="gift-table__col-1">{timeStampToTimeShortFormat(gift.created_at)}</div>
                                <div className="gift-table__col-2">{timeStampToTimeShortFormat(gift.expire)}</div>
                            </div>
                            <div className="gift-table__cols-holder">
                                <div className="gift-table__col-3"><span>{gift.sender}</span></div>
                                <div className="gift-table__col-4"><span>{gift.receiver}</span></div>
                                <div className="gift-table__col-5">
                                    {this.isUserSender(gift)
                                        ? [
                                            <span className="pending-msg"
                                                key={`pending${gift.id}`}>{I18n.t('gifts.waiting')}</span>, <button key={`deny${gift.id}`} className="btn btn--small btn--invert"
                                                onClick={() => this.reject(gift)}>
                                                {I18n.t('gifts.reject')}</button>
                                        ]
                                        : <div className="gift-table__buttons">
                                            <button className="btn btn--small"
                                                onClick={() => this.swapGiftAction({
                                                    id: gift.id,
                                                    action_type: 'accept'
                                                }, gift)}>
                                                {I18n.t('gifts.accept')}
                                            </button>
                                            <button className="btn btn--small btn--invert"
                                                onClick={() => this.reject(gift)}>
                                                {I18n.t('gifts.reject')}
                                            </button>
                                        </div>}
                                </div>
                            </div>
                        </div>)}
                    </div>
                    : null}

                <form className="popup-form" onSubmit={e => {
                    e.preventDefault();
                    this.requestToSwap();
                }}>
                    <div className="field">
                        <label htmlFor="swap_gift_username">{I18n.t('please_enter_user_nickname')}</label>
                        <div className="field__wrap">
                            <input
                                id="swap_gift_username"
                                type="text"
                                value={this.state.name}
                                ref={this.name}
                                className={`field__input ${this.inputClass()}`}
                                onChange={this.handleChange.bind(this, 'name')}
                                name="swap_gift_username"
                            />
                            {typeof this.state.nameExist !== 'undefined'
                                ? <span className="field__state">
                                    <svg height="12px" width="12px">
                                        <use xlinkHref={this.state.nameExist ? '#svg-check' : '#svg-close'}/>
                                    </svg>
                                </span>
                                : null}
                        </div>
                    </div>
                    <div className="simple-popup__text">
                        <button type="submit" className="btn">{I18n.t('swap_gift')}</button>
                    </div>
                </form>
            </div>
        </PopupDialog>;
    }
}
