import BaseStrategy from 'app/components/reusable/WalletSelect/BaseStrategy';
import I18n from 'app/i18n';

export default class SingleStrategy extends BaseStrategy {
    get selectedItems(): string {
        return this.select.props.value as string;
    }

    selectValue(value: string): string {
        return this.selectedItems === value ? '' : value;
    }

    isCheckedItem(value: string): boolean {
        return value === this.selectedItems;
    }

    selectedItemsMessage = (): string => this.select.props.collection.find(el => el.value === this.selectedItems)?.text;

    placeHolderMessage = (): string => this.select.props.placeholder_text_multiple;

    emptyItemsMessage = (): string => this.select.props.empty_collection_text
        ? this.select.props.empty_collection_text
        : I18n.t('third_level_sort_blank');
}
