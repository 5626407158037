import $ from 'jquery';
import React, {RefObject, ReactNode} from 'react';
import Mailcheck from 'mailcheck';
import I18n from 'app/i18n';
import Captcha from './captcha';
import {addGaEvent} from 'app/ga_methods';
import logEvent from 'app/amplitude/log_event';
import SmartLoader from 'app/components/reusable/SmartLoader';
import Post from 'app/utils/Post';
import {getLinkPath} from 'app/utils/link_helper';
import {RowSelfExclusion, RowCountryCode, RowDayOfBirth, defaultBirthDay, RowDepositLimit, RowPassword, RowTimeZone,
    RowNoMarketingConsent, RowTermsOfUse, RowTradeLink, RowPhone, RowNameType, RowName, RowEmail}
    from 'app/components/auth/rows/index';
import {DefaultRegistrationInterface} from 'app/interfaces/DefaultRegistrationInterface';
import {FormDataInterface} from 'app/interfaces/FormDataInterface';

    interface RegistrationState{
        deposit_limit:string;
        deposit_limit_period: string;
        errors: {[key: string]: string}| [];
        passwordType: string;
        submitting: boolean;
        success?: boolean;
        logged?: boolean;
    }


export default class DefaultRegistration extends React.Component<DefaultRegistrationInterface, RegistrationState> {
    constructor(props: DefaultRegistrationInterface) {
        super(props);
        this.state = {
            deposit_limit: props.deposit_limit,
            deposit_limit_period: props.deposit_limit_period,
            errors: {},
            passwordType: 'password',
            submitting: false
        };
    }

    rootForm: React.RefObject<HTMLFormElement> = React.createRef();

    limitPeriodSelect: RefObject<HTMLSelectElement> = React.createRef();

    emailInput: React.RefObject<HTMLInputElement> = React.createRef();

    nameInput: React.RefObject<HTMLInputElement> = React.createRef();

    passwordInput: React.RefObject<HTMLInputElement> = React.createRef();

    first_nameInput: React.RefObject<HTMLInputElement> = React.createRef();

    last_nameInput: React.RefObject<HTMLInputElement> = React.createRef();


    static LEAD_FIELDS = [];

    componentDidUpdate(prevProps: DefaultRegistrationInterface, prevState: RegistrationState): void {
        if (prevState.deposit_limit_period !== this.state.deposit_limit_period) {
            $(this.limitPeriodSelect.current).trigger('chosen:updated');
        }
    }

    error = (field: string): string => {
        if (this.state.errors[field]) {
            return typeof this.state.errors[field] === 'object'
                ? this.state.errors[field].join(' ')
                : this.state.errors[field];
        }
        return null;
    };

    errorHint = (field: string): ReactNode => <div className="field__error-hint">{this.error(field)}</div>;

    cleanError = (field: string): void => {
        const res = {};
        res[field] = false;
        this.setState({errors: Object.assign(this.state.errors, res)});
    };

    mailCheck = (): void => {
        Mailcheck.run({
            email: this.emailInput.current.value,
            suggested: suggestion => {
                this.setState({errors: Object.assign(this.state.errors,
                    {email: [`${I18n.t('mailcheck')} ${suggestion.full}`]})});
            }
        });
    };

    rowClass = (field: string, confirmation_btn_class = false, isCaptcha = false): string => {
        const classes = ['popup-form__row'];

        if (isCaptcha) {
            classes.push('captcha__row');
        }
        if (this.error(field)) {
            classes.push('field-error');
        }
        if (confirmation_btn_class) {
            classes.push('with__confirmation_button');
        }
        return classes.join(' ');
    };

    rowClassInput = (field: string, confirmation_btn_class = false, isCaptcha = false): string => {
        const classes = ['popup-form__row popup-form__wrapper'];

        if (isCaptcha) {
            classes.push('captcha__row');
        }
        if (this.error(field)) {
            classes.push('field-error');
        }
        if (confirmation_btn_class) {
            classes.push('with__confirmation_button');
        }
        return classes.join(' ');
    };

    errorHandler = (error: {[key: string]: string | boolean | number}): void => {
        logEvent('REGISTRATION_FORM_FAILURE', {...error, SOURCE: this.props.usingOnTab});
        const responseJSON = {...error};
        let newState = {};
        if (Object.keys(responseJSON).length) {
            newState = {
                ...newState,
                errors: responseJSON.errors
            };
        }
        this.setState({
            ...newState,
            submitting: false
        });
    };

    signUp = (e: React.ChangeEvent<HTMLFormElement>): void => {
        if (this.state.submitting) {
            return;
        }
        this.setState({
            errors: {},
            submitting: true
        });
        const form = new FormData(e.target) as FormDataInterface;
        const {...egb_user} = Object.fromEntries(form.entries());
        const formData = {
            egb_user,
            'g-recaptcha-response': form.get('g-recaptcha-response')
        };
        new Post({
            params: formData,
            url: '/egb_users'

        })
            .execute()
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    logEvent(
                        'REGISTRATION_FORM_SENT',
                        {
                            SOURCE: this.props.usingOnTab,
                            method: this.constructor.METHOD
                        }
                    );
                    addGaEvent('Sign up', 'done');
                    location.href = getLinkPath(`${this.props.redirectUrl ? this.props.redirectUrl : '/deposits'}`);
                }
                if (data.errors) {
                    this.errorHandler(data);
                }
            });
        e && e.preventDefault();
    };

    onChangeHandler = (): void => {
        const {LEAD_FIELDS, METHOD} = this.constructor;

        if (LEAD_FIELDS.filter(field => !this[`${field}Input`].current.value).length ||
                this.state.logged) {
            return;
        }
        this.setState(() => ({logged: true}), () => logEvent('JOIN_PAGE_LEAD', {
            SOURCE: this.props.usingOnTab, method: METHOD
        }));
    };

    rowEmail = ({email,
        confirmed,
        confirmation_period_expired} = this.props, hidden = false): ReactNode => <RowEmail
        key="email"
        hidden={hidden}
        email={email}
        confirmed={confirmed} confirmationExpired={confirmation_period_expired}
        rowClass={this.rowClass('email', typeof confirmed !== 'undefined' &&
                    !confirmed)}
        reference={this.emailInput}
        mailCheck={this.mailCheck}
        cleanError={this.cleanError}
        onChangeHandler={this.onChangeHandler}
        errorHint={this.errorHint('email')}/>;

    rowEmailFull = ({email,
        confirmed,
        confirmation_period_expired} = this.props, hidden = false): ReactNode => <RowEmail
        key="email"
        hidden={hidden}
        email={email}
        confirmed={confirmed} confirmationExpired={confirmation_period_expired}
        rowClass={this.rowClassInput('email', typeof confirmed !== 'undefined' &&
                !confirmed)}
        reference={this.emailInput}
        mailCheck={this.mailCheck}
        cleanError={this.cleanError}
        onChangeHandler={this.onChangeHandler}
        errorHint={this.errorHint('email')}/>;

    rowNameType = (nameType: string, reference: RefObject<HTMLInputElement>, inputAttrs = {}): ReactNode => {
        const {verified_status} = this.props;
        const name = this.props[nameType];
        return <RowNameType rowClass={this.rowClassInput(nameType)} key={nameType}
            nameType={nameType}
            name={name}
            reference={reference}
            inputAttrs={{...inputAttrs}}
            errorHint={this.errorHint(nameType)}
            onChangeHandler={this.onChangeHandler}
            cleanError={this.cleanError}
            verifiedStatus={verified_status}/>;
    };

    rowFirstName = (): ReactNode => {
        const {full_registered} = this.props;
        return this.rowNameType('first_name', this.first_nameInput, {disabled: full_registered});
    };

    rowLastName = (): ReactNode => {
        const {full_registered} = this.props;
        return this.rowNameType('last_name', this.last_nameInput, {disabled: full_registered});
    };

    rowDayOfBirth = ({day, month, year} = defaultBirthDay(), disabled = false): ReactNode => <RowDayOfBirth
        rowClass={this.rowClassInput('base')} key="birthDay"
        cleanError={this.cleanError}
        errorHint={this.errorHint('base')}
        dayOfBirth={{day, month, year}}
        disabled={disabled}/>;

    rowDepositLimits = (
        {new_deposit_limit, new_deposit_limit_period, new_deposit_limit_effective_from} = this.props
    ): ReactNode => <RowDepositLimit rowClass={this.rowClass('deposit_limit.amount')} key="deposit_limit"
        errorHint={this.errorHint('deposit_limit.amount')}
        new_deposit_limit={new_deposit_limit}
        new_deposit_limit_period={new_deposit_limit_period}
        new_deposit_limit_effective_from={new_deposit_limit_effective_from}
        deposit_limit={this.props.deposit_limit}
        deposit_limit_period={this.state.deposit_limit_period}
        cleanError={this.cleanError}/>;

    rowSelfExclusion = (): ReactNode => <RowSelfExclusion
        rowClass={this.rowClass('self-exclusion')} key="self_exclusion"
        errorHint={this.errorHint('self-exclusion')}/>;

    rowCountryCode = ({country_code} = this.props): ReactNode => <RowCountryCode
        rowClass={this.rowClass('country_code')} key="country_code"
        errorHint={this.errorHint('country_code')}
        country_code={country_code}
        cleanError={this.cleanError}/>;

    rowTimezone = ({timezone_id} = this.props): ReactNode => <RowTimeZone timeZoneId={timezone_id} key="timezone"/>;


    rowName = (): ReactNode => <RowName
        rowClass={this.rowClassInput('name')}
        key="name"
        reference={this.nameInput}
        cleanError={this.cleanError}
        onChangeHandler={this.onChangeHandler}
        errorHint={this.errorHint('name')}/>;


    rowPassword = (showPassword = false): ReactNode => <RowPassword
        rowClass={this.rowClassInput('password')}
        key="password"
        reference={this.passwordInput}
        errorHint={this.errorHint('password')}
        showPassword={showPassword}
        cleanError={this.cleanError}
        onChangeHandler={this.onChangeHandler}/>;

    rowNoMarketingConsent = (): ReactNode => <RowNoMarketingConsent rowClass={this.rowClass('no_marketing_consent')}
        key="marketing_consent"/>;

    rowTermsOfUse = (): ReactNode => <RowTermsOfUse rowClass={this.rowClass('terms_of_use')} key="terms_of_use"
        cleanError={this.cleanError}
        errorHint={this.errorHint}
        error={this.error('terms_of_use')}/>;

    rowTradeLink = ({trade_link} = this.props): ReactNode => <RowTradeLink
        rowClass={this.rowClass('trade_link')} key="trade_link"
        cleanError={this.cleanError}
        errorHint={this.errorHint('trade_link')}
        tradeLink={trade_link}/>;

    rowPhone = ({phone} = this.props): ReactNode => <RowPhone rowClass={this.rowClass('phone')} key="phone"
        errorHint={this.errorHint('phone')}
        phone={phone}
        cleanError={this.cleanError}
        onChangeHandler={this.onChangeHandler} />;

    rowCaptcha = (): ReactNode => <div className={this.rowClassInput('captcha', false, true)} key="captcha">
        <div className={this.error('captcha') ? ' field__input' : ''}>
            <Captcha reset={this.state.errors} />
        </div>{this.errorHint('captcha')}
    </div>;

    renderRegisterButton = (text = I18n.t('register')): ReactNode => this.state.submitting
        ? <SmartLoader loaderClassName="button-adaptive smart-loader_theme-dark" />
        : <input className="button-adaptive" name="commit"
            type="submit" disabled={this.state.submitting} value={text}/>;
}
