import {translated} from 'app/utils/translations_helper';
import SendForm from './gift/SendForm';
import React from 'react';
import I18n from 'app/i18n';
import {fixed2, isGuest, TimeToStartTimerOld, userSignedIn} from 'app/utils';
import {goToRoute} from 'app/utils/link_helper';
import SwapGift from '../gift/swap_gift';
import {B_DAY_GIFT, GIFT_BOX} from 'app/consts';
import {showPopup} from 'app/utils/modals/ModalsService';
import CountDownPromo from './count_down_promo';
import AsideHeader from 'app/modules/AsideHeader';
import nextPrizeProgress from './modules/next_prize_progress';
import FAQButton from './modules/FAQButton';
import {openRegistration} from '../auth/auth';
import OpenGiftPopup from 'app/components/promo_action/gift/OpenGiftPopup';
import {Provider} from 'mobx-react';
import {promoActionStore} from 'app/store/global';
import {goToMyHero} from 'app/utils/app_links';
import {preloadImage} from 'app/utils/preloadImage';
import GiftLink from './GiftLink';

const handleGuestClick = func => isGuest()
    ? openRegistration('PROMO_RIGHT_BLOCK')
    : func();


export const showSendForm = userName => {
    handleGuestClick(() => showPopup('send-form', props => <SendForm userName={userName} popupId={props.popupId}/>));
};

export const showSwapForm = userName => {
    handleGuestClick(() => showPopup('swap-gift', props => <SwapGift userName={userName} popupId={props.popupId}/>));
};

export const giftOpening = (promoAction, e) => {
    e && e.preventDefault();
    handleGuestClick(() => showPopup('gift-popup', props => <OpenGiftPopup promoAction={promoAction} popupId={props.popupId}/>));
};

const openGiftFunction = e => {
    giftOpening('PromoActionPresent', e);
};

export class PromoActionPresent extends CountDownPromo {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            converted: false,
            time_to_start: props.time_to_start
        };
        this.FAQPath = '/faq/gifts';
    }

    componentDidMount() {
        super.componentDidMount();

        preloadImage(GIFT_BOX);
    }

    nextPrizeProgress = () => nextPrizeProgress({
        ...this.props,
        isStarted: this.isStarted
    });

    static get promoUrl() {
        return `/promotions/${promoActionStore.currentPromoAction().PromoActionPresent.url}`;
    }

    onClick = () => this.isStarted() ? openGiftFunction() : goToRoute(this.constructor.promoUrl);

    render() {
        const {to_send, to_swap, to_open, count_of_opened_gifts, quantity_in_swap} = this.props;
        const ConvertButton = promoActionStore.convertButton;
        const {conversion_available, time_to_start} = promoActionStore.promoAction;

        return <AsideHeader cookieName={'b-day-menu'}
            title={<>
                <span className="b-day-aside__title-text-wrap">
                    {translated(this.props, 'promo_action_name', 'promo_action_name_translations')}
                    <FAQButton FAQPath={this.FAQPath}/>
                </span>
                {time_to_start === 0 && <span className="b-day-aside__counter">
                    {`(${count_of_opened_gifts} ${I18n.t('opened')})`}
                </span>}
            </>}
            isStarted={this.isStarted}
            componentName={'b-day'}>
            <div className="promo-aside">
                <div className="promo-aside__content b-day-aside__content">
                    {this.isStarted() && <div className="b-day-aside__links">
                        <GiftLink
                            title={I18n.t('send_gift')}
                            count={to_send}
                            onClickHandler={showSendForm}/>
                        <GiftLink
                            title={I18n.t('swap_gift')}
                            count={to_swap}
                            enabled={to_send || quantity_in_swap}
                            onClickHandler={showSwapForm}/>
                        <GiftLink
                            title={I18n.t('open')}
                            count={to_open}
                            onClickHandler={openGiftFunction}/>
                    </div>}
                    <div className="b-day-aside__gift-wrap">
                        <div className="b-day-aside__gift" onClick={this.onClick}
                        >
                            <div className="b-day-aside__box">
                                <div className="b-day-aside__box-empty"/>
                                <div className="b-day-aside__box-shape">
                                    <div className="b-day-aside__box-shadow"/>
                                </div>
                                <div
                                    style={{bottom: `${this.nextPrizeProgress()}%`}}
                                    className="b-day-aside__box-full">
                                    <img
                                        src={B_DAY_GIFT.BACKGROUND}
                                        alt="Gift background"
                                    />
                                </div>
                            </div>
                            <div className="b-day-aside__ribbon">
                                <img
                                    src={B_DAY_GIFT.RIBBON}
                                    alt="Gift ribbon"
                                />
                            </div>
                        </div>
                        {!this.isStarted() && <TimeToStartTimerOld {...this.state}/>}
                        {this.isStarted() && userSignedIn() && <div className="b-day-aside__balance">
                            <span className="user__balance" key="balance">{fixed2(this.nextPrizeProgress())}%</span>
                        </div>}
                    </div>
                </div>
                {userSignedIn() && this.isStarted() && <div className="promo-aside__footer b-day-aside__footer">
                    <Provider promoActionStore={promoActionStore}>
                        <ConvertButton onClickHandler={goToMyHero}/>
                    </Provider>
                    <div className="color-gray-light">
                        {I18n.t(`${promoActionStore.i18nKey}.${
                            conversion_available ? 'scatter.promo_text' : 'scatter.once_a_day'}`)}
                    </div>
                </div>}
            </div>
        </AsideHeader>;
    }
}
