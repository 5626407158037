import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import Input from 'app/components/reusable/Input';
import {gameImage} from 'app/utils';
import {GamesStore} from 'app/store/GamesStore';
import SmartSelect from 'app/components/reusable/SmartSelect';
import {IncubatorCreateLineController} from '../../../controllers/IncubatorCreateLineController';

interface Props {
    isEditable: boolean
    game_id?: number
    gamesStore?: GamesStore
    controller?: IncubatorCreateLineController;
    onSelectGame(game_id: number): void
    setOptionsForHeartStone(game_id: number): void
    esports?: boolean
}

@inject('gamesStore', 'controller')
@observer
export default class IncubatorLineFormLineGame extends Component<Props> {
    componentDidMount(): void {
        const {gamesStore,
            esports} = this.props;
        gamesStore.changeEsports(esports);
    }

    handleSelectGame = (value: string): void => {
        const numValue = Number(value);

        this.props.controller.incubatorGame.clearError('stream_link');
        if (this.isLineTypeEditable(numValue)) {
            this.props.setOptionsForHeartStone(numValue);
        } else {
            this.props.onSelectGame(numValue);
        }
    };

    isLineTypeEditable = (game_id: number): boolean => this.props.gamesStore
        .elementById(game_id)?.name
        .toLowerCase() === 'hearthstone';

    render(): ReactNode {
        const {game_id, isEditable, gamesStore} = this.props;

        return <div className="field-item column-2">
            <label className="field__label">{I18n.t('table_title_match')}</label>
            {isEditable
                ? <div className="game-container">
                    <span className="game-container__icon">
                        {gameImage(game_id)}
                    </span>
                    <Input
                        className="game-container__input field__input"
                        type="text"
                        id="gameIncubator"
                        disabled={isEditable}
                        value={gamesStore.elementById(game_id)?.name}
                        name="game[Incubator]"
                    />
                </div>
                : <>
                    <SmartSelect
                        key="incubator_game_name"
                        collection={gamesStore.bySportWithoutOther.map(event => ({
                            text: event.name,
                            text2: '',
                            value: event.id.toString()
                        }))}
                        onChange={this.handleSelectGame}
                        value={game_id && game_id.toString()}
                        id="line[game_id]"
                        placeholder_text_multiple={I18n.t('tooltip_incubator_game_placeholder')}
                        search
                    />
                </>}
        </div>;
    }
}
