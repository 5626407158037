import React, {PureComponent, ReactNode} from 'react';
import ResultsContent from './ResultsContent';

export default class ResultsEsportContent extends PureComponent {
    render(): ReactNode {
        return <ResultsContent
            urlPrefix={'/play/results/'}
            esports
            keyPrefix={'results'}
            params={{filter: ''}}/>;
    }
}
