import React, {Component, ReactNode} from 'react';
import {betMoney, scrollToTop} from 'app/utils';
import I18n from 'app/i18n';
import {modalConfirm} from 'app/utils/modals/popups';
import P2pDepositsStoreProps from 'app/store/P2pDepositsStoreProps';
import {inject, observer} from 'mobx-react';
import bankLogotypeSvg from 'images/p2p/bank-logotype.svg';
import {isoCurrencyToHtml} from 'app/components/deposit/depositExchangeAmount';
import AvailableP2pDeposit from 'app/interfaces/AvailableP2pDeposit';
import {RatesType, DepositsStoreProps} from 'app/store/DepositsStore';
import {roundByCurrency} from 'app/utils/deposits/roundByCurrency';

interface DepositCardProps extends P2pDepositsStoreProps, DepositsStoreProps {
    deposit: AvailableP2pDeposit,
    system: {
        id: number
    }
    rates: RatesType
}

@inject('p2pDepositsStore', 'depositsStore')
@observer
export default class DepositCard extends Component<DepositCardProps> {
    acceptDeposit = (): void => {
        const {deposit: {amount, currency_amount, currency}, depositsStore} = this.props;
        const amountFormatted = this.amountFormatted(currency_amount, amount);
        const currencyAmountFormatted = this.currencyAmountFormatted(currency_amount || 0, currency, amount);
        const amountParam = currency_amount ? currencyAmountFormatted + amountFormatted : amountFormatted;
        modalConfirm(
            I18n.t('confirm_accept_p2p_deposit', {amount: amountParam}),
            () => this.props.p2pDepositsStore.acceptP2pDeposit(this.props.deposit, depositsStore.bonus_type, currency)
                .then(scrollToTop)
        );
    };

    currencyAmountFormatted = (currency_amount: number, currency: string, am: number): string => {
        const amount = betMoney({}, roundByCurrency({amount: am.toString(),
            currency,
            currency_amount: currency_amount.toString(),
            rates: this.props.rates}), false, true);
        return `${amount} ${isoCurrencyToHtml(currency)}`;
    };

    amountFormatted = (currency_amount: number, amount: number): string => {
        const amountF = betMoney({}, amount);
        return `${currency_amount ? `(${amountF})` : amountF}`;
    };

    render(): ReactNode {
        const {deposit: {id, amount, bank, currency_amount, currency}} = this.props;
        const {currentPaymentSystem: {related_to_bank_bins}} = this.props.p2pDepositsStore;

        return <div className={`card-deposit ${related_to_bank_bins ? 'card-deposit_logotype' : ''}`} key={id}>
            <div className="inner">
                <span className="name">{I18n.t('deposit_amount')}</span>
                {currency_amount && <span className="total">
                    {this.currencyAmountFormatted(currency_amount, currency, amount)}
                </span>}
                <span className={`${currency_amount ? 'total-default' : 'total'}`}>
                    {this.amountFormatted(currency_amount, amount)}
                </span>
                {related_to_bank_bins && <span className="logotype">
                    {bank
                        ? <img src={bank.logo.url} title={bank.name} alt={bank.name}/>
                        : <img src={bankLogotypeSvg} title="bankLogo" alt="bankLogo"/>}
                </span>}
            </div>
            <div className="button">
                <button
                    className="button-adaptive"
                    onClick={this.acceptDeposit}
                >
                    {I18n.t('user.make_deposit_label')}
                </button>
            </div>
        </div>;
    }
}
