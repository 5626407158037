import {fixed2, fixed8} from 'app/utils/fixed';
import {RatesType} from 'app/store/DepositsStore';
import {calculateAmountBySelectedCurrency} from 'app/utils/deposits/calculateAmountBySelectedCurrency';

const ASYMP_EQUAL = 8776;
const BTC = 579;
const CNY = 20803;
const EUR = 8364;
const INR = 8377;
const KRW = 8361;
const KZT = 8376;
const MXN = 8369;
const PHP = 8369;
const RUB = 8381;
const THB = 3647;
const UAH = 8372;
const JPY = 165;

const ISO_CURRENCY_HTML = {
    BRL: 'R$',
    BTC: String.fromCharCode(BTC),
    BYN: 'Br',
    CNY: String.fromCharCode(CNY),
    EUR: String.fromCharCode(EUR),
    IDR: 'Rp',
    INR: String.fromCharCode(INR),
    JPY: String.fromCharCode(JPY),
    KRW: String.fromCharCode(KRW),
    KZT: String.fromCharCode(KZT),
    MXN: String.fromCharCode(MXN),
    MYR: 'RM',
    PHP: String.fromCharCode(PHP),
    RUB: String.fromCharCode(RUB),
    THB: String.fromCharCode(THB),
    UAH: String.fromCharCode(UAH)
};

const CURRENCIES = {
    BRL: 'BRL',
    BYN: 'BYN',
    CNY: 'CNY',
    EUR: 'EUR',
    IDR: 'IDR',
    JPY: 'JPY',
    KZT: 'KZT',
    MXN: 'MXN',
    MYR: 'MYR',
    PHP: 'PHP',
    RUB: 'RUB',
    THB: 'THB',
    UAH: 'UAH'
};

const CURRENCIES_ROUNDED = ['RUB', 'UAH'];

const FIRST_AMOUNT_LIMIT = 1000;
const SECOND_AMOUNT_LIMIT = 10000;
const ROUND_TO_NEAREST_TEN = 10;
const ROUND_TO_NEAREST_HUNDRED = 100;

const arroundAmount = (value): number => {
    if (value < FIRST_AMOUNT_LIMIT) {
        return Math.round(value);
    } else if (value < SECOND_AMOUNT_LIMIT) {
        return Math.round(value / ROUND_TO_NEAREST_TEN) * ROUND_TO_NEAREST_TEN;
    }

    return Math.round(value / ROUND_TO_NEAREST_HUNDRED) * ROUND_TO_NEAREST_HUNDRED;
};

export const isoCurrencyToHtml = (currency: string): string => {
    const res = ISO_CURRENCY_HTML[currency];
    return res || currency;
};

const depositBtcAmount = (amount: string, rates: RatesType): string => {
    const res = fixed8(rates.btc_rate * Number(amount));
    return `${ISO_CURRENCY_HTML.BTC}${res}`;
};

const depositKrwAmount = (
    amount: string,
    rates: RatesType
): string => `${ISO_CURRENCY_HTML.KRW}${Math.ceil(Number(fixed2(Number(amount) * rates.krw_rate)))}`;

const depositInrAmount = (
    amount: string,
    rates: RatesType
): string => `${ISO_CURRENCY_HTML.INR}${Math.ceil(Number(fixed2(Number(amount) * rates.inr_rate)))}`;


export const depositExchangedAmount = (amount: string, exchange: string, rates: RatesType): string => {
    switch (exchange) {
    case CURRENCIES[exchange]: {
        const rate = rates[`${exchange.toLowerCase()}_rate`];

        if (CURRENCIES_ROUNDED.includes(exchange)) {
            return ` = ${ISO_CURRENCY_HTML[exchange]}${fixed2(arroundAmount(Number(amount) * rate))}`;
        }

        return ` = ${ISO_CURRENCY_HTML[exchange]}${fixed2(Number(amount) * rate)}`;
    }
    case 'BTC':
        return ` ${String.fromCharCode(ASYMP_EQUAL)} ${depositBtcAmount(amount, rates)}`;
    case 'KRW':
        return ` = ${depositKrwAmount(amount, rates)}`;
    case 'INR':
        return ` = ${depositInrAmount(amount, rates)}`;
    default:
        return '';
    }
};

export const depositP2PExcangedAmount = (amount: string, exchange: string, rates: RatesType): string => {
    switch (exchange) {
    case CURRENCIES[exchange]: {
        return ` = $${calculateAmountBySelectedCurrency(amount, exchange, rates)}`;
    }
    default:
        return '';
    }
};
