import React from 'react';
import createReactClass from 'create-react-class';
import I18n from 'app/i18n';
import {slowConnectionPromise, round2Decimal, isGuest} from 'app/utils';
import {showPopup} from 'app/utils/modals/ModalsService';
import {imageLoader} from 'app/components/shop/helpers';
import ShopConfirmPopup from './shop_confirm_popup';
import {shopItemPrice} from 'app/utils/ShopItemPriceFormat';
import logEvent from 'app/amplitude/log_event';
import Post from 'app/utils/Post';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import {SignUpButton} from 'app/components/auth/SignUpButton';
import {shopStore, userStore} from 'app/store/global';
import SmartLoader from 'app/components/reusable/SmartLoader';

const Product = createReactClass({
    buy(e = {}) {
        e && e.preventDefault();

        if (this.props.points < this.props.item.price) {
            return;
        }

        this.showConfirmPopup();
    },
    componentDidMount() {
        const imageUrl = this.props.item.image_url;

        if (imageUrl) {
            imageLoader(imageUrl, () => this.setState({imageUrl}));
        }
    },
    getInitialState() {
        return {imageUrl: null};
    },
    itemImages() {
        const images = [...this.props.item.images];
        return images.filter(image => image !== null);
    },
    postData(data) {
        slowConnectionPromise(
            new Post({
                params: data,
                url: '/shop'
            })
                .execute()
                .then(response => response.json())
                .then(response => {
                    if (response.success) {
                        const {item: {title, price, type_shop}} = this.props;
                        logEvent(
                            'SHOP_ITEM_PURCHASED',
                            {
                                item: title,
                                price,
                                real_money: false,
                                type: type_shop
                            }
                        );
                        toastSuccess(I18n.t('purchase_was_successful_message'));
                        if (response.success) {
                            shopStore.updatePurchasesData(response.purchases, response.has_more, 1);
                            userStore.update({user: response.user});
                            shopStore.updateShop(false);
                        }
                    } else {
                        toastError(I18n.t('server_error_try_later'));
                    }
                })
        );
    },

    renderBuyButton() {
        const {points, item: {price}} = this.props;

        if (isGuest()) {
            return <SignUpButton title={I18n.t('buy_shop_item')} usingOnTab={'SHOP'}/>;
        }

        if (points === null) {
            return null;
        }
        return points < price
            ? <div className="button">
                <button className="button-adaptive button-adaptive_disable" disabled>
                    {I18n.t('shop.you_need', {amount: shopItemPrice({cost_points: Math.ceil(price - points),
                        currency: 'points'})})}
                </button>
            </div>
            : <div className="button">
                <button onClick={this.buy} className="button-adaptive">{price} {I18n.t('user_points_shop')}</button>
            </div>;
    },
    showConfirmPopup(title, price, userData) {
        const {cost_points} = this.props.item;
        showPopup('shop-confirm-popup', props => <ShopConfirmPopup
            title={title || this.props.item.title}
            price={price || shopItemPrice({cost_points, currency: 'points'})}
            popupId={props.popupId}
            confirmed={() => {
                const data = {
                    id: this.props.item.id,
                    ...userData || {}
                };
                this.postData(data);
            }}/>);
    },
    render() {
        const {item} = this.props;
        const {title} = this.props.item;
        return <div className="shop__item">
            <div className="preview">
                <div className="preview__inner">
                    {this.state.imageUrl && item.type_shop !== 'money'
                        ? <div className="preview__image">
                            <img
                                alt={title}
                                title={title}
                                src={this.state.imageUrl}
                                className="product__preview-image"
                            />
                        </div>
                        : item.type_shop === 'money'
                            ? <div className="preview__money">
                                <svg className="icon">
                                    <use xlinkHref="#svg-money-icon" fill="none"/>
                                </svg>
                                <span className="total">${round2Decimal(item.amount_money)}</span>
                            </div>
                            : <SmartLoader />}
                </div>
            </div>
            <div className="description">
                <div className="description__inner">
                    <span className={`shop__title ${item.sale ? '' : 'shop__title_margin'}`}>{item.title}</span>
                    {item.on_sale
                        ? <div className="discount">
                            <span
                                className="sum">
                                {item.no_sale_price} {I18n.t('user_points_shop')}
                            </span>
                            <div className="percent">
                                <span>
                                    -{item.sale}%
                                </span>
                            </div>
                        </div>
                        : null}
                </div>
                {this.renderBuyButton()}
            </div>
        </div>;
    }
});

export default Product;
