import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {getLinkPath} from 'app/utils/link_helper';

interface RowTermsOfUseProps {
    rowClass: string;
    errorHint: (field: string) => ReactNode;
    cleanError: (field: string) => void;
    error: string;
}

export class RowTermsOfUse extends PureComponent<RowTermsOfUseProps> {
    cleanError = (): void => {
        this.props.cleanError('terms_of_use');
    };

    errorHint = (): ReactNode => this.props.errorHint('terms_of_use');

    render(): ReactNode {
        const {rowClass, error} = this.props;
        return <div className={rowClass}>
            <div className={`field__checkbox ${error ? 'field__input' : 'field '}`}
                onClick={this.cleanError}>
                <label className="field__check">
                    <input
                        className="field__check-hidden"
                        name="terms_of_use"
                        type="checkbox"
                        value="1"
                    />
                    <div className="field__check-box">
                        <svg height="11px" width="15px">
                            <use xlinkHref="#svg-check"/>
                        </svg>
                    </div>
                    <span className="label"
                        dangerouslySetInnerHTML={{
                            __html: I18n.t('service_agree', {
                                policy_link:
    `<a target="_blank" href=${getLinkPath('/privacy_policy')}>${I18n.t('privacy_policy')}</a>`,
                                terms_link:
    `<a target="_blank" href=${getLinkPath('/terms_and_conditions')}>${I18n.t('terms_and_conditions')}</a>`
                            })
                        }}/>
                </label>
            </div>
            {this.errorHint()}
            <div className="field__error-hint" style={{display: error ? 'block' : 'none'}}>{error}</div>
        </div>;
    }
}
