import React from 'react';
import I18n from 'app/i18n';

export interface NewMessageProps {
    data: {
        subject: string
    }
}

const newMessage = (props: NewMessageProps): React.ReactNode => <div>
    {props.data.subject}
    <br />
    {I18n.t('tap_to_open')}
</div>;

export default newMessage;
