import logEvent from 'app/amplitude/log_event';
import BetSlip from 'app/components/bet_slip/BetSlip';
import {BaseStrategy} from 'app/components/bet_slip/strategies/Base.strategy';
import {BetStrategy} from 'app/components/bet_slip/strategies/Bet.strategy';
import {BetsPostContractInterface} from 'app/components/bet_slip/BetsPost.contract';
import {ExpressStrategy} from 'app/components/bet_slip/strategies/Express.strategy';
import {IncubatorStrategy} from 'app/components/bet_slip/strategies/Incubator.strategy';
import {toastError} from 'app/components/toasts/liteToast';
import {BaseController} from 'app/controllers/BaseController';
import LineInterface from 'app/interfaces/LineInterface';
import MakeBetInterface from 'app/interfaces/MakeBetInterface';
import {isPlayMoney} from 'app/lines/isPlayMoney';
import {isRealMoney} from 'app/lines/isRealMoney';
import {tableNick} from 'app/lines/tableNick';
import {validationAmount} from 'app/lines/validationAmount';
import {modalConfirm, showTermsCommon} from 'app/utils/modals/popups';
import {ArtifactBetStore} from 'app/store/ArtifactBetStore';
import {ArtifactStore} from 'app/store/ArtifactStore';
import {BetSlipStore} from 'app/store/BetSlipStore';
import NotificationStore from 'app/store/notifications';
import {TableStore} from 'app/store/TableStore';
import {UserStore} from 'app/store/UserStore';
import {fixed2} from 'app/utils/fixed';
import {isBetPage} from 'app/utils/IsBetPage';
import {isRootLine, rootLine} from 'app/utils/line/rootLine';
import Post from 'app/utils/Post';
import {isDesktop} from 'app/utils/windowFunctions';
import {BetSlipFocus, DEMO_MONEY, GAMER_2, REAL_MONEY, TURN_OFF_SCROLL_CLASS_NAME} from 'app/utils/Consts';
import {windowHeight} from 'app/components/bet_slip/utils/windowHeight';
import {windowWidth} from 'app/components/bet_slip/utils/windowWidth';
import {computed} from 'mobx';
import {betsBalance} from 'app/utils/balance/betsBalance';
import ErrorsServerInterface from 'app/interfaces/ErrorsServerInterface';
import {showErrorToast} from 'app/components/toasts/promiseToast';
import {UNPROCESSABLE_ENTITY} from 'app/utils/HttpStatuses';

const BET_SLIP_WIDTH = 374;
interface ModalParams {
    message: string
    onClick: () => void
}

export default class BetSlipController extends BaseController {
    betSlipStore: BetSlipStore;

    artifactStore: ArtifactStore;

    artifactBetStore: ArtifactBetStore;

    tableStore: TableStore;

    userStore: UserStore;

    betSlip: BetSlip;

    // eslint-disable-next-line max-params
    constructor(betSlipStore: BetSlipStore,
        artifactBetStore: ArtifactBetStore, userStore: UserStore,
        artifactStore: ArtifactStore, tableStore: TableStore) {
        super();
        this.betSlipStore = betSlipStore;
        this.artifactBetStore = artifactBetStore;
        this.userStore = userStore;
        this.artifactStore = artifactStore;
        this.tableStore = tableStore;
    }

    @computed
    get strategy(): BaseStrategy {
        const {incubator2, incubator2WithCoef, lines} = this.betSlipStore;

        if (incubator2) {
            return incubator2WithCoef ? new BetStrategy(this) : new IncubatorStrategy(this);
        }
        return lines && lines.length > 1 ? new ExpressStrategy(this) : new BetStrategy(this);
    }

    betSlipDidUpdate(betSlip: BetSlip): void {
        return this.strategy.betSlipDidUpdate(betSlip);
    }

    suggestCoef = (): void => {
        this.betSlipStore.setShowSuggestCoef(false);
        this.betSlipStore.setIncubator2Form(true);
    };

    setWarning = (warning: string): void => this.betSlipStore.setWarning(warning);

    clearForm = (): void => {
        this.betSlipStore.setAmount('');
        this.betSlipStore.setLoading(false);
        this.betSlipStore.setCoefIncreased(null);
        this.betSlipStore.setNetPrize('');
        this.betSlipStore.setWin('');
        this.betSlipStore.setFormCoef(null);
        this.betSlipStore.setLimit(0);
        this.betSlipStore.setShowSuggestCoef(true);
        this.setLoadingForm(false);
    };

    clearType = (): void => {
        this.betSlipStore.setType(this.userStore.user.demo ? DEMO_MONEY : REAL_MONEY);
    };

    setLoadingForm = (loadingForm: boolean): void => {
        this.betSlipStore.setLoadingForm(loadingForm);
    };

    makeBetRequest = (params: MakeBetInterface): void => {
        const {loading, setLoading} = params;

        if (loading) {
            return;
        }
        setLoading(true);
        new Post({
            params: this.strategy.getParams(params),
            url: this.strategy.postUrl
        }).execute().then<BetsPostContractInterface>(async response => {
            const json = await response.json();

            if (response.status === UNPROCESSABLE_ENTITY) {
                throw json;
            }
            return json;
        // eslint-disable-next-line max-statements
        }).then(data => {
            if (data.terms) {
                showTermsCommon();
                return;
            }
            if (data.success) {
                if (params.line && params.line.delay_for_bets_time && !data.incubator_2_bet && !params.incubatorBetId) {
                    this.betSlipStore.setDelayTime(params.line.delay_for_bets_time);
                }

                this.placeBetSuccess(params, data);
            } else if (data.confirm) {
                this.placeBetConfirm(params, data);
            } else if (data.action) {
                this.placeBetAction(params, data);
            } else if (data.message) {
                if (data.rest_time) {
                    this.betSlipStore.setDelayTime(data.rest_time);
                    this.betSlipStore.setLoading(true);
                    this.betSlipStore.setClearForm(true, params);
                } else if (data.message === 'disabled_country') {
                    NotificationStore.add({type: 'disabled_country'});
                } else {
                    this.betSlipStore.setDelayTime(0);
                    toastError(data.message);
                }
            }
        }).catch((errors: ErrorsServerInterface): void => {
            const [errorKey] = Object.keys(errors);
            const [error] = errors[errorKey];

            if (error) {
                toastError(`${errorKey} ${error}`);
                return;
            }
            showErrorToast(errors);
        }).finally(() => {
            if (!this.betSlipStore.delayedTimerId) {
                setLoading(false);
            }
        });
    };

    makeLinkRequest = (lineId: number): void => {
        new Post({params: {id: lineId}, url: '/oddin/stats/scoreboard'}).execute().then(response => {
            if (response.ok) {
                return response.json();
            }
            return null;
        }).then(r => {
            if (r.url) {
                this.betSlipStore.setStatsLink(r.url);
            }
        });
    };

    placeBetSuccess = (params: MakeBetInterface, data: BetsPostContractInterface): void => {
        this.strategy.placeBetSuccess(params);
        this.sendNotification(params, data);
        this.updateUser(data);
    };

    makeBet = (): void => {
        const {formCoef, type, loading, setLoading, amount, incubator2WithCoef, num, selectedLine: {incubator_2_bet}} = this.betSlipStore;
        const incubatorBetId = incubator2WithCoef ? incubator_2_bet && incubator_2_bet[`id_${num}`] : null;
        this.makeBetRequest(this.strategy.makeBetParams({amount,
            changeAmount: this.changeAmount,
            coef: Number(formCoef),
            incubatorBetId,
            loading,
            setLoading,
            type}));
    };

    removeClosedLines = (): void => {
        this.betSlipStore.removeClosedLines();
    };

    removeAllGames = (): void => {
        this.betSlipStore.setShowForm(false);
        this.betSlipStore.reset();
    };

    removeOnclick = (line: LineInterface): void => {
        this.strategy.removeOnclick(line);
    };

    removedScrollBody = (): void => {
        if (isDesktop()) {
            return;
        }

        const bodyClassList = document.body.classList;

        if (isBetPage() && this.betSlipStore.showForm && !this.betSlipStore.minimize && this.betSlipStore.express) {
            bodyClassList.add(TURN_OFF_SCROLL_CLASS_NAME);
        } else {
            bodyClassList.remove(TURN_OFF_SCROLL_CLASS_NAME);
        }
    };

    setPlaceBetConfirm(): void {
        this.betSlipStore.setConfirm(false);
    }

    setMinimize(minimize: boolean): void {
        this.betSlipStore.setMinimize(minimize);
    }

    getMultipleBetLimit = (type: string): number => {
        const {incubator2WithCoef, selectedLine, num} = this.betSlipStore;
        const {multiple_bet_limit_enabled, real_money_multiple_limit,
            play_money_multiple_limit, real_money_multiple_limit_2} = selectedLine;

        if (!multiple_bet_limit_enabled) {
            return null;
        }
        if (isPlayMoney(type)) {
            return play_money_multiple_limit;
        }
        return incubator2WithCoef && num === GAMER_2 ? real_money_multiple_limit_2 : real_money_multiple_limit;
    };

    updateUser = (data: BetsPostContractInterface): void => {
        if (data.inventory_items) {
            this.artifactBetStore.initArtifacts();
            this.artifactStore.updateInventoryItems(data);
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.userStore.update({user: data.balance});
        this.userStore.updateFirstBet(true);
    };

    logEventBet = (params: MakeBetInterface): void => {
        const {artifactsToBet, amount, type, num, line, line: {tourn, game, live, esports}} = params;
        const isArtifactBet = artifactsToBet.length > 0;
        const first_team = 1;
        const second_team = 2;
        logEvent(
            'SINGLE_BET_PLACED',
            {
                artifact_bet: isArtifactBet,
                bet_on_team: tableNick(rootLine(line), num),
                esports,
                event: tourn,
                live,
                num: isArtifactBet ? artifactsToBet.length : fixed2(amount),
                real_money: isRealMoney(type),
                sport: game,
                team_1: tableNick(rootLine(line), first_team),
                team_1_nested: isRootLine(line) ? 'none' : tableNick(line, first_team),
                team_2: tableNick(rootLine(line), second_team),
                team_2_nested: isRootLine(line) ? 'none' : tableNick(line, second_team)
            }
        );
    };

    logEventBetExpress = (params: MakeBetInterface): void => {
        const {artifactsToBet, amount, type, isEsport} = params;
        const isArtifactBet = artifactsToBet.length > 0;
        logEvent(
            'EXPRESS_PLACED',
            {
                artifact_bet: isArtifactBet,
                esports: isEsport,
                num: isArtifactBet ? artifactsToBet.length : fixed2(amount),
                real_money: isRealMoney(type)
            }
        );
    };

    sendNotification = (params: MakeBetInterface, data: BetsPostContractInterface): void => {
        const {express} = params;

        if (!express && data.delay) {
            this.betSlipStore.notifyBetWasAccepted(data);
        } else {
            NotificationStore.add({...data.notification,
                type: express ? 'express_bet_accepted' : 'bet_accepted'});
        }
    };

    placeBetConfirm = (params: MakeBetInterface, data: BetsPostContractInterface): void => {
        if (!params.express) {
            this.betSlipStore.restartUpdate();
        }
        if (data.message) {
            modalConfirm(data.message, () => {
                this.betSlipStore.setConfirm(false);
                if (data.coef) {
                    params.coef = Number(data.coef);
                    this.makeBetRequest(params);
                }
            });
        }
    };

    placeBetSingleSuccess = (params: MakeBetInterface): void => {
        if (this.userStore.user.coupon_close) {
            this.betSlipStore.setShowForm(false);
        } else {
            this.betSlipStore.setClearForm(true, params);
            this.betSlipStore.setNewForm(false);
            this.betSlipStore.resetFormBet();
        }
    };

    placeBetAction = (params: MakeBetInterface, data: BetsPostContractInterface): void => {
        logEvent('PLACE_BET_ERROR', {error: data.message});
        let onClick = null;
        switch (data.action) {
        case 'get_deposit':
            onClick = () => {
                location.href = '/deposits';
            };
            break;
        case 'cancel_cashout':
            onClick = () => {
                new Post({url: '/cashouts/cancel_oldest'}).execute().then(() => this.makeBetRequest(params));
            };
            break;
        case 'limit_was_exhausted':
            if (data.allowed_limit > 0) {
                params.amount = Number(data.allowed_limit).toString();
                onClick = () => {
                    this.makeBetRequest(params);
                };
            }
            break;
        case 'get_deposit_playmoney':
            toastError(data.message);
            return;
        default:
            onClick = null;
        }
        this.showModal({message: data.message, onClick});
    };

    showModal = (params: ModalParams): void => {
        if (params.onClick) {
            modalConfirm(params.message, params.onClick);
        } else {
            toastError(params.message);
        }
    };

    maxWin = (): number => this.strategy.maxWin();

    changeWin(newWin: string, noValidation = false): void {
        const {netPrize, limit, formCoef, setAmount, setNetPrize, setWin, setFocus} = this.betSlipStore;
        const coefNumber = Number(formCoef);

        if (this.checkEmpty(newWin)) {
            return;
        }

        const valWin = noValidation
            ? newWin
            : validationAmount(newWin, netPrize, Number(limit) * (coefNumber - 1));
        const newAmount = Number(valWin) / (coefNumber - 1);
        setAmount(fixed2(newAmount));
        setFocus(BetSlipFocus.WIN);
        setNetPrize(valWin);
        setWin(fixed2(Number(newAmount) * coefNumber));
    }

    getLimit = (type: string): number => this.strategy.getLimit(type);

    setLimitBetSlip = (): void => {
        this.betSlipStore.setLimit(this.getLimit(this.betSlipStore.type));
    };

    changeAmount = (newAmount: string, noValidation = false): void => {
        const {amount, formCoef, limit, setAmount, setNetPrize, setWin, setFocus} = this.betSlipStore;
        const coefNumber = Number(formCoef);

        if (this.checkEmpty(newAmount)) {
            return;
        }

        const valAmount = noValidation ? newAmount : validationAmount(newAmount, amount, limit);
        setAmount(valAmount);
        setFocus(BetSlipFocus.AMOUNT);
        setNetPrize(fixed2(Number(valAmount) * (coefNumber - 1)));
        setWin(fixed2(Number(valAmount) * coefNumber));
    };

    changeInput = (amount: string, noValidation = false): void => {
        if (this.betSlipStore.amountFocus) {
            this.changeAmount(amount, noValidation);
        } else if (this.betSlipStore.winFocus) {
            this.changeWin(amount, noValidation);
        }
    };

    get max(): number {
        const {type} = this.betSlipStore;
        const {user, user: {play_money}} = this.userStore;
        const balance = Number(isPlayMoney(type) ? play_money : betsBalance(user));
        const limit = this.getLimit(type);
        return balance > limit ? limit : balance;
    }

    changeCoef = (coef: string): void => {
        this.strategy.setCoef(coef);
    };

    checkEmpty = (value: string): boolean => {
        if (!value) {
            this.betSlipStore.setAmount('');
            this.betSlipStore.setNetPrize('');
            return true;
        }
        return false;
    };

    get incubator(): boolean {
        return this.betSlipStore.selectedLine?.incubator;
    }

    @computed
    get bets(): {num: number, rootLine: LineInterface, selectedLine: LineInterface}[] {
        return this.strategy.bets();
    }

    @computed
    get bet(): {num: number, rootLine: LineInterface, selectedLine: LineInterface} {
        const [bet] = this.bets;
        return bet;
    }

    get countBets(): number {
        return this.bets.length;
    }

    switchTab = (type: string): void => {
        this.artifactBetStore.resetArtifacts();
        const {setAmount, setNetPrize, setType, setWin} = this.betSlipStore;
        setAmount('');
        setNetPrize('');
        setType(type);
        setWin('');
        // Was setState Callback
        this.setLimitBetSlip();
        this.setDraggableBounds();
        // Was Callback
    };

    setDraggableBounds = (): void => {
        const {current} = this.betSlip.container;
        const height = current?.offsetHeight || 0;
        const browserHeight = windowHeight();
        const browserWidth = windowWidth();
        let calcTop = this.betSlip.state.newTop;
        let calcLeft = this.betSlip.state.newLeft;
        const {top, left} = current.getBoundingClientRect();

        if (top + height > browserHeight) {
            calcTop = browserHeight - (top + height - calcTop);
            current.style.top = `${calcTop}px`;
        }
        if (left + BET_SLIP_WIDTH > browserWidth) {
            calcLeft = browserWidth - (left + BET_SLIP_WIDTH - calcLeft);
            current.style.left = `${calcLeft}px`;
        }
        this.betSlip.setState({height,
            newLeft: calcLeft,
            newTop: calcTop,
            right: browserWidth - BET_SLIP_WIDTH - calcLeft,
            top: browserHeight - height - calcTop});
    };
}
