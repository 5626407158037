import $ from 'jquery';
import React from 'react';
import PropTypes from 'prop-types';
import {langsValue} from 'app/utils/langs/langsValue';

export default class Answer extends React.PureComponent {
    constructor() {
        super();
        this.selectAnswer = this.selectAnswer.bind(this);
        this.selectMultiAnswer = this.selectMultiAnswer.bind(this);
    }

    selectAnswer() {
        this.props.selectAnswer(this.props.id);
    }

    selectMultiAnswer(e) {
        this.props.selectMultiAnswer({
            checked: $(e.target).is(':checked'),
            id: this.props.id
        });
    }

    render() {
        const {answerWasGiven} = this.props;

        return this.props.correct_answers_count === 1
            ? <div className="compendium-grid__addon-option">
                <label className="field__radio">
                    <input
                        type="radio"
                        name={`answer_${this.props.prediction_id}`}
                        checked={this.props.isSelectedAnswer}
                        value="1"
                        onClick={this.selectAnswer}
                        disabled={answerWasGiven}
                    />
                    <div className="field__radio-box"/>
                    <div className="field__radio-label">{langsValue(this.props, 'answer')}</div>
                </label>
            </div>
            : <div className="compendium-grid__addon-option">
                <label className="field__check">
                    <input
                        type="checkbox"
                        value="1"
                        className="field__check-hidden"
                        onChange={this.selectMultiAnswer}
                        checked={this.props.isSelectedAnswer}
                        disabled={answerWasGiven}
                    />
                    <div className="field__check-box">
                        <svg height="11px" width="15px">
                            <use xlinkHref="#svg-check"/>
                        </svg>
                    </div>
                    <div className="field__radio-label">{langsValue(this.props, 'answer')}</div>
                </label>
            </div>;
    }
}

Answer.propTypes = {
    answer: PropTypes.string.isRequired,
    correct_answers_count: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    langs: PropTypes.object.isRequired,
    prediction_id: PropTypes.number.isRequired,
    selectAnswer: PropTypes.func.isRequired,
    selectMultiAnswer: PropTypes.func.isRequired
};

