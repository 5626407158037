import React, {Component, MouseEvent, ReactNode} from 'react';

interface ShowPasswordEyeProps {
    title: string
    onClickHandler?: (e: MouseEvent) => void
    eyeSign: string
}

export class ShowPasswordEye extends Component<ShowPasswordEyeProps> {
    render(): ReactNode {
        return <span
            title={this.props.title}
            onClick={this.props.onClickHandler}
            className="field__input-icon field__input-icon--pointer">
            <svg className="svg-default">
                <use xlinkHref={this.props.eyeSign}/>
            </svg>
        </span>;
    }
}
