import React from 'react';
import {streamMinimize, setStreamParam} from 'app/components/streams/stream_functions';
import StreamsPlayPage from 'app/components/streams/streams_play_page';
import Stream from 'app/components/streams/stream';
import {streamStore} from 'app/store/global';
import {showPopup} from 'app/utils/modals/ModalsService';
import StreamCreationPopup from 'app/components/streams/stream_creation_popup';

const openStream = (stream, minimize = false) => {
    streamStore.chooseStream(stream, minimize);
    setStreamParam(stream);
};

const openAddStreamPopup = () => showPopup('stream-add', props => <StreamCreationPopup popupId={props.popupId}/>);

export {openStream, streamMinimize, Stream, StreamsPlayPage, openAddStreamPopup};
