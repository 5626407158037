import React, {PureComponent, Fragment} from 'react';
import I18n from 'app/i18n';
import PossibleWinAmount from 'app/components/bet_form/PossibleWinAmount';
import {betMoney, fixed3} from 'app/utils';
import {userStore} from 'app/store/global';

export default class PlaceBetButton extends PureComponent {
    coefChanged() {
        const {amount, coefChanged, countArtifacts} = this.props;
        return (Number(amount) > 0 || Number(countArtifacts) > 0) && coefChanged;
    }

    renderButtonOnChangedCoef() {
        const {coef, handlePlaceBet, disabled} = this.props;
        return <div className="match-details__place-bet__coef-changed">
            <div className="match-details__place-bet__coef-changed__text">
                {I18n.t('coefficient_has_been_changed', {coef: fixed3(coef)})}
            </div>
            <a href="#"
                className="btn btn--large match-details__place-bet coef-changed"
                onClick={ disabled ? null : handlePlaceBet}>
                {userStore.user.accept_any_coef ? I18n.t('accept_changes_and_bet') : I18n.t('accept') }
            </a>
        </div>;
    }

    renderButtonPlaceBet() {
        const {handlePlaceBet, disabled} = this.props;
        return <button
            disabled={disabled}
            className="btn btn--large match-details__place-bet"
            onClick={handlePlaceBet}
        >
            {this.renderSpan()}
        </button>;
    }

    renderSpan() {
        const {amount, coef, playmoney, countArtifacts} = this.props;

        if (Number(countArtifacts) >= 0) {
            return <span>{I18n.t('place_a_bet_full')}</span>;
        }

        return <Fragment>
            <span>
                {I18n.t('place_a_bet_full')} {amount > 0
                    ? betMoney({
                        amount,
                        playmoney
                    })
                    : ''}
            </span>

            <PossibleWinAmount
                amount={amount}
                coef={coef}
                playmoney={playmoney}
            />
        </Fragment>;
    }

    render() {
        if (this.coefChanged()) {
            return this.renderButtonOnChangedCoef();
        }

        return this.renderButtonPlaceBet();
    }
}
