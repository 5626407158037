import {fixed2} from 'app/utils/fixed';

export const signedAmount = (amount: number, sign: string): string => {
    const am = fixed2(amount);

    if (!Number(am)) {
        return null;
    }

    return sign === '$'
        ? `$${am}`
        : `${am} ${sign}`;
};
