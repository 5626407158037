import React, {Component} from 'react';
import {streamStore} from 'app/store/global';
import Stream from './stream';
import {inject, observer} from 'mobx-react';
import logEvent from 'app/amplitude/log_event';
import {checkingPages, streamGameImage} from 'app/utils';
import {goToLinkSimpleBets} from 'app/utils/app_links';
import {openAddStreamPopup} from 'app/components/streams';
import {i18n_t} from 'app/i18n';
import {setStreamParam} from 'app/components/streams/stream_functions';

interface StreamsMenuProps {
}

interface StreamsMenuState {
    current_game: string;
}

export default inject('streamStore')(observer(class StreamsMenu extends Component<StreamsMenuProps, StreamsMenuState> {
    constructor(props: StreamsMenuProps) {
        super(props);

        this.state = {current_game: null};
    }

    openStreamHandler = stream => {
        logEvent('STREAM_OPENED');
        if (checkingPages.isHomePage()) {
            goToLinkSimpleBets();
        }
        streamStore.chooseStream(stream);
        setStreamParam(stream);
    };

    handleStreamCreationPopup = e => {
        e.preventDefault();
        openAddStreamPopup();
    };

    handleClick = game => {
        this.setState({current_game: game === this.state.current_game ? null : game});
    };

    streams = () => {
        if (this.state.current_game && streamStore.streams_by_games[this.state.current_game]) {
            return streamStore.streams_by_games[this.state.current_game];
        }
        return streamStore.streams;
    };

    render() {
        const style = {backgroundColor: '#2C2D2E'};
        return <div className="tabs js-tabs">
            <div className="tabs__content">
                <div className="tabs__header">
                    {Object.keys(streamStore.streams_by_games).map(key => <a
                        key={key}
                        className={`tabs__link tabs__link-logo ${this.state.current_game === key ? 'active-link' : ''}`}
                        href="#"
                        onClick={() => this.handleClick(key)}>
                        {streamGameImage(key, true, 'filter--game')}
                    </a>)
                    }
                </div>
                <div className="tabs__content-item">
                    <div className="stream-list stream-list--play">
                        {this.streams().map(s => <Stream
                            stream={s}
                            preview="play"
                            key={s.id}
                            userStream={false}
                            onClick={this.openStreamHandler}
                            imageLastUpdate={streamStore.imageLastUpdate}
                            mobile
                        />)}
                        {
                            streamStore.userStreams.map(s => <Stream
                                stream={s}
                                preview="play"
                                key={s.id}
                                userStream
                                onClick={this.openStreamHandler}
                                imageLastUpdate={streamStore.imageLastUpdate}
                                mobile
                            />)
                        }
                        <a
                            href="#"
                            data-popup="#stream-add"
                            style={style}
                            onClick={this.handleStreamCreationPopup}
                            className="media-preview media-preview--add"
                        >
                            <span className="media-preview--add-stream-mobile">
                                <svg height="20px" width="20px">
                                    <use xlinkHref="#svg-stream-add-mobile"/>
                                </svg>
                            </span>
                            <span className="media-preview__name">
                                <span className="media-preview__name-text text-center">{i18n_t('add_your_own')}</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>;
    }
}));
