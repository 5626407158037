import React, {Component, Fragment} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import Recipe from './recipe';
import Pagination from './pagination';
import {getLinkPath, scrollToElement, userSignedIn} from 'app/utils';
import $ from 'jquery';
import {recipeStore} from 'app/store/global';
import SellOrders from './sell_orders';
import BuyOrders from './buy_orders';
import SearchInput from 'app/components/SearchInput';
import EmptyResults from 'app/components/articles/EmptyResults';
import Get from 'app/utils/Get';
import SmartPageLoader from 'app/components/reusable/smart_loaders/SmartPageLoader';
import {locationQuery} from 'app/utils/navigate/location';
import {KEY_ENTER} from 'app/utils/Consts';

@inject('recipeStore')
@observer
export default class Recipes extends Component {
    state = {
        loading: true,
        searchValue: ''
    };

    recipesTable = React.createRef();

    componentDidMount() {
        this.switchPage({page: locationQuery().page || 1});
    }

    setLoadingTo = (state, callback) => this.setState({loading: state}, callback);

    switchPage = (params, searchValue = '', scrolling = false) => {
        this.setState({loading: true});
        new Get({params: {...params, search: searchValue},
            url: getLinkPath('/shop/marketplace')})
            .execute()
            .then(response => response.json())
            .then(response => {
                recipeStore.updateRecipes(response);
                this.setLoadingTo(false, () => scrolling && scrollToElement($(this.recipesTable.current)));
            });
    };

    searchHandle = event => {
        if (event.key === KEY_ENTER) {
            this.switchPage({page: 1}, event.currentTarget.value);
        }
    };

    searchValueHandler = event => {
        this.setState({searchValue: event.currentTarget.value});
    };

    handleRefreshSearch = () => {
        this.setState({searchValue: ''});
        this.switchPage({page: 1});
    };

    render() {
        const {recipes = [], paginate} = this.props.recipeStore;
        const {loading} = this.state;
        return <div className="market">
            {userSignedIn()
                ? <div className="tabs js-tabs tabs--lots">
                    <div className="tabs__content">
                        <div className="tabs__content-item">
                            <SellOrders/>
                            <BuyOrders/>
                        </div>
                    </div>
                </div>
                : null
            }
            {loading
                ? <div className="table-more">
                    <SmartPageLoader/>
                </div>
                : <Fragment>
                    <div className="grid-header-marketplace background__grey vertical-center">
                        <div className="grid-row col-1">
                            <SearchInput
                                handleChange={this.searchValueHandler}
                                handleKeyPress={this.searchHandle}
                                value={this.state.searchValue}
                                onClick={this.handleRefreshSearch}>
                            </SearchInput>
                        </div>
                    </div>
                    <div className="market__items js-table" ref={this.recipesTable}>
                        <div className="market__items__heading tables-heading">
                            <div className="market__items__name-quantity">
                                <div className="market__items__name">
                                    {I18n.t('user.name')}
                                </div>
                                <div className="market__items__quantity">
                                    {I18n.t('quantity')}
                                </div>
                            </div>
                            <div className="market__items__price-col">
                                <div className="market__items__price-title">
                                    {I18n.t('price')}
                                </div>
                            </div>
                        </div>
                        {recipes.length > 0
                            ? recipes.map(recipe => <Recipe key={recipe.id} {...recipe}/>)
                            : <EmptyResults text={I18n.t('empty_marketplace_page')}/>}
                    </div>
                </Fragment>}
            <Pagination {...paginate} searchValue={this.state.searchValue} switchPage={this.switchPage}/>
        </div>;
    }
}
