import $ from 'jquery';
import React, {ReactNode} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import {linkMarketplace, linkMyHero} from 'app/utils/app_links';
import EffectsHeader from './EffectsHeader';
import {ArtifactStore} from 'app/store/ArtifactStore';
import {scrollToTop, isDesktop} from 'app/utils';
import ListArtifactEffectsButton from './ListArtifactEffectsButton';
import GoMarketplace from './GoMarketplace';

interface ListOfArtifactsProps {
    artifactStore?: ArtifactStore;
}

interface ListOfArtifactsState{
    showArtifactsContent: boolean;
}

@inject('artifactStore')
@observer
export default class ListOfArtifacts extends React.Component<ListOfArtifactsProps, ListOfArtifactsState> {
    artifactsOpener: React.RefObject<HTMLDivElement> = React.createRef();

    artifactsContainer: React.RefObject<HTMLDivElement> = React.createRef();

    artifactsScrollContainer: React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props: ListOfArtifactsProps) {
        super(props);

        this.state = {
            showArtifactsContent: false
        };
    }

    componentDidMount(): void {
        document.body.addEventListener('click', this.handlerClose);
    }

    componentWillUnmount(): void {
        document.body.removeEventListener('click', this.handlerClose);
    }

    handlerClose = (e: Event): void => {
        if (!this.state.showArtifactsContent) {
            return;
        }
        if (!this.artifactsOpener.current.contains(e.target as Node) &&
            !this.artifactsContainer.current.contains(e.target as Node)) {
            this.toggleArtifactsContainer();
        }
    };

    toggleArtifactsContainer = (): void => {
        if (this.state.showArtifactsContent) {
            if (!isDesktop()) {
                document.body.classList.remove('body-overflow');
            }
            $(this.artifactsContainer.current).slideUp();
        } else {
            if (!isDesktop()) {
                document.body.classList.add('body-overflow');
            }
            $(this.artifactsContainer.current).stop().slideToggle();
            $(this.artifactsScrollContainer.current).scrollTop(0);
        }
        this.setState({showArtifactsContent: !this.state.showArtifactsContent});
    };

    linkHandler = (): void => {
        scrollToTop();
        this.toggleArtifactsContainer();
    };

    render(): ReactNode {
        const {artifactStore: {scrollEffects, artifactEffects}} = this.props;
        const {showArtifactsContent} = this.state;
        return <div className="artifacts-user">
            <div
                className={`artifacts-user-opener${
                    showArtifactsContent ? ' artifacts-user-opener_active' : ''}`}
                ref={this.artifactsOpener}
                onClick={this.toggleArtifactsContainer}
            >
                <span className="icon icon_man">
                    <svg>
                        <use xlinkHref="#svg-man"/>
                    </svg>
                </span>
                <span className="icon icon_arrow">
                    <svg>
                        <use xlinkHref="#svg-arr-select-small"/>
                    </svg>
                </span>
            </div>
            <div
                className={`artifacts-user-content${
                    showArtifactsContent ? ' artifacts-user-content_active' : '' }`}
                ref={this.artifactsContainer}>
                {artifactEffects.length === 0 && scrollEffects.length === 0
                    ? <GoMarketplace onClick={this.linkHandler}/>
                    : <div className="artifacts-user-all">
                        <div className="artifacts-user-all__scroll" ref={this.artifactsScrollContainer}>
                            <EffectsHeader effects={scrollEffects} title={I18n.t('scrolls')}/>
                            <EffectsHeader effects={artifactEffects} title={I18n.t('artifacts')}/>
                        </div>
                        <div className="buttons-adaptve-group buttons-adaptve-group_couple">
                            <ListArtifactEffectsButton
                                linkMyHero={linkMyHero}
                                onClick={this.linkHandler}
                                text={I18n.t('shop.button_go_inventory')}/>
                            <ListArtifactEffectsButton
                                linkMyHero={linkMarketplace}
                                onClick={this.linkHandler}
                                text={I18n.t('shop.button_go_marketplace')}/>
                        </div>
                    </div>}
            </div>
        </div>;
    }
}

