import MY_PROFILE_PUBLIC from 'images/promo_action/instructions/image1.png';
import CREATE_NEW_URL from 'images/promo_action/instructions/image2.png';
import INVENTORY from 'images/promo_action/instructions/image3.png';
import TRADE_OFFERS from 'images/promo_action/instructions/image4.png';
import TRADE_OFFERS_LINK from 'images/promo_action/instructions/image5.png';
import INVENTORY_SETTINGS from 'images/promo_action/instructions/image6.png';

export default {
    INSTRUCTIONS: {
        CREATE_NEW_URL,
        INVENTORY,
        INVENTORY_SETTINGS,
        MY_PROFILE_PUBLIC,
        TRADE_OFFERS,
        TRADE_OFFERS_LINK
    }
};
