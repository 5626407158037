import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import {IncubatorCreateLineController} from 'app/controllers/IncubatorCreateLineController';
import IncubatorUsersBets from 'app/components/pages/incubator/line/IncubatorUsersBets';
import {betMoney, fixed2, flagImage, isDesktop} from 'app/utils';
import {GAMER_1, KEY_DOWN, KEY_UP} from 'app/utils/Consts';
import changeCoefByStep from 'app/utils/ChangeCoefByStep';
import {MAX_COEF, MIN_COEF, STEP, THREE} from './ControlsCommonConstants';
import IncubatorLineLeftWinner from 'app/components/pages/incubator/line/IncubatorLineLeftWinner';

interface Props {
    controller?: IncubatorCreateLineController
}

const STEP_ARROW = 0.2;
const SCALE = 3;

@inject('controller')
@observer
export default class IncubatorLineLeft extends Component<Props> {
    get controller(): IncubatorCreateLineController {
        return this.props.controller;
    }

    handleInputChange = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        let initValue: string = e.currentTarget.value;
        let currentCoef = Number(initValue);
        if (e.key === KEY_UP && currentCoef < MAX_COEF) {
            currentCoef += STEP;
            initValue = currentCoef >= MAX_COEF ? '9.999' : String(currentCoef.toFixed(THREE));
            this.controller.onChangeCoef1(String(initValue));
        }
        if (e.key === KEY_DOWN && currentCoef > MIN_COEF) {
            currentCoef -= STEP;
            initValue = currentCoef <= MIN_COEF ? '1.01' : String(currentCoef.toFixed(THREE));
            this.controller.onChangeCoef1(String(initValue));
        }
    };

    handleChangeCoef1 = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.controller.onChangeCoef1(e.target.value);
    };

    onClickChangeCoef = (plus = true): void => {
        const {coef_1} = this.controller.incubatorGame.attributes;

        if (coef_1) {
            const num_coef1 = Number(coef_1);
            const res = plus ? num_coef1 + STEP_ARROW : num_coef1 - STEP_ARROW;
            this.controller.onChangeCoef1(res.toFixed(SCALE));
        }
    };

    onClickUpArrow = (): void => {
        this.onClickChangeCoef();
    };

    onClickDownArrow = (): void => {
        this.onClickChangeCoef(false);
    };

    render(): ReactNode {
        const {controller} = this.props;
        const {bets, incubatorGame, profitClass} = controller;
        const {coef_1, gamer_1, coef1_win, user_bets_sum_1} = incubatorGame.attributes;
        const show_coef_1 = coef_1 ?? '';

        return <div className="part left">
            <div className="line-top">
                <div className="line-team">
                    <span className="name">{gamer_1.nickname}</span>
                    {isDesktop() && flagImage(gamer_1.country_code, 'line-team-flag')}
                </div>
                <div className="line-about-sum">
                    {!isDesktop() && flagImage(gamer_1.country_code, 'line-team-flag')}
                    <span className="line-sum">{betMoney({amount: user_bets_sum_1})}</span>
                </div>
            </div>
            <div className="line-fields">
                {isDesktop() && <IncubatorLineLeftWinner/>}
                <div className="input-line w110 incubator-profit-line">
                    <label className="field__label">
                        {I18n.t('tooltip_incubator_prize')}
                    </label>
                    <input
                        className={`field__input bold field-number__input ${profitClass(coef1_win)}`}
                        type="number"
                        step="0.001"
                        min="0"
                        disabled
                        value={fixed2(coef1_win)}
                    />
                </div>
                <div className="input-line w57">
                    <input
                        className="field__input bold field-number__input"
                        type="text"
                        min="0"
                        step="0.001"
                        value={show_coef_1}
                        name="line[coef_1]"
                        id="line[coef_1]"
                        onKeyDown={changeCoefByStep}
                        onKeyUp={this.handleInputChange}
                        onChange={this.handleChangeCoef1}
                    />
                    {!isDesktop() && <>
                        <span className="incubator-mobile-button-coef plus" onClick={this.onClickUpArrow}>
                            <svg className="svg-default">
                                <use xlinkHref="#svg-triangle-full"/>
                            </svg>
                        </span>
                        <span className="incubator-mobile-button-coef minus" onClick={this.onClickDownArrow}>
                            <svg className="svg-default">
                                <use xlinkHref="#svg-triangle-full"/>
                            </svg>
                        </span>
                    </>}
                </div>
            </div>
            <div className="incubator-line-table">
                <IncubatorUsersBets
                    positionTable={GAMER_1}
                    bets={bets ? bets.gamer1Bets() : []}
                />
            </div>
        </div>;
    }
}
