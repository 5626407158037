import React, {ReactElement} from 'react';

const EmptyBgItemDeposit = (): ReactElement => <div className="card-deposit">
    <div className="inner">
        <span className="name"/>
        <span className="total"/>
    </div>
    <div className="button">
        <span className="button-adaptive"/>
    </div>
</div>;

export default EmptyBgItemDeposit;
