import $ from 'jquery';
import React from 'react';
import {getLinkPath} from 'app/utils';
import {hideLastPopup} from 'app/utils/modals/ModalsService';
import {PopupDialog} from 'app/utils/modals/PopupDialog';

export default class MessageNotification extends React.PureComponent {
    componentDidMount() {
        $.ajax('/user/message_notification', {
            type: 'POST',
            dataType: 'JSON'
        });
    }

    close(e) {
        hideLastPopup();
        e && e.preventDefault();
    }

    render() {
        return <PopupDialog id={this.props.popupId} setFocus>
            <div className="popup__content">
                <p>
                    We’ve published revised version of our
                    <a className="cursor-pointer" href={getLinkPath('/privacy_policy')} target="_blank" rel="noreferrer">Privacy Policy</a>.
                    We encourage you to read it. Your use of EGB websites and services will be subject to this updated
                    policy from May 25, 2018.
                </p>
                <form className="form-registration popup-form--error">
                    <div className="popup-form__submit-row">
                        <input type="submit" className="button btn btn--large" name="commit" value="Got it" onClick={this.close}/>
                    </div>
                </form>
            </div>
        </PopupDialog>;
    }
}
