import React from 'react';
import FormProgressBar from 'app/components/bet_form/progress_bar';
import {GIFT_BOX} from 'app/consts';
import I18n from 'app/i18n';
export default class GiftProgressBar extends FormProgressBar {
    UNSAFE_componentWillMount() {
        const img = new Image();
        img.onload = () => this.setState({gixBoxImage: img.src});
        img.src = GIFT_BOX;

        setTimeout(() => this.props.afterFinish && this.props.afterFinish(), this.props.delayTime);
    }

    render() {
        return <div className="popup__content gift-box">
            <div className="gift-box__gif">
                <img
                    src={this.state.gixBoxImage}
                    alt={I18n.t('user.promo_action.gift_opening_progress')}
                    title={I18n.t('user.promo_action.gift_opening_progress')}
                />
            </div>
        </div>;
    }
}
