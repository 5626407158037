import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import SocialLink from 'app/components/pages/referrals/SocialLink';

export default class JoinUs extends PureComponent {
    static SOCIALS = [
        {
            href: 'https://www.youtube.com/channel/UCTBJL9fT1-wS7CkLOgf83MQ',
            text: 'Youtube',
            xlinkHref: '#svg-youtube2'
        },
        {
            href: 'https://facebook.com/EgamingBets',
            text: 'Facebook',
            xlinkHref: '#svg-facebook-square'
        },
        {
            href: 'https://twitter.com/egamingbets',
            text: 'Twitter',
            xlinkHref: '#svg-twitter2'
        }
    ];

    render() {
        return <div className="join-us">
            <div className="join-us__title">{I18n.t('referrals.join_us__title')}</div>
            <div className="join-us__text">
                {I18n.t('referrals.join_us__text')} <span className="color-orange">#EGB</span>
            </div>
            <div className="join-us__social-wrap">
                <div className="socials">
                    <div className="socials__list">
                        {this.constructor.SOCIALS.map(social => <SocialLink key={social.href} {...social}/>)}
                    </div>
                </div>
            </div>
        </div>;
    }
}
