import EventProperties from 'app/amplitude/event_properties';
import head from 'lodash/head';

export default class SignUpEventProperties extends EventProperties {
    extractProperties() {
        if (!this.errorsKeysLength) {
            return null;
        }

        return {
            SOURCE: this.response.SOURCE,
            notPassed: this.errorsKeysLength > 1 && 'any' || head(this.errorsKeys),
            statusCode: this.responseJSON.status
        };
    }
}
