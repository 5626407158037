import $ from 'jquery';
import React from 'react';

export default class FadeWrapper extends React.PureComponent {
    constructor(props) {
        super(props);
        this.rootElement = React.createRef();
    }

    componentDidMount() {
        $(this.rootElement.current).fadeIn();
    }

    render() {
        return <div ref={this.rootElement} {...this.props}>
            {this.props.children}
        </div>;
    }
}
