import React, {Component} from 'react';
import {modalsStore} from 'app/store/global';
import {PopupDialog} from './PopupDialog';
import I18n from 'app/i18n';

interface ModalAlertInterface {
    okClick?(): void
    popupId: string
    isReactElement: boolean
    children?: React.ReactNode
    okText: string
}

export class ModalAlert extends Component<ModalAlertInterface> {
    okBtnRef: React.RefObject<HTMLButtonElement> = React.createRef();

    componentDidMount(): void {
        this.okBtnRef.current.focus();
    }

    okClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        modalsStore.hideModal(this.props.popupId);
        this.props.okClick && this.props.okClick();
    };

    render(): React.JSX.Element {
        return <PopupDialog id={this.props.popupId}>
            <div className="popup__content popup__content--small">
                <div className="modal-popup-content">
                    {this.props.isReactElement
                        ? <div className="modal-popup-content__text">
                            {this.props.children}
                        </div>
                        : <div
                            className="modal-popup-content__text"
                            dangerouslySetInnerHTML={{__html: this.props.children}}
                        />
                    }
                    <div className="modal-popup-content__submit-row_alert">
                        <button
                            ref={this.okBtnRef}
                            onClick={this.okClick}
                            className="btn"
                        >
                            {this.props.okText || I18n.t('ok')}
                        </button>
                    </div>
                </div>
            </div>
        </PopupDialog>;
    }
}
