import React, {PureComponent} from 'react';
import Slider from 'react-slick';
import EquippedDescription from './equipped_description';

export default class DescriptionGroup extends PureComponent {
    colorClass = () => {
        const {type, multi} = this.props;

        if (!multi) {
            return '';
        }

        switch (type) {
        case 'armor':
        case 'cloak':
            return ' hero__inventory--group--yellow';
        default:
            return '';
        }
    };

    render() {
        const {artifact, type} = this.props;
        return <div
            className={`hero__inventory--group hero__inventory--${type}${
                this.colorClass()}${artifact.length === 0 ? '' : ' hero__inventory--selected'}`}>
            <Slider slidesToShow={1} arrows={false} dots={true} infinite={false}>
                {artifact.map(a => <EquippedDescription key={a.id} artifact={a} type={type}/>)}
            </Slider>
        </div>;
    }
}
