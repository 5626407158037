import React, {Component, ReactNode, RefObject} from 'react';
import I18n from 'app/i18n';
import {ShowPasswordEye} from 'app/components/auth/ShowPasswordEye';

interface RowPasswordProps {
    rowClass: string;
    errorHint: ReactNode;
    cleanError: (field: string) => void;
    onChangeHandler: () => void;
    showPassword: boolean;
    reference: RefObject<HTMLInputElement>;
}

interface RowPasswordState {
    passwordType: string,
}

export class RowPassword extends Component<RowPasswordProps, RowPasswordState> {
    constructor(props: RowPasswordProps) {
        super(props);
        this.state = {
            passwordType: 'password'
        };
    }

    showPasswordHandler = (): void => {
        this.setState({passwordType: this.typePassword() ? 'text' : 'password'});
    };

    typePassword(): boolean {
        return this.state.passwordType === 'password';
    }

    showPasswordTitle = (): string => I18n.t(`auth.${this.typePassword() ? 'show' : 'hide'}_password_label`);

    cleanError = (): void => {
        this.props.cleanError('password');
    };

    render = (): ReactNode => {
        const {rowClass, errorHint, onChangeHandler, showPassword, reference} = this.props;

        return <div className={rowClass}>
            <label className="field-label" htmlFor="field-password">{I18n.t('password')}</label>
            <div className="field field__input-group">
                <input
                    ref={reference}
                    className="field__input"
                    id="field-password"
                    name="password"
                    type={this.state.passwordType}
                    onKeyUp={this.cleanError}
                    onChange={onChangeHandler}
                />
                {showPassword
                    ? <ShowPasswordEye title={this.showPasswordTitle()}
                        onClickHandler={this.showPasswordHandler}
                        eyeSign={this.typePassword() ? '#eye' : '#eye_crossed'}/>
                    : null}
            </div>
            {errorHint}
        </div>;
    };
}
