import {action, makeObservable, observable} from 'mobx';

export class ScreenStore {
    @observable isDesktop = false;

    constructor() {
        makeObservable(this);
    }

    @action setDesktop(isDesktop: boolean): void {
        this.isDesktop = isDesktop;
    }
}
