import React, {PureComponent, ReactNode} from 'react';
import DepositWallet from 'app/components/deposit/form/DepositWallet';
import I18n from 'app/i18n';
import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';
import WalletInterface from 'app/interfaces/WalletInterface';

const MAX_WALLETS_COUNT_FOR_NEW_WALLET = 9;

interface WalletsProps {
    accountLabel: string
    wallets: WalletInterface[]
    selectWallet: (w?: {id: number}) => void
    selectedSystem: PaymentSystemInterface
    deactivateWallet: (id: number) => void
}

export default class Wallets extends PureComponent<WalletsProps> {
    render(): ReactNode {
        const {wallets, selectWallet, selectedSystem, accountLabel} = this.props;
        return <div className="field field__filter">
            <div className="field__wrap">
                <div
                    id="cashout__wallets"
                    className="cashout__wallets column-full filters__list"
                >
                    {wallets.map((w, i) => <DepositWallet
                        key={w.id}
                        selectedSystem={selectedSystem}
                        wallet={w}
                        defaultChecked={i === 0}
                        deactivateWallet={this.props.deactivateWallet}
                        onClick={selectWallet.bind(null, w)}
                    />)}
                    {selectedSystem.input_wallet &&
                    selectedSystem.multiple_wallets &&
                    wallets.length <= MAX_WALLETS_COUNT_FOR_NEW_WALLET &&
                    <DepositWallet
                        key="w0"
                        wallet={{masked_account:
                                `${I18n.t('new')} ${accountLabel.toLowerCase()}`} as WalletInterface}
                        defaultChecked={false}
                        onClick={selectWallet.bind(null, null)}
                    />}
                </div>
            </div>
        </div>;
    }
}
