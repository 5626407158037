import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import InputError from 'app/components/deposit/InputError';

export default class FieldWrapInput extends PureComponent {
    handleOnChange = e => this.props.handleOnChange(this.props.stateName, e);

    render() {
        const {errors, stateName, value, disabled, detailsAttribute} = this.props;
        const {[`${detailsAttribute || 'cashout_detail'}.${stateName}`]: error} = errors;
        return <div className="form__row">
            <div className="form__row-item">
                <label className="form__row-label" htmlFor={stateName}>{I18n.t(stateName)}</label>
                <div className={`field ${error ? 'field-error' : ''}`}>
                    <div className="field__wrap">
                        <input
                            name={stateName}
                            type="text"
                            className="field__input field__input_text"
                            onChange={this.handleOnChange}
                            value={value}
                            disabled={disabled}
                        />
                        {error && <InputError error={error}/>}
                    </div>
                </div>
            </div>
        </div>;
    }
}
