import LineInterface from 'app/interfaces/LineInterface';

export const lineLimit = (line: LineInterface, play_money: boolean, gamerNum: number): string => {
    if (!line) {
        return null;
    }

    const money = play_money ? 'play' : 'real';
    return line[`limit_${money}_money_gamer_${gamerNum}`];
};

