import React, {PureComponent} from 'react';

export default class AmountInput extends PureComponent {
    render() {
        const {helpTxt, value, stateName, onChangeHandler, label, min, placeholder, disabled} = this.props;
        return <div className="sell-inventory__popup--form__item">
            <div className="sell-inventory__popup--form__item-wrap">
                <label
                    htmlFor={stateName}
                    className="label-fee"
                >
                    <span>{label}</span>
                    {helpTxt && <span className="help-txt">({helpTxt})</span>}
                </label>
                <div className="field">
                    <div className="field__wrap">
                        <input
                            id={stateName}
                            type="number"
                            step="0.01"
                            min={min}
                            placeholder={placeholder || 0}
                            name={stateName}
                            value={value}
                            onChange={onChangeHandler}
                            disabled={disabled}
                            className="field__input field-number__input"
                        />
                    </div>
                </div>
            </div>
            <div className="field__error-hint"/>
        </div>;
    }
}
