
const getIdertifierImage = (identifier: string): string => identifier.split(':').pop();

export const size380x380 = (provider: string, identifier: string): string => {
    const identifierImage = getIdertifierImage(identifier);
    return `https://cdn.softswiss.net/i/s3/${provider}/${identifierImage}.png`;
};

export const size190x190 = (provider: string, identifier: string): string => {
    const identifierImage = getIdertifierImage(identifier);
    return `https://cdn.softswiss.net/i/s3/${provider}/${identifierImage}.png`;
};

export const size1600x900 = (provider: string, identifier: string): string => {
    const identifierImage = getIdertifierImage(identifier);
    return `https://cdn.softswiss.net/b/${provider}/${identifierImage}.jpg`;
};
