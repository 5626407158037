import {promoActionStore, userStore} from 'app/store/global';
import React, {PureComponent} from 'react';
import {promoUrl, saveTradeLinkHandler, slowConnectionAndError} from 'app/utils';
import {modalAlert} from 'app/utils/modals/popups';
import I18n from 'app/i18n';
import {Link} from 'react-router-dom';

export default class TradeLink extends PureComponent {
    state = {trade_link: userStore.user.trade_link};

    changeTradeLinkHandler = e => this.setState({trade_link: e.target.value});

    isTradeLinkAvailable = () => this.props.inTop && Object.keys(promoActionStore.compendium()).length === 0;

    submitTradeLinkHandler = () => slowConnectionAndError(saveTradeLinkHandler(this.state.trade_link, {
        success: response => {
            userStore.update({user: response});
            modalAlert(I18n.t('trade_link_has_been_updated'));
        }
    }));

    render() {
        const {trade_link} = this.state;

        if (!this.isTradeLinkAvailable()) {
            return null;
        }

        return <div className="compendium__leaders-bar">
            <div className="compendium-leaders-bar">
                <div className="compendium-leaders-bar__row">
                    <div className="compendium-leaders-bar__trade_link_column">
                        <div className="popup-form__row  with__confirmation_button">
                            <div className="field trade-link-field">
                                <div className="field__wrap">
                                    <input className="field__input field__input--exist-state"
                                        type="text"
                                        placeholder={I18n.t('trade_link')}
                                        value={trade_link}
                                        onChange={this.changeTradeLinkHandler}/>
                                    <span className="field__state">
                                        <Link to={promoUrl('redeem_prize')}>
                                            <svg height="20px" width="20px">
                                                <use xlinkHref="#svg-question"/>
                                            </svg>
                                        </Link>
                                    </span>
                                </div>
                                <button
                                    className="btn mg-l-15"
                                    onClick={this.submitTradeLinkHandler}
                                >
                                    {I18n.t('user.send')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }
}
