import WarningToast from 'app/components/toasts/WarningToast';
import React, {Attributes, Component} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import SuccessToast from './SuccessToast';
import ErrorToast from './ErrorToast';
import InfoToast from './InfoToast';
import {inject, observer} from 'mobx-react';
import NotificationStore from 'app/store/notifications';
import {notificationSettingsStore} from 'app/store/global';
import {componentByType, components} from './Components';
import {doShowInformer} from 'app/utils';
import NotificationsSettingsStoreInterface from 'app/interfaces/stores/NotificationsSettingsStoreInterface';

interface ToastListInterface {
    NotificationStore?: typeof NotificationStore,
    notificationSettingsStore?: NotificationsSettingsStoreInterface
}

export default inject('NotificationStore', 'notificationSettingsStore')(observer(
    class ToastList extends Component<ToastListInterface, NotificationsSettingsStoreInterface> {
        success = (content, data, sound) => <SuccessToast key={data.key} content={content} data={data} sound={sound}/>;

        error = (content, data, sound) => <ErrorToast key={data.key} content={content} data={data} sound={sound}/>;

        info = (content, data, sound) => <InfoToast key={data.key} content={content} data={data} sound={sound}/>;

        warning = (content, data, sound) => <WarningToast key={data.key} content={content} data={data} sound={sound}/>;

        processingToast = data => {
            if (data.group && !notificationSettingsStore.notificationSettings[data.group]) {
                NotificationStore.removeActiveKeys(data.key);
                return null;
            }

            const component = componentByType(data.type);

            if (component) {
                return React.createElement(component.content, {
                    data,
                    key: data.key,
                    sound: component.sound
                } as Attributes);
            }

            const componentsData = components(data);

            if (!componentsData.content || data.filtered && !doShowInformer(data.filter)) {
                return null;
            }

            switch (componentsData.status) {
            case 'success':
                return this.success(componentsData.content, data, componentsData.sound);
            case 'error':
                return this.error(componentsData.content, data, componentsData.sound);
            case 'warning':
                return this.warning(componentsData.content, data, componentsData.sound);
            default:
                return this.info(componentsData.content, data, componentsData.sound);
            }
        };

        render() {
            return (
                <div>
                    {this.props.NotificationStore.activeNotify.map(data => this.processingToast(data))}
                </div>
            );
        }
    }
));

