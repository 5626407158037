export const playPageType = (): string => {
    const {pathname} = window.location;

    switch (true) {
    case pathname.includes('/play/bets'):
        return 'bets';
    case pathname.includes('/play/results'):
        return 'results';
    case pathname.includes('/play/incubator'):
        return 'incubator';
    default:
        return 'bets';
    }
};
