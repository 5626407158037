import {ComponentType} from 'react';
import PiastrixDeposit from './piastrix';
import GiftCardDeposit from './GiftCardDeposit';
import ObmenkaDeposit from './obmenka';
import SkinsbackDeposit from './skinsback';
import P2pDeposit from './p2p/P2pDeposit';
import DepositInstantRedirectSystem from './redirect/DepositInstantRedirectSystem';
import DepositRedirectSystem from './redirect/DepositRedirectSystem';
import PayzenBase from './payzen/PayzenBase';
import OnlineBankingDeposit from './payzen/online_banking';
import VietnamOnlineBankingDeposit from './payzen/vietnam/online_banking';
import CoinspaidDeposit from './coinspaid/CoinspaidDeposit';
import {DepositPageProps} from './DepositPage';

const IMPLEMENTED_DEPOSITS = {
    CoinspaidDeposit,
    DepositInstantRedirectSystem,
    DepositRedirectSystem,
    GiftCardDeposit,
    ObmenkaDeposit,
    OnlineBankingDeposit,
    P2pDeposit,
    PayzenBase,
    PiastrixDeposit,
    SkinsbackDeposit,
    VietnamOnlineBankingDeposit
};

export const getDepositComponent = (name: string): ComponentType<DepositPageProps> => IMPLEMENTED_DEPOSITS[name];
