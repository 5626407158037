import {inject, observer} from 'mobx-react';
import moment from 'moment';
import {Component} from 'react';
import {CurrentTimeStore} from 'stores/current_time_store';

interface Props {
    endTime: number
    format?: (m: moment.Moment, endTime: number, currentTime: number) => string
    currentTimeStore?: CurrentTimeStore
    onlyMinutes?: boolean
}

const LIMIT_TO_HOURS = 10;

@inject('currentTimeStore')
@observer
export default class Timer extends Component<Props> {
    format(timeDiff: moment.Moment): string {
        const {format, currentTimeStore, endTime, onlyMinutes} = this.props;

        if (format) {
            return format(timeDiff, endTime, currentTimeStore.t);
        }

        const minutes = timeDiff.format('mm:ss');

        if (onlyMinutes) {
            return minutes;
        }

        const hours = Number(moment.unix(endTime).diff(moment.unix(currentTimeStore.t), 'hours'));
        const strHours = hours < LIMIT_TO_HOURS ? `0${hours}` : hours;
        return `${strHours}:${minutes}`;
    }

    render(): string {
        const {endTime, currentTimeStore} = this.props;
        const timeDiff = endTime - currentTimeStore.t;

        if (timeDiff <= 0) {
            return null;
        }

        return this.format(moment.unix(timeDiff));
    }
}
