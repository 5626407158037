import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {toastSuccess} from 'app/components/toasts/liteToast';
import {CopyToClipboard} from 'react-copy-to-clipboard';

interface BannerButtonBlockProps {
    link: string
    referrals_count: number
    paid_amount: string
}

export default class BannerButtonBlock extends Component<BannerButtonBlockProps> {
    onClickCopyMessageCall = (): void => {
        toastSuccess(I18n.t('referral_copy_notification'));
    };

    render(): ReactNode {
        const {link, referrals_count, paid_amount} = this.props;

        return <div className="banner-button-block invite-friends">
            <CopyToClipboard text={link} onCopy={this.onClickCopyMessageCall}>
                <button className="button-adaptive button-adaptive_big">{I18n.t('referral_share_more')}</button>
            </CopyToClipboard>
            <div className="banner-items">
                <div className="item">
                    <span className="title-name">{I18n.t('referral_banner_title_1')}</span>
                    <div className="body">
                        <span className="number">{referrals_count}</span>
                        <span className="icon-add-friends">
                            <svg className="svg-default">
                                <use xlinkHref="#svg-promotion-plus"/>
                            </svg>
                        </span>
                    </div>
                </div>
                <div className="item">
                    <span className="title-name">{I18n.t('referral_banner_title_2')}</span>
                    <div className="body">
                        <span className="number">{paid_amount}</span>
                    </div>
                </div>
            </div>
        </div>;
    }
}
