import React, {PureComponent, ReactNode} from 'react';
import VolumeChanger from 'app/components/volume_changer';

class VolumeControl extends PureComponent {
    render(): ReactNode {
        return <div className="header__control">
            <VolumeChanger/>
        </div>;
    }
}

export default VolumeControl;
