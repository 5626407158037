import React, {Fragment, PureComponent} from 'react';
import {artifactStore, userStore} from 'app/store/global';
import {OPEN_LOOTBOX_UPDATE_TIMEOUT} from 'app/store/ArtifactStore';
import {modalConfirm} from 'app/utils/modals/popups';
import {showPopup} from 'app/utils/modals/ModalsService';
import I18n from 'app/i18n';
import SendArtifactPopup from './send_artifact_popup';
import SellArtifactPopup from './sell_artifact_popup';
import {localDateTimeFromISO} from 'app/utils';
import {goToRoute} from 'app/utils/link_helper';
import InventoryItemDescription from 'app/components/my_profile/artifacts/popup/inventory_item_description';
import Smiles from 'app/components/my_profile/artifacts/popup/smiles';
import ActionButton from './action_button';
import {artifactShopLink} from 'app/components/my_profile/artifacts/utils';
import Notice from './popup/notice';
import {firstElementResponse} from 'app/utils/lootbox_open/first_element_response';
import {SimpleArtifactImage} from 'app/components/my_profile/artifacts/image';
import HeroSlotCount from 'app/utils/hero/hero_slot_count';
import SmartLoader from 'app/components/reusable/SmartLoader';

export default class InventoryPopupContent extends PureComponent {
    state = {loading: false};

    componentWillUnmount() {
        const {commonProperties: {onCloseInventoryPopup}} = this.props;

        if (!this.isArtifact) {
            onCloseInventoryPopup();
        }
    }

    get isUseButtonDisabled() {
        const {artifact: {useable}} = this.props;
        return !useable;
    }

    get isSendButtonDisabled() {
        const {restrict_artifact} = userStore.user;
        const {artifact: {sending_prohibited, transferable}} = this.props;
        return sending_prohibited || !transferable || restrict_artifact;
    }

    get isSellButtonDisabled() {
        const {restrict_artifact} = userStore.user;
        const {artifact: {salable}} = this.props;
        return !salable || restrict_artifact;
    }

    get publicView() {
        const {artifact: {public_view}} = this.props;
        return public_view;
    }

    get isArtifact() {
        const {commonProperties: {isArtifact}} = this.props;
        return isArtifact;
    }

    messagePromoCodeActiveAt() {
        const {artifact: {is_promo_code_active, promo_code_active_at}} = this.props;

        if (typeof is_promo_code_active === 'undefined' || is_promo_code_active) {
            return null;
        }
        return <p>
            {I18n.t('user.promo_code_will_be_able_to_use_after',
                {ending_date: localDateTimeFromISO(promo_code_active_at)})}
        </p>;
    }

    useButtonText = () => {
        const {artifact: {equipped}} = this.props;

        if (this.isArtifact) {
            return I18n.t(equipped
                ? 'unequip'
                : 'equip');
        }

        return I18n.t('open');
    };

    use = ({continue_equipping = false}) => {
        if (this.publicView) {
            return;
        }

        this.setState({loading: true});

        const {artifact, commonProperties: {handleAnimationEnded}} = this.props;

        artifactStore
            .use(artifact, continue_equipping)
            .fail(response => {
                modalConfirm(firstElementResponse(response),
                    () => this.use({continue_equipping: true}));
            })
            .done(response => {
                this.updateOuterState({responseJSON: response});
                setTimeout(() => handleAnimationEnded(), OPEN_LOOTBOX_UPDATE_TIMEOUT);

                if (this.isArtifact) {
                    this.props.close();
                }
                this.setState({loading: false});
            })
            .then(() => this.updateOuterState({openingLootbox: true}));
    };

    updateOuterState = state => {
        this.props.propsUpdateState(state);
    };

    send = () => {
        const {artifact} = this.props;
        const {sending_prohibited, public_view} = artifact;

        if (sending_prohibited || public_view) {
            return;
        }

        showPopup('inventory-popup', props => <SendArtifactPopup artifact={artifact} popupId={props.popupId}/>);
    };

    sell = () => {
        const {artifact} = this.props;

        if (this.publicView) {
            return;
        }

        if (artifact.in_market) {
            this.props.close();
            goToRoute(artifactShopLink(artifact.recipe, true), true);
        } else {
            showPopup(
                'sell-inventory',
                props => <SellArtifactPopup artifact={artifact} popupId={props.popupId}/>,
                ['inventory-popup']
            );
        }
    };

    render() {
        const {commonProperties, artifact} = this.props;
        const {openingLootbox, useNow, isArtifact} = commonProperties;
        const {in_market} = artifact;
        const {loading} = this.state;

        return <div className="inventory__popup">
            <div className="inventory__popup--description">
                <div className="inventory__popup--img-wrap">
                    <div className="market-details--info--img">
                        <div className="market-details--info--inner--img">
                            <SimpleArtifactImage artifact={artifact}/>
                            { openingLootbox
                                ? ''
                                : <HeroSlotCount {...artifact}/> }
                            <div
                                className={`market-details--loader ${
                                    openingLootbox && !isArtifact ? 'active' : ''
                                    }`}
                            >
                                <div className="loader-line-box ">
                                    <span id="lootboxLoader" className="loader-line">
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="inventory__popup--desc">
                    <InventoryItemDescription {...commonProperties}>
                        <div className="inventory__popup--desc-title">
                            {I18n.t('you_may_win')}
                        </div>
                    </InventoryItemDescription>
                    {this.messagePromoCodeActiveAt()}
                    <Smiles {...artifact}/>
                </div>
            </div>
            {loading
                ? <SmartLoader loaderClassName="smart-loader_theme-dark_no-margin" />
                : <div className="inventory__popup--actions">
                    {!useNow && <Fragment>
                        <ActionButton
                            {...artifact}
                            disabled={this.isUseButtonDisabled}
                            onClickHandler={this.use}
                            openingLootbox={openingLootbox}
                            btnText={this.useButtonText()}
                        />
                        <ActionButton
                            {...artifact}
                            disabled={this.isSendButtonDisabled}
                            onClickHandler={this.send}
                            openingLootbox={openingLootbox}
                            btnText={I18n.t('send_as_gift')}
                        />
                        <ActionButton
                            {...artifact}
                            disabled={this.isSellButtonDisabled}
                            onClickHandler={this.sell}
                            openingLootbox={openingLootbox}
                            btnText={in_market ? I18n.t('show_now') : I18n.t('sell')}
                        />
                    </Fragment>}
                </div>}
            <Notice {...artifact} openingLootbox={openingLootbox}/>
        </div>;
    }
}
