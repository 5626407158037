import React, {PureComponent, ReactNode} from 'react';
import System from './System';
import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';

interface SystemsProps {
    systems: PaymentSystemInterface[];
    title: string;
    selectSystem(system: PaymentSystemInterface): void
}

export default class Systems extends PureComponent<SystemsProps> {
    render(): ReactNode {
        const {systems, title, selectSystem} = this.props;
        return systems.length > 0 && <div className="payment-systems__list">
            <h3 className="payment-systems__deposit__heading">{title}</h3>
            <div className="payment-systems__deposit__wrap payment-systems__deposit-recommended__wrap">
                {systems.map(system => <System key={system.id} system={system} selectSystem={selectSystem}/>)}
            </div>
        </div>;
    }
}
