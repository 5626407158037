import {TableStore} from 'app/store/TableStore';
import {inject} from 'mobx-react';
import React, {PureComponent, ReactNode} from 'react';
import PlayContentImpl from './index';
import {ResultsFilterStore} from 'app/store/filters/ResultsFilterStore';

interface Filter {
    filter: string;
}

interface ResultsContentProps {
    params: Filter
    urlPrefix: string
    keyPrefix: string
    esports: boolean
    tableStore?: TableStore
    resultsFilterStore?: ResultsFilterStore
}
@inject('tableStore', 'resultsFilterStore')
export default class ResultsContent extends PureComponent<ResultsContentProps> {
    constructor(props: ResultsContentProps) {
        super(props);
        this.props.tableStore.filterStore = props.resultsFilterStore;
    }

    componentDidMount(): void {
        const {tableStore} = this.props;
        tableStore.update({results: true});
        tableStore.startUpdateLinesResults();
    }

    componentWillUnmount(): void {
        this.props.tableStore.update({results: false});
    }

    render(): ReactNode {
        const {params, esports, urlPrefix, keyPrefix, tableStore} = this.props;
        return <PlayContentImpl
            filter={params.filter}
            urlPrefix={urlPrefix}
            keyPrefix={keyPrefix}
            esports={esports}
            tableStore={tableStore}
        />;
    }
}
