import React, {FormEvent, ReactElement} from 'react';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {modalConfirm} from 'app/utils/modals/popups';
import {i18n_t} from 'app/i18n';
import {artifactName} from './utils';
import {SimpleArtifactImage} from './image';
import HeroSlotCount from 'app/utils/hero/hero_slot_count';
import InventoryItemDescription from 'app/components/my_profile/artifacts/popup/inventory_item_description';
import SendArtifactPopup, {
    buttonClassNames,
    inputClassNames
} from 'app/components/my_profile/artifacts/send_artifact_popup';
import {modalsStore, steamTradeStore} from 'app/store/global';

export default class SendSteamSkinPopup extends SendArtifactPopup {
    sendArtifact = (e: FormEvent<HTMLFormElement>): void => {
        const {artifact, popupId} = this.props;
        const {checked_name, user} = this.state;
        const quantity = 1;

        e && e.preventDefault();
        if (!checked_name) {
            return;
        }

        modalConfirm(
            i18n_t('send_artifact_confirm', {
                artifact: artifact.steam_skin.name,
                quantity,
                user
            }),
            () => steamTradeStore.sendAsGift(artifact, user, quantity)
                .then(() => modalsStore.hideModal(popupId))
        );
    };

    render(): ReactElement {
        const {artifact: {steam_skin}, popupId} = this.props;
        const {checked_name, checked_quantity, user} = this.state;
        const sign = checked_name ? '#svg-check' : '#svg-close';

        return <PopupDialog id={popupId} setFocus={true}>
            <div className="popup__content popup--send-gift">
                <h2 className="popup__header">{i18n_t('send_as_gift')}</h2>
                <div className="send-gift__popup">
                    <div className="send-gift__popup--description">
                        <div className="send-gift__popup--img-wrap">
                            <div className="send-gift__popup--img">
                                <SimpleArtifactImage artifact={steam_skin}/>
                                <HeroSlotCount {...steam_skin}/>
                            </div>
                        </div>
                        <div className="send-gift__popup--desc">
                            <div className="send-gift__popup--desc-title">
                                {artifactName(steam_skin)}
                            </div>
                            <InventoryItemDescription {...steam_skin}/>
                        </div>
                    </div>
                    <form
                        className="send-gift__popup--form"
                        onSubmit={this.sendArtifact}
                    >
                        <div className="send-gift__popup--form-content">
                            <div className="send-gift__popup--form__item">
                                <div className="send-gift__popup--form__item-wrap">
                                    <label className="label-fee">
                                        <span>{i18n_t('please_enter_user_nickname')}</span>
                                    </label>
                                    <div className="field">
                                        <div className="field__wrap">
                                            <input
                                                type="text"
                                                name="name"
                                                className={inputClassNames(this.state)}
                                                value={user}
                                                onChange={this.changeUserHandler}
                                            />
                                            <span className="field__state">
                                                <svg height="12px" width="12px">
                                                    <use xlinkHref={sign}/>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="send-gift__popup--form-footer">
                            <div className="send-gift__popup--actions">
                                <button
                                    className={buttonClassNames(this.state)}
                                    disabled={!checked_name || !checked_quantity}
                                >
                                    {i18n_t('send_gift')}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </PopupDialog>;
    }
}
