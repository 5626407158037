import {RatesType} from 'app/store/DepositsStore';
import {getRateByCurrency} from './getRateByCurrency';

const FIRST_LIMIT = 1000;
const SECOND_LIMIT = 10000;
const FIRST_SUM_TO_DIVISION = 10;
const HALF = 2;

export const roundAmount = (amount: number, currency: string, rates: RatesType): number => {
    const rate = getRateByCurrency(currency, rates);
    const newAmount = amount * rate;

    if (newAmount >= FIRST_LIMIT && newAmount < SECOND_LIMIT) {
        return round(newAmount, FIRST_SUM_TO_DIVISION);
    }

    if (newAmount >= SECOND_LIMIT) {
        return round(newAmount, 100);
    }
    return Math.round(newAmount);
};

const round = (amount: number, division: number): number => {
    const remainder = amount % division;
    return remainder > division / HALF ? amount - remainder + division : amount - remainder;
};
