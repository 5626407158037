import {action, makeObservable, observable} from 'mobx';

const ONE_HUNDER_MS = 1000;

export class CurrentTimeStore {
    constructor() {
        makeObservable(this);
        setInterval(() => this.setTime(this.t + 1), ONE_HUNDER_MS);
    }

    @observable t: number = null;

    @action setTime(time: number): void {
        this.t = time;
    }
}
