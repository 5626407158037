import {action, computed, makeObservable, observable} from 'mobx';

export class RecipeStore {
    constructor() {
        makeObservable(this);
    }

    @observable recipes = [];

    @observable paginate = {};

    @observable market_prices = [];

    @action
    updateRecipes({recipes, paginate, market_prices}) {
        if (recipes) {
            this.recipes = recipes;
        }

        if (paginate) {
            this.paginate = paginate;
        }

        if (market_prices) {
            this.market_prices = market_prices;
        }
    }

    @computed
    get onSale() {
        return this.market_prices;
    }
}
