import React, {PureComponent, ReactNode} from 'react';
import {scrollToTop} from 'app/utils';
import SystemCard from './SystemCard';
import {getDepositComponent} from './DepositComponents';
import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';

interface SystemProps {
    system: PaymentSystemInterface,
    selectSystem(system: PaymentSystemInterface): void
}

export default class System extends PureComponent<SystemProps> {
    selectSystem = (): void => {
        const {system, selectSystem} = this.props;
        selectSystem(system);
        scrollToTop();
    };

    render(): ReactNode {
        const {system, system: {deposit_ui_component}} = this.props;

        if (!getDepositComponent(deposit_ui_component)) {
            return null;
        }

        return <div
            onClick={this.selectSystem}
            className="payment-systems__deposit__item payment-systems__deposit-recommended__item"
        >
            <SystemCard system={system}/>
        </div>;
    }
}
