import React, {PureComponent, ReactNode} from 'react';
import BetSlipListInventory from 'app/components/bet_slip/inventory/BetSlipListInventory';
import InventoryItemInterface from 'app/interfaces/InventoryItemInterface';

interface BetSlipInventoryProps {
    artifacts: InventoryItemInterface[]
    onClick: (artifact: InventoryItemInterface) => void
    subTitle: string
}

export default class BetSlipInventory extends PureComponent<BetSlipInventoryProps> {
    render(): ReactNode {
        const {artifacts} = this.props;
        return <div className="bet-slip-inventory">
            <span className="sub-title">{this.props.subTitle}</span>
            {artifacts.length > 0
                ? <BetSlipListInventory artifacts={artifacts} onClick={this.props.onClick}/>
                : <div className="list-inventories">
                    <span className="empty-object-inventory"/>
                </div>}
        </div>;
    }
}
