import React, {ReactNode, PureComponent} from 'react';

interface CryptoInformationLogoProps {
    srcLogo: string
    altLogo: string
}

export default class CryptoInformationLogo extends PureComponent<CryptoInformationLogoProps> {
    render(): ReactNode {
        return <span className="crypto-logotype">
            <img alt={this.props.altLogo} src={this.props.srcLogo}/>
        </span>;
    }
}
