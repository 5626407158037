import React, {Fragment, PureComponent, ReactNode} from 'react';
import CustomEWallet from 'app/components/cashout/custom_ewallet';
import InputError from 'app/components/deposit/InputError';
import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';
import ErrorsServerInterface from 'app/interfaces/ErrorsServerInterface';

interface InputWalletProps {
    accountLabel: string
    selectedSystem: PaymentSystemInterface
    errors: ErrorsServerInterface
    accountValue: string
    updateWalletAccount: (account: string) => void
}

export default class InputWallet extends PureComponent<InputWalletProps> {
    render(): ReactNode {
        const {accountValue, errors, selectedSystem, accountLabel, updateWalletAccount} = this.props;
        return <Fragment>
            <label className="form__row-label" htmlFor="wallet.account">{accountLabel}</label>
            <div className={`field ${errors['wallet.account'] ? 'field-error' : ''}`}>
                <div className="field__wrap">
                    <CustomEWallet
                        selectedSystem={selectedSystem}
                        value={accountValue}
                        onChange={updateWalletAccount}
                    />
                    {errors['wallet.account'] && <InputError error={errors['wallet.account']}/>}
                </div>
            </div>
        </Fragment>;
    }
}
