import React, {ChangeEvent, PureComponent} from 'react';
import FieldWrapInput from 'app/components/cashout/FieldWrapInput';
import CollectionInterface from 'app/interfaces/CollectionInterface';
import {selectPickerCollection} from 'app/components/reusable/SelectCollection';
import SelectPicker from 'app/components/reusable/SelectPicker';
import I18n from 'app/i18n';
import WalletInterface from 'app/interfaces/WalletInterface';
import WalletDetailInterface from 'app/interfaces/WalletDetailInterface';
import {ELEMENT_NOT_FOUND} from 'app/utils/Consts';
import {AddressesCurrenciesType, loadAddresses} from 'app/components/deposit/coinspaid/CoinspaidUtils';
import {cashoutsStore} from 'app/store/global';
import {modalConfirm} from 'app/utils/modals/popups';
import {currencyName} from 'app/utils/deposits/coinspaid/currencyName';

interface PropsInterface {
    disabled: boolean
    handleWalletDetails: (state: {[key: string]: string | boolean | number}, details: string) => void
    errors: {[key: string]: string | boolean | number}
    selectedWallet: WalletInterface
}

const DEFAULT_CURRENCY = 'BTC';

export default class CoinspaidCashoutDetails extends PureComponent<PropsInterface> {
    state = {
        currencies: [],
        currency: DEFAULT_CURRENCY,
        tag: ''
    };

    componentDidMount = (): void => {
        const {selectedWallet} = this.props;

        if (selectedWallet && selectedWallet.wallet_detail) {
            this.setWalletDetail(selectedWallet.wallet_detail);
        }

        this.loadAddresses();
    };

    componentDidUpdate(prevProps: PropsInterface): void {
        const {selectedWallet} = this.props;

        if (selectedWallet !== prevProps.selectedWallet) {
            if (selectedWallet && selectedWallet.wallet_detail) {
                this.setWalletDetail(selectedWallet.wallet_detail);
            } else {
                this.setState({currency: DEFAULT_CURRENCY, tag: ''}, this.stateChangeCallback);
            }
        }
    }

    setWalletDetail = (walletDetail: WalletDetailInterface): void => {
        this.setState({currency: walletDetail.currency ? walletDetail.currency : DEFAULT_CURRENCY,
            tag: walletDetail.tag ? walletDetail.tag : ''}, this.stateChangeCallback);
    };

    loadAddresses = (): void => {
        loadAddresses((res: AddressesCurrenciesType) => {
            this.setState({
                ...res
            }, this.stateChangeCallback);
        });
    };

    availableCurrencies = (): CollectionInterface[] => selectPickerCollection(
        this.state.currencies.map(c => [currencyName(c.currency, c.network), c.currency])
    );

    changeCurrency = (e: ChangeEvent<HTMLSelectElement>): void => {
        const selectedValue = e.target.value;

        if (this.props.selectedWallet &&
            cashoutsStore.data.wallet_attributes.wallet_detail_attributes.currency !== e.target.value) {
            modalConfirm(
                I18n.t('coinspaid_change_currency'),
                () => cashoutsStore
                    .changeCurrencyToCryptoWallet(this.props.selectedWallet.id, selectedValue)
            );
        } else {
            this.setState({currency: e.target.value}, this.stateChangeCallback);
        }
    };

    stateChangeCallback = (): void => {
        const {handleWalletDetails} = this.props;
        const {currency, tag} = this.state;
        handleWalletDetails({currency, tag}, 'wallet_detail_attributes');
    };

    handleOnChange = (stateName: string, e: React.ChangeEvent<HTMLSelectElement>): void => this.setState(
        {[stateName]: e.target.value},
        this.stateChangeCallback
    );

    isHasTag = (currency: string): boolean => {
        const currencies = ['XRP', 'BTS'];
        return currencies.indexOf(currency) > ELEMENT_NOT_FOUND;
    };

    render(): React.ReactNode {
        const {errors, selectedWallet} = this.props;
        const disabled = Boolean(selectedWallet);
        const {currency} = this.state;

        return <div className="cashout__field field">
            <SelectPicker
                onChange={this.changeCurrency}
                value={currency}
                collection={this.availableCurrencies()}
                placeholder={I18n.t('choose_currency')}
                className="select-bank"
            />
            {this.isHasTag(currency) && <FieldWrapInput
                stateName="tag"
                handleOnChange={this.handleOnChange}
                detailsAttribute="wallet_attributes[wallet_detail_attributes]"
                value={this.state.tag}
                disabled={disabled}
                errors={errors}
            />}
        </div>;
    }
}
