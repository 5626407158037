import LineInterface from 'app/interfaces/LineInterface';

export type ActiveCoef = (id: number) => {
    on: number
};

export const createSerialRendering = (line: LineInterface, activeCoef?: ActiveCoef): string => JSON.stringify(
    createSerialObj(line, activeCoef)
);

const createLineSerialObj = (line: LineInterface, activeCoef: ActiveCoef) => {
    const coef = activeCoef ? activeCoef(line.id) : null;
    return {
        c: line.color,
        g: coef && coef.on,
        ut: line.ut
    };
};

const createSerialObj = (line: LineInterface, activeCoef: ActiveCoef) => {
    let serial = {};

    const mergeSerial = nestedLine => {
        serial[nestedLine.id] = createLineSerialObj(nestedLine, activeCoef);
        serial = {
            ...serial,
            ...createSerialObj(nestedLine, activeCoef)
        };
    };

    line.nested_bets && Object.keys(line.nested_bets).forEach(key => mergeSerial(line.nested_bets[key]));

    return serial;
};
