import ImageInterface from 'app/interfaces/ImageInterface';
import React, {PureComponent, ReactNode} from 'react';

interface CompendiumIndexStyleProps {
    main_image: ImageInterface
    main_mobile_image: ImageInterface
    background_image: ImageInterface
    background_mobile_image: ImageInterface
    text_color: string
    second_text_color: string
    prizes_text_color: string
}

export default class CompendiumIndexStyle extends PureComponent<CompendiumIndexStyleProps> {
    render(): ReactNode {
        const {
            main_image,
            main_mobile_image,
            background_image,
            background_mobile_image,
            text_color = '#74de97',
            second_text_color = '#fff',
            prizes_text_color = '#c5c5c5'
        } = this.props || {};
        const promoClassName = 'color_compendium_251021';
        return <style>
            {`.compendium_index {
                    background-image: url(${background_image.url});
                }
                
                .compendium_index .promo-battle {
                    background-image: url(${main_image.url});
                }
                
                @media only screen and (max-width: 480px) {
                    .compendium_index {
                        background-image: url(${background_mobile_image.url});
                    }
                    
                    .compendium_index .promo-battle {
                        background-image: url(${main_mobile_image.url});
                    }
                }
                
                .${promoClassName} .promo-battle-title h1 {
                    color: ${second_text_color};
                }
                .${promoClassName} .answers-received {
                    color: ${text_color};
                }
                .${promoClassName} .text-title {
                    color: ${second_text_color};
                }
        
                .${promoClassName} .text-description p {
                    color: ${text_color};
                }
                .${promoClassName} .promo-points-title {
                    color: ${second_text_color};
                }
                .${promoClassName} .points-list .count {
                    color: ${second_text_color};
                }
            
                .${promoClassName} .points-list .count-name {
                    color: ${second_text_color};
                }
            
                .${promoClassName} .points-list .for {
                    color: ${text_color};
                }
                .${promoClassName} .points-list .amount {
                    color: ${text_color};
                }
                .${promoClassName} .prize-pool .prize-title h2 {
                    color: ${second_text_color};
                }
                .${promoClassName} .prize-section_first .prize-item-title,
                .${promoClassName} .prize-section_second .prize-item-title,
                .${promoClassName} .prize-section_third .prize-item-title,
                .${promoClassName} .prize-section_fourth .prize-item-title {
                    color: ${text_color};
                }
                .${promoClassName} .prize-section_first .prize-place-number,
                .${promoClassName} .prize-section_second .prize-place-number,
                .${promoClassName} .prize-section_third .prize-place-number,
                .${promoClassName} .prize-section_fourth .prize-place-number {
                    color: ${text_color};
                }
                .${promoClassName} .prize-section_first .main-prize,
                .${promoClassName} .prize-section_second .main-prize,
                .${promoClassName} .prize-section_third .main-prize,
                .${promoClassName} .prize-section_fourth .main-prize {
                    color: ${text_color};
                }
                .${promoClassName} .prize-section_first .additional-prize-title,
                .${promoClassName} .prize-section_second .additional-prize-title,
                .${promoClassName} .prize-section_third .additional-prize-title,
                .${promoClassName} .prize-section_fourth .additional-prize-title {
                    color: ${prizes_text_color};
                }`}
        </style>;
    }
}
