import React, {PureComponent, ReactNode} from 'react';
import {checkElementCookies, isOpenedContainer, setElementCookies} from 'app/utils';
import doubleArrDownSvg from 'images/svg_icons/double-arr-down.svg';
import SliderContainer from 'app/components/slider_container';

interface PropsInterface {
    componentName: string,
    cookieName: string,
    title: string | ReactNode | ReactNode[]
    children?: React.ReactNode
}

export default class AsideHeader extends PureComponent<PropsInterface> {
    container = React.createRef();

    state = {opened: checkElementCookies(this.props.cookieName)};

    toggleHeaderHandler = (): void => {
        const opened = !this.state.opened;
        this.setState({opened});
        setElementCookies(this.props.cookieName, opened);
    };

    render(): ReactNode {
        const {componentName, title, children} = this.props;
        const {opened} = this.state;

        return <div className={`aside-item ${componentName}-aside`}>
            <div className={`aside-item__heading ${componentName}-aside__heading`}>
                <div className={`aside-item__title ${componentName}-aside__title`}>
                    <div className={`aside-item__title-text ${componentName}-aside__title-text`}>
                        {title}
                    </div>
                </div>
                <div className={`aside-item__controls ${componentName}-aside__controls`}>
                    <button
                        className={`act ${componentName}-aside__opener ${isOpenedContainer(this)}`}
                        onClick={this.toggleHeaderHandler}
                    >
                        <img src={doubleArrDownSvg} alt="opener" className="opener-icon" height="12px" width="12px"/>
                    </button>
                </div>
            </div>
            <SliderContainer opened={opened}>
                {children}
            </SliderContainer>
        </div>;
    }
}
