import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import LineInterface from 'app/interfaces/LineInterface';
import {GAMER_1, GAMER_2, GREEN, lineEventName} from 'app/lines/line_helpers';
import {gamerCoef} from 'app/lines/gamerCoef';
import {tableNick} from 'app/lines/tableNick';
import {date as dateFormat, gameImage, scrollToTop, time} from 'app/utils';
import {goToLinkSimpleBets} from 'app/utils/app_links';
import {showBetSlip} from 'app/components/table';
import StreamInterface from 'app/interfaces/StreamInterface';
import {openStream} from 'app/components/streams';
import GamerPhoto from 'app/components/bet_form/GamerPhoto';
import {lineGamer} from 'app/lines/lineGamer';
import {fixed3} from 'app/utils/fixed';

interface PropsInterface {
    line: LineInterface
    updatedAt: number
    currentStream: StreamInterface
}

export default class PreviewGamesItem extends PureComponent<PropsInterface> {
    handleCoefClick = (num: number): void => {
        const {line} = this.props;
        goToLinkSimpleBets();
        scrollToTop();
        showBetSlip(line, num, {selectedBet: line});
    };

    handleCoefClick1 = (): void => {
        this.handleCoefClick(GAMER_1);
    };

    handleCoefClick2 = (): void => {
        this.handleCoefClick(GAMER_2);
    };

    openStream = (e: React.MouseEvent<HTMLOrSVGElement>): void => {
        e.stopPropagation();
        goToLinkSimpleBets();
        scrollToTop();
        const {currentStream} = this.props;

        if (currentStream) {
            openStream(currentStream);
        }
    };

    colorClass = (color: number): string => {
        switch (color) {
        case GREEN:
            return 'started';
        default:
            return '';
        }
    };

    renderCoef = (gamer: number): string => {
        const gamer_coef = gamerCoef(this.props.line, gamer);

        return gamer_coef ? fixed3(gamer_coef.toString()) : '\u2015';
    };

    render(): React.ReactNode {
        const {line} = this.props;
        const colorClass = this.colorClass(line.color);

        return <div className="preview-game">
            <div className="preview-game__heading">
                <span className="preview-game__heading-title">{lineEventName(line, false)}</span>
            </div>
            <div className="preview-game__body">
                <div className="preview-game__body-left">
                    <div className="preview-game-team">
                        <span className="preview-game-team-span-img">
                            <GamerPhoto
                                line_gamer={lineGamer(line, GAMER_1)}
                                className="preview-game-team-img"
                            />
                        </span>
                        <span className={`preview-game-team-name ${colorClass}`}>
                            <b>{tableNick(line, GAMER_1)}</b>
                        </span>
                        <button className={`button-preview-game-team ${colorClass}`}
                            onClick={this.handleCoefClick1}>
                            {this.renderCoef(GAMER_1)}
                        </button>
                    </div>
                </div>
                <div className="preview-game__body-middle">
                    <div className="preview-game-about">
                        {line.live
                            ? <button className="preview-game-watch-stream" onClick={this.openStream}
                                title={I18n.t('live_stream_title')}>
                                <svg className="svg-default">
                                    <use xlinkHref="#svg-movie"/>
                                </svg>
                                <span className="preview-game-watch-stream__title">{I18n.t('live_label')}</span>
                            </button>
                            : <div className="preview-game-start">
                                <span className="preview-game-date">{dateFormat(line.date)}</span>
                                <span className="preview-game-date">{time(line.date)}</span>
                            </div>
                        }
                        {gameImage(line.filter, 'preview-game-logo')}
                    </div>
                </div>
                <div className="preview-game__body-right">
                    <div className="preview-game-team">
                        <span className="preview-game-team-span-img">
                            <GamerPhoto
                                line_gamer={lineGamer(line, GAMER_2)}
                                className="preview-game-team-img"
                            />
                        </span>
                        <span className={`preview-game-team-name ${colorClass}`}>
                            <b>{tableNick(line, GAMER_2)}</b>
                        </span>
                        <button className={`button-preview-game-team ${colorClass}`}
                            onClick={this.handleCoefClick2}>
                            {this.renderCoef(GAMER_2)}
                        </button>
                    </div>
                </div>
            </div>
        </div>;
    }
}
