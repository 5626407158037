export const ELEMENT_NOT_FOUND = -1;
export const DEFAULT_CURRENCY = 'USD';
export const MAIN_CONTAINER_ID = 'main_container';
export const MINIMIZED_CONTAINER_ID = 'minimized_container';
export const GAMER_1 = 1;
export const GAMER_2 = 2;
export const STATUS_OPENED = 0;
export const REAL_MONEY = 'real_money';
export const DEMO_MONEY = 'demo_money';
export const PLAY_MONEY = 'play_money';
export const ARTIFACT_MONEY = 'artifact_money';
export const FIVE_SECONDS = 5000;
export const APP_INSTALL_ASKED = 'appInstallAsked';
export const BET_SLIP_POSITION_X = 'betSlipPositionX';
export const BET_SLIP_POSITION_Y = 'betSlipPositionY';
export const TURN_OFF_SCROLL_CLASS_NAME = 'body-overflow';
export const MIN_PLEDGE = 200;
export const MIN_COEF_CHANGING = 0;
export const MAX_COEF_CHANGING = 9.9;
export const INCUNATOR_GAME_ID = 'incubator_game_id';
export const UNKNOWN_COUNTRY = 'xx';
export const PERCENT_75 = 75;
export const StatsType = {
    EMPTY: 0,
    MONTHLY: 1,
    YEAR: 2
};
export const LANGUAGES = {
    EN: 'en',
    RU: 'ru'
};

export const ESC_KEY_CODE = 'Escape';
export const KEY_UP = 'ArrowUp';
export const KEY_DOWN = 'ArrowDown';
export const KEY_ENTER = 'Enter';

export const BetSlipFocus = {
    AMOUNT: 0,
    COEF: 1,
    WIN: 2
};

export const DEFAULT_BETS = [
    '10',
    '20',
    '50'
];

export const USER_PROMOTION_DEPOSIT = 'userPromotionDeposit';
export const USER_PROMOTION_FRIEND = 'userPromotionFriend';
export const USER_PROMOTION_DISCONT = 'userPromotionDiscont';
