import React, {FC, useCallback} from 'react';
import I18n from 'app/i18n';
import ShowMore from 'app/utils/ShowMore';
import TableEmptyState from 'app/components/table/empty_state';
import {dateLabel} from 'app/utils/dateLabel';
import GiveawayItemInterface from 'app/interfaces/my_prizes/GiveawayItemInterface';
import GiveawayRow from 'app/components/my_profile/prizes/GiveawayRow';
import {GiveawayStore} from 'app/store/GiveawayStore';
import {inject, observer} from 'mobx-react';
import SmartTableLoader from 'app/components/reusable/smart_loaders/SmartTableLoader';

interface GiveawayTableProps {
    data?: GiveawayItemInterface[],
    resetData?: () => void,
    loading?: boolean,
    showMoreHandler?: () => Promise<void>,
    has_more?: boolean,
    giveawayStore?: GiveawayStore
}

const GiveawayTable: FC<GiveawayTableProps> = props => {
    const takePartHandle = useCallback((e: React.MouseEvent) => takePart(e), []);

    const takePart = (e: React.MouseEvent): void => {
        e.preventDefault();
        props.giveawayStore.takePart();
    };


    const {data, loading, has_more, resetData, showMoreHandler, giveawayStore} = props;
    return <>
        <div className="table-content-head table-content-giveaway">
            <div className="table-content-cell">
                <span className="table-content-cell-name">{dateLabel()}</span>
            </div>
            <div className="table-content-cell">
                <span className="table-content-cell-name">{I18n.t('prize_label')}</span>
            </div>
        </div>
        <div className="table-content-body table-content-giveaway">
            {loading && <SmartTableLoader/>}
            {data && data.map(giveaway => <GiveawayRow
                key={giveaway.id}
                item={giveaway}
                resetData={resetData}
            />)}
        </div>
        <ShowMore onClickHandler={showMoreHandler} has_more={has_more}/>
        <TableEmptyState
            arrayLength={data.length}
            text={I18n.t('no_prizes')}
            buttonText={I18n.t('prizes_texts.take_part')}
            buttonClick={takePartHandle}
            isDisabled={giveawayStore.giveaway.participated}
            loading={loading}
        />
    </>;
};

export default inject('giveawayStore')(observer(GiveawayTable));

