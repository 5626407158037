import $ from 'jquery';
import {ComponentStore} from 'app/store';
import {getLinkPath, scrollToTop, slowConnectionAndError, updateTitle} from 'app/utils';
import React, {Fragment} from 'react';
import {Loader} from './common_components';
import {Link} from 'react-router-dom';
import I18n from 'app/i18n';
import SocialLink from 'app/components/social_link';
import ArticleContent from 'app/components/articles/content';
import withRouter from 'app/utils/navigate/customWithRouter';

class BlogShow extends ComponentStore {
    state = {loader: true};

    componentDidMount() {
        this.loadContentHandler();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.params.id !== this.props.params.id) {
            this.loadContentHandler();
        }
        updateTitle(this.state.title);
    }

    loadContentHandler() {
        slowConnectionAndError($.ajax(getLinkPath(`/blog/${this.props.params.id}`), {
            dataType: 'JSON',
            success: response => this.setState({...response, loader: false})
        }));
    }

    shareFacebook = e => {
        e.preventDefault();
        window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${escape(window.location.href)}&t=${document.title}`,
            '_blank',
            'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600'
        );
    };

    shareTwitter = e => {
        e.preventDefault();
        window.open(
            `https://twitter.com/share?url=${escape(window.location.href)}`,
            '_blank',
            'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600'
        );
    };

    shareVK = e => {
        e.preventDefault();
        window.open(
            `https://vk.com/share.php?url=${escape(window.location.href)}&title=${document.title}`,
            '_blank',
            'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600'
        );
    };

    render() {
        const {loader, title, date, content, recent_posts} = this.state;
        return loader
            ? <Loader/>
            : <Fragment>
                <div className="title">{title}</div>
                <ArticleContent date={date} content={content}/>
                <div className="content__holder">
                    <div className="socials">
                        <div className="socials__title"><h2>{I18n.t('user.share_article')}</h2></div>
                        <div className="socials__list">
                            <SocialLink href="#" onClick={this.shareFacebook} svgId="#svg-facebook"/>
                            <SocialLink href="#" onClick={this.shareTwitter} svgId="#svg-twitter"/>
                            <SocialLink href="#" onClick={this.shareVK} svgId="#svg-vk"/>
                        </div>
                    </div>
                </div>
                <div className="content__holder">
                    <div className="blog-recent">
                        {recent_posts.length > 0 && <Fragment>
                            <h2 className="blog-recent__heading">{I18n.t('recent_blogs')}</h2>
                            <div className="blog-recent__content-holder">
                                {recent_posts.map(post => <div key={post.slug} className="blog-recent__item">
                                    <Link
                                        to={getLinkPath(`/blog/${post.slug}`)}
                                        className="blog-recent__item-link"
                                        onClick={scrollToTop}
                                    >
                                        <span className="blog-recent__image">
                                            <img
                                                data-src={post.preview_image}
                                                alt={post.title}
                                                title={post.title}
                                                className="lazyload"
                                            />
                                        </span>
                                        <span className="blog-recent__title">{post.title}</span>
                                    </Link>
                                </div>)}
                            </div>
                        </Fragment>}
                    </div>
                </div>
            </Fragment>;
    }
}

export default withRouter(BlogShow);
