import {TableStore} from 'app/store/TableStore';
import {inject, observer} from 'mobx-react';
import React from 'react';
import PlayContentImpl from './index';
import PlayPage from '../play';
import {useParams} from 'react-router';

interface PlayLiveContentProps {
    tableStore?: TableStore
}

const PlayLiveContent = (props: PlayLiveContentProps): React.JSX.Element => {
    const params = useParams<{ filter: string }>();

    const {filter = ''} = params;
    const {tableStore} = props;
    return <PlayContentImpl
        filter={filter}
        urlPrefix="/play/live/"
        keyPrefix="live"
        esports
        tableStore={tableStore}
        tableProps={{
            filter: PlayPage.liveFilter,
            live: true
        }}
    />;
};

export default inject('tableStore')(observer(PlayLiveContent));
