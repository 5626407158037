import $ from 'jquery';
import React from 'react';

let recaptchaLoadAttemptsLeft = 6;

const isRecaptchaAvailable = () => window.grecaptchaReady;

export default class Captcha extends React.PureComponent {
    state = {egbCaptcha: null, recaptchaId: null};

    componentDidMount() {
        setTimeout(this.initializeRecaptcha, 100);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.reset !== this.props.reset && nextProps.reset) {
            if (this.state.recaptchaId !== null) {
                window.grecaptcha.reset(this.state.recaptchaId);
            } else if (this.state.egbCaptcha) {
                this.initializeEgbCaptcha();
            }
        }
    }

    componentWillUnmount() {
        if (this.state.recaptchaId) {
            window.grecaptcha.reset(this.state.recaptchaId);
        }
    }

    initializeEgbCaptcha = () => $.get('/captcha', x => this.setState({egbCaptcha: x.data}));

    initializeRecaptcha = () => {
        if (isRecaptchaAvailable()) {
            const recaptchaId = window.grecaptcha.render(this.recaptchaRef, {
                callback: this.props.onPass,
                sitekey: document.querySelector('meta[name="Recaptcha"]').content,
                tabindex: 0,
                theme: 'dark'
            });
            this.setState({recaptchaId});
        } else if (--recaptchaLoadAttemptsLeft >= 0) {
            setTimeout(this.initializeRecaptcha, 250);
        } else {
            this.initializeEgbCaptcha();
        }
    };

    setRecaptchaRef = dom => this.recaptchaRef = dom;

    render() {
        if (this.state.egbCaptcha) {
            return <div className="popup-form__row">
                <div className="field">
                    <img onClick={this.initializeEgbCaptcha} src={this.state.egbCaptcha} style={{width: '100%'}}/>
                    <input className="field__input" name="verification"/>
                </div>
            </div>;
        }

        return <div ref={this.setRecaptchaRef}/>;
    }
}
