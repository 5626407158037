import SurveyInterface from 'app/interfaces/SurveyInterface';
import SingleRecordStore from 'stores/SingleRecordStore';
import {makeObservable} from 'mobx';

export class ActiveSurveyStore extends SingleRecordStore<SurveyInterface> {
    channel = (): string => null;

    feedUrl = (): string => null;

    constructor() {
        super();
        makeObservable(this);
    }
}
