import React, {memo} from 'react';
import I18n from 'app/i18n';

export default interface BetSlipBodyInterface {
    showWarning: string
    warning: string
    available: boolean
    onClick: () => void
}

export const BetSlipWarning = memo((props: BetSlipBodyInterface): JSX.Element => {
    const {showWarning, warning, available, onClick} = props;

    return showWarning && <div className="bet-slip-match-warning bet-single">
        <p>{warning}</p>
        {available
            ? <p>
                <button
                    className="button-adaptive button-adaptive_instyle"
                    onClick={onClick}
                >
                    {I18n.t('suggest_your_odds')}
                </button>
            </p>
            : null}
    </div>;
});
