import React from 'react';
import {NavLink} from 'react-router-dom';
import {getClassNameActiveLink, getLinkPath} from 'app/utils/link_helper';
import I18n from 'app/i18n';
import {linkMyHero} from 'app/utils/app_links';

const ProfileMenu = (): React.JSX.Element => <>
    <NavLink
        to={getLinkPath('/my_profile/prizes')}
        className={getClassNameActiveLink}
    >
        {I18n.t('prizes')}
    </NavLink>
    <NavLink
        to={linkMyHero()}
        className={getClassNameActiveLink}
    >
        {I18n.t('hero')}
    </NavLink>
    <NavLink
        to={getLinkPath('/my_profile/trades/outgoing')}
        className={getClassNameActiveLink}
    >
        {I18n.t('trades')}
    </NavLink>
    <NavLink
        to={getLinkPath('/my_profile/referrals')}
        className={getClassNameActiveLink}
    >
        {I18n.t('referrals.title')}
    </NavLink>
</>;

export default ProfileMenu;
