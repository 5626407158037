import React, {PureComponent} from 'react';
import $ from 'jquery';
import {getLinkPath} from 'app/utils/link_helper';
import I18n from 'app/i18n';
import ShopInfoPopupGift from 'app/components/shop/shop_info_popup_gift';

export default class ShopItemGift extends PureComponent {
    constructor() {
        super();
        this.state = {message: null};
        this.showConfirmPopup = this.showConfirmPopup.bind(this);
    }

    showConfirmPopup(title, price, userData) {
        $.ajax(getLinkPath(`/gift/information/${userData.item_gifts_user_id}`), {
            data: userData,
            statusCode: {
                200: () => this.setState({message: I18n.t('user.promo_action.information_received_message')})
            },
            type: 'POST'
        });
    }

    render() {
        return this.state.message || <ShopInfoPopupGift
            {...this.props}
            scope_param="information"
            gift
            confirmBuyHandler={this.showConfirmPopup}
        />;
    }
}
