import React, {PureComponent} from 'react';
import {ArtifactImage} from '../artifacts/image';
import {showPopup} from 'app/utils/modals/ModalsService';
import SteamTradePopup from '../artifacts/SteamTradePopup';
import SteamTradeInterface from 'app/interfaces/SteamTradeInterface';
import AlertPoint from '../../AlertPoint';

interface SteamTradeSlotProps {
    steamTrade: SteamTradeInterface;
}

export const openSteamTradePopup = (steamTrade: SteamTradeInterface): void => {
    const {id, steam_skin, state, will_sold_at} = steamTrade;
    showPopup('inventory-popup',
        (props: {popupId: string}) => <SteamTradePopup
            id={id}
            popupId={props.popupId}
            state={state}
            steam_skin={steam_skin}
            will_sold_at={will_sold_at}
        />);
};

export default class SteamTradeSlot extends PureComponent<SteamTradeSlotProps> {
    openPopup = (): void => {
        openSteamTradePopup(this.props.steamTrade);
    };

    render(): React.ReactNode {
        const {steamTrade: {id, state, steam_skin}} = this.props;
        return <div
            id={`steam-trade-hero-slot-${id}`}
            className={`hero__slot ${state === 'on_approval' ? 'hero__slot--unavailable' : ''}`}
            onClick={this.openPopup}
        >
            {state === 'pending_edits' ? <AlertPoint/> : null}
            <ArtifactImage artifact={steam_skin}/>
        </div>;
    }
}
