import React, {Component, ReactNode} from 'react';
import Items from 'app/components/shop/Items';
import {ShopsStoreProps} from 'app/store/ShopStore';
import {inject, observer} from 'mobx-react';

@inject('shopStore')
@observer
export default class ProductItems extends Component<ShopsStoreProps> {
    componentDidMount(): void {
        this.props.shopStore.setShopUrl(null);
    }

    render(): ReactNode {
        const {data: {user: {points}, money_items}} = this.props.shopStore;
        return <Items items={money_items} points={points}/>;
    }
}
