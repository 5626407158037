import React, {Component} from 'react';
import {modalsStore} from 'app/store/global';
import $ from 'jquery/dist/jquery';
import {PopupDialog} from './PopupDialog';
import {SubmitButton} from 'app/utils';
import I18n from 'app/i18n';

interface ModalConfirmInterface {
    popupId: string
    okClick?(): void | { finished: boolean; done?(p: () => void): void }
    contentScroll?: boolean
    setFocus?: boolean
    cancelShow?: boolean
    cancelClick?(): void
    hideCloseButton?: boolean
    children?: React.ReactNode | {element: React.ReactNode, react: boolean}
    okText?: string
    cancelText?: string
}

export class ModalConfirm extends Component<ModalConfirmInterface> {
    okBtnRef: React.RefObject<HTMLButtonElement> = React.createRef();

    scrollRef: React.RefObject<HTMLDivElement> = React.createRef();

    closePopup = (): void => {
        modalsStore.hideModal(this.props.popupId);
    };

    okClick = (): void => {
        const res = this.props.okClick && this.props.okClick();

        if (res && res.finished) {
            return;
        }
        if (res && res.done) {
            res.done(() => this.closePopup());
        } else {
            this.closePopup();
        }
    };

    componentDidMount = (): void => {
        if (this.props.contentScroll) {
            $(this.scrollRef.current).children().perfectScrollbar({
                wheelPropagation: true
            });
        }
        this.okBtnRef.current.focus();
    };

    cancelClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event && event.preventDefault();
        this.props.cancelClick && this.props.cancelClick();
        this.closePopup();
    };

    modalChildren = (): React.ReactNode => {
        const {children} = this.props as {children: {element: React.ReactNode, react: boolean}};

        if (!React.isValidElement(children) && typeof children === 'object') {
            return children.element;
        }
        return <div dangerouslySetInnerHTML={{__html: children}}/>;
    };

    render(): React.JSX.Element {
        return <PopupDialog
            id={this.props.popupId}
            setFocus={this.props.setFocus}
            onClose={this.props.cancelClick}
            hideCloseButton={this.props.hideCloseButton}
        >
            <div className="popup__content popup__content--small">
                <div className="modal-popup-content">
                    <div className="modal-popup-content__text" ref={this.scrollRef}>
                        {this.modalChildren()}
                    </div>
                    <SubmitButton className="modal-popup-content__submit-row" okClick={this.okClick}
                        cancelClick={this.props.cancelShow ? null : this.cancelClick}
                        title={this.props.okText || I18n.t('confirm_button_yes')}
                        cancelText={this.props.cancelText} okBtnRef={this.okBtnRef}/>
                </div>
            </div>
        </PopupDialog>;
    }
}
