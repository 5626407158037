import I18n from 'app/i18n';
import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';
import CashoutValidator from 'app/validators/CashoutValidator';
import PresenceValidation from 'validations/PresenceValidation';

export default class CombinedCashoutValidator extends CashoutValidator {
    validatePaymentSystems(): void {
        this.validatePaymentSystemsArray();
        this.object.selectedWallets.forEach(wallet => {
            const paymentSystem = this.object.systems[wallet.payment_system_id];
            this.paymentSystemValidation(paymentSystem);
        });
    }

    paymentSystemValidation(paymentSystem: PaymentSystemInterface): void {
        this.validateWalletRegExp(paymentSystem);
        this.validateMaxLimit(paymentSystem);
        this.validateMinLimit(paymentSystem);
        this.validatePayzenDetails(paymentSystem);
        this.validateScreenshot(paymentSystem);
    }

    validatePayzenDetails(paymentSystem: PaymentSystemInterface): void {
        if (paymentSystem.cashout_details_ui_component && !paymentSystem.p2p) {
            Object.keys(this.object.cashout_detail_attributes).forEach(d => {
                if (!new PresenceValidation(this.object.cashout_detail_attributes[d]).execute()) {
                    this.setError(`cashout_detail.${d}`, I18n.t('required_field'));
                    this.result = false;
                }
            });
        }
    }

    validatePaymentSystemsArray(): void {
        if (this.object.selectedWallets.length === 0) {
            this.setError('payment_systems', I18n.t('required_field'));
            this.result = false;
        }
    }
}
