import React, {PureComponent} from 'react';
import {ToastContainerCustomize} from 'app/components/toasts/ToastContainerCustomize';
import I18n from 'app/i18n';
import {MAIN_CONTAINER_ID, MINIMIZED_CONTAINER_ID} from 'app/utils/Consts';
import {toast} from 'react-toastify';
import {isDesktop} from 'app/utils';
import {OnChangeCallback} from 'react-toastify/dist/core';

export class GroupToastContainer extends PureComponent {
    state = {
        count: 0,
        minimized: true
    };

    toastifyScroll: React.RefObject<HTMLDivElement> = React.createRef();

    componentDidMount(): void {
        toast.onChange(((numberOfToastDisplayed, containerId) => {
            if (containerId === MINIMIZED_CONTAINER_ID) {
                if (numberOfToastDisplayed > this.state.count && this.toastifyScroll) {
                    this.toastifyScroll.current.scrollTop = 0;
                }
                this.setState({count: numberOfToastDisplayed});
            }
        }) as OnChangeCallback);
    }

    handleMinimizeClick = (): void => {
        const {minimized} = this.state;
        this.setState({minimized: !minimized});
    };

    handleMinimizeContainerClick = (e: React.MouseEvent<HTMLDivElement>): void => {
        if (!(e.target instanceof HTMLButtonElement) && this.state.minimized && this.state.count > 1) {
            this.handleMinimizeClick();
        }
    };

    mainToastContainerCustomize = (): React.ReactNode => <div className="toastify-no-scroll">
        <ToastContainerCustomize
            containerId={MAIN_CONTAINER_ID}
            position={toast.POSITION.BOTTOM_RIGHT}
            draggable/>
    </div>;

    render(): React.ReactNode {
        const {count, minimized} = this.state;
        return <div className="smart-notifications">
            {isDesktop() && this.mainToastContainerCustomize()}
            <div className={`${minimized ? 'notifications-minimized hidden' : 'notifications-minimized shown'}`}>
                {count > 1 && <div className="notifications-minimized__heading">
                    <span className="notifications-minimized__heading-title">
                        {I18n.t('notifications_count', {count})}
                    </span>
                    <button
                        className="button-adaptive button-adaptive_full"
                        onClick={this.handleMinimizeClick}>
                        {I18n.t(minimized ? 'show_all' : 'minimized')}
                    </button>
                </div>}
                <div className="notifications-minimized__body">
                    <div className="toastify-scroll"
                        ref={this.toastifyScroll}
                        onClick={this.handleMinimizeContainerClick}>
                        <ToastContainerCustomize
                            className={MINIMIZED_CONTAINER_ID}
                            containerId={MINIMIZED_CONTAINER_ID}
                            draggable={false}
                            position={toast.POSITION.BOTTOM_RIGHT}/>
                    </div>
                </div>
            </div>
            {!isDesktop() && this.mainToastContainerCustomize()}
        </div>;
    }
}
