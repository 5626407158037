import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {showPopup} from 'app/utils/modals/ModalsService';
import I18n from 'app/i18n';
import {SubmitButton} from 'app/utils';
import React, {PureComponent} from 'react';
import {modalsStore, userStore} from 'app/store/global';
import Post from 'app/utils/Post';
import {toastSuccess} from 'app/components/toasts/liteToast';
import UserInterface from 'app/interfaces/UserInterface';

export const changeNick = (): void => showPopup('change-nickname', props => <UserResetPopup popupId={props.popupId}/>);

export const resetStat = (): void => showPopup('change-nickname', props => <UserResetPopup popupId={props.popupId} reset={true}/>);


interface UserResetPopupProps {
    nickname?: string;
    new_nickname?: string;
    reset?: boolean;
    e?: void;
    popupId: string
}
interface UserResetPopupState {
    errors?: {
            name?: string,
            password?: string
    }
}

interface IResponseSuccess {
    success?: boolean;
    user: UserInterface
}

interface IResponseFailed {
    [k:string]: string
}

class UserResetPopup extends PureComponent<UserResetPopupProps, UserResetPopupState> {
    state: UserResetPopupState = {errors: {}};

    new_nickname: React.RefObject<HTMLInputElement> = React.createRef();

    nick_password: React.RefObject<HTMLInputElement> = React.createRef();

    handleUserReset = (e: typeof SubmitButton): void => {
        e && e.preventDefault && e.preventDefault();

        const data:{[k:string]: string} = {nick_password: this.nick_password.current.value};

        if (!this.props.reset) {
            data.new_nickname = this.new_nickname.current.value;
        }
        this.setState({errors: {}});
        new Post({
            params: data,
            url: '/user/reset_user'
        })
            .execute()
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    this.handleSuccessResponse(response);
                } else {
                    this.handleFailedResponse(response);
                    e();
                }
            });
    };

    handleSuccessResponse: (response: IResponseSuccess) => void = response => {
        userStore.update({user: response.user});
        this.closeModal();
        toastSuccess(this.props.reset ? I18n.t('stat_reset_success') : I18n.t('nickname_change_success'));
    };

    handleFailedResponse: (response: IResponseFailed) => void = response => {
        this.setState({errors: {...response}});
    };

    cancelClick = (e: React.MouseEvent) => {
        e && e.preventDefault();
        this.closeModal();
    };

    checkError(field: string): boolean {
        return this.state.errors[field] ? this.state.errors[field][0] : false;
    }

    removeError(prop) {
        const obj = {...this.state.errors};

        if (this.checkError(prop)) {
            delete obj[prop];
            this.setState({errors: obj});
        }
    }

    onKeyUpName = (): void => this.removeError('name');

    onKeyUpPassword = (): void => this.removeError('password');

    closeModal = (): void => {
        modalsStore.hideModal(this.props.popupId);
    };

    render(): React.ReactElement {
        return <PopupDialog id={this.props.popupId} setFocus={true}>
            <div className="popup__content popup__content--small">
                <div className="modal-popup-content">
                    <div className="modal-popup-content__text">
                        <form onSubmit={this.handleUserReset}>
                            <div className="popup-form__row">
                                {this.props.reset
                                    ? I18n.t('stat_reset_confirm')
                                    : I18n.t('nickname_change_confirm')}
                            </div>
                            {!this.props.reset &&
                                <div className={`popup-form__row${this.state.errors.name ? ' field-error' : ''}`}>
                                    <div>{I18n.t('new_nickname')}</div>
                                    <div className="field">
                                        <input
                                            ref={this.new_nickname}
                                            type="text"
                                            id="new_nickname"
                                            name="new_nickname"
                                            className="field__input"
                                            autoComplete="new_nickname"
                                            onKeyUp={this.onKeyUpName}
                                        />
                                    </div>
                                    {this.state.errors.name &&
                                        <div className="field__error-hint">{this.state.errors.name} </div>}
                                </div>}

                            <div className={`popup-form__row${this.state.errors.password ? ' field-error' : ''}`}>
                                <div>{I18n.t('enter_password')}</div>
                                <div className="field">
                                    <input
                                        ref={this.nick_password}
                                        type="password"
                                        id="nick_password"
                                        name="nick_password"
                                        className="field__input"
                                        autoComplete="nick_password"
                                        onKeyUp={this.onKeyUpPassword}
                                    />
                                </div>
                                {this.state.errors.password && <div className="field__error-hint">
                                    {this.state.errors.password}
                                </div>}
                                <input type="submit" hidden />
                            </div>
                        </form>
                    </div>
                    <SubmitButton className="modal-popup-content__submit-row" okClick={this.handleUserReset}
                        cancelClick={this.cancelClick} title={I18n.t('confirm_button_yes')}/>
                </div>
            </div>
        </PopupDialog>;
    }
}
