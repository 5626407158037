import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import StreamsMenu from 'app/components/streams/StreamsMenu';

class StreamsListMobile extends PureComponent {
    render(): ReactNode {
        return <div className="stream-list__wrap">
            <div className="main-nav__subtitle">{I18n.t('stream_title')}</div>
            {<StreamsMenu/>}
        </div>;
    }
}

export default StreamsListMobile;
