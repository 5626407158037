import LazyImage from 'app/helpers/LazyImage';
import {getLinkPath, scrollToTop} from 'app/utils';
import I18n from 'app/i18n';
import {Link} from 'react-router-dom';
import React, {Component, ReactNode} from 'react';
import ArticleInterface from 'app/interfaces/articles/ArticleInterface';
import CountdownTimer from 'app/components/bet_form/timer';
import {getCountDown} from 'app/utils/timer/getCountDown';
import {DATE_WITHOUT_TIME, commonTimeFormat} from 'admin/utils/timeFormat';

interface PromoNewItemInterface {
    data: ArticleInterface
    type?: string
}

interface TimerInfoInterface {
    className: string
    label: string
    time: number
}

export default class PromoNewItem extends Component<PromoNewItemInterface> {
    getTimerInfo = (): TimerInfoInterface => {
        const {started_at, ended_at} = this.props.data;

        if (getCountDown(started_at) < 0) {
            return {className: 'time-end', label: I18n.t('promo_new_label_time_to_end'), time: ended_at};
        }

        return {className: 'time', label: I18n.t('promo_new_label_time'), time: started_at};
    };

    getLabelByLocale = (column = 'label'): string => {
        const {data} = this.props;
        return data[`promo_${column}_langs`] &&
            data[`promo_${column}_langs`][I18n.locale] ||
            data[`promo_${column}`] || data[column];
    };

    render(): ReactNode {
        const {id, preview_image, title, slug, date} = this.props.data;
        const {label, time, className} = this.getTimerInfo();
        const text = this.getLabelByLocale('text');
        return <div className="item-promo-article">
            <Link
                to={getLinkPath(`/${this.props.type || 'promotions'}/${slug}`)}
                onClick={scrollToTop}
                className="link-promo-article"
                key={id}>
                {preview_image && <LazyImage
                    attributes={{itemProp: 'url'}}
                    image={{name: title, url: preview_image}}
                    className="lazyload image-promo-article"
                />}
                <div className="description-promo-article">
                    <span
                        className="title-promo-article"
                        dangerouslySetInnerHTML={{__html: this.getLabelByLocale('title')}}
                    />
                    <div
                        className="text-promo-article"
                        dangerouslySetInnerHTML={{__html: this.getLabelByLocale('pre_content')}}
                    />
                    {this.props.type === 'news'
                        ? <div className="labels-promo-article">
                            <div className="row">
                                <span className="name">{I18n.t('news_published')}:</span>
                                <span className="label-promo-article time-end">
                                    <span className="span-timer">{commonTimeFormat(date, DATE_WITHOUT_TIME)}</span>
                                </span>
                            </div>
                        </div>
                        : <div className="labels-promo-article">
                            {time > 0 && <div className="row">
                                <span className="name">{label}:</span>
                                <span className={`label-promo-article ${className}`}>
                                    <CountdownTimer time={time} plainText/>
                                </span>
                            </div>}
                            {text && <div className="row">
                                <span className="name">{this.getLabelByLocale()}</span>
                                <span className="label-promo-article prize">{text}</span>
                            </div>}
                        </div>}
                </div>
            </Link>
        </div>;
    }
}
