import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {localDateTimeFromISO} from 'app/utils';

export default class Notice extends PureComponent {
    render() {
        const {openingLootbox, sending_prohibited, user_received_period, reason = {}} = this.props;
        const {message} = reason;

        if (openingLootbox || !sending_prohibited && !message) {
            return null;
        }

        return <div className="inventory__popup--notice">
            {message || I18n.t('send_as_gift_notice', {time: localDateTimeFromISO(user_received_period)})}
        </div>;
    }
}
