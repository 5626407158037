/* eslint-disable no-magic-numbers */
import {I18n} from 'i18n-js';
import Cookies from 'js-cookie';

const EN_LOCALE = 'en';

const i18n = new I18n();

const translateFiles = {
    cs: () => import('app/translations/cs.json'),
    de: () => import('app/translations/de.json'),
    en: () => import('app/translations/en.json'),
    es: () => import('app/translations/es.json'),
    fr: () => import('app/translations/fr.json'),
    it: () => import('app/translations/it.json'),
    ko: () => import('app/translations/ko.json'),
    pl: () => import('app/translations/pl.json'),
    pt: () => import('app/translations/pt.json'),
    ru: () => import('app/translations/ru.json'),
    tr: () => import('app/translations/tr.json'),
    zh: () => import('app/translations/zh.json')
};

window.I18n = i18n;
const siteLocale = (self.window && typeof self.window.CURRENT_LOCALE === 'string'
    ? self.window.CURRENT_LOCALE
    : Cookies.get('lang')) || EN_LOCALE;

i18n.locale = siteLocale;
translateFiles[siteLocale]()
    .then(module => {
        i18n.store({
            [siteLocale]: module[siteLocale]
        });
    });

// I18n.store(storeTranslations());
i18n.enableFallback = true;

i18n.pluralization.register('ru', (i18n, count) => {
    const mod10 = count % 10;
    const mod100 = count % 100;
    let key;

    const one = mod10 === 1 && mod100 !== 11;
    const few = [
        2,
        3,
        4
    ].includes(mod10) && ![
        12,
        13,
        14
    ].includes(mod100);
    const many =
        mod10 === 0 ||
        [
            5,
            6,
            7,
            8,
            9
        ].includes(mod10) ||
        [
            11,
            12,
            13,
            14
        ].includes(mod100);

    if (one) {
        key = 'one';
    } else if (few) {
        key = 'few';
    } else if (many) {
        key = 'many';
    } else {
        key = 'other';
    }

    return [key];
});
export const i18n_t = (key: string, scope = {}): string => i18n.t(key, scope);

export default i18n as I18n;
