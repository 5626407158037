import React, {PureComponent, ReactNode} from 'react';
import SmartPromotionItem from 'app/components/pages/home_page/SmartPromotion/SmartPromotionItem';
import {Swiper, SwiperSlide} from 'swiper/swiper-react';
import {Autoplay, EffectFade, Pagination, Navigation} from 'swiper/modules';
import ArticleInterface from 'app/interfaces/articles/ArticleInterface';
import {isDesktop} from 'app/utils';

const swiperParams = {
    allowTouchMove: true
};

interface ArticlesWithTypeProps {
    data: ArticleInterface[]
    type?: string
    nameForControls: string
}

export default class SmartPromotionList extends PureComponent<ArticlesWithTypeProps> {
    render(): ReactNode {
        const {data, type, nameForControls} = this.props;
        return <div className="smart-promotion-list">
            {isDesktop()
                ? <Swiper
                    className="slider-smart-promotions"
                    {...swiperParams}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false
                    }}
                    modules={[
                        Autoplay,
                        EffectFade,
                        Pagination
                    ]}
                    pagination={{
                        clickable: true,
                        el: `.pagination-${nameForControls}`
                    }}
                    effect={'fade'}
                    speed={700}
                >
                    {data.map(article => <SwiperSlide key={article.id}>
                        <SmartPromotionItem data={article} type={type}/>
                    </SwiperSlide>)}
                    <div className={`swiper-pagination-group pagination-${nameForControls}`}/>
                </Swiper>
                : <>
                    <div className="wrapper-swiper-container">
                        <Swiper
                            className="slider-container-swiper slider-smart-promotions"
                            {...swiperParams}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false
                            }}
                            modules={[
                                Autoplay,
                                EffectFade,
                                Pagination,
                                Navigation
                            ]}
                            pagination={{
                                clickable: true,
                                el: `.pagination-${nameForControls}`
                            }}
                            navigation={{
                                nextEl: `.button-${nameForControls}-next`,
                                prevEl: `.button-${nameForControls}-prev`
                            }}
                            preventClicksPropagation
                            preventInteractionOnTransition
                            effect={'fade'}
                            speed={700}
                        >
                            {data.map(article => <SwiperSlide key={article.id}>
                                <SmartPromotionItem data={article} type={type}/>
                            </SwiperSlide>)}
                        </Swiper>
                    </div>
                    <div className="slider-pagination-control">
                        <button className={`swiper-button-slider-prev button-${nameForControls}-prev`}>
                            <svg className="svg-default">
                                <use xlinkHref="#svg-swiper-arrow"/>
                            </svg>
                        </button>
                        <div className={`swiper-pagination-group pagination-${nameForControls}`}/>
                        <button className={`swiper-button-slider-next button-${nameForControls}-next`}>
                            <svg className="svg-default">
                                <use xlinkHref="#svg-swiper-arrow"/>
                            </svg>
                        </button>
                    </div>
                </>}
        </div>;
    }
}
