import React, {useMemo} from 'react';
import {inject, observer, Provider} from 'mobx-react';
import BetSlipContainerInterface from 'app/interfaces/BetSlipContainerInterface';
import BetSlipController from 'app/components/bet_slip/BetSlipController';
import BetSlip from 'app/components/bet_slip/BetSlip';
import {useLocation} from 'react-router-dom';

const BetSlipContainer: React.FC<BetSlipContainerInterface> = props => {
    const location = useLocation();

    const controller = useMemo(() => new BetSlipController(
        props.betSlipStore,
        props.artifactBetStore,
        props.userStore,
        props.artifactStore,
        props.tableStore
    ), [
        props.betSlipStore,
        props.artifactBetStore,
        props.userStore,
        props.artifactStore,
        props.tableStore
    ]);

    if (props.betSlipStore.showForm) {
        return (
            <Provider controller={controller}>
                <BetSlip location={location} />
            </Provider>
        );
    }

    return <></>;
};

export default inject(
    'betSlipStore',
    'artifactBetStore',
    'artifactStore',
    'userStore',
    'artifactStore',
    'tableStore'
)(observer(BetSlipContainer));
