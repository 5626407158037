import React, {PureComponent} from 'react';
import {i18n_t} from 'app/i18n';

interface NotificationsSettingInterface {
    name: string,
    status: boolean,
    id: number,
    handleChangeSettings(checked: boolean, paramName: string): void
}

export default class NotificationSetting extends PureComponent<NotificationsSettingInterface> {
    onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.props.handleChangeSettings(e.target.checked, this.props.name);
    };

    render(): React.JSX.Element {
        const {name, status, id} = this.props;
        return <div
            key={`settings-${id}`}
            className="form__item"
        >
            <div className="form__row form__row__space-between">
                <div
                    className="field-checkbox-switch-text"
                >
                    <div className="form__item-title">
                        {i18n_t(`notification_settings.titles.${name}`)}
                    </div>
                    <div>
                        {i18n_t(`notification_settings.options.${name}`)}
                    </div>
                </div>

                <div className="field field-checkbox-switch">
                    <input
                        id={name}
                        name={name}
                        type="checkbox"
                        checked={status}
                        onChange={this.onChangeHandler}
                        className="field-checkbox-switch-input"
                    />
                    <label id={`label_${name}`} htmlFor={name} className="field-checkbox-switch-label">Switch</label>
                </div>
            </div>
        </div>;
    }
}
