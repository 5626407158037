import React, {Component, Fragment} from 'react';
import I18n from 'app/i18n';
import Limits from './Limits';
import CashoutDetails from './cashout_details';
import {hideLastPopup} from 'app/utils/modals/ModalsService';
import get from 'lodash/get';
import VerifiedStatusMessage from './verified_status_message';
import InputError from '../deposit/InputError';
import SystemCard from 'app/components/deposit/SystemCard';
import {inject, observer} from 'mobx-react';
import {translatedKeyLangs} from 'app/utils/translations_helper';
import Wallets from './Wallets';
import InputWallet from './InputWallet';
import UploadScreenshot from './UploadScreenshot';
import {sendEmailConfirmation} from 'app/utils/email/sendEmailConfirmation';
import {toastError} from 'app/components/toasts/liteToast';
import {CashoutsStore, ScreenshotType} from 'app/store/CashoutsStore';
import {UserStore} from 'app/store/UserStore';
import WalletDetailInterface from 'app/interfaces/WalletDetailInterface';
import DetailInterface from 'app/interfaces/DetailInterface';
import ChinaDictionariesInterface from 'app/interfaces/ChinaDictionariesInterface';
import PayzenChinaBankInterface from 'app/interfaces/PayzenChinaBankInterface';
import Post from 'app/utils/Post';
import {getLinkPath} from 'app/utils/link_helper';
import {modalConfirm} from 'app/utils/modals/popups';

const cashoutDisabledMessage = function(system) {
    return system.message_reject || I18n.t('user.cashout_disabled');
};

interface PropsInterface {
    cashoutsStore?: CashoutsStore
    userStore?: UserStore
    china_dictionaries?: ChinaDictionariesInterface
    china_banks?: PayzenChinaBankInterface[]
}

interface StateInterface {
    submitting: boolean
}

@inject('cashoutsStore', 'userStore')
@observer
export class CashoutForm extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {submitting: false};
        props.cashoutsStore.selectFirstWallet();
    }

    handleStartCashout = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        const {cashoutsStore, userStore: {user: {confirmation_period_expired, confirmed, email}}} = this.props;

        if (!confirmed) {
            toastError(I18n.t('toast_message_email_confirmation', {email}));
            if (confirmation_period_expired) {
                sendEmailConfirmation();
            }
            return;
        }
        cashoutsStore.startCashout(this.handleSendCashout);
    };

    handleSendCashout = (e?: React.FormEvent<HTMLFormElement>): void => {
        if (e) {
            e.preventDefault();
            hideLastPopup();
        }

        this.setState({submitting: true});

        this.props.cashoutsStore.sendCashout().finally(() => this.setState({submitting: false}));
    };

    amountChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.props.cashoutsStore.amountChanged(e);
    };

    handleDetails = (params: DetailInterface, stateName: 'cashout_detail_attributes'): void => {
        this.props.cashoutsStore.updateDetails({[stateName]: params});
    };

    handleWalletDetails = (params: WalletDetailInterface, stateName: 'wallet_detail_attributes'): void => {
        this.props.cashoutsStore.updateWalletDetails({[stateName]: params});
    };

    updateWalletAccount = (account: string): void => {
        this.props.cashoutsStore.updateWalletAccount(account);
    };

    screenshotUploaded = (screenshot: ScreenshotType): void => {
        this.props.cashoutsStore.updateScreenshot(screenshot);
        this.updateWalletAccount(screenshot ? screenshot.id.toString() : null);
    };

    deactivateWallet = (id: number, account: string): void => {
        modalConfirm(
            I18n.t('deactivate_wallet_message', {wallet: account}),
            () => {
                new Post({
                    params: {id},
                    url: getLinkPath('/cashouts/deactivate_wallet')
                }).execute()
                    .then(response => {
                        if (response.ok) {
                            response.json().then(data => {
                                if (data.success) {
                                    this.props.cashoutsStore.setWallets(data.wallets);
                                    this.props.cashoutsStore.selectWallet(null);
                                }
                            });
                        }
                    });
            }
        );
    };

    handleKeyPress = (e: React.KeyboardEvent<HTMLFormElement>): void => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleStartCashout(e as unknown as React.FormEvent<HTMLFormElement>);
        }
    };

    render(): React.JSX.Element {
        const {china_dictionaries, china_banks, cashoutsStore} = this.props;
        const wallets = cashoutsStore.getSelectedWallets();
        const {amount, errors, selectedSystem, selectedWallet, screenshot} = cashoutsStore.data;
        const {exchangeAmount, selectWallet, BTCMessage} = cashoutsStore;
        const accountLabel = translatedKeyLangs(selectedSystem, 'account_label') || I18n.t('user.ewallet_number');
        const accountValue = get(
            this.state,
            'wallet_attributes.account',
            selectedWallet && selectedWallet.masked_account
        );
        return <div className="cashout__info">
            <form
                className="cashout-form form form-egb used-chosen"
                onSubmit={this.handleStartCashout}
                onKeyPress={this.handleKeyPress}
            >
                <div
                    className="
                    cashout-form__logo
                    payment-systems__cashout__item
                    payment-systems__cashout__item__selectedSystem"
                >
                    <div className="cashout__system-content">
                        <SystemCard system={selectedSystem}/>
                    </div>
                </div>
                <div>
                    {selectedSystem.verification && <VerifiedStatusMessage message={BTCMessage}/>}
                    {!selectedSystem.verification && !selectedSystem.enabled && cashoutDisabledMessage(selectedSystem)}
                    {selectedSystem.enabled && <Fragment>
                        <div className="form__row">
                            <div className="form__row-item">
                                {selectedSystem.p2p &&
                                <CashoutDetails
                                    system={selectedSystem}
                                    handleDetails={this.handleDetails}
                                    handleWalletDetails={this.handleWalletDetails}
                                    errors={errors}
                                />}
                                {wallets.length > 0 && !selectedSystem.payment_screenshot && <Wallets
                                    accountLabel={accountLabel}
                                    selectedSystem={selectedSystem}
                                    wallets={wallets}
                                    deactivateWallet={this.deactivateWallet.bind(this)}
                                    selectWallet={selectWallet}
                                />}
                            </div>
                        </div>
                        {!selectedWallet && selectedSystem.input_wallet && !selectedSystem.payment_screenshot &&
                        <div className="form__row">
                            <div className="form__row-item">
                                <InputWallet
                                    accountLabel={accountLabel}
                                    selectedSystem={selectedSystem}
                                    errors={errors}
                                    accountValue={accountValue}
                                    updateWalletAccount={this.updateWalletAccount}
                                />
                            </div>
                        </div>}
                        <div className="form__row">
                            <div className="form__row-item">
                                <label className="form__row-label" htmlFor="amount">
                                    {I18n.t('amount')}, $ {exchangeAmount()}
                                </label>
                                <div className={`field ${errors.amount ? 'field-error' : ''}`}>
                                    <div className="field__wrap">
                                        <input
                                            id="amount"
                                            type="text"
                                            className="field__input field__input_text"
                                            value={amount}
                                            onChange={this.amountChanged}
                                        />
                                        {errors.amount && <InputError error={errors.amount}/>}
                                    </div>
                                    <Limits cashoutsStore={cashoutsStore}/>
                                </div>
                                {!selectedSystem.p2p && <CashoutDetails
                                    system={selectedSystem}
                                    handleDetails={this.handleDetails}
                                    handleWalletDetails={this.handleWalletDetails}
                                    selectedWallet={selectedWallet}
                                    china_banks={china_banks}
                                    china_dictionaries={china_dictionaries}
                                    errors={errors}
                                />}
                            </div>
                        </div>
                        {selectedSystem.payment_screenshot && <div className="form__row form__row-screenshot">
                            <div className="form__row-item">
                                <UploadScreenshot
                                    onUpload={this.screenshotUploaded}
                                    screenshot={screenshot || Boolean(selectedWallet)}
                                />
                            </div>
                        </div>}
                        {this.state.submitting
                            ? <div className="loader"/>
                            : <div className="form__row form__row-submit">
                                <div className="form__row-item">
                                    <button type="submit" className="btn">
                                        {I18n.t('user.submit_cashout')}
                                    </button>
                                </div>
                            </div>}
                    </Fragment>}
                </div>
            </form>
        </div>;
    }
}
