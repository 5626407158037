import React, {MouseEvent, PureComponent} from 'react';

interface Props {
    title: string
    count: number
    onClickHandler: () => void
    enabled?: boolean
}

export default class GiftLink extends PureComponent<Props> {
    onClick = (e: MouseEvent<HTMLAnchorElement>): void => {
        e && e.preventDefault();

        this.props.onClickHandler();
    };

    render(): React.ReactNode {
        const {title, count, enabled} = this.props;
        return <a
            className={enabled || count ? '' : 'disabled'}
            href="#"
            onClick={this.onClick}>
            {title} {count ? `(${count})` : null}
        </a>;
    }
}
