import React, {Component, RefObject} from 'react';
import {CasinoGameInterface, CasinoGamesStoreProps} from 'app/interfaces/stores/CasinoGamesStoreInterfaces';
import {inject, observer} from 'mobx-react';
import IframeViewControls from 'app/components/pages/casino/iframe-view/IframeViewControls';
import SmartLoader from 'app/components/reusable/SmartLoader';
import {isDesktop} from 'app/utils';
import {ONE_SECOND_MS} from 'app/store/constants/storeConstants';


interface IframeViewState {
    popup: boolean
    minimized: boolean
    isFullScreen: boolean
}

interface IframeViewProps extends CasinoGamesStoreProps{
    activeCasinoGame: CasinoGameInterface
}

@inject('casinoGamesStore')
@observer
export default class IframeView extends Component<IframeViewProps, IframeViewState> {
    iframeRef: RefObject<HTMLIFrameElement> = React.createRef();

    state = {isFullScreen: false, minimized: false, popup: !isDesktop()};

    iframeMainRef: RefObject<HTMLIFrameElement> = React.createRef();


    componentDidUpdate(): void {
        const {casinoGamesStore: {gameLaunchOptions, isGameActive, reloadPage, changeReloadPage}} = this.props;
        const {current} = this.iframeRef as RefObject<HTMLIFrameElement>;

        if (!current) {
            return;
        }

        if (reloadPage && gameLaunchOptions) {
            changeReloadPage(false);
            this.handleIframeLoad();
        }

        if (!gameLaunchOptions && !isGameActive) {
            current.contentWindow?.location.reload();
        }
    }

    handleIframeLoad = (): void => {
        const {casinoGamesStore: {gameLaunchOptions}} = this.props;
        const launchOptions = JSON.parse(JSON.stringify(gameLaunchOptions));
        const {current} = this.iframeRef as RefObject<HTMLIFrameElement>;

        current.contentWindow?.postMessage({data: launchOptions}, '*');

        setTimeout(() => {
            const iframe = (document?.querySelector('.iframe-view') as HTMLIFrameElement);
            const providerIframeCollection = iframe.contentWindow.document.body.getElementsByTagName('iframe');

            if (providerIframeCollection.length === 0) {
                current.contentWindow?.postMessage({data: launchOptions}, '*');
            }
        }, ONE_SECOND_MS);
    };

    handlerPopup = (): void => {
        const {popup, isFullScreen} = this.state;

        if (popup) {
            if (isFullScreen) {
                this.exitFullscreen();
            }
            this.setState({isFullScreen: false, minimized: false, popup: false});
        } else {
            if (isDesktop()) {
                this.requestFullscreen();
                this.setState({isFullScreen: true});
            }
            this.setState({minimized: false, popup: true});
        }
    };

    handlerMinimize = (): void => {
        if (this.state.isFullScreen) {
            this.exitFullscreen();
        }

        this.setState({isFullScreen: false, minimized: !this.state.minimized, popup: false});
    };

    exitFullscreen = (): void => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    };

    requestFullscreen = (): void => {
        const {current} = this.iframeMainRef as RefObject<HTMLIFrameElement>;

        if (current.requestFullscreen) {
            current.requestFullscreen();
        } else if (current.webkitRequestFullscreen) {
            current.webkitRequestFullscreen();
        } else if (current.mozRequestFullScreen) {
            current.mozRequestFullScreen();
        } else if (current.msRequestFullscreen) {
            current.msRequestFullscreen();
        }
    };

    handleClose = (): void => {
        this.props.casinoGamesStore.closeModal();

        if (this.state.isFullScreen) {
            this.exitFullscreen();
        }
        this.setState({isFullScreen: false, minimized: false, popup: !isDesktop()});
    };

    render(): React.ReactNode {
        const {casinoGamesStore: {gameLaunchOptions, isGameActive, activeGameToFavorite}, activeCasinoGame} = this.props;
        const {minimized, popup} = this.state;
        const viewClass = `iframe-view-wrapper ${popup ? 'popup-active' : ''} ${minimized ? 'minimizing-active' : ''}`;

        return isGameActive && <div id="iframe_view" className={viewClass} ref={this.iframeMainRef}>
            <div className="iframe-view-inner">
                <IframeViewControls
                    handleMinimize={this.handlerMinimize}
                    handlePopup={this.handlerPopup}
                    handleClose={this.handleClose}
                    activeCasinoGame={activeCasinoGame}
                    activeGameToFavorite={activeGameToFavorite}
                    popup={popup}
                    minimized={minimized}
                />
                <div className="iframe-view-content">
                    {!gameLaunchOptions && <SmartLoader/>}
                    <iframe className="iframe-view" src="/casino/game_view" ref={this.iframeRef}/>
                </div>
            </div>
        </div>;
    }
}
