import React from 'react';
import {ToastContainer, ToastPosition, Zoom} from 'react-toastify';
import {CloseButton} from './CloseButton';

const AUTO_CLOSE_TIME_TOAST = 8000;

interface ToastContainerCustomizeProps {
    containerId: string
    draggable: boolean
    position: ToastPosition
    className?: string
}

export const ToastContainerCustomize = (props: ToastContainerCustomizeProps): React.ReactElement => <ToastContainer
    position={props.position}
    className={props.className}
    autoClose={AUTO_CLOSE_TIME_TOAST}
    transition={Zoom}
    hideProgressBar
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss={false}
    draggable={props.draggable}
    pauseOnHover
    enableMultiContainer
    containerId={props.containerId}
    closeButton={<CloseButton/>}
    icon={false}
/>;
