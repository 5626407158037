export enum StoryType {
    BASIC = 'basic',
    SHOP_PURCHASE = 'shop_purchase',
    CASINO_FIRST_BET = 'casino_first_bet',
    FIRST_BET = 'first_bet',
    GIVEAWAY = 'giveaway',
    FIRST_DEPOSIT = 'first_deposit',
    EMAIL_CONFIRM = 'email_confirm',
    CLOSE_BUTTON = 'close_button'
}
