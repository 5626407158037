import React, {PureComponent} from 'react';

export default class HeroSlotCount extends PureComponent {
    get svg() {
        const {showHeroSlotCountOrange} = this.props;
        const svg = '#svg-inventory-count';

        if (showHeroSlotCountOrange) {
            return `${svg}-orange`;
        }

        return svg;
    }

    render() {
        const {total_inventory_items, quantity} = this.props;

        if ((quantity || total_inventory_items || 0) <= 1) {
            return null;
        }

        return <div className="hero__slot--count">
            <svg>
                <use xlinkHref={this.svg}/>
            </svg>
            <span className="hero__slot--count-total">
                {quantity || total_inventory_items}
            </span>
        </div>;
    }
}
