import {streamMinimize} from 'app/components/streams/stream_functions';
import {addGaEvent} from 'app/ga_methods';
import Cookies from 'js-cookie';

const MULTIPLIER = 5,
    YEAR = 365;

const minimizeStream = (): void => {
    Cookies.set('pin', true, {expires: YEAR * MULTIPLIER});
    streamMinimize();
    addGaEvent('Stream', 'minimize');
};

export default minimizeStream;
