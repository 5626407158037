import React, {Component} from 'react';
import {i18n_t} from 'app/i18n';
import {betMoney, getLinkPath, localDateTimeFromISO} from 'app/utils';
import {Link} from 'react-router-dom';
import {fixed2int} from 'app/utils/fixed';
import {inject, observer} from 'mobx-react';
import {DepositsStoreProps} from 'app/store/DepositsStore';
import {isoCurrencyToHtml} from 'app/components/deposit/depositExchangeAmount';
import {exchangedAmount} from 'app/utils/deposits/exchangedAmount';
import {roundAmount} from 'app/utils/deposits/roundAmount';
import {CURRENCY_TO_ROUND} from 'app/utils/deposits/roundByCurrency';

interface LimitsProps extends DepositsStoreProps {
    currency_rate?: number,
    currency?: string
}

@inject('depositsStore')
@observer
export default class Limits extends Component<LimitsProps> {
    minLimit = (): string => {
        const system = this.props.depositsStore.currentPaymentSystem;
        const {currency_rate, currency} = this.props;
        const res = CURRENCY_TO_ROUND.includes(currency) && currency_rate
            ? roundAmount(Number(system.min_limit), currency, this.props.depositsStore.rates)
            : exchangedAmount(currency_rate, system.min_limit);
        return fixed2int(res);
    };

    maxLimit = (): string => {
        const system = this.props.depositsStore.currentPaymentSystem;
        const {currency_rate, currency} = this.props;
        const res = CURRENCY_TO_ROUND.includes(currency) && currency_rate
            ? roundAmount(Number(system.max_limit), currency, this.props.depositsStore.rates)
            : exchangedAmount(currency_rate, system.max_limit);
        return fixed2int(res);
    };

    setMinAmount = (): void => this.props.depositsStore.updateAmount(this.minLimit());

    setMaxAmount = (): void => this.props.depositsStore.updateAmount(this.maxLimit());

    render(): JSX.Element {
        const {availableDepositLimit} = this.props.depositsStore;
        const system = this.props.depositsStore.currentPaymentSystem;
        const {currency} = this.props;
        const currency_sign = currency ? `${isoCurrencyToHtml(currency)}` : '$';
        return system && <label className="control-label deposit-form__limits">
            {system.min_limit && <>
                <span className="deposit-form__limits-item">
                    {i18n_t('min_dot')}: <span
                        className="label-interval"
                        onClick={this.setMinAmount}
                    >
                        {currency_sign}{betMoney({}, this.minLimit(), false, true)}
                    </span>
                </span>
                {Number(system.max_limit) > 0 && <span className="deposit-form__limits-item">
                    {i18n_t('max_dot')}: <span
                        className="label-interval"
                        onClick={this.setMaxAmount}
                    >
                        {currency_sign}{betMoney({}, this.maxLimit(), false, true)}
                    </span>
                </span>}
            </>}
            {availableDepositLimit && <div>
                <span>
                    {`${i18n_t('available_deposit_limit', {
                        amount: availableDepositLimit.amount,
                        date: localDateTimeFromISO(availableDepositLimit.date)
                    })}. `}
                </span>
                <span>
                    <Link to={getLinkPath('/my_profile#deposit-limit')}>{i18n_t('change')}</Link>
                </span>
            </div>}
        </label>;
    }
}
