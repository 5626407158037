import React, {PureComponent} from 'react';
import Image from 'app/helpers/Image';

export default class Smiles extends PureComponent {
    render() {
        const {smiles = []} = this.props;

        return <div className="hero__slot-wrap">
            {smiles.map((smile, index) => <Smile key={index} {...smile}/>)}
        </div>;
    }
}

class Smile extends PureComponent {
    render() {
        return <div className="hero__slot">
            <div className="hero__slot--img">
                <Image {...this.props}/>
            </div>
        </div>;
    }
}
