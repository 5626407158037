import {TableStore} from 'app/store/TableStore';
import {computed} from 'mobx';
import LineInterface from 'app/interfaces/LineInterface';

export class IncubatorStore extends TableStore {
    incubator = true;

    defaultBetType = 'live';

    @computed
    get userCreatedLines(): LineInterface[] {
        return this.lines.filter(line => line.isCreator);
    }
}
