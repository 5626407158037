import React, {PureComponent, ReactElement, ReactNode, Ref} from 'react';
import DepositAmount from './DepositAmount';
import DepositPromoCode from './DepositPromoCode';
import DepositSubmit from './DepositSubmit';
import SelectBonusType from 'app/components/user/SelectBonusType';
import {inject, observer} from 'mobx-react';
import {UserStore} from 'app/store/UserStore';

interface DepositSystemFormProps {
    noAmount?: boolean
    name?: string
    children: ReactElement | ReactElement[]
    formRef?: Ref<HTMLFormElement>
    url?: string
    submitHandler?: () => void
    isHiddenForRedirect?: boolean
    onSubmit: () => void
    userStore?: UserStore

}

@inject('userStore')
@observer
export default class DepositSystemForm extends PureComponent<DepositSystemFormProps> {
    elements(): ReactNode {
        const {noAmount, name, children, onSubmit, isHiddenForRedirect, userStore} = this.props;
        return <>
            {!noAmount && <DepositAmount
                isHiddenForRedirect={isHiddenForRedirect}
                name={name}
            />}

            {children}

            <DepositPromoCode/>
            {!userStore.user.first_deposit && <SelectBonusType/>}
            <DepositSubmit onSubmit={onSubmit}/>
        </>;
    }

    form(): ReactNode {
        const {formRef, url, submitHandler} = this.props;
        return <form method="POST" ref={formRef} action={url} onSubmit={submitHandler}>
            {this.elements()}
        </form>;
    }

    render(): ReactNode {
        const {formRef} = this.props;
        return <div className="deposit-form__inputs">
            <div className="deposit-form__content">
                {formRef ? this.form() : this.elements()}
            </div>
        </div>;
    }
}
