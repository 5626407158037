import React from 'react';
import {isDesktop} from 'app/utils';
import {PopupDialog} from 'app/utils/modals/PopupDialog';

export default class FormCover extends React.PureComponent {
    render() {
        return this.props.seo
            ? <div className="match-details" itemScope itemType="http://schema.org/SportsEvent">{this.props.children}</div>
            : <PopupDialog id={this.props.id} setFocus={isDesktop()}>
                <div className="match-details" itemScope itemType="http://schema.org/SportsEvent">{this.props.children}</div>
            </PopupDialog>;
    }
}
