import React, {PureComponent} from 'react';
import {artifactShopLink} from 'app/components/my_profile/artifacts/utils';
import {translatedName} from 'app/utils/translations_helper';
import {Link} from 'react-router-dom';

export default class PrizeLink extends PureComponent {
    render() {
        const {recipe_id} = this.props;

        if (recipe_id) {
            return <Link to={artifactShopLink({id: recipe_id})}>
                {translatedName(this.props)}
            </Link>;
        }
        return <span>{translatedName(this.props)} </span>;
    }
}
