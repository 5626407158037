import {depositExchangedAmount} from '../deposit/depositExchangeAmount';
import {RatesType} from 'app/store/DepositsStore';

const ALMOST_EQUAL_CHAR_CODE = 8776;

export const cashoutExchangeAmount =
    (rates: RatesType, amount: string, exchangeCurrency: string): string => depositExchangedAmount(
        amount,
        exchangeCurrency,
        rates
    ).replace('=', String.fromCharCode(ALMOST_EQUAL_CHAR_CODE));
