import React, {Component, ReactNode, ReactElement} from 'react';
import I18n from 'app/i18n';
import {translated} from 'app/utils/translations_helper';
import HelpSupport from './HelpSupport';
import SuggestedDeposit from './SuggestedDeposit';
import DepositDisabledMessage from './DepositDisabledMessage';
import SystemCard from '../SystemCard';
import {DepositsStoreProps} from 'app/store/DepositsStore';
import {inject, observer} from 'mobx-react';

interface DepositSystemProps extends DepositsStoreProps {
    children: ReactElement | ReactElement[]
}

@inject('depositsStore')
@observer
export default class DepositSystem extends Component<DepositSystemProps> {
    render(): ReactNode {
        const system = this.props.depositsStore.currentPaymentSystem;
        const {children} = this.props;
        const {p2p, banned, message_reject, verification} = system;

        if (verification) {
            return <div className="deposit-form"><h3>{I18n.t('user.verification')}</h3></div>;
        }
        return <>
            <SuggestedDeposit/>
            <div className="deposit-form form form-egb">
                <div
                    className="
                    deposit-form__logo payment-systems__deposit__item
                    payment-systems__deposit__item__selectedSystem
                    ">
                    <SystemCard system={system}/>
                </div>
                <div className="deposit-form__info">
                    <DepositDisabledMessage banned={banned} message_reject={message_reject}/>
                    {!banned && <>
                        <div
                            className="deposit-form__description"
                            dangerouslySetInnerHTML={{__html: translated(system, 'description')}}
                        />
                        {children}
                    </>}
                </div>
            </div>
            {!p2p && <div className="left-inner-padding box-p2p_type-2"><HelpSupport/></div>}
        </>;
    }
}
