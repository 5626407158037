import Input from 'app/components/reusable/Input';
import InputErrorWrapper from 'app/components/reusable/InputErrorWrapper';
import SmartSelect from 'app/components/reusable/SmartSelect';
import I18n from 'app/i18n';
import {hideLastPopup} from 'app/utils/modals/ModalsService';
import {CashoutsStore} from 'app/store/CashoutsStore';
import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';

interface PropsInterface {
    cashoutsStore?: CashoutsStore
}

interface StateInterface {
    submitting: boolean
}

@inject('cashoutsStore')
@observer
export default class CombinedCashout extends Component<PropsInterface, StateInterface> {
    state = {submitting: false};

    selectWallet = (ids: string[]): void => {
        const {cashoutsStore} = this.props;
        const currentWallets = [];
        ids.forEach((id: number | string) => {
            currentWallets.push(cashoutsStore.findWalletById(Number(id)));
        });
        cashoutsStore.selectWallets(currentWallets);
    };

    handleAmountChange = (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>): void => {
        this.props.cashoutsStore.amountChanged(e);
    };

    handleSendCashout = (e: React.FormEvent<HTMLFormElement>): void => {
        if (e) {
            e.preventDefault();
            hideLastPopup();
        }

        this.setState({submitting: true});

        const {cashoutsStore} = this.props;

        cashoutsStore.sendCashout('CombinedCashout').then(response => {
            if (response.success && cashoutsStore.data.selectedWallets.length > 0 &&
                !cashoutsStore.data.selectedSystem.payment_screenshot) {
                cashoutsStore.selectWallets([]);
                cashoutsStore.selectFirstWallet();
            }
        }).finally(() => this.setState({submitting: false}));
    };

    handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        this.props.cashoutsStore.startCashout(this.handleSendCashout, 'CombinedCashout');
    };

    render(): React.ReactNode {
        const {combinedCashoutsWallets} = this.props.cashoutsStore;
        const {amount, errors, selectedWallets, systems} = this.props.cashoutsStore.data;

        return <form className="combined-cashouts" onSubmit={this.handleSubmit}>
            <h3 className="payment-systems__cashout__heading">{I18n.t('combined_cashout')}</h3>
            <div className="combined-cashouts-form">
                <div className="row">
                    <SmartSelect
                        multiple
                        collection={combinedCashoutsWallets.map(wallet => ({
                            text: systems[wallet.payment_system_id].name,
                            text2: wallet.masked_account,
                            value: wallet.id
                        }))}
                        onChange={this.selectWallet}
                        value={selectedWallets.map(wallet => wallet.id.toString())}
                        placeholder_text_multiple={I18n.t('choose_payment_method')}
                        errors={errors.payment_systems}
                        empty_collection_text={I18n.t('user.cashout_deposit_first')}
                    />
                </div>
                <div className="row">
                    <InputErrorWrapper errors={errors} attributeName="amount">
                        <Input
                            value={amount}
                            placeholder={I18n.t('enter_amount')}
                            onChange={this.handleAmountChange}
                            onKeyUp={this.handleAmountChange}
                        />
                    </InputErrorWrapper>
                </div>
                <div className="row">
                    <button type="submit" className="button-adaptive">
                        {I18n.t('user.submit_cashout')}
                    </button>
                </div>
            </div>
        </form>;
    }
}
