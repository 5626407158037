import React, {PureComponent, ReactNode} from 'react';

export default class ChatSmilesScroll extends PureComponent<{children?: React.ReactNode}> {
    rootElement: React.RefObject<HTMLDivElement> = React.createRef();

    render(): ReactNode {
        return <div ref={this.rootElement} className="chat__smile-list-scroll">
            {this.props.children}
        </div>;
    }
}
