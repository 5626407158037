import $ from 'jquery';
import React, {Component, ChangeEvent, KeyboardEvent, ReactNode} from 'react';
import I18n from 'app/i18n';
import DepositSystem from './form/DepositSystem';
import DepositSystemForm from './form/DepositSystemForm';
import Post from 'app/utils/Post';
import logEvent from 'app/amplitude/log_event';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import {userStore} from 'app/store/global';
import InputError, {fieldErrorClass} from './InputError';
import {inject, observer} from 'mobx-react';
import {DepositsStoreProps} from 'app/store/DepositsStore';

const CODE_LENGTH_1 = 4;
const CODE_LENGTH_2 = 9;

@inject('depositsStore')
@observer
export default class GiftCardDeposit extends Component<DepositsStoreProps> {
    paste = async function(): Promise<void> {
        const text = await navigator.clipboard?.readText();
        $('[name=code1]').val(text);
        this.props.depositsStore.updateGiftCardCode(text);
    };

    updateGiftCardCode = (event: ChangeEvent<HTMLInputElement>): void => {
        this.props.depositsStore.updateGiftCardCode(event.target.value);
    };


    giftCardCodeKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
        const target = event.target as HTMLInputElement;

        if (target.value.length === CODE_LENGTH_1 || target.value.length === CODE_LENGTH_2) {
            target.value += '-';
        }
    };

    depositSubmitGiftCard = (): void => {
        const {giftCardCode, promoCode, loadMoreHistory} = this.props.depositsStore;
        this.props.depositsStore.startSubmitting();
        new Post({
            params: {code: giftCardCode, promo_code: promoCode},
            url: '/deposits/gift_card'
        }).execute()
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    this.props.depositsStore.resetInputs();
                    toastSuccess(response.message || 'Success');
                    loadMoreHistory(true);
                    userStore.update({user: response.user});
                } else {
                    if (response.message) {
                        toastError(response.message);
                    }
                    this.props.depositsStore.stopSubmitting(response.errors);
                    logEvent('INVOICE_FORM_FAILURE', {responseJSON: {errors: response.message}});
                }
            })
            .finally(() => this.props.depositsStore.stopSubmitting());
    };

    render(): ReactNode {
        const system = this.props.depositsStore.currentPaymentSystem;
        const {amount_input, verification, cards} = system;
        const {giftCardCode, errors: {code}} = this.props.depositsStore;
        return <div>
            <DepositSystem>
                {verification
                    ? <>{I18n.t('user.verification')}</>
                    : <DepositSystemForm noAmount={!amount_input} onSubmit={this.depositSubmitGiftCard}>
                        <div className="form__row">
                            <div className="form__row-item">
                                <label className="form__row-label">{I18n.t('user.gift_card_code_label')}</label>
                                <div className="deposit-form__code-field paste_from_clipboard-wrap">
                                    <div className={fieldErrorClass(Boolean(code))}>
                                        <div className="deposit-form__code-field-input field__wrap">
                                            <input
                                                className="field__input"
                                                maxLength={14}
                                                autoComplete="off"
                                                onKeyPress={this.giftCardCodeKeyUp}
                                                onChange={this.updateGiftCardCode}
                                                value={giftCardCode}
                                            />
                                            {code && <InputError error={code}/>}
                                        </div>
                                    </div>
                                    <div className="paste_from_clipboard">
                                        <label className="deposit__label">
                                            <button className="btn-link" onClick={this.paste.bind(this)}>
                                                <svg height="13px" width="11px">
                                                    <use xlinkHref="#svg-paste-icon"/>
                                                </svg>
                                                {I18n.t('paste_from_clipboard')}
                                            </button>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DepositSystemForm>}
            </DepositSystem>
            {!verification && cards && <div className="deposit__gift-list">
                {cards.map((card, i) => <a
                    key={`card${i}`}
                    href={card.link}
                    rel="noreferrer noopener"
                    target="_blank"
                    title={card.title}
                    className="deposit__gift-item"
                >
                    <img src={card.image} alt={card.title} className="deposit__gift-img"/>
                </a>)}
            </div>}
        </div>;
    }
}
