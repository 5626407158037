export * from './consts/chat-images';
export * from './consts/common-images';
export {default as BEER_IMAGE} from './consts/beer-image';
export {default as COCKTAIL_IMAGE} from './consts/cocktail-image';
export {default as B_DAY_GIFT} from './consts/b-day-gift';
export {default as COMPENDIUM_ASSETS} from './consts/compendium-images';
export {default as GAMER_NO_IMAGE} from 'images/gamer-logo.svg';
export {default as GIFT_IMAGE} from 'images/gifts-icon.png';
export {default as HIDDEN_TEMPLE_EN} from 'images/hidden_temple_en.jpg';
export {default as HIDDEN_TEMPLE_RU} from 'images/hidden_temple_ru.jpg';
export {default as LOGO_IMAGE} from 'images/logo-egb.svg';
export {default as LOGO_IMAGE_N} from 'images/logo-egb-n.svg';
export {default as NET_LOGO_IMAGE} from 'images/net-logo.svg';
export {default as TEAM_NO_IMAGE} from 'images/team-logo.svg';
export {default as HERO_PRIVATE} from 'images/hero/hero-private.png';

export {LOCALES} from './consts/locales';

const {FILTER_GAME_CS, FILTER_GAME_DOTA} = window.SITE_DATA;

export {FILTER_GAME_CS, FILTER_GAME_DOTA};

export const TABLE_LABEL_TYPES_ARROWS = '';
