import React, {PureComponent} from 'react';
import {HERO_PRIVATE} from 'app/consts';
import {Link} from 'react-router-dom';
import {getLinkPath} from 'app/utils/link_helper';
import I18n from 'app/i18n';

export default class PrivateInventory extends PureComponent {
    render() {
        return <div className="content__holder">
            <div className="inventory-private">
                <div className="inventory-private__text-wrap">
                    <div className="inventory-private__text">
                        {I18n.t('inventory_is_set_to_private', {name: this.props.name})}
                        <br/>
                        {I18n.t('you_cannot_check_this_profile_now')}
                    </div>
                    <div className="inventory-private__details">
                        <div className="inventory-private__details-label">
                            {I18n.t('for_more_details')}
                        </div>
                        <div className="inventory-private__details-link">
                            <Link to={getLinkPath('/faq/my-hero-and-skins')}>
                                {`${window.location.hostname}${getLinkPath('/faq/my-hero-and-skins')}`}
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="inventory-private__img">
                    <img
                        src={HERO_PRIVATE}
                        alt="Private hero image"
                        title="Private hero"
                    />
                </div>
            </div>
        </div>;
    }
}
