import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import BetSlipController from 'app/components/bet_slip/BetSlipController';

interface BetsGamesNumberProps {
    controller?: BetSlipController
}

@inject('controller')
@observer
export default class BetsGamesNumber extends Component<BetsGamesNumberProps> {
    render(): ReactNode {
        const {controller: {countBets, removeAllGames}} = this.props;
        const gamerNumberMessage = '';
        return <div className="bets-games-number">
            {gamerNumberMessage
                ? <div className="message">
                    <p>{gamerNumberMessage}</p>
                </div>
                : <div className="top">
                    <span className="bets-number">{I18n.t('description_count_bets', {count: countBets})}</span>
                    <button onClick={removeAllGames} className="button-remove-all-bets">{I18n.t('remove_all')}</button>
                </div>}
        </div>;
    }
}
