export const ONE = '1';
export const TWO = '2';
export const THREE = '3';
export const FOUR = '4';
export const FIVE = '5';
export const SIX = '6';
export const SEVEN = '7';
export const EIGHT = '8';
export const NINE = '9';
export const TEN = '10';
export const JACK = 'J';
export const QUEEN = 'Q';
export const KING = 'K';
export const ACE = 'A';

export const SPADES = 'SPADES';
export const DIAMONDS = 'DIAMONDS';
export const HEARTS = 'HEARTS';
export const CLUBS = 'CLUBS';

/* eslint sort-keys: 0 */

export const CARDS = {
    ONE,
    TWO,
    THREE,
    FOUR,
    FIVE,
    SIX,
    SEVEN,
    EIGHT,
    NINE,
    TEN,
    JACK,
    QUEEN,
    KING,
    ACE
};
