import React from 'react';
import {secondsToShortHoursFormat} from 'app/utils';

interface TimeLeftProps {
    time_left: number
}

const TimeLeft: React.FC<TimeLeftProps> = ({time_left}) => time_left
    ? <div className="artifact-user__timer">{secondsToShortHoursFormat(time_left)}</div>
    : null;

export default TimeLeft;
