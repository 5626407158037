import $ from 'jquery';
import React from 'react';
import {userSignedIn, flagImage, isDesktop} from 'app/utils';
import {modalAlert} from 'app/utils/modals/popups';
import logEvent from 'app/amplitude/log_event';
import {AVAILABLE_LOCALES, setLanguageStorage} from 'app/utils/Language';
import {urlByLang} from 'app/utils/langs/urlByLang';
import {switchLanguage} from 'app/utils/langs/switchLanguage';

export default class ListOfLanguages extends React.Component {
    rootElement = React.createRef();

    componentDidMount() {
        const buttonOpener = $('.js-lang-opener', this.rootElement.current);
        const container = $('.js-lang-list', this.rootElement.current);

        buttonOpener.on('click', () => {
            container.stop().slideToggle();
            buttonOpener.toggleClass('opener-active');
        });
        $('body').on('click', e => {
            if ($(e.target).closest('.js-lang-opener').length === 0 && container.is(':visible')) {
                container.slideUp();
                buttonOpener.removeClass('opener-active');
            }
        });
    }

    changeLocaleHandler(locale, e) {
        if (userSignedIn()) {
            $.ajax('/user/site_lang', {
                data: {user: {site_lang: locale.lang}},
                statusCode: {
                    200: () => {
                        logEvent('LANGUAGE_CHANGE', {language: locale.lang});
                        setLanguageStorage(locale.lang);
                        switchLanguage(e, locale.lang);
                    },
                    422: error => modalAlert(Object.values(error.responseJSON.errors)[0])
                },
                type: 'POST'
            });
        } else {
            setLanguageStorage(locale.lang);
            switchLanguage(e, locale.lang);
        }
        e && e.preventDefault();
    }

    render() {
        const currentLocale = AVAILABLE_LOCALES.find(locale => locale.lang === window.CURRENT_LOCALE);

        return <div className="lang" ref={this.rootElement}>
            <div className="lang__opener js-lang-opener">
                <span className="lang__flag-wrap">{flagImage(currentLocale.flag, 'lang__flag')}</span>
                <span className="js-lang-code">{currentLocale.lang.toUpperCase()}</span>
                <span className="lang__opener-icon">
                    <svg height="6px" width="8px">
                        <use xlinkHref="#svg-arr-select-small"/>
                    </svg>
                </span>
            </div>
            <div className={isDesktop() ? 'lang__list js-lang-list' : 'lang__list-mobile js-lang-list'}>
                {AVAILABLE_LOCALES.map(locale => <a
                    key={locale.lang}
                    className="lang__item"
                    onClick={this.changeLocaleHandler.bind(this, locale)}
                    href={`/${urlByLang(locale.lang)}`}
                >
                    <span className="lang__flag-wrap">{flagImage(locale.flag === 'en'
                        ? 'gb'
                        : locale.flag, 'lang__flag')}
                    </span>
                    <span className="lang__name">{locale.name}</span>
                </a>)}
            </div>
        </div>;
    }
}
