import {setElementCookies} from 'app/utils';
import {urlByLang} from 'app/utils/langs/urlByLang';
import {replaceLocale} from 'app/utils/langs/replaceLocale';

export const switchLanguage = (e: Event, lang: string): void => {
    const path = location.pathname;

    if (window.CURRENT_LOCALE === lang) {
        return;
    }
    location.pathname = window.CURRENT_LOCALE === window.DEFAULT_LOCALE
        ? `/${urlByLang(lang)}${path}`
        : replaceLocale(path, lang);
    setElementCookies('lang', lang);
    e && e.preventDefault();
};
