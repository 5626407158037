import React, {Component, ReactNode} from 'react';

import {coefColorClass} from 'app/lines/coefColorClass';
import {fixed3} from 'app/utils/fixed';
import BetSlipTimer from 'app/components/bet_slip/BetSlipTimer';
import {inject, observer} from 'mobx-react';
import BetSlipInputAmountContainer from 'app/components/bet_slip/amount_money/BetSlipInputAmount.container';
import {BetSlipStore} from 'app/store/BetSlipStore';
import BetSlipInputWinContainer from 'app/components/bet_slip/amount_money/BetSlipInputWin.container';
import BetSlipInputCoefContainer from 'app/components/bet_slip/amount_money/BetSlipInputCoef.container';

interface BetSlipAmountMoneyProps {
    betSlipStore?: BetSlipStore
}

@inject('betSlipStore')
@observer
export default class BetSlipAmount extends Component<BetSlipAmountMoneyProps> {
    render(): ReactNode {
        const {formCoef, coefIncreasedForm, express, incubator2, incubator2WithCoef, selectedLine} = this.props.betSlipStore;
        return <div className="bet-slip-amount-money">
            <BetSlipInputAmountContainer/>
            <div className="cell-box">
                {incubator2 && !incubator2WithCoef
                    ? <BetSlipInputCoefContainer/>
                    : <div className="center">
                        <span className={`bet-slip-coefficient${coefColorClass(coefIncreasedForm)}`}>
                            {fixed3(formCoef)}
                        </span>
                        <BetSlipTimer express={express} selectedLine={selectedLine}/>
                    </div>
                }
            </div>
            <BetSlipInputWinContainer/>
        </div>;
    }
}
