import React, {Component} from 'react';
import {getLinkPath} from 'app/utils/link_helper';
import {inject, observer} from 'mobx-react';
import $ from 'jquery';
import SmartPageLoader from 'app/components/reusable/smart_loaders/SmartPageLoader';

const ScrolledPageContent = inject('pageContentStore')(observer(class ScrolledPageContentImpl extends Component {
    root = React.createRef();

    state = {
        content: null,
        loading: false
    };

    UNSAFE_componentWillMount() {
        const {contentUrl, contentUrlLocaled, pageContentStore, contentKey, failCallBack} = this.props;

        if (!contentUrl) {
            return;
        }

        if (pageContentStore.justLoaded) {
            pageContentStore.shownLoaded();
        } else {
            this.setState({loading: true});
            pageContentStore.startLoading();
            $.ajax(contentUrlLocaled ? contentUrl : getLinkPath(contentUrl), {
                dataType: 'json',
                error: error => {
                    failCallBack && failCallBack(error);
                },
                success: response => {
                    pageContentStore.updateContent(response);
                    this.setState({loading: false});
                }
            });
        }

        pageContentStore.updateContentKey(contentKey);
    }

    componentDidMount() {
        const {onlyOnce, pageContentStore, contentKey} = this.props;

        if (onlyOnce) {
            this.setState({content: pageContentStore.contentByContentKey(contentKey)});
            pageContentStore.updateContentKey(null, true);
        }
    }

    componentWillUnmount = () => this.props.pageContentStore.updateContentKey(null, true);

    render() {
        const {contentKey, contentHolder, pageContentStore, className, id, onlyOnce} = this.props;
        let {content} = this.state;
        const {loading} = this.state;

        if (!onlyOnce) {
            content = pageContentStore.contentByContentKey(contentKey);
        }

        return <div id={id} className={className} ref={this.root}>
            {loading
                ? <SmartPageLoader/>
                : this.props.children || <div
                    className={contentHolder ? 'content__holder' : null}
                    dangerouslySetInnerHTML={
                        {__html: content}
                    }
                />}
        </div>;
    }
}));

const PageContent = props => <ScrolledPageContent noScroll={true} {...props}>
    {props.children}
</ScrolledPageContent>;

export {PageContent, ScrolledPageContent};
