import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import I18n from 'app/i18n';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {CSSTransition} from 'react-transition-group';

export default class CopyLink extends PureComponent {
    state = {copied: false};

    static propTypes = {
        label: PropTypes.string,
        link: PropTypes.string.isRequired,
        noLinkClick: PropTypes.bool
    };

    onLinkClick = e => {
        if (this.props.noLinkClick) {
            e.preventDefault();
        }
    };

    onCopyHandler = () => {
        this.setState({copied: true});
    };

    hideCopyMessage = () => {
        this.setState({copied: false});
    };

    render() {
        const {label, link} = this.props;
        const {copied} = this.state;

        return <div className="sharing-link">
            <span className="sharing-link__title">{label || I18n.t('link')}:</span>
            <a className="copy-link"
                target="_blank"
                rel="noopener noreferrer"
                href={link}
                onClick={this.onLinkClick}
            >
                {link}
            </a>
            <CopyToClipboard text={link} onCopy={this.onCopyHandler}>
                <button className="btn btn--invert copy-link__copy">{I18n.t('copy_link')}</button>
            </CopyToClipboard>
            <CSSTransition
                timeout={{
                    enter: 500,
                    exit: 1500
                }}
                classNames="fade"
                in={copied}
                onEntered={this.hideCopyMessage}>
                <span className="copy-link__message">{I18n.t('link_copied')}!</span>
            </CSSTransition>
        </div>;
    }
}
