import CollectionStore from 'stores/CollectionStore';
import GameInterface from 'app/interfaces/GameInterface';
import {action, makeObservable, observable} from 'mobx';

export const OTHER = 0;

export class GamesStore extends CollectionStore<GameInterface> {
    feedUrl = null;

    channel = null;

    constructor(games: GameInterface[]) {
        super();
        this.setCollection(games);
        makeObservable(this);
    }

    @observable esports = false;

    @action
    changeEsports(esportStatus: boolean): void {
        this.esports = esportStatus;
    }

    @action
    gameSelect(game_id: number): number {
        return game_id === OTHER ? this.bySportWithoutOther[0].id : game_id;
    }

    @action
    findGameById(game_id: number): GameInterface {
        return this.collection.find(game => game.id === game_id);
    }

    @action
    lastGame(): GameInterface {
        return this.collection[0];
    }

    get esport(): GameInterface[] {
        return this.collection.filter(game => game.esports);
    }

    get bySportWithoutOther(): GameInterface[] {
        return this.collection.filter(game => game.id !== OTHER);
    }
}
