import $ from 'jquery';
import React from 'react';
import I18n from 'app/i18n';
import {getLinkPath} from 'app/utils';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {shopItemPrice} from 'app/utils/ShopItemPriceFormat';
import {modalsStore} from 'app/store/global';

export default class DefaultShopInfoPopup extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {quantity: 1};
        this.buy = this.buy.bind(this);
        this.closeHandlerPopup = this.closeHandlerPopup.bind(this);
    }

    componentDidMount() {
        this.dataLastShopInformation();
    }

    dataLastShopInformation = () => {
        $.ajax(getLinkPath('/shop/last_shop_information'), {
            dataType: 'JSON',
            success: response => Object.keys(response).forEach(key => this.refs[key].value = response[key])
        });
    };

    buy() {
        const {scope_param} = this.props;
        const {quantity} = this.state;
        const userData = {
            item_gifts_user_id: this.props.item_gifts_user_id,
            quantity: this.state.quantity,
            [scope_param]: {}
        };
        let title = this.props.item ? this.props.item.title : '';
        if (quantity > 1) {
            title = `${quantity} x ${title}`;
        }

        ['first_name', 'last_name', 'address', 'city', 'region', 'zip', 'country', 'phone_number'].forEach(field => userData[scope_param][field] = this.refs[field].value);
        this.props.confirmBuyHandler(title, this.price(quantity), userData, this.closeHandlerPopup);
    }

    closeHandlerPopup() {
        modalsStore.hideModal(this.props.popupId);
    }

    price(quantity) {
        return shopItemPrice(this.props.item, quantity);
    }

    renderBody() {
        return <div className="popup-form">
            {['first_name', 'last_name'].map((field, i) => <div key={i} className="popup-form__row">
                <label className="field-label" htmlFor="field-name">{I18n.t(field)}</label>
                <div className="field">
                    <input ref={field} type="text" id={`field-${field}`} name={field} className="field__input"/>
                </div>
            </div>)}
            <div className="popup-form__row">
                <label className="field-label" htmlFor="field-address">{I18n.t('address')}</label>
                <div className="field">
                    <input ref="address" type="text" id="field-address" name="address" className="field__input"/>
                </div>
            </div>
            <div className="popup-form__row">
                <label className="field-label" htmlFor="field-city">{I18n.t('city')}</label>
                <div className="field">
                    <input ref="city" type="text" id="field-city" name="city" className="field__input"/>
                </div>
            </div>
            <div className="popup-form__row">
                <label className="field-label" htmlFor="field-state">
                    {I18n.t('state')}/{I18n.t('province')}/{I18n.t('region')}
                </label>
                <div className="field">
                    <input ref="region" type="text" id="field-state" name="region" className="field__input"/>
                </div>
            </div>
            <div className="popup-form__row">
                <label className="field-label" htmlFor="field-zip">Zip</label>
                <div className="field">
                    <input ref="zip" type="text" id="field-zip" name="zip" className="field__input"/>
                </div>
            </div>
            <div className="popup-form__row">
                <label className="field-label" htmlFor="field-country">{I18n.t('user.country')}</label>
                <div className="field">
                    <input ref="country" type="text" id="field-country" name="country" className="field__input"/>
                </div>
            </div>
            <div className="popup-form__row">
                <label className="field-label" htmlFor="field-phone">{I18n.t('phone_number')}</label>
                <div className="field">
                    <input ref="phone_number" type="text" id="field-phone" name="phone" className="field__input"/>
                </div>
            </div>
            <div className="popup-form__submit-row">
                <button className="btn btn--large" href="#" onClick={this.buy}>
                    {I18n.t(this.props.gift ? 'ok' : 'buy_shop_item')}
                </button>
            </div>
        </div>;
    }

    render() {
        return <PopupDialog id={this.props.popupId}>
            <div className="popup__content">
                {this.renderHeader()}
                {this.renderBody()}
            </div>
        </PopupDialog>;
    }
}
