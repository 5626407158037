import {action, makeObservable, observable} from 'mobx';

export class ArticleStore {
    constructor() {
        makeObservable(this);
    }

    @observable pinnedPromotionsCount = 0;

    @action init(data) {
        this.update(data);
    }

    @action update({pinnedPromotionsCount}) {
        if (typeof pinnedPromotionsCount !== 'undefined') {
            this.pinnedPromotionsCount = pinnedPromotionsCount;
        }
    }
}
