import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {translatedName} from 'app/utils/translations_helper';

const FIRST_PLACE = 1,
    SECOND_PLACE = 2,
    THIRD_PLACE = 3;

export default class Place extends PureComponent {
    get title_number() {
        const {place_from, place_to} = this.props;

        if (place_from !== place_to) {
            return I18n.t('prizes_texts.places_range', {
                from: place_from,
                to: place_to
            });
        }

        switch (place_from) {
        case FIRST_PLACE:
            return I18n.t('prizes_texts.winner');
        case SECOND_PLACE:
            return I18n.t('prizes_texts.2nd_place');
        case THIRD_PLACE:
            return I18n.t('prizes_texts.3rd_place');
        default:
            return I18n.t('prizes_texts.count_place', {count: place_from});
        }
    }

    get title_place() {
        const {place_from, place_to} = this.props;

        if (place_from !== place_to) {
            return I18n.t('prizes_texts.places');
        }
        return place_from === FIRST_PLACE ? '' : I18n.t('prizes_texts.place');
    }

    get prize() {
        const [prize] = this.props.prizes || [];

        if (!prize) {
            return null;
        }

        return translatedName(prize);
    }

    get extraPrizes() {
        const [, ...extraPrizes] = this.props.prizes || [];
        return extraPrizes.map(extraPrize => `+ ${translatedName(extraPrize)}`);
    }

    render() {
        const {section, image, promoClassName, PrizeClass} = this.props;
        return this.prize && <PrizeClass
            image={image}
            promoClassName={promoClassName}
            section={section}
            title_number={this.title_number}
            title_place={this.title_place}
            prize={this.prize}
            extra_prizes={this.extraPrizes}
        />;
    }
}
