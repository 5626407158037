import React, {PureComponent} from 'react';
import {casinoGamesStore} from 'app/store/global';
import MultiLangsInterface from 'app/interfaces/MultiLangsInterface';
import {isDesktopGoUp} from 'app/utils/isDesktopGoUp';
import I18n from 'app/i18n';

interface FilterGroupProps {
    className?: string
    id: number
    name: string
    onClick?: () => void
    langs?: MultiLangsInterface
}

export default class FilterGroup extends PureComponent<FilterGroupProps> {
    selectFilter = (): void => {
        casinoGamesStore.changeFilterGroup(this.props.id);
        this.props.onClick();
        isDesktopGoUp();
    };

    render(): React.ReactNode {
        const {className, name, langs} = this.props;
        return <button className={className} onClick={this.selectFilter}>
            <span className="name">{langs && langs[I18n.locale] || name}</span>
        </button>;
    }
}
