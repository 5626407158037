import {translated} from 'app/utils/translations_helper';
import React, {Component} from 'react';
import {i18n_t} from 'app/i18n';
import AsideHeader from 'app/modules/AsideHeader';

import ScheduledComponent from './compendium/scheduled_component';
import StartedComponent from './compendium/started_component';
import FinishedComponent from './compendium/finished_component';

import {inject, observer} from 'mobx-react';

export class Compendium extends Component {
    static cookieName = 'compendium-menu';

    static componentName = 'compendium';

    title = () => translated(
        this.props,
        'display_name',
        'display_name_translations'
    ) || i18n_t('compendium.title');

    render() {
        const componentProps = {
            ...this.props,
            currentTimeStore: {t: this.props.currentTimeStore.t}
        };

        const {cookieName, componentName} = this.constructor;

        return <AsideHeader
            cookieName={cookieName}
            title={this.title()}
            componentName={componentName}
        >
            <div>
                <ScheduledComponent {...componentProps}/>
                <StartedComponent {...componentProps}/>
                <FinishedComponent {...componentProps}/>
            </div>
        </AsideHeader>;
    }
}

export default inject('currentTimeStore')(observer(Compendium));
