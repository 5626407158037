import React, {PureComponent} from 'react';
import Image from 'app/helpers/Image';
import BuyButton from 'app/components/shop/lootboxes/buy_button';
import ShopItemDiscount from './shop_item_discount';
import {goToShopLootbox, goToShopOtherItem} from 'app/utils/app_links';
import {shopLootboxStore} from 'app/store/global';
import {translatedName} from 'app/utils/translations_helper';
import {isUSD} from 'app/utils/ShopItemPriceFormat';
import {toastSuccess} from 'app/components/toasts/liteToast';
import I18n from 'app/i18n';
import {otherItemName} from 'app/components/my_profile/artifacts/otherItemName';

export default class ShopItem extends PureComponent {
    state = {popupId: null};

    moreDetailsHandler = e => {
        e && e.preventDefault();
        const {id, recipe, currency, description, type_shop} = this.props;

        if (type_shop === 'lootbox' && recipe) {
            goToShopLootbox(recipe.slug, !isUSD(currency));
        }
        if (type_shop === 'other' && description) {
            goToShopOtherItem(id, !isUSD(currency));
        }
    };

    afterBuy = () => {
        this.moreDetailsHandler();
        toastSuccess(I18n.t('purchase_was_successful_message'));
    };

    get gameClass() {
        const {game} = this.props;

        return typeof game === 'string'
            ? (game === '' ? 'game' : 'game_') + game.toLowerCase().replace(/ |:\s*/g, '_')
            : '';
    }

    openLootboxAfterBuy = () => {
        const {recipe} = this.props;

        if (!recipe) {
            return;
        }
        shopLootboxStore.updateOpenNowStatus(true);
    };

    nameItem = () => {
        const {recipe, title, type_shop} = this.props;

        if (type_shop === 'lootbox') {
            return translatedName(recipe);
        }
        if (type_shop === 'other') {
            return otherItemName(this.props);
        }
        return title;
    };

    render() {
        return <div className="market__lootbox">
            <div className="market__lootbox--img-wrap">
                <div
                    className={`market__lootbox--img ${this.gameClass}`}
                    onClick={this.moreDetailsHandler}>
                    <Image {...this.props}/>
                    <ShopItemDiscount {...this.props}/>
                </div>
            </div>
            <div className="market__lootbox--desc">
                <div className="market__lootbox--desc__content">
                    <div className="market__lootbox--title">{this.nameItem()}</div>
                </div>
                <div className="market__lootbox--actions">
                    <BuyButton
                        {...this.props}
                        openLootboxAfterBuy={this.openLootboxAfterBuy}
                        afterBuy={this.afterBuy}
                        with_confirmation={true}
                    />
                </div>
            </div>
        </div>;
    }
}
