import React from 'react';
import Image, {ImageProps} from 'app/helpers/Image';

interface LazyImageProps extends ImageProps {
    attributes?: {
        [key: string]: string
    }
}

export default class LazyImage extends Image<LazyImageProps> {
    render(): React.ReactNode {
        const {
            image: {url, name},
            className = 'lazyload',
            alt,
            title,
            attributes = {}
        } = this.props;

        return <img
            {...attributes}
            data-src={url}
            className={className}
            alt={alt || name}
            title={title || name}
        />;
    }
}
