import React, {Component} from 'react';
import {i18n_t} from 'app/i18n';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {goToRoute} from 'app/utils/link_helper';
import {goToMyHero} from 'app/utils/app_links';
import {fixed2} from 'app/utils/fixed';
import ConvertButton from '../components/my_profile/artifacts/artifact_popup/compendium/ConvertButton';
import {inject, Provider} from 'mobx-react';
import {PromoActionStore} from 'app/store/PromoActionStore';
import {modalsStore} from 'app/store/global';

interface NotEnoughPredictionPointsPopupProps {
    promoActionStore?: PromoActionStore
    popupId: string
}

const INITIAL_MONEY_TO_THE_NEXT_POINT = 5;

@inject('promoActionStore')
export default class NotEnoughPredictionPointsPopup extends Component<NotEnoughPredictionPointsPopupProps> {
    moneyToNextPoint = (): string => {
        const {money_to_next_point} = this.props.promoActionStore.promoAction;

        return fixed2(money_to_next_point || INITIAL_MONEY_TO_THE_NEXT_POINT);
    };

    close = (): void => modalsStore.hideModal(this.props.popupId);

    clickButtonHandler = (): void => {
        this.close();
        goToMyHero();
    };

    clickLinkHandler = (): void => {
        this.close();
        goToRoute('/play/bets');
    };

    render(): React.ReactNode {
        return <PopupDialog id={this.props.popupId}>
            <div className="popup__content popup__content--small">
                <div className="modal-popup-content">
                    <div className="modal-popup-content__text">
                        <div className="subtitle">
                            {i18n_t('not_enough_prediction_points')}
                        </div>
                        <div>
                            <span>{i18n_t('place_bet_on_sum')}</span>
                            &nbsp;
                            {`$${this.moneyToNextPoint()}`}
                            <br/>
                            <span>{i18n_t('or_convert_artifact')}</span>
                        </div>
                    </div>
                    <div className="modal-popup-content__submit-row">
                        <Provider promoActionStore={this.props.promoActionStore}>
                            <ConvertButton onClickHandler={this.clickButtonHandler}/>
                        </Provider>
                        <a
                            href="#"
                            onClick={this.clickLinkHandler}
                        >
                            {i18n_t('place_a_bet_full')}
                        </a>
                    </div>
                </div>
            </div>
        </PopupDialog>;
    }
}
