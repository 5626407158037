import React, {Component, ReactNode} from 'react';
import {getLinkPath, scrollToTop, timeStampToDashDate, updateMeta, updateTitle} from 'app/utils';
import {E_SPORT_MAIN_URL, lineTournament} from 'app/utils/esport';
import I18n from 'app/i18n';
import {ScrolledPageContent} from 'app/components/page_content';
import LineProvider from 'app/components/bet_form/line_provider';
import Form from 'app/components/bet_form/form';
import {gameName} from 'app/components/pages/esport/esport';
import {inject, observer} from 'mobx-react';
import {Link} from 'react-router-dom';
import {lineNickWithParent} from 'app/lines/line_helpers';
import {isNestedLine} from 'app/lines/isNestedLine';
import LineInterface from 'app/interfaces/LineInterface';
import {PageContentStore} from 'app/store/PageContentStore';
import {EsportContent} from 'app/components/pages/esport/EsportShow';
import withRouter from 'app/utils/navigate/customWithRouter';

const GAMER_1 = 1,
    GAMER_2 = 2;

const eSportGamersDescription = (line, parentLine, title = false) => `${
    lineNickWithParent(line, parentLine, GAMER_1)
}${title ? ` ${I18n.t('versus')} ` : '-vs-'}${
    lineNickWithParent(line, parentLine, GAMER_2)
}`;

const eSportDate = (line, parentLine) => timeStampToDashDate(isNestedLine(line) ? parentLine.date : line.date);

const h1Description = bet => bet && I18n.t('esport_h1_bet_description', {
    date: eSportDate(bet, null),
    game: gameName(bet.game),
    gamers: eSportGamersDescription(bet, null, true),
    tournament: lineTournament(bet)
});

interface ShowESportBetProps {
    pageContentStore?: PageContentStore
    location?: {
        pathname: string
    }
    params?: {
        game: string
    }
}

interface EsportBet {
    bet: LineInterface
    user_bets: LineInterface[]
}

class ShowESportBet extends Component<ShowESportBetProps> {
    componentDidMount(): void {
        this.updateTitleAndMeta();
        scrollToTop();
    }

    componentDidUpdate(): void {
        this.updateTitleAndMeta();
    }

    updateTitleAndMeta = (): void => {
        const bet = this.bet();

        if (!bet) {
            return;
        }

        const data = {
            game: gameName(bet.game),
            game_slug: bet.game_slug,
            gamers: eSportGamersDescription(bet, null, true),
            tournament: lineTournament(bet)
        };

        updateTitle(I18n.t('esport_bet_title', data));
        updateMeta({
            description: I18n.t('esport_meta_description', data),
            keywords: I18n.t(`esport_meta_keywords_${data.game_slug}`)
        });
    };

    bet(): LineInterface {
        return this.esportBet() ? this.esportBet().bet : null;
    }

    esportBet = (): EsportBet => {
        const content = this.props.pageContentStore.content as unknown as EsportContent;
        return content.esport_bet;
    };

    render(): ReactNode {
        const esportBet = this.esportBet();
        const bet = this.bet();
        return <ScrolledPageContent contentUrl={this.props.location.pathname} contentUrlLocaled>
            {esportBet && <>
                <div className="title picks-back" key="backButton">
                    <Link
                        to={getLinkPath(`${E_SPORT_MAIN_URL}/${this.props.params.game}`) || '#'}
                        className="picks-back__link"
                    >
                        <svg height="7px" width="4px">
                            <use xlinkHref="#svg-arr-left2"/>
                        </svg>
                        <span>{I18n.t('button_back_game_label', {game: gameName(bet.game)})}</span>
                    </Link>
                </div>
                <div className="match-details" key="matchDetails">
                    <h1>{h1Description(bet)}</h1>
                </div>
                {bet
                    ? <LineProvider
                        bet={bet}
                        selectedBet={Form.betId(bet)}
                        seo formIdShow={bet.hash_id}
                        userBets={esportBet.user_bets}/>
                    : <div className="loader"/>}
            </>}
        </ScrolledPageContent>;
    }
}

export default withRouter(inject('pageContentStore')(observer(ShowESportBet)));
