import React, {PureComponent} from 'react';
import {i18n_t} from 'app/i18n';

interface EmptyGamesProps {
    favourite?: boolean
}

export default class EmptyGames extends PureComponent<EmptyGamesProps> {
    render(): React.ReactNode {
        const {favourite} = this.props;
        return <div className={`search-no-found casino-games ${favourite ? 'favourite-games' : ''}`}>
            <span className="background-image"/>
            { favourite
                ? <div className="container-text">
                    <p>{i18n_t('empty_favorite_games1')}</p>
                    <p>{i18n_t('empty_favorite_games2')}</p>
                </div>
                : <div className="container-text">
                    <p>{i18n_t('empty_games1')}</p>
                    <p>{i18n_t('empty_games2')}</p>
                </div> }
        </div>;
    }
}
