import Cookies from 'js-cookie';

const getCookies = <T>(key: string, defaultValue: T): T => {
    const dataJSON = Cookies.get(key);

    try {
        return dataJSON ? JSON.parse(dataJSON) : defaultValue;
    } catch (error) {
        return defaultValue;
    }
};

export default getCookies;
