import React, {forwardRef, ReactNode} from 'react';

interface Props {
    className?: string,
    children?: ReactNode
}

export const DivWithReference = forwardRef<HTMLDivElement, Props>((props: Props, ref) => <div
    ref={ref} className={props.className}>{props.children}</div>);

