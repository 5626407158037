import React, {PureComponent, ReactNode} from 'react';
import ResultsContent from './ResultsContent';

export default class ResultsSportContent extends PureComponent {
    render(): ReactNode {
        return <ResultsContent
            urlPrefix={'/play/results_sport/'}
            esports={false}
            keyPrefix={'results_sport'}
            params={{filter: ''}}/>;
    }
}
