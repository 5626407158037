import AmountValidation from 'validations/AmountValidation';
import extractArguments from 'modules/extractArguments';
import ErrorsServerInterface from 'app/interfaces/ErrorsServerInterface';
import BaseValidation from 'validations/BaseValidation';
import LessOrEqualValidation from 'validations/LessOrEqualValidation';
import PresenceValidation from 'validations/PresenceValidation';
import UrlValidation from 'validations/UrlValidation';
import StreamValidation from 'validations/StreamValidation';
import GreaterOrEqualValidation from 'validations/GreaterOrEqualValidation';
import SportStreamValidation from 'validations/SportStreamValidation';

interface ArgsInterface {
    attributes: string[]
    options: {
        message?: string
    }
}

export default abstract class BaseValidator<T> {
    constructor(object: T) {
        this.object = object;
    }

    errors: ErrorsServerInterface = {};

    result = true;

    object: T;

    presence(...args: unknown[]): void {
        this.validateAll(args, PresenceValidation);
    }

    greaterOrEqual(...args: unknown[]): void {
        this.validateAll(args, GreaterOrEqualValidation);
    }

    lessOrEqual(...args: unknown[]): void {
        this.validateAll(args, LessOrEqualValidation);
    }

    isAmount(...args: unknown[]): void {
        this.validateAll(args, AmountValidation);
    }

    isValidUrl(...args: unknown[]): void {
        this.validateAll(args, UrlValidation);
    }

    isValidStream(esports?: boolean, ...args: unknown[]): void {
        this.validateAll(args, esports ? StreamValidation : SportStreamValidation);
    }

    validateAll(args: unknown[], ValidationClass: new (a, o) => BaseValidation<unknown, unknown>): void {
        const {attributes, options} = extractArguments(args) as ArgsInterface;
        const {message} = options;

        attributes.forEach(attribute => {
            const result = this.validate(this.object[attribute], options, ValidationClass);

            if (!result) {
                this.setError(attribute, message);
            }
        });
    }

    validate = (attribute: unknown,
        options: unknown,
        ValidationClass: new (a, o) => BaseValidation<unknown, unknown>): boolean => new ValidationClass(
        attribute, options
    ).execute();

    setError(attribute: string, message: string): void {
        this.result = false;
        if (!Array.isArray(this.errors[attribute])) {
            this.errors[attribute] = [];
        }
        this.errors[attribute].push(message);
    }

    abstract validations(esports?: boolean): void;

    isValid = (esports?: boolean): boolean => {
        this.validations(esports);
        return this.result;
    };
}
