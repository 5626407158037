import SmartSelect from 'app/components/reusable/SmartSelect';

export default abstract class BaseStrategy {
    constructor(select: SmartSelect) {
        this.select = select;
    }

    select: SmartSelect;

    abstract get selectedItems(): string | string[] | number | number[];

    abstract selectValue(value: string | string[]): string | string[];

    abstract isCheckedItem(value: string | string[]): boolean;

    abstract selectedItemsMessage(): string;

    abstract placeHolderMessage(): string;

    abstract emptyItemsMessage(): string;
}
