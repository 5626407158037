import {goToRoute} from 'app/utils/link_helper';
import React, {Component, ReactNode} from 'react';
import Items from 'app/components/shop/Items';
import {ShopsStoreProps} from 'app/store/ShopStore';
import {inject, observer} from 'mobx-react';

@inject('shopStore')
@observer
export default class ProductItems extends Component<ShopsStoreProps> {
    componentDidMount = (): void => {
        if (this.props.shopStore.data.product_items.length === 0) {
            goToRoute('/shop');
        }
    };

    render(): ReactNode {
        const {data: {user: {points}, product_items}} = this.props.shopStore;
        return <div className="tabs__content">
            <div className="tabs__content-item">
                <Items items={product_items} points={points}/>
            </div>
        </div>;
    }
}
