import React, {PureComponent} from 'react';
import Image from 'app/helpers/Image';
import {getPromoLinkPath} from 'app/utils';
import {Link} from 'react-router-dom';

export default class Progress extends PureComponent {
    get percentsToNextPoint() {
        let {percents_to_next_point} = this.props;
        percents_to_next_point = Number(percents_to_next_point);

        if (percents_to_next_point) {
            return 100 - percents_to_next_point;
        }

        return 100;
    }

    render() {
        const {background, progress, logo, styleTop} = this.props;

        return <div className="compendium-aside__image">
            <div className="compendium-progress small-img">
                <Link
                    to={getPromoLinkPath()}
                >
                    <span className="compendium-progress__image">
                        <Image image={{...background}}/>
                    </span>
                    <span
                        className="compendium-progress__overlay"
                        style={{
                            backgroundImage: `url(${progress && progress.url})`,
                            top: `${typeof styleTop === 'number'
                                ? styleTop
                                : this.percentsToNextPoint}%`
                        }}
                    />
                    <span
                        className="compendium-progress__overlay__elements"
                        style={{backgroundImage: `url(${logo.url})`}}
                    />
                </Link>
            </div>
        </div>;
    }
}
