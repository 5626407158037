import React, {PureComponent, ReactNode} from 'react';
import {betMoney} from 'app/utils';
import I18n from 'app/i18n';

interface Props {
    availablePledge: number
    initialPledge: number
    bonusBalance?: number
}

export class IncubatorCurrentPledge extends PureComponent<Props> {
    initialBalance = (): string => this.props.bonusBalance > 0
        ? `${betMoney({amount: this.props.initialPledge})}` +
        ` + ${betMoney({amount: this.props.bonusBalance})}${I18n.t('bonus_balance_sign')}`
        : betMoney({amount: this.props.initialPledge});

    render(): ReactNode {
        const {availablePledge} = this.props;
        return <>
            {betMoney({amount: availablePledge})}&nbsp;
            ({this.initialBalance()})
        </>;
    }
}
