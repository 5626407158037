import $ from 'jquery';
import React, {createRef} from 'react';
import I18n from 'app/i18n';
import Cookies from 'js-cookie';
import {addGaEvent} from 'app/ga_methods';
import FadeWrapper from './fade_wrapper';
import GameCheckBox from './GameCheckBox';
import SmartSelect from 'app/components/reusable/SmartSelect';
import SmartSelectItem from 'app/components/reusable/SmartSelectItem';
import {SwiperSlide} from 'swiper/swiper-react';
import ParentsFilter from 'app/components/pages/casino/ParentsFilter';
import {playPageType} from 'app/utils/playPageType';
import {isNewDesktop} from 'app/utils/windowFunctions';
import {sortByFilters} from 'app/utils/SortByFilters';
import {COOKIES_EXPIRATION_TIME} from 'app/store/constants/storeConstants';
import {getWidth} from 'app/utils/getWidth';

const CLASS_END = 'reached-end';
const CLASS_BEGIN = 'reached-begin';
const CLASS_MIDDLE = 'reached-middle';
const SWIPER_PADDING = 17;

export class TableFilter extends React.PureComponent {
    state = {renderSlider: false};

    constructor(props) {
        super(props);
        this.showMore = this.showMore.bind(this);
        this.switchGame = this.switchGame.bind(this);
        this.getEvents = this.getEvents.bind(this);
    }

    showMoreFadeWrapper = createRef();

    rootElement = React.createRef();

    filtersGroupElement = React.createRef();

    onCheckReached = swiper => {
        const el = this.rootElement.current;

        if (!el) {
            return;
        }

        if (swiper.isEnd) {
            el.classList.add(CLASS_END);
            el.classList.remove(CLASS_BEGIN, CLASS_MIDDLE);
        } else if (swiper.isBeginning) {
            el.classList.add(CLASS_BEGIN);
            el.classList.remove(CLASS_END, CLASS_MIDDLE);
        } else {
            el.classList.remove(CLASS_BEGIN, CLASS_END);
            el.classList.add(CLASS_MIDDLE);
        }
    };

    getFilters() {
        const {tableFilters} = this.props;
        const {filters} = this.props.tableStore.filterStore;
        return sortByFilters(filters.filter(filter => tableFilters.has(filter.id)));
    }

    switchGame(id) {
        const {tableStore} = this.props;
        const currentEvents = this.props.showAllFilters
            ? this.props.currentEvents
                .map(currentEvent => this.props.events.find(event => event.tourn === currentEvent))
            : null;

        tableStore
            .applyTableFilter(id, currentEvents, {forceUseHidden: false});
    }

    showMore() {
        const {tableStore} = this.props;
        const showMoreFilter = !this.props.showMoreFilter;

        if (showMoreFilter) {
            tableStore.update({showMoreFilter});
        } else {
            $(this.showMoreFadeWrapper.current.rootElement.current).fadeOut('done', () => {
                tableStore.update({showMoreFilter});
            });
        }
    }

    showMoreText() {
        return this.props.showMoreFilter ? I18n.t('less') : I18n.t('more');
    }

    showOnlyFuture = () => this.showOnlyUpcomingMatches(false);

    showUpcoming = () => this.showOnlyUpcomingMatches(true);

    showOnlyUpcomingMatches = checked => {
        const {tableStore} = this.props;
        addGaEvent('Upcoming matches', checked ? 'on' : 'off');
        tableStore.filterStore.setOnlyUpcomingMatches(checked, playPageType());
    };

    showOnly24h = () => {
        const {tableStore} = this.props;
        const res = !tableStore.filterStore.getOnly24h();
        addGaEvent('Only 24h', res ? 'on' : 'off');
        tableStore.filterStore.setOnly24h(res, playPageType());
    };

    makeTableCompact = () => this.tableCompact(true);

    makeTableFull = () => this.tableCompact(false);

    tableCompact = compact => {
        const {tableStore} = this.props;
        addGaEvent('Table compact', compact ? 'on' : 'off');
        tableStore.update({tableCompact: compact});
        Cookies.set('tableCompact', compact, {expires: COOKIES_EXPIRATION_TIME});
    };

    tableCompactButtonStyle(compact) {
        return this.props.tableCompact === compact ? ' active' : '';
    }

    getEvents() {
        const events = this.props.showAllFilters
            ? this.props.events
            : this.props.events
                .filter(event => this.props.filters.find(filter => event.filter === filter.id && filter.val));
        return events.concat().sort((a, b) => b.esport - a.esport);
    }

    handleSelect = currentEvents => {
        const {tableStore} = this.props;
        tableStore.setCurrentEvent(currentEvents, tableStore.results);
    };

    handleDiscipline = discipline => {
        const {tableStore} = this.props;
        tableStore.filterStore.setCurrentDiscipline(playPageType(), discipline);
    };

    handleBetType = type => {
        const {tableStore} = this.props;
        tableStore.filterStore.setCurrentBetType(playPageType(), type);
    };

    componentDidMount() {
        this.setRenderSwiper();
        window.addEventListener('resize', this.setRenderSwiper);
    }

    componentDidUpdate(prevProps) {
        if (this.props.events !== prevProps.events || this.props.filters !== prevProps.filters ||
            this.props.tableFilters !== prevProps.tableFilters) {
            this.setRenderSwiper();
        }
    }

    setRenderSwiper = () => {
        const filterGroups = this.filtersGroupElement.current?.clientWidth;
        const filterList = getWidth('.buttons-filter-without-swiper') || getWidth('.swiper-wrapper');
        this.setState({renderSlider: isNewDesktop() && filterGroups - SWIPER_PADDING < filterList});
    };

    buttonFilterReset() {
        return <button
            className="button-filters-reset"
            onClick={this.props.tableStore.clearFilters}
            title={I18n.t('clear')}
        >
            <svg className="svg-default">
                <use xlinkHref="#svg-clear"/>
            </svg>
        </button>;
    }

    render() {
        const {tableStore} = this.props;
        const onlyUpcomingMatches = tableStore.filterStore.getOnlyUpcomingMatches();
        const only24h = tableStore.filterStore.getOnly24h();
        const {renderSlider} = this.state;

        return <div className="filters-main-table">
            <div className="filters-main-table-row">
                <div className="selects-filter-games">
                    <div className="select-filter-games">
                        <label className="top-filters-row__title">{I18n.t('disciplines')}</label>
                        <SmartSelectItem
                            collection={tableStore.filterStore.getDisciplines()}
                            onChange={this.handleDiscipline}
                            value={tableStore.filterStore.discipline}
                        />
                    </div>
                    <div className="select-filter-games">
                        <label className="top-filters-row__title">{I18n.t('bet_type')}</label>
                        {tableStore.incubator
                            ? <SmartSelectItem
                                collection={tableStore.getIncubatorBetType()}
                                onChange={this.handleBetType}
                                value={tableStore.getIncubatorBetType()[0].value}
                            />
                            : <SmartSelectItem
                                collection={tableStore.getBetTypes()}
                                onChange={this.handleBetType}
                                value={tableStore.filterStore.betType}
                            />}
                    </div>
                    {!isNewDesktop() && this.buttonFilterReset()}
                </div>
                <div className="buttons-filter-games">
                    <div className="buttons-filter-slide-games" ref={this.filtersGroupElement}>
                        {this.props.events && this.props.events?.length === 0
                            ? <div className="buttons-filter-games-items">
                                <div className="items">
                                    <span className="item"/>
                                    <span className="item"/>
                                    <span className="item"/>
                                    <span className="item"/>
                                    <span className="item"/>
                                    <span className="item"/>
                                    <span className="item"/>
                                </div>
                            </div>
                            : <div className={`buttons-filter-group ${renderSlider ? '' : 'without-swiper'}`}
                                ref={this.rootElement}>
                                <ParentsFilter isSwiper={renderSlider} onCheckReached={this.onCheckReached}>
                                    {this.getFilters().map(filter => <SwiperSlide key={`filter-${filter.id}`}>
                                        <GameCheckBox switchGame={this.switchGame} {...filter} />
                                    </SwiperSlide>)}
                                </ParentsFilter>
                            </div>}
                    </div>
                    <button className={`button-filter-selects${this.props.showMoreFilter ? ' active' : ''}`}
                        onClick={this.showMore}>
                        <span className="icon">
                            <svg className="svg-default">
                                <use xlinkHref="#svg-icon-filter"/>
                            </svg>
                        </span>
                        <span className="name">{I18n.t('more')}</span>
                    </button>
                </div>
                {isNewDesktop() && this.buttonFilterReset()}
            </div>
            {this.props.showMoreFilter && <FadeWrapper
                className="filters-main-table-row"
                ref={this.showMoreFadeWrapper}
            >
                <div className="filters-main-table-settings">
                    <div className="filters-main-table-select">
                        <SmartSelect
                            multiple
                            collection={this.getEvents().map(event => ({
                                text: event.tourn,
                                value: event.tourn
                            }))}
                            onChange={this.handleSelect}
                            value={this.props.currentEvents}
                            placeholder_text_multiple={I18n.t('filter_select_tournament')}
                            search
                        />
                    </div>
                    <div className="filters-main-table-sorting">
                        <button
                            data-title={I18n.t('future_matches_first')}
                            onClick={this.showOnlyFuture}
                            className={`button-filter-sorting${onlyUpcomingMatches ? '' : ' active'}`}
                        >
                            <svg className="svg-default">
                                <use xlinkHref="#svg-filter-future-on"/>
                            </svg>
                        </button>
                        <button
                            data-title={I18n.t('upcoming_matches')}
                            onClick={this.showUpcoming}
                            className={`button-filter-sorting${onlyUpcomingMatches ? ' active' : ''}`}
                        >
                            <svg className="svg-default">
                                <use xlinkHref="#svg-filter-future-off"/>
                            </svg>
                        </button>
                        <button
                            data-title={I18n.t('only_24h')}
                            onClick={this.showOnly24h}
                            className={`button-filter-sorting${only24h ? ' active' : ''}`}
                        >
                            <svg className="svg-default">
                                <use xlinkHref="#svg-filter-only-24"/>
                            </svg>
                        </button>
                        <button
                            data-title={I18n.t('table_compact')}
                            onClick={this.makeTableCompact}
                            className={`button-filter-sorting${
                                        this.tableCompactButtonStyle(true)}`}
                        >
                            <svg className="svg-default">
                                <use xlinkHref="#svg-filter-compact"/>
                            </svg>
                        </button>
                        <button
                            data-title={I18n.t('table_full')}
                            onClick={this.makeTableFull}
                            className={`button-filter-sorting${
                                        this.tableCompactButtonStyle(false)}`}
                        >
                            <svg className="svg-default">
                                <use xlinkHref="#svg-filter-full"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </FadeWrapper>}
        </div>;
    }
}
