import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {coefColorClass} from 'app/lines/coefColorClass';
import {fixed3} from 'app/utils';
import LineInterface from 'app/interfaces/LineInterface';
import BetSlipTimer from 'app/components/bet_slip/BetSlipTimer';

interface BetSlipCoefficientProps {
    coef: string
    coefIncreased: boolean
    selectedLine: LineInterface
    express: boolean
}

export default class BetSlipCoefficient extends PureComponent<BetSlipCoefficientProps> {
    render(): ReactNode {
        return <div className="bet-slip-inventory-coefficient">
            <div className={`bet-slip-coefficient${coefColorClass(this.props.coefIncreased)}`}>
                <span>{I18n.t('add_bet_rate_label')}</span>
                <span>{fixed3(this.props.coef)}</span>
            </div>
            <BetSlipTimer express={this.props.express} selectedLine={this.props.selectedLine}/>
        </div>;
    }
}
