import React from 'react';
import I18n from 'app/i18n';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {modalsStore, streamStore} from 'app/store/global';

export default class StreamCreationPopup extends React.PureComponent {
    constructor(props) {
        super(props);
        const {title = '', twitch_channel = ''} = props;

        this.state = {
            title,
            twitch_channel
        };
    }

    // eslint-disable-next-line
    static channelPattern = /^(?:(?:(?:https|http):\/\/(?:www.twitch.tv|twitch.tv|player.twitch.tv)\/(?:\?channel=){0,1}(\1[a-z0-9_]{1,30}))|(\2[a-zA-Z0-9_]{1,30}))$/;

    handleTitle = e => {
        this.setState({title: e.target.value});
    };

    handleTwitchChannel = e => {
        this.setState({twitch_channel: e.target.value});
    };

    handleSubmit = e => {
        e.preventDefault();
        const {id} = this.props;
        const {twitch_channel} = this.state;
        const title = this.state.title || I18n.t('user_stream');
        const channelName = twitch_channel
            .replace(this.constructor.channelPattern, '$1$2').toLowerCase();

        if (id) {
            streamStore.updateUserStream({
                id,
                title,
                twitch_channel: channelName
            });
        } else {
            streamStore.createUserStream({
                title,
                twitch_channel: channelName
            });
        }
        modalsStore.hideModal(this.props.popupId);
    };

    render() {
        const {title, twitch_channel} = this.state;
        return <PopupDialog id={this.props.popupId}>
            <div className="popup__content">
                <h2 className="popup__header">{I18n.t('add_your_own')}</h2>
                <div className="popup-form">
                    <form onSubmit={this.handleSubmit}>
                        <div className="popup-form__row">
                            <label
                                className="field-label"
                                htmlFor="field-stream-add-name"
                            >{I18n.t('stream_name')}</label>
                            <div className="field">
                                <input type="text"
                                    id="field-stream-add-name"
                                    placeholder={I18n.t('user_stream')}
                                    className="field__input"
                                    name="title"
                                    maxLength="60"
                                    onChange={this.handleTitle}
                                    value={title}
                                />
                            </div>
                        </div>
                        <div className="popup-form__row">
                            <label
                                className="field-label"
                                htmlFor="field-stream-add-link"
                            >{I18n.t('put_your_link_below')}</label>
                            <div className="field">
                                <input type="text"
                                    id="field-stream-add-link"
                                    required
                                    placeholder="https://www.twitch.tv/esl_csgo"
                                    className="field__input link"
                                    name="twitch_channel"
                                    maxLength="60"
                                    pattern={this.constructor.channelPattern.source}
                                    onChange={this.handleTwitchChannel}
                                    value={twitch_channel}
                                />
                            </div>
                        </div>
                        <div className="popup-form__submit-row">
                            <button type="submit"
                                className="btn btn--middle btn--big-on-mobile"
                            >
                                {I18n.t('save')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </PopupDialog>;
    }
}
