import React, {Fragment, ReactNode} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import SmartStories from 'app/components/pages/home_page/SmartStories/SmartStories';
import {storiesStore} from 'app/store/global';
import AsideItem from 'site_version/com/AsideItem';

@inject('storiesStore')
@observer
export default class AsideStories extends React.Component {
    header = (): ReactNode => <Fragment>
        <div className="aside-item-heading__title">
            <h3>{I18n.t('stories')}</h3>
        </div>
        <div className="aside-item-heading__other">
            {storiesStore.getCountNewStories() > 0 &&
                <span className="number-new-stories">+{storiesStore.getCountNewStories()}</span>
            }
        </div>
    </Fragment>;

    render(): ReactNode {
        return storiesStore.hasStories()
            ? <AsideItem header={this.header()} name="stories" itemClassName="smart-stories">
                <SmartStories/>
            </AsideItem>
            : null;
    }
}
