import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {IncubatorCreateLineController} from 'app/controllers/IncubatorCreateLineController';
import {inject, observer} from 'mobx-react';
import {ParamsInterface} from 'app/utils/Get';
import {BLUE} from 'app/lines/line_helpers';
import {isDesktop} from 'app/utils';
import IncubatorLineTimer from 'app/components/pages/incubator/line/IncubatorLineTimer';
import IncubatorCancelCreateLineButton from 'app/components/pages/incubator/IncubatorCancelCreateLineButton';
import {isClosedLine} from 'utils/line_helpers';

interface Props {
    controller: IncubatorCreateLineController
}

const LIMIT_FROM_PLEDGE = 20;
const MIN_BET_AMOUNT = 1;

@inject('controller')
@observer
export default class IncubatorLineControlButtons extends Component {
    get controller(): IncubatorCreateLineController {
        const props = this.props as Props;
        return props.controller;
    }

    state = {
        closeButtonDisabled: false,
        plus2ButtonDisabled: false,
        plus5ButtonDisabled: false
    };

    handleKeyPress = (e: KeyboardEvent): void => {
        if (e.metaKey || e.ctrlKey || document.activeElement.className === 'chat__input-text js-chat-input') {
            return;
        }
        this.handleInputs(e.key);
        this.handleFocus(e.key);
    };

    handleInputs(value: string): void {
        if (value === 'T' || value === 't') {
            this.disableButton(
                () => this.addTime({time: 2}),
                'plus2ButtonDisabled'
            );
        }
        if (value === 'F' || value === 'f') {
            this.disableButton(
                () => this.addTime({time: 5}),
                'plus5ButtonDisabled'
            );
        }
        if (value === 'C' || value === 'c') {
            this.disableButton(
                () => this.controller.close({id: this.controller.incubatorGame.id}),
                'closeButtonDisabled'
            );
        }
        if (value === 'A' || value === 'a') {
            this.disableButton(
                () => this.controller.acceptAndClose({id: this.controller.incubatorGame.id}),
                'closeButtonDisabled'
            );
        }
    }

    handleFocus(value:string): void {
        if (value === 'ArrowRight') {
            const rightInput = document.getElementById('line[coef_2]');

            if (rightInput) {
                rightInput.focus();
            }
        }
        if (value === 'ArrowLeft') {
            const leftInput = document.getElementById('line[coef_1]');

            if (leftInput) {
                leftInput.focus();
            }
        }
    }

    componentDidMount(): void {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount(): void {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    handleAddTime5 = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        this.disableButton(
            () => this.addTime({time: 5}),
            'plus5ButtonDisabled'
        );
    };

    handleAddTime2 = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        this.disableButton(
            () => this.addTime({time: 2}),
            'plus2ButtonDisabled'
        );
    };

    addTime = (params: ParamsInterface): Promise<Response> => this
        .controller.addTime({...params, id: this.controller.incubatorGame.id});

    handleClose = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        this.disableButton(
            () => this.controller.close({id: this.controller.incubatorGame.id}),
            'closeButtonDisabled'
        );
    };

    handleAcceptAndClose = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        this.disableButton(
            () => this.controller.acceptAndClose({id: this.controller.incubatorGame.id}),
            'closeButtonDisabled'
        );
    };

    hasLoss(): boolean {
        const {coef1_win, coef2_win, coef_1, coef_2} = this.controller.incubatorGame.lineStore.collection;
        return this.coefProfit(coef1_win, coef_1) < 0 ||
            this.coefProfit(coef2_win, coef_2) < 0;
    }

    coefProfit(coef_win: string, coef: string): number {
        const {pledge} = this.controller.incubatorGame.lineStore.collection;
        return Number(pledge) + Number(coef_win) - (MIN_BET_AMOUNT * Number(coef) - MIN_BET_AMOUNT);
    }

    limit(): number {
        const {pledge} = this.controller.incubatorGame.lineStore.collection;
        return Number(pledge) / LIMIT_FROM_PLEDGE;
    }

    disableButton = (fun: () => Promise<unknown>, state: string): void => {
        if (this.state[state]) {
            return;
        }
        this.setState({[state]: true});
        fun().finally(() => {
            this.setState({[state]: false});
        });
    };

    render(): ReactNode {
        const {color, date, status} = this.controller.incubatorGame.lineStore.collection;
        const lineClosed = color !== BLUE;
        const classDisabled = lineClosed ? ' button-adaptive_disable' : '';
        const disablePlusButtons = this.hasLoss() || isClosedLine(status) ? ' button-adaptive_disable' : '';
        const {plus2ButtonDisabled, plus5ButtonDisabled, closeButtonDisabled} = this.state;
        const {attributes} = this.controller.incubatorGame;

        return <>
            <div className="incubator-line-controller">
                {isDesktop() && <div className="top-part">
                    <IncubatorLineTimer
                        lineClosed={lineClosed}
                        date={date}
                    />
                </div>}
                <div className="middle-part">
                    {lineClosed
                        ? <div className="cell">
                            <button
                                onClick={this.handleAddTime2}
                                type="button"
                                className={`button-adaptive button-adaptive_full-thin icon ${!lineClosed}`}
                                disabled={!lineClosed || this.hasLoss()}>
                                {I18n.t('incubator_open_line')}
                            </button>
                        </div>
                        : <div className="cell">
                            <button
                                onClick={this.handleAddTime2}
                                type="button"
                                className={`button-adaptive button-adaptive_full-thin icon ${disablePlusButtons}`}
                                disabled={plus2ButtonDisabled || this.hasLoss()}
                            >
                                <span className="icon">
                                    <svg className="svg-default">
                                        <use xlinkHref="#svg-icon-plus-b"/>
                                    </svg>
                                </span>
                                <span className="name">2m</span>
                            </button>
                            <button
                                onClick={this.handleAddTime5}
                                type="button"
                                className={`button-adaptive button-adaptive_full-thin icon ${disablePlusButtons}`}
                                disabled={plus5ButtonDisabled || this.hasLoss()}
                            >
                                <span className="icon">
                                    <svg className="svg-default">
                                        <use xlinkHref="#svg-icon-plus-b"/>
                                    </svg>
                                </span>
                                <span className="name">5m</span>
                            </button>
                        </div>}
                    <div className="cell">
                        <button
                            onClick={this.handleClose}
                            type="button"
                            className={`button-adaptive button-adaptive_full-thin${classDisabled}`}
                            disabled={closeButtonDisabled}
                        >
                            {I18n.t(isDesktop() ? 'tooltip_incubator_close_line' : 'tooltip_incubator_close_line_mob')}
                        </button>
                    </div>
                    <div className="cell">
                        <button
                            onClick={this.handleAcceptAndClose}
                            type="button"
                            className={`button-adaptive button-adaptive_full-thin${classDisabled}`}
                            disabled={closeButtonDisabled}>
                            {I18n.t('incubator_accept_and_close')}
                        </button>
                    </div>
                    {!isDesktop() && <>
                        <div className="cell">
                            <IncubatorCancelCreateLineButton
                                disabled={attributes.accepted_bets !== 0 ||
                                this.controller.bets?.collection.length !== 0}
                                cancelLine={this.controller.cancelLine}
                            />
                        </div>
                        <div className="cell">
                            <button
                                type="submit"
                                className="button-adaptive button-adaptive_full-thin"
                                disabled={isClosedLine(status)}
                            >
                                {I18n.t('user.save')}
                            </button>
                        </div>
                    </>}
                </div>
            </div>
        </>;
    }
}
