import React from 'react';
import Prize from 'app/components/my_profile/artifacts/popup/prize';
import GiveawayWinnerInterface from 'app/interfaces/GiveawayWinnerInterface';
import UserInventoryLink from 'app/helpers/UserInventoryLink';
import {shortLocalDateTimeFromISO} from 'app/utils';

const AccordionItem: React.FC<GiveawayWinnerInterface> = props => <div className="accordion-item">
    <div className="image">
        <Prize loot={props.prize} />
    </div>
    <div className="description">
        <div className="information">
            <UserInventoryLink
                user_nickname={props.nick}
                public_inventory={props.nick && props.profile}
            />
        </div>
        <span className="date">{shortLocalDateTimeFromISO(props.date)}</span>
    </div>
</div>;

export default AccordionItem;
