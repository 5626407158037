import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import {StreamStore} from 'app/store/stream_store';

interface IncubatorMobileStreamProps {
    stream_link: string
    streamStore?: StreamStore
}

@inject('streamStore')
@observer
export default class IncubatorMobileStream extends Component<IncubatorMobileStreamProps> {
    onClickOpenMobileStream = (): void => {
        this.props.streamStore.chooseStream({stream_url: this.props.stream_link}, false);
    };

    render(): ReactNode {
        return <>
            {!this.props.streamStore.stream && <div className="wrapper-incubator-stream">
                <span
                    className="button-incubator-mobile-stream"
                    onClick={this.onClickOpenMobileStream}
                >
                    <svg className="svg-default">
                        <use xlinkHref="#svg-movie"/>
                    </svg>
                    {I18n.t('incubator_mobile_stream')}
                </span>
            </div>}
        </>;
    }
}
