import {tableStore, betSlipStore} from 'app/store/global';
import {currentStream} from 'app/utils/currentStream';
import Footer from 'app/components/table/Footer';
import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import I18n from 'app/i18n';
import {TABLE_LABEL_TYPES_ARROWS, TABLE_LABEL_TYPES_CUP, TABLE_LABEL_TYPES_FIRE} from 'app/consts';
import MainLine from './row/main_line';
import TableEmptyState from './empty_state';
import Get from 'app/utils/Get';
import lineLoader from 'images/table/line-loader';
import {preloadImage} from 'app/utils/preloadImage';
import {continueRender, isComponentLoaded} from 'app/utils/listLazyRendering';
import {rootLine} from 'app/utils/line/rootLine';
import {toastError} from 'app/components/toasts/liteToast';
import {createSerialRendering} from 'app/lines/createSerialRendering';
import {lineCoef, lineId} from 'app/lines/line_helpers';
import {slowConnectionPromise, isDesktop, isGuest} from 'app/utils';
import {showPopup} from 'app/utils/modals/ModalsService';
import Form from '../bet_form/form';
import LineProvider from 'app/components/bet_form/line_provider';
import {ACTIVE_STATUSES, STATUS_CANCEL_COMPLETED, STATUS_COMPLETED, STATUS_PENDING} from 'admin/components/bet_form/utils/LineConsts';
import {isIncubatorPage} from 'app/utils/isIncubatorPage';
import {incubatorTablePage} from 'app/utils/incubatorTablePage';

const NOT_FOUND = 404;

const betFinalId = bet => {
    const root_line = bet.parent ? rootLine(bet) : bet;
    return root_line.hash_id || root_line.id;
};

export const showBetSlip = (bet, on, options) => {
    if (options && options.selectedBet) {
        const {status, incubator_2} = options.selectedBet;

        if (status === STATUS_COMPLETED || status === STATUS_CANCEL_COMPLETED) {
            return;
        }
        if (!lineCoef(options.selectedBet, on) && !incubator_2) {
            return;
        }
    }
    openBetSlip(bet, on, options);
};

const openBetSlip = (bet, on, options) => {
    const hash_id = betFinalId(bet);
    const sLineId = lineId(options && options.selectedBet || bet);
    betSlipStore.getFormBet(hash_id, sLineId, on);
};

export const showBetForm = function(bet, on, options) {
    slowConnectionPromise(
        new Get({url: `/bets/${betFinalId(bet)}`})
            .execute()
            .then(response => {
                if (response.status === NOT_FOUND) {
                    toastError(I18n.t('line_na'));
                }
                if (!response.ok) {
                    throw response;
                }

                return response.json();
            })
            .then(data => {
                if (options && options.openBetSlip && ACTIVE_STATUSES.concat(STATUS_PENDING).includes(data.bet.status)) {
                    openBetSlip(bet, on, options);
                } else {
                    showPopup('bet-form',
                        props => <LineProvider
                            bet={data.bet}
                            formIdShow={Form.betId(bet)}
                            selectedBet={Form.betId(options && options.selectedBet || bet)}
                            idBetShow={bet.id}
                            gamerNum={on}
                            afterBet={options && options.afterBet}
                            userBets={data.user_bets}
                            popupId={props.popupId}
                        />);
                }
            })
    );
};

const LOADING_STEP = 10;
const FIRST_LINES = 15;

@inject('betSlipStore', 'tableStore', 'userStore')
@observer
class Table extends Component {
    state = {
        itemsRendered: false,
        loading: false,
        showNestedBets: false
    };

    componentDidMount() {
        preloadImage(lineLoader);
        if (this.props.bets.length !== 0) {
            continueRender(this);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.bets.length === 0 && this.props.bets.length !== 0) {
            continueRender(this);
        }
    }

    static labelTypes = [
        {
            id: 'important_match',
            src: TABLE_LABEL_TYPES_FIRE
        },
        {
            id: 'final',
            src: TABLE_LABEL_TYPES_CUP
        },
        {
            id: 'increasing_limits',
            src: TABLE_LABEL_TYPES_ARROWS
        }
    ];

    showTableEmptyStateButton() {
        if (this.props.bets.length !== 0) {
            return null;
        }
        let params = null;

        if (this.props.currentEvents.length !== 0 || this.props.onlyUpcomingMatches ||
                this.props.only24h || !this.props.showAllFilters || this.props.tableStore.filterStore.betType === 'live') {
            params = {
                arrayLength: this.props.bets.length,
                buttonClick: () => this.props.tableStore.clearFilters(),
                buttonText: I18n.t('clear_filters'),
                filter: tableStore.filterType,
                text: I18n.t('there_are_no_events_to_display_with_these_criteria')
            };
        }

        return <TableEmptyState {...{
            ...params,
            seo: this.props.seo
        }}/>;
    }


    showAllNestedBets = () => {
        const {userStore} = this.props;
        const isShown = !this.state.showNestedBets;

        if (isShown && userStore.user.light_mode) {
            this.setState({loading: true});
            return this.loadAllNestedLines()
                .finally(() => {
                    this.setState({
                        loading: false,
                        showNestedBets: isShown
                    });
                });
        }

        return this.setState({showNestedBets: isShown});
    };

    loadAllNestedLines = () => {
        const {tableStore: ts} = this.props;

        const lines = this.props.bets
            .filter(line => line.nested_bets_count > 0 && Object.keys(line.nested_bets).length === 0);
        return ts.loadLine(lines.map(l => l.id));
    };

    extendLimit = () => {
        if (this.props.bets.length > this.state.linesLimit) {
            this.setState({linesLimit: this.state.linesLimit + LOADING_STEP});
        }
    };

    editableLines = () => isIncubatorPage() && !isGuest();

    get lines() {
        return isComponentLoaded(this)
            ? this.props.bets
            : this.props.bets.slice(0, FIRST_LINES);
    }

    get filterType() {
        return this.props.tableStore.filterType;
    }

    render() {
        const {loading} = this.state;
        const plusClass = `act table-expand table-expand--main${this.state.showNestedBets
            ? ' table-expand--active'
            : ''}`;
        const {tableCompact, betSlipStore, activeCoef, coefOnClick, seo, userStore} = this.props;
        const classBetSlip = !isDesktop() && betSlipStore.showForm ? ' table-bets--coupon' : '';
        const tableClass = `table-bets table-bets--full ${tableCompact ? 'table-bets--compact' : ''}${classBetSlip}`;
        const incubatorPage = isIncubatorPage();
        const editableClass = this.editableLines() ? ' tables-heading-incubator' : '';
        const editableClassTable = this.editableLines() ? ' table-bets-incubator' : '';

        return <div className={`${tableClass}${editableClassTable}`}>
            <div className={`table-bets__heading tables-heading${editableClass}`}>
                <button className={plusClass} id="expand-all-lines" onClick={this.showAllNestedBets}>
                    {loading
                        ? <span className="loader-button">
                            <img alt="loader" src={lineLoader}/>
                        </span>
                        : <div className="table-expand__plus-holder">
                            <svg className="svg-default svg-h">
                                <use xlinkHref="#svg-plus-new-h"/>
                            </svg>
                            <svg className="svg-default svg-v">
                                <use xlinkHref="#svg-plus-new-v"/>
                            </svg>
                        </div>}
                </button>
                <div className="table-bets__content">
                    <span className="table-expand table-expand--inner"/>

                    <div className="table-bets__content-row">
                        <div className="table-bets__mobile-row">
                            <div className="table-bets__date">{I18n.t('date_label')} ({this.props.timeZoneName})</div>
                            <div className="table-bets__icon"/>
                            <div className="table-bets__event">{I18n.t('table_title_event')}</div>
                        </div>
                        <div className="table-bets__cols-holder">
                            <div className="table-bets__col-1">
                                <div className="table-bets__player1">{I18n.t('player_label')}</div>
                                <div className="table-bets__odds">{I18n.t('odds_label')}</div>
                            </div>
                            <div className="table-bets__col-3">
                                <div className="table-bets__player2">{I18n.t('player_label')}</div>
                                <div className="table-bets__odds">{I18n.t('odds_label')}</div>
                            </div>
                        </div>
                    </div>
                    {incubatorTablePage()
                        ? <div className={`table-bets__col-4${isGuest() ? ' registration' : ''}`}>
                            <div className="table-bets__odds">{I18n.t('user.label_bonuses.turnover')}</div>
                        </div>
                        : <div className="table-bets__play"/>}
                </div>
            </div>
            {this.lines.map(bet => {
                const gamerActiveCoef = activeCoef && activeCoef(bet.id);
                return <MainLine
                    key={`bet-${bet.id}`}
                    bet={bet}
                    coef_1={bet.coef_1}
                    coef_2={bet.coef_2}
                    ut={bet.ut}
                    color={bet.color}
                    serial={createSerialRendering(bet, activeCoef)}
                    activeCoef={activeCoef}
                    gamerActiveCoef={gamerActiveCoef}
                    isExpress={this.props.betSlipStore.express}
                    seo={seo}
                    coefOnClick={coefOnClick}
                    currentStream={currentStream(bet)}
                    showNestedBets={this.state.showNestedBets}
                    incubatorPage={incubatorPage}
                    isCreator={userStore.isIncubatorLineCreator(bet.id)}
                />;
            })}
            {this.showTableEmptyStateButton()}
            {this.props.children}
        </div>;
    }
}

Table.Footer = Footer;

export {Table};
