import $ from 'jquery';
import React from 'react';
import I18n from 'app/i18n';
import {getLinkPath} from 'app/utils';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {updateInfo} from 'app/common';
import {toastSuccess} from 'app/components/toasts/liteToast';
import {modalsStore} from 'app/store/global';

const SEND_TO_USER_TYPE = 0;
const SEND_TO_EMAIL_TYPE = 1;
const USER_NAME_LENGTH = 32;
const EMAIL_NAME_LENGTH = 52;
const MIN_STRING_LENGTH_FOR_SEARCH = 4;

export const findUserOrEmail = (value, callback, type) => {
    const checkType = typeof type === 'undefined' ? SEND_TO_USER_TYPE : type;

    $.ajax({
        data: {id: value},
        success: response => callback(response.success),
        type: 'GET',
        url: `/gift/check_${checkType === SEND_TO_USER_TYPE ? 'name' : 'email'}`
    });
};

const activeTabClass = (type, currentType) => type === currentType ? ' active-link' : '';

export default class SendForm extends React.PureComponent {
    constructor(props) {
        super(props);
        const {userCredential, userName} = props;
        this.state = {
            checked: typeof userCredential !== 'undefined',
            type: SEND_TO_USER_TYPE,
            userCredential: userName
        };
        this.userCredentialInput = React.createRef();
        this.findUserOrEmail = this.findUserOrEmail.bind(this);
        this.clickTabHandler = this.clickTabHandler.bind(this);
        this.sendGift = this.sendGift.bind(this);
        this.changeUserCredentialHandler = this.changeUserCredentialHandler.bind(this);
    }

    setFocusOnUserCredentialInput() {
        this.userCredentialInput.current.focus();
    }

    componentDidMount() {
        this.setFocusOnUserCredentialInput();
    }

    clickTabHandler(type, e) {
        e && e.preventDefault();
        this.setState({
            checked: false,
            error: null,
            type,
            userCredential: ''
        });
        this.setFocusOnUserCredentialInput();
    }

    findUserOrEmail(e) {
        if (e.target.value.length < MIN_STRING_LENGTH_FOR_SEARCH) {
            this.setState({error: this.setState({checked: false})});
            return;
        }

        if (this.state.error) {
            this.setState({error: null});
        }
        findUserOrEmail(e.target.value, success => {
            this.setState({checked: success});
        }, this.state.type);
    }

    sendGift(event) {
        event.preventDefault();
        $.ajax(getLinkPath('/gift/send_gift'), {
            data: {
                receiver: this.state.userCredential,
                send_type: this.state.type
            },
            success: response => {
                if (response.success) {
                    modalsStore.hideModal(this.props.popupId);
                    toastSuccess(response.message);
                } else {
                    this.setState({error: response.error});
                }
                updateInfo();
            },
            type: 'POST'
        });
    }

    changeUserCredentialHandler(e) {
        const exceedsLength = event => event.target.value.length > (this.state.type === SEND_TO_USER_TYPE
            ? USER_NAME_LENGTH
            : EMAIL_NAME_LENGTH);

        if (e && !exceedsLength(e)) {
            this.setState({userCredential: e.target.value});
            this.findUserOrEmail(e);
        }
    }

    userCredentialInputClasses = () => {
        const classes = ['popup-form__row'];

        if (this.state.error) {
            classes.push('field-error');
        }

        return classes.join(' ');
    };

    render() {
        const nameCheckedClass = this.state.checked ? ' field__input--exist-state' : ' field__input--inexist-state';
        const signName = this.state.checked ? 'svg-check' : 'svg-close';
        const labelText = I18n.t(this.state.type === SEND_TO_USER_TYPE
            ? 'please_enter_user_nickname'
            : 'please_enter_user_email');

        return <PopupDialog id={this.props.popupId}>
            <div className="popup__content popup__content--small">
                <div className="modal-popup-content">
                    <div className="tabs">
                        <div className="tabs__header tabs__header--auth">
                            <div className="tabs__inner__header">
                                <a
                                    href="#"
                                    onClick={this.clickTabHandler.bind(this, SEND_TO_USER_TYPE)}
                                    className={`tabs__link tabs__link--btn tabs__link--popup${
                                        activeTabClass(this.state.type, SEND_TO_USER_TYPE)}`}
                                >Egb User</a>
                                <a
                                    href="#"
                                    onClick={this.clickTabHandler.bind(this, SEND_TO_EMAIL_TYPE)}
                                    className={`tabs__link tabs__link--btn tabs__link--popup${
                                        activeTabClass(this.state.type, SEND_TO_EMAIL_TYPE)}`}>Email</a>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={this.sendGift}>
                        <div className={this.userCredentialInputClasses()}>
                            <label htmlFor="send_gift_username_or_email">{labelText}</label>
                            <div className="field">
                                <div className="field__wrap">
                                    <input
                                        id="send_gift_username_or_email"
                                        type="text"
                                        ref={this.userCredentialInput}
                                        onChange={this.changeUserCredentialHandler}
                                        value={this.state.userCredential}
                                        className={`field__input${ nameCheckedClass}`}
                                        name="send_gift_username_or_email"
                                    />
                                    <span className="field__state">
                                        <svg height="12px" width="12px">
                                            <use xlinkHref={`#${signName}`}/>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="field__error-hint">{this.state.error}</div>
                        </div>
                        <div className="simple-popup__text center">
                            <button className="btn">{I18n.t('send_gift')}</button>
                        </div>
                    </form>
                </div>
            </div>
        </PopupDialog>;
    }
}
