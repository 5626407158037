import I18n from 'app/i18n';
import NotificationStore from 'app/store/notifications';
import React from 'react';
import {requestPermission, notificationsAvailableInBrowser, notificationsStatusIsDefault} from 'app/firebase';

const permissionAsked = () => window.localStorage.getItem('permissionAsked') === 'true';

const setPermissionFlag = () => {
    window.localStorage.setItem('permissionAsked', 'true');
};

export default class Subscription extends React.PureComponent {
    static inform() {
        if (!notificationsAvailableInBrowser() || !notificationsStatusIsDefault() || permissionAsked()) {
            return;
        }
        NotificationStore.add({showCloseButton: false,
            timeout: false,
            type: 'subscription_browser_notifications'});
    }

    handleSubscription = e => {
        e.preventDefault();
        requestPermission();
        this.props.onClose();
    };

    handleClose = e => {
        e.preventDefault();
        setPermissionFlag();
        this.props.onClose();
    };

    render() {
        return <div className="notification__content">
            <div className="notification__content-wrap">
                <div className="notification__title">{I18n.t('egb_notifications')}</div>
                <div className="notification__text">{I18n.t('get_notified_of_the_latest_news_in_your_browser')}</div>
            </div>
            <div className="notification__button-holder notification__button-holder--2btn">
                <div className="btn" onClick={this.handleSubscription}>{I18n.t('subscribe')}</div>
                <div className="btn btn--gray-invert js-close-block-btn" onClick={this.handleClose}>
                    {I18n.t('not_yet')}
                </div>
            </div>
        </div>;
    }
}
