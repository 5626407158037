import React, {Component, ReactNode} from 'react';
import {Link} from 'react-router-dom';
import doubleArrDownSvg from 'images/svg_icons/double-arr-down.svg';
import SliderContainer from 'app/components/slider_container';
import {scrollToTop} from 'app/utils';
import SmartPageLoader from 'app/components/reusable/smart_loaders/SmartPageLoader';
import {inject, observer} from 'mobx-react';
import {ArticlesStore} from 'app/store/ArticlesStore';

interface ArticleAsideContentProps {
    link: string
    title: string
    children?: React.ReactNode
    articlesStore?: ArticlesStore
}

@inject('articlesStore')
@observer
export default class ArticleAsideContent extends Component<ArticleAsideContentProps> {
    state = {opened: true};

    rootElement: React.RefObject<HTMLDivElement> = React.createRef();

    handlerShowContent = (): void => {
        this.setState({opened: !this.state.opened});
    };

    render(): ReactNode {
        const {opened} = this.state;
        const {title, link, children} = this.props;
        return <div ref={this.rootElement} className="chat chat_promotions_news">
            <div className="chat__heading">
                <div className="chat__title">
                    <div className="aside-preview-post__title">
                        <Link onClick={scrollToTop} to={link}>{title}</Link>
                    </div>
                </div>
                <div className="chat__controls">
                    <button
                        className={`act chat__opener ${opened ? 'opener-active' : ''}`}
                        onClick={this.handlerShowContent}
                    >
                        <img src={doubleArrDownSvg} alt="opener" height="12px" width="12px"/>
                    </button>
                </div>
            </div>
            {this.props.articlesStore.loadingMainArticles
                ? <div className="mobile-loader-articles">
                    <SmartPageLoader/>
                </div>
                : <SliderContainer opened={opened}>
                    <div className="aside-preview-post__content">
                        {children}
                    </div>
                </SliderContainer>}
        </div>;
    }
}
