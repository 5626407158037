import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';

interface Props {
    onClick: () => void
}

export default class PokerMenuButtonQuit extends Component<Props> {
    render(): ReactNode {
        return <button className="poker-menu-container-button quit" onClick={this.props.onClick}>
            <svg className="svg-default"><use xlinkHref="#svg-quit-icon"/></svg>
            <span className="name">{I18n.t('poker_bet_exit_table')}</span>
        </button>;
    }
}
