import {PopupDialog} from 'app/utils/modals/PopupDialog';
import React, {ReactNode, RefObject} from 'react';
import {revertNoScroll} from 'app/utils/revertNoScroll';
import SmartLoader from '../reusable/SmartLoader';


export interface PopupStatProps {
    widgetUrl: string
    show: boolean
    popupId?: string
}

interface PopupStatState {
    loading: boolean
}

type EventLoaded = {
    type: 'LOADED'
};

type EventDataAvailability = {
    type: 'DATA'
    available: boolean
};

type EventClose = {
    type: 'CLOSE'
};

type EventResize = {
    type: 'RESIZE'
    height: number
};

type EventScrollTop = {
    type: 'SCROLL_TOP'
};

type WidgetEvent = EventLoaded | EventClose | EventResize | EventScrollTop | EventDataAvailability;

export default class PopupStat extends React.Component<PopupStatProps, PopupStatState> {
    constructor(props: PopupStatProps) {
        super(props);
        this.state = {
            loading: true
        };
    }

    iframe: RefObject<HTMLIFrameElement> = React.createRef();

    componentDidMount(): void {
        if (this.iframe.current) {
            this.subscribeToEvents(this.iframe.current, event => this.onLoad(event));
        }
    }

    onClose = ():void => {
        revertNoScroll();
    };

    subscribeToEvents(
        iframe: HTMLIFrameElement,
        onMessage: (event: WidgetEvent) => void,
        onError?: (err: Error) => void
    ): () => void {
        const handleMessage = (e: MessageEvent) => {
            if (e.source !== iframe.contentWindow) {
                return;
            }

            try {
                const message = JSON.parse(e.data);

                if (message && typeof message.type === 'string') {
                    onMessage(message);
                }
            } catch (e) {
                onError?.(e);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => window.removeEventListener('message', handleMessage);
    }


    onLoad = (event: WidgetEvent): void => {
        if (event.type === 'DATA') {
            this.setState({loading: false});
        }
    };


    render(): ReactNode {
        const {
            widgetUrl,
            show
        } = this.props;


        return <>
            {show
                ? <PopupDialog id={this.props.popupId} setFocus onClose={this.onClose}>

                    {this.state.loading && <div className="wrapper-loader-stat"><SmartLoader/></div>}
                    <iframe ref={this.iframe}
                        className={`iframe-stat-line${this.state.loading ? '' : ' active'}`}
                        src={widgetUrl}
                    />

                </PopupDialog>
                : null}
        </>;
    }
}
