import Head from 'app/components/cashout/cashout_history/Head';
import HeadMobile from 'app/components/cashout/cashout_history/HeadMobile';
import Row from 'app/components/cashout/cashout_history/Row';
import RowMobile from 'app/components/cashout/cashout_history/RowMobile';
import React from 'react';
import I18n from 'app/i18n';
import {isDesktop} from 'app/utils';
import TableEmptyState from 'app/components/table/empty_state';
import ShowMore from 'app/utils/ShowMore';

const CashoutHistory = props => {
    const Component = isDesktop() ? Row : RowMobile;
    return <div className="cashout">
        <div className="history--wrap">
            <h2 className="title">{I18n.t('user.history_header')}</h2>
            <div className="history">
                <div className="table-adaptive-width">
                    <table className="table-history">
                        {isDesktop() ? <Head/> : <HeadMobile/>}
                        <tbody>
                            {props.latest_cashouts.map(cashout => <Component
                                key={cashout.id}
                                {...cashout}
                                cancelCashout={props.cancelCashout}
                                submitting={props.submitting}
                                cancellingCashoutId={props.cancellingCashoutId}
                            />)}
                        </tbody>
                    </table>
                </div>
                <ShowMore onClickHandler={props.loadMore.bind(null, false)} has_more={props.has_more}/>
                <TableEmptyState
                    arrayLength={props.latest_cashouts.length}
                    text={I18n.t('you_have_no_cash_outs_yet')}
                />
            </div>
        </div>
    </div>;
};

export default CashoutHistory;
