import React from 'react';
import TableHistoryHead from 'app/components/my_profile/table-history/TableHistoryHead';
import TableHistoryRowExpress from 'app/components/my_profile/table-history/TableHistoryRowExpress';
import {inject, observer} from 'mobx-react';
import {UserBetsStore} from 'app/store/UserBetsStore';
import {isDesktop} from 'app/utils';
import EmptyResults from 'app/components/articles/EmptyResults';
import TableHistoryStubPage from './TableHistoryStubPage';
import ShowMore from 'app/utils/ShowMore';

interface TableHistoryExpressProps {
    userBetsStore?: UserBetsStore
    hasBets: boolean
}

@inject('userBetsStore')
@observer
export default class TableHistoryExpress extends React.Component<TableHistoryExpressProps> {
    state = {
        type: 'expresses'
    };

    loadBets = (): void => {
        this.props.userBetsStore.loadBets(this.state.type);
    };

    render(): React.ReactNode {
        const {userBetsStore} = this.props;
        const {userBets} = userBetsStore;

        return this.props.hasBets
            ? <div className="table-content">
                {isDesktop() && <TableHistoryHead/>}
                <div className="table-content-body table-content-history">
                    {userBets && userBets.map(userBet => <TableHistoryRowExpress
                        key={userBet.id}
                        {...userBet}
                        reloadBets={this.loadBets}/>)}
                </div>
                {userBets.length > 0
                    ? <ShowMore
                        onClickHandler={userBetsStore.updateLoadedBetsHandler}
                        has_more={userBetsStore.filter.has_more}/>
                    : <EmptyResults/>
                }</div>
            : <TableHistoryStubPage route={'/play/express'}/>;
    }
}
