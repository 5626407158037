import React, {PureComponent} from 'react';
import Progress from 'app/components/promo_action/compendium/progress';
import I18n from 'app/i18n';
import {fixed2} from 'app/utils/fixed';
import TableLeaders from 'app/components/promo_action/compendium/table_leaders';
import AsideLinks from 'app/components/promo_action/compendium/strarted_components/AsideLinks';
import Timer from 'app/components/reusable/Timer';

const INITIAL_MONEY_TO_THE_NEXT_POINT = 5;

export default class WithUserComponent extends PureComponent {
    isLeaderboardAvailable() {
        const {first_10_places_show_at, currentTimeStore: {t}} = this.props;

        return first_10_places_show_at && t >= first_10_places_show_at;
    }

    render() {
        const {
            predictions_available,
            predictions_total,
            prediction_points,
            max_points,
            money_to_next_point,
            reset_points_at
        } = this.props;

        return <div className="compendium-aside__content">
            <div className="compendium-aside__row">
                <div className="compendium-aside__progress-column">
                    <Progress {...this.props}/>
                    {reset_points_at
                        ? null
                        : <AsideLinks
                            predictions_available={predictions_available}
                            prediction_points={prediction_points}
                        />}
                </div>
                {this.isLeaderboardAvailable()
                    ? <TableLeaders {...this.props}/>
                    : <div className="compendium-aside__info-column">
                        <div>{I18n.t('compendium.remaining_until_the_next_point')}:</div>
                        <div className="compendium-aside__highlighted">
                            ${fixed2(max_points
                                ? 0
                                : money_to_next_point || INITIAL_MONEY_TO_THE_NEXT_POINT)}
                        </div>
                    </div>}
            </div>
            {reset_points_at
                ? <div className="compendium-aside__row">
                    <AsideLinks
                        predictions_available={predictions_available}
                        prediction_points={prediction_points}
                    />
                    <div className="compendium-aside__timer">
                        <span className="compendium-aside__timer-label">
                            {I18n.t('compendium.points_cost_reset_in')}:
                        </span>
                        <div className="compendium-aside__timer-value">
                            <Timer endTime={reset_points_at}/>
                        </div>
                    </div>
                </div>
                : null}
            {this.isLeaderboardAvailable() && predictions_total
                ? <div className="compendium-aside__row compendium-aside__centered compendium-aside__description">
                    {I18n.t('the_champions_are_almost_determined', {count: predictions_total})}
                </div>
                : null}
        </div>;
    }
}
