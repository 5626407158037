import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';

interface Props {
    onClick: () => void
    satOut: boolean
}

export default class PokerMenuButtonPause extends Component<Props> {
    render(): ReactNode {
        const text = this.props.satOut ? I18n.t('poker_bet_sit_in') : I18n.t('poker_bet_sit_out');

        return <button className="poker-menu-container-button pause" onClick={this.props.onClick}>
            <svg className="svg-default"><use xlinkHref="#svg-pause-icon"/></svg>
            <span className="name">{text}</span>
        </button>;
    }
}
