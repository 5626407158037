import React, {PureComponent, ReactNode} from 'react';
import {timeStampToTime} from 'app/utils';

interface ContentProps {
    date: number
    content: string
}

export default class Content extends PureComponent<ContentProps> {
    render(): ReactNode {
        const {date, content} = this.props;
        return <div className="content__holder">
            <div className="article">
                <div className="content__date">{timeStampToTime(date)}</div>
                <div dangerouslySetInnerHTML={{__html: content}}/>
            </div>
        </div>;
    }
}
