import React, {Component, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import BetSlipController from 'app/components/bet_slip/BetSlipController';

import I18n from 'app/i18n';
import {BetSlipStore} from 'app/store/BetSlipStore';
import {fixed2} from 'app/utils/fixed';
import BetSlipLimitButton from 'app/components/bet_slip/latest_bets/BetSlipLimitButton';

interface BetSlipLimitButtonProps {
    controller?: BetSlipController
    betSlipStore?: BetSlipStore
}

@inject('controller', 'betSlipStore')
@observer
export default class BetSlipLimitIncubatorButtonContainer extends Component<BetSlipLimitButtonProps> {
    render(): ReactNode {
        const {betSlipStore: {amount, type, incubator}} = this.props;
        return <BetSlipLimitButton
            amount={fixed2(this.props.controller.maxWin() - Number(amount))}
            incubator={incubator}
            title={I18n.t('max_dot')}
            type={type}
        />;
    }
}
