import React, {PureComponent, ReactNode, MouseEvent} from 'react';
import InventoryItemInterface from 'app/interfaces/InventoryItemInterface';
import {SimpleArtifactImage} from 'app/components/my_profile/artifacts/image';

interface BetSlipObjectInventoryProps {
    artifact: InventoryItemInterface
    onClick: (artifact: InventoryItemInterface) => void
}

export default class BetSlipObjectInventory extends PureComponent<BetSlipObjectInventoryProps> {
    onClickInventory = (event: MouseEvent<HTMLDivElement>):void => {
        event.preventDefault();
        this.props.onClick(this.props.artifact);
    };

    render(): ReactNode {
        const {artifact} = this.props;
        return <div className="object-inventory" onClick={this.onClickInventory}>
            <SimpleArtifactImage artifact={artifact} imgAttributes={{className: 'image-inventory'}}/>
            {artifact.total_inventory_items > 1 && <div className="number-inventory">
                <svg className="svg-default">
                    <use xlinkHref="#svg-inventory-count-orange"/>
                </svg>
                <span className="number">{artifact.total_inventory_items}</span>
            </div>}
        </div>;
    }
}
