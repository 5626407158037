import BaseNotification from './index';
import {goToRoute, getPathWithoutLocale} from 'app/utils/link_helper';

export default class SimpleNotification extends BaseNotification {
    onClick(e) {
        super.onClick(e);
        if (this.data.url) {
            goToRoute(getPathWithoutLocale(this.tryLangContent(this.data.url)));
        }
    }
}
