import $ from 'jquery';
import React from 'react';
import DefaultShopInfoPopup from './default_shop_info_popup';

export default class ShopInfoPopupGift extends DefaultShopInfoPopup {
    componentDidMount() {
        $('.simple-popup.center.simple-popup--big').removeAttr('class').addClass('popup__content');
    }
    render() {
        return <div>
            <div className="product__preview" style={{margin: '15px 0'}}>
                <img src={this.props.gift_image} alt={this.props.gift_name} title={this.props.gift_name} className="product__preview-image"/>
            </div>
            {this.renderBody()}
        </div>;
    }
}
