import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import {UserStore} from 'app/store/UserStore';
import {inject, observer} from 'mobx-react';
import SmartSelectItem from 'app/components/reusable/SmartSelectItem';
import CollectionInterface from 'app/interfaces/CollectionInterface';
import {UserBetsStore} from 'app/store/UserBetsStore';
import GoBackButton from 'app/components/reusable/GoBackButton';

interface MyBetsFiltersProps {
    active_bets_only?: boolean
    playmoneyNotAvailable?: boolean
    skinsNotAvailable?: boolean
    userStore?: UserStore
    userBetsStore?: UserBetsStore
}

const BETS_FILTERS_TYPES: CollectionInterface[] = [
    {text: 'live_and_prematch', value: 'all'},
    {text: 'single_bets', value: 'simple_bets'},
    {text: 'user_menu_express', value: 'expresses'},
    {text: 'user_menu_live', value: 'live_bets'}
];

const BETS_FILTERS_TYPE_SUGGESTIONS: CollectionInterface[] = [{text: 'suggestions', value: 'suggestions'}];

const BET_TYPE_FILTERS_TYPES: CollectionInterface[] = [
    {text: 'bet_type_filters.all', value: 'all'},
    {text: 'bet_type_filters.active', value: 'active'},
    {text: 'bet_type_filters.won', value: 'won'},
    {text: 'bet_type_filters.lost', value: 'lost'},
    {text: 'bet_type_filters.canceled', value: 'canceled'}
];

const CURRENCY_FILTERS_TYPES: CollectionInterface[] = [
    {text: 'all', value: 'all'},
    {text: 'bet_real_money_label', value: 'money'},
    {text: 'bet_play_money_label', value: 'playmoney'},
    {text: 'skins', value: 'skins'}
];

const CASINO_FILTERS_TYPES: CollectionInterface[] = [
    {text: 'all', value: 'all'},
    {text: 'casino_filters.bet', value: 'bet'},
    {text: 'casino_filters.win', value: 'win'},
    {text: 'casino_filters.rollback', value: 'rollback'}
];

const CASINO_TYPE = 'casino';

@inject('userStore', 'userBetsStore')
@observer
export default class MyBetsFilters extends Component<MyBetsFiltersProps> {
    onChangeCasino = (): void => {
        const {userBetsStore} = this.props;
        userBetsStore.toggleCasino();
        userBetsStore.loadBets(userBetsStore.filter.type);
    };

    componentDidMount(): void {
        const {userBetsStore} = this.props;

        if (userBetsStore.filter.type === CASINO_TYPE && !userBetsStore.filter.casino) {
            userBetsStore.toggleCasino();
        } else if (userBetsStore.filter.type !== CASINO_TYPE && userBetsStore.filter.casino) {
            userBetsStore.toggleCasino();
        }
    }

    componentWillUnmount(): void {
        this.props.userBetsStore.setDefaultAllFilters();
    }

    changeType = (value: string): void => {
        const {userBetsStore} = this.props;
        userBetsStore.setDefaultParams();
        userBetsStore.changeType(value, true);
        userBetsStore.loadBets(userBetsStore.filter.type);
    };

    changeCasinoAction = (value: string): void => {
        this.props.userBetsStore.loadLogs(value);
    };

    isCasino = (): boolean => this.props.userBetsStore.filter.type === CASINO_TYPE;

    render(): ReactNode {
        const {userBetsStore, userStore: {user}} = this.props;
        //* *** Remove after release Incubator2
        const betsFilters = user.enable_incubator2
            ? BETS_FILTERS_TYPES.concat(BETS_FILTERS_TYPE_SUGGESTIONS)
            : BETS_FILTERS_TYPES;
        return <>
            <div className="top-filters">
                <div className="top-filters__title">
                    <GoBackButton/>
                    <h1 className="title">{I18n.t('bet_history')}</h1>
                </div>
                <div className="top-filters__body">
                    <div className="top-filters-row">
                        <label className="top-filters-row__title">
                            {this.isCasino() ? I18n.t('action') : I18n.t('filter_type_bet')}
                        </label>
                        {this.isCasino()
                            ? <SmartSelectItem
                                onChange={this.changeCasinoAction}
                                value={userBetsStore.casinoFilter.action}
                                collection={CASINO_FILTERS_TYPES}
                                nameAttr="select-bet-type-c"
                                htmlId="history_casino_bet_type"
                            />
                            : <SmartSelectItem
                                onChange={this.changeType}
                                value={userBetsStore.filter.type}
                                collection={betsFilters}
                                nameAttr="select-bet-type"
                                htmlId="history_bet_type"
                            />}
                    </div>
                    <div className={`top-filters-row${this.isCasino() ? ' top-filters-row_disabled' : ''}`}>
                        <label className="top-filters-row__title">{I18n.t('filter_type_status')}</label>
                        <SmartSelectItem
                            onChange={userBetsStore.changeBetType}
                            value={userBetsStore.filter.bet_type}
                            collection={BET_TYPE_FILTERS_TYPES}
                            nameAttr="select-bet-status"
                            htmlId="history_bet_status"
                        />
                    </div>
                    <div className={`top-filters-row${this.isCasino() ? ' top-filters-row_disabled' : ''}`}>
                        <label className="top-filters-row__title">{I18n.t('filter_type_currency')}</label>
                        {<SmartSelectItem
                            onChange={userBetsStore.changeBetCurrency}
                            value={userBetsStore.filter.bet_currency}
                            collection={CURRENCY_FILTERS_TYPES}
                            nameAttr="select-bet-currency"
                            htmlId="history_bet_currency"
                        />}
                    </div>
                    <div className="top-filters-row">
                        <div className="button-checkbox">
                            <label
                                className={`label-checkbox${this.isCasino() ? ' active' : ''}`}
                                data-name="label-checkbox-c"
                            >
                                <span className="label-checkbox__label-icon">
                                    <svg className="svg-default">
                                        <use xlinkHref="#svg-tap_bar_play_casino"/>
                                    </svg>
                                </span>
                                <span
                                    className="label-checkbox__label"
                                    title={I18n.t('casino')}
                                >{I18n.t('casino')}</span>
                                <input
                                    checked={this.isCasino()}
                                    className="label-checkbox__input"
                                    type="checkbox"
                                    onChange={this.onChangeCasino}
                                />
                                <span className="label-checkbox__icon">
                                    <svg>
                                        <use xlinkHref="#svg-check"/>
                                    </svg>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>;
    }
}

