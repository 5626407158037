import GameInterface from 'app/interfaces/GameInterface';

export const prepareFilters = (filters: GameInterface[]): string => {
    const cookiesFilters = [];
    filters.forEach(filter => {
        if (filter.val) {
            cookiesFilters.push(filter.id);
        }
    });
    return JSON.stringify(cookiesFilters);
};
