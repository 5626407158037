import I18n from 'app/i18n';
import React, {PureComponent} from 'react';

export default class HeadMobile extends PureComponent {
    render = (): React.ReactNode => <thead>
        <tr>
            <th>
                <span className="title-date">{I18n.t('user.date_time_header')}</span>
            </th>
            <th>
                <span className="title-payment-number">{I18n.t('user.payment_number_header')}</span>
                <span className="title-payment-system">{I18n.t('user.payment_system_header')}</span>
                <span className="title-instant">{I18n.t('instant')}</span>
                <span className="title-status">{I18n.t('user.status_label')}</span>
            </th>
            <th>
                <span className="title-amount">{I18n.t('amount')}</span>
            </th>
        </tr>
    </thead>;
}
