import React, {MouseEvent, PureComponent, ReactNode} from 'react';
import {fixed2} from 'app/utils/fixed';
import BetSlipLatestBet from 'app/components/bet_slip/latest_bets/BetSlipLatestBet';
import {inject} from 'mobx-react';
import BetSlipController from 'app/components/bet_slip/BetSlipController';

interface BetSlipLatestBetsProps {
    bet: string
    controller?: BetSlipController
    title: string
    disabled?: boolean
}

@inject('controller')
export default class BetSlipLatestBetContainer extends PureComponent<BetSlipLatestBetsProps> {
    onClickLastBet = (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        this.props.controller.changeInput(fixed2(this.props.bet));
    };

    render(): ReactNode {
        const {title, disabled} = this.props;
        return <BetSlipLatestBet
            disabled={disabled}
            title={title}
            onClick={this.onClickLastBet}
        />;
    }
}
