import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {showPopup} from 'app/utils/modals/ModalsService';
import {isDesktop} from 'app/utils';

interface ScreenshotPaymentProps {
    screenshot_url: string
}

export default class ScreenshotPayment extends PureComponent<ScreenshotPaymentProps> {
    enlarge = (): void => {
        const {screenshot_url} = this.props;
        showPopup('qr-screenshot', (props: {popupId: string}) => <PopupDialog id={props.popupId}>
            <div className="popup__content simple-popup--big">
                <img className="popup-screenshot-qr" src={screenshot_url} alt={I18n.t('qr_for_payment')}/>
            </div>
        </PopupDialog>);
    };

    render(): ReactNode {
        const {screenshot_url} = this.props;
        return <div className="recipient-details">
            <div className="title-info">
                <span className="caption">{I18n.t('recipient_details')}</span>
                <div className="description">
                    <p>{isDesktop() ? I18n.t('scan_the_qr') : I18n.t('scan_the_qr_mobile')}</p>
                </div>
            </div>
            {!isDesktop() && <a
                href={screenshot_url}
                className="button-adaptive button-adaptive_full-radius"
                target="_blank"
                rel="noreferrer"
                download
            >
                {I18n.t('save_screenshot')}
            </a>}
            <div className="screenshot-image">
                <div className="screenshot-image__open" onClick={this.enlarge}>
                    <img src={screenshot_url} alt={I18n.t('qr_for_payment')}/>
                    <div className="button-adaptive button-adaptive_crystal">
                        <span className="icon">
                            <svg>
                                <use xlinkHref="#svg-zoom-icon"/>
                            </svg>
                        </span>
                        <span className="name">{I18n.t('enlarge')}</span>
                    </div>
                </div>
            </div>
        </div>;
    }
}
