import React, {ChangeEvent, FocusEvent, PureComponent, ReactNode} from 'react';
import {coefColorClass} from 'app/lines/coefColorClass';
import {isPlayMoney} from 'app/lines/isPlayMoney';
import {isDesktop} from 'app/utils';

interface BetSlipInputAmountProps {
    active: boolean
    amount: string
    autoFocus: boolean
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    onFocus: (e: FocusEvent<HTMLInputElement>) => void
    coefIncreased: boolean
    id: string
    title: string
    type: string
}

export default class BetSlipInputAmount extends PureComponent<BetSlipInputAmountProps> {
    inputAmount: React.RefObject<HTMLInputElement> = React.createRef();

    setFocus = (): void => this.inputAmount.current.focus();

    render(): ReactNode {
        const {active, amount, autoFocus, coefIncreased, id, type, title, onFocus, onChange} = this.props;
        const isPM = isPlayMoney(type);
        const coefClass = `${isPM ? 'pm' : ''} ${active ? 'active' : ''}`;
        const currency = isPM ? 'PM' : '$';
        return <div className="cell-box">
            <input id={id}
                autoComplete={'off'}
                type="string"
                step="0.01"
                name={id}
                maxLength={8}
                className={`input-number${coefColorClass(coefIncreased)} ${coefClass}`}
                autoFocus={autoFocus}
                placeholder="0.00"
                value={amount}
                onFocus={onFocus}
                onChange={onChange}
                readOnly={!isDesktop()}
                ref={this.inputAmount}/>
            <label htmlFor={id} className="label-name">{title}</label>
            <label htmlFor={id} className="currency-name">{currency}</label>
        </div>;
    }
}
