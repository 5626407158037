import React, {PureComponent} from 'react';
import $ from 'jquery';
import Decimal from 'decimal.js';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {modalConfirm} from 'app/utils/modals/popups';
import I18n from 'app/i18n';
import {artifactStore, modalsStore} from 'app/store/global';
import AmountInput from './amount_input';
import WithChart from './with_chart';
import {artifactName} from './utils';
import {amountWithSign, serverError} from 'app/utils';
import {toastSuccess} from 'app/components/toasts/liteToast';

const FEE_COEFFICIENTS = 0.15;

export default class SellArtifactPopup extends PureComponent {
    state = {maxRequestPrice: '', price: '', total_price: ''};

    componentDidMount() {
        $.get(`/artifact_orders/requests_data?recipe_id=${this.props.artifact.recipe.id}`, data => {
            this.setState({maxRequestPrice: data.max_price});
        });
    }

    getPriceFromTotalPrice(value) {
        return new Decimal(Number(value)).mul(1 - FEE_COEFFICIENTS).toFixed(2);
    }

    handleFeeChange = e => {
        const {value} = e.target;
        this.setState(() => ({
            price: this.getPriceFromTotalPrice(value),
            total_price: value
        }));
    };

    handleSubmit = e => {
        e && e.preventDefault();
        if (!this.sellAllowed()) {
            return;
        }

        const {artifact} = this.props;

        modalConfirm(I18n.t('sell_artifact_confirm', {artifact: artifactName(artifact)}),
            () => artifactStore.sell(artifact, {...this.state, artifact_id: artifact.id}, data => {
                if (data.status === 200) {
                    toastSuccess(I18n.t('sell_artifact_success', {artifact: artifactName(artifact)}));
                } else {
                    serverError();
                }
                modalsStore.hideModal(this.props.popupId);
            }));
    };

    sellAllowed = () => {
        const minChangePrice = 0.1;
        return this.state.total_price >= minChangePrice;
    };

    handleRequestPrice = e => {
        e.preventDefault();
        this.setState({
            price: this.getPriceFromTotalPrice(this.state.maxRequestPrice),
            total_price: this.state.maxRequestPrice
        });
    };

    render() {
        const {artifact, popupId} = this.props;
        return <PopupDialog
            id={popupId}
            setFocus={true}
        >
            <WithChart artifact={artifact}>
                <form className="sell-inventory__popup--form" onSubmit={this.handleSubmit}>
                    <div className="sell-inventory__popup--form-content">
                        <div className="sell-inventory__popup--form__row">
                            <AmountInput
                                value={this.state.price}
                                stateName="price"
                                disabled={true}
                                label={I18n.t('you_receive')}
                            />
                            <AmountInput
                                onChangeHandler={this.handleFeeChange}
                                value={this.state.total_price}
                                stateName="total_price"
                                helpTxt={I18n.t('includes_fees')}
                                label={I18n.t('buyer_pays')}
                            />
                        </div>
                        {Number(this.state.maxRequestPrice) > 0 && <div className="sell-inventory__popup--form__row">
                            <div className="sell-inventory__popup--form__item">
                                <div className="sell-inventory__popup--form__item-wrap">
                                    <label className="label-max-price">
                                        <span>{I18n.t('instant_sale_price')}: </span>
                                    </label>
                                    <div className="field">
                                        <div className="field__wrap">
                                            <a href="#" onClick={this.handleRequestPrice}>
                                                {amountWithSign(this.state.maxRequestPrice)}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="field__error-hint"/>
                            </div>
                        </div>}
                    </div>
                    <div className="sell-inventory__popup--form-footer">
                        <div className="sell-inventory__popup--form-footer__row">
                            <div className="sell-inventory__popup--actions margin_left_a">
                                <button className={`btn${ this.sellAllowed() ? '' : ' btn--disable'}`} type="submit">
                                    {I18n.t('put_it_up_for_sale')}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </WithChart>
        </PopupDialog>;
    }
}
