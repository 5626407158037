import React, {PureComponent} from 'react';
import {FILTER_GAME_CS, FILTER_GAME_DOTA} from 'app/consts';
import PopupVideoBgDefaultWebm from 'videos/lootbox/video-default.webm';
import PopupVideoBgCSGOWebm from 'videos/lootbox/video-csgo.webm';
import PopupVideoBgDota2Webm from 'videos/lootbox/video-dota.webm';
import PopupVideoBgDefaultMp4 from 'videos/lootbox/video-default.mp4';
import PopupVideoBgCSGOMp4 from 'videos/lootbox/video-csgo.mp4';
import PopupVideoBgDota2Mp4 from 'videos/lootbox/video-dota.mp4';
import PopupVideoBgDefaultOgv from 'videos/lootbox/video-default.ogv';
import PopupVideoBgCSGOOgv from 'videos/lootbox/video-csgo.ogv';
import PopupVideoBgDota2Ogv from 'videos/lootbox/video-dota.ogv';

interface PrizeBackgroundProps {
    steam_skin: {
        game_id: number;
    }
}

export default class PrizeBackground extends PureComponent<PrizeBackgroundProps> {
    get videoBackground() {
        switch (true) {
        case this.props.steam_skin && this.props.steam_skin.game_id === FILTER_GAME_CS:
            return {
                mp4: PopupVideoBgCSGOMp4,
                ogv: PopupVideoBgCSGOOgv,
                webm: PopupVideoBgCSGOWebm
            };
        case this.props.steam_skin && this.props.steam_skin.game_id === FILTER_GAME_DOTA:
            return {
                mp4: PopupVideoBgDota2Mp4,
                ogv: PopupVideoBgDota2Ogv,
                webm: PopupVideoBgDota2Webm
            };
        default:
            return {
                mp4: PopupVideoBgDefaultMp4,
                ogv: PopupVideoBgDefaultOgv,
                webm: PopupVideoBgDefaultWebm
            };
        }
    }

    render() {
        return <video
            className="prize-won-background-video"
            autoPlay
            playsInline
            loop>
            <source
                src={this.videoBackground.webm}
                type='video/webm; codecs="vp8, vorbis"'
            />
            <source
                src={this.videoBackground.mp4}
                type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
            />
            <source
                src={this.videoBackground.ogv}
                type='video/ogg; codecs="theora, vorbis"'
            />
        </video>;
    }
}
