import React, {MouseEvent, PureComponent, ReactNode} from 'react';
import {ARTIFACT_MONEY, PLAY_MONEY, REAL_MONEY} from './bet_maker';
import I18n from 'app/i18n';
import {userStore} from 'app/store/global';

interface TabsProps {
    amount_bet_type: string
    handleSwitchTab: (tab: string, e: MouseEvent) => void
}

export default class Tabs extends PureComponent<TabsProps> {
    onClick = (tab: string, e: MouseEvent<HTMLAnchorElement>): void => this.props.handleSwitchTab(tab, e);

    onClickRealMoney = (e: MouseEvent<HTMLAnchorElement>): void => this.onClick(REAL_MONEY, e);

    onClickPlayMoney = (e: MouseEvent<HTMLAnchorElement>): void => this.onClick(PLAY_MONEY, e);

    onClickArtifact = (e: MouseEvent<HTMLAnchorElement>): void => this.onClick(ARTIFACT_MONEY, e);

    get isRealMoney(): boolean {
        return this.props.amount_bet_type === REAL_MONEY;
    }

    get isPlayMoney(): boolean {
        return this.props.amount_bet_type === PLAY_MONEY;
    }

    get isArtifactMoney(): boolean {
        return this.props.amount_bet_type === ARTIFACT_MONEY;
    }

    render(): ReactNode {
        return <div className="tabs__header tabs__header--popup">
            <a
                href="#"
                onClick={this.onClickRealMoney}
                className={`tabs__link tabs__link--btn tabs__link--popup ${this.isRealMoney ? 'active-link' : ''}`}>
                {I18n.t('bet_real_money_label')}
            </a>
            <a
                href="#"
                onClick={this.onClickPlayMoney}
                className={`tabs__link tabs__link--btn tabs__link--popup ${this.isPlayMoney ? 'active-link' : ''}`}>
                {I18n.t('bet_play_money_label')}
            </a>
            {userStore.user.artifact_bet && <a
                href="#"
                onClick={this.onClickArtifact}
                className={`tabs__link tabs__link--btn tabs__link--popup ${this.isArtifactMoney ? 'active-link' : ''}`}>
                {I18n.t('skins')}
            </a>}
        </div>;
    }
}
