import React, {Component} from 'react';
import I18n from 'app/i18n';
import CollectionInterface from 'app/interfaces/CollectionInterface';

interface PropsInterface {
    value?: string | number
    onChange?: (value: string | number) => void
    collection: CollectionInterface[]
    disabled?: boolean
    name?: string
    translation_key?: string
    placeholder?: string
    children?: React.ReactNode
    className?: string
    eventWithSelect?: (id: string) => void
    search?: boolean
    nameAttr?: string
    htmlId: string
}

interface StateInterface {
    opened: boolean
    selected: string | number
}

export default class SmartSelectItem extends Component<PropsInterface, StateInterface> {
    constructor(props: PropsInterface) {
        super(props);
        this.state = {
            opened: false,
            selected: props.value
        };
    }

    componentDidUpdate(): void {
        if (this.props.value !== this.state.selected) {
            this.setState({selected: this.props.value});
        }
    }

    rootElement: React.RefObject<HTMLDivElement> = React.createRef();

    componentDidMount(): void {
        document.addEventListener('click', this.handleCloseSelect, true);
    }

    componentWillUnmount(): void {
        document.removeEventListener('click', this.handleCloseSelect, true);
    }

    handleOpenSelect = (e: React.MouseEvent<HTMLDivElement>): void => {
        e.preventDefault();
        this.setState({opened: !this.state.opened});
    };

    handleCloseSelect = (e: MouseEvent): void => {
        if (this.rootElement.current && !this.rootElement.current.contains(e.target as HTMLDivElement)) {
            this.setState({opened: false});
        }
    };

    getSelectedValue = (): string => {
        const element = this.props.collection.find(el => el.value === this.state.selected);
        return I18n.t(`${element ? element.text : this.props.collection[0].text}`);
    };

    setSelectedValue = (value: string | number): void => {
        this.props.onChange(value);
        this.setState({opened: false, selected: value});
    };

    render(): React.ReactNode {
        return <div ref={this.rootElement} className="select-container select-container_item" id={this.props.htmlId}>
            <div
                onClick={this.handleOpenSelect}
                className={`select-header select-header${this.state.opened ? '_active' : ''}`}
                data-name={this.props.nameAttr}
            >
                <span className="select-header__value">{this.getSelectedValue()}</span>
            </div>
            <div className={`select-body select-body${this.state.opened ? '_active' : ''}`}>
                <ul className="select-body__list">
                    {this.props.collection?.map(item => <li key={item.text}>
                        <div className="select-body__list-item">
                            <label className="label-checkbox" onClick={() => this.setSelectedValue(item.value)}>
                                <span className="label-checkbox__label"
                                    title={I18n.t(`${item.text}`)}>
                                    {I18n.t(`${item.text}`)}
                                </span>
                            </label>
                        </div>
                    </li>)}
                </ul>
            </div>
        </div>;
    }
}
