import React, {PureComponent} from 'react';
import {getLinkPath} from 'app/utils/link_helper';
import pick from 'lodash/pick';
import $ from 'jquery';
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import I18n from 'app/i18n';
import {amountWithSign} from 'app/utils';

export default class SimpleLineChart extends PureComponent {
    state = {
        graphdata: [],
        period: 'lifetime'
    };

    static propTypes = {
        graphdata: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.string,
            price: PropTypes.string
        }))
    };

    static HEIGHT = 200;

    static WIDTH = 850;

    get recipeId() {
        return this.props.recipe && this.props.recipe.id || this.props.artifact.recipe.id;
    }

    handleLoadingGraphdata = () => {
        $.ajax(getLinkPath(`/artifact_orders/${this.recipeId}`), {
            data: pick(this.state, 'period'),
            success: response => this.setState(() => ({
                graphdata: response.map(data => ({
                    date: data.date,
                    price: Number(data.price)
                }))
            }))
        });
    };

    componentDidUpdate(prevProps) {
        if (!isEqual(this.props, prevProps)) {
            this.handleLoadingGraphdata();
        }
    }

    componentDidMount = () => this.handleLoadingGraphdata();

    tickFormat = value => amountWithSign(value);

    tooltipFormat = (value, name) => [this.tickFormat(value), I18n.t(name)];

    render() {
        const {HEIGHT, WIDTH} = this.constructor;
        const chartStyle = {
            height: HEIGHT,
            width: '100%'
        };
        const tooltipItemStyle = {color: 'black'};
        const lineChartMargin = {
            bottom: 0,
            left: 0,
            right: 0,
            top: 0
        };

        return <div style={chartStyle}>
            <ResponsiveContainer>
                <LineChart
                    width="100%"
                    height={HEIGHT}
                    data={this.state.graphdata}
                    margin={lineChartMargin}
                >

                    <rect x="60" y="0" width={WIDTH - 40} height={HEIGHT - 30} fill="#363738"/>
                    <XAxis
                        dataKey="date"
                        stroke="grey"
                        tickLine={false}
                    />
                    <YAxis
                        width={60}
                        stroke="grey"
                        tickLine={false}
                        tickFormatter={this.tickFormat}
                    />
                    <CartesianGrid strokeDasharray="1 0" stroke="#000" strokeWidth="2"/>
                    <Tooltip
                        itemStyle={tooltipItemStyle}
                        formatter={this.tooltipFormat}
                    />
                    <Line
                        type="monotone"
                        dataKey="price"
                        stroke="yellow"
                        dot={false}
                        strokeWidth="2"
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>;
    }
}
