import React, {ReactNode} from 'react';
import I18n from 'app/i18n';

interface Props {
    onSitIn?: () => void
}

export default class PokerButtonTakePlace extends React.Component<Props> {
    render(): ReactNode {
        const {onSitIn} = this.props;

        return <span className="poker-button-take-place" onClick={onSitIn}>{I18n.t('poker_take_place')}</span>;
    }
}
