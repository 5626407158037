import LineInterface from 'app/interfaces/LineInterface';

export const incubator2WithCoef = (line: LineInterface, gamerNum: number): string | boolean => {
    if (!line) {
        return false;
    }

    const res = line.incubator_2_bet[`coef_${gamerNum}`];

    if (!res || res === '' || res === 0) {
        return false;
    }
    return res;
};
