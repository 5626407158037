import FailedButton from 'app/components/deposit/FailedButton';
import FixedButton from 'app/components/deposit/FixedButton';
import P2pUploadButton from 'app/components/DocumentsUpload/P2pUploadButton';
import React, {Component, Fragment, MouseEvent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {betMoney, isDesktop, localDateTimeFromISO} from 'app/utils';
import TableEmptyState from '../table/empty_state';
import {inject, observer} from 'mobx-react';
import {modalConfirm} from 'app/utils/modals/popups';
import CurrentP2pDepositInterface from 'app/interfaces/CurrentP2pDepositInterface';
import P2pDepositsStoreProps from 'app/store/P2pDepositsStoreProps';
import {DepositsStoreProps} from 'app/store/DepositsStore';
import ShowMore from 'app/utils/ShowMore';
import {DepositHistoryItem} from './DepositHistoryItem';

interface DepositHistoryProps extends P2pDepositsStoreProps, DepositsStoreProps {}

@inject('depositsStore', 'p2pDepositsStore')
@observer
export class DepositHistory extends Component<DepositHistoryProps> {
    cancelP2pDeposit = (e: MouseEvent<HTMLAnchorElement>, deposit: CurrentP2pDepositInterface): void => {
        e.preventDefault();
        const {id, amount, auto_waiting} = deposit;

        if (auto_waiting) {
            return;
        }
        modalConfirm(
            I18n.t('undo_deposit', {amount: betMoney({amount})}),
            () => this.props.p2pDepositsStore.cancelOneP2pDeposit(id)
        );
    };

    fixP2pDeposit = (deposit: CurrentP2pDepositInterface): void => {
        const {id} = deposit;
        this.props.p2pDepositsStore.fixP2pDeposit(id);
    };

    onUpload = (): void => this.props.p2pDepositsStore.loadCurrentP2pDeposits();

    loadMore = (): Promise<void> => {
        const {loadMoreHistory} = this.props.depositsStore;
        return loadMoreHistory().promise();
    };

    render(): ReactNode {
        const {current_p2p_deposits = []} = this.props.p2pDepositsStore;
        const {historyDeposits, historyHasMore, currentOfelospayDeposits} = this.props.depositsStore;
        return <div className="history--wrap">
            <h2 className="title">{I18n.t('user.history_header')}</h2>
            <div className="history">
                {isDesktop() && <div className="table-adaptive-width">
                    <table className="table-history">
                        <thead>
                            <tr>
                                <th>
                                    <span className="title-date">{I18n.t('user.date_time_header')}</span>
                                </th>
                                <th>
                                    <p className="section">
                                        <span
                                            className="title-payment-number"
                                        >{I18n.t('user.payment_number_header')}</span>
                                    </p>
                                    <p className="section">
                                        <span className="title-operator">{I18n.t('user.payment_operator_header')}</span>
                                    </p>
                                </th>
                                <th>
                                    <span className="title-payment-system">{I18n.t('user.payment_system_header')}</span>
                                </th>
                                <th>
                                    <span className="title-amount">{I18n.t('amount')}</span>
                                </th>
                                <th>
                                    <span className="title-status">{I18n.t('user.status_label')}</span>
                                </th>
                                <th>
                                    <span className="title-button"/>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {current_p2p_deposits.map(d => <tr key={d.id}>
                                <td>
                                    <span className="value-date">{localDateTimeFromISO(d.accepted_at)}</span>
                                </td>
                                <td>
                                    <p className="section">
                                        <span className="value-payment-number">{d.cashout_id}</span>
                                    </p>
                                    <p className="section">
                                        <span className="value-operator">{I18n.t('egb')}</span>
                                    </p>
                                </td>
                                <td>
                                    <span className="value-payment-system">{d.system}</span>
                                </td>
                                <td>
                                    <span className="value-amount">{betMoney(d)}</span>
                                </td>
                                <td>
                                    <span className="value-status">{d.status}</span>
                                </td>
                                <td>
                                    {(d.waiting || d.not_valid) && <div className="line-group">
                                        <P2pUploadButton p2p_deposit_invoice={d} onUpload={this.onUpload}/>
                                        {d.not_valid &&
                                        <FixedButton deposit={d} fixP2pDeposit={this.fixP2pDeposit}/>}
                                        {!d.auto_deposit_status &&
                                        <FailedButton deposit={d} cancelP2pDeposit={this.cancelP2pDeposit}/>}
                                    </div>}
                                </td>
                            </tr>)}
                            {currentOfelospayDeposits.map(d => <DepositHistoryItem deposit={d} key={d.id} ofelospay/>)}
                            {historyDeposits.map(d => <DepositHistoryItem deposit={d} key={d.id} ofelospay={false}/>)}
                        </tbody>
                    </table>
                </div>}

                {!isDesktop() && <table className="table-history for-mobile">
                    <thead>
                        <tr>
                            <th>
                                <span className="title-date">{I18n.t('user.date_time_header')}</span>
                            </th>
                            <th>
                                <span className="title-payment-number">{I18n.t('user.payment_number_header')}</span>
                                <span className="title-operator">{I18n.t('user.payment_operator_header')}</span>
                                <span className="title-payment-system">{I18n.t('user.payment_system_header')}</span>
                                <span className="title-status">{I18n.t('user.status_label')}</span>
                            </th>
                            <th>
                                <span className="title-amount">{I18n.t('amount')}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {current_p2p_deposits.map(d => <Fragment key={d.id}>
                            <tr>
                                <td>
                                    <span className="value-date">{localDateTimeFromISO(d.accepted_at)}</span>
                                </td>
                                <td>
                                    <span className="value-payment-number">{d.cashout_id}</span>
                                    <span className="value-operator">{I18n.t('egb')}</span>
                                    <span className="value-payment-system">{d.system}</span>
                                    <span className="value-status">{d.status}</span>
                                </td>
                                <td>
                                    <span className="value-amount">{betMoney(d)}</span>
                                </td>
                            </tr>
                            {(d.waiting || d.not_valid) && <tr className="no-border">
                                <td colSpan={3}>
                                    <div className="line-group">
                                        <P2pUploadButton p2p_deposit_invoice={d} onUpload={this.onUpload}/>
                                        {d.not_valid &&
                                        <FixedButton deposit={d} fixP2pDeposit={this.fixP2pDeposit}/>}
                                        {!d.auto_deposit_status &&
                                        <FailedButton deposit={d} cancelP2pDeposit={this.cancelP2pDeposit}/>}
                                    </div>
                                </td>
                            </tr>}
                        </Fragment>)}
                        {historyDeposits.map((d, i) => <tr key={`dep${i}`}>
                            <td>
                                <span className="value-date">{d.date}</span>
                            </td>
                            <td className="no-commas">
                                <span className="value-id">{d.id}</span>
                                <span className="value-operator">{d.operator}</span>
                                <span className="value-payment-system">{d.system}</span>
                                <span className="value-status">{I18n.t('user.status_completed')}</span>
                            </td>
                            <td>
                                <span className="value-gift-amount">{d.gift_amount || betMoney(d)}</span>
                            </td>
                        </tr>)}
                    </tbody>
                </table>}

                <ShowMore onClickHandler={this.loadMore} has_more={historyHasMore}/>
                <TableEmptyState arrayLength={historyDeposits.length} text={I18n.t('you_have_no_payments_yet')}/>
            </div>
        </div>;
    }
}
