import {objectToURIComponent} from 'app/utils/uri_helper';

export function addGaEvent(event, action, label) {
    if (typeof window !== 'undefined' && window.dataLayer) {
        window.dataLayer.push({event: event, action: action, label: label});
    } else if (typeof ServiceWorkerGlobalScope !== 'undefined') {
        sendAnalyticsEvent('event', event, action, label);
    } else {
        console.log(arguments);
    }
}

function sendAnalyticsEvent(event, category, action, label) {
    if (!self.trackingId) {
        return console.log('Sending analytics event: ' + category + '/' + action);
    }

    const payloadData = {
        v: 1, //version
        cid: self.origin, //endpoint
        tid: self.trackingId,
        t: event,
        ec: category,
        ea: action,
        el: label || 'service_worker'
    };

    fetch('https://www.google-analytics.com/collect', {
        method: 'post',
        body: objectToURIComponent(payloadData)
    });
}
