import React, {PureComponent} from 'react';
import HoneycombGroup from './honeycomb_group';
import {equippedAtDescendingSort} from '../artifacts/utils';
import ArtifactSlot from './artifact_slot';

const SINGLE_SLOT = 1;

export default class Equipped extends PureComponent {
    render() {
        const {artifact, type, multi} = this.props;
        const emptySlots = multi || SINGLE_SLOT;
        return <div className={`hero__warrior-inventory hero__inventory--${ type}`}>
            {artifact.length > 0 && <div className={`hero__inventory--selected hero__inventory--${type}--selected`}/>}
            <HoneycombGroup multi={multi} type={type}>
                {equippedAtDescendingSort(artifact).map(a => <ArtifactSlot key={a.id} artifact={a}/>)}
                {[...Array(Math.max(emptySlots - artifact.length, 0))]
                    .map((s, i) => <div key={`s${ i}`} className="hero__slot"/>)}
            </HoneycombGroup>
        </div>;
    }
}
