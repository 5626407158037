import React, {PureComponent} from 'react';
import Progress from 'app/components/promo_action/compendium/progress';
import I18n from 'app/i18n';
import {Link} from 'react-router-dom';
import {getPromoLinkPath} from 'app/utils';
import TakePartButton from 'app/components/compendium/take_part_button';

export default class WithoutUserComponent extends PureComponent {
    render() {
        return <div className="compendium-aside__content compendium-aside__centered">
            <Progress
                {...this.props}
                styleTop={0}
            />
            <div className="compendium-aside__text">
                {I18n.t('compendium.has_started')}
            </div>
            <TakePartButton
                className="btn btn--invert"
                isRenderable
            />

            <div className="compendium-aside__links">
                <Link to={getPromoLinkPath('/predictions')}>
                    {I18n.t('compendium.compendium_table')}
                </Link>
                <Link to={getPromoLinkPath('/rating')}>
                    {I18n.t('compendium.results_table')}
                </Link>
            </div>
        </div>;
    }
}
