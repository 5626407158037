import React, {PureComponent} from 'react';
import {ArtifactImage} from 'app/components/my_profile/artifacts/image';

export default class InventoryItem extends PureComponent {
    onClick = e => {
        e.preventDefault();
        const {artifact} = this.props;

        if (this.isItemUnavailable) {
            return;
        }

        this.props.onClick(artifact);
    };

    get isItemUnavailable() {
        const {artifact: {sending_prohibited, tradable}} = this.props;
        return sending_prohibited || !tradable;
    }

    render() {
        const {artifact} = this.props;
        return <div
            className={`hero__slot ${this.isItemUnavailable ? 'hero__slot--unavailable' : ''}`}
            onClick={this.onClick}
        >
            <ArtifactImage
                artifact={artifact}
                showHeroSlotCount
            />
        </div>;
    }
}
