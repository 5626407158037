import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {Link} from 'react-router-dom';
import {linkIncubatorMyLines, linkIncubatorHelpStaticPage, linkIncubatorNew} from 'app/utils/app_links';
import {SignUpButton} from 'app/components/auth/SignUpButton';
import {isDesktop, isGuest} from 'app/utils';
import {inject, observer} from 'mobx-react';
import {UserStore} from 'app/store/UserStore';

interface IncubatorFilterButtonsProps {
  userStore: UserStore
}

@inject('incubatorShiftStore', 'userStore')
@observer
export default class IncubatorFilterButtons extends PureComponent<IncubatorFilterButtonsProps> {
    createButton = (text: string, link: string): ReactNode => {
        const buttonClass = 'button-adaptive button-adaptive_full-thin';
        return isGuest()
            ? <SignUpButton className={buttonClass} title={text} usingOnTab={'PLAY'}/>
            : <Link className={buttonClass} to={link}> {text} </Link>;
    };

    render(): ReactNode {
        const {userStore} = this.props;
        const enable_incubator = userStore && userStore.user ? userStore.user.enable_incubator : false;
        return <div className="incubator-line-buttons">
            <div className="left">
                {enable_incubator && this.createButton(I18n.t('create_line'), linkIncubatorNew())}
                {this.createButton(I18n.t('my_lines'), linkIncubatorMyLines())}
            </div>
            <div className="right">
                <Link
                    className="button-adaptive button-adaptive_instyle"
                    to={linkIncubatorHelpStaticPage()}
                >
                    <span className="button-icon">
                        <svg className="svg-default">
                            <use xlinkHref="#svg-incubator-about"/>
                        </svg>
                    </span>
                    <span className="button-name">{I18n.t(isDesktop()
                        ? 'incubator_user_guide'
                        : 'incubator_user_guide_mob')}</span>
                </Link>
            </div>
        </div>;
    }
}
