import LootInterface from 'app/interfaces/LootInterface';
import I18n from 'app/i18n';
import {fixed2int} from 'app/utils/fixed';
import {stringTypeOfBonus} from 'app/utils/stringTypeOfBonus';
import {translatedName} from 'app/utils/translations_helper';
import upperFirst from 'lodash/upperFirst';
import InventoryItemInterface from 'app/interfaces/InventoryItemInterface';

export const prizeName = (prize: LootInterface): string => {
    const {points_amount, money_amount, bonus_amount, bonus, recipe, steam_skin} = prize;

    switch (true) {
    case Number(points_amount) > 0:
        return `${points_amount} ${upperFirst(I18n.t('points', {count: points_amount}))}`;
    case Number(money_amount) > 0:
        return `$${fixed2int(money_amount)}`;
    case bonus !== null:
        return `$${fixed2int(bonus_amount)} 
                ${stringTypeOfBonus(bonus)}`;
    case recipe !== null:
        return prizeNameRecipe(recipe);
    default:
        return translatedName(steam_skin);
    }
};

const prizeNameRecipe = (recipe: InventoryItemInterface): string => translatedName(recipe);
