import React, {Component} from 'react';

interface PropsInterface {
    isActive: boolean
    favoritesCount: number
    handleOnClick: (e: React.MouseEvent) => void
}

export default class ButtonFavourite extends Component<PropsInterface> {
    render(): React.ReactNode {
        const {isActive, favoritesCount, handleOnClick} = this.props;

        return <button
            className={`button-casino-favourite${isActive ? ' active' : ''}`}
            onClick={handleOnClick}>
            <span className="button-casino-favourite__icon">
                <svg className="svg-default">
                    <use xlinkHref="#svg-button-favourite"/>
                </svg>
            </span>
            <span className="button-casino-favourite__number">{favoritesCount}</span>
        </button>;
    }
}
