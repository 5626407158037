import React, {PureComponent, ReactNode} from 'react';
import I18nInterface from 'interfaces/i18n_interface';

interface PropsInterface {
    predictionsCount: number
    translations: I18nInterface['t']
    promo_action_name?: string
    promo_action_received_total?: string
}

class CompendiumHero extends PureComponent<PropsInterface> {
    render(): ReactNode {
        const {translations, promo_action_name, promo_action_received_total, predictionsCount} = this.props;

        return <div className="promo-battle-hero">
            <div className="top">
                <div className="promo-battle-title">
                    <h1 dangerouslySetInnerHTML={{__html: promo_action_name || translations('title_h1')}}/>
                </div>
            </div>
            <div className="bottom">
                <div className="answers-received">
                    <span className="caption">{promo_action_received_total || translations('received')}:</span>
                    <span className="count">{predictionsCount || 0}</span>
                </div>
            </div>
        </div>;
    }
}
export default CompendiumHero;
