import {streamStore} from 'app/store/global';
import {hasWinner, isCancelled} from 'app/lines/line_helpers';
import {isMainLine} from 'app/lines/isMainLine';
import LineInterface from 'app/interfaces/LineInterface';
import StreamInterface from 'app/interfaces/StreamInterface';

const ELEMENT_NOT_FOUND = -1;

const activeForStream = (line: LineInterface): boolean => {
    if (isMainLine(line) && !hasWinner(line) && !isCancelled(line)) {
        return true;
    }
    if (line.nested_bets) {
        return Object.values(line.nested_bets).filter(nested => !hasWinner(nested) && isCancelled(nested)).length > 0;
    }
    return false;
};

export const currentStream = (line: LineInterface): StreamInterface => {
    if (!line.streams_enabled) {
        return null;
    }

    const {streams} = streamStore;
    let stream = null;
    if (activeForStream(line)) {
        if (line.streams && line.streams.length > 0) {
            [stream] = streams.filter(s => line.streams.indexOf(s.id) !== ELEMENT_NOT_FOUND);
        }
        if (!stream) {
            [stream] = streams.filter(s => s.tournament_name &&
                line.tourn &&
                s.tournament_name.toLocaleLowerCase() === line.tourn.toLocaleLowerCase());
        }
    }
    return stream;
};
