export default abstract class BaseValidation<AttributeType, Options> {
    constructor(attribute: AttributeType, options?: Options) {
        this.attribute = attribute;
        this.options = options;
    }

    attribute: AttributeType;

    options: Options;

    abstract execute(): boolean;
}
