import I18n from 'app/i18n';
import CashoutInterface from 'app/interfaces/CashoutInterface';

interface MessageInfo {
    id: number
    account_display: string
    amount: string
    payment_system_name: string
    in_usd?: string
    paid?: string
}

export const confirmCashoutMessage =
    (cashout: CashoutInterface): string => I18n.t('confirm_money_receiving', messageInfo(cashout));

export const messageInfo = (cashout: CashoutInterface): MessageInfo => {
    const {id, system, currency, currency_amount, account_display, paid, amount} = cashout;
    return {
        account_display: account_display ? `(${account_display})` : '',
        amount: `${currency || '$'} ${currency_amount}`,
        id,
        in_usd: amount === currency_amount ? '' : `(${amount}$)`,
        paid,
        payment_system_name: system

    };
};
