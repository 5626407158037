import React from 'react';
import I18n from 'app/i18n';
import {NavLink} from 'react-router-dom';
import {getLinkPath, isGuest, scrollToTop} from 'app/utils';
import {openRegistration} from 'app/components/auth/auth';
import MenuAlertPoint from 'app/components/MenuAlertPoint';
import {inject, observer} from 'mobx-react';
import {casinoGamesStore} from 'app/store/global';
import {getTableLinkPath} from 'app/utils/link_helper';

const classActiveLinkTapBar = ({isActive}) => isActive
    ? 'tap_bar--item active-link'
    : 'tap_bar--item';

@inject('userStore')
@observer
export class TapBar extends React.Component {
    profileClick = e => {
        if (isGuest()) {
            e.preventDefault();
            openRegistration(getLinkPath('/my_profile/mobile'));
        }
        scrollToTop();
    };

    onCasinoClick = () => {
        if (!isGuest()) {
            casinoGamesStore.clearAllFilters();
        }
    };

    render() {
        return <div className="tap_bar">
            <div className="tap_bar--list">
                {<NavLink
                    className={classActiveLinkTapBar}
                    to={getLinkPath('/')}
                    onClick={scrollToTop}
                >
                    <span className="tap_bar--item-icon">
                        <svg height="14px" width="14px">
                            <use xlinkHref="#svg-tap_bar_home"/>
                        </svg>
                    </span>
                    <span className="tap_bar--item-title">{I18n.t('menu_links.home')}</span>
                </NavLink>}

                <NavLink
                    className={classActiveLinkTapBar}
                    to={getTableLinkPath('/play/bets')}
                    onClick={scrollToTop}
                >
                    <span className="tap_bar--item-icon">
                        <svg height="16px" width="16px">
                            <use xlinkHref="#svg-tap_bar_play"/>
                        </svg>
                    </span>
                    <span className="tap_bar--item-title">{I18n.t('menu_links.bets')}</span>
                </NavLink>

                <NavLink
                    className={classActiveLinkTapBar}
                    to={getLinkPath('/casino')}
                    onClick={this.onCasinoClick}
                >
                    <span className="tap_bar--item-icon">
                        <svg height="14px" width="14px">
                            <use xlinkHref="#svg-tap_bar_play_casino"/>
                        </svg>
                    </span>
                    <span className="tap_bar--item-title">{I18n.t('casino')}</span>
                </NavLink>

                <NavLink
                    className={classActiveLinkTapBar}
                    to={getLinkPath('/chat/mobile')}
                    onClick={scrollToTop}
                >
                    <span className="tap_bar--item-icon">
                        <svg height="16px" width="16px">
                            <use xlinkHref="#svg-tap_bar_chat"/>
                        </svg>
                    </span>
                    <span className="tap_bar--item-title">{I18n.t('chat_title')}</span>
                </NavLink>

                <NavLink
                    className={classActiveLinkTapBar}
                    onClick={this.profileClick}
                    to={getLinkPath('/my_profile/mobile')}
                >
                    <span className="tap_bar--item-icon">
                        <svg height="16px" width="16px">
                            <use xlinkHref="#svg-tap_bar_profile"/>
                        </svg>
                        <MenuAlertPoint/>
                    </span>
                    <span className="tap_bar--item-title">{I18n.t('profile')}</span>
                </NavLink>
            </div>
        </div>;
    }
}
