import React, {Component} from 'react';
import {i18n_t} from 'app/i18n';
import {isGuest, secondsToShortHoursFormat, userSignedIn} from 'app/utils';
import {openAuth} from 'app/components/auth/auth';
import {linkMyHero} from 'app/utils/app_links';
import TPromoActionStore from 'app/interfaces/stores/TPromoActionStore';

interface ConvertButtonBaseProps {
    promoActionStore?: TPromoActionStore,
    onClickHandler(): void
}

export default class ConvertButtonBase extends Component<ConvertButtonBaseProps> {
    defaultClassNames = (): string[] => ['btn'];

    className = (): string => {
        const classes = this.defaultClassNames();

        if (this.isDisabled()) {
            classes.push('btn--disable');
        }

        return classes.join(' ');
    };

    isTimerUnavailable = (): boolean => {
        throw new Error();
    };

    nextConversionIn = (): string | null => {
        const {promoAction: {next_conversion_in}} = this.props.promoActionStore;

        if (this.isTimerUnavailable()) {
            return null;
        }

        return secondsToShortHoursFormat(next_conversion_in);
    };

    onClickHandler = ():void => {
        if (userSignedIn()) {
            this.props.onClickHandler();
        } else {
            openAuth(linkMyHero(false));
        }
    };

    isDisabled = (): boolean => {
        const {isConversionAvailableNow} = this.props.promoActionStore;

        if (isGuest()) {
            return false;
        }

        return !isConversionAvailableNow;
    };

    render(): React.ReactNode {
        if (!this.props.promoActionStore.promoAction) {
            return null;
        }

        return (
            <button
                disabled={this.isDisabled()}
                onClick={this.onClickHandler}
                className={this.className()}
            >
                {this.nextConversionIn() || i18n_t('scatter.button')}
            </button>
        );
    }
}
