import React, {PureComponent} from 'react';
import ArtifactImage from './artifact_image';

export default class ArtifactImages extends PureComponent {
    render() {
        const {recipes} = this.props;
        return recipes.map(recipe => <ArtifactImage
            key={recipe.id}
            recipe={recipe}
        />);
    }
}
