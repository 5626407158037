import React from 'react';
import {doShowInformer} from 'app/utils';

export default class Layout extends React.PureComponent {
    static defaultProps = {
        showCloseButton: true,
        filtered: false
    };

    onClose = () => {
        const { externalKey, onClose } = this.props;
        onClose(externalKey);
    };

    render() {
        const { component: Component, showCloseButton, filtered, filter, ...props } = this.props;
        if (filtered && !doShowInformer(filter)) return null;

        return (
            <div className="notification__item">
                {showCloseButton && <button className="act notification__close" onClick={this.onClose}>
                    <svg height="10px" width="10px">
                        <use xlinkHref="#svg-close"/>
                    </svg>
                </button>}

                <Component {...props} onClose={this.onClose}/>
            </div>
        );
    }
}
