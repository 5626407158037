import React, {Component, ReactNode} from 'react';
import {ImageStatsType} from './SmartStoryImagePopup';
import {StatsType} from 'app/utils/Consts';
import {inject} from 'mobx-react';
import {StoriesStoreProps} from 'app/store/StoriesStore';
import I18n from 'app/i18n';
import StatsInterface from 'app/interfaces/stores/stories/StatsInterface';
import moneyBox from 'images/stories/pig.png';

interface Props extends StoriesStoreProps {
    type: number
    imageType: number
}

const DECLENSION_NUMBER = 2;
const DECEMBER = 12;

@inject('storiesStore')
export default class SmartStoryImageStatistic extends Component<Props> {
    renderHeader = (): ReactNode => {
        const {imageType} = this.props;
        const date = this.getHeaderDate(1);

        switch (imageType) {
        case ImageStatsType.FAVORITE_GAMER:
            return <>
                <span className="title-first">{I18n.t('header_story_stats', {date})}</span>
                <span className="title-second">{I18n.t('favorite')}</span>
            </>;
        case ImageStatsType.BEST_TOURNAMENT:
            return <>
                <span className="title-first">{I18n.t('header_story_stats', {date})}</span>
                <span className="title-second">{I18n.t('excellent_tournament')}</span>
            </>;
        case ImageStatsType.POINTS:
            return <>
                <span className="title-first">{I18n.t('header_story_stats', {date})}</span>
                <span className="title-second">{I18n.t('piggy_bank')}</span>
            </>;
        default:
            return null;
        }
    };

    renderImage = (): ReactNode => {
        const stats = this.getStats();

        switch (this.props.imageType) {
        case ImageStatsType.FAVORITE_GAMER:
            return <img className="logotype-player" src={stats.player_image}/>;
        case ImageStatsType.BEST_TOURNAMENT:
            return <div className="middle-text">
                <p>{stats.tournament_name}</p>
            </div>;
        case ImageStatsType.POINTS:
            return <img className="box-money" src={moneyBox}/>;
        default:
            return null;
        }
    };

    renderDescription = (): ReactNode => {
        const stats = this.getStats();
        const date = this.getDescriptionDate(DECLENSION_NUMBER);

        switch (this.props.imageType) {
        case ImageStatsType.FAVORITE_GAMER:
            return <>
                <div className="description-bets">
                    <p>{I18n.t('description_favorite', {date})}</p>
                </div>
                <div className="player-detail">
                    <img className="player-detail__icon" src={stats.player_game_image}/>
                    <span className="player-detail__name">{stats.player_name}</span>
                </div>
                <div className="number-bets">
                    <div className="row">
                        <span>{I18n.t('description_count_bets_title')}</span>
                        <p>{I18n.t('description_count_bets', {count: stats.player_bets_count})}</p>
                    </div>
                    <div className="row">
                        <span>{I18n.t('description_sum_bets_title')}</span>
                        <p>{I18n.t('description_sum_bets', {sum: stats.player_win_amount})}</p>
                    </div>
                </div>
            </>;
        case ImageStatsType.BEST_TOURNAMENT:
            return <>
                <div className="description-bets">
                    <p>{I18n.t('description_tournament', {date})}</p>
                </div>
                <div className="player-detail">
                    <img className="player-detail__icon" src={stats.tournament_game_image}/>
                    <span className="player-detail__name">{stats.tournament_name}</span>
                </div>
                <div className="number-bets">
                    <div className="row">
                        <span>{I18n.t('description_count_bets_title')}</span>
                        <p>{I18n.t('description_count_bets', {count: stats.tournament_bets_count})}</p>
                    </div>
                    <div className="row">
                        <span>{I18n.t('description_sum_bets_title')}</span>
                        <p>{I18n.t('description_sum_bets', {sum: stats.tournament_win_amount})}</p>
                    </div>
                </div>
            </>;
        case ImageStatsType.POINTS:
            return <div className="bottom-text">
                <p>{I18n.t('description_piggy_bank', {date})}</p>
                <p>{I18n.t('description_piggy_bank_points', {amount: stats.points_amount})}</p>
            </div>;
        default:
            return null;
        }
    };

    getStats = (): StatsInterface => {
        const {type, storiesStore} = this.props;
        return type === StatsType.MONTHLY ? storiesStore.monthlyStats : storiesStore.yearlyStats;
    };

    getMonth = (): number => {
        const month = new Date(this.props.storiesStore.monthlyStats.created_at).getMonth();
        return month === 0 ? DECEMBER : month;
    };

    getYear = (): number => new Date(this.getStats().created_at).getFullYear();

    getHeaderDate = (count: number): string => this.props.type === StatsType.MONTHLY
        ? I18n.t(`description_stats.month_${this.getMonth()}`, {count})
        : this.getYear().toString();

    getDescriptionDate = (count: number): string => this.props.type === StatsType.MONTHLY
        ? I18n.t('month_stats', {count})
        : I18n.t('year', {count});

    render(): ReactNode {
        const classSmall = this.props.imageType === ImageStatsType.FAVORITE_GAMER ? '' : ' small';
        return <div className="slide-smart-storie-statistic">
            <div className={`slide-smart-storie-statistic__title${classSmall}`}>
                {this.renderHeader()}
            </div>
            <div className="slide-smart-storie-statistic__image">
                {this.renderImage()}
            </div>
            <div className="slide-smart-storie-statistic__information">
                {this.renderDescription()}
            </div>
        </div>;
    }
}
