import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {fixed3} from 'app/utils/fixed';

export default class CoefButton extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {coefIncreased: null};
    }

    static propTypes = {
        changingColorByCoef: PropTypes.bool,
        className: PropTypes.string,
        coef: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
            PropTypes.number
        ]),
        incubator_2: PropTypes.bool,
        onClick: PropTypes.func
    };

    static defaultProps = {changingColorByCoef: false};

    deleteCoefColorTimer = null;

    componentDidUpdate(prevProps) {
        const {coef} = this.props;

        if (prevProps.coef !== this.props.coef) {
            this.setState({coefIncreased: Number(coef) > Number(prevProps.coef)});
            this.deleteCoefColor();
        }
    }

    deleteCoefColor() {
        const FIVE_SECONDS = 5000;

        if (this.deleteCoefColorTimer) {
            clearTimeout(this.deleteCoefColorTimer);
        }

        this.deleteCoefColorTimer = setTimeout(() => {
            this.setState({coefIncreased: null});
        }, FIVE_SECONDS);
    }

    showingCoef() {
        return this.isCoefAvailable() ? fixed3(this.props.coef) : '-';
    }

    isCoefAvailable = () => {
        const {coef} = this.props;
        return coef && coef !== '0.000';
    };

    onClick = e => e.preventDefault();

    coefColorClass() {
        const {changingColorByCoef} = this.props;
        const {coefIncreased} = this.state;

        if (!changingColorByCoef || coefIncreased === null) {
            return '';
        }

        return ` ${coefIncreased ? 'bet-rate__up' : 'bet-rate__down'}`;
    }

    render() {
        const {className, onClick} = this.props;

        return <button
            className={`${className}${this.coefColorClass()}`}
            onClick={onClick}
        >
            {this.showingCoef()}
        </button>;
    }
}
