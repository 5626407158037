import React, {PureComponent, ReactNode} from 'react';
import {isDesktop, isGuest} from 'app/utils';
import {SignUpButton} from 'app/components/auth/SignUpButton';

interface PropsInterface {
    isActive: boolean
    message: string
    handleOnClick: (e: React.MouseEvent) => void
}

export default class ButtonBellWithMessage extends PureComponent<PropsInterface> {
    renderSvg = (): ReactNode => <svg className="svg-default">
        <use xlinkHref="#svg-notification-bell-transparent"/>
    </svg>;

    render(): ReactNode {
        const {isActive, message, handleOnClick} = this.props;
        return <div className="subscription-container">
            {isGuest()
                ? <SignUpButton className="button-subscription" usingOnTab={'GIVEAWAY-BELL'}>
                    {this.renderSvg()}
                </SignUpButton>
                : <button className={`button-subscription${isActive ? ' active' : ''}`} onClick={handleOnClick}>
                    {this.renderSvg()}
                </button>}
            {isDesktop() && <div className="subscription-description">
                <div className="subscription-description__inner">
                    <p>{message}</p>
                </div>
            </div>}
        </div>;
    }
}
