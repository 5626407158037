import React, {PureComponent} from 'react';
import SupportButton from 'app/components/SupportButton';
import I18n from 'app/i18n';

export default class HelpSupport extends PureComponent {
    render(): React.ReactNode {
        return <div className="message-for-users">
            <span className="message-for-users__icon">
                <svg className="svg-default">
                    <use xlinkHref="#svg-question"/>
                </svg>
            </span>
            <div className="message-for-users__message">
                <p>{I18n.t('p2p_info_support_first')}</p>
                <p>{I18n.t('p2p_info_support_second')} <SupportButton hideIcon/>. {I18n.t('p2p_info_support_third')}</p>
            </div>
        </div>;
    }
}
