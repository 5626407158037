import React, {Component, ReactNode, ChangeEvent} from 'react';
import {inject, observer} from 'mobx-react';
import I18n from 'app/i18n';

interface FaqSearchProps {
    handleChange(e: ChangeEvent<HTMLInputElement>): void
}

@inject('faqStore')
@observer
export default class FaqSearch extends Component<FaqSearchProps> {
    render(): ReactNode {
        return <form className="search-container">
            <input type="text"
                onChange={this.props.handleChange}
                placeholder={I18n.t('search')}
                className="search-container__input"
            />
            <span className="search-container__icon">
                <svg className="svg-default">
                    <use xlinkHref="#svg-search-icon"/>
                </svg>
            </span>
        </form>;
    }
}
