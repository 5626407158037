import React, {PureComponent, ReactNode} from 'react';

const ROWS_COUNT = 3;

interface IncubatorStubPageProps {
    message: string
}

export default class IncubatorStubPage extends PureComponent<IncubatorStubPageProps> {
    render(): ReactNode {
        const {message} = this.props;
        return <div className="wrapper-incubator-waiting-line">
            <div className="wrapper-incubator-waiting-text">
                <p>{message}</p>
            </div>
            <div className="wrapper-incubator-waiting-table">
                {Array(ROWS_COUNT).fill(0).map((_, i) => <div className="row" key={i}>
                    <div className="cell">
                        <span className="l1"/>
                    </div>
                    <div className="cell">
                        <span className="r1"/>
                        <span className="l1"/>
                    </div>
                    <div className="cell">
                        <span className="r2"/>
                        <span className="l1"/>
                    </div>
                    <div className="cell">
                        <span className="l2"/>
                    </div>
                    <div className="cell">
                        <span className="r2"/>
                        <span className="l1"/>
                    </div>
                    <div className="cell">
                        <span className="l2"/>
                    </div>
                </div>)}
            </div>
        </div>;
    }
}
