import React from 'react';
import I18n from 'app/i18n';

const t = (key, values) => I18n.t(`prizes_texts.${key}`, values);

export default class CommonPointsBets extends React.PureComponent {
    render() {
        return <div className="points-list">
            <div className="points-list-row">
                <div className="point">
                    <div className="point-row">
                        <span className="count">1</span>
                        <span className="count-name">{t('point')}</span>
                    </div>
                    <div className="point-row">
                        <sup className="for">{t('for')}</sup>
                        <span className="amount">$5</span>
                    </div>
                </div>
                <div className="point">
                    <div className="point-row">
                        <span className="count">2</span>
                        <span className="count-name">{t('points')}</span>
                    </div>
                    <div className="point-row">
                        <sup className="for">{t('for')}</sup>
                        <span className="amount">$15</span>
                    </div>
                </div>
                <div className="point">
                    <div className="point-row">
                        <span className="count">3</span>
                        <span className="count-name">{t('points')}</span>
                    </div>
                    <div className="point-row">
                        <sup className="for">{t('for')}</sup>
                        <span className="amount">$30</span>
                    </div>
                </div>
                <div className="point">
                    <div className="point-row">
                        <span className="count">4</span>
                        <span className="count-name">{t('points')}</span>
                    </div>
                    <div className="point-row">
                        <sup className="for">{t('for')}</sup>
                        <span className="amount">$55</span>
                    </div>
                </div>
                <div className="point">
                    <div className="point-row">
                        <span className="count">5</span>
                        <span className="count-name">{t('points')}</span>
                    </div>
                    <div className="point-row">
                        <sup className="for">{t('for')}</sup>
                        <span className="amount">$75</span>
                    </div>
                </div>
                <div className="point">
                    <div className="point-row">
                        <span className="count">6</span>
                        <span className="count-name">{t('points')}</span>
                    </div>
                    <div className="point-row">
                        <sup className="for">{t('for')}</sup>
                        <span className="amount">$110</span>
                    </div>
                </div>
                <div className="point">
                    <div className="point-row">
                        <span className="count">7</span>
                        <span className="count-name">{t('points')}</span>
                    </div>
                    <div className="point-row">
                        <sup className="for">{t('for')}</sup>
                        <span className="amount">$150</span>
                    </div>
                </div>
                <div className="point">
                    <div className="point-row">
                        <span className="count">8</span>
                        <span className="count-name">{t('points')}</span>
                    </div>
                    <div className="point-row">
                        <sup className="for">{t('for')}</sup>
                        <span className="amount">$200</span>
                    </div>
                </div>
            </div>
            <div className="points-list-row">
                <div className="point">
                    <div className="point-row">
                        <span className="count">9</span>
                        <span className="count-name">{t('points')}</span>
                    </div>
                    <div className="point-row">
                        <sup className="for">{t('for')}</sup>
                        <span className="amount">$275</span>
                    </div>
                </div>
                <div className="point">
                    <div className="point-row">
                        <span className="count">10</span>
                        <span className="count-name">{t('points')}</span>
                    </div>
                    <div className="point-row">
                        <sup className="for">{t('for')}</sup>
                        <span className="amount">$350</span>
                    </div>
                </div>
                {!this.props.mini && <>
                    <div className="point">
                        <div className="point-row">
                            <span className="count">11</span>
                            <span className="count-name">{t('points')}</span>
                        </div>
                        <div className="point-row">
                            <sup className="for">{t('for')}</sup>
                            <span className="amount">$600</span>
                        </div>
                    </div>
                    <div className="point">
                        <div className="point-row">
                            <span className="count">12</span>
                            <span className="count-name">{t('points')}</span>
                        </div>
                        <div className="point-row">
                            <sup className="for">{t('for')}</sup>
                            <span className="amount">$1,250</span>
                        </div>
                    </div>
                    <div className="point">
                        <div className="point-row">
                            <span className="count">13</span>
                            <span className="count-name">{t('points')}</span>
                        </div>
                        <div className="point-row">
                            <sup className="for">{t('for')}</sup>
                            <span className="amount">$2,500</span>
                        </div>
                    </div>
                    <div className="point">
                        <div className="point-row">
                            <span className="count">14</span>
                            <span className="count-name">{t('points')}</span>
                        </div>
                        <div className="point-row">
                            <sup className="for">{t('for')}</sup>
                            <span className="amount">$5,000</span>
                        </div>
                    </div>
                    <div className="point">
                        <div className="point-row">
                            <span className="count">15</span>
                            <span className="count-name">{t('points')}</span>
                        </div>
                        <div className="point-row">
                            <sup className="for">{t('for')}</sup>
                            <span className="amount">$12,500</span>
                        </div>
                    </div>
                </>
                }
            </div>
        </div>;
    }
}
