/* eslint-disable max-lines */
import {action, computed, makeObservable, observable} from 'mobx';
import {updateOrAddObject} from 'app/utils/updateArraysObject';
import {isDesktop, scrollToTop} from 'app/utils';
import logEvent from 'app/amplitude/log_event';
import {ELEMENT_NOT_FOUND} from 'app/utils/Consts';
import {
    casinoGameById,
    favoriteGameChange,
    sendRequestStartCasino,
    loadGames,
    lastFindSame
} from 'app/services/CasinoServices';
import {
    CasinoGameInterface,
    FilteredParamsInterface,
    SortingsInterface,
    CasinoGamesStoreInterface,
    PlayGameParamsInterface,
    GameLaunchOptionsInterface,
    CasinoFilterInterface,
    CasinoProvidersInterface,
    CasinoGamesCategoriesInterface,
    BigWinInterface
} from 'app/interfaces/stores/CasinoGamesStoreInterfaces';
import {toastError} from 'app/components/toasts/liteToast';
import {CasinoGamesController} from 'app/controllers/casinoGamesController';
import I18n from 'app/i18n';
import {RestApiService} from 'app/services/RestApiService';
import {CasinoGameUpdatePayloadInterface} from 'app/interfaces/CasinoGameUpdatePayloadInterface';
import CasinoLaunchInterface from 'app/interfaces/CasinoLaunchInterface';

export const GAMES_PER_PAGE = 40;
export const DEFAULT_SORTING = 'popular';
export const GAMES_FIND_LIMIT = 1000;

export class CasinoGamesStore {
    controller: CasinoGamesController;

    constructor() {
        makeObservable(this);
        this.controller = new CasinoGamesController(this);
    }

    @observable data: CasinoGamesStoreInterface = {
        casinoFilters: [],
        casinoGames: [],
        casinoGamesByFiltersCount: 0,
        casinoGamesCategories: [],
        casinoProviders: [],
        usersFavoritesGameCount: 0
    };

    @observable
    biggestWins: BigWinInterface[] = [];

    @observable
    homePageGames: CasinoGameInterface[] = [];

    @observable
    casinoFilters: CasinoFilterInterface[] = [];

    @observable
    collectionSize = 0;

    @observable
    filters: CasinoFilterInterface[] = [];

    @observable
    favoritesGameCount = 0;

    @observable
    casinoProviders: CasinoProvidersInterface[] = [];

    @observable
    casinoGamesCategories: CasinoGamesCategoriesInterface[] = [];

    @observable filteredParams: FilteredParamsInterface = {
        categories: [],
        favorite: false,
        find: '',
        game_id: null,
        provider: []
    };

    @observable selectedCategoriesIds: string[] = [];

    @observable activeCasinoGame?: CasinoGameInterface | null;

    @observable partsLoaded = 1;

    @observable hasMore = true;

    @observable sortingValue = DEFAULT_SORTING;

    @observable loadingNewGames = true;

    @observable showOnlyFavorite = false;

    @observable isGameActive = false;

    @observable reloadPage = false;

    @observable gameLaunchOptions?: GameLaunchOptionsInterface = null;

    @observable selectedFilterGroupId = ELEMENT_NOT_FOUND;

    @observable lastFind: FilteredParamsInterface = {
        categories: [],
        favorite: false,
        find: '',
        game_id: null,
        provider: []
    };

    @observable sortings: SortingsInterface[] = [
        {id: 0, value: 'popular'},
        {id: 1, value: 'new'},
        {id: 2, value: 'alphabetical'}
    ];

    @computed
    get favoriteGames(): CasinoGameInterface[] {
        const {casinoGames} = this.data;

        return casinoGames.filter(game => game.favorite === true);
    }

    @computed
    get device(): string {
        return isDesktop() ? 'desktop' : 'mobile';
    }

    @action
    initialize = (): void => {
        const params = this.filteredParams;
        const queryParams = {
            device: this.device,
            filter_group: this.selectedFilterGroupId > 0
                ? this.selectedFilterGroupId
                : null,
            order_by: this.sortingValue,
            page: this.partsLoaded,
            ...params
        };

        this.controller.loadGames(queryParams);

        this.controller.loadFilters(queryParams);

        this.controller.init();
    };

    @action
    init = (data: CasinoGamesStoreInterface): void => {
        this.update('data', {
            ...{casinoGamesByFiltersCount: data.casinoGamesByFiltersCount},
            ...{casinoGames: this.data.casinoGames}
        });
        this.updateBiggestWins(data.biggestWins);
        this.updateCasinoProviders(data.casinoProviders);
        this.updateCasinoGamesCategories(data.casinoGamesCategories);
        this.updateTabFilters(data.casinoFilters);
        this.setFavoriteGamesCount(data.usersFavoritesGameCount);
        if (data.casinoGames?.length < GAMES_PER_PAGE) {
            this.changeHasMore(false);
        }
    };

    @action
    updateBiggestWins = (wins: BigWinInterface[]): void => {
        this.biggestWins = wins;
    };

    @action
    update = (variableName: string, data: CasinoGamesStoreInterface): void => {
        this[variableName] = data;
    };

    @action
    setFavoriteGamesCount = (count: number): void => {
        this.favoritesGameCount = count;
    };

    @action
    updateCasinoProviders = (providers: CasinoProvidersInterface[]): void => {
        this.casinoProviders = [...providers];
    };

    @action
    updateCasinoGamesCategories = (gamesCategories: CasinoGamesCategoriesInterface[]): void => {
        this.casinoGamesCategories = [...gamesCategories];
    };

    @action
    updateTabFilters(filters: CasinoFilterInterface[]): void {
        this.filters = [];
        this.filters = this.filters.concat(filters);
    }

    @action
    updateElementCollection(item: CasinoGameInterface): void {
        updateOrAddObject(this.data, 'casinoGames', item);
        if (this.activeCasinoGame && this.activeCasinoGame.id === item.id) {
            this.changeActiveGame(item);
        }
        if (this.showOnlyFavorite) {
            this.updateGamesCollection(
                this.data.casinoGames.filter(game => game.favorite === true)
            );
        }
    }

    @action
    updateGamesCollection(casinoGames: CasinoGameInterface[]): void {
        this.data.casinoGames = casinoGames;
    }

    @action
    updateGames(data: { casinoGames: CasinoGameInterface[] }): void {
        if (this) {
            this.update('data', {casinoGames: []});
            const filteredCasinoGames = data.casinoGames?.filter(game => game.devices.includes(this.device));
            this.update('data', {...{casinoGames: this.data.casinoGames.concat(filteredCasinoGames)}});
        }
    }

    @action
    partsChanged(partsLoaded: number): void {
        this.partsLoaded = partsLoaded;
    }

    @action
    changeHasMore(hasMore: boolean): void {
        this.hasMore = hasMore;
    }

    @action
    updateShowOnlyFavorite(showOnlyFavorite: boolean): void {
        this.showOnlyFavorite = showOnlyFavorite;
        this.filteredParams.favorite = showOnlyFavorite;
        this.filteredParams.provider = [];
    }

    @action
    updateLastFind(lastFind: FilteredParamsInterface): void {
        this.lastFind = lastFind;
    }

    @action
    changeSortingValue(value: string): void {
        this.sortingValue = value;
    }

    @action
    selectFilterGroup(id: number): void {
        this.selectedFilterGroupId = id;
    }

    @action
    updateGameId = (gameId: string): void => {
        this.filteredParams.game_id = gameId;
    };

    @action
    updateFindFilteredParams = (find: string, gameId: string): void => {
        this.filteredParams.find = find;
        this.filteredParams.game_id = gameId;
        this.filteredParams.favorite = false;
        this.showOnlyFavorite = false;
        this.selectFilterGroup(null);
        this.checkUpdateFilteredParams();
    };

    @action
    updateFilteredParams(identifier: string, value: string | boolean | number): void {
        this.filteredParams[identifier] = value;
        this.checkUpdateFilteredParams();
    }

    @action
    updateSelectProviders = (ids: string[]): void => {
        this.filteredParams.provider = ids;
    };

    @action
    updateSelectCategories = (categories: string[], selectedCategoriesIds: string[]): void => {
        this.selectedCategoriesIds = selectedCategoriesIds;
        this.filteredParams.categories = categories;
    };

    @action
    clearFilteredParams = (): void => {
        this.showOnlyFavorite = false;
        this.filteredParams = {
            categories: [],
            favorite: false,
            find: '',
            provider: []
        };
    };

    @action
    clearSelectedCategories = (): void => {
        this.selectedCategoriesIds = [];
    };

    @action
    clearAllFilters = (): void => {
        this.clearFilteredParams();
        this.clearSelectedCategories();
        this.selectFilterGroup(ELEMENT_NOT_FOUND);
        this.changeSelectSorting(DEFAULT_SORTING);
    };

    @action
    changeActiveGame = (casinoGame: CasinoGameInterface | null): void => {
        this.activeCasinoGame = casinoGame;
    };

    @action
    changeIsGameActive = (isActive: boolean): void => {
        this.isGameActive = isActive;
    };

    @action
    changeLoadStatus = (status: boolean): void => {
        this.loadingNewGames = status;
    };

    @action
    clearFind = (): void => {
        this.filteredParams.find = '';
        this.filteredParams.game_id = null;
    };

    @action
    changeReloadPage = (reload: boolean): void => {
        this.reloadPage = reload;
    };

    @action
    updateGamesByFilters(item: CasinoGameInterface): void {
        this.casinoFilters.forEach(filter => {
            filter.casinoGames[filter.casinoGames.findIndex(game => game.id === item.id)] = item;
        });
    }

    @action
    updateHomePageGame(item: CasinoGameInterface): void {
        this.homePageGames[this.homePageGames.findIndex(game => game.id === item.id)] = item;
    }

    @action
    updateFilters(items: CasinoFilterInterface): void {
        this.casinoFilters = [];
        this.casinoFilters = this.casinoFilters.concat(items);
    }

    @action
    updateFiltersGamesCollection(items: CasinoGamesStoreInterface, filterId: number): void {
        const casinoFilter = this.casinoFilters.find(filter => filter.id === filterId);
        const filteredGames =
                items.casinoGames.filter(game => game.devices.includes(this.device));
        casinoFilter.casinoGames = casinoFilter.casinoGames.concat(filteredGames);
    }

    @action
    updateGameLaunchOptions(params?: GameLaunchOptionsInterface): void {
        this.gameLaunchOptions = params;
    }

    loadCasinoGames = (filtered?: FilteredParamsInterface): void => {
        const params = filtered || this.filteredParams;
        const queryParams = {
            filter_group: this.selectedFilterGroupId > 0 ? this.selectedFilterGroupId : null,
            order_by: this.sortingValue,
            page: this.partsLoaded + 1,
            ...params
        };
        this.updateLastFind({...this.filteredParams});
        loadGames(
            queryParams,
            this.savePartCasinoGames
        );
    };

    savePartCasinoGames = (data: CasinoGamesStoreInterface): void => {
        this.update('data', {
            ...this.data,
            ...{casinoGames: this.data.casinoGames?.concat(data.casinoGames)}
        });
        this.updateCasinoGamesCategories(data.casinoGamesCategories);
        this.updateCasinoProviders(data.casinoProviders);
        this.changeLoadStatus(false);
        this.changeHasMore(data.has_more);
        if (this.partsLoaded === 0) {
            scrollToTop();
        }
        this.partsChanged(this.partsLoaded + 1);
    };

    playGame = (params: PlayGameParamsInterface): boolean => {
        const filter = this.casinoFilters.find(f => f.id === this.selectedFilterGroupId) || params.filter;
        const activeGame = casinoGameById(params.id, params.filter ? params.filter.id : null);

        if (activeGame.restricted) {
            toastError(I18n.t('slot_not_available'));
            return false;
        }
        this.changeActiveGame(activeGame);
        sendRequestStartCasino(
            `/casino/games/${params.id}/${params.demo ? 'launch_demo' : 'launch_play'} `,
            this.device
        );
        const favorite = this.showOnlyFavorite ? 'Favorite' : 'All Games';
        const filter_name = filter ? filter.name : favorite;

        logEvent('CASINO_GAME_OPENED', {
            SOURCE: this.filteredParams.find ? 'Search' : filter_name,
            demo: params.demo,
            game: activeGame.name,
            game_type: activeGame.category,
            provider: activeGame.provider_name
        });
        return true;
    };

    closeModal = (): void => {
        if (this.isGameActive) {
            this.changeActiveGame(null);
            this.updateGameLaunchOptions(null);
        }
        this.changeIsGameActive(false);
    };

    openModal = (): void => {
        this.changeIsGameActive(true);
        this.changeReloadPage(true);
    };

    activeGameToFavorite = (): void => {
        const {id, favorite} = this.activeCasinoGame;
        favoriteGameChange(id, !favorite, null);
    };

    selectProviders = (ids: string[]): void => {
        this.updateSelectProviders(ids);
        this.checkUpdateFilteredParams();
    };

    selectCategories = (ids: string[]): void => {
        const currentCategories = [];
        ids.forEach((id: number | string) => {
            currentCategories.push(
                this.casinoGamesCategories.find(item => item.id === Number(id)).name
            );
        });
        this.clearFind();
        this.updateSelectCategories(currentCategories, ids);
        this.checkUpdateFilteredParams();
    };

    changeSelectSorting = (value: string): void => {
        this.changeSortingValue(value);
        this.updateShowOnlyFavorite(false);
        this.clearFind();
        this.checkUpdateFilteredParams(true);
    };

    changeFilterGroup = (id: number): void => {
        this.selectFilterGroup(id);
        this.updateShowOnlyFavorite(false);
        this.clearFilteredParams();
        this.clearSelectedCategories();
        this.checkUpdateFilteredParams(true);
    };

    checkUpdateFilteredParams = (changeSorting?: boolean): void => {
        if (lastFindSame() && !changeSorting) {
            return;
        }

        this.partsChanged(0);
        this.updateGamesCollection([]);
        this.changeLoadStatus(true);
        this.setFilteredParams();
    };

    setFilteredParams = (): void => {
        const {find, provider, game_id} = this.filteredParams;

        if (find !== '') {
            this.loadCasinoGames({find, game_id, provider});
            this.clearFilterGroup();
            return;
        }
        if (this.showOnlyFavorite) {
            this.clearFilterGroup();
        } else {
            this.defaultFilteredParams();
        }
        this.loadCasinoGames();
    };

    clearFilterGroup = (): void => {
        this.selectFilterGroup(null);
        this.changeSortingValue('');
    };

    defaultFilteredParams = (): void => {
        if (!this.selectedFilterGroupId) {
            this.selectFilterGroup(ELEMENT_NOT_FOUND);
        }
        if (this.sortingValue === '') {
            this.changeSortingValue(DEFAULT_SORTING);
        }
    };

    liveSearchApiService = (inputValue: string): Promise<Response> => new RestApiService('/casino/games/load_games')
        .index({
            device: this.device,
            favorite: false,
            filter_group: '',
            find: inputValue,
            game_id: this.filteredParams.game_id,
            limit: GAMES_FIND_LIMIT,
            provider: this.filteredParams.provider
        });

    showFilterGroups = (): boolean => this.selectedFilterGroupId === ELEMENT_NOT_FOUND &&
            this.selectedCategoriesIds.length === 0 && this.filteredParams.provider.length === 0;

    listenCasinoGameUpdate(payload: CasinoGameUpdatePayloadInterface): void {
        this.update('data', {
            ...this.data,
            usersFavoritesGameCount: payload.usersFavoritesGameCount
        });
        this.setFavoriteGamesCount(payload.usersFavoritesGameCount);
        this.updateGamesByFilters(payload.casino_game);
        this.updateElementCollection(payload.casino_game);
        this.updateHomePageGame(payload.casino_game);
    }

    listenCasinoSession(payload: CasinoLaunchInterface): void {
        if (payload.errors) {
            toastError(payload.errors.base[0]);
            this.closeModal();
        } else {
            this.updateGameLaunchOptions({
                launch_options: payload.launch_options,
                target_element: 'a8r_iframe'
            });
            scrollToTop();
        }
    }

    @action
    updateHomePageGames(casinoGames: CasinoGameInterface[]): void {
        this.homePageGames = casinoGames;
    }
}
