import React, {ChangeEvent, FocusEvent, Component, ReactNode} from 'react';
import {BetSlipStore} from 'app/store/BetSlipStore';
import BetSlipController from 'app/components/bet_slip/BetSlipController';
import BetSlipInputCoef from 'app/components/bet_slip/amount_money/BetSlipInputCoef';
import {inject, observer} from 'mobx-react';
import {BetSlipFocus} from 'app/utils/Consts';

interface BetSlipInputAmountProps {
    betSlipStore?: BetSlipStore
    controller?: BetSlipController
}


@inject('betSlipStore', 'controller')
@observer
export default class BetSlipInputCoefContainer extends Component<BetSlipInputAmountProps> {
    inputAmount: React.RefObject<BetSlipInputCoef> = React.createRef();

    handleOnChange = (e: ChangeEvent<HTMLInputElement> | string): void => {
        this.props.controller.changeCoef(typeof e === 'string' ? e : e.target.value);
    };

    setFocus = (): void => this.inputAmount.current.setFocus();

    handelOnFocus = (e: FocusEvent<HTMLInputElement>): void => {
        const val = e.target.value;
        e.target.value = '';
        e.target.value = val;
        this.props.betSlipStore.setFocus(BetSlipFocus.COEF);
    };

    render(): ReactNode {
        const {formCoef, coefFocus} = this.props.betSlipStore;
        return <BetSlipInputCoef
            active={coefFocus}
            amount={formCoef === null && !coefFocus ? '' : formCoef}
            autoFocus={false}
            onFocus={this.handelOnFocus}
            onChange={this.handleOnChange}
            coefIncreased={null}
            id="betSlipCoef"
            ref={this.inputAmount}
        />;
    }
}
