import I18n from 'app/i18n';
import {betMoney} from 'app/utils';
import React, {PureComponent} from 'react';

export default class PossibleWinAmount extends PureComponent {
    render() {
        const {amount, coef, coefIncreased, playmoney} = this.props;
        return <span className="match-details__item-form-row possible-win__wrap">
            <span className="possible-win">
                {I18n.t('bet_you_can_win_label')}: <span
                    className={`possible-win__amount${coefIncreased ? ' bet-rate__up' : ''}`}
                >
                    {betMoney({
                        amount: amount * coef,
                        playmoney
                    })}
                </span>
            </span>
        </span>;
    }
}
