import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import HistoricalDepositInterface from 'app/interfaces/HistoricalDepositInterface';

interface PropsInterface {
    deposit: HistoricalDepositInterface,
    fixDeposit: (deposit: HistoricalDepositInterface) => void
}

export default class FixedButton extends PureComponent<PropsInterface> {
    handleClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
        e.preventDefault();
        const {deposit, fixDeposit} = this.props;
        fixDeposit(deposit);
    };

    render(): React.ReactNode {
        const {deposit: {not_valid}} = this.props;
        return <div className="value-button">
            {not_valid && <a
                onClick={this.handleClick}
                href="#"
                className={'button-adaptive button-adaptive_transparent'}
            >
                {I18n.t('i_fixed_mistake')}
            </a>}
        </div>;
    }
}
