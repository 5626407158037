import React, {useCallback} from 'react';
import {NavLink} from 'react-router-dom';
import {goBackNavigate} from 'app/utils/navigate/GoBack';
import I18n from 'app/i18n';

interface Props {
    onClick?(): void
}

const GoBackButton = (props: Props): React.JSX.Element => {
    const goBack = (e: React.MouseEvent<HTMLAnchorElement>): void => {
        e.preventDefault();
        const {onClick} = props;

        if (onClick) {
            onClick();
        } else {
            goBackNavigate(false);
        }
    };

    const handleGoBack = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => goBack(e), [goBack]);

    return (
        <NavLink to="#" onClick={handleGoBack} className="btn-go-back">
            <svg className="icon-btn">
                <use xlinkHref="#svg-go-back" />
            </svg>
            <span>{I18n.t('button_back_label')}</span>
        </NavLink>
    );
};

export default GoBackButton;
