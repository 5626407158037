import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import toUpper from 'lodash/toUpper';

interface EmptyShopResultProps {
    currency: string
}

export default class EmptyShopResult extends PureComponent<EmptyShopResultProps> {
    render(): React.ReactNode {
        const {currency} = this.props;

        return <div className="search-no-found default">
            <span className="background-image"/>
            <div className="container-text">
                <p>{I18n.t('we_have_no_goods', {currency: toUpper(currency)})}</p>
                <p>{I18n.t('visit_our_shop_later')}</p>
            </div>
        </div>;
    }
}
