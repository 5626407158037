export default class EventProperties {
    constructor(response) {
        this.response = response;
    }

    get responseJSON() {
        const {responseJSON} = this.response;

        if (responseJSON) {
            return responseJSON;
        }
        return this.response;
    }

    get errors() {
        const {responseJSON: {errors}} = this;

        if (!errors) {
            return {};
        }
        return errors;
    }

    get errorsKeys() {
        return Object.keys(this.errors);
    }

    get errorsKeysLength() {
        return this.errorsKeys.length;
    }

    extractProperties() {}
}
