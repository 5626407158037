import ToastContent, {ToastContentProps} from 'app/components/toasts/Contents/ToastContent';
import SiteNotificationInterface from 'app/interfaces/SiteNotificationInterface';
import {siteNotificationStore} from 'app/store/global';
import React, {Fragment} from 'react';

interface PropsInterface extends ToastContentProps {
    notification: SiteNotificationInterface
}

export default class SiteNotificationContent extends ToastContent<PropsInterface> {
    state = {disabled: false};

    handleConfirm = (): void => {
        if (this.state.disabled) {
            return;
        }
        this.setState({disabled: true});
        siteNotificationStore.sendLog(this.props.notification.id, 'click')
            .then(this.props.closeToast)
            .finally(() => this.setState({disabled: false}));
    };

    render(): React.ReactNode {
        const {link, text} = this.props.notification;
        return <Fragment>
            <a
                onClick={this.handleConfirm}
                className="Toastify__notification__content--link"
                href={link}
                target="_blank"
                rel="noreferrer"
            >
                <div className="Toastify__body-content">
                    <div className="Toastify__notification">
                        <div className="Toastify__notification__icon">
                            <svg width="16px" height="20px">
                                <use xlinkHref="#svg-notification-bell"/>
                            </svg>
                        </div>
                        <div className="Toastify__notification__content">
                            <div className="Toastify__notification__content--text">
                                {text}
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </Fragment>;
    }
}
