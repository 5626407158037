import React, {Component} from 'react';

import PromoActionBeer from 'app/components/promo_action/beer';
import {PromoActionPresent} from 'app/components/promo_action/gift';
import PromoActionOktoberfest from 'app/components/promo_action/oktoberfest';
import Compendium from 'app/components/promo_action/compendium';
import Enigma from 'app/components/promo_action/enigma';
import PromoActionCompenigma from 'app/components/promo_action/PromoActionCompenigma';
import PromoActionCocktail from 'app/components/promo_action/PromoActionCocktail';
import {inject, observer} from 'mobx-react';
import TPromoActionStore from 'app/interfaces/stores/TPromoActionStore';

const PROMO_ACTIONS = {
    PromoActionBeer,
    PromoActionCocktail,
    PromoActionCompenigma,
    PromoActionOktoberfest,
    PromoActionPresent,
    compendium: Compendium,
    enigma: Enigma,
    path_of_legend: Compendium,
    race_of_champions: Compendium
};

interface PromoActionsProps {
    promoActionStore?: TPromoActionStore
}

class PromoActions extends Component<PromoActionsProps> {
    getPromoAction = (key, promoActions) => {
        const {compendium_type} = promoActions[key];

        return PROMO_ACTIONS[key] || PROMO_ACTIONS[compendium_type];
    };

    render() {
        const {promoActions} = this.props.promoActionStore;

        return Object.keys(promoActions).map(key => {
            const PromoAction = this.getPromoAction(key, promoActions);

            if (PromoAction) {
                return <PromoAction key={key} {...promoActions[key]}/>;
            }

            return null;
        });
    }
}

export default inject('promoActionStore')(observer(PromoActions));
