import React, {Fragment, PureComponent} from 'react';
import {CSSTransition} from 'react-transition-group';
import PropTypes from 'prop-types';

export default class SliderContainer extends PureComponent {
    constructor(props) {
        super(props);
        const {opened} = props;
        this.state = {
            bufferedHeight: null,
            height: null,
            isAnimating: false,
            show: opened
        };
        this.handleOnExit = this.handleOnExit.bind(this);
        this.handleOnExiting = this.handleOnExiting.bind(this);
        this.handleOnExited = this.handleOnExited.bind(this);
        this.handleOnEnter = this.handleOnEnter.bind(this);
        this.handleOnEntering = this.handleOnEntering.bind(this);
        this.handleOnEntered = this.handleOnEntered.bind(this);
    }

    componentDidMount() {
        if (this.state.show) {
            this.setState({height: this.offsetHeight});
        }
    }

    // What is purpose of it?
    rootElement = React.createRef();

    container = React.createRef();

    static defaultProps = {
        enter: 400,
        exit: 400
    };

    static propTypes = {className: PropTypes.string};

    handleOnExit = () => {
        this.setState({
            height: this.offsetHeight,
            isAnimating: true
        });
    };

    handleOnExiting = () => {
        this.setState({height: 0});
    };

    handleOnExited = () => {
        this.setState({
            isAnimating: false,
            show: false
        });
    };

    handleOnEnter = () => {
        this.setState({
            bufferedHeight: this.offsetHeight,
            height: 0,
            isAnimating: true,
            show: true
        });
    };

    handleOnEntering = () => {
        this.setState({height: this.state.bufferedHeight});
    };

    handleOnEntered = () => {
        this.setState({isAnimating: false});
    };

    get containerStyle() {
        const {height, isAnimating} = this.state;
        const style = {};

        if (isAnimating) {
            style.height = height;
        }
        return style;
    }

    get offsetHeight() {
        return this.container.current.offsetHeight;
    }

    render() {
        const {show} = this.state;
        const {children, opened, enter, exit} = this.props;
        const timeout = {
            enter,
            exit
        };
        return <CSSTransition
            timeout={timeout}
            classNames="slide"
            in={opened}
            onExit={this.handleOnExit}
            onExiting={this.handleOnExiting}
            onExited={this.handleOnExited}
            onEnter={this.handleOnEnter}
            onEntering={this.handleOnEntering}
            onEntered={this.handleOnEntered}
        >
            <Fragment>
                {(show || !show && opened) && children && React.cloneElement(children, {
                    ref: this.container,
                    style: this.containerStyle
                })}
            </Fragment>
        </CSSTransition>;
    }
}
