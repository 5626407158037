import BaseValidation from 'validations/BaseValidation';

export default class PresenceValidation extends BaseValidation<unknown, null> {
    execute(): boolean {
        return !(!this.attribute ||
            Array.isArray(this.attribute) && this.attribute.length === 0 ||
            this.attribute instanceof Object && Object.keys(this.attribute).length === 0) ||
            this.attribute === 0;
    }
}
