import React, {PureComponent, ReactNode} from 'react';
import {dateLabel} from 'app/utils/dateLabel';
import {localDateTimeFromISO} from 'app/utils';
import GiveawayItemInterface from 'app/interfaces/my_prizes/GiveawayItemInterface';
import I18n from 'app/i18n';
import MyHeroLink from 'app/components/common/MyHeroLink';
import SellNowSteamSkin from 'app/components/common/SellNowSteamSkin';
import {artifactName} from 'app/components/my_profile/artifacts/utils';

interface RowProps {
    item: GiveawayItemInterface,
    resetData: () => void
}

export default class GiveawayRow extends PureComponent<RowProps> {
    valueByType = (): ReactNode => {
        const {name, artifact, steam_skin, steam_trade} = this.props.item.prize;

        if (artifact) {
            return <>
                <div className="table-content-cell">
                    <span className="mobile-label">{I18n.t('prize_label')}:</span>
                    <p>{artifactName(artifact)}</p>
                </div>
                <div className="table-content-cell">
                    <MyHeroLink btnClass="button-adaptive button-adaptive_instyle"/>
                </div>
            </>;
        }

        if (steam_skin) {
            return <>
                <div className="table-content-cell">
                    <p>{name}</p>
                </div>
                <div className="table-content-cell">
                    <SellNowSteamSkin
                        afterSell={this.props.resetData}
                        steam_skin={steam_skin}
                        steam_trade={steam_trade}
                        btnClass="button-adaptive button-adaptive_instyle"
                        soldClassName="button-adaptive button-adaptive_instyle disabled"
                    />
                </div>
            </>;
        }
        return <div className="table-content-cell"><p>{name}</p></div>;
    };

    render(): ReactNode {
        return <>
            <div className="table-content-row">
                <div className="table-content-cell">
                    <span className="mobile-label">{dateLabel()}:</span>
                    <span className="field-value">{localDateTimeFromISO(this.props.item.created_at)}</span>
                </div>
                {this.valueByType()}
            </div>
        </>;
    }
}
