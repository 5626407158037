import React, {PureComponent} from 'react';
import {artifactDescription, artifactName} from '../artifacts/utils';

export default class EquippedDescription extends PureComponent {
    render() {
        const {artifact, type} = this.props;
        return <div
            className={`hero__inventory hero__inventory--${type}${artifact ? ' hero__inventory--selected' : ''}`}
        >
            <div className="hero__inventory--title">{artifact && artifactName(artifact)}</div>
            <div className="hero__inventory--description">{artifact && artifactDescription(artifact)}</div>
        </div>;
    }
}
