import React, {Component, Fragment} from 'react';
import I18n from 'app/i18n';
import Hero from 'app/components/my_profile/hero';
import PrivateInventory from 'app/components/my_profile/private_inventory';
import {inject, observer, Provider} from 'mobx-react';
import {SteamTradeStore} from 'app/store/SteamTradeStore';
import SmartLoader from 'app/components/reusable/SmartLoader';
import withRouter from 'app/utils/navigate/customWithRouter';
import {ArtifactStoreController} from 'app/controllers/ArtifactStoreController';
import {ArtifactStore} from 'app/store/ArtifactStore';

interface ProfileInterface {
   params?: {
       name?: string;
   };
}

class Profile extends Component<ProfileInterface> {
    rootElement: React.RefObject<HTMLHeadingElement> = React.createRef();

    steamTradeStore = new SteamTradeStore();

    artifactStore = new ArtifactStore();

    controller = new ArtifactStoreController(this.props.params.name, this.artifactStore);

    componentDidMount(): void {
        this.controller.loadProfile();
    }

    componentDidUpdate(prevProps): void {
        if (this.props.params.name !== prevProps.params.name) {
            this.controller.setUserName(this.props.params.name);
            this.controller.loadProfile();
        }
    }

    render(): React.JSX.Element {
        const {user, denied, loading, tradeEnabled} = this.artifactStore;
        const {name} = this.props.params;
        return <Fragment>
            <h1 className="title" ref={this.rootElement}>
                {I18n.t('users_inventory', {name})}
            </h1>
            {loading && <SmartLoader/>}
            {denied && !loading
                ? <PrivateInventory
                    name={name}
                />
            // Provider for the correct display of inventory in the profile of other players
                : user && !loading &&
                    <Provider steamTradeStore={this.steamTradeStore} artifactStore={this.artifactStore}>
                        <Hero readOnly userName={name} tradeEnabled={tradeEnabled}/>
                    </Provider>}
        </Fragment>;
    }
}

export default withRouter(inject('artifactStore')(observer(Profile)));
