import React, {PureComponent, ReactNode} from 'react';
import {getLinkPath} from 'app/utils/link_helper';
import {userSignedIn} from 'app/utils';
import lootboxIconDesktop from 'app/components/svg/lootboxIconDesktop.jsx';
import {Link} from 'react-router-dom';
import I18n from 'app/i18n';

interface HeaderLootboxProps {
    clickHotHidden: () => void
    hot_icon_lootbox_enabled: boolean
}

class HeaderLootbox extends PureComponent<HeaderLootboxProps> {
    render(): ReactNode {
        const {hot_icon_lootbox_enabled, clickHotHidden} = this.props;
        return <div className="header__control header__control-lootboxes">
            <Link
                title={I18n.t('open_lootbox_shop_page')}
                to={getLinkPath('/shop/lootboxes')}
                className={`header__control-opener header__control-lootboxes-opener 
                                    ${hot_icon_lootbox_enabled ? 'header__control-hot' : ''}`}
                onClick={userSignedIn() && clickHotHidden}
            >
                <span className="svg__icon">{lootboxIconDesktop}</span>
            </Link>
        </div>;
    }
}

export default HeaderLootbox;
