import React, {FC, memo, ReactElement, ReactNode, useEffect, useState} from 'react';
import {getLinkPath} from 'app/utils';
import Get from 'app/utils/Get';

interface TableSourceProps {
    sourceUrl?: string;
    children?: ReactNode;
}

interface TableSourceState {
    has_more: boolean;
    loading: boolean;
}

const TableSource: FC<TableSourceProps> = memo((props: TableSourceProps) => {
    const [data, setData] = useState<unknown[]>([]);
    const [page, setPage] = useState<number>(0);
    const [state, setState] = useState<TableSourceState>({
        has_more: false,
        loading: true
    });

    useEffect(() => {
        setData([]);
        setPage(0);
        setState(() => ({
            has_more: false,
            loading: true
        }));
        loadData(true);
    }, [props.sourceUrl]);

    const loadData = (next: boolean): Promise<void> => {
        const getDataPage = next ? page + 1 : 1;
        return new Get({params: {page: getDataPage}, url: getLinkPath(props.sourceUrl)})
            .execute()
            .then(response => response.json())
            .then(response => {
                setData(next ? data.concat(response.data) : response.data);
                setPage(getDataPage);
                setState({
                    has_more: response.has_more,
                    loading: false
                });
            });
    };

    const showMoreHandler = (): Promise<void> => loadData(true);

    const resetData = (): void => {
        setData([]);
        setPage(1);
        setState({
            has_more: false,
            loading: true
        });
        loadData(false);
    };

    return <>
        {React.Children.map(props.children, child => React.cloneElement(child as ReactElement, {
            data,
            has_more: state.has_more,
            loading: state.loading,
            resetData,
            showMoreHandler
        }))}
    </>;
});

export default TableSource;

