import $ from 'jquery';
import React from 'react';
import I18n from 'app/i18n';
import {slowConnection, SubmitButton} from 'app/utils';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {showPopup} from 'app/utils/modals/ModalsService';

export class CountrySelection extends React.Component {
    componentDidMount() {
        $(this.countrySelect).chosen({disable_search: true});
    }

    okClick() {
        slowConnection($.ajax('/user/save_country', {
            method: 'POST',
            data: {country_code: this.countrySelect.value},
            success: this.popup.close()
        }));
    }

    render() {
        return <PopupDialog id={this.props.popupId} setFocus={this.props.setFocus} hideCloseButton>
            <div className="popup__content popup__content--small">
                <div className="modal-popup-content">
                    <div className="popup-form__row country">
                        <label htmlFor="field-country">{I18n.t('select_a_country')}</label>
                        <div className="field">
                            <div className="field__select">
                                <select ref={ref => this.countrySelect = ref}
                                    className="chosen-select field__select-chosen"
                                    id="field-country" defaultValue={CURRENT_COUNTRY}>
                                    {REG_FLAGS.map((f, i) => <option value={f.code} key={i}>{f.name}</option>)}
                                </select>
                                <div className="field__select-arrow"/>
                            </div>
                        </div>
                    </div>
                    <SubmitButton className="modal-popup-content__submit-row" okClick={() => this.okClick()} title={I18n.t('ok')}/>
                </div>
            </div>
        </PopupDialog>;
    }
}
export const showCountrySelectionDialog = props => showPopup('country-selection', <CountrySelection popupId={props.popupId}/>);
