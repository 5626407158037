import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import {getLinkPath} from 'app/utils/link_helper';
import {TableStore} from 'app/store/TableStore';
import {scrollToTop} from 'app/utils';

interface ViewMenuGamesItemProps {
    filter_image: string
    id: number
    name: string
    tableStore: TableStore
}
export default class ViewMenuGamesItem extends PureComponent<ViewMenuGamesItemProps> {
    constructor(props: ViewMenuGamesItemProps) {
        super(props);
        this.switchGame = this.switchGame.bind(this);
    }

    switchGame(): void {
        this.props.tableStore.clearFilters();
        this.props.tableStore.applyTableFilter(this.props.id, null, {forceUseHidden: false, results: false});
        scrollToTop();
    }

    render(): React.ReactNode {
        const {filter_image, name} = this.props;
        return <Link
            to={getLinkPath('/play/bets')}
            onClick={this.switchGame}
            className="view-game-item"
            title={name}
        >
            <img
                className="view-game-item__image"
                alt={name}
                title={name}
                src={filter_image}
            />
            <span className="view-game-item__title">
                <b>{name}</b>
            </span>
        </Link>;
    }
}
