import React, {ReactElement} from 'react';
import {IntersectionElement} from './IntersectionElement';

interface LazyLoadingInterface {
    show: boolean
    callback(): void;
}

export const LazyLoading = ({show, callback}: LazyLoadingInterface): ReactElement => {
    if (show) {
        return <IntersectionElement callback={callback}/>;
    }
    return <></>;
};
