import {IncubatorGameModel} from 'app/models/IncubatorGameModel';
import I18n from 'app/i18n';

export const updateIncubatorLineText = (incubatorGame: IncubatorGameModel): string => {
    if (checkSetWinnerText(incubatorGame)) {
        return I18n.t('match_result_saved');
    }

    if (checkChangeScore(incubatorGame) && checkChangeCoef(incubatorGame)) {
        return I18n.t('incubator_change_coef_and_score');
    }

    if (checkChangeScore(incubatorGame)) {
        return I18n.t('incubator_change_score');
    }
    if (checkChangeCoef(incubatorGame)) {
        return I18n.t('incubator_change_coef');
    }
    return I18n.t('update_line');
};

const checkSetWinnerText = (incubatorGame: IncubatorGameModel): boolean => {
    const {lineStore: {collection}, attributes} = incubatorGame;

    return collection && collection.winner !== attributes.winner;
};

const checkChangeScore = (incubatorGame: IncubatorGameModel): boolean => {
    const {lineStore: {collection}, attributes} = incubatorGame;

    return collection.gamer_1_score !== attributes.gamer_1_score ||
        collection.gamer_2_score !== attributes.gamer_2_score;
};

const checkChangeCoef = (incubatorGame: IncubatorGameModel): boolean => {
    const {lineStore: {collection}, attributes} = incubatorGame;

    return collection.coef_1 !== attributes.coef_1 ||
        collection.coef_2 !== attributes.coef_2;
};
