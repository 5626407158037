import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';

interface RowNoMarketingConsentProps {
    rowClass: string;
}

export class RowNoMarketingConsent extends Component<RowNoMarketingConsentProps> {
    render(): ReactNode {
        const {rowClass} = this.props;
        return <div className={rowClass}>
            <div className="field">
                <label className="field__check">
                    <input
                        className="field__check-hidden"
                        name="marketing_consent"
                        type="checkbox"
                        value="1"
                    />
                    <div className="field__check-box">
                        <svg height="11px" width="15px">
                            <use xlinkHref="#svg-check"/>
                        </svg>
                    </div>
                    <span className="label">{I18n.t('marketing_consent')}</span>
                </label>
            </div>
        </div>;
    }
}
