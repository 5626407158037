import React from 'react';
import {streamFlagImage, streamGameImage} from 'app/utils';

export default class StreamTitle extends React.PureComponent {
    setStream = e => {
        e.preventDefault();
        const {setStream, stream} = this.props;
        setStream(stream);
    };

    render() {
        const {stream, currentStreamId, popup} = this.props;
        return <span
            onClick={this.setStream}
            className={`stream-list${popup ? '--popup' : ''}__item js-streams-minheight ${currentStreamId === stream.id ? 'active-link' : ''}`}
        >
            {streamGameImage(stream.game, null, null, popup)}
            {streamFlagImage(stream.author_flag, null, null, popup)}
            <span>{stream.title}</span>
        </span>;
    }
}
