import React, {Component, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import I18n from 'app/i18n';
import {goToLinkMarketplace} from 'app/utils/app_links';
import InventoryItemInterface from 'app/interfaces/InventoryItemInterface';
import {toastError} from 'app/components/toasts/liteToast';
import {scrollToTop} from 'app/utils';
import BetSlipInventory from './BetSlipInventory';
import BetSlipCoefficient from './BetSlipCoefficient';
import GoMarketplace from 'app/components/my_profile/artifacts/artifacts_header/GoMarketplace';

import {UserStore} from 'app/store/UserStore';
import {ArtifactBetStore} from 'app/store/ArtifactBetStore';
import {BetSlipStore} from 'app/store/BetSlipStore';
import BetSlipController from 'app/components/bet_slip/BetSlipController';

interface ArtifactFormProps {
    userStore?: UserStore
    artifactBetStore?: ArtifactBetStore
    betSlipStore?: BetSlipStore
    controller?: BetSlipController
}

@inject('artifactBetStore', 'userStore', 'betSlipStore', 'controller')
@observer
export default class ArtifactForm extends Component<ArtifactFormProps> {
    componentDidMount(): void {
        this.props.artifactBetStore.getUserInventory();
        this.props.artifactBetStore.listen(this.props.userStore.user.user_id);
    }

    componentWillUnmount(): void {
        if (!this.props.betSlipStore.delayedBetChannel) {
            this.props.artifactBetStore.unsubscribe(this.props.userStore.user.user_id);
        }
    }

    goToMarketplace = (): void => {
        scrollToTop();
        goToLinkMarketplace();
    };

    addArtifact = (artifact: InventoryItemInterface): void => {
        if (!this.props.artifactBetStore.addArtifact(artifact)) {
            toastError(I18n.t('error_message_limitation_artifact'));
        }
    };

    render(): ReactNode {
        const {removeArtifact, artifactsToBet, availableToBetArtifacts} = this.props.artifactBetStore;
        const {betSlipStore: {express, coef, coefIncreasedForm}} = this.props;
        const {bets} = this.props.controller;
        const [bet] = bets;
        return <>
            {availableToBetArtifacts.length === 0 && artifactsToBet.length === 0
                ? <GoMarketplace
                    onClick={this.goToMarketplace}
                    boxClassName="bet-slip-go-market"
                />
                : <>
                    {availableToBetArtifacts.length > 0 && <BetSlipInventory
                        artifacts={availableToBetArtifacts}
                        onClick={this.addArtifact}
                        subTitle={I18n.t('your_inventory')}
                    />}
                    <BetSlipCoefficient
                        coef={String(coef)}
                        coefIncreased={coefIncreasedForm}
                        selectedLine={bet.selectedLine}
                        express={express}
                    />
                    <BetSlipInventory
                        artifacts={artifactsToBet}
                        onClick={removeArtifact}
                        subTitle={I18n.t('your_bet_label')}
                    />
                </>}
        </>;
    }
}
