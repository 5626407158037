import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {PageContent} from 'app/components/page_content';
import I18n from 'app/i18n';
import SupportButton from 'app/components/SupportButton';

const Contacts = inject('pageContentStore')(observer(class ContactsImpl extends Component {
    rootElement = React.createRef();

    render() {
        return <div ref={this.rootElement}>
            <h1 className="title">{I18n.t('contact_us_menu')}</h1>
            <PageContent contentUrl="/contacts" contentHolder contentKey="contacts">
                <div className="content__holder">
                    <div dangerouslySetInnerHTML={{__html: this.props.pageContentStore.content.contacts}}/>
                    <br/>
                    <div>{I18n.t('online_chat')}: <SupportButton/></div>
                </div>
            </PageContent>
        </div>;
    }
}));

export default Contacts;
