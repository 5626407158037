import React from 'react';

export default function Story(props) {
    const {
        duration,
        closeButton,
        onCloseButtonClick,
        children,
        ...rest
    } = props;
    return (
        <div className="swiper-slide" data-duration={duration} {...rest}>
            {closeButton &&
            <div className="stories-slider-actions">
                <button
                    className="stories-slider-close-button"
                    onClick={onCloseButtonClick}
                />
            </div>
            }
            <div className="stories-slider-content">{children}</div>
        </div>
    );
}
