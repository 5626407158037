import React, {Component} from 'react';
import {userSignedIn} from 'app/utils';
import WithUserComponent from 'app/components/promo_action/compendium/strarted_components/with_user_component';
import WithoutUserComponent from 'app/components/promo_action/compendium/strarted_components/without_user_component';

export default class StartedComponent extends Component {
    get isRenderable() {
        const {start_date, finished, currentTimeStore: {t}} = this.props;

        return start_date <= t && !finished;
    }

    render() {
        if (!this.isRenderable) {
            return null;
        }

        return userSignedIn()
            ? <WithUserComponent {...this.props}/>
            : <WithoutUserComponent {...this.props}/>;
    }
}
