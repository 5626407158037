import React, {memo} from 'react';
import I18n from 'app/i18n';
import {timeStampToTime} from 'app/utils';
import TableEmptyState from 'app/components/table/empty_state';
import dateLabel from './DateLabel';
import SellNowSteamSkin from 'app/components/common/SellNowSteamSkin';
import MyHeroLink from 'app/components/common/MyHeroLink';
import {goToLinkSimpleBets} from 'app/utils/app_links';
import ShowMore from 'app/utils/ShowMore';
import SmartTableLoader from 'app/components/reusable/smart_loaders/SmartTableLoader';

const Row = memo(props => {
    const {
        resetData,
        opened_at, sender_name, prize, promo_action_name, artifact,
        skin_gift: {steam_skin, steam_trade, artifact: egb_artifact}
    } = props;

    return <div className="table-content-row">
        <div className="table-content-cell">
            <span className="mobile-label">{dateLabel()}:</span>
            <span className="field-value">{timeStampToTime(opened_at, true)}</span>
        </div>
        <div className="table-content-cell">
            <span className="mobile-label">{I18n.t('gifts.sender')}:</span>
            <span className="field-value">{sender_name}</span>
        </div>
        <div className="table-content-cell">
            <span className="mobile-label">{I18n.t('prize_label')}:</span>
            <span className="field-value">{prize}</span>
        </div>
        <div className="table-content-cell">
            <span className="mobile-label">{I18n.t('promo_action_title')}:</span>
            <span className="field-value">{promo_action_name}</span>
        </div>
        <div className="table-content-cell">
            <SellNowSteamSkin
                afterSell={resetData}
                steam_skin={steam_skin}
                steam_trade={steam_trade}
                btnClass="button-adaptive button-adaptive_instyle"
                soldClassName="button-adaptive button-adaptive_instyle disabled"
            />
            {(artifact || egb_artifact) &&
                    <MyHeroLink btnClass="button-adaptive button-adaptive_instyle"/>}
        </div>
    </div>;
});

const GiftsTable = memo(props => {
    const {data, loading, has_more, resetData, showMoreHandler} = props;
    return <>
        <div className="table-content-head table-content-gifts">
            <div className="table-content-cell">
                <span className="table-content-cell-name">{dateLabel()}</span>
            </div>
            <div className="table-content-cell">
                <span className="table-content-cell-name">{I18n.t('gifts.sender')}</span>
            </div>
            <div className="table-content-cell">
                <span className="table-content-cell-name">{I18n.t('prize_label')}</span>
            </div>
            <div className="table-content-cell">
                <span className="table-content-cell-name">{I18n.t('promo_action_title')}</span>
            </div>
        </div>
        <div className="table-content-body table-content-gifts">
            {loading && <SmartTableLoader/>}
            {data.map(gift => <Row key={gift.id} {...gift} resetData={resetData}/>)}
        </div>
        <ShowMore onClickHandler={showMoreHandler} has_more={has_more}/>
        <TableEmptyState
            arrayLength={data.length}
            text={I18n.t('no_gifts')}
            buttonText={I18n.t('play')}
            buttonClick={goToLinkSimpleBets}
            loading={loading}
        />
    </>;
});

export default GiftsTable;
