import BetSlip from 'app/components/bet_slip/BetSlip';
import BetSlipController from 'app/components/bet_slip/BetSlipController';
import LineInterface from 'app/interfaces/LineInterface';
import MakeBetInterface from 'app/interfaces/MakeBetInterface';
import MakeBetParamsInterface from 'app/interfaces/MakeBetParamsInterface';
import {ArtifactBetStore} from 'app/store/ArtifactBetStore';
import {BetSlipStore} from 'app/store/BetSlipStore';
import {ParamsInterface} from 'app/utils/Get';

export abstract class BaseStrategy {
    betSlipController: BetSlipController;

    constructor(betSlipController: BetSlipController) {
        this.betSlipController = betSlipController;
    }

    get betSlipStore(): BetSlipStore {
        return this.betSlipController.betSlipStore;
    }

    get artifactBetStore(): ArtifactBetStore {
        return this.betSlipController.artifactBetStore;
    }

    abstract betSlipDidUpdate(betSlip: BetSlip): void;

    abstract maxWin(): number;

    abstract makeBetParams(params: MakeBetParamsInterface): MakeBetInterface;

    abstract placeBetSuccess(params: MakeBetInterface): void;

    abstract getParams(params: MakeBetInterface): ParamsInterface;

    abstract removeOnclick(line?: LineInterface): void;

    abstract getCoefSingle(): string;

    abstract setCoef(coef: string): void;

    abstract getLimit(type: string): number;

    abstract setFormCoefSingle(firstOpening?: boolean): void;

    abstract get postUrl(): string;

    abstract bets(): {num: number, rootLine: LineInterface, selectedLine: LineInterface}[];

    setCoefSingle = (changeAmount = false, firstOpening = false): void => {
        const {amountFocus, amount, netPrize} = this.betSlipStore;
        this.setFormCoefSingle(firstOpening);

        if (changeAmount) {
            this.betSlipController.changeInput(amountFocus ? amount : netPrize);
        }
    };

    setFormCoef(): void {
        const {setFormCoef, formCoef} = this.betSlipStore;
        const coefSingle = this.getCoefSingle();

        if (Number(formCoef) !== Number(coefSingle)) {
            setFormCoef(coefSingle);
        }
    }
}
