import React, {useEffect} from 'react';
import {createBrowserRouter, useNavigate} from 'react-router-dom';
import MainRoutes from './mainRoutes';

const RedirectTo = ({to}: {to: string}) => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(to);
    }, [navigate, to]);

    return null;
};

export const router = createBrowserRouter(
    [{Component: MainRoutes, errorElement: <RedirectTo to="/play/bets" />, path: '*'}]
);
