import PlaceInterface from 'app/interfaces/PlaceInterface';
import {ContentInterface, PageContentStore} from 'app/store/PageContentStore';
import {Component} from 'react';

interface PropsInterface {
    pageContentStore: PageContentStore
    url: string
}

export default class BaseLandingPage extends Component<PropsInterface> {
    get content(): ContentInterface {
        return this.props.pageContentStore.content as ContentInterface;
    }

    get prizes(): PlaceInterface[] {
        return this.content.prizes;
    }

    get predictionsCount(): number {
        return this.content.predictions_count;
    }
}
