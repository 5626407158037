import BaseValidation from 'validations/BaseValidation';


const sportStreamRegex = new RegExp(
    '^https:\\/\\/(?:|www\\.)' +
    '(?:flashscore.com)/\\S+?$'
    , 'u'
);


export default class SportStreamValidation extends BaseValidation<string, null> {
    execute = (): boolean => sportStreamRegex.test(this.attribute);
}
