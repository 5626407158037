import React, {Component, ReactNode} from 'react';
import FaqItemQuestion from 'app/components/pages/faq/FaqItemQuestion';
import {FaqInterface} from 'app/store/FaqStore';
import I18n from 'app/i18n';

interface FaqListQuestionsProps {
    faqs: FaqInterface[]
    scroll: boolean
}

export default class FaqListQuestions extends Component<FaqListQuestionsProps> {
    render(): ReactNode {
        return this.props.faqs.length > 0
            ? <div className="faq-list-questions">
                {this.props.faqs.map((faq, i) => <FaqItemQuestion
                    key={i}
                    title={faq.title}
                    text={faq.text}
                    name={faq.name}
                    scroll={this.props.scroll}
                />)}
            </div>
            : <div className="faq-nothing-found">{I18n.t('global.nothing_found')}</div>;
    }
}
