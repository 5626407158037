import React, {memo} from 'react';
import I18n from 'app/i18n';
import {localDateTimeFromISO} from 'app/utils';
import dateLabel from './DateLabel';
import MyHeroLink from 'app/components/common/MyHeroLink';
import SellNowSteamSkin from 'app/components/common/SellNowSteamSkin';
import {artifactName} from 'app/components/my_profile/artifacts/utils';
import {PromoActionDataInterface} from './PromoActionsTable';


interface PromoActionsTableRowInterface {
    resetData?(): void
}
export const PromoActionsTableRow: React.FC<PromoActionsTableRowInterface & PromoActionDataInterface> = memo(props => {
    const {promo_action_name, prizes, created_at, steam_trades, resetData} = props;
    return <>
        <div className="table-content-row">
            <div className="table-content-cell">
                <span className="mobile-label">{dateLabel()}:</span>
                <span className="field-value">{localDateTimeFromISO(created_at)}</span>
            </div>
            <div className="table-content-cell">
                <span className="mobile-label">{I18n.t('promo_action_title')}:</span>
                <span className="field-value">{promo_action_name}</span>
            </div>
            <div className="table-content-cell">
                <span className="mobile-label">{I18n.t('prize_label')}:</span>
                <div className="div-prizes">
                    {prizes.map(prize => <div className="prize-value" key={prize.name}>
                        {prize.artifact ? <p>{artifactName(prize.artifact)}</p> : <p>{prize.name}</p>}
                        {prize.artifact && <MyHeroLink btnClass="button-adaptive button-adaptive_instyle"/>}
                    </div>)}
                    {steam_trades.map(trade => <div className="prize-value" key={trade.steam_trade.id}>
                        <p>{trade.steam_skin.name}</p>
                        <SellNowSteamSkin
                            afterSell={resetData}
                            steam_skin={trade.steam_skin}
                            steam_trade={trade.steam_trade}
                            btnClass="button-adaptive button-adaptive_instyle"
                            soldClassName="button-adaptive button-adaptive_instyle disabled"
                        />
                    </div>)}
                </div>
            </div>
        </div>
    </>;
});
