import {disabledButton} from 'app/components/deposit/util/userVerifiedDocuments';
import CurrentP2pDepositInterface from 'app/interfaces/CurrentP2pDepositInterface';
import React, {PureComponent} from 'react';
import UploadButton from 'app/components/DocumentsUpload/UploadButton';

interface PropsInterface {
    p2p_deposit_invoice: CurrentP2pDepositInterface
    onUpload: () => void
}

export default class P2pUploadButton extends PureComponent<PropsInterface> {
    parentId(): number {
        const {p2p_deposit_invoice: {id, parent_p2p_deposit_invoice_id}} = this.props;
        return parent_p2p_deposit_invoice_id || id;
    }

    render(): React.ReactNode {
        const {p2p_deposit_invoice: {user_verified_documents, auto_waiting, waiting, not_valid}} = this.props;
        const disable = disabledButton(user_verified_documents);
        return <div className="value-button">
            {(auto_waiting || waiting || not_valid) && <UploadButton
                created_for_id={this.parentId()}
                created_for_type="P2PDepositInvoice"
                maxDocumentsLoaded={disable}
                justButton
                onUpload={this.props.onUpload}
            />}
        </div>;
    }
}
