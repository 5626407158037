import {Centrifuge, Subscription} from 'centrifuge';
import Get from 'app/utils/Get';
import {DataEventInterface} from 'modules/WsSubscription';
import {sendError} from 'app/utils/error/sendError';

let connection = null;
let token_url = null;

const getToken = (channel, handler) => {
    new Get({
        params: {channel},
        url: token_url
    }).execute()
        .then(response => {
            response.json()
                .then(token => handler(token.token));
        });
};

const Websocket = {
    getSubscription: (channel: string): Subscription | null => connection.getSubscription(channel),

    init(url: string, token: string, tokenUrl: string): void {
        if (connection) {
            return;
        }
        connection = new Centrifuge(url, {token});
        token_url = tokenUrl;
        Websocket.updateToken(token);
        // 1. connection.on('connect', x => { window.console.log('connect', x); });

        // 2. connection.on('disconnect', x => { window.console.log('disconnect', x); });
        connection.on('error', x => window.console.log('error', x));
        // 3. connection.on('publish', x => { window.console.log('publish', x); });

        // 4. connection.on('subscribe', x => { window.console.log('subscribe', x); });

        connection.connect();
    },

    onConnect: (handler: () => void): void => {
        connection.on('connect', handler);
    },

    subscribe: (channel: string, handler: (data: DataEventInterface) => void): string => {
        getToken(channel, token => {
            let sub = Websocket.getSubscription(channel);
            if (!sub) {
                sub = connection.newSubscription(channel, {token});
            }

            sub.on('publication', (data: DataEventInterface) => {
                try {
                    handler(data);
                } catch (err) {
                    console.error(err);
                    sendError(err);
                }
            });
            sub.subscribe();
        });
        return channel;
    },

    unsubscribe: (channel: string): void => {
        const sub = Websocket.getSubscription(channel);

        if (sub) {
            sub.unsubscribe();
            sub.removeAllListeners();
        }
    },

    updateToken: (token: string): void => {
        connection.setToken(token);
    }
};

export default Websocket;
