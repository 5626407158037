import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {HelpDeskSupportLink} from 'app/components/HelpDeskSupportLink';
import axios from 'axios';

interface SupportButtonProps {
    hideIcon?: boolean
}

export default class SupportButton extends PureComponent<SupportButtonProps> {
    state = {
        country: 'XX'
    };

    componentDidMount(): void {
        this.getCountry();
    }

    getCountry = (): void => {
        axios
            .get('https://ipapi.co/json/')
            .then(response => {
                this.setState({country: response.data.country_code});
            });
    };

    supportLink = (children: ReactNode): ReactNode => {
        const ua = this.state.country === 'UA';
        return ua
            ? <a
                href="https://t.me/egbcom_bot"
                target={'_blank'}
                className = {this.className(true)} rel="noreferrer"
            >
                {children}
            </a>

            : <>
                <HelpDeskSupportLink className = {this.className(true)} >
                    {children}
                </HelpDeskSupportLink>
            </>;
    };

    className = (ru = false): string => {
        const className = 'cursor-pointer';

        if (this.props.hideIcon) {
            return className;
        }

        return ru ? `${className} support__link` : `${className }support__link support__disabled`;
    };

    render(): ReactNode {
        return this.supportLink(
            <>
                {!this.props.hideIcon && <svg height="30px" width="30px">
                    <use xlinkHref="#svg-question"/>
                </svg>}
                {`${I18n.t('support')} Online`}
            </>
        );
    }
}
