import React, {Fragment, PureComponent} from 'react';
import {fixed2, getLinkPath, scrollByHash} from 'app/utils';
import ReferralsTable from './referrals/table';
import ShowMore from 'app/utils/ShowMore';
import Get from 'app/utils/Get';
import ListDestinations from './referrals/ListDestinations';
import BannerButtonBlock from './referrals/BannerButtonBlock';
import TableEmptyState from 'app/components/table/empty_state';
import I18n from 'app/i18n';

export default class Referrals extends PureComponent {
    state = {
        data: [],
        has_more: false,
        loading: false,
        page: 1,
        referrals_amount: 0,
        referrals_count: 0
    };

    componentDidMount() {
        scrollByHash();

        this.loadMoreHandler();
    }

    loadMoreHandler = () => {
        const {page, data} = this.state;
        this.setState({loading: true});
        return new Get({
            params: {page},
            url: getLinkPath('/my_profile/referrals')
        }).execute()
            .then(response => response.json())
            .then(response => {
                this.setState({
                    data: data.concat(response.data),
                    has_more: response.has_more,
                    loading: false,
                    page: page + 1,
                    referrals_amount: response.referrals_amount,
                    referrals_count: response.referrals_count
                });
            });
    };

    render() {
        const {data, has_more, loading, referrals_count, referrals_amount} = this.state;
        return <div className="referral-page">
            <ListDestinations/>
            <BannerButtonBlock
                referrals_count={referrals_count}
                link={window.SITE_DATA.REFERRAL_LINK}
                paid_amount={`$${fixed2(referrals_amount)}`}
            />
            <Fragment>
                <ReferralsTable data={data} loading={loading}/>
                {data && data.length === 0 && !loading &&
                    <TableEmptyState arrayLength={data.length} text={I18n.t('no_referral')}/>}
                <ShowMore onClickHandler={this.loadMoreHandler} has_more={has_more}/>
            </Fragment>
        </div>;
    }
}
