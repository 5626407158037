import PayzenBaseClass from '../payzen/PayzenBaseClass';
import {inject, observer} from 'mobx-react';

@inject('depositsStore')
@observer
export default class SkinsbackDeposit extends PayzenBaseClass {
    submitHandler = (event: Event): void => {
        if (!this.state.url) {
            event.preventDefault();
        }

        const {route_path} = this.props.depositsStore.currentPaymentSystem;
        this.submitRedirect(`/deposits/${route_path}`, `/deposits/${route_path}/prepared`);
    };

    handleSuccessResponse = (response: Response, intervalTimerId: number): void => {
        window.clearInterval(intervalTimerId);
        location.href = response.url;
    };
}
