import React, {PureComponent, ReactNode} from 'react';
import VolumeControl from 'site_version/com/volumeControl';

export default class IncubatorVolumeButton extends PureComponent {
    render(): ReactNode {
        return <div className="button-incubator-volume">
            <VolumeControl/>
        </div>;
    }
}
