import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';

interface RowTradeLinkProps {
    rowClass: string;
    errorHint: ReactNode;
    tradeLink: string;
    cleanError: (field: string) => void;

}

export class RowTradeLink extends Component<RowTradeLinkProps> {
    cleanError = (): void => {
        this.props.cleanError('trade_link');
    };

    render(): ReactNode {
        const {rowClass, errorHint, tradeLink} = this.props;
        return <div className={rowClass}>
            <label className="field-label" htmlFor="field-invited">{I18n.t('trade_link')}</label>
            <div className="field">
                <input className="field__input"
                    name="trade_link"
                    defaultValue={tradeLink}
                    onKeyUp={this.cleanError}/>
            </div>
            {errorHint}
        </div>;
    }
}
