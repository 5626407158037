import React, {PureComponent} from 'react';
import pick from 'lodash/pick';
import I18n from 'app/i18n';

export default class SmartTooltipReason extends PureComponent {
    render(): React.ReactNode {
        return <div className="tooltip-reason">
            <span className="tooltip-reason-button">{I18n.t('reason_title')}</span>
            <div className="tooltip-reason-body">
                <div className="tooltip-reason-description">
                    <p>{I18n.t('cancelled_due_to_reason_title',
                        {...pick(this.props, ['cancelled_reason'])})}</p>
                </div>
            </div>
        </div>;
    }
}
