import {action, makeObservable, observable} from 'mobx';
import Get from 'app/utils/Get';
import ShopItemInterface from 'app/interfaces/shop/ShopItemInterface';


export interface OtherItemInterface {
    title?: string;
    description?: string;
    item?: ShopItemInterface;
    user?: {
        money: number;
        points: string;
    }
    content_langs?: { [key: string]: string };
    langs?: { [key: string]: string }
}
export class OtherItemStore {
    constructor() {
        makeObservable(this);
    }

    @observable item: OtherItemInterface = null;


    @action
    updateItem(item: OtherItemInterface): void {
        this.item = item;
    }

    getItem(id: number): void {
        new Get({url: `/shop/other/${id}`})
            .execute()
            .then(response => {
                if (!response.ok) {
                    throw response;
                }

                return response.json();
            })
            .then(r => this.updateItem(r));
    }
}
