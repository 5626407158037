import React from 'react';
import I18n from 'app/i18n';
import {timeStampToTime} from 'app/utils';

export default class UploadedDocument extends React.PureComponent {
    getColor = () => {
        switch (this.props.document.status) {
        case 'verified':
            return 'holder--color2';
        case 'not_verified':
            return 'holder--color3';
        default:
            return '';
        }
    };

    render() {
        return <div>
            <span className={this.getColor()}>
                {I18n.t('user.document')} {this.props.index + 1}
                , {timeStampToTime(this.props.document.created_at, true)}
            </span>
        </div>;
    }
}
