import React, {PureComponent} from 'react';
import {artifactName} from 'app/components/my_profile/artifacts/utils';

export default class TabLink extends PureComponent {
    handleTabLinkClick = e => this.props.handleTabLinkClick(this.props.recipe, e);

    render() {
        return <a href="#"
            className={'tabs__link' + (this.props.selected ? ' active-link' : '')}
            onClick={this.handleTabLinkClick}
        >
            {artifactName(this.props.recipe)}
        </a>;
    }
}
