import {switchLanguage} from 'app/utils/langs/switchLanguage';

export const AVAILABLE_LOCALES = [
    {
        flag: 'ru',
        lang: 'ru',
        name: 'Русский'
    },
    {
        flag: 'gb',
        lang: 'en',
        name: 'English'
    },
    {
        flag: 'cz',
        lang: 'cs',
        name: 'Ceska'
    },
    {
        flag: 'de',
        lang: 'de',
        name: 'Deutsch'
    },
    {
        flag: 'es',
        lang: 'es',
        name: 'Español'
    },
    {
        flag: 'fr',
        lang: 'fr',
        name: 'Francese'
    },
    {
        flag: 'it',
        lang: 'it',
        name: 'Italiano'
    },
    {
        flag: 'kr',
        lang: 'ko',
        name: '한국의'
    },
    {
        flag: 'pl',
        lang: 'pl',
        name: 'Polski'
    },
    {
        flag: 'pt',
        lang: 'pt',
        name: 'Português'
    },
    {
        flag: 'tr',
        lang: 'tr',
        name: 'Türkçe'
    },
    {
        flag: 'cn',
        lang: 'zh',
        name: '中國的'
    }
];

export const AVAILABLE_LOCALES_LANGS: string[] = [
    'ru',
    'en',
    'cs',
    'de',
    'es',
    'fr',
    'it',
    'ko',
    'pl',
    'pt',
    'tr',
    'zh'
];

export const getLanguageStorage = (): string => localStorage.getItem('language');

export const setLanguageStorage = (lang: string): void => localStorage.setItem('language', lang);

export const defaultLanguage = (): string => {
    const currentLocale = AVAILABLE_LOCALES.find(locale => locale.lang === browserLanguage());
    return currentLocale ? currentLocale.lang : window.DEFAULT_LOCALE;
};

export const setLanguage = (lang: string): void => {
    if (rootPage()) {
        const language = lang ? lang : defaultLanguage();
        setLanguageStorage(language);
        switchLanguage(null, language);
    } else {
        setLanguageStorage(window.CURRENT_LOCALE);
    }
};

const rootPage = (): boolean => location.pathname === '/';

const browserLanguage = (): string => shortLang(navigator.languages && navigator.languages[0] ||
    navigator.language);

const shortLang = (lang: string): string => lang.substring(0, lang.indexOf('-'));

