import React, {ReactNode} from 'react';
import {betMoney, date, isDesktop, time} from 'app/utils';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import UserCasinoLogInterface from 'app/interfaces/casino/CasinoLogInterface';
import {casinoLogToChat} from 'app/utils/share_to_chat';
import I18n from 'app/i18n';
import {toastSuccess} from 'app/components/toasts/liteToast';

interface UserCasinoLogRowProps extends UserCasinoLogInterface {
    key: string
}

export default class TableHistoryRowCasino extends React.Component<UserCasinoLogRowProps> {
    chatButtonClick = (e: React.MouseEvent): void => {
        e.preventDefault();
        e.stopPropagation();

        casinoLogToChat(this.props.id);
    };

    checkActionWin = (): boolean => this.props.action === 'win';

    checkActionRollback = (): boolean => this.props.action === 'rollback';

    shareInChatButton(): ReactNode {
        return this.checkActionWin() && <button
            key={`share_chat_button_${this.props.id}`}
            className="button-adaptive button-adaptive_instyle"
            onClick={this.chatButtonClick}
        >
            <svg className="svg-default button-icon">
                <use xlinkHref="#svg-arrow-link-new"/>
            </svg>
            <span className="button-name">{I18n.t('chat_title')}</span>
        </button>;
    }

    colorClassName = (): string => {
        if (this.checkActionRollback()) {
            return ' match-canceled';
        }

        if (this.checkActionWin()) {
            return ' match-won';
        }

        return '';
    };

    onClickCopyMessage = (): void => {
        toastSuccess(I18n.t('casino_bet_copied'));
    };

    renderMobile(): React.ReactNode {
        const {id, created_at, game, action, amount} = this.props;

        return <div className={`table-content-row-mobile${this.colorClassName()}`}>
            <div className="history-match">
                <div className="history-match-date">
                    <span>{date(created_at)}</span>
                    <span>{time(created_at)}</span>
                    <div className="history-match-id">
                        <span className="history-match-id-number">{id}</span>
                        <div className="copy-button">
                            <CopyToClipboard text={id} onCopy={this.onClickCopyMessage}>
                                <span className="icon">
                                    <svg className="svg-default">
                                        <use xlinkHref="#svg-copy-icon"/>
                                    </svg>
                                </span>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
                <div className="history-match-team">
                    <span>{game}</span>
                </div>
            </div>
            <div className="table-content-inner-row-mobile">
                <div className="casino-cell">
                    <span className="history-match-sum">{I18n.t(`casino_filters.${action}`)}</span>
                </div>
                <div className="casino-cell">
                    <span>{betMoney({amount, playmoney: false})}</span>
                </div>
            </div>
            <div className="history-right-buttons">
                {this.shareInChatButton()}
            </div>
        </div>;
    }

    render(): React.ReactNode {
        const {id, created_at, game, action, amount} = this.props;

        if (!isDesktop()) {
            return this.renderMobile();
        }

        return <div className={`table-content-row${this.colorClassName()}`}>
            <div className="table-content-cell">
                <div className="table-events">
                    <span className="history-match-date">
                        <span>{date(created_at)}</span>
                        <span>{time(created_at)}</span>
                    </span>
                    <span>{game}</span>
                </div>
            </div>
            <div className="table-content-cell">
                <div className="history-match-id">
                    <span className="history-match-id-number">{id}</span>
                    <div className="copy-button">
                        <CopyToClipboard text={id} onCopy={this.onClickCopyMessage}>
                            <span className="icon">
                                <svg className="svg-default">
                                    <use xlinkHref="#svg-copy-icon"/>
                                </svg>
                            </span>
                        </CopyToClipboard>
                    </div>
                </div>
            </div>
            <div className="table-content-cell">
                <span className="history-match-sum">{I18n.t(`casino_filters.${action}`)}</span>
            </div>
            <div className="table-content-cell">
                <span>{betMoney({amount, playmoney: false})}</span>
            </div>
            <div className="table-content-cell">
                <div className="right-side-cell">
                    {this.shareInChatButton()}
                </div>
            </div>
        </div>;
    }
}
