import React, {Component} from 'react';

interface IntersectionElementProps {
    callback?: ()=> void
}
export class IntersectionElement extends Component<IntersectionElementProps> {
    observer = new IntersectionObserver(entries => {
        if (entries && entries[0].isIntersecting) {
            this.props.callback();
        }
    }, {});

    content = React.createRef<HTMLDivElement>();

    componentDidMount(): void {
        this.observer.observe(this.content.current);
    }

    componentWillUnmount(): void {
        this.observer.disconnect();
    }

    render(): React.ReactNode {
        return <div ref={this.content}>   </div>;
    }
}
