import React, {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {scrollToTop} from 'app/utils';
import {getLinkPath} from 'app/utils/link_helper';
import I18n from 'app/i18n';
import logEvent from 'app/amplitude/log_event';

interface PropsInterface {
    linkUserPromotion: string
    itemClassName: string
    localePromotionTitle: string
    svgIconName: string
    localePromotionText: string
    bannerType: string
    nameCoockie?: string
    showClose?: boolean
}

export default class UserPromotion extends React.PureComponent<PropsInterface> {
    state = {
        showPromotion: window.localStorage.getItem(this.props.nameCoockie) === 'true'
    };

    onClick = (): void => {
        scrollToTop();
        logEvent('BANNER_OPENED', {type: this.props.bannerType});
    };

    setLocalStorageItem = (): void => {
        this.setState({showPromotion: true});
        window.localStorage.setItem(this.props.nameCoockie, 'true');
    };

    render(): ReactNode {
        const {linkUserPromotion, itemClassName, localePromotionTitle, localePromotionText, svgIconName, showClose} = this.props;
        return !this.state.showPromotion && <div className="user-promotion">
            <Link
                to={getLinkPath(linkUserPromotion)}
                className={`user-promotion-content ${itemClassName}`}
                onClick={this.onClick}
            >
                <div className="user-promotion-content__title">
                    <span className="promotion-name">{I18n.t(localePromotionTitle)}</span>
                    <svg className="svg-default promotion-icon">
                        <use xlinkHref={`#svg-promotion-${svgIconName}`}/>
                    </svg>
                </div>
                <div className="user-promotion-content__text">
                    <p>{I18n.t(localePromotionText)}</p>
                </div>
            </Link>
            {showClose && <button
                className="user-promotion__close"
                onClick={this.setLocalStorageItem}
            >
                <span className="icon">
                    <svg className="svg-default">
                        <use xlinkHref="#svg-close"/>
                    </svg>
                </span>
            </button>}
        </div>;
    }
}
