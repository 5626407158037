import React, {PureComponent} from 'react';
import SwiperCore from 'swiper';
import {Swiper} from 'swiper/swiper-react';

const swiperParams = {
    allowTouchMove: true,
    freeMode: {
        momentum: false,
        momentumBounce: false
    },
    observeParents: true,
    observer: true,
    touchRatio: 0.65,
    touchReleaseOnEdges: false,
    touchStartForcePreventDefault: true
};

interface ViewMenuGamesListProps {
    isSwiper: boolean
    onCheckReached?: (swiper: SwiperCore) => void
    children?: React.ReactNode
}

export default class ViewMenuGamesList extends PureComponent<ViewMenuGamesListProps> {
    render(): React.ReactNode {
        const {children, onCheckReached} = this.props;
        return this.props.isSwiper
            ? <Swiper className="slider-container-swiper" {...swiperParams}
                slidesPerView="auto"
                freeMode
                preventInteractionOnTransition
                preventClicksPropagation
                resistance={false}
                watchSlidesProgress
                onTouchEnd={onCheckReached}
                onAfterInit={onCheckReached}>{children}</Swiper>
            : <div className="slider-container-free">{children}</div>;
    }
}
