import React from 'react';
export default <svg id="svg-lootboxes"
                    height="18px"
                    width="18px"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
    <path d="M8.97471 10.156V16.8574L14.1801 15.8449V12.5798C14.1801
    12.4431 14.2962 12.337 14.4287 12.3525L15.0691 12.4541C15.1818 12.4674
    15.2668 12.5651 15.2668 12.6814V16.4745C15.2668 16.5824 15.1934 16.6755
    15.0905 16.6982L9.39236 17.954C9.117 18.0146 8.83225 18.0153 8.55663
    17.9559L2.91961 16.7406C2.81845 16.7188 2.74513 16.6286 2.74243
    16.5227L2.64505 12.6703C2.64199 12.5495 2.73118 12.4471 2.84862
    12.4366L7.63694 12.0053C7.71998 11.9978 7.79202 11.9435 7.82378
    11.8646L8.54515 10.069C8.63822 9.83726 8.97471 9.9055 8.97471
    10.156ZM2.59089 7.96196L1.08828 11.2539C1.01486 11.4148 1.14049
    11.5963 1.31288 11.5784L6.95594 10.994C7.03569 10.9858 7.10499
    10.9343 7.1375 10.8592L8.95322 6.66663L2.75275 7.8339C2.68179
    7.84727 2.62149 7.89497 2.59089 7.96196ZM8.95325 6.66663L10.8745
    10.9903C10.9081 11.0659 10.979 11.1168 11.0598 11.1235L16.6922
    11.5877C16.8619 11.6017 16.9838 11.4236 16.9131 11.2648L15.4442
    7.96416C15.4138 7.8959 15.3527 7.84727 15.2807 7.83396L8.95325
    6.66663Z" fill="#000000"/>
    <path d="M1.6333 1L4 6.5L8.99997 5.53333L14.9011 6.66667L16.9333 1H1.6333Z"
          fill="url(#paint0_linear1)"/>
    <defs>
        <linearGradient
            id="paint0_linear1"
            x1="9.56663"
            y1="1"
            x2="9.56663"
            y2="7.23333"
            gradientUnits="userSpaceOnUse"
        >
            <stop
                offset="0.148767"
                stopColor="#000000"
                stopOpacity="0"
            />
            <stop
                offset="1"
                stopColor="#000000"
                stopOpacity="0.7"
            />
        </linearGradient>
    </defs>
</svg>;