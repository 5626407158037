import moment from 'moment';

const DAY_SECONDS = 86400;

export const isNewNews = (newsDate: number): boolean => {
    if (typeof newsDate !== 'number') {
        return false;
    }
    return moment().unix() - newsDate < DAY_SECONDS;
};
