import React from 'react';
import {inject, observer} from 'mobx-react';
import {BetSlipStore} from 'app/store/BetSlipStore';
import BetSlipController from 'app/components/bet_slip/BetSlipController';
import {BetSlipWarning} from 'app/components/bet_slip/BetSlipWarning/BetSlipWarning';
import {isRedLine} from 'app/lines/line_helpers';
import {isRealMoney} from 'app/lines/isRealMoney';
import {isDemoMoney} from 'app/lines/isDemoMoney';
import {checkOwnLine} from 'app/lines/checkOwnLine';

interface BetSlipBodyContainerInterface {
    betSlipStore?: BetSlipStore
    controller?: BetSlipController
}

export const BetSlipWarningContainer = inject('controller', 'betSlipStore')(
    observer(
        (props: BetSlipBodyContainerInterface): JSX.Element => {
            const {
                controller: {bet, suggestCoef, userStore: {user}},
                betSlipStore: {incubator2, warning, loadingForm, incubator2WithCoef, showSuggestCoef, type, selectedLine}
            } = props;
            const isNotRealMoneyIncubator2 = incubator2 && !isRealMoney(type);
            const isDemoBetAndIncubator2 = bet.selectedLine && bet.selectedLine.incubator_2 && user.demo && isDemoMoney(type);
            const showWarning = !loadingForm &&
                  (!user.enable_incubator2 || isNotRealMoneyIncubator2 || !incubator2WithCoef &&
                          showSuggestCoef || isDemoBetAndIncubator2) && warning;
            const availableForIncubator2_0 = user.enable_incubator2 && isRealMoney(type) && bet.selectedLine &&
             bet.selectedLine.live && (isRedLine(bet.selectedLine.color) || !incubator2WithCoef) &&
                (isRedLine(bet.selectedLine.color) || !checkOwnLine(selectedLine.isCreator));

            return <BetSlipWarning
                showWarning={showWarning}
                warning={warning}
                available={availableForIncubator2_0}
                onClick={suggestCoef}
            />;
        }
    )
);
