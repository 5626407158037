import WithZeroDays from './days_components/with_zero_days';
import WithoutZeroDays from './days_components/without_zero_days';

export default function daysClassComponent(daysComponentName) {
    switch (daysComponentName) {
    case 'with_zero_days':
        return WithZeroDays;
    case 'without_zero_days':
        return WithoutZeroDays;
    default:
        return WithoutZeroDays;
    }
}
