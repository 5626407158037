import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Row from './Row';

export default class Body extends PureComponent {
    static propTypes = {data: PropTypes.array.isRequired};

    render() {
        const {data} = this.props;

        return data.map(referral => {
            const {user: {name}} = referral;

            return <Row key={name} {...referral}/>;
        });
    }
}
