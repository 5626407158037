import React, {ReactNode} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import {PokerStore} from 'app/store/PokerStore';
import AsidePokerItem from './AsidePokerItem';

interface Props {
    pokerStore?: PokerStore
}

@inject('pokerStore')
@observer
export default class AsidePoker extends React.Component<Props> {
    componentDidMount(): void {
        this.props.pokerStore.loadTables();
        this.props.pokerStore.listen();
    }

    render(): ReactNode {
        const {tables, openTable} = this.props.pokerStore;

        return <div className="aside-item poker-aside">
            <div className="aside-item-heading">
                <div className="aside-item-heading__title">
                    <h3>{I18n.t('poker_game')}</h3>
                </div>
            </div>
            <div className="poker-tables-list">
                {tables && tables.map((table, i) => <AsidePokerItem
                    key={`poker-table-${i}`}
                    maxPlayers={table.max_players}
                    currentPlayers={table.count_players}
                    openTable={openTable.bind(this.props.pokerStore)}
                    tableId={table.table_id}
                />)}
            </div>
        </div>;
    }
}
