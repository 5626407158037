import React, {PureComponent, ReactNode} from 'react';
import {openRegistration} from 'app/components/auth/auth';

interface SignUpButtonProps {
    title?: string
    usingOnTab: string
    className?: string
    children?: ReactNode | ReactNode[]
}
export class SignUpButton extends PureComponent<SignUpButtonProps> {
    handleRedirectClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        const {usingOnTab} = this.props;
        openRegistration(usingOnTab);
    };

    render(): React.ReactNode {
        const {title, className, children} = this.props;
        return <button
            className={`${className ? className : 'button-adaptive'}`}
            onClick={this.handleRedirectClick}>
            {title}{children}
        </button>;
    }
}
