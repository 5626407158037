import beerBackground from 'images/beer/st_patrick/beer-background.svg';
import beerLiquid from 'images/beer/st_patrick/beer-liquid.svg';
import beerVessel from 'images/beer/st_patrick/beer-vessel.svg';
import oktoberfestBackground from 'images/beer/therese_von_bayern/beer-background.svg';
import oktoberfestLiquid from 'images/beer/therese_von_bayern/beer-liquid.svg';
import oktoberfestVessel from 'images/beer/therese_von_bayern/beer-vessel.svg';

export default {
    PROMO_ACTION_BEER: {
        BACKGROUND: beerBackground,
        LIQUID: beerLiquid,
        VESSEL: beerVessel
    },
    PROMO_ACTION_OKTOBERFEST: {
        BACKGROUND: oktoberfestBackground,
        LIQUID: oktoberfestLiquid,
        VESSEL: oktoberfestVessel
    }
};
