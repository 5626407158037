import $ from 'jquery';
import React, {Fragment, PureComponent} from 'react';
import {i18n_t} from 'app/i18n';
import TableEmptyState from 'app/components/table/empty_state';
import {timeStampToTime} from 'app/utils';
import {goToLinkSimpleBets} from 'app/utils/app_links';
import ShowMore from 'app/utils/ShowMore';
import {userStore} from 'app/store/global';
import Post from 'app/utils/Post';
import Get from 'app/utils/Get';
import SmartLoader from 'app/components/reusable/SmartLoader';
import {toastError} from 'app/components/toasts/liteToast';

interface MessageCheckboxProps {
    message: {[key: string]: any},
    selectMessage(id: number): {selectedIds: number[]}
}

interface MessageInterfaceState {
    messages: {
        [key: string]: any
    }[];
    has_more: boolean;
    page: number;
    loading: boolean;
    selectedIds: number[];
    unread_count: number;
}

class MessageCheckbox extends PureComponent<MessageCheckboxProps, {}> {
    selectMessage = () => this.props.selectMessage(this.props.message.id);

    render() {
        const {message} = this.props;
        return <div className="field" onClick={this.selectMessage}>
            <label className="field__check">
                <input type="checkbox" className="field__check-hidden" data-id={message.id}/>
                <span className="field__check-box small">
                    <svg height="11px" width="15px"><use xlinkHref="#svg-check"/></svg>
                </span>
            </label>
        </div>;
    }
}

export default class Messages extends PureComponent<{}, MessageInterfaceState> {
    constructor(props) {
        super(props);
        this.state = {
            has_more: false,
            loading: true,
            messages: [],
            page: 1,
            selectedIds: [],
            unread_count: 0
        };
    }

    componentDidMount() {
        this.load();
    }

    load = (): Promise<void> => new Get({
        params: {page: this.state.page},
        url: '/messages'
    }).execute()
        .then(response => {
            if (response.ok) {
                response.json().then(response => {
                    this.setState({
                        loading: false,
                        has_more: response.has_more,
                        messages: this.state.messages.concat(response.messages),
                        page: response.page,
                        unread_count: response.unread_count
                    });
                });
            } else {
                response.json().then(response => toastError(response.message));
            }
        });

    markMessageAsRead = state => {
        const {messages, selectedIds, unread_count} = state;

        const isMessageSelected = message => selectedIds.length === 0 || selectedIds.includes(message.id);

        return {
            messages: messages.map(message => ({
                ...message,
                ...isMessageSelected(message) ? {unread: false} : {}
            })),
            selectedIds: [],
            unread_count: selectedIds.length === 0 ? 0 : unread_count - selectedIds.length
        };
    };

    markRead = (): void => {
        if (!this.hasUnread()) {
            return;
        }
        this.setState({loading: true});
        new Post({
            params: {ids: this.state.selectedIds},
            url: '/messages/mark'
        }).execute()
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    this.setState(this.markMessageAsRead,
                        () => {
                            $('.table-messages').find(':checked').prop('checked', false);
                        });
                    userStore.update({messagesCount: response.messages_count});
                }
            }).finally(() => this.setState({loading: false}));
    };

    selectMessage = (id: number): any => {
        this.setState(state => {
            if (state.selectedIds.includes(id)) {
                return {selectedIds: state.selectedIds.filter(i => i !== id)};
            }
            return {selectedIds: [id].concat(state.selectedIds)};
        });
    };

    hasUnread = (): boolean => this.state.unread_count > 0;

    render(): React.ReactNode {
        const {loading} = this.state;
        return <Fragment>
            <div className="title">{i18n_t('user.messages')}</div>
            <div className="content__holder">
                <div className="table-messages">
                    <div className="table-messages__heading tables-heading">
                        <div className="table-messages__date-subject">
                            <div className="table-messages__date">{i18n_t('date_label')}</div>
                            <div className="table-messages__subject">{i18n_t('user.subject')}</div>
                        </div>
                        <div className="table-messages__notification">
                            <span>{i18n_t('message')}</span>
                            <button
                                className={`act btn ${this.hasUnread() ? '' : 'btn--disable'}`}
                                onClick={this.markRead}
                            >
                                {this.state.selectedIds && this.state.selectedIds.length !== 0
                                    ? i18n_t('user.mark_as_read')
                                    : i18n_t('mark_all_read')}
                            </button>
                        </div>
                    </div>

                    {loading && <SmartLoader />}
                    {this.state.messages.map(message => <div
                        key={message.id}
                        className={`table-messages__main-row${
                            message.unread ? ' table-messages__main-row--unread' : ''
                        }${message.important ? ' table-messages__main-row--important' : ''}`}
                    >
                        <div className="table-messages__date-subject">
                            <div className="table-messages__date">
                                {message.unread &&
                                <MessageCheckbox
                                    message={message}
                                    selectMessage={this.selectMessage}
                                />}
                                <div className="table-messages__date--value">{timeStampToTime(message.date, true)}</div>
                            </div>
                            <div className="table-messages__subject">{message.subject}</div>
                        </div>
                        <div className="table-messages__notification">{message.msg}</div>
                    </div>)}
                    <ShowMore onClickHandler={this.load} has_more={this.state.has_more}/>
                </div>
            </div>
            {this.state.messages.length === 0 && !loading && <TableEmptyState
                arrayLength={this.state.messages.length}
                text={i18n_t('you_have_no_notification_yet')}
                buttonText={i18n_t('play')}
                buttonClick={goToLinkSimpleBets}
            />}

        </Fragment>;
    }
}
