import {Link} from 'react-router-dom';
import React, {PureComponent} from 'react';

interface ListArtifactEffectsButtonProps {
    linkMyHero: () => string
    onClick: () => void
    text: string
}

export default class ListArtifactEffectsButton extends PureComponent<ListArtifactEffectsButtonProps> {
    render(): React.ReactNode {
        const {linkMyHero, onClick, text} = this.props;
        return <Link className="button-adaptive button-adaptive_full-radius"
            to={linkMyHero()}
            onClick={onClick}
        >
            {text}
        </Link>;
    }
}
