import React, {Component, MouseEvent, ReactNode} from 'react';
import KeyboardButton from './KeyboardButton';

import {fixed2} from 'app/utils/fixed';
import {isPlayMoney} from 'app/lines/isPlayMoney';
import {DEFAULT_BETS} from 'app/utils/Consts';

interface MobileKeyboardProps {
    changeAmount: (amount: string) => void
    handleBackspace: (e: MouseEvent<HTMLButtonElement>) => void
    handleChangeAmountInsertLast: (symbol: string) => void
    type: string
    amount: string
    max: number
    disableButtons: boolean
}

const DEFAULT_BUTTONS = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '0'
];

export default class MobileKeyboard extends Component<MobileKeyboardProps> {
    render(): ReactNode {
        const {type, changeAmount, max, handleChangeAmountInsertLast, handleBackspace, amount, disableButtons} = this.props;
        return <div className={`mobile-keyboard ${isPlayMoney(type) ? 'pm' : ''}`}>
            <div className="row">
                {DEFAULT_BUTTONS.map((value, ind) => <KeyboardButton
                    changeAmount={handleChangeAmountInsertLast}
                    className="w10"
                    key={ind}
                    title={value}
                    value={value}
                />)}
            </div>
            <div className="row">
                <KeyboardButton
                    changeAmount={handleChangeAmountInsertLast}
                    className="w12"
                    title="."
                    value="."
                />
                {DEFAULT_BETS.map((value, ind) => <KeyboardButton
                    changeAmount={changeAmount}
                    className="w19"
                    key={ind}
                    disabled={disableButtons}
                    title={`+${value}`}
                    value={fixed2(Number(amount) + Number(value))}
                />)}
                <KeyboardButton
                    changeAmount={changeAmount}
                    className="w19"
                    title="max"
                    disabled={disableButtons}
                    value={fixed2(max)}
                />
                <div className="cell w12">
                    <button className="button-keyboard backspace" onClick={handleBackspace}>
                        <svg className="svg-default"><use xlinkHref="#svg-keyboard-backspace"/></svg>
                    </button>
                </div>
            </div>
        </div>;
    }
}
