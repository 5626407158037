import CashoutInterface from 'app/interfaces/CashoutInterface';
import {modalConfirm} from 'app/utils/modals/popups';
import {cashoutsStore} from 'app/store/global';
import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {betMoney, localDateTimeFromISO, slowConnectionPromise} from 'app/utils';
import TimeToStartTimer from 'app/utils/TimeToStartTimer';
import {confirmCashoutMessage} from 'app/utils/p2p/confirmCashoutMessage';
import SmartLoader from 'app/components/reusable/SmartLoader';

export const blankAmount = (amount: string): string => amount === '0.00' ? '' : betMoney({amount});

interface PropsInterface extends CashoutInterface {
    cancelCashout: (id: number) => void
    submitting: boolean
    cancellingCashoutId :number
}

export const CancelButtonStates = [
    'accepted',
    'in_progress',
    'init'
];
export const ConfirmButtonStates = ['waiting', 'not_valid'];
export const TimerStates = ['waiting'];

export default class Row extends PureComponent<PropsInterface> {
    handleCancelClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        e.preventDefault();
        const {cancelCashout, id} = this.props;
        cancelCashout(id);
    };

    handleConfirmClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        e.preventDefault();
        this.confirm();
    };

    confirm = (): void => {
        const {id} = this.props;
        modalConfirm(confirmCashoutMessage(this.props), () => {
            cashoutsStore.confirm(id);
        });
    };

    handleTransferNotReceivedClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        e.preventDefault();
        this.invalidate();
    };

    invalidate(): void {
        const {id} = this.props;
        modalConfirm(I18n.t('if_transfer_not_received'), () => {
            slowConnectionPromise(cashoutsStore.invalidate(id));
        });
    }

    renderSystem = (): ReactNode => {
        const {system, crypto_currency, account_display} = this.props;

        if (system.includes('P2P')) {
            return `${system} (${account_display})`;
        }
        if (crypto_currency) {
            return `${system} (${crypto_currency})`;
        }
        return system;
    };

    render(): ReactNode {
        const {id, instant_amount, amount, created_at, state, cancellable,
            external_url, paid_at, submitting, cancellingCashoutId, bank_logo} = this.props;
        const loading = CancelButtonStates.includes(state) && cancellable && submitting && cancellingCashoutId === id;
        return <tr>
            <td>
                <span className="value-date">{localDateTimeFromISO(created_at)}</span>
            </td>
            <td>
                <span className="value-payment-number">{id}</span>
            </td>
            <td>
                <div className="value-payment-system">
                    <span className="value-payment-system-name">{this.renderSystem()}</span>
                    {bank_logo && <span className="value-payment-system-img"><img src={bank_logo?.url}/></span>}
                </div>
            </td>
            <td>
                <span className="value-instant">{blankAmount(instant_amount)}</span>
            </td>
            <td>
                <span className="value-amount">{blankAmount(amount)}</span>
            </td>
            <td>
                <p className="section">
                    <span className="value-status">{I18n.t(`user.status_${state}`)}</span>
                </p>
                {TimerStates.includes(state) && <p className="section"><span className="timer-waiting">
                    <TimeToStartTimer plainText time_to_start={paid_at}/>
                </span></p>}
            </td>
            <td>
                <div className="value-button">
                    {
                        loading
                            ? <SmartLoader />
                            : CancelButtonStates
                                .includes(state) && cancellable && <a
                                href="#"
                                className="button-adaptive button-adaptive_transparent"
                                onClick={this.handleCancelClick}
                            >
                                {I18n.t('user.cancel_label')}
                            </a>
                    }
                    {external_url && <a
                        className="button-adaptive button-adaptive_transparent"
                        target="_blank"
                        href={external_url}
                        rel="noreferrer"
                    >
                        {I18n.t('show_now')}
                    </a>}

                    {ConfirmButtonStates.includes(state) && <a
                        href="#"
                        className="button-adaptive button-adaptive_transparent"
                        onClick={this.handleConfirmClick}
                    >{I18n.t('confirm')}</a>}
                    {state === 'waiting' && <a
                        href="#"
                        className="button-adaptive button-adaptive_link"
                        onClick={this.handleTransferNotReceivedClick}
                    >{I18n.t('transfer_not_received')}</a>}
                </div>
            </td>
        </tr>;
    }
}
