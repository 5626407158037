import {init as initApm} from '@elastic/apm-rum';

const env = process.env.NODE_ENV;

if (env === 'production') {
    initApm({
        environment: 'production',
        errorThrottleInterval: 10,
        eventsLimit: 1,
        queueLimit: 10,

        serverUrl: `https://${location.hostname}`,
        serviceName: 'Egb',
        serviceVersion: '2',
        transactionSampleRate: 0.2,
        transactionThrottleInterval: 10
    });
}
