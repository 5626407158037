import React, {PureComponent, ReactNode, ChangeEvent, KeyboardEvent} from 'react';
import {i18n_t} from 'app/i18n';

interface SearchInputProps {
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void,
    handleKeyPress: (event: KeyboardEvent<HTMLInputElement>) => void,
    value: string,
    onBlur?(): void,
    onClick?(): void
}

export default class SearchInput extends PureComponent<SearchInputProps> {
    render(): ReactNode {
        return <div className="search-container">
            <span className="search-container__icon">
                <svg className="svg-default">
                    <use xlinkHref="#svg-search-icon"/>
                </svg>
            </span>
            <input
                className="search-container__input"
                type="text"
                placeholder={i18n_t('search')}
                onChange={this.props.handleChange}
                onKeyPress={this.props.handleKeyPress}
                value={this.props.value}
                onBlur={this.props.onBlur}
            />
            {this.props.value.length > 0 && <button
                className="search-container__clear"
                onClick={this.props.onClick}
            >
                <svg className="svg-default">
                    <use xlinkHref="#svg-close"/>
                </svg>
            </button>}
        </div>;
    }
}
