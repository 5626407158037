import $ from 'jquery';
import Chosen from 'chosen.jquery';

Chosen.prototype.old_set_up_html = Chosen.prototype.set_up_html;
Chosen.prototype.set_up_html = function() {
    const result = this.old_set_up_html();
    this.search_results.perfectScrollbar({wheelPropagation: true});
    return result;
};

Chosen.prototype.choice_build = function(item) {
    const choice = $('<li/>', {class: 'search-choice'}).html(`<span>${this.choice_label(item)}</span>`);

    if (item.disabled) {
        choice.addClass('search-choice-disabled');
    } else {
        const close_link = $('<a/>', {
            class: 'search-choice-close',
            'data-option-array-index': item.array_index,
            html: '<svg height="10px" width="10px">' +
                '<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#svg-close"></use>' +
            '</svg>'
        });
        close_link.bind('click.chosen', evt => this.choice_destroy_link_click(evt));
        choice.append(close_link);
    }
    return this.search_container.before(choice);
};

Chosen.prototype.choice_destroy_link_click = function(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (!this.is_disabled) {
        return this.choice_destroy($(evt.currentTarget));
    }
    return null;
};

Chosen.prototype.search_field_scale = function() {
    let _i, _len, div, f_width, h, style, style_block, styles, w;
    if (this.is_multiple) {
        h = 0;
        w = 0;
        style_block = 'position:absolute; left: -1000px; top: -1000px; display:none;';
        styles = [
            'font-size',
            'font-style',
            'font-weight',
            'font-family',
            'line-height',
            'text-transform',
            'letter-spacing'
        ];
        for (_i = 0, _len = styles.length; _i < _len; _i++) {
            style = styles[_i];
            style_block += `${style }:${ this.search_field.css(style) };`;
        }
        div = $('<div />', {
            style: style_block
        });
        div.text(this.search_field.val());
        $('body').append(div);
        w = div.width() + 25;
        div.remove();
        f_width = this.container.outerWidth();
        if (w > f_width - 10) {
            w = f_width - 10;
        }
    }
};
