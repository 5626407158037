import BankInterface from 'app/interfaces/BankInterface';
import CurrentP2pDepositInterface from 'app/interfaces/CurrentP2pDepositInterface';
import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';
import {ChangeEvent} from 'react';
import {action, computed, makeObservable, observable} from 'mobx';
import Get from 'app/utils/Get';
import {getLinkPath} from 'app/utils/link_helper';
import Post from 'app/utils/Post';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import I18n from 'app/i18n';
import uniqBy from 'lodash/uniqBy';
import {DEFAULT_CURRENCY} from 'app/utils/Consts';
import CollectionInterface from 'app/interfaces/CollectionInterface';
import AvailableP2pDeposit from 'app/interfaces/AvailableP2pDeposit';
import {BETS_BONUS_TYPE} from 'app/components/user/SelectBonusType';
import {sendGaFirstDepositEvent} from 'app/utils/deposits/sendGaFirstDepositEvent';

export const ALL_BANKS = -1;
export const OTHER_BANK = 0;

interface CountryInterface {
    country_code: string
    title: string
    currency: string
}

export class P2pDepositsStore {
    @observable available_p2p_deposits: AvailableP2pDeposit[] = [];

    @observable p2p_countries: CountryInterface[] = [];

    @observable current_p2p_deposit: CurrentP2pDepositInterface = null;

    @observable current_p2p_deposits: CurrentP2pDepositInterface[] = [];

    @observable min_amount: string = null;

    @observable max_amount: string = null;

    @observable promo_code: string = null;

    @observable invalid_promo_code: boolean = false;

    @observable submitting: boolean = false;

    @observable currentBank: BankInterface = null;

    @observable currentCountry: CountryInterface = null;

    @observable currentPaymentSystem: PaymentSystemInterface = null;

    @observable currency: string = null;

    @observable currencies: CollectionInterface[] = [];

    constructor() {
        this.updateCurrentP2pDeposits([]);
        makeObservable(this);
    }

    @action
        update = (available: AvailableP2pDeposit[],
            current: CurrentP2pDepositInterface, currentDeposits: CurrentP2pDepositInterface[] = null): void => {
            if (available) {
                this.available_p2p_deposits = available;
            }
            this.current_p2p_deposit = current;
            if (currentDeposits) {
                this.current_p2p_deposits = currentDeposits;
            }
        };

    @action changeCountries = (countries: CountryInterface[]): void => {
        const [first_element] = countries;

        this.p2p_countries = countries;
        this.currentCountry = first_element;
        this.setCurrencies();
    };

    @computed
    get filteredDeposits(): AvailableP2pDeposit[] {
        return this.available_p2p_deposits.filter(
            deposit => this.hasMinAmount(this.convertible(this.currency) ? deposit.currency_amount : deposit.amount) &&
                            this.hasMaxAmount(this.convertible(this.currency) ? deposit.currency_amount : deposit.amount) &&
                            this.hasBank(deposit.bank)
        );
    }

    hasMinAmount(amount: number): boolean {
        if (!this.min_amount) {
            return true;
        }
        return amount >= Number(this.min_amount);
    }

    hasMaxAmount(amount: number): boolean {
        if (!this.max_amount) {
            return true;
        }
        return amount <= Number(this.max_amount);
    }

    hasBank(bank: BankInterface): boolean {
        if (!this.currentPaymentSystem.related_to_bank_bins || !this.currentBank) {
            return true;
        } else if (!bank) {
            return this.currentBank.id === OTHER_BANK;
        }

        return this.currentBank.id === ALL_BANKS || this.currentBank.id === bank.id;
    }

    @computed
    get banks(): BankInterface[] {
        const defaultBanks: BankInterface[] =
            [{id: ALL_BANKS, name: I18n.t('all_banks')}, {id: OTHER_BANK, name: I18n.t('other')}];
        return uniqBy(
            this.available_p2p_deposits.reduce((accumulator, value) => {
                if (value.bank) {
                    accumulator.push(value.bank);
                }
                return accumulator;
            }, defaultBanks),
            'id'
        );
    }

    @action
        changeFilterMinAmount = (min: string): void => {
            this.min_amount = min;
        };

    @action
        changeFilterMaxAmount = (max: string): void => {
            this.max_amount = max;
        };

    updatePromoCode = (e: ChangeEvent<HTMLInputElement>): void => {
        this.updatePromoCodeValue(e.target.value);
    };

    @action
        updatePromoCodeValue = (value: string): void => {
            this.promo_code = value;
            this.updateInvalidPromoCode(false);
        };

    @action
        updateInvalidPromoCode = (value: boolean): void => {
            this.invalid_promo_code = value;
        };

    @action
        updateSubmitting = (value: boolean): void => {
            this.submitting = value;
        };

    loadP2pDeposits = (systemId: number): void => {
        const params: {country_code?: string} = {};

        if (this.currentPaymentSystem.p2p_same_country && this.currentCountry) {
            params.country_code = this.currentCountry.country_code;
        }

        new Get({params,
            url: getLinkPath(`/deposits/${systemId}/available_deposits`)})
            .execute()
            .then(data => data.json())
            .then(data => {
                this.update(data.available_p2p_deposits, data.current_p2p_deposit);
                this.selectBankById(data.p2p_bank_id);
            });
    };

    loadP2PCountries = (systemId: number): void => {
        new Get({url: getLinkPath(`/deposits/${systemId}/available_countries`)})
            .execute()
            .then(data => data.json())
            .then(data => {
                this.changeCountries(data);
            });
    };

    loadCurrentP2pDeposits = (): void => {
        new Get({url: getLinkPath('/deposits/current_p2p_deposits')})
            .execute()
            .then(data => data.json())
            .then(data => this.updateCurrentP2pDeposits(data.current_p2p_deposits));
    };

    @action
    updateCurrentP2pDeposits(deposits: CurrentP2pDepositInterface[]): void {
        this.current_p2p_deposits = deposits;
    }

    acceptP2pDeposit = (deposit: AvailableP2pDeposit, bonus: number, currency: string): Promise<void> => {
        const {id} = this.currentPaymentSystem;
        return new Post({
            params: {
                bonus,
                currency,
                group_cashouts: (deposit.group_cashouts || []).map(c => c.id),
                id: deposit.id,
                payment_system_id: id
            },
            url: '/deposits/p2p_deposits'
        })
            .execute()
            .then(data => data.json())
            .then(data => {
                if (data.message) {
                    toastError(data.message);
                } else {
                    sendGaFirstDepositEvent();
                    this.update(null, data.start_result.current_p2p_deposit);
                }
            });
    };

    currentRealBankId(): number | null {
        return !this.currentBank || this.currentBank.id === ALL_BANKS ? null : this.currentBank.id;
    }

    startDepositByAmount = (amount: string, bonus: number, promoCode: string): void => {
        const {id, p2p_same_country} = this.currentPaymentSystem;
        const currency = p2p_same_country ? this.currentCountry.currency || this.currency : null;
        const country_code = p2p_same_country && this.currentCountry && this.currentCountry.country_code;
        new Post({
            params: {
                amount: amount || 0,
                bank_id: this.currentRealBankId(),
                bonus: bonus || BETS_BONUS_TYPE,
                country_code,
                currency,
                payment_system_id: id,
                promo_code: promoCode
            },
            url: '/deposits/p2p_deposits'
        })
            .execute()
            .then(data => data.json())
            .then(data => {
                if (data.message) {
                    toastError(data.message);
                } else {
                    sendGaFirstDepositEvent();
                    this.update(null, data.start_result.current_p2p_deposit);
                }
            });
    };

    confirmP2pTransfer = (): void => {
        const {id} = this.current_p2p_deposit;
        this.updateSubmitting(true);
        new Post({
            params: {promo_code: this.promo_code},
            url: `/deposits/p2p_deposits/${id}/confirm`
        })
            .execute()
            .then(data => data.json())
            .then(data => {
                this.updateSubmitting(false);
                if (data.invalid_promo_code) {
                    this.updateInvalidPromoCode(true);
                } else {
                    this.update(null, data.current_p2p_deposit, data.current_p2p_deposits);
                    this.updatePromoCodeValue(null);
                }
                this.update(null, data.current_p2p_deposit, data.current_p2p_deposits);
            });
    };

    cancelP2pDepositQuery = (url: string, params?: {country_code?: string}): Promise<void | Response> => new Post({
        params, url
    }).execute()
        .then(data => data.json())
        .then(data => {
            this.update(data.available_p2p_deposits, data.current_p2p_deposit, data.current_p2p_deposits);
            toastSuccess(I18n.t('p2p_deposit_cancelled'));
        });

    cancelP2pDeposit = (id: number = null): void => {
        const params: {country_code?: string} = {};

        if (this.currentCountry) {
            params.country_code = this.currentCountry.country_code;
        }

        this.cancelP2pDepositQuery(`/deposits/p2p_deposits/${id || this.current_p2p_deposit.id}/cancel`, params)
            .catch();
    };

    cancelOneP2pDeposit = (id: number = null): void => {
        const params: {country_code?: string} = {};

        if (this.currentCountry) {
            params.country_code = this.currentCountry.country_code;
        }

        this.cancelP2pDepositQuery(`/deposits/p2p_deposits/${id || this.current_p2p_deposit.id}/cancel_one`, params)
            .catch();
    };

    fixP2pDeposit = (id: number = null): void => {
        new Post({
            url: `/deposits/p2p_deposits/${id || this.current_p2p_deposit.id}/fix`
        })
            .execute()
            .then(data => data.json())
            .then(() => this.loadCurrentP2pDeposits());
    };

    @action
    selectBankById(bankId: number, saveBank = false): void {
        this.currentBank = this.banks.find(b => b.id === bankId);
        if (saveBank) {
            new Post({params: {bank_id: bankId}, url: '/deposits/p2p_deposits/save_bank'}).execute();
        }
    }

    @action
    selectCountry(countryCode: string): void {
        this.currentCountry = this.p2p_countries.find(c => c.country_code === countryCode);
        this.loadP2pDeposits(this.currentPaymentSystem.id);
        this.setCurrencies();
    }

    @action
    selectPaymentSystem(paymentSystem: PaymentSystemInterface): void {
        this.currentPaymentSystem = paymentSystem;
        this.currentBank = null;
    }

    @action
    // eslint-disable-next-line max-statements
    setCurrencies(): void {
        this.currencies = [];

        if (!this.currentPaymentSystem) {
            return;
        }

        if (this.currentPaymentSystem.p2p_same_country && this.currentCountry) {
            if (this.convertible(this.currentCountry.currency)) {
                this.currencies.push({
                    text: this.currentCountry.currency,
                    value: this.currentCountry.currency
                });
            }
        } else {
            const {exchange_currency} = this.currentPaymentSystem;

            if (this.convertible(exchange_currency)) {
                this.currencies.push({
                    text: exchange_currency,
                    value: exchange_currency
                });
            }
        }

        this.currencies.push({text: DEFAULT_CURRENCY,
            value: DEFAULT_CURRENCY});
        this.currency = this.currencies[0].text;
    }

    @action
    selectCurrency(currency: string): void {
        this.currency = currency;
    }

    convertible = (currency: string): boolean => currency !== DEFAULT_CURRENCY;
}
