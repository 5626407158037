import {action, makeObservable, observable} from 'mobx';
import I18n from 'app/i18n';
import {showBetForm} from 'app/components/table';
import MyBetInterface from 'app/interfaces/MyBetInterface';
import Get from 'app/utils/Get';
import UserCasinoLogInterface from 'app/interfaces/casino/CasinoLogInterface';
import {getLinkPath} from 'app/utils';

interface UserBetsInterface {
    bets: MyBetInterface[],
    has_more: boolean
}

interface FilterInterface {
    has_more: boolean,
    page: number,
    type: string,
    bet_type?: string
    bet_currency?: string
    casino?: boolean
}

interface CasinoFilterInterface {
    page: number,
    action: string,
    has_more: boolean
}

interface UserCasinoLogsInterface {
    data: UserCasinoLogInterface[],
    has_more: boolean
}

export class UserBetsStore {
    @observable casinoLogs = [];

    @observable userBets = [];

    @observable casinoFilter: CasinoFilterInterface = {
        action: 'all',
        has_more: true,
        page: 1
    };

    @observable filter: FilterInterface = {
        bet_currency: 'all',
        bet_type: 'all',
        casino: false,
        has_more: true,
        page: 1,
        type: 'all'
    };

    @observable loading = false;

    @observable openedUserBetId = 0;

    constructor() {
        makeObservable(this);
    }

    @action
        setLoading = (value: boolean): void => {
            this.loading = value;
        };

    @action
        setOpenedUserBetId = (value: number): void => {
            this.openedUserBetId = value;
        };

    @action
        setDefaultAllFilters = (): void => {
            this.filter.type = 'all';
            this.setDefaultBetsFilters();
            this.setDefaultCasinoFilters();
        };

    @action
        setDefaultBetsFilters = (): void => {
            this.filter.bet_type = 'all';
            this.filter.bet_currency = 'all';
            this.filter.page = 1;
        };

    @action
        setDefaultCasinoFilters = (): void => {
            this.casinoFilter.action = 'all';
            this.casinoFilter.page = 1;
        };

    @action
        setDefaultParams = (): void => {
            this.userBets = [];
            this.filter.page = 1;
        };

    @action
        toggleCasino = (): void => {
            this.filter.casino = !this.filter.casino;
            if (this.filter.casino) {
                this.setDefaultCasinoFilters();
                this.filter.type = 'casino';
                this.setDefaultBetsFilters();
            } else {
                this.setDefaultParams();
                this.filter.type = 'simple_bets';
            }
        };

    @action
        changeCasinoAction = (value: string): void => {
            this.casinoFilter.action = value;
        };

    @action
        changeBetType = (value: string): void => {
            this.filter.bet_type = value;
            this.loadBets(this.filter.type);
        };

    @action
        changeBetCurrency = (value: string): void => {
            this.filter.bet_currency = value;
            this.loadBets(this.filter.type);
        };

    @action
        update = (data: UserBetsInterface, reset = true): void => {
            if (data && data.bets) {
                this.userBets = reset ? data.bets : this.userBets.concat(data.bets);
                this.filter.page += 1;
            }
            this.filter.has_more = data && data.has_more;
        };

    @action
        changeType = (value: string, resetFilters = false): void => {
            if (resetFilters) {
                this.filter.bet_currency = 'all';
                this.filter.bet_type = 'all';
            }
            this.filter.type = value;
        };

    loadBetsHistory = (successLoadFunction: (response: UserBetsInterface) => void): Promise<void> => {
        const params = this.getFilterParams();
        this.setLoading(true);
        return new Get({
            params: {...params}, url: getLinkPath('/my_bets')
        })
            .execute()
            .then(response => response.json())
            .then(data => {
                successLoadFunction(data);
            }).finally(() => {
                this.setLoading(false);
            });
    };

    loadBetsHandler = (): Promise<void> => this.loadBetsHistory(response => this.update(response));

    updateLoadedBetsHandler = (): Promise<void> => this.loadBetsHistory(response => this.update(response, false));

    @action
        filterParams = (page: number): void => {
            this.filter.page = page || this.filter.page;
            this.filter.has_more = false;
        };

    getFilterParams = (): FilterInterface => ({
        bet_currency: this.filter.bet_currency,
        bet_type: this.filter.bet_type,
        has_more: this.filter.has_more,
        page: this.filter.page,
        type: this.filter.type
    });

    loadBets = (type: string): void => {
        this.changeType(type);
        this.filterParams(1);
        this.loadBetsHandler();
    };

    loadLogs = (logAction: string): void => {
        this.changeCasinoAction(logAction);
        this.updateCasinoPage(1);
        this.loadCasinoLogs();
    };

    @action
        updateCancelledUserBet = (id: string): void => {
            if (this.filter.bet_type === 'active') {
                this.userBets.splice(this.userBets.findIndex(el => el.id === id), 1);
            } else {
                const bet = this.userBets.find(u => id === u.id);
                bet.started = false;
                bet.cancelled = true;
                bet.cancelled_reason = I18n.t('user_cancel_bet.reason');
            }
        };

    betMore = (bet: { hash_id: string, id: number }): void => {
        showBetForm(bet, null, {afterBet: this.loadBetsHandler});
    };

    @action
        updateCasinoPage = (value: number): void => {
            this.casinoFilter.page = value;
        };

    @action
        updateCasinoLogs = (data: UserCasinoLogsInterface): void => {
            if (this.casinoFilter.page > 1) {
                this.casinoFilter.has_more = data.has_more;
                this.casinoLogs = this.casinoLogs.concat(data.data);
            } else {
                this.casinoLogs = data.data;
                this.casinoFilter.has_more = data.has_more;
            }
        };

    @action
        loadCasinoLogs = (next_page = false): Promise<void> => {
            if (next_page) {
                this.increasePage();
            }

            this.setLoading(true);
            return new Get({
                params: {
                    casino_action: this.casinoFilter.action,
                    page: this.casinoFilter.page,
                    type: 'casino'
                },
                url: getLinkPath('/my_bets')
            })
                .execute()
                .then(response => response.json()).then(data => {
                    this.updateCasinoLogs(data);
                }).finally(() => {
                    this.setLoading(false);
                });
        };

    increasePage = (): void => {
        this.updateCasinoPage(this.casinoFilter.page + 1);
    };
}
