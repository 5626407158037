import React from 'react';
import {useParams} from 'react-router-dom';
import {inject} from 'mobx-react';
import PlayContentImpl from './index';
import {TableStore} from 'app/store/TableStore';

interface PlaySimpleBetsContentProps {
    tableStore?: TableStore
}
const PlaySimpleBetsContent = (props: PlaySimpleBetsContentProps) => {
    const {filter = ''} = useParams();

    return (
        <PlayContentImpl
            filter={filter}
            urlPrefix="/play/bets/"
            keyPrefix="simple_bets"
            tableStore={props.tableStore}
            esports
        />
    );
};

export default inject('tableStore')(PlaySimpleBetsContent);
