import React, {MouseEvent, PureComponent, ReactNode} from 'react';

interface KeyboardButtonProps {
    changeAmount: (amount: string) => void
    className: string
    title: string
    value: string
    disabled?: boolean
}

export default class KeyboardButton extends PureComponent<KeyboardButtonProps> {
    changeAmount = (e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        const {changeAmount, value} = this.props;
        changeAmount(value);
    };

    render(): ReactNode {
        const {className, title, disabled} = this.props;
        return <div className={`cell ${className}`}>
            <button
                onClick={this.changeAmount}
                className="button-keyboard"
                disabled={disabled}>
                {title}
            </button>
        </div>;
    }
}
