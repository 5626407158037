import React, {useCallback} from 'react';
import {timeStampToTime, betMoney} from 'app/utils';
import {size190x190} from 'app/utils/CasinoGameImages';
import {startCasinoGame} from 'app/services/CasinoServices';

interface Props {
    game_id: number
    name: string
    amount: string
    created_at: number
    bet_amount: string
    provider_name: string
    identifier: string
}

const AccordionItem: React.FC<Props> = props => {
    const startCasino = () => {
        startCasinoGame(props.game_id);
    };

    const handleStartCasino = useCallback(() => startCasino(), [startCasino]);

    return <div className="accordion-item">
        <div className="image">
            <button className="button-image" onClick={handleStartCasino}>
                <img className="image-img"
                    src={size190x190(props.provider_name, props.identifier)}
                    alt="Teen Patti"/>
            </button>
        </div>
        <div className="description">
            <div className="information">
                <span className="name">{props.name}</span>
                <span className="amount">{betMoney({amount: props.bet_amount})}</span>
                <span className="arrow">-&gt;</span>
                <span className="amount">{betMoney({amount: props.amount})}</span>
            </div>
            <span className="date">{timeStampToTime(props.created_at, true)}</span>
        </div>
    </div>;
};

export default AccordionItem;
