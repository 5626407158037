import React, {MouseEvent, PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import HistoricalDepositInterface from 'app/interfaces/HistoricalDepositInterface';

interface FailedButtonProps {
    deposit: HistoricalDepositInterface
    cancelDeposit: (e: MouseEvent<HTMLAnchorElement>) => void
}

export default class FailedButton extends PureComponent<FailedButtonProps> {
    cancelDeposit = (e: MouseEvent<HTMLAnchorElement>): void => {
        this.props.cancelDeposit(e);
    };

    render(): ReactNode {
        const {deposit: {waiting, not_valid, auto_waiting}} = this.props;
        return <div className="value-button">
            {(waiting || not_valid) && <a
                onClick={this.cancelDeposit}
                href="#"
                className={`button-adaptive ${auto_waiting
                    ? 'button-adaptive_disable'
                    : 'button-adaptive_transparent'}`}
            >
                {I18n.t('i_failed')}
            </a>}
        </div>;
    }
}
