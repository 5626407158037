import React, {PureComponent} from 'react';
import I18n from 'app/i18n';

export default class HistoryMessage extends PureComponent {
    tradeMessage = () => {
        switch (this.props.status) {
        case 'cancelled':
            return I18n.t('trade_cancelled');
        case 'item_gone':
            return I18n.t('trade_item_gone');
        case 'expired':
            return I18n.t('trade_expired');
        default:
            return I18n.t('trade_accepted');
        }
    };
    endDay = () => {
        const {status} = this.props;
        return status === 'accepted' || status || 'expired' ? this.props.endDay : null;
    };
    render() {
        let tradeStatus = this.props.status === 'accepted' ? 'accepted' : 'expired';
        let endDay = this.endDay();

        return <div className={'offer-date offer-date--' + tradeStatus}>
            {this.tradeMessage()} {endDay && <span>{endDay}</span>}
        </div>;
    }
}