import React, {Component, ReactNode, RefObject} from 'react';
import $ from 'jquery';
import I18n from 'app/i18n';
import {betMoney, localDateTimeFromISO, slowConnectionAndError} from 'app/utils';
import {userStore} from 'app/store/global';

const limitPeriodLabels = function(value) {
    switch (value) {
    case 'daily':
        return I18n.t('daily');
    case 'weekly':
        return I18n.t('weekly');
    case 'monthly':
        return I18n.t('monthly');
    default:
        return I18n.t('daily');
    }
};

interface RowDepositLimitProps {
    deposit_limit: string;
    deposit_limit_period: string;
    new_deposit_limit?: string;
    new_deposit_limit_period?: string;
    new_deposit_limit_effective_from?: string;
    rowClass: string;
    errorHint: ReactNode;
    cleanError: (field: string) => void;
}

interface RowDepositLimitState {
    deposit_limit: string;
    deposit_limit_period: string;
}

export class RowDepositLimit extends Component<RowDepositLimitProps, RowDepositLimitState> {
    constructor(props: RowDepositLimitProps) {
        super(props);
        this.state = {
            deposit_limit: props.deposit_limit || '',
            deposit_limit_period: props.deposit_limit_period || 'daily'
        };
    }

    limitPeriodSelect: RefObject<HTMLSelectElement> = React.createRef();

    componentDidMount(): void {
        $(this.limitPeriodSelect.current).chosen({disable_search: true}).trigger('chosen:updated');
        $(this.limitPeriodSelect.current).on('change', e => this.setState({deposit_limit_period: e.target.value}));
    }

    handleDepositLimitPeriod = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        this.setState({deposit_limit_period: e.target.value});
    };

    resetDepositLimit = (event: React.MouseEvent): void => {
        event.preventDefault();
        if ($(event.target).hasClass('btn--disable')) {
            return;
        }
        slowConnectionAndError($.ajax('/user/reset_deposit_limit', {
            dataType: 'JSON',
            success: response => {
                userStore.update({user: response});
            },
            type: 'POST'
        }));
    };

    assignDepositLimitMessage(new_deposit_limit: string,
        new_deposit_limit_period:string,
        new_deposit_limit_effective_from: string): string | null {
        if (new_deposit_limit_effective_from) {
            return new_deposit_limit
                ? I18n.t('new_deposit_limit', {
                    date: localDateTimeFromISO(new_deposit_limit_effective_from),
                    limit: betMoney({amount: new_deposit_limit}),
                    period: limitPeriodLabels(new_deposit_limit_period)
                })
                : I18n.t('new_reset_deposit_limit',
                    {date: localDateTimeFromISO(new_deposit_limit_effective_from)});
        }
        return null;
    }

    cleanError = (): void => {
        this.props.cleanError('deposit_limit.amount');
    };

    changeDepositLimit = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.setState({deposit_limit: e.target.value});
    };

    render(): ReactNode {
        const {rowClass, errorHint, new_deposit_limit, new_deposit_limit_period,
            new_deposit_limit_effective_from} = this.props;

        const newLimitMessage = this.assignDepositLimitMessage(new_deposit_limit,
            new_deposit_limit_period,
            new_deposit_limit_effective_from);

        return <div className={`${rowClass} deposit-limit-row`}>
            <label className="field-label" id="deposit-limit" htmlFor="field-deposit_limit">
                {I18n.t('deposit_limit')}, $</label>
            <div className="field deposit-limit-selects">
                <div className="field__select deposit-limit-input">
                    <input className="field__input" id="field-deposit_label" name="deposit_limit_amount" type="text"
                        value={this.state.deposit_limit} onKeyUp={this.cleanError}
                        onChange={this.changeDepositLimit}/>
                </div>
                <div className="field__select deposit-limit-period classical-select">
                    <select ref={this.limitPeriodSelect}
                        className="chosen-select field__select-chosen"
                        id="deposit_limit_period"
                        name="deposit_limit_period"
                        onChange={this.handleDepositLimitPeriod}
                        value={this.state.deposit_limit_period}>
                        {[
                            'daily',
                            'weekly',
                            'monthly'
                        ].map(v => <option value={v} key={v}>{limitPeriodLabels(v)}</option>)}
                    </select>
                    <div className="field__select-arrow"/>
                </div>
                <div className="field__select deposit-limit-reset">
                    <button className={`btn like-field-input ${this.props.deposit_limit ? '' : 'btn--disable'}`}
                        onClick={this.resetDepositLimit}>{I18n.t('user.reset')}
                    </button>
                </div>
                <div className="limit-warning">
                    {newLimitMessage}
                </div>
            </div>
            {errorHint}
        </div>;
    }
}
