import React from 'react';
import I18n from 'app/i18n';
import {SubmitButton} from 'app/utils';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {modalsStore} from 'app/store/global';

export default class ShopConfirmPopup extends React.Component {
    constructor(props) {
        super(props);
        this.confirmed = this.confirmed.bind(this);
        this.close = this.close.bind(this);
    }

    confirmed() {
        this.props.confirmed();
        this.close();
    }

    close() {
        modalsStore.hideModal(this.props.popupId);
    }

    render() {
        const {price, title} = this.props;

        return <PopupDialog id={this.props.popupId}>
            <div className="popup__content popup__content--small">
                <div className="purchases-money">
                    <p
                        className="purchases-money__text"
                        dangerouslySetInnerHTML={{
                            __html: I18n.t('shop.are_you_sure_to_buy_message',
                                {
                                    price,
                                    title: `<span class="mark">${title}</span>`
                                })
                        }}
                    />
                    <SubmitButton
                        className="purchases-money__submit-row"
                        title={I18n.t('confirm_button_yes')}
                        okClick={this.confirmed}
                        cancelClick={this.close}
                    />
                </div>
            </div>
        </PopupDialog>;
    }
}
