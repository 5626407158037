import React from 'react';
import {Link} from 'react-router-dom';
import {getLinkPath} from 'app/utils';

interface Props {
    FAQPath: string
}

export default class FAQButton extends React.PureComponent<Props> {
    onClick = (e: React.MouseEvent): void => {
        e.stopPropagation();
    };

    render(): React.ReactNode {
        return <div className="faq-button">
            <Link
                to={getLinkPath(this.props.FAQPath)}
                target="_blank"
                className="act faq-button__icon"
                onClick={this.onClick}
            >
                <svg height="20px" width="20px">
                    <use xlinkHref="#svg-question"/>
                </svg>
            </Link>
        </div>;
    }
}
