import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import {shopItemPrice, isUSD} from 'app/utils/ShopItemPriceFormat';
import {fixed2} from 'app/utils/fixed';
import {isGuest, slowConnectionPromise} from 'app/utils';
import {showPopup} from 'app/utils/modals/ModalsService';
import logEvent from 'app/amplitude/log_event';
import {confirmGoToDeposits} from 'app/utils/app_links';
import ShopConfirmPopup from 'app/components/shop/shop_confirm_popup';
import {SignUpButton} from 'app/components/auth/SignUpButton';
import Post from 'app/utils/Post';
import {toastError} from 'app/components/toasts/liteToast';

export default class BuyButton extends PureComponent {
    get isEnoughBalance() {
        const {user: {points}, cost_points, currency} = this.props;
        const points_price = Number(cost_points);

        if (!isUSD(currency) && points_price > 0) {
            return Number(points) - points_price >= 0;
        }
        return true;
    }

    get shopItemPriceProps() {
        const {user, cost_points, cost_money, currency} = this.props;
        return {
            cost_money: fixed2(cost_money - user.money),
            cost_points: Math.ceil(cost_points - Number(user.points)),
            currency
        };
    }

    handleBuyAction = () => {
        const {title, type_shop, lootboxOpeningProcess, with_confirmation} = this.props;

        if (lootboxOpeningProcess) {
            return;
        }

        if (type_shop === 'lootbox' && !with_confirmation) {
            this.buyAction();
            return;
        }

        showPopup(
            'shop-confirm-popup',
            props => <ShopConfirmPopup
                title={title}
                popupId={props.popupId}
                price={shopItemPrice(this.props)}
                confirmed={this.buyAction}
            />
        );
    };

    buyAction = () => {
        const {id, title, cost_money, cost_points, type_shop, afterBuy, currency} = this.props;

        slowConnectionPromise(
            new Post({
                params: {
                    currency,
                    id
                },
                url: '/shop_items'
            })
                .execute()
                .then(response => {
                    const unprocessableEntity = 422;

                    if (response.ok) {
                        logEvent(
                            'SHOP_ITEM_PURCHASED',
                            {
                                item: title,
                                price: Number(cost_money) || cost_points,
                                real_money: Boolean(Number(cost_money)),
                                type: type_shop
                            }
                        );
                        afterBuy && afterBuy();
                    } else if (response.status === unprocessableEntity) {
                        response.json().then(responseJSON => {
                            const {error} = responseJSON;

                            if (error === I18n.t('insufficient_funds_error')) {
                                confirmGoToDeposits();
                            } else {
                                toastError(error);
                            }
                        });
                    }
                })
        );
    };

    get customTextButton() {
        const {customText} = this.props;
        return customText ? customText : I18n.t('buy_shop_item');
    }

    render() {
        const {okLabel, lootboxOpeningProcess} = this.props;

        if (isGuest()) {
            return <SignUpButton title={I18n.t('buy_shop_item')} usingOnTab={'SHOP'} />;
        }
        return this.isEnoughBalance
            ? <button
                className={`button-adaptive
                ${lootboxOpeningProcess
                    ? 'button-adaptive_disable'
                    : 'btn-open'}`}
                onClick={this.handleBuyAction}>
                {okLabel ? I18n.t('ok') : this.customTextButton} {shopItemPrice(this.props)}
            </button>
            : <button className="button-adaptive button-adaptive_disable">
                {I18n.t('shop.you_need', {amount: shopItemPrice(this.shopItemPriceProps)})}
            </button>;
    }
}
