import React, {PureComponent, ReactNode} from 'react';
import {IncubatorShiftController} from 'app/controllers/IncubatorShiftController';
import {validateAmount} from 'app/utils/ValidateAmount';

interface IncubatorAmountInputProps {
    controller?: IncubatorShiftController
    onChange?: (pledge: string | number) => void
}

export default class IncubatorAmountInput extends PureComponent<IncubatorAmountInputProps> {
    state = {
        value: 0
    };

    handleShiftPledge = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const amount = validateAmount(e.target.value);
        this.setState({value: amount});
        if (this.props.controller) {
            this.props.controller.onChangeShiftPledge(amount.toString());
        } else {
            this.props.onChange(amount);
        }
    };

    render(): ReactNode {
        return <div className="field-symbol">
            <label className="symbol-label" htmlFor="amountIncubatorDeposit">$</label>
            <input
                className="field__input bold field-number__input"
                placeholder="0"
                value={this.state.value}
                id="amountIncubatorDeposit"
                name="amount[IncubatorDeposit]"
                onChange={this.handleShiftPledge}
            />
        </div>;
    }
}
