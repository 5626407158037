import Timer from 'app/components/reusable/Timer';
import GameInterface from 'app/interfaces/GameInterface';
import MultiLangsInterface from 'app/interfaces/MultiLangsInterface';
import CompendiumAnswerInterface from 'app/interfaces/CompendiumAnswerInterface';
import {ELEMENT_NOT_FOUND} from 'app/utils/Consts';
import $ from 'jquery';
import React from 'react';
import I18n from 'app/i18n';
import {isGuest, localePromoName, promoUrl, serverError} from 'app/utils';
import {modalConfirm} from 'app/utils/modals/popups';
import {showPopup} from 'app/utils/modals/ModalsService';
import Answer from './predictions/answer';
import CancelPrediction from 'app/components/compendium/CancelPrediction';
import NotEnoughPredictionPointsPopup from 'app/popups/NotEnoughPredictionPointsPopup';
import {openAuth} from 'app/components/auth/auth';
import {linkPredictionsTable} from 'app/utils/app_links';
import {promoActionStore} from 'app/store/global';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import {langsValue} from 'app/utils/langs/langsValue';

interface Props {
    answerWasGiven: boolean
    answers: CompendiumAnswerInterface[]
    correctAnswers: number[]
    correct_answers_count: number
    due_date: number
    game: GameInterface
    id: number
    langs: MultiLangsInterface
    question: string
    reward: boolean
    selected: boolean
    showAnswersHandler: (predictionId?: number) => void
    submitAnswer: (predictionId: number) => void
    user_answers: number[],
    day: number
}

interface States {
    answer_id?: number,
    disabled: boolean,
    multi_answers?: number[],
    showAnswers: boolean,
    submitting: boolean
}

export default class Prediction extends React.PureComponent<Props, States> {
    constructor(props: Props) {
        super(props);
        this.state = {
            answer_id: null,
            disabled: true,
            multi_answers: [],
            showAnswers: false,
            submitting: false
        };
        this.submitDisabled = this.submitDisabled.bind(this);
        this.selectAnswer = this.selectAnswer.bind(this);
        this.selectMultiAnswer = this.selectMultiAnswer.bind(this);
        this.submitAnswer = this.submitAnswer.bind(this);
        this.resetSubmitting = this.resetSubmitting.bind(this);
        this.showAnswersHandler = this.showAnswersHandler.bind(this);
    }

    componentDidMount(): void {
        const {user_answers, correct_answers_count} = this.props;

        if (user_answers.length <= 0) {
            return;
        }

        const newState: {multi_answers?: number[], answer_id?: number, disabled: boolean} = {disabled: true};

        if (correct_answers_count > 1) {
            newState.multi_answers = user_answers;
        } else {
            const [answer_id] = user_answers;
            newState.answer_id = answer_id;
        }

        this.setState(newState);
    }

    componentDidUpdate(prevProps: Props): void {
        if (this.props.selected !== prevProps.selected) {
            this.setState({showAnswers: this.props.selected});
        }
    }

    componentWillUnmount(): void {
        if (this.state.showAnswers) {
            this.props.showAnswersHandler();
        }
    }

    isSelectedAnswer(answer: CompendiumAnswerInterface): boolean {
        const {multi_answers, answer_id} = this.state;

        if (multi_answers.length > 0) {
            return multi_answers.indexOf(answer.id) !== ELEMENT_NOT_FOUND;
        }

        return answer_id && answer_id === answer.id;
    }

    selectAnswer(answer_id: number): void {
        this.setState({
            answer_id,
            disabled: false
        });
    }

    selectMultiAnswer(answer: {checked: boolean, id: number}): void {
        let res = this.state.multi_answers.slice();
        if (answer.checked) {
            res.push(answer.id);
        } else {
            res = res.filter(a => a !== answer.id);
        }
        this.setState({
            disabled: res.length !== this.props.correct_answers_count,
            multi_answers: res
        });
    }

    resetSubmitting(): void {
        this.setState({submitting: false});
    }

    submitAnswer(): void {
        if (isGuest()) {
            return openAuth(linkPredictionsTable(promoActionStore.promoAction, false));
        }

        this.setState({submitting: true});

        modalConfirm(I18n.t('compendium.are_you_sure_prediction'), () => $.ajax(
            promoUrl('answer'), {
                data: {
                    answer_id: this.props.correct_answers_count === 1 ? this.state.answer_id : this.state.multi_answers,
                    prediction_id: this.props.id
                },
                error: () => serverError(this.resetSubmitting),
                method: 'POST',
                success: data => {
                    this.resetSubmitting();
                    if (data.success) {
                        this.props.submitAnswer(this.props.id);
                        toastSuccess(I18n.t('compendium.successful_prediction'));
                        if (this.state.showAnswers) {
                            this.showAnswersHandler();
                        }
                    } else {
                        if (data.error === I18n.t(`${localePromoName()}.not_enough_prediction_points`)) {
                            return showPopup(
                                'not-enough-predictions-points-popup',
                                (props: {popupId: string}) => <NotEnoughPredictionPointsPopup popupId={props.popupId}/>
                            );
                        }

                        toastError(data.error);
                    }
                    return null;
                }
            }
        ), this.resetSubmitting);
        return null;
    }

    submitDisabled(): boolean {
        return this.state.submitting || this.state.disabled;
    }

    gridClass(): string {
        const classes = ['compendium-grid__item'];

        if (this.state.showAnswers) {
            classes.push('compendium-grid__item--active');
        }
        return classes.join(' ');
    }

    showAnswersHandler = (): void => {
        this.props.showAnswersHandler(this.props.id);
    };

    handleShowAnswersHandler = (e: React.MouseEvent<HTMLAnchorElement>): void => {
        e.preventDefault();
        this.showAnswersHandler();
    };

    doesHaveResult(): boolean {
        return this.props.correctAnswers.length > 0;
    }

    makePredictionButtonText(): string {
        return I18n.t(`user.promo_action.${localePromoName()}.${this.props.answerWasGiven
            ? 'prediction_has_been_made'
            : 'make_prediction'}`);
    }

    cancelAnswer = (): void => {
        new CancelPrediction(this.props.id, () => this.setState({
            answer_id: null,
            multi_answers: []
        })).cancelAnswer();
    };

    render(): React.ReactNode {
        const {answerWasGiven, user_answers, game, due_date, day} = this.props;

        return <div className={this.gridClass()}>
            <div className="compendium-grid__item-inner">
                <div className="compendium-grid__item-header">
                    <svg height="21px" width="21px">
                        <use xlinkHref="#svg-cup-table"/>
                    </svg>
                    <span>{this.props.reward}RP</span>
                </div>
                <div className="compendium-grid__item-timer">
                    {game.filter_image && game.filter_image.url && <span className="compendium-grid__item-timer__logo">
                        <img
                            src={game.filter_image.url}
                            alt={game.name}
                            title={game.name} />
                    </span>}
                    <span className="compendium-grid__item-timer__time">
                        <Timer endTime={due_date}/>
                    </span>
                </div>
                <div className="compendium-grid__item-question">
                    {langsValue(this.props, 'question')} ({I18n.t('day')} {day})
                </div>
                <a
                    href="#"
                    onClick={this.handleShowAnswersHandler}
                    className="compendium-grid__item-link"
                >
                    <div>
                        {this.makePredictionButtonText()}
                    </div>
                </a>
                <div
                    className="compendium-grid__item-link"
                    style={{cursor: 'default'}}
                >
                    {answerWasGiven &&
                    <div className="btn-wrap">
                        <button className="btn" onClick={this.cancelAnswer}>{I18n.t('button_cancel')}</button>
                    </div>}
                </div>
                {this.state.showAnswers && <div
                    className={`compendium-grid__addon compendium-grid__addon--shown${this.props.answerWasGiven
                        ? ' compendium-grid__addon--answered'
                        : ''} `}
                >
                    <div className="compendium-grid__addon-options">
                        {this.props.answers.map(answer => <Answer
                            key={answer.id}
                            prediction_id={this.props.id}
                            correct_answers_count={this.props.correct_answers_count}
                            selectAnswer={this.selectAnswer}
                            isSelectedAnswer={this.isSelectedAnswer(answer)}
                            selectMultiAnswer={this.selectMultiAnswer}
                            answerWasGiven={answerWasGiven}
                            user_answers={user_answers}
                            {...answer}
                        />)}
                    </div>
                    {!answerWasGiven && <div className="compendium-grid__addon-action">
                        <button
                            className={`btn ${this.submitDisabled() ? 'btn--disable' : ''}`}
                            disabled={this.submitDisabled()}
                            onClick={this.submitAnswer}
                        >
                            {I18n.t(`user.promo_action.${localePromoName()}.make_prediction`)}
                        </button>
                    </div>}
                </div>}
            </div>
        </div>;
    }
}
