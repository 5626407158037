import React, {Component, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';

import {isDesktop} from 'app/utils/windowFunctions';
import BetSlipLatestBetContainer from 'app/components/bet_slip/latest_bets/BetSlipLatestBet.container';
import BetSlipLimitButtonContainer from 'app/components/bet_slip/latest_bets/BetSlipLimitButton.container';
import BetSlipLimitIncubatorButtonContainer
    from 'app/components/bet_slip/latest_bets/BetSlipLimitIncubatorButton.container';
import {BetSlipStore} from 'app/store/BetSlipStore';
import BetSlipController from 'app/components/bet_slip/BetSlipController';
import {fixed2} from 'app/utils/fixed';
import BetSlipLatestBet from 'app/components/bet_slip/latest_bets/BetSlipLatestBet';
import {DEFAULT_BETS} from 'app/utils/Consts';

interface BetSlipLatestBetsProps {
    betSlipStore?: BetSlipStore
    controller?: BetSlipController
}

@inject('betSlipStore', 'controller')
@observer
export default class BetSlipLatestBets extends Component<BetSlipLatestBetsProps> {
    handleChangeAmount = (): void => {
        const {controller: {changeInput, max}} = this.props;
        changeInput(fixed2(max));
    };

    render(): ReactNode {
        const {betSlipStore: {amount, netPrize, amountFocus, coefFocus}, controller: {maxWin}} = this.props;

        return <div className="bet-slip-latest-bets">
            {isDesktop() && <div className="list-buttons-latest-bets">
                {DEFAULT_BETS.map((value, ind) => <BetSlipLatestBetContainer
                    key={ind}
                    disabled={coefFocus}
                    bet={(Number(amountFocus ? amount : netPrize) + Number(value)).toString()}
                    title={`+${value}`}
                />)}
                <BetSlipLatestBet
                    title="MAX"
                    disabled={coefFocus}
                    onClick={this.handleChangeAmount}
                />
            </div>}
            <div className="bet-slip-limit-bets">
                <BetSlipLimitButtonContainer/>
                {typeof maxWin() === 'number' && <BetSlipLimitIncubatorButtonContainer/>}
            </div>
        </div>;
    }
}
