import ThailandCashoutDetails from 'app/components/cashout/payzen/ThailandCashoutDetails';

export default class VietnamCashoutDetails extends ThailandCashoutDetails {
    state = {
        bank_account_number: '',
        bank_id: '',
        customer_name: ''
    };

    get bankList() {
        const {china_banks = []} = this.props;
        return china_banks.filter(b => b.country_code === 'vietnam' &&
            b.method === 'payout');
    }
}
