import React, {MouseEvent, PureComponent, ReactNode} from 'react';
import {amountCurrency} from 'app/utils/amountCurrency';
import {inject, observer} from 'mobx-react';
import I18n from 'app/i18n';
import {BetSlipStore} from 'app/store/BetSlipStore';

interface BetSlipLimitButtonProps {
    amount: string
    onClick?: (e: MouseEvent<HTMLSpanElement>) => void
    incubator?: boolean
    subClass?: string
    title: string
    type: string
    betSlipStore?: BetSlipStore
}

@inject('betSlipStore')
@observer
export default class BetSlipLimitButton extends PureComponent<BetSlipLimitButtonProps> {
    betSlipStore: BetSlipStore;

    isLoading: boolean;

    tmpLimit: string | null;

    constructor(props: BetSlipLimitButtonProps) {
        super(props);
        this.betSlipStore = props.betSlipStore!;
        this.tmpLimit = null;
    }

    componentDidUpdate(prevProps: BetSlipLimitButtonProps): void {
        if (this.betSlipStore.loading) {
            this.tmpLimit = prevProps.amount;
        }

        if (this.betSlipStore.loading === false) {
            this.tmpLimit = null;
        }
    }

    render(): ReactNode {
        const {amount, onClick, subClass, title, type, incubator} = this.props;
        const className = `limit-value ${subClass ? subClass : ''}`;
        const text = amountCurrency(this.betSlipStore.loading && this.tmpLimit ? this.tmpLimit : amount, type);

        return (
            <div className="cell-box">
                <span className="sub-title">{title}</span>
                {onClick && !incubator
                    ? <button className={className} onClick={onClick}>{text}</button>
                    : <span className={className}>{incubator ? I18n.t('unlimited') : text}</span>
                }
            </div>
        );
    }
}
