import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import {getLinkPath} from 'app/utils/link_helper';

export default class QuestionMark extends PureComponent {
    render() {
        return <div className="field__icon faq-button">
            <Link
                to={getLinkPath('/faq/my-hero-and-skins')}
                className="act faq-button__icon"
            >
                <svg height="20px" width="20px">
                    <use xlinkHref="#svg-question"/>
                </svg>
            </Link>
        </div>;
    }
}