import React from 'react';
import {NavLink} from 'react-router-dom';
import I18n from 'app/i18n';
import {getLinkPath} from 'app/utils';
import {addGaEvent} from 'app/ga_methods';
import {openRegistration, openAuth} from '../auth/auth';

class LoginSignUpButton extends React.PureComponent {
    constructor(props) {
        super(props);
        this.showPopup = this.showPopup.bind(this);
    }

    showPopup() {
        throw new Error('showPopup function is not overrode');
    }

    textOfLink() {
        throw new Error('textOfLink function is not overrode');
    }

    render() {
        return <NavLink to={getLinkPath('/play/bets#auth')} className={`btn ${this.props.className}`}
            onClick={this.showPopup}>{this.textOfLink()}</NavLink>;
    }
}

class LoginButton extends LoginSignUpButton {
    static opener() {
        return openAuth;
    }

    showPopup(e) {
        LoginButton.opener()(this.props.redirectUrl);
        e && e.preventDefault();
    }

    textOfLink() {
        return I18n.t('user.login');
    }
}

class SignUpButton extends LoginSignUpButton {
    static opener() {
        return openRegistration;
    }

    showPopup(e) {
        SignUpButton.opener()(
            this.props.usingOn,
            this.props.redirectUrl
        );
        addGaEvent('Sign up', this.props.eventType || 'open');
        e && e.preventDefault();
    }

    textOfLink() {
        return I18n.t(this.props.seo ? 'user.auth_button_e_sport' : 'register');
    }
}

class LoginSignUpButtons extends React.PureComponent {
    sanitizeProps() {
        const props = {...this.props};
        delete props.className;
        return props;
    }

    render() {
        return <div className={this.props.className}>
            <LoginButton className="auth-link auth-link-login" {...this.sanitizeProps()}/>
            <SignUpButton className="auth-link auth-link-reg" {...this.sanitizeProps()}/>
        </div>;
    }
}

export {LoginSignUpButton, LoginButton, SignUpButton, LoginSignUpButtons};
