import React, {PureComponent, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import I18n from 'app/i18n';
import {isRealMoney} from 'app/lines/isRealMoney';
import {BetSlipStore} from 'app/store/BetSlipStore';
import BetSlipController from 'app/components/bet_slip/BetSlipController';

interface BetSlipLineDescriptionProps {
    betSlipStore?: BetSlipStore
    controller?: BetSlipController
}

@inject('betSlipStore', 'controller')
@observer
export default class BetSlipLineDescription extends PureComponent<BetSlipLineDescriptionProps> {
    suggestOdds = (): void => {
        const {setIncubator2WithCoef, setShowSuggestCoef, setIncubator2Form} = this.props.betSlipStore;
        setIncubator2WithCoef(null);
        setShowSuggestCoef(false);
        setIncubator2Form(true);
    };

    render(): ReactNode {
        const {betSlipStore: {incubator2WithCoef, type}, controller: {userStore: {user}}} = this.props;

        return incubator2WithCoef && isRealMoney(type) && user.enable_incubator2 && <div className="bet-slip-line-description middle-icon">
            <svg className="svg-default">
                <use xlinkHref="#svg-round-warning"/>
            </svg>
            <div>
                <p>
                    {`${I18n.t('bet_created_user')} `}
                    <a onClick={this.suggestOdds}>
                        {I18n.t('suggest_your_odds').toLowerCase()}
                    </a>
                </p>
            </div>
        </div>;
    }
}
