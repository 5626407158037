import React, {PureComponent, ReactNode} from 'react';
import InventorySlot from 'app/components/my_profile/hero/inventory_slot';
import InventoryItemInterface from 'app/interfaces/InventoryItemInterface';

interface ArtifactToBetSlotProps {
    artifact: InventoryItemInterface
    onClick?: (artifact: InventoryItemInterface) => void
}

export default class ArtifactSlot extends PureComponent<ArtifactToBetSlotProps> {
    onClick = (): void => {
        const {artifact, onClick} = this.props;

        if (onClick) {
            onClick(artifact);
        }
    };

    render(): ReactNode {
        const {artifact} = this.props;
        return <InventorySlot artifact={artifact} key={artifact.id} onClick={this.onClick}/>;
    }
}
