import P2PCashoutDetails from 'app/components/cashout/p2p_cashout_details';
import React, {PureComponent} from 'react';
import PayzenCashoutDetails from 'app/components/cashout/PayzenCashoutDetails';
import MalaysiaCashoutDetails from 'app/components/cashout/payzen/MalaysiaCashoutDetails';
import SouthKoreaCashoutDetails from 'app/components/cashout/payzen/SouthKoreaCashoutDetails';
import IndiaImpsDetails from 'app/components/cashout/payzen/IndiaImpsDetails';
import IndiaPayTmDetails from 'app/components/cashout/payzen/IndiaPayTmDetails';
import ThailandCashoutDetails from 'app/components/cashout/payzen/ThailandCashoutDetails';
import VietnamCashoutDetails from 'app/components/cashout/payzen/VietnamCashoutDetails';
import IndonesiaCashoutDetails from 'app/components/cashout/monetix/IndonesiaCashoutDetails';
import CoinspaidCashoutDetails from 'app/components/cashout/CoinspaidCashoutDetails';

const IMPLEMENTED_DETAILS = {
    CoinspaidCashoutDetails,
    IndiaImpsDetails,
    IndiaPayTmDetails,
    IndonesiaCashoutDetails,
    MalaysiaCashoutDetails,
    P2PCashoutDetails,
    PayzenCashoutDetails,
    SouthKoreaCashoutDetails,
    ThailandCashoutDetails,
    VietnamCashoutDetails
};

export default class CashoutDetails extends PureComponent {
    render() {
        const {system: {cashout_details_ui_component}} = this.props;

        if (cashout_details_ui_component) {
            const Res = IMPLEMENTED_DETAILS[cashout_details_ui_component];
            return <Res {...this.props}/>;
        }
        return null;
    }
}
