import LineInterface from 'app/interfaces/LineInterface';
import {lineGamer} from 'app/lines/lineGamer';
import {isAdvantageLine} from 'app/lines/isAdvantageLine';
import i18n from 'app/i18n';
import {isHandicapLine} from './isHandicapLine';

export const tableNick = (line: LineInterface, gamerNum: number): string => {
    if (isAdvantageLine(line)) {
        return line[`gamer_short_text_${gamerNum}`];
    }
    if (line.type_map || isHandicapLine(line)) {
        const name = lineGamer(line, gamerNum).nick;
        const mapRegex = /Map|Карта/u;
        const underRegex = /Under|Меньше/u;
        const overRegex = /Over|Больше/u;

        switch (true) {
        case mapRegex.test(name):
            return name.replace(mapRegex, i18n.t('map'));
        case overRegex.test(name):
            return name.replace(overRegex, i18n.t('over'));
        case underRegex.test(name):
            return name.replace(underRegex, i18n.t('under'));
        default:
            break;
        }
    }

    return lineGamer(line, gamerNum).nick;
};
