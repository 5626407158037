import React, {Fragment, PureComponent} from 'react';
import I18n from 'app/i18n';
import SentTradeOffer from 'app/components/pages/trade_offer/sent_trade_offer';
import EmptyOffers from './empty_offers';
import {getLinkPath} from 'app/utils';
import {modalConfirm} from 'app/utils/modals/popups';
import {NavLink, Link} from 'react-router-dom';
import ShowMore from 'app/utils/ShowMore';
import SmartLoader from 'app/components/reusable/SmartLoader';
import Get from 'app/utils/Get';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import Post from 'app/utils/Post';
import {getClassNameActiveLink} from 'app/utils/link_helper';

class TradeCount extends PureComponent {
    render() {
        const {count} = this.props;
        return <Fragment>{count > 0 && `(${count})`}</Fragment>;
    }
}

class MyTrades extends PureComponent {
    state = {trades: [], loading: true, page: 1, hasMore: false};

    acceptTrade = id => {
        modalConfirm(I18n.t('accept_trade_confirm'), () => {
            new Post({
                params: {
                    type: this.props.type
                },
                url: `/trades/${id}/accept`
            })
                .execute()
                .then(response => {
                    if (response.ok) {
                        response.json().then(response => {
                            this.loadTrades();
                            toastSuccess(response.message);
                        });
                    } else {
                        response.json().then(response => {
                            toastError(response.message);
                        });
                    }
                });
        });
    };

    cancelTrade = id => {
        modalConfirm(I18n.t('cancel_trade_confirm'), () => {
            new Post({
                params: {
                    type: this.props.type
                },
                url: `/trades/${id}/cancel`
            })
                .execute()
                .then(response => {
                    if (response.ok) {
                        response.json().then(response => {
                            this.loadTrades();
                            toastSuccess(response.message);
                        });
                    } else {
                        response.json().then(response => {
                            toastError(response.message);
                        });
                    }
                });
        });
    };

    componentDidMount() {
        this.loadTrades();
    }

    loadTrades = () => {
        new Get({
            params: {
                type: this.props.type,
                page: this.state.page
            },
            url: '/trades/my_trades'
        }).execute()
            .then(response => {
                if (response.ok) {
                    response.json().then(response => {
                        this.setState({
                            loading: false,
                            trades: response.data,
                            hasMore: response.has_more,
                            page: this.state.page + 1,
                            total_outgoing: response.total_outgoing,
                            total_incoming: response.total_incoming
                        });
                    });
                } else {
                    response.json().then(response => toastError(response.message));
                }
            });
    };

    render() {
        const {loading} = this.state;
        return <div className="exchange">
            <div className="title">{I18n.t('user.my_trades')}</div>
            <div className="tabs">
                <div className="tabs__header trade">
                    <NavLink
                        to={getLinkPath('/my_profile/trades/outgoing')}
                        className={getClassNameActiveLink}>
                        {I18n.t('user.sent')} <TradeCount count={this.state.total_outgoing}/>
                    </NavLink>
                    <NavLink
                        to={getLinkPath('/my_profile/trades/incoming')}
                        className={getClassNameActiveLink}>
                        {I18n.t('user.received')} <TradeCount count={this.state.total_incoming}/>
                    </NavLink>
                    <NavLink
                        to={getLinkPath('/my_profile/trades/history')}
                        className={getClassNameActiveLink}>
                        {I18n.t('user.history_header')}
                    </NavLink>
                </div>
                <div className="tabs__content">
                    <div className="tabs__content-item">
                        {loading && <SmartLoader/>}

                        {!this.state.loading && this.state.trades.length === 0 && <EmptyOffers
                            subtitle={this.props.empty_subtitle}
                            description={I18n.t('empty_trade_description')}
                        />}

                        {this.state.trades.map(trade => <SentTradeOffer
                            key={trade.id}
                            trade={trade}
                            type={this.props.type}
                            onCancelClick={this.cancelTrade}
                            onAcceptClick={this.acceptTrade}
                        />)}
                    </div>
                    <ShowMore onClickHandler={this.loadTrades} has_more={this.state.hasMore}/>
                </div>
            </div>
        </div>;
    }
}

const MySentTrades = props => <MyTrades {...props} type="sent" empty_subtitle={I18n.t('empty_sent_offers')}/>;
const MyReceivedTrades = props => <MyTrades {...props} type="received" empty_subtitle={I18n.t('empty_received_offers')}/>;
const MyHistoryTrades = props => <MyTrades {...props} type="history" empty_subtitle={I18n.t('empty_history_offers')}/>;

export {MySentTrades, MyReceivedTrades, MyHistoryTrades};
