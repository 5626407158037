import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import TradeItem from './trade_item';
import {shortLocalDateTimeFromISO} from 'app/utils';
import HistoryMessage from './history_message';

export default class SentTradeOffer extends PureComponent {
    onCancelClick = () => {
        this.props.onCancelClick(this.props.trade.id);
    };

    onAcceptClick = () => {
        this.props.onAcceptClick(this.props.trade.id);
    };

    render() {
        const {trade} = this.props;
        const endDay = shortLocalDateTimeFromISO(trade.end_time);

        let askSubtitle = '',
            partnerSubtitle = '',
            yourSubtitle = '';

        if (trade.ask_user_name === window.settings.user.name) {
            askSubtitle = I18n.t('your_trade_user_offer', {name: trade.partner_user_name});
            yourSubtitle = I18n.t('your_items');
            partnerSubtitle = I18n.t('partner_items', {name: trade.partner_user_name});
        } else {
            askSubtitle = I18n.t('user_trade_you_offer', {name: trade.ask_user_name});
            yourSubtitle = I18n.t('partner_items', {name: trade.ask_user_name});
            partnerSubtitle = I18n.t('your_items');
        }

        return <div className={`offer offer--${this.props.type}`}>
            <div className="subtitle">{askSubtitle}</div>
            <div className="offer-content">
                <div className="offer-items">
                    <div className="offer-label">{yourSubtitle}</div>
                    <div className="offer-items--wrap">
                        {trade.ask_items.map((item, i) => <TradeItem key={i} artifact={item}/>)}
                    </div>
                </div>
                {this.props.type === 'history'
                    ? <HistoryMessage status={trade.status} endDay={endDay}/>
                    : <div className="offer-separator">
                        <div className="line"/>
                        <svg height="40px" width="42px"><use xlinkHref="#svg-exchange"/></svg>
                        <div className="line"/>
                    </div>}
                <div className="offer-items offer-items--desired">
                    <div className="offer-label">{partnerSubtitle}
                    </div>
                    <div className="offer-items--wrap">
                        {trade.partner_items.map((item, i) => <TradeItem key={i} artifact={item}/>)}
                    </div>
                </div>
            </div>
            {this.props.type !== 'history' && <div className={`offer-footer offer-footer--${this.props.type}`}>
                {this.props.type === 'received' && <div className="offer-actions">
                    <button className="btn--text" onClick={this.onCancelClick}>{I18n.t('cancel_trade_offer')}</button>
                    <button className="btn btn--invert btn--big-on-mobile" onClick={this.onAcceptClick}>
                        {I18n.t('accept_trade')}
                    </button>
                </div>}
                <div className="offer-expires">{I18n.t('offer_ends', {date: endDay})}</div>
                {this.props.type === 'sent' && <div className="offer-actions">
                    <button className="btn--text" onClick={this.onCancelClick}>{I18n.t('cancel_trade_offer')}</button>
                </div>}
            </div>}
        </div>;
    }
}
