import React, {Component} from 'react';
import I18n from 'app/i18n';
import LotsForSaleTable from './lots_for_sale_table';
import {inject, observer} from 'mobx-react';
import {modalConfirm} from 'app/utils/modals/popups';
import {artifactName} from 'app/components/my_profile/artifacts/utils';
import {buyOrderStore} from 'app/store/global';
import SmartLoader from 'app/components/reusable/SmartLoader';

export default inject('buyOrderStore')(observer(class BuyOrders extends Component {
    handleLoadMore = () => this.props.buyOrderStore.loadMore();

    componentDidMount = () => {
        this.handleLoadMore()
            .promise(() => this.props.buyOrderStore.setLoadingTo(true));
    };

    componentWillUnmount = () => this.props.buyOrderStore.reset();

    cancelOrder = (artifact, id) => {
        modalConfirm(I18n.t(
            'are_you_sure_you_want_to_cancel_buy_request',
            {artifact: artifactName(artifact)}
        ), () => buyOrderStore.delete(id));
    };

    render() {
        const {loading, count, asJson} = this.props.buyOrderStore;
        return <div className="market__buy-requests">
            <div className="market__buy-requests-count">
                {I18n.t('my_buy_requests')}
                <span>({count})</span>
            </div>

            {loading
                ? <div className="table-more">
                    <SmartLoader/>
                </div>
                : <LotsForSaleTable
                    {...asJson}
                    hideHeader
                    handleLoadMore={this.handleLoadMore}
                    cancelOrder={this.cancelOrder}
                    noLotsLabel={I18n.t('there_are_no_buy_requests_yet')}
                />}
        </div>;
    }
}));
