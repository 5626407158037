import React from 'react';
import {getLinkPath} from 'app/utils/link_helper';
import {Link} from 'react-router-dom';
import {scrollToTop} from 'app/utils';

interface SitemapLinkInterface {
    link: string,
    title: string
}

const SitemapLink = ({link, title}: SitemapLinkInterface): JSX.Element => <Link
    to={getLinkPath(link)}
    className="sitemap__link"
    onClick={scrollToTop}
>
    {title}
</Link>;

export default SitemapLink;
