import React, {PureComponent} from 'react';
import {ArtifactImage} from 'app/components/my_profile/artifacts/image';
import {Provider} from 'mobx-react';
import {shopLootboxStore} from 'app/store/global';

export default class PopupImage extends PureComponent {
    render() {
        return <Provider shopLootboxStore={shopLootboxStore}>
            <ArtifactImage artifact={this.props} showHeroSlotCount/>
        </Provider>;
    }
}
