import Get, {ParamsInterface} from 'app/utils/Get';
import Post from 'app/utils/Post';
import Delete from 'app/utils/Delete';
import Patch from 'app/utils/Patch';

export class RestApiService {
    constructor(url: string) {
        this.url = url;
    }

    url: string;

    index(params?: ParamsInterface): Promise<Response> {
        return new Get({
            params,
            url: this.url
        }).execute();
    }

    create(params: ParamsInterface): Promise<Response> {
        return new Post({
            params,
            url: this.url
        }).execute();
    }

    show(id: string): Promise<Response> {
        return new Get({
            url: this.withIdUrl(id)
        }).execute();
    }

    update(id: string, params: ParamsInterface): Promise<Response> {
        return new Patch({
            params,
            url: this.withIdUrl(id)
        }).execute();
    }

    delete(id: string): Promise<Response> {
        return new Delete({
            url: this.withIdUrl(id)
        }).execute();
    }

    postAction(action: string, params?: ParamsInterface): Promise<Response> {
        return new Post({
            params,
            url: `${this.url}/${action}`
        }).execute();
    }

    private withIdUrl(id: string | number) {
        return `${this.url}/${id}`;
    }
}
