import React, {useEffect} from 'react';
import {tableStore} from 'app/store/global';
import {inject, observer} from 'mobx-react';
import {NavLink} from 'react-router-dom';
import I18n from 'app/i18n';
import {getLinkPath, isDesktop} from 'app/utils';
import {countedTitle} from 'app/utils/countedTitle';
import {scrollToBottomStream} from '../streams/stream_functions';
import {IncubatorStore} from 'app/store/IncubatorStore';
import {TableStore} from 'app/store/TableStore';
import {isBetPage} from 'app/utils/IsBetPage';
import {getClassNameActiveLink, getTableLinkPath} from 'app/utils/link_helper';
import {useOutlet, useParams} from 'react-router';

interface PlayPageWrapperInterface {
    params?: {
        filter?: string
    },
    tableStore?: TableStore,
    incubatorStore?: IncubatorStore
    children?: React.ReactNode
}

const PlayPageWrapper = (props: PlayPageWrapperInterface): React.JSX.Element => {
    const outlet = useOutlet();
    const {filter} = useParams();

    useEffect(() => {
        if (filter) {
            const [game] = window.SITE_DATA.INDEX_GAMES.filter(g => g.slug === filter);
            tableStore.clearFilters();
            tableStore.applyTableFilter(game && game.filter_id,
                null,
                {forceUseHidden: true, results: tableStore.results});
        }
        scrollToBottomStream();
        updateSportsInStores();
    }, [filter, props]);

    const updateSportsInStores = () => {
        const {incubatorStore} = props;

        tableStore.updateFiltersPage();
        incubatorStore.updateFiltersPage();
    };

    const {incubatorCount} = props.tableStore;
    const isShowTabsDesktopMobile = isDesktop() || !isDesktop() && isBetPage(true);

    return <div className="tabs js-tabs tabs--top">
        {isShowTabsDesktopMobile && <div className="tabs__header__wrapper">
            <div className="tabs__header">
                <div className="tabs__inner__header tabs-play">
                    <NavLink
                        to={getTableLinkPath('/play/bets')}
                        className={getClassNameActiveLink}>
                        {I18n.t('user_menu_table')}
                    </NavLink>
                    <NavLink
                        to={getTableLinkPath('/play/incubator/all')}
                        className={getClassNameActiveLink}>
                        {countedTitle(I18n.t('incubator'), incubatorCount)}
                    </NavLink>
                    {<NavLink
                        to={getTableLinkPath('/play/results')}
                        className={getClassNameActiveLink}>
                        {I18n.t('results')}
                    </NavLink>}
                    {isDesktop() && <NavLink
                        className="button-my-bets"
                        to={getLinkPath('/my_bets')}
                        title={I18n.t('user.rates')}
                    >
                        <svg className="svg-default">
                            <use xlinkHref="#svg-bets"/>
                        </svg>
                    </NavLink>}
                </div>
            </div>
            {!isDesktop() && <NavLink
                className="button-my-bets"
                to={getLinkPath('/my_bets')}
                title={I18n.t('user.rates')}
            >
                <svg className="svg-default">
                    <use xlinkHref="#svg-bets"/>
                </svg>
            </NavLink>}
        </div>}
        {outlet}
    </div>;
};
export default inject('tableStore', 'incubatorStore')(observer(PlayPageWrapper));
