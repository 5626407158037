import LineInterface from 'app/interfaces/LineInterface';

export const linesSortIncubator = (lines: LineInterface[]): LineInterface[] => lines.sort((a, b) => {
    if (b.color === a.color) {
        if (b.date === a.date) {
            return b.id - a.id;
        }
        return b.date - a.date;
    }
    return a.color - b.color;
});
