import {deleteObjectById, getObjectById, ObjectWithId, updateOrAddObject} from 'app/utils/updateArraysObject';
import {action, makeObservable, override} from 'mobx';
import BaseStore from 'stores/BaseStore';

export default abstract class CollectionStore<T> extends BaseStore<T> {
    constructor() {
        super();
        makeObservable(this);
        this.setCollection([]);
    }

    @override collection: T[] = [];

    @action
    updateElementCollection(item: ObjectWithId): void {
        updateOrAddObject(this, 'collection', item);
    }

    @action
    deleteElementCollection(item: ObjectWithId): void {
        deleteObjectById(this, 'collection', item.id);
    }

    elementById(id: number): T {
        return getObjectById(this.collection, id);
    }
}
