import $ from 'jquery';
import React from 'react';
import Cookies from 'js-cookie';

const EXPIRES_DATE = 1825;

export default class CookiesPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.acceptCookiesPolicy = this.acceptCookiesPolicy.bind(this);
        this.cookiesPanelRef = React.createRef();
    }

    componentDidMount() {
        this.autoHideTimer = setTimeout(() => {
            this.hideCookiesPanel();
        }, 3000);
    }

    componentWillUnmount() {
        clearTimeout(this.autoHideTimer);
    }

    hideCookiesPanel() {
        $(this.cookiesPanelRef.current).fadeOut();
    }

    acceptCookiesPolicy() {
        Cookies.set('cookies_policy', '1', { expires: EXPIRES_DATE });
        clearTimeout(this.autoHideTimer);
        this.hideCookiesPanel();
    }

    render() {
        return Cookies.get('cookies_policy')
            ? null
            : <div className="cookies-panel js-close-block" ref={this.cookiesPanelRef}>
                <div className="cookies-panel__wrap">
                    <div className="cookies-panel__text">
                        We use cookies on this site to enhance your user experience.
                        By clicking any link on this page you are giving your consent for us to set cookies.
                    </div>
                    <div
                        className="cookies-panel__close js-close-block-btn"
                        onClick={this.acceptCookiesPolicy}
                    >
                        Got It!
                    </div>
                </div>
            </div>;
    }
}