import React, {Component, ReactNode} from 'react';
import BetSlipMatchGame from 'app/components/bet_slip/matches_games/BetSlipMatchGame';
import {inject, observer} from 'mobx-react';
import {BetSlipStore} from 'app/store/BetSlipStore';
import BetSlipController from 'app/components/bet_slip/BetSlipController';

interface ListBetSlipMatchesGamesProps {
    betSlipStore?: BetSlipStore
    controller?: BetSlipController
}

@inject('betSlipStore', 'controller')
@observer
export default class ListBetSlipMatchesGames extends Component<ListBetSlipMatchesGamesProps> {
    render(): ReactNode {
        const {controller: {bets}, betSlipStore: {showCoefInList}} = this.props;
        return <div className="list-bet-slip-matches-games">
            {bets.map(item => <BetSlipMatchGame
                bet={item}
                key={`bet-${item.selectedLine.id}-${item.num}`}
                showCoefInList={showCoefInList}/>)}
        </div>;
    }
}
