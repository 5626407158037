import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {goToRoute} from 'app/utils';

interface Props {
    route: string
}

export default class TableHistoryStubPage extends PureComponent<Props> {
    onClick = (): void => {
        goToRoute(this.props.route);
    };

    render(): ReactNode {
        return <div className="wrapper-incubator-waiting-line table-history-page">
            <div className="wrapper-incubator-waiting-text">
                <p>{I18n.t('my_bets_stub_label')}</p>
                <button
                    className="btn btn--middle"
                    onClick={this.onClick}
                >{I18n.t('play')}</button>
            </div>
        </div>;
    }
}
