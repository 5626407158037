import GameInterface from '../interfaces/GameInterface';


export const prepareFiltersQuery = function(arr: GameInterface[]): string {
    if (!arr) {
        return '';
    }

    return encodeURI(arr.filter(f => f.val === true).map(x => x.slug).join());
};
