import {toastError, toastSuccess} from './liteToast';
import I18n from 'app/i18n';
import ErrorsServerInterface from 'app/interfaces/ErrorsServerInterface';

export const promiseToast = (response: Promise<Response>, successMessage: string): Promise<Response> => response
    .then(promiseResponse => {
        showToast(promiseResponse, successMessage);
        return promiseResponse;
    });

const showToast = (response: Response, text?: string): void => {
    if (response.ok) {
        toastSuccess(text ?? I18n.t('success'));
        return;
    }
    response.json().then(errors => {
        if (errors?.base) {
            toastError(errors?.base?.[0]);
        } else {
            toastError(I18n.t('server_error_try_later'));
        }
    });
};

export const showErrorToast = (errors: ErrorsServerInterface): void => {
    if (errors?.base) {
        toastError(errors?.base?.[0]);
    } else {
        toastError(I18n.t('server_error_try_later'));
    }
};
