import React, {Component, ReactNode, ChangeEvent} from 'react';
import {inject, observer} from 'mobx-react';
import I18n from 'app/i18n';
import FaqListQuestions from 'app/components/pages/faq/FaqListQuestions';
import {FaqStore, FaqInterface} from 'app/store/FaqStore';
import FaqSearch from 'app/components/pages/faq/FaqSearch';
import SmartFullPageLoader from 'app/components/reusable/smart_loaders/SmartFullPageLoader';

interface FaqIndexProps {
    faqStore?: FaqStore
}

@inject('faqStore')
@observer
export default class FaqIndex extends Component<FaqIndexProps> {
    state = {
        scroll: true,
        search: ''
    };

    componentDidMount(): void {
        this.props.faqStore.loadData();
    }

    filteredFAQ(): FaqInterface[] {
        // eslint-disable-next-line require-unicode-regexp
        const regExpMatch = new RegExp(this.state.search || '', 'i');
        return this.props.faqStore.faqs.filter(faq => faq.title && faq.text &&
            (faq.title.match(regExpMatch) || faq.text.match(regExpMatch)));
    }

    handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();
        this.setState({scroll: false, search: e.target.value});
    };

    render(): ReactNode {
        return <>
            <div className="content-header">
                <h1 className="title-h1" key="title">{I18n.t('frequently_asked_questions')}</h1>
                <FaqSearch handleChange={this.handleChange}/>
            </div>
            {this.props.faqStore.loading
                ? <SmartFullPageLoader/>
                : <FaqListQuestions
                    faqs={this.filteredFAQ()}
                    scroll={this.state.scroll}
                />}
        </>;
    }
}
