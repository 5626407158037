import React, {ReactNode, Component} from 'react';
import Product from 'app/components/shop/product';
import ShopItemInterface from 'app/interfaces/shop/ShopItemInterface';

interface Props {
    items: ShopItemInterface[],
    points: number
}

export default class Items extends Component<Props> {
    render(): ReactNode {
        const {items, points} = this.props;
        return <div className="shop-wrapper">
            <div className="shop">
                {items.sort((item, nextItem) => item.priority - nextItem.priority)
                    .map(v => <Product item={v} key={v.id} points={points}/>)}
            </div>
        </div>;
    }
}
