import React, {ReactNode, PureComponent} from 'react';
import I18n from 'app/i18n';

interface DepositDisabledMessageProps {
    banned: boolean
    message_reject: string
}

export default class DepositDisabledMessage extends PureComponent<DepositDisabledMessageProps> {
    render(): ReactNode {
        const {banned, message_reject} = this.props;

        if (!banned) {
            return null;
        }
        return <div className="deposit__reject-message">
            {message_reject || I18n.t('user.deposit_disabled')}
        </div>;
    }
}
