import {TableStore} from 'app/store/TableStore';
import {inject, observer} from 'mobx-react';
import React from 'react';
import PlayContentImpl from './index';
import PlayPage from 'app/components/pages/play';
import {useParams} from 'react-router';
interface PlaySimpleSportBetsContentProps {
    tableStore?: TableStore
}

const PlaySimpleSportBetsContent = (props: PlaySimpleSportBetsContentProps): React.JSX.Element => {
    const params = useParams<{ filter: string }>();

    const {filter = ''} = params;
    const {tableStore} = props;

    return <PlayContentImpl
        filter={filter}
        urlPrefix="/play/simple_sport_bets/"
        keyPrefix="simple_sport_bets"
        esports={false}
        tableStore={tableStore}
        tableProps={{
            filter: PlayPage.simpleSportBetsFilter
        }}
    />;
};

export default inject('tableStore')(observer(PlaySimpleSportBetsContent));
