import {isGuest} from 'app/utils';

const MAX_PROGRESS = 100;
const MIN_PROGRESS = 0;

export default function nextPrizeProgress(props) {
    if (!props.isStarted() || isGuest()) return MAX_PROGRESS;

    const {dollars_to_close, progress, count} = props;

    const percent = () => (dollars_to_close - progress) / dollars_to_close * MAX_PROGRESS;

    if (count) {
        return percent() || MAX_PROGRESS;
    } else {
        return Number(progress) ? percent() : MIN_PROGRESS;
    }
}
