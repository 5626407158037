import I18n from 'app/i18n';
import {userStore} from 'app/store/global';
import PropTypes from 'prop-types';
import {isGuest, round2Decimal, userSignedIn} from 'app/utils';
import CountdownTimer from './timer';
import {LoginSignUpButtons} from 'app/components/auth/log_in_sign_up_button';
import React, {PureComponent} from 'react';
import $ from 'jquery';
import PlaceBetButton from './PlaceBetButton';
import PossibleWinAmount from './PossibleWinAmount';
import {DRAW, STATUS_CANCEL_COMPLETED, STATUS_COMPLETED} from 'admin/components/bet_form/utils/LineConsts';
import ArtifactBetForm from './artifact/ArtifactBetForm';
import {isArtifactMoney, isPlayMoney} from './bet_maker';
import Tabs from './Tabs';
import {STATUS_OPENED} from 'app/lines/line_helpers';
import AvailableAmount from './AvailableAmount';
import {InputAmountForm} from './InputAmountForm';

export default class AmountBetForm extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            coefChanged: false,
            coefIncreased: false
        };


        this.toggleUserBets = this.toggleUserBets.bind(this);
        this.resetCoefChanged = this.resetCoefChanged.bind(this);
    }

    static propTypes = {
        amount_bet_type: PropTypes.string,
        classNamePrefix: PropTypes.string,
        date: PropTypes.number,
        description: PropTypes.string,
        gamerNum: PropTypes.number,
        hideDescription: PropTypes.bool,
        live: PropTypes.bool,
        loading: PropTypes.bool,
        multiple_bet_limit: PropTypes.number,
        multiple_bet_limit_enabled: PropTypes.bool,
        scores: PropTypes.string,
        status: PropTypes.number,
        submitHandler: PropTypes.func,
        switchTab: PropTypes.func,
        type: PropTypes.string,
        updateAmount: PropTypes.func,
        winner: PropTypes.number
    };


    componentDidUpdate(prevProps) {
        const {limit, amount, coef, gamerNum, loading} = this.props;
        const {coefChanged} = this.state;

        if (limit !== prevProps.limit) {
            if (amount > limit) {
                this.setAmount(amount);
            }
        }

        if (prevProps.coef !== coef && prevProps.gamerNum === gamerNum) {
            this.setState({
                coefChanged: !(loading && !coefChanged),
                coefIncreased: prevProps.coef < coef
            });
        } else if (prevProps.gamerNum !== gamerNum) {
            this.resetCoefChanged();
        }
    }

    validation = newAmount => {
        const {amount, limit} = this.props;
        let tempNewAmount = newAmount;

        if (tempNewAmount && !(/^\d+[,.]?\d{0,2}$/u).test(tempNewAmount)) {
            return amount;
        }

        if (Number(tempNewAmount) > limit) {
            return limit;
        }

        if (typeof tempNewAmount === 'string') {
            tempNewAmount = tempNewAmount.replace(',', '.');
        }
        return tempNewAmount;
    };

    resetCoefChanged() {
        this.setState({
            coefChanged: false,
            coefIncreased: false
        });
    }

    handleSwitchTab = (play_money, e) => {
        e && e.preventDefault();
        this.props.switchTab(play_money);
        this.resetCoefChanged();
    };

    handleIncrementAmount = by => this.setAmount(round2Decimal(Number(this.props.amount || 0) + by));

    handleChangeAmount = e => this.setAmount(e.target.value, e);

    setAmount = (amount, e) => {
        e && e.preventDefault();
        this.props.updateAmount(this.validation(amount));
        this.resetCoefChanged();
    };

    maxAvailableAmount = () => {
        const {play_money, balance} = userStore.user;
        return Math.min(isPlayMoney(this.props) ? play_money : balance, this.props.limit);
    };

    handlePlaceBet = e => {
        e && e.preventDefault();
        if (userStore.user.accept_any_coef) {
            return this.props.submitHandler();
        }

        if (this.state.coefChanged) {
            return this.resetCoefChanged();
        }

        return this.props.submitHandler();
    };

    toggleUserBets(e) {
        e && e.preventDefault();
        $(e.target).next().slideToggle(!this.props.userBetsHidden, this.props.userBetsClick);
    }

    renderScores() {
        const {status, winner, scores, hideDescription} = this.props;

        if (hideDescription) {
            return null;
        }

        if (status === STATUS_COMPLETED || status === STATUS_CANCEL_COMPLETED && DRAW === winner) {
            return <div className="match-details__item-timer-descr">{scores}</div>;
        }

        return null;
    }

    render() {
        const {amount, coef, loading = false} = this.props;
        const {coefChanged, coefIncreased} = this.state;
        const signedIn = userSignedIn() &&
              (Number(this.props.status) === STATUS_OPENED || typeof this.props.status === 'undefined') &&
              coef;

        return <div className="match-details__item-full">
            <div className="match-details__item-title">{this.props.title}</div>
            {this.props.description && this.props.lineType !== 'main' &&
              <div className="match-details__item-descr" dangerouslySetInnerHTML={{__html: this.props.description}}/>}
            {this.renderScores()}
            {typeof this.props.status !== 'undefined' &&
              <CountdownTimer
                  time={this.props.date}
                  status={this.props.status}
                  winner={this.props.winner}
                  live={this.props.live}
              />}

            {signedIn && <div className="tabs">
                <Tabs {...this.props} handleSwitchTab={this.handleSwitchTab}/>
                {isArtifactMoney(this.props)
                    ? <ArtifactBetForm
                        resetCoefChanged={this.resetCoefChanged}
                        handlePlaceBet={this.handlePlaceBet}
                        coef={coef}
                        loading={loading}
                        coefChanged={coefChanged}/>
                    : <div className="tabs__content">
                        <div className="tabs__content-item">
                            <div className="match-details__item-form">
                                <div className="match-details__item-form-row">
                                    <p>{I18n.t('your_bet_label')}:</p>
                                </div>
                                <InputAmountForm
                                    {...this.props}
                                    handleIncrementAmount={this.handleIncrementAmount}
                                    handleChangeAmount={this.handleChangeAmount}
                                    setAmount={this.setAmount}
                                    maxAvailableAmount={this.maxAvailableAmount}
                                />
                                <PossibleWinAmount
                                    amount={amount}
                                    coef={coef}
                                    coefIncreased={coefChanged && coefIncreased}
                                    playmoney={isPlayMoney(this.props)}
                                />
                                <AvailableAmount {...this.props} setAmount={this.setAmount}/>
                                <PlaceBetButton
                                    amount={amount}
                                    coef={coef}
                                    disabled={loading}
                                    playmoney={isPlayMoney(this.props)}
                                    handlePlaceBet={this.handlePlaceBet}
                                    coefChanged={coefChanged}
                                />
                            </div>
                        </div>
                    </div>}
            </div>}
            {isGuest() && <div className="match-details__row-login">
                <LoginSignUpButtons
                    usingOn="BET_POP_UP"
                    redirectUrl={`/play/bets?login#${this.props.betId}`}
                    seo={this.props.seo}
                    className="auth-links"/>
            </div>}
        </div>;
    }
}
