import {objectToURIComponent} from 'app/utils/uri_helper';

export const post = (url, params = {}, headers) => fetch(url, {
    body: objectToURIComponent(params),
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        ...headers
    },
    method: 'post'
});

export const get = (url, params = {}, headers) => fetch(url, {
    headers: {
        Accept: 'application/json',
        ...headers
    },
    method: 'get'
});

export const getCsrfToken = () => get('/deposits/token')
    .then(response => response.json())
    .then(json => json.token);
