import Post from 'app/utils/Post';
import {inject, observer} from 'mobx-react';
import React, {Component, MouseEvent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {userSignedIn, isGuest, slowConnectionPromise} from 'app/utils';
// @ts-ignore
import doubleArrDownSvg from 'images/svg_icons/double-arr-down.svg';
import SliderContainer from 'app/components/slider_container';
import {ActiveSurveyStore} from 'app/store/ActiveSurveyStore';
import {langsValue} from 'app/utils/langs/langsValue';
import {SignUpButton} from 'app/components/auth/SignUpButton';

interface SurveyProps {
    activeSurveyStore?: ActiveSurveyStore
}

interface SurveyStates {
    disabled: boolean
    opened: boolean
    results: boolean
    currentAnswer: number
}

@inject('activeSurveyStore')
@observer
export default class Survey extends Component<SurveyProps, SurveyStates> {
    constructor(props: SurveyProps) {
        super(props);
        this.state = {
            currentAnswer: null,
            disabled: true,
            opened: true,
            results: false
        };
        this.toggleOpener = this.toggleOpener.bind(this);
        this.toggleResults = this.toggleResults.bind(this);
        this.saveAnswer = this.saveAnswer.bind(this);
        this.radioClick = this.radioClick.bind(this);
    }

    container = React.createRef();

    toggleOpener(): void {
        this.setState({opened: !this.state.opened});
    }

    toggleResults(value?: boolean): void {
        this.setState({
            disabled: true,
            results: typeof value === 'boolean' ? value : !this.state.results
        });
    }

    handleToggleResults = (e: MouseEvent<HTMLAnchorElement>): void => {
        e.preventDefault();
        this.setState({
            disabled: true,
            results: !this.state.results
        });
    };

    radioClick(): void {
        this.setState({disabled: false});
    }

    saveAnswer(e: MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        const {currentAnswer} = this.state;

        if (!currentAnswer) {
            return;
        }

        slowConnectionPromise(new Post({
            params: {id: this.state.currentAnswer},
            url: '/surveys'
        })
            .execute()
            .then(data => data.json())
            .then(data => {
                this.props.activeSurveyStore.updateElementCollection(data);
                this.toggleResults(true);
            }));
    }

    handleDisable = (): void => this.setState({disabled: false});

    handleAnswerChoosing = (e: React.ChangeEvent<HTMLInputElement>): void => this
        .setState({currentAnswer: Number(e.target.value)});

    render(): ReactNode {
        const {record: activeSurvey} = this.props.activeSurveyStore;
        const {answered} = activeSurvey;

        if (Object.keys(activeSurvey).length === 0) {
            return null;
        }

        const {disabled, opened, results} = this.state;
        return <div className="voting">
            <div className="voting__heading">
                <div className="voting__title">
                    {I18n.t('survey')}
                </div>

                <div className="chat__controls" style={{width: 0}} onClick={this.toggleOpener}>
                    <button className={`act chat__opener ${opened ? 'opener-active' : ''}`}>
                        <img src={doubleArrDownSvg} alt="opener" height="12px" width="12px"/>
                    </button>
                </div>
            </div>
            <SliderContainer opened={opened}>
                <div className="voting__content">
                    <p className="voting__text">{langsValue(activeSurvey, 'question')}</p>
                    {!results && !answered
                        ? <ul className="voting__options">
                            {activeSurvey.answers.map(a => <li className="voting__option" key={a.id}>
                                <label className="field__radio" onClick={this.handleDisable}>
                                    <input
                                        type="radio"
                                        name="voting"
                                        onChange={this.handleAnswerChoosing}
                                        value={a.id}
                                    />
                                    <div className="field__radio-box"/>
                                    <div className="field__radio-label">{langsValue(a, 'answer')}</div>
                                </label>
                            </li>)}
                        </ul>
                        : <ul className="voting__results">
                            {activeSurvey.answers.map(a => <li className="voting__result" key={a.id}>
                                <div className="voting__result-label">{langsValue(a, 'answer')}</div>
                                <div className="voting__result-count">{a.percent}%</div>
                                <div className="voting__result-progress">
                                    <span style={{width: `${a.percent}%`}}/>
                                </div>
                            </li>)}
                        </ul>}
                    {!answered &&
                    <a href="#" className="voting__show-results" onClick={this.handleToggleResults}>
                        {I18n.t(results ? 'hide_results' : 'show_results')}
                    </a>}

                    {userSignedIn() && !answered && !results &&
                    <button
                        className={`button-adaptive${disabled ? ' button-adaptive_disable' : ''}`}
                        disabled={disabled}
                        onClick={this.saveAnswer}
                    >
                        {I18n.t('vote')}
                    </button>}
                    {isGuest() && <SignUpButton title={I18n.t('vote')} usingOnTab={'SURVEY_RIGHT_BLOCK'}/>}
                </div>
            </SliderContainer>
        </div>;
    }
}
