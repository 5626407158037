import React from 'react';
import {
    betMoney,
    date,
    fixed3,
    flagImage,
    gameImage,
    time,
    getMomentDiffMinutes,
    isDesktop,
    raceImage
} from 'app/utils';
import MyBetInterface from 'app/interfaces/MyBetInterface';
import {SimpleArtifactImage} from 'app/components/my_profile/artifacts/image';
import I18n from 'app/i18n';
import SmartTooltipReason from 'app/components/reusable/SmartTooltipReason';
import {betToChat, suggestionToChat} from 'app/utils/share_to_chat';
import moment from 'moment';
import {showBetForm, showBetSlip} from 'app/components/table';
import Get from 'app/utils/Get';
import {modalAlert, modalConfirm} from 'app/utils/modals/popups';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import {updateInfo} from 'app/common';
import logEvent from 'app/amplitude/log_event';
import Delete from 'app/utils/Delete';
import {userBetsStore} from 'app/store/global';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {GAMER_1} from 'app/utils/Consts';
import Post from 'app/utils/Post';

const CHAT_AVAILABLE_TIME_SECONDS = 86400;
const CANCEL_BET_TIME_MINUTES = 15;

interface Props extends MyBetInterface {
    isOpened?: boolean
}

export default class TableHistoryRow extends React.Component<Props> {
    rowStatusBet(): string {
        if (this.props.cancelled) {
            return ' match-canceled';
        }

        if (this.props.won !== null) {
            return this.props.won ? ' match-won' : ' match-lost';
        }
        return '';
    }

    cancelBet = (e: React.MouseEvent): void => {
        e && e.preventDefault();
        new Get({url: `/bets/${this.props.id}/cancel_amount`})
            .execute()
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    toastError(data.message);
                } else {
                    modalConfirm(
                        I18n.t('user_cancel_bet.confirm', {
                            amount: betMoney({
                                amount: data.cancel_amount,
                                playmoney: this.props.playmoney
                            })
                        }),
                        () => this.doCancelBet(data.cancel_amount)
                    );
                }
            });
    };

    cancelSuggestion = (e: React.MouseEvent): void => {
        e && e.preventDefault();
        modalConfirm(I18n.t('cancel_suggestion'), () => {
            new Post({
                params: {ids: [this.props.id]},
                url: '/incubator2/bets/cancel'
            }).execute()
                .then(response => {
                    if (response.ok) {
                        toastSuccess(I18n.t('suggestion_cancelled'));
                        updateInfo();
                        this.setCancel();
                    }
                });
        });
    };

    setCancel(): void {
        userBetsStore.updateCancelledUserBet(this.props.id);
    }

    doCancelBet = (amount: number): void => {
        new Delete({params: {cancel_amount: amount}, url: `/bets/${this.props.id}`})
            .execute()
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    toastSuccess(I18n.t('user_cancel_bet.success'));
                    updateInfo();
                    logEvent('CANCEL_BET');
                    this.setCancel();
                } else if (data.cancel_amount) {
                    modalConfirm(
                        `${I18n.t('user_cancel_bet.odd_changed') } ${I18n.t('user_cancel_bet.confirm',
                            {
                                amount: betMoney({
                                    amount: data.cancel_amount,
                                    playmoney: this.props.playmoney
                                })
                            })}`,
                        () => this.doCancelBet(data.cancel_amount)
                    );
                } else if (data.message) {
                    modalAlert(data.message);
                } else {
                    modalAlert(I18n.t('user_cancel_bet.failure'));
                }
            });
    };

    shareChatButtonAvailable(): boolean {
        return this.props.bet.winner !== 0 && moment().unix() - this.props.date > CHAT_AVAILABLE_TIME_SECONDS;
    }

    betMoreAvailable = (): boolean => this.props.bet && !this.props.bet.cancelled;

    cancelAvailable(): boolean {
        return this.props.bet && !this.props.bet.live &&
            getMomentDiffMinutes(this.props.bet.start_time) > CANCEL_BET_TIME_MINUTES &&
            this.props.bet.type === 'UserBet';
    }

    cancelAvailableSuggestion(): boolean {
        return this.props.bet && this.props.suggestion && this.props.started;
    }

    betMore = (e: React.MouseEvent<HTMLButtonElement>): void => {
        e && e.preventDefault();
        const {bet, betMore, on, reloadBets} = this.props;

        if (!betMore) {
            return;
        }

        userBetsStore.setOpenedUserBetId(Number(this.props.id));

        if (this.betMoreAvailable() && !(bet.finished || bet.cancelled)) {
            showBetSlip(bet, on, {selectedBet: bet});
        } else {
            showBetForm(bet, null, {afterBet: reloadBets});
        }
    };

    renderActionsButtons(): React.ReactNode {
        if (!this.props.actionsButtons) {
            return null;
        }

        const res = [];
        const {id, suggestion} = this.props;

        if (this.betMoreAvailable() && (this.cancelAvailable() || this.cancelAvailableSuggestion())) {
            res.push(<button className="button-adaptive button-adaptive_instyle"
                key={`cancel_${id}`} onClick={suggestion ? this.cancelSuggestion : this.cancelBet}
            >
                <span className="button-name">{I18n.t('user.cancel_label')}</span>
            </button>);
        }
        res.push(this.shareInChatButton());
        return res;
    }

    chatButtonClick = (e: React.MouseEvent): void => {
        e.preventDefault();
        e.stopPropagation();
        const {suggestion, id} = this.props;

        if (suggestion) {
            suggestionToChat(id);
        } else {
            betToChat(id);
        }
    };

    shareInChatButton(): React.ReactNode {
        return !this.shareChatButtonAvailable() &&
            !this.props.cancelled &&
            <button className="button-adaptive button-adaptive_instyle"
                key={`share_chat_button_${this.props.id}`}
                onClick={this.chatButtonClick}
            >
                <svg className="svg-default button-icon">
                    <use xlinkHref="#svg-arrow-link-new"/>
                </svg>
                <span className="button-name">{I18n.t('chat_title')}</span>
            </button>;
    }

    isNestedBet = (): boolean => this.props.bet.game_id > 0;

    onClickCopyMessage = (): void => {
        toastSuccess(I18n.t('single_bet_copied'));
    };

    renderMobile(): React.ReactNode {
        const {
            id, filter, flag, extended_gamer, playmoney, race,
            coef, amount, cancelled, bet_artifacts, win_artifacts
        } = this.props;
        const {gamer_1, gamer_2} = this.props.bet.root_line || this.props.bet;
        const gamer_nick = this.props.on === GAMER_1 ? gamer_1.nick : gamer_2.nick;
        const activeClass = this.props.isOpened ? ' active' : '';
        return <div className={`table-content-row-mobile${this.rowStatusBet()}`}>
            <div className="history-match">
                <div className="history-match-date">
                    <span>{date(this.props.date)}</span>
                    <span>{time(this.props.date)}</span>
                    <div className="history-match-id">
                        <span className="history-match-id-number">#{id}</span>
                        <div className="copy-button">
                            <CopyToClipboard text={id} onCopy={this.onClickCopyMessage}>
                                <span className="icon">
                                    <svg className="svg-default">
                                        <use xlinkHref="#svg-copy-icon"/>
                                    </svg>
                                </span>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
                <div className="history-match-teams">
                    {gameImage(filter, 'history-match-game-logo')}
                    <span className="history-match-teams-names">{gamer_1.nick} vs {gamer_2.nick}</span>
                </div>
                {this.isNestedBet() && <span className="history-match-details">
                    {extended_gamer}
                </span>}
                <div className="history-match-team">
                    {flagImage(flag, 'history-match-team-country-flag')}
                    {raceImage(race, 'history-match-team-country-flag')}
                    <span className="history-match-team-name">{this.isNestedBet() ? gamer_nick : extended_gamer}</span>
                </div>
            </div>
            <div className="table-content-inner-row-mobile">
                <div className="cell">
                    <span className="cell-title">{I18n.t('bet')}:</span>
                    {bet_artifacts
                        ? <div className="history-match-artifacts">
                            {bet_artifacts.map((a, i) => <>
                                {Array.from({length: a.total_inventory_items
                                    ? a.total_inventory_items
                                    : 1}, (val, index) => <span
                                    key={`artifact_${index}`}
                                    className="history-match-artifact">
                                    <SimpleArtifactImage
                                        isClickable
                                        imgAttributes={{className: 'image-inventory cursor-pointer'}}
                                        artifact={a}
                                        key={index + i}
                                    />
                                </span>)}
                            </>)}
                        </div>
                        : <span className="history-match-sum">{betMoney(this.props)}</span>}
                </div>
                <div className="cell">
                    <button
                        className={`history-match-coefficient${this.betMoreAvailable() ? ' more' : ''}${activeClass}`}
                        onClick={this.betMore}
                    >
                        {fixed3(coef)}
                    </button>
                </div>
                <div className="cell">
                    <span className="cell-title">{I18n.t('won')}:</span>
                    {win_artifacts
                        ? <div className="history-match-artifacts">
                            {win_artifacts.map((a, i) => <>
                                {Array.from({length: a.total_inventory_items
                                    ? a.total_inventory_items
                                    : 1}, (val, index) => <span
                                    key={`win_artifact_${index}`}
                                    className="history-match-artifact">
                                    <SimpleArtifactImage
                                        isClickable
                                        imgAttributes={{className: 'image-inventory cursor-pointer'}}
                                        artifact={a}
                                        key={index + i}
                                    />
                                </span>)}
                            </>)}
                        </div>
                        : <span className="history-sum">{this.props.won !== null && this.props.won ||
                        this.props.won === null && !this.props.cancelled
                            ? betMoney({amount: amount * coef, playmoney})
                            : '-'}</span>}
                </div>
            </div>
            <div className="history-right-buttons">
                {cancelled ? <SmartTooltipReason {...this.props}/> : this.renderActionsButtons()}
            </div>
        </div>;
    }

    render(): React.ReactNode {
        if (!isDesktop()) {
            return this.renderMobile();
        }

        const {
            id, filter, flag, extended_gamer, playmoney, race,
            coef, amount, cancelled, bet_artifacts, win_artifacts,
            finished
        } = this.props;
        const {gamer_1, gamer_2} = this.props.bet.root_line || this.props.bet;
        const gamer_nick = this.props.on === GAMER_1 ? gamer_1.nick : gamer_2.nick;
        const activeClass = this.props.isOpened ? ' active' : '';

        return <div className={`table-content-row${this.rowStatusBet()}`}>
            <div className="table-content-cell">
                <div className="history-match">
                    <div className="history-match-date">
                        <span>{date(this.props.date)}</span>
                        <span>{time(this.props.date)}</span>
                    </div>
                    <div className="history-match-team">
                        {gameImage(filter, 'history-match-game-logo')}
                        <span className="history-match-team-name">
                            {gamer_1.nick} vs {gamer_2.nick} {finished && this.props.bet.game_name_score}
                        </span>
                    </div>
                    {this.isNestedBet() && <span className="history-match-details">
                        {extended_gamer}
                    </span>}
                    {!this.isNestedBet() && <div className="history-match-teams">
                        {flagImage(flag, 'history-match-team-country-flag')}
                        {raceImage(race, 'history-match-team-country-flag')}
                        <span className="history-match-teams-names">
                            {this.isNestedBet() ? gamer_nick : extended_gamer}
                        </span>
                    </div>}
                </div>
            </div>
            <div className="table-content-cell">
                {bet_artifacts
                    ? <div className="history-match-artifacts">
                        {bet_artifacts.map((a, i) => <>
                            {Array.from({length: a.total_inventory_items
                                ? a.total_inventory_items
                                : 1}, (val, index) => <span
                                key={`artifact_item_${index}`}
                                className="history-match-artifact">
                                <SimpleArtifactImage
                                    isClickable
                                    imgAttributes={{className: 'image-inventory cursor-pointer'}}
                                    artifact={a}
                                    key={index + i}
                                />
                            </span>)}
                        </>)}
                    </div>
                    : <span className="history-match-sum">{betMoney(this.props)}</span>}
            </div>
            <div className="table-content-cell">
                <button
                    className={`history-match-coefficient more${activeClass}`}
                    onClick={this.betMore}
                >
                    {fixed3(coef)}
                </button>
            </div>
            <div className="table-content-cell">
                {win_artifacts
                    ? <div className="history-match-artifacts">
                        {win_artifacts.map((a, i) => <>
                            {Array.from({length: a.total_inventory_items
                                ? a.total_inventory_items
                                : 1}, (val, index) => <span
                                key={`win_artifact_item_${index}`}
                                className="history-match-artifact">
                                <SimpleArtifactImage
                                    isClickable
                                    imgAttributes={{className: 'image-inventory cursor-pointer'}}
                                    artifact={a}
                                    key={index + i}
                                />
                            </span>)}
                        </>)}
                    </div>
                    : <span className="history-sum">{this.props.won !== null && this.props.won ||
                    this.props.won === null && !this.props.cancelled
                        ? betMoney({amount: amount * coef, playmoney})
                        : '-'}</span>}
            </div>
            <div className="table-content-cell">
                <div className="history-match-id">
                    <span className="history-match-id-number">{id}</span>
                    <div className="copy-button">
                        <CopyToClipboard text={id} onCopy={this.onClickCopyMessage}>
                            <span className="icon">
                                <svg className="svg-default">
                                    <use xlinkHref="#svg-copy-icon"/>
                                </svg>
                            </span>
                        </CopyToClipboard>
                    </div>
                </div>
                <div className="history-right-buttons">
                    {cancelled ? <SmartTooltipReason {...this.props}/> : this.renderActionsButtons()}
                </div>
            </div>
        </div>;
    }
}
