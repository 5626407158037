import React, {Component, ReactNode} from 'react';

export default class PokerMoveButtonDraggable extends Component {
    render(): ReactNode {
        return <button className="poker-move-button draggable-poker">
            <svg className="svg-default">
                <use xlinkHref="#svg-bet-slip-controller-draggable"/>
            </svg>
        </button>;
    }
}
