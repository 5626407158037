import React, {memo} from 'react';
import ListBetSlipMatchesGames from 'app/components/bet_slip/matches_games/ListBetSlipMatchesGames';
import BetSlipAmount from 'app/components/bet_slip/amount_money/BetSlipAmount';
import BetSlipLatestBets from 'app/components/bet_slip/latest_bets/BetSlipLatestBets';
import {isArtifactMoney} from 'app/lines/isArtifactMoney';
import BetSlipLoading from 'app/components/bet_slip/loading/BetSlipLoading';
import ArtifactForm from 'app/components/bet_slip/inventory/ArtifactForm';
import BetsGamesNumber from 'app/components/bet_slip/matches_games/BetsGamesNumber';
import BetSlipSuggestionsCoefficients from 'app/components/bet_slip/suggestions/BetSlipSuggestionsCoefficients';
import BetSlipLineDescription from 'app/components/bet_slip/line_description/BetSlipLineDescription';
import BetSlipStats from 'app/components/bet_slip/stats/BetSlipStats';
import {isDesktop} from 'app/utils';
import MobileKeyboardContainer from 'app/components/bet_slip/mobile_keyboard/MobileKeyboard.container';
import {BetSlipWarningContainer} from 'app/components/bet_slip/BetSlipWarning/BetSlipWarning.container';

export default interface BetSlipBodyInterface {
    loading: boolean
    type: string
    showGamerNumber: boolean
}

export const BetSlipBody = memo((props: BetSlipBodyInterface): JSX.Element => {
    const {type, loading, showGamerNumber} = props;

    return <>
        {loading
            ? <BetSlipLoading/>
            : <>
                <ListBetSlipMatchesGames/>
                <BetSlipSuggestionsCoefficients/>
                <BetSlipStats/>
                <BetSlipLineDescription/>
                <div className="bet-slip__body-inner">
                    <BetSlipWarningContainer/>
                    {showGamerNumber && <BetsGamesNumber/>}
                    {isArtifactMoney(type)
                        ? <ArtifactForm/>
                        : <>
                            <BetSlipAmount/>
                            <BetSlipLatestBets/>
                        </>}
                    {!isDesktop() && !isArtifactMoney(type) && <MobileKeyboardContainer/>}
                </div>
            </>}
    </>;
});
