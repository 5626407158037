import LineInterface from 'app/interfaces/LineInterface';

export default class Line {
    allNestedLines = {};

    constructor(line: LineInterface, parentLine?: LineInterface) {
        if (parentLine) {
            this.asLine().parent = parentLine;
        }
        this.asLine().nested_bets = {};
        this.update(line);
    }

    update = (newLine: LineInterface): void => {
        Object.keys(newLine).forEach(key => {
            this[key] = newLine[key];
        });
    };

    asLine = (): LineInterface => this as unknown as LineInterface;
}
