import React, {Component} from 'react';
import {observer} from 'mobx-react';
import NotificationStore from 'app/store/notifications';

import BetProgress from './bet_progress';
import Layout from './layout';
import Subscription from './subscription';


const components = {
    betProgress: BetProgress,
    subscription_browser_notifications: Subscription
};

@observer
class NotificationList extends Component {
    onItemClose = key => {
        NotificationStore.removeActiveKeys(key);
    };

    render() {
        return (
            <div className="notification__list">
                {NotificationStore.activeNotify.map(x => components[x.type] && <Layout {...x}
                    component={components[x.type]}
                    key={`${x.type}-${x.key}`}
                    onClose={this.onItemClose}
                    externalKey={x.key}
                />)}
            </div>
        );
    }
}

export default NotificationList;
