import $ from 'jquery';
import React, {Component} from 'react';
import I18n from 'app/i18n';
import {date, isDesktop, flagImage, raceImage, time, isGuest, fixed2} from 'app/utils';
import {fixed2int} from 'app/utils/fixed';
import {Table, showBetSlip, showBetForm} from 'app/components/table';
import {openStream} from 'app/components/streams';
import OpenBetLinkCover from './open_bet_link_cover';
import CoefButton from '../coef_button';
import nestedBetsSort from 'app/utils/nestedBetsSort';
import {
    lineEventName,
    isLoserClass,
    isWinnerClass,
    GAMER_1,
    GAMER_2,
    isOpened, WHITE
} from 'app/lines/line_helpers';
import lineLoader from 'images/table/line-loader';
import {hasNestedLines} from 'app/lines/hasNestedLines';
import {isAdvantageLine} from 'app/lines/isAdvantageLine';
import {hasNestedBetWithUserBet} from 'app/lines/hasNestedLinesWithUserBet';
import {gamerCoef} from 'app/lines/gamerCoef';
import {hasUserBet} from 'app/lines/hasUserBet';
import {lineGamer} from 'app/lines/lineGamer';
import {tableNick} from 'app/lines/tableNick';
import {isMainLine} from 'app/lines/isMainLine';
import {STATUS_CANCEL_COMPLETED, STATUS_COMPLETED} from 'admin/components/bet_form/utils/LineConsts';
import {goToIncubatorLineEdit} from 'app/utils/app_links';
import {IconOpenStream} from 'app/components/pages/incubator/IconOpenStream';
import {betSlipStore} from 'app/store/global';
import {isIncubatorPages} from 'app/utils/isIncubatorPages';
import {incubatorTablePage} from 'app/utils/incubatorTablePage';
import Get from 'app/utils/Get';
import {revertNoScroll} from 'app/utils/revertNoScroll';
import PopupStat from '../../popup/PopupStat';
import {showPopup} from 'app/utils/modals/ModalsService';


const COL_1 = 1,
    COL_3 = 3;

export default class Row extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showModal: false,
            showNestedBets: false,
            widgetUrl: ''
        };
        this.handleShowNestedBets = this.handleShowNestedBets.bind(this);
        this.coefOnClick = this.coefOnClick.bind(this);
        this.container = React.createRef();
    }

    fetchStatisticUrl = () => {
        const bet = this.props.bet.id;
        const url = `/oddin/stats/${bet}/match_statistics`;
        return new Get({url}).execute();
    };

    toggleStatisticModal = () => {
        this.fetchStatisticUrl().then(response => response.json())
            .then(r => this.setState({showModal: true, widgetUrl: r.url}, () => {
                if (isDesktop()) {
                    this.showStatisticsPopup();
                } else {
                    this.showStatisticsPopup();
                }
            }));
    };

    closeModal = () => {
        this.setState({showModal: false}, () => {
            revertNoScroll();
            document.body.classList.remove('modal-open');
        });
    };


    componentDidUpdate(prevProps) {
        const {showNestedBets} = this.props;

        if (prevProps.showNestedBets !== showNestedBets) {
            this.collapseRow(showNestedBets);
        } else if (this.state.showNestedBets) {
            this.openNestedContainerManually();
        }
    }

    componentDidMount() {
        document.addEventListener('visibilitychange', this.handleVisibilityChange);
    }

    componentWillUnmount() {
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    }

    handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            this.forceUpdate();
        }
    };

    openNestedContainerManually() {
        $(this.container.current).fadeIn();
    }

    hasNestedBets = () => hasNestedLines(this.props.bet) || this.props.bet.has_advantage;

    handleShowNestedBets() {
        if (this.hasNestedBets()) {
            const showStatus = !this.state.showNestedBets;

            this.collapseRow(showStatus);
        }
    }

    loadLine(callback) {
        const {userStore, tableStore, bet} = this.props;

        if (userStore.user.light_mode && !tableStore.hasLoadedLineId(bet.id)) {
            this.setState({loading: true});
            tableStore.loadLine(bet.id).then(() => {
                callback();
                tableStore.addLoadedLineId(bet.id);
            }).finally(() => this.setState({loading: false}));
        } else {
            callback();
        }
    }

    collapseRow(showStatus) {
        const {bet, tableStore} = this.props;

        if (this.state.loading || !hasNestedLines(bet) && !bet.has_advantage) {
            return;
        }
        if (showStatus) {
            this.loadLine(() => {
                this.setState({showNestedBets: showStatus});
                tableStore.addOpenedLineId(bet.id);
                this.openNestedContainerManually();
            });
        } else {
            $(this.container.current).fadeOut('done', () => {
                tableStore.deleteOpenedLineId(bet.id);
                this.setState({showNestedBets: showStatus});
            });
        }
    }

    nestedBets() {
        const {bet: {nested_bets}} = this.props;
        return nestedBetsSort(nested_bets || {});
    }

    renderButtonPlus(className, nestedBetsCount, hasNestedBets = false) {
        const {loading} = this.state;

        const buttonClass = `act table-expand ${className}${hasNestedBetWithUserBet(this.props.bet)
            ? ' table-expand--already-bet'
            : ''}`;
        return <button className={buttonClass} onClick={this.handleShowNestedBets}>
            {loading
                ? <span className="loader-button">
                    <img alt="loader" src={lineLoader}/>
                </span>
                : this.renderPlus(hasNestedBets, nestedBetsCount)}
        </button>;
    }

    renderPlus(hasNestedBets, nestedBetsCount) {
        const plusClass = `table-expand__plus-holder${this.state.showNestedBets ? ' table-expand--active' : ''}`;
        return hasNestedBets
            ? <div className={plusClass}>
                <svg className="svg-default svg-h">
                    <use xlinkHref="#svg-plus-new-h"/>
                </svg>
                <svg className="svg-default svg-v">
                    <use xlinkHref="#svg-plus-new-v"/>
                </svg>
                <span className="table-expand__plus-num">{nestedBetsCount}</span>
            </div>
            : null;
    }

    getParentBetOnNestedBet = () => hasNestedLines(this.props.bet) ? this.props.bet : this.props.bet.parent;

    getBet = () => isMainLine(this.props.bet) ? this.props.bet : this.getParentBetOnNestedBet();

    coefOnClick(on, event) {
        event.preventDefault();
        event.stopPropagation();
        const bet = this.props.isExpress ? this.props.bet : this.getBet();

        if (this.props.coefOnClick) {
            this.props.coefOnClick(bet, on);
        } else if (this.rowNotActive(this.props.bet.status)) {
            showBetForm(this.getBet(), on, {selectedBet: this.props.bet});
        } else if (betSlipStore.lines && betSlipStore.lines.length > 0) {
            betSlipStore.coefClick(this.props.bet, on);
        } else {
            showBetSlip(bet, on, {selectedBet: this.props.bet});
        }
    }

    rowOnClick = on => {
        const bet = this.props.isExpress ? this.props.bet : this.getBet();

        if (this.rowNotActive(bet.status)) {
            showBetForm(this.getBet(), on, {selectedBet: this.props.bet});
        } else {
            showBetSlip(bet, on, {selectedBet: this.props.bet});
        }
    };

    rowNotActive = status => status === STATUS_COMPLETED || status === STATUS_CANCEL_COMPLETED;

    coefBorderClass(active, gamerNum) {
        const {bet} = this.props;
        return (isOpened(bet.color) ? '' : ' no-border-color') +
            (active ? ' active' : '') +
            isWinnerClass(bet, gamerNum) +
            (!this.props.isExpress && hasUserBet(bet, gamerNum) ? ' bet-rate--already-bet' : '');
    }

    renderGamer(num, isNested, active) {
        const {bet} = this.props;
        const gamer = lineGamer(bet, num);
        const resultLostClass = isLoserClass(bet, num);
        const cursorClass = this.hasNestedBets() ? ' cursor-pointer' : '';
        const winnerClass = isWinnerClass(bet, num);
        const coefClassName = `bet-rate cursor-pointer${
             this.coefBorderClass(active, num)}`;
        const coef = gamerCoef(bet, num);
        const gamerNick = tableNick(bet, num);
        const isShowedFlag = !isAdvantageLine(bet) && !isNested;
        const onClick = coef && coef !== '0.000' || bet.incubator_2
            ? this.coefOnClick.bind(this, num)
            : this.handleShowNestedBets;
        return <div className={`table-bets__col-${num === GAMER_1 ? COL_1 : COL_3}`}>
            <div
                className={`table-bets__player${num === GAMER_1 ? GAMER_1 : GAMER_2}${resultLostClass}${cursorClass}`}
                onClick={this.handleShowNestedBets}
            >
                {isShowedFlag ? flagImage(gamer.flag) : null}
                {isShowedFlag ? raceImage(gamer.race) : null}
                <span title={gamerNick} className={winnerClass}>{gamerNick}</span>
            </div>
            <div className={`table-bets__odds${ resultLostClass}`}>
                <CoefButton
                    coef={coef}
                    className={coefClassName}
                    incubator_2={bet.incubator_2}
                    onClick={onClick}
                />
            </div>
        </div>;
    }

    renderStreamIcon() {
        const {currentStream} = this.props;
        return <div className="table-bets__play cursor-pointer">
            {currentStream
                ? <svg height="14px" width="19px" onClick={() => openStream(currentStream)}>
                    <use xlinkHref="#svg-movie"/>
                </svg>
                : null}
        </div>;
    }

    renderStreamIconMobile() {
        const {currentStream} = this.props;
        return currentStream
            ? <span className="watch-button-mobile live" onClick={() => openStream(currentStream)}>
                <svg className="svg-default">
                    <use xlinkHref="#svg-movie"/>
                </svg>
                <b>{I18n.t('stream.name')}</b>
            </span>
            : null;
    }


    renderStatIconMobile() {
        return <span className="watch-button-mobile stat" onClick={this.toggleStatisticModal}>
            <svg className="svg-default">
                <use xlinkHref="#svg-stat" />
            </svg>
            <b>{I18n.t('incubator_user_guide_mob')}</b>
        </span>;
    }

    handleEdit() {
        const {bet} = this.props;
        goToIncubatorLineEdit(bet.id, bet.esports);
    }

    renderIncubatorEditIcon() {
        const {bet, incubatorPage, isCreator} = this.props;

        return incubatorPage && !isGuest() && !this.rowNotActive(bet.status) &&
                <div className="table-bets__play cursor-pointer edit-incubator">
                    {bet.incubator && bet.color !== WHITE && isCreator &&
                            <span className="button-edit-incubator-line" onClick={this.handleEdit.bind(this)}>
                                <svg className="svg-default">
                                    <use xlinkHref="#svg-edit-pencil"/>
                                </svg>
                            </span>}
                </div>;
    }

    renderIncubatorLineTurnover() {
        const {bet: {turnover}} = this.props;

        return incubatorTablePage() && <div className={'table-bets__profit-line'}>
            ${fixed2int(turnover)}
        </div>;
    }

    renderIncubatorLineProfit(bet) {
        const {profit, status} = bet;
        const minusValue = -1;
        const positiveNumber = profit < 0 ? profit * minusValue : profit;

        if (this.rowNotActive(status)) {
            return <span className={`table-bets__incubator-profit${Number(profit) >= 0
                ? ' holder--color2'
                : ' holder--color3'}`}>
            ${fixed2(positiveNumber)}
            </span>;
        }

        return <span className="table-bets__incubator-profit-stub"></span>;
    }

    renderDate() {
        const {bet} = this.props;
        return bet.live
            ? <div className="table-bets__live">
                <span className="table-bets__live-text">{I18n.t('live_label')}</span>
            </div>
            : <div className="table-bets__date">
                <span>{date(bet.date)}</span>
                <span>{time(bet.date)}</span>
            </div>;
    }

    isActive(on) {
        const active = this.props.activeCoef ? this.props.activeCoef(this.props.bet.id) : null;
        return active && active.on === on;
    }


    showStatisticsPopup() {
        showPopup('popup-stat',
            props => <PopupStat
                popupId={props.popupId}
                widgetUrl={this.state.widgetUrl}
                show={this.state.showModal}
            />);
    }

    renderBody(isNested) {
        const {bet, bet: {incubator_stream, color, esports}, incubatorPage, userStore} = this.props;
        const eventName = lineEventName(bet, isNested, userStore);
        const labelType = Table.labelTypes[bet.label_type];
        const class_pointer = this.hasNestedBets() ? 'cursor-pointer' : '';

        return <div className="table-bets__content-row">
            {this.renderTableExpand()}
            <OpenBetLinkCover className="table-bets__content-row"
                seo={this.props.seo} bet={bet}>
                <div
                    className={`table-bets__mobile-row ${class_pointer}`}
                    onClick={this.handleShowNestedBets}
                >
                    {this.renderDate()}
                    <div className="table-bets__icon">
                        {bet.label_type !== null && !isNested && <img
                            className="label-type"
                            src={labelType.src}
                            alt={I18n.t(labelType.id)}
                            title={I18n.t(labelType.id)}
                        />}
                    </div>
                    <div className="table-bets__event">
                        {this.renderGameImage()}
                        <span title={eventName}>{eventName}</span>
                    </div>
                    {!isDesktop() && this.renderIncubatorLineTurnover()}
                </div>
                <div className="table-bets__cols-holder">
                    {this.renderGamer(GAMER_1, isNested, this.isActive(GAMER_1))}
                    {this.renderGamer(GAMER_2, isNested, this.isActive(GAMER_2))}
                    {!isDesktop() && (bet.incubator
                        ? <IconOpenStream
                            className="table-bets__play cursor-pointer"
                            white={color === WHITE}
                            esports={esports}
                            incubator_stream={incubator_stream}
                            bet={bet}
                            showMobilePencilEdit={incubatorPage &&
                                bet.incubator && bet.color !== WHITE && bet.isCreator}
                            profitMobile={incubatorPage ? this.renderIncubatorLineProfit(bet) : null}
                        />
                        : <div className="wrapper-watch-buttons-mobile">
                            <>{this.renderStreamIconMobile()}</>
                        </div>
                    )}

                </div>
            </OpenBetLinkCover>
            {isDesktop() && this.renderIncubatorLineTurnover()}

            {bet.incubator
                ? <IconOpenStream
                    white={color === WHITE}
                    esports={esports}
                    incubator_stream={incubator_stream}
                    bet={bet}
                    className="table-bets__play cursor-pointer"/>
                : this.renderStreamIcon()}
            {this.renderIncubatorEditIcon()}
            {isDesktop() && incubatorPage && bet.incubator && isIncubatorPages() && this.renderIncubatorLineProfit(bet)}
            <div className="table-bets__stat">
                {this.props.bet.has_stats && <button className="button-stats-line" onClick={this.toggleStatisticModal}>
                    <svg className="svg-default">
                        <use xlinkHref="#svg-stat"/>
                    </svg>
                </button>}
            </div>
            {!isDesktop() && this.props.bet.has_stats && this.renderStatIconMobile()}
        </div>;
    }
}
