import React, {Fragment, PureComponent} from 'react';
import TimeToStartTimer from 'app/utils/TimeToStartTimer';
import I18n from 'app/i18n';
import {STATUS_CANCELED, STATUS_CLOSED, STATUS_DRAW, STATUS_OPENED, STATUS_STARTED} from 'app/lines/line_helpers';

const getStatusMatchText = (status, winner) => {
    let translationKey = null;
    switch (status) {
    case STATUS_STARTED:
        translationKey = 'match_has_started_label';
        break;
    case STATUS_CLOSED:
        translationKey = 'match_is_over_label';
        break;
    case STATUS_OPENED:
        translationKey = 'it_will_be_closed_in';
        break;
    case STATUS_CANCELED:
        translationKey = winner === STATUS_DRAW ? 'match_was_ended_by_draw' : 'match_was_cancelled_label';
        break;
    default:
        break;
    }
    return translationKey && I18n.t(translationKey);
};

export default class CountdownTimer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {...window.settings.user.time_zone};
    }

    render() {
        const {time, status, live, plainText, winner} = this.props;
        const opened = status === STATUS_OPENED || !status;
        const started = status === STATUS_STARTED;
        const props = {
            ...this.state,
            plainText,
            time_to_start: time
        };

        return plainText
            ? <TimeToStartTimer {...props}/>
            : <div className="match-details__item-timer-descr">
                {live
                    ? <span>
                        {opened && I18n.t('live_label')}
                        {started && I18n.t('live_has_started_label')}
                        {winner === STATUS_DRAW && I18n.t('match_was_ended_by_draw')}
                        {status === STATUS_CANCELED && I18n.t('match_was_cancelled_label')}
                    </span>
                    : <Fragment>
                        {getStatusMatchText(status, winner)}
                        {opened && <TimeToStartTimer {...props}/>}
                    </Fragment>}
            </div>;
    }
}
