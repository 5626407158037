import React from 'react';

const KEYS_FOR_DOT_AUTOFILL = /^[0-9]/u;

const changeCoefByStep = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;

    if (target.value.length === 1 && Number(target.value[0]) && KEYS_FOR_DOT_AUTOFILL.test(e.key) &&
        window.getSelection().toString() !== target.value[0]) {
        target.value = target.value.concat('.');
    }
};

export default changeCoefByStep;
