import TableLeadersRow from 'app/components/promo_action/compendium/table_leaders/TableLeadersRow';
import React, {PureComponent} from 'react';
import I18n from 'app/i18n';

export default class TableLeaders extends PureComponent {
    render() {
        const {ratings} = this.props;

        return <div className="compendium-aside__table-leaders aside-table table-leaders">
            <div className="aside-table__heading table-leaders__heading">
                <TableLeadersRow
                    place="№"
                    rating_points="RP"
                    user={{name: I18n.t('table.nickname_title')}}
                />
            </div>
            <div className="aside-table__body table-leaders__body">
                {ratings.map(rating => <TableLeadersRow key={rating.place} {...rating}/>)}
            </div>
        </div>;
    }
}
