import React, {Fragment, PureComponent} from 'react';
import {flagImage, tableGameImage} from 'app/utils';

class SimpleGamerDescription extends PureComponent {
    render() {
        const {flag, detailed_name, game, withoutGameImg} = this.props;
        return <Fragment>
            {
                withoutGameImg
                    ? ''
                    : tableGameImage(game, 'lang__flag-toast__game')
            }{
                flagImage(flag, 'lang__flag-toast')
            }{
                detailed_name
            }
        </Fragment>;
    }
}

export {SimpleGamerDescription};
