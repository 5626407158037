import React, {PureComponent} from 'react';
import {SimpleArtifactImage} from 'app/components/my_profile/artifacts/image';
import I18n from 'app/i18n';
import SimpleLineChart from './simple_line_chart';
import {artifactDescription, artifactName} from './utils';
import Smiles from 'app/components/my_profile/artifacts/popup/smiles';

export default class WithChart extends PureComponent {
    render() {
        const {artifact, recipe, header} = this.props;
        return <div className="popup__content">
            <h2 className="popup__header">{header || I18n.t('put_it_up_for_sale')}</h2>

            <div className="sell-inventory__popup">
                <div className="sell-inventory__popup--description">
                    <div className="sell-inventory__popup--img-wrap">
                        <div className="sell-inventory__popup--img">
                            <SimpleArtifactImage artifact={artifact}/>
                        </div>
                    </div>
                    <div className="sell-inventory__popup--desc">
                        <div className="sell-inventory__popup--desc-title">
                            {artifactName(artifact)}
                        </div>
                        <div className="sell-inventory__popup--desc-text">
                            {artifactDescription(artifact)}
                            <Smiles {...artifact}/>
                        </div>
                    </div>
                </div>
                <div className="sell-inventory__popup--prices">
                    <div className="sell-inventory__popup--prices-title">{I18n.t('median_sale_price')}</div>
                    <div className="sell-inventory__popup--prices-diagram">
                        <div id="inventory-sales">
                            <SimpleLineChart
                                artifact={artifact}
                                recipe={recipe}
                            />
                        </div>
                    </div>
                </div>
                {this.props.children}
            </div>
        </div>;
    }
}
