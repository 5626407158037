import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';
import PresenceValidation from 'validations/PresenceValidation';
import WalletInterface from 'app/interfaces/WalletInterface';
import I18n from 'app/i18n';
import {CashoutsFormInterface} from 'app/store/CashoutsStore';
import BaseValidator from 'validators/BaseValidator';
import {translatedKeyLangs} from 'app/utils/translations_helper';
import {fixed2int} from 'app/utils/fixed';

export default class CashoutValidator extends BaseValidator<CashoutsFormInterface> {
    getSelectedWallets = (id: number): WalletInterface[] => this.object.wallets[id]
        .filter(w => w.masked_account) || [];

    validatePaymentSystems(): void {
        const {selectedSystem} = this.object;
        this.paymentSystemValidation(selectedSystem);
    }

    paymentSystemValidation(paymentSystem: PaymentSystemInterface): void {
        this.validateWalletAccount();
        this.validateWalletRegExp(paymentSystem);
        this.validateMaxLimit(paymentSystem);
        this.validateMinLimit(paymentSystem);
        this.validatePayzenDetails(paymentSystem);
        this.validateScreenshot(paymentSystem);
    }

    validateWalletAccount(): void {
        const {selectedWallet} = this.object;
        const {account} = this.object.wallet_attributes;

        if (!selectedWallet && !account) {
            this.setError('wallet.account', I18n.t('required_field'));
            this.result = false;
        }
    }

    validateWalletRegExp(paymentSystem: PaymentSystemInterface): void {
        const {wallet_regexp} = paymentSystem;
        const {account} = this.object.wallet_attributes;

        if (wallet_regexp &&
            account &&
            !new RegExp(`^${paymentSystem.wallet_regexp}$`, 'u').test(account)) {
            this.setError('wallet.account', translatedKeyLangs(paymentSystem, 'wallet_regexp_error_text'));
            this.result = false;
        }
    }

    validateMaxLimit(paymentSystem: PaymentSystemInterface): void {
        const maxLimit = Number(paymentSystem.max_limit);
        const {amount} = this.object;
        const amountNumber = Number(amount);

        if (maxLimit !== 0 && amountNumber > maxLimit) {
            this.setError('amount', I18n.t('amount_max_error', {amount: fixed2int(paymentSystem.max_limit)}));
            this.result = false;
        }
    }

    validateMinLimit(paymentSystem: PaymentSystemInterface): void {
        const {amount} = this.object;
        const minLimit = Number(paymentSystem.min_limit);
        const amountNumber = Number(amount);

        if (amountNumber < minLimit) {
            this.setError('amount', I18n.t('amount_min_error', {amount: fixed2int(paymentSystem.min_limit)}));
            this.result = false;
        }
    }

    validatePayzenDetails(paymentSystem: PaymentSystemInterface): void {
        const walletDetail = this.object.wallet_attributes.wallet_detail_attributes;

        if (paymentSystem.cashout_details_ui_component && (!walletDetail || walletDetail && !walletDetail.currency)) {
            Object.keys(this.object.cashout_detail_attributes).forEach(d => {
                if (!new PresenceValidation(this.object.cashout_detail_attributes[d]).execute()) {
                    this.setError(`cashout_detail.${d}`, I18n.t('required_field'));
                    this.result = false;
                }
            });
        }
    }

    validateScreenshot(paymentSystem: PaymentSystemInterface): void {
        if (paymentSystem.payment_screenshot && !this.object.screenshot && !this.object.selectedWallet) {
            this.setError('qr_code_document', I18n.t('qr_required'));
            this.result = false;
        }
    }

    validations(): void {
        this.presence('amount', {message: I18n.t('required_field')});
        this.isAmount('amount',
            {message: `${I18n.t('user.amount_label')} ${I18n.t('errors.messages.not_an_integer')}`});

        this.validatePaymentSystems();
    }
}
