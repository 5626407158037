import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import UploadButton from 'app/components/DocumentsUpload/UploadButton';
import {disabledButton} from 'app/components/deposit/util/userVerifiedDocuments';
import UserVerifiedDocumentInterface from 'app/interfaces/UserVerifiedDocumentInterface';
import {inject, observer} from 'mobx-react';

interface ScreenshotLoaderProps {
    id: number,
    user_verified_documents: UserVerifiedDocumentInterface[],
    loadCurrentP2pDeposits: () => void
}

@inject('p2pDepositsStore')
@observer
export default class ScreenshotLoader extends Component<ScreenshotLoaderProps> {
    onUpload = (): void => this.props.loadCurrentP2pDeposits();

    render(): ReactNode {
        const disabledLoaderButton = disabledButton(this.props.user_verified_documents);

        return <div className="loader-screenshot">
            <div className="loader-description">
                <span className={`icon-loading-screenshot${disabledLoaderButton ? ' active' : ''}`}>
                    <svg className="svg-default icon-timer">
                        <use xlinkHref="#svg-document-timer"/>
                    </svg>
                    <svg className="svg-default icon-done">
                        <use xlinkHref="#svg-document-done"/>
                    </svg>
                </span>
                <div className="description">
                    {disabledLoaderButton
                        ? <p>{I18n.t('your_screenshot_uploaded_successfully')}</p>
                        : <p>{I18n.t('you_can_speed_up_the_process')}</p>}
                </div>
            </div>
            <div className="loader-button">
                <UploadButton
                    created_for_id={this.props.id}
                    created_for_type="P2PDepositInvoice"
                    maxDocumentsLoaded={disabledLoaderButton}
                    justButton
                    label={I18n.t('upload')}
                    onUpload={this.onUpload}
                />
            </div>
        </div>;
    }
}
