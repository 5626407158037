import {windowWidth} from './windowWidth';

const MAX_WINDOW_WIDTH = 1280;
const SCROLL_WIDTH = 6;
const MIDDLE = 2;

export const defaultPositionX = (): number => {
    const width = windowWidth();
    return width > MAX_WINDOW_WIDTH ? (windowWidth() - MAX_WINDOW_WIDTH - SCROLL_WIDTH) / MIDDLE : 0;
};
