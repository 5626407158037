import {action, makeObservable, override} from 'mobx';
import BaseStore from 'stores/BaseStore';

export default abstract class SingleRecordStore<T> extends BaseStore<T> {
    constructor() {
        super();
        makeObservable(this);
        this.setCollection(null);
    }

    @override collection: T = null;

    @action
    updateElementCollection(item: T): void {
        this.setCollection(item);
    }

    @action
    deleteElementCollection(): void {
        this.setCollection(null);
    }

    @override
    setCollection(collection: T): void {
        this.collection = collection;
    }

    get record(): T {
        return this.collection;
    }
}
