import React, {PureComponent, ReactNode} from 'react';
import CurrentDeposit from './CurrentDeposit';
import SystemCard from 'app/components/deposit/SystemCard';
import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';
import HelpSupport from 'app/components/deposit/form/HelpSupport';
import P2pDepositSteps from 'app/components/deposit/p2p/P2pDepositSteps';
import CurrentP2pDepositInterface from 'app/interfaces/CurrentP2pDepositInterface';

interface CurrentP2pDepositProps {
    current_p2p_deposit: CurrentP2pDepositInterface
    firstDeposit: boolean
    system: PaymentSystemInterface
}

export default class CurrentP2pDeposit extends PureComponent<CurrentP2pDepositProps> {
    render(): ReactNode {
        const {system, current_p2p_deposit: {working, waiting, user_verified_documents}} = this.props;
        return <div className="left-inner-padding left-inner-padding_first left-inner-padding_mobile">
            <div className="box-content box-content_gray box-content_mobile">
                <div className="card-transfer">
                    <div className="face">
                        <div className={'deposit-form__logo payment-systems__deposit__item ' +
                            'payment-systems__deposit__item__selectedSystem'}
                        >
                            <SystemCard system={system}/>
                        </div>
                    </div>
                    <div className="body">
                        <CurrentDeposit system={system} />
                    </div>
                </div>
            </div>
            {(waiting || working) &&
            <P2pDepositSteps waiting={waiting} user_verified_documents={user_verified_documents}/>}
            <div className="box-p2p_type-1"><HelpSupport/></div>
        </div>;
    }
}
