import React, {Component, ReactNode} from 'react';
import UserMenuBalance from 'app/components/user/menu_balance';
import BonusInterface from 'app/interfaces/BonusInterface';

interface UserBalanceProps {
    balance?: string;
    bonus_balance?: string;
    active_bets_sum?: string;
    bonuses?: BonusInterface[];
    play_money?: string;
    points?: number;
    verified_status?: string;
    noWrap?: boolean;
    onClose?: void;
}

export default class UserBalance extends Component<UserBalanceProps> {
    render(): ReactNode {
        const res = <UserMenuBalance {...this.props}/>;
        return this.props.noWrap ? res : <div className="user-menu__balance">{res}</div>;
    }
}
