import React, {PureComponent} from 'react';
import $ from 'jquery';
import I18n from 'app/i18n';
import InputError from 'app/components/deposit/InputError';

export default class FieldWrapSelectPicker extends PureComponent {
    select = React.createRef();

    componentDidMount() {
        $(this.select.current)
            .chosen({
                allow_single_deselect: true,
                search_contains: true
            })
            .change(e => this.props.handleOnChange(this.props.stateName, e.target.value));
    }

    handleSelect = e => this.props.handleOnChange(this.props.stateName, e.target.value);

    componentDidUpdate = () => $(this.select.current).trigger('chosen:updated');

    render() {
        const {
            errors,
            currentValue,
            attrValue,
            attrDescription,
            collection,
            stateName,
            disabled,
            translation_key
        } = this.props;
        const error = errors[`cashout_detail.${stateName}`];
        return <div className="form__row">
            <div className="form__row-item">
                <label className="form__row-label" htmlFor={stateName}>{I18n.t(translation_key || stateName)}</label>
                <div className={`field field__select ${error ? 'field-error' : ''}`}>
                    <div id={stateName} className="field__wrap">
                        <select
                            ref={this.select}
                            className="select-bank"
                            value={currentValue}
                            disabled={disabled}
                            name={stateName}
                            onChange={this.handleOnChange}>
                            {[
                                ...[
                                    {
                                        [attrDescription]: '',
                                        [attrValue]: ''
                                    }
                                ], ...collection
                            ].map(data => <option
                                key={data[attrValue]}
                                value={data[attrValue]}
                            >
                                {data[attrDescription]}
                            </option>)}
                        </select>
                        <div className="field__select-arrow"/>
                        {error && <InputError error={error}/>}
                    </div>
                </div>
            </div>
        </div>;
    }
}
