import React from 'react';
import I18n from 'app/i18n';
import {amountWithSign} from 'app/utils/format';
import {fixed2} from 'app/utils/fixed';

export default class UserStats extends React.PureComponent {
    render() {
        const {
            data: {
                turnover30, turnover30_live, turnover90, turnover90_live,
                roi30, roi30_live, roi30p, roi30p_live,
                roi90, roi90_live, roi90p, roi90p_live
            }
        } = this.props;

        if (roi30 === undefined) return null;

        return (
            <div className="profile__stat" style={{'flex-direction': 'column'}}>
                <div className="profile__stat-wrap">
                    <strong>{I18n.t('roi3090days')}</strong>
                    <span>{amountWithSign(roi30)} ({ fixed2(roi30p) }%) / {amountWithSign(roi90)} ({ fixed2(roi90p) }%)</span>
                </div>
                <div className="profile__stat-wrap">
                    <strong>{I18n.t('turnover3090days')}</strong>
                    <span>{amountWithSign(turnover30)} / {amountWithSign(turnover90)}</span>
                </div>
                <div className="profile__stat-wrap">
                    <strong>{I18n.t('roi3090days_live')}</strong>
                    <span>{amountWithSign(roi30_live)} ({ fixed2(roi30p_live) }%) / {amountWithSign(roi90_live)} ({ fixed2(roi90p_live) }%)</span>
                </div>
                <div className="profile__stat-wrap">
                    <strong>{I18n.t('turnover3090days_live')}</strong>
                    <span>{amountWithSign(turnover30_live)} / {amountWithSign(turnover90_live)}</span>
                </div>
            </div>
        );
    }
}
