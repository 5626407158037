import PayzenBaseClass from '../payzen/PayzenBaseClass';
import {ResponseType} from '../form/DepositForm';

export default class ObmenkaDepositClass extends PayzenBaseClass {
    instant = (): boolean => true;

    amountParam = (response: ResponseType): string => {
        const parameters = response.start_result.parameters as Array<{name: string, value: string}>;
        return parameters.find(p => p.name === 'AMOUNT').value;
    };

    instantDeposit = (response: ResponseType): void => {
        const {start_result} = response;

        start_result.parameters = Object.entries(start_result.parameters).map(([name, value]) => ({name, value}));

        this.handleSuccessResponse(start_result, null);
    };
}
