import React, {PureComponent} from 'react';
import {isUSD} from 'app/utils/ShopItemPriceFormat';

interface ShopItemDiscountProps {
    sale: string
    sale_money: string
    currency: string
}

export default class ShopItemDiscount extends PureComponent<ShopItemDiscountProps> {
    render(): React.ReactNode {
        const {sale, sale_money, currency} = this.props;

        if (!(sale && !isUSD(currency)) && !(sale_money && isUSD(currency))) {
            return null;
        }
        return <div className="sale">
            <svg height="24px" width="24px">
                <use xlinkHref="#svg-sale-label"/>
            </svg>
            <div className="sale__text">
                <span>{isUSD(currency) ? sale_money : sale}%</span>
            </div>
        </div>;
    }
}
