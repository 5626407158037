import React, {MouseEvent, PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {betMoney} from 'app/utils';
import HistoricalDepositInterface from 'app/interfaces/HistoricalDepositInterface';
import FixedButton from 'app/components/deposit/ofelospay/FixedButton';
import FailedButton from 'app/components/deposit/ofelospay/FailedButton';
import Post from 'app/utils/Post';
import {modalConfirm} from 'app/utils/modals/popups';

interface HistoricalDepositProps {
    deposit: HistoricalDepositInterface
    ofelospay: boolean
}

export class DepositHistoryItem extends PureComponent<HistoricalDepositProps> {
    changeDeposit = (url: string): void => {
        new Post({url}).execute();
    };

    ofelospayFixDeposit = (): void => {
        const {id} = this.props.deposit;
        this.changeDeposit(`/deposits/ofelospay_deposits/${id}/fix`);
    };

    ofelospayCancelDeposit = (e: MouseEvent<HTMLAnchorElement>): void => {
        e.preventDefault();
        const {deposit} = this.props;
        const {id, auto_waiting} = deposit;

        if (auto_waiting) {
            return;
        }
        modalConfirm(
            I18n.t('undo_deposit', {amount: betMoney(deposit)}),
            () => this.changeDeposit(`/deposits/ofelospay_deposits/${id}/cancel`)
        );
    };

    render(): ReactNode {
        const {deposit, ofelospay} = this.props;
        const {auto_waiting, id, date, external_url, not_valid,
            operator, system, gift_amount, status, waiting} = deposit;
        return <tr key={id}>
            <td>
                <span className="value-date">{date}</span>
            </td>
            <td>
                <p className="section">
                    <span className="value-id">{id}</span>
                </p>
                <p className="section">
                    <span className="value-operator">{operator}</span>
                </p>
            </td>
            <td>
                <span className="value-payment-system">{system}</span>
            </td>
            <td>
                <span className="value-gift-amount">{gift_amount || betMoney(deposit)}</span>
            </td>
            {ofelospay
                ? <>
                    <td>
                        <span className="value-status">{status}</span>
                    </td>
                    {external_url && <td>
                        <div className="line-group">
                            <a
                                href={external_url}
                                rel="noreferrer"
                                target="_blank"
                                className="button-adaptive button-adaptive_transparent"
                            >
                                {I18n.t('show_now')}
                            </a>
                        </div>
                        {(waiting || not_valid) && <div className="line-group">
                            {not_valid &&
                            <FixedButton deposit={deposit} fixDeposit={this.ofelospayFixDeposit}/>}
                            {!auto_waiting &&
                            <FailedButton deposit={deposit} cancelDeposit={this.ofelospayCancelDeposit}/>}
                        </div>}
                    </td>}

                </>
                : <td colSpan={2}>
                    <span className="value-status">
                        {I18n.t('user.status_completed')}
                    </span>
                </td>}
        </tr>;
    }
}
