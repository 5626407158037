import React, {Fragment, PureComponent, ReactNode} from 'react';
import {goToMyHero} from 'app/utils/app_links';
import {i18n_t} from 'app/i18n';
import ConvertButton from 'app/components/my_profile/artifacts/artifact_popup/compendium/ConvertButton';
import {Provider} from 'mobx-react';
import {promoActionStore} from 'app/store/global';

class ConversionCard extends PureComponent {
    render(): ReactNode {
        if (!promoActionStore.promoAction) {
            return null;
        }

        return (
            <div className="compendium-grid__item compendium-grid__item--conversion">
                <div className="compendium-grid__item-inner compendium-grid__item-inner--conversion">
                    <div className="compendium-grid__item-header">
                        <svg height="27px" width="27px">
                            <use xlinkHref="#svg-scatter-graph"/>
                        </svg>
                    </div>
                    <div className="compendium-grid__item-question">
                        <Fragment>
                            {i18n_t('scatter.you_can')}
                        </Fragment>
                    </div>

                    <div className="compendium-grid__item-link">
                        <Provider promoActionStore={promoActionStore}>
                            <ConvertButton onClickHandler={goToMyHero}/>
                        </Provider>
                    </div>
                </div>
            </div>
        );
    }
}

export default ConversionCard;
