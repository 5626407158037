import React, {Component, ReactNode, RefObject} from 'react';
import I18n from 'app/i18n';

interface RowPhoneProps {
    rowClass: string;
    errorHint: ReactNode;
    phone: string;
    cleanError: (field: string) => void;
    onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export class RowPhone extends Component<RowPhoneProps> {
    phoneInput: RefObject<HTMLInputElement> = React.createRef();

    cleanError = (): void => {
        this.props.cleanError('phone');
    };

    render(): ReactNode {
        const {rowClass, errorHint, phone, onChangeHandler} = this.props;
        return <>
            <div className={rowClass}>
                <label className="field-label" htmlFor="field-login">{I18n.t('phone_number')}</label>
                <div className="field">
                    <input
                        ref={this.phoneInput}
                        type="text"
                        pattern="[0-9+]*"
                        className="field__input"
                        id="field-phone"
                        maxLength={20}
                        minLength={7}
                        name="phone"
                        defaultValue={phone}
                        onKeyUp={this.cleanError}
                        onChange={onChangeHandler}
                    />
                </div>
                {errorHint}
            </div>
        </>;
    }
}
