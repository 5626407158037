import React, {Component, ReactNode} from 'react';
import {PopupDialog} from 'app/utils/modals/PopupDialog';
import {translatedName} from 'app/utils/translations_helper';
import ScrollPopupProps from 'app/interfaces/popups/ScrollPopupProps';
import PopupContent from './scroll_popup/PopupContent';
import {onCloseItemIntentory} from 'app/utils/CloseInventoryPopup';
import {modalsStore} from 'app/store/global';

export default class ScrollPopup extends Component<ScrollPopupProps> {
    closePopup = (): void => modalsStore.hideModal(this.props.popupId);

    onClose = (): void => onCloseItemIntentory();

    render(): ReactNode {
        const {
            recipe,
            item_type,
            total_inventory_items,
            total_sending_prohibited_items,
            public_view,
            id,
            useable,
            tradable,
            salable,
            transferable,
            sending_prohibited,
            user_received_period,
            in_market,
            popupId
        } = this.props;

        return <>
            <PopupDialog id={popupId} onClose={this.onClose}>
                <div className="popup__content">
                    <h2 className="popup__header">
                        {translatedName(recipe)}
                    </h2>
                    <PopupContent
                        closePopup={this.closePopup}
                        recipe={recipe}
                        item_type={item_type}
                        total_inventory_items={total_inventory_items}
                        total_sending_prohibited_items={total_sending_prohibited_items}
                        public_view={public_view}
                        id={id}
                        popupId={popupId}
                        useable={useable}
                        tradable={tradable}
                        salable={salable}
                        transferable={transferable}
                        user_received_period={user_received_period}
                        sending_prohibited={sending_prohibited}
                        in_market={in_market}
                    />
                </div>
            </PopupDialog>
        </>;
    }
}
