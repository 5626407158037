import React, {Component, ReactNode} from 'react';
import I18n from 'app/i18n';
import CurrencyInput from 'react-currency-input-field';
import P2pDepositsStoreProps from 'app/store/P2pDepositsStoreProps';
import {inject, observer} from 'mobx-react';

@inject('p2pDepositsStore', 'depositsStore')
@observer
export default class AvailableDepositsFilter extends Component<P2pDepositsStoreProps> {
    changeMinAmountHandler = (value: string | undefined): void => {
        this.props.p2pDepositsStore.changeFilterMinAmount(value);
    };

    changeMaxAmountHandler = (value: string | undefined): void => {
        this.props.p2pDepositsStore.changeFilterMaxAmount(value);
    };

    render(): ReactNode {
        const {min_amount, max_amount} = this.props.p2pDepositsStore;

        if (!this.props.p2pDepositsStore.currentPaymentSystem) {
            return null;
        }

        return <div className="form-egb filter-amount-p2p">
            <div className="row">
                <label className="label-title">
                    {I18n.t('amount_from')}
                </label>
            </div>
            <div className="row">
                <CurrencyInput
                    className="field__input field-number__input"
                    value={min_amount}
                    onValueChange={this.changeMinAmountHandler}
                />
                <label className="label-title">
                    {I18n.t('to')}
                </label>
                <CurrencyInput
                    className="field__input field-number__input"
                    value={max_amount}
                    onValueChange={this.changeMaxAmountHandler}
                />
            </div>
        </div>;
    }
}
