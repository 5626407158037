import {TableStore} from 'app/store/TableStore';
import React, {Component, ReactNode} from 'react';
import PlayPage from 'app/components/pages/play';
import {ScrolledPageContent} from 'app/components/page_content';
import {inject, observer} from 'mobx-react';
import {PageContentStore} from 'app/store/PageContentStore';
import {E_SPORT_MAIN_URL} from 'app/utils/esport';
import LineInterface from 'app/interfaces/LineInterface';
import withRouter from 'app/utils/navigate/customWithRouter';

interface EsportShowProps {
    pageContentStore?: PageContentStore
    tableStore?: TableStore
    params?: {
        id: string
    }
}

export interface EsportContent {
    title?: string
    content?: string
    filter?: string
    esport_bet?: {
        bet: LineInterface
        user_bets: LineInterface[]
    }
}

class EsportShow extends Component<EsportShowProps> {
    esport = (): EsportContent => this.props.pageContentStore.contentByContentKey('esport') || {};

    render(): ReactNode {
        return <ScrolledPageContent contentUrl={`${E_SPORT_MAIN_URL}/${this.props.params.id}`}>
            <div className="title">{this.esport().title}</div>
            <div className="content__holder" dangerouslySetInnerHTML={{__html: this.esport().content}}/>
            <PlayPage.Table
                filter={PlayPage.simpleBetsFilter}
                customFilters={this.esport().filter}
                tableStore={this.props.tableStore}
                hideFilters
                seo
            />
        </ScrolledPageContent>;
    }
}

export default withRouter(inject('tableStore', 'pageContentStore')(observer(EsportShow)));
