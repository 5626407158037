import {userStore} from 'app/store/global';
import Post from 'app/utils/Post';

export const sendEmailConfirmation = (): void => {
    new Post({
        url: '/user/send_confirmation'
    })
        .execute()
        .then(response => response.json())
        .then(response => {
            userStore.update({user: response});
        });
};
