import React, {Component} from 'react';
import I18n from 'app/i18n';
import {isOpenedContainer, openContainer} from 'app/utils';
import $ from 'jquery';
import TabLink from './tab_link';
import TabContent from './tab_content';
import doubleArrDownSvg from 'images/svg_icons/double-arr-down';
import SmartLoader from 'app/components/reusable/SmartLoader';

export default class Crafting extends Component {
    constructor(props) {
        super(props);
        this.container = React.createRef();
        this.state = {opened: false, selectedRecipe: null, recipes: [], loading: true};
    }

    componentDidMount = () => this.handleCraftableRecipeAjax();

    componentDidUpdate(prevProps, prevState) {
        if (this.state.opened && !prevState.opened) {
            this.setState({loading: true});

            this.handleCraftableRecipeAjax()
                .done(() => this.setState({loading: false}));
        }
    }

    handleCraftableRecipeAjax = () => $.get('/artifacts/crafting', data => {
        this.setState({selectedRecipe: data.recipes[0], ...data});
    });

    toggleHeaderHandler = () => openContainer(this);

    handleTabLinkClick = (recipe, e) => {
        e && e.preventDefault();
        this.setState({selectedRecipe: recipe});
    };

    render() {
        const {recipes} = this.state;
        return recipes.length > 0 && <div className="crafting">
            <div className="crafting__heading" onClick={this.toggleHeaderHandler}>
                <div className="crafting__title">{I18n.t('crafting')}</div>
                <div className="crafting__controls">
                    <button data-js-opener="crafting-data"
                        className={`act crafting__opener${ isOpenedContainer(this)}`}>
                        <img src={doubleArrDownSvg} height="12px" width="12px"/>
                    </button>
                </div>
            </div>
            <div ref={this.container} style={{display: this.state.opened ? 'block' : 'none'}}>
                <div className="crafting__content">
                    <div className="crafting__items">
                        {this.state.loading
                            ? <SmartLoader/>
                            : <div className="tabs tabs--crafting tabs--vertical">
                                <div className="tabs__header tabs--vertical__header">
                                    {recipes.map(r => <TabLink
                                        key={r.id}
                                        recipe={r}
                                        handleTabLinkClick={this.handleTabLinkClick}
                                        selected={this.state.selectedRecipe === r}
                                    />)}
                                </div>
                                <div className="tabs__content tabs--vertical__content">
                                    <TabContent recipe={this.state.selectedRecipe}/>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </div>;
    }
}
