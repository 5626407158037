import BaseStrategy from 'app/components/reusable/WalletSelect/BaseStrategy';
import I18n from 'app/i18n';
import {ELEMENT_NOT_FOUND} from 'app/utils/Consts';

export default class MultipleStrategy extends BaseStrategy {
    get selectedItems(): string[] {
        return this.select.props.value as string[];
    }

    selectValue(value: string): string[] {
        const selectedItems = this.selectedItems && this.selectedItems.slice() || [];
        const valueIndex = selectedItems.findIndex(item => item === value);

        if (valueIndex === ELEMENT_NOT_FOUND) {
            selectedItems.push(value);
        } else {
            selectedItems.splice(valueIndex, 1);
        }

        return selectedItems;
    }

    isCheckedItem(value: string): boolean {
        return Boolean(this.selectedItems && this.selectedItems.find(item => item === value));
    }

    selectedItemsMessage = (): string => I18n.t('selected_objects', {
        count: this.select.props.value.length
    });

    placeHolderMessage(): string {
        return this.select.props.value.length === 0
            ? this.select.props.placeholder_text_multiple
            : this.selectedItemsMessage();
    }

    emptyItemsMessage = (): string => this.select.props.empty_collection_text
        ? this.select.props.empty_collection_text
        : I18n.t('third_level_sort_blank');
}
