import React, {PureComponent} from 'react';
import FormProgressBar from '../bet_form/progress_bar';
import {flagImage} from 'app/utils';
import {lineFullNick, lineGamer} from 'app/lines/line_helpers';
import {fixed3} from 'app/utils/fixed';

export default class BetProgress extends PureComponent {
    handleAfterFinish = () => setTimeout(this.props.onClose, 10 * 1000);

    render() {
        const {betId, coef, delayTime, line, parentLine, on} = this.props;
        const gamer = lineGamer(line, on, parentLine);

        return <div className="notification__content">
            <div className="notification__content-wrap">
                <div className="notification__text">
                    {flagImage(gamer.flag)} {lineFullNick(line, on, parentLine)} {fixed3(coef)}
                    <FormProgressBar
                        afterFinish={this.handleAfterFinish}
                        delayTime={delayTime}
                        id={betId}
                    />
                </div>
            </div>
        </div>;
    }
}
