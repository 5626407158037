import React, {Component, ReactNode} from 'react';
import {inject, observer} from 'mobx-react';
import BetSlipMenuController from 'app/components/bet_slip/menu/BetSlipMenuController';
import {isDesktop} from 'app/utils';
import {
    BET_SLIP_POSITION_X,
    BET_SLIP_POSITION_Y
} from 'app/utils/Consts';
import BetSlipInterface from 'app/interfaces/BetSlipInterface';
import {isShowAppNotification} from 'app/utils/isShowAppNotification';
import Draggable, {DraggableData, DraggableEvent} from 'react-draggable';
import {defaultPositionX} from './utils/defaultPositionX';
import {defaultPositionY} from './utils/defaultPositionY';
import {isBetPage} from 'app/utils/IsBetPage';
import {getPositionX} from './utils/getPositionX';
import {getPositionY} from './utils/getPositionY';
import ButtonPlaceBetContainer from 'app/components/bet_slip/place_bet/ButtonPlaceBet.container';
import BetSlipMinimizeContainer from 'app/components/bet_slip/minimize_bet_slip/BetSlipMinimize.container';
import BetSlipMenuTabsContainer from 'app/components/bet_slip/menu/BetSlipMenuTabs.container';
import {BetSlipBodyContainer} from 'app/components/bet_slip/BetSlipBody/BetSlipBody.container';

const BET_SLIP_CLASS_VISIBLE = 'visible';
const HEADER_HEIGHT = 70;

@inject('controller', 'betSlipStore', 'artifactBetStore')
@observer
export default class BetSlip extends Component<BetSlipInterface> {
    state = {
        height: 0,
        newLeft: 0,
        newTop: 0,
        positionX: getPositionX(),
        positionY: getPositionY(),
        right: 0,
        top: 0
    };

    container: React.RefObject<HTMLDivElement> = React.createRef();

    resizeObserver = null;

    constructor(props: BetSlipInterface) {
        super(props);

        props.controller.betSlip = this;
    }

    componentDidUpdate = (prevProps: BetSlipInterface): void => {
        const {controller} = this.props;
        controller.removedScrollBody();
        controller.betSlipDidUpdate(this);

        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.showMobileChecker();
        }
    };

    componentDidMount = (): void => {
        this.props.artifactBetStore.resetArtifacts();
        const {setDraggableBounds} = this.props.controller;
        setDraggableBounds();
        this.resizeObserver = new MutationObserver(setDraggableBounds);
        this.resizeObserver.observe(this.container.current, {childList: true, subtree: true});
        window.addEventListener('resize', setDraggableBounds);
        this.showMobile();
        document.addEventListener('keydown', this.escFunction);
        this.props.betSlipStore.clearClearFormTimer();
    };

    componentWillUnmount = (): void => {
        this.resizeObserver?.disconnect();
        const {setDraggableBounds} = this.props.controller;
        window.removeEventListener('resize', setDraggableBounds);
        document.body.classList.remove('body-overflow');
        document.removeEventListener('keydown', this.escFunction);

        const {clear, reset} = this.props.betSlipStore;
        clear();
        reset();
    };

    escFunction = (event: KeyboardEvent): void => {
        const {controller: {removeOnclick, bets, removeAllGames}} = this.props;

        if (event.key === 'Escape') {
            if (removeAllGames) {
                removeAllGames();
            } else {
                removeOnclick(bets && bets[0]?.selectedLine);
            }
        }
    };

    showMobile = (): void => {
        if (isDesktop()) {
            return;
        }
        this.container.current.classList.add(BET_SLIP_CLASS_VISIBLE);
    };

    showMobileChecker = (): void => {
        if (isDesktop()) {
            return;
        }

        const {classList} = this.container.current;
        const {controller: {removedScrollBody}, betSlipStore} = this.props;

        this.setState({location_pathname: this.props.location.pathname});

        if (isBetPage()) {
            classList.add(BET_SLIP_CLASS_VISIBLE);
        } else {
            betSlipStore.setMinimize(true);
            classList.remove(BET_SLIP_CLASS_VISIBLE);
        }

        removedScrollBody();
    };

    handleStop = (e: DraggableEvent, data: DraggableData): void => {
        localStorage.setItem(BET_SLIP_POSITION_X, (data.x || defaultPositionX()).toString());
        localStorage.setItem(BET_SLIP_POSITION_Y, ((data.y || defaultPositionY()) + this.state.newTop).toString());
    };

    render(): ReactNode {
        const {newTop, positionX, positionY, right,
            top} = this.state;
        const {betSlipStore: {minimize, showForm, updateTime}} = this.props;

        return showForm && <Draggable defaultPosition={{x: Number(positionX), y: Number(positionY)}}
            handle={isDesktop() ? '.draggable-coupon' : 'strongMobile'}
            onStop={this.handleStop} bounds={{bottom: top, left: 0, right, top: HEADER_HEIGHT - newTop}}>
            <div ref={this.container} className="bet-slip-draggable">
                <div hidden={true}>{updateTime}</div>
                {minimize
                    ? <BetSlipMinimizeContainer/>
                    : <div className={`bet-slip${isShowAppNotification() ? ' app-install' : ''}`}>
                        <div className="bet-slip__header">
                            <BetSlipMenuTabsContainer/>
                            <BetSlipMenuController/>
                        </div>
                        <div className="bet-slip__body">
                            <BetSlipBodyContainer/>
                        </div>
                        <div className="bet-slip__footer">
                            <ButtonPlaceBetContainer/>
                        </div>
                    </div>
                }
            </div>
        </Draggable>;
    }
}
