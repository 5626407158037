import React from 'react';
import PayzenCashoutDetails from 'app/components/cashout/PayzenCashoutDetails';
import FieldWrapInput from 'app/components/cashout/FieldWrapInput';

export default class IndiaImpsDetails extends PayzenCashoutDetails {
    state = {
        bank_account_number: '',
        customer_name: '',
        ifsc_code: ''
    };

    render() {
        const {disabled, errors} = this.props;

        return <div className="cashout__field field">
            <FieldWrapInput
                stateName="customer_name"
                handleOnChange={this.handleOnChange}
                value={this.state.customer_name}
                disabled={disabled}
                errors={errors}
            />

            <FieldWrapInput
                stateName="bank_account_number"
                handleOnChange={this.handleOnChange}
                value={this.state.bank_account_number}
                disabled={disabled}
                errors={errors}
            />

            <FieldWrapInput
                stateName="ifsc_code"
                handleOnChange={this.handleOnChange}
                value={this.state.ifsc_code}
                disabled={disabled}
                errors={errors}
            />
        </div>;
    }
}
