import {action, makeObservable, observable} from 'mobx';
import {ajaxError, getLinkPath, slowConnection} from 'app/utils';
import $ from 'jquery';

export class OrdersStore {
    constructor() {
        makeObservable(this);
    }

    @observable
        orders = [];

    @observable
        recipe = {};

    @observable
        requested_artifacts_data = {};

    @observable
        paginate = {};

    @action
    updateOrders({orders, paginate, recipe, requested_artifacts_data}) {
        if (orders) {
            this.orders = orders;
        }
        if (paginate) {
            this.paginate = paginate;
        }
        if (recipe) {
            this.recipe = recipe;
        }
        if (requested_artifacts_data) {
            this.requested_artifacts_data = requested_artifacts_data;
        }
    }

    @action
    request(recipe, recipeOrderParams, successCallback = null) {
        slowConnection($.ajax(getLinkPath('/artifact_orders/request'), {
            data: {
                artifact_order: recipeOrderParams,
                marketplace_id: recipe.id
            },
            error: ajaxError,
            success: response => {
                this.updateOrders(response);
                successCallback && successCallback(response);
            },
            type: 'POST'
        }));
    }
}
