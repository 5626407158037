import React from 'react';
import {NavLink} from 'react-router-dom';
import {getClassNameActiveLink, getTableLinkPath} from 'app/utils/link_helper';
import {Outlet} from 'react-router';
import I18n from 'app/i18n';

const MainGrid = (): React.JSX.Element => <>
    <div className="tabs tabs--body">
        <div className="tabs__header">
            <div className="tabs__inner__header">
                <NavLink
                    to={getTableLinkPath('/grid/1')}
                    className={getClassNameActiveLink}>
                    {I18n.t('grid_tab_first')}
                </NavLink>
                <NavLink
                    to={getTableLinkPath('/grid/2')}
                    className={getClassNameActiveLink}>
                    {I18n.t('grid_tab_second')}
                </NavLink>
            </div>
        </div>
    </div>
    <div className="container-main-left">
        <Outlet />
    </div>
</>;

export default MainGrid;
