import React, {ReactNode} from 'react';
import PokerPlace from 'app/components/poker/poker_players/poker_places/PokerPlace';
import {inject, observer} from 'mobx-react';
import {PokerController} from 'app/controllers/PokerController';
import {PokerStore} from 'app/store/PokerStore';
import PlayerInterface from 'app/interfaces/poker/PlayerInterface';

interface Props {
    players?: PlayerInterface[]
    max_players: number
    count_players: number
    dealer_place: number
    action_index: number
    pokerStore?: PokerStore
    controller?: PokerController
}

@inject('pokerStore', 'controller')
@observer
export default class PokerPlayers extends React.Component<Props> {
    sitIn(): void {
        this.props.controller.sitIn(this.props.pokerStore.currentTable.table_id);
    }

    render(): ReactNode {
        const {players, dealer_place, count_players, max_players, action_index} = this.props;
        const {player} = this.props.pokerStore;
        const availablePlaces = Array.from({length: max_players - count_players}, (_, i) => i + count_players);

        return <>
            {players && players.map((p, i) => <PokerPlace
                key={`p${i}`}
                position={i + 1}
                active={i === action_index}
                player={p.name === player?.name ? player : p}
                isBigBlind={dealer_place === i}
                satOut={p.sat_out}
            />)}
            {!player && availablePlaces.map((p, i) => <PokerPlace key={`f${i}`}
                onSitIn={this.sitIn.bind(this)}
                position={p + 1}
                isBigBlind={false}
            />)}
            {/* <PokerPlaceFirst/>
            <PokerPlaceSecond/>
            <PokerPlaceThird/>
            <PokerPlaceFourth/>
            <PokerPlaceFifth/>
            <PokerPlaceSixth/> */}
        </>;
    }
}
