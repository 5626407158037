import {i18n_t} from 'app/i18n';
import {Compendium} from 'app/components/promo_action/compendium';
import {inject, observer} from 'mobx-react';

class PromoActionCompenigma extends Compendium {
    static cookieName = 'compenigma-menu';

    static title = () => i18n_t('compenigma.name');
}

export default inject('currentTimeStore')(observer(PromoActionCompenigma));
