/* eslint-disable complexity */
import React, {Component, FormEvent, ReactNode} from 'react';
import I18n from 'app/i18n';
import SmartLabelTooltip from 'app/components/reusable/SmartLabelTooltip';
import {inject, observer} from 'mobx-react';
import {IncubatorCreateLineController} from 'app/controllers/IncubatorCreateLineController';
import {IncubatorShiftStore} from 'app/store/IncubatorShiftStore';
import IncubatorShift from 'app/components/pages/incubator/IncubatorShift';
import IncubatorLineLeft from 'app/components/pages/incubator/line/IncubatorLineLeft';
import IncubatorLineMiddle from 'app/components/pages/incubator/line/IncubatorLineMiddle';
import IncubatorLineRight from 'app/components/pages/incubator/line/IncubatorLineRight';
import {IncubatorLineNewForm} from 'app/components/pages/incubator/IncubatorLineNewForm';
import IncubatorGoBack from 'app/components/pages/incubator/IncubatorGoBack';
import IncubatorMyLines from 'app/components/pages/incubator/IncubatorMyLines';
import {IncubatorShiftController} from 'app/controllers/IncubatorShiftController';
import {incubatorTopUpModal} from './IncubatorTopUpModal';
import {modalAlert, modalConfirm} from 'app/utils/modals/popups';
import {DRAW, WHITE, BLUE} from 'app/lines/line_helpers';
import {MIN_PLEDGE} from 'app/utils/Consts';
import IncubatorPledgeAdd from 'app/components/pages/incubator/IncubatorPledgeAdd';
import {isDesktop, userSignedIn} from 'app/utils';
import IncubatorAmountInput from 'app/components/pages/incubator/IncubatorAmountInput';
import {IncubatorCurrentPledge} from 'app/components/pages/incubator/IncubatorCurrentPledge';
import IncubatorLineFormPanel from 'app/components/pages/incubator/IncubatorLineFormPanel';
import IncubatorLineFormLineType from 'app/components/pages/incubator/IncubatorLineFormLineType';
import IncubatorLineFormChangeCoef from 'app/components/pages/incubator/IncubatorLineFormChangeCoef';
import IncubatorLineTimer from 'app/components/pages/incubator/line/IncubatorLineTimer';
import IncubatorMobileStream from 'app/components/pages/incubator/IncubatorMobileStream';
import IncubatorLineFormLineStream from 'app/components/pages/incubator/IncubatorLineFormLineStream';
import IncubatorLineFormLineGame from 'app/components/pages/incubator/IncubatorLineFormLineGame';
import {GamesStore} from 'app/store/GamesStore';
import {ElementWithError} from 'app/components/reusable/ElementWithError';
import GameInterface from 'app/interfaces/GameInterface';
import withRouter from 'app/utils/navigate/customWithRouter';

interface Props {
    gamesStore?: GamesStore
    controller?: IncubatorCreateLineController
    incubatorShiftStore?: IncubatorShiftStore
    params?: { id?: number }
    shiftController?: IncubatorShiftController
}

interface State {
    pledge: string | number
    esports: boolean
}

const MY_LINES = 'my_lines';

@inject('gamesStore', 'controller', 'incubatorShiftStore', 'artifactStore', 'shiftController')
@observer
class IncubatorLineForm extends Component<Props, State> {
    state = {esports: true, pledge: 0};

    componentDidMount(): void {
        const {params} = this.props;
        const {game_id} = this.controller.incubatorGame.attributes;
        const game = this.props.gamesStore.findGameById(game_id) || this.props.gamesStore.lastGame();
        this.setState({esports: game.esports});
        this.controller.incubatorGame.setErrors({});

        const isEditable = Boolean(params && params.id);
        const {gamesStore: {bySportWithoutOther}} = this.props;

        if (!isEditable && bySportWithoutOther && bySportWithoutOther.length) {
            this.setOptionsByDefault();
        }
    }

    setOptionsByDefault = (): void => {
        const {game_id} = this.controller.incubatorGame.attributes;
        this.controller.onSelectGame(this.props.gamesStore.gameSelect(game_id));
        if (this.isLineTypeEditable(game_id)) {
            this.setOptionsForHeartStone(game_id);
        }
    };


    get controller(): IncubatorCreateLineController {
        return this.props.controller;
    }

    get type_id(): number {
        const {lines_type_id, game_id} = this.controller.incubatorGame.attributes;

        if (this.isLineTypeEditable(game_id)) {
            this.controller.onSelectLinesType(Number(this.controller.currentGameLineType()?.id));
            return this.controller.currentGameLineType()?.id;
        }

        return lines_type_id;
    }

    componentWillUnmount(): void {
        const {bets, incubatorGame} = this.controller;

        if (bets) {
            bets.stopListening();
        }
        incubatorGame.lineStore.stopListening();
    }

    isLineTypeEditable = (game_id: number): boolean => this.props.gamesStore
        .elementById(game_id)?.name
        .toLowerCase() === 'hearthstone';

    setOptionsForHeartStone = (game_id: number): void => {
        this.controller.onSelectGame(game_id);
        this.controller.onSelectLinesType(Number(this.controller.currentGameLineType()?.id));
    };

    handleChangePledge = (e: React.ChangeEvent<HTMLInputElement>): void => {
        this.controller.onChangePledge(e.target.value);
        this.controller.incubatorGame.clearError('pledge');
    };

    handleSubmitForm = (e: FormEvent<HTMLFormElement>): void => {
        const {params} = this.props;
        const isEditable = Boolean(params && params.id);
        const checkMinPledgeBalance = !isEditable && this.checkMinPledgeBalance(MIN_PLEDGE);

        if (checkMinPledgeBalance || this.showResultConfirm(e)) {
            e.preventDefault();
            return;
        }
        this.controller.onSubmitForm(e);
    };

    showResultConfirm = (e: FormEvent<HTMLFormElement>): boolean => {
        const {attributes, lineStore} = this.controller.incubatorGame;

        if (attributes.winner === DRAW) {
            modalAlert(I18n.t('draw_result_match'));
            return true;
        }
        if (attributes.winner !== lineStore.collection.winner) {
            modalConfirm(I18n.t('confirm_closing_line'), () => this.controller.onSubmitForm(e));
            return true;
        }
        return false;
    };

    checkMinPledgeBalance = (pledge: number): boolean => {
        const {shiftController, incubatorShiftStore} = this.props;
        const currentPledge = incubatorShiftStore.currentShift()?.pledge;

        if (currentPledge < pledge || !currentPledge || currentPledge <= 0) {
            Promise.resolve()
                .then(() => incubatorTopUpModal(
                    shiftController,
                    I18n.t('not_enough_funds_on_your_pledge_balance')
                ));
            return true;
        }
        return false;
    };

    setPledge = (pledge: string | number): void => {
        this.setState({pledge});
    };

    onClickAddPledge = (): void => {
        if (!this.checkMinPledgeBalance(this.state.pledge)) {
            this.addPledge();
        }
    };

    addPledge = (): void => {
        const {incubatorShiftStore} = this.props;
        modalConfirm({
            element: <div>{I18n.t('add_line_pledge')}<br/>
                {I18n.t('money_debited')}<br/>
                {I18n.t('current_pledge_balance')}:&nbsp;
                <IncubatorCurrentPledge
                    availablePledge={incubatorShiftStore.availablePledge()}
                    initialPledge={incubatorShiftStore.initialPledge()}/>
                <IncubatorAmountInput onChange={this.setPledge}/>
            </div>,
            react: true
        }, (): void => {
            if (!this.checkMinPledgeBalance(this.state.pledge)) {
                this.controller.addPledge(this.state.pledge);
            }
            this.setPledge(0);
        });
    };

    errorHint = (field: string): ReactNode => this.error(field);

    error = (field: string): string => this.controller.incubatorGame.getError(field);

    handleSelectLinesType = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        this.controller.onSelectLinesType(Number(e.target.value));
    };

    defineGame = (game_id: number): GameInterface => this.props.gamesStore.findGameById(game_id) ||
            this.props.gamesStore.lastGame();

    lineEditableAndUserSignedIn = (): boolean => Boolean(this.props.params && this.props.params.id) && userSignedIn();

    render(): ReactNode {
        const {controller, params} = this.props;
        const {game_id, pledge, stream_link, color, date} = controller.incubatorGame.attributes;
        const game = this.defineGame(game_id);
        const {esports} = game;
        const isEditable = Boolean(params && params.id);
        const hideEditForm = isEditable && (params.id.toString() === MY_LINES || color === WHITE);
        const lineClosed = color !== BLUE;
        const showHideIncubatorGoBack = !isDesktop() && (hideEditForm || !isEditable) || isDesktop();
        const showIncubatorMobileStream = !isDesktop() && !hideEditForm && isEditable;

        return <>
            {showIncubatorMobileStream && <IncubatorMobileStream stream_link={stream_link}/>}
            <IncubatorGoBack
                title={I18n.t(isEditable ? 'my_lines' : 'create_line')}
                hasShift={Boolean(this.props.incubatorShiftStore.currentShift())}
                showButtonCreateLine={isEditable}
                showIncubatorGoBack={showHideIncubatorGoBack}
            />
            <div className={`wrapper-incubator-create-line
                        ${isEditable ? ' wrapper-incubator-create-line_edit' : ''}`}>
                <IncubatorShift/>
                {!hideEditForm && <div className="form-incubator-create-line">
                    <form onSubmit={this.handleSubmitForm}>
                        <div className="incubator-create-line">
                            <div className="field-row">
                                <div className="field-cell">
                                    <div className="field-item column-1">
                                        <SmartLabelTooltip
                                            labelTooltip="tooltip_incubator_deposit_title"
                                            forLabelTooltip="amountIncubatorLinePledge"
                                            descriptionTooltip="tooltip_incubator_deposit"
                                            classTooltip="right-position"
                                        />
                                        <ElementWithError
                                            errorHint={this.errorHint('pledge')}
                                            className={this.error('pledge') ? 'field-error' : ''}
                                        >
                                            {isEditable
                                                ? <IncubatorPledgeAdd
                                                    pledge={pledge}
                                                    onClick={this.onClickAddPledge}/>
                                                : <>
                                                    <label className="symbol-label"
                                                        htmlFor="amountIncubatorLinePledge">
                                                        $
                                                    </label>
                                                    <input
                                                        className="field__input bold field-number__input"
                                                        id="amountIncubatorLinePledge"
                                                        disabled={isEditable}
                                                        value={pledge}
                                                        name="amount[IncubatorLinePledge]"
                                                        onChange={this.handleChangePledge}
                                                        placeholder="200"
                                                    />
                                                </>}
                                        </ElementWithError>
                                    </div>
                                    <IncubatorLineFormLineGame
                                        isEditable={isEditable}
                                        game_id={game_id}
                                        onSelectGame={controller.onSelectGame}
                                        setOptionsForHeartStone={this.setOptionsForHeartStone}
                                        esports={esports}
                                    />
                                </div>
                                <div className="field-cell">
                                    {isDesktop() && <IncubatorLineFormLineType
                                        lines_type_id={this.type_id}
                                        isEditable={isEditable || this.isLineTypeEditable(game_id)}
                                    />}
                                    {isEditable && <div className="field-item">
                                        <IncubatorLineFormChangeCoef
                                            controller={controller}
                                        />
                                    </div>}
                                    <IncubatorLineFormLineStream
                                        stream_link={stream_link}
                                        isEditable={isEditable}
                                        esports={esports}
                                    />
                                </div>
                            </div>
                            {isEditable
                                ? <>
                                    <div className="incubator-line-details">
                                        <IncubatorLineLeft/>
                                        <IncubatorLineMiddle/>
                                        <IncubatorLineRight/>
                                    </div>
                                    {!isDesktop() && <IncubatorLineTimer
                                        lineClosed={lineClosed}
                                        date={date}
                                    />}
                                    <IncubatorLineFormPanel/>
                                </>
                                : <IncubatorLineNewForm
                                    lines_type_id={this.type_id}
                                    isLineTypeEditable={this.isLineTypeEditable}
                                    params={params}
                                    esports={esports}
                                />
                            }
                        </div>
                    </form>
                </div>}
            </div>
            {this.lineEditableAndUserSignedIn() && <IncubatorMyLines/>}
        </>;
    }
}

export default withRouter(IncubatorLineForm);
