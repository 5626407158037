import React from 'react';
import {showPopup} from 'app/utils/modals/ModalsService';
import ArtifactPopup from '../artifacts/artifact_popup';
import ScrollPopup from 'app/components/my_profile/artifacts/ScrollPopup';
import pick from 'lodash/pick';

export const openPopupClickable = (
    artifact,
    useNow = false,
    options = {}
) => {
    if (!artifact) {
        return;
    }

    const {recipe, item_type} = artifact;
    const isScroll = () => item_type === 'scroll_artifact';

    showPopup('inventory-popup',
        isScroll()
            ? props => <ScrollPopup
                recipe={{
                    ...pick(artifact, [
                        'langs',
                        'name',
                        'description_langs',
                        'description',
                        'smiles',
                        'image'
                    ]),
                    ...pick(recipe, 'id')
                }}
                {...pick(artifact, [
                    'item_type',
                    'total_inventory_items',
                    'total_sending_prohibited_items',
                    'public_view',
                    'tradable',
                    'salable',
                    'transferable',
                    'sending_prohibited',
                    'user_received_period',
                    'useable',
                    'in_market',
                    'id'
                ])}
                popupId={props.popupId}
            />
            : props => <ArtifactPopup artifact={artifact} useNow={useNow} options={options} popupId={props.popupId}/>);
};
