import I18n from 'app/i18n';
import upperFirst from 'lodash/upperFirst';
import {fixed2int} from 'app/utils/fixed';

interface ShopItemInterface {
    cost_points: number,
    cost_money: number,
    currency: string
}

export const CURRENCY_USD = 'usd';

export const POINTS = 'points';

export const isUSD = (currency: string): boolean => currency === CURRENCY_USD;

export const shopItemPrice = (shopItem: ShopItemInterface, quantity = 1): string => {
    if (!shopItem) {
        return '0';
    }

    const {cost_points, cost_money, currency = CURRENCY_USD} = shopItem;
    const calcAmount = amount => amount * quantity;

    if (isUSD(currency)) {
        return `$${fixed2int(calcAmount(cost_money))}`;
    }

    return `${calcAmount(cost_points)} ${upperFirst(I18n.t('points', {count: calcAmount(cost_points)}))}`;
};

export const currencyItem = (currency: string) :string => currency ? currency : CURRENCY_USD;
