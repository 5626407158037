import React, {ReactNode, Component} from 'react';
import I18n from 'app/i18n';
import {inject, observer} from 'mobx-react';
import {DepositsStoreProps} from 'app/store/DepositsStore';
import SmartLoader from 'app/components/reusable/SmartLoader';

interface DepositSubmitProps extends DepositsStoreProps {
    onSubmit: () => void
}

@inject('depositsStore')
@observer
export default class DepositSubmit extends Component<DepositSubmitProps> {
    render(): ReactNode {
        const {onSubmit} = this.props;
        const {submitting} = this.props.depositsStore;
        return submitting
            ? <SmartLoader loaderClassName="btn form__row-submit smart-loader_theme-dark" />
            : <div className="form__row form__row-submit">
                <div className="form__row-item">
                    <button
                        type="submit"
                        className="btn"
                        onClick={onSubmit}
                    >
                        {I18n.t('user.make_deposit_label')}
                    </button>
                </div>
            </div>;
    }
}
