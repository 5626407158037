import React, {Component} from 'react';

export default class EmptyOffers extends Component {
    render() {
        return <div className="offer offer--empty">
            <div className="subtitle">{this.props.subtitle}</div>
            <p>{this.props.description}</p>
        </div>;
    }
}
