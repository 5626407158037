import {TableStore} from 'app/store/TableStore';
import {inject, observer} from 'mobx-react';
import React from 'react';
import PlayContentImpl from './index';
import PlayPage from '../play';
import {useParams} from 'react-router';
interface PlaySportLiveContentProps {
    tableStore?: TableStore
}

const PlaySportLiveContent = (props: PlaySportLiveContentProps): React.JSX.Element => {
    const params = useParams<{ filter: string }>();

    const {filter = ''} = params;
    const {tableStore} = props;
    return <PlayContentImpl
        filter={filter}
        urlPrefix="/play/sport_live/"
        keyPrefix="sport_live"
        esports={false}
        tableStore={tableStore}
        tableProps={{
            filter: PlayPage.sportLiveFilter,
            live: true
        }}
    />;
};

export default inject('tableStore')(observer(PlaySportLiveContent));
