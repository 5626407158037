import Row, {
    blankAmount,
    CancelButtonStates,
    ConfirmButtonStates, TimerStates
} from 'app/components/cashout/cashout_history/Row';
import React, {Fragment} from 'react';
import I18n from 'app/i18n';
import {localDateTimeFromISO} from 'app/utils';
import TimeToStartTimer from 'app/utils/TimeToStartTimer';
import SmartLoader from 'app/components/reusable/SmartLoader';

export default class RowMobile extends Row {
    render(): React.ReactNode {
        const {id, instant_amount, amount, created_at, state, cancellable,
            paid_at, submitting, cancellingCashoutId, bank_logo} = this.props;
        const isShowCancelButton = CancelButtonStates.includes(state) && cancellable;
        const isShowConfirmButton = ConfirmButtonStates.includes(state);
        const isShowTransferButton = state === 'waiting';

        return <Fragment>
            <tr>
                <td>
                    <span className="value-date">{localDateTimeFromISO(created_at)}</span>
                </td>
                <td>
                    {bank_logo && <span className="value-payment-system-img"><img src={bank_logo?.url}/></span>}
                    <p className="section">
                        <span className="value-payment-number">{id}</span>
                        <span className="value-payment-system">{this.renderSystem()}</span>
                        <span className="value-instant">
                            {instant_amount === '0.00' ? '' : `${blankAmount(instant_amount)},`}
                        </span>
                        <span className="value-status">{I18n.t(`user.status_${state}`)}</span>
                    </p>
                    {TimerStates.includes(state) && <p className="section"><span className="timer-waiting">
                        <TimeToStartTimer plainText time_to_start={paid_at}/>
                    </span></p>}
                </td>
                <td>
                    <span className="value-amount">{blankAmount(amount)}</span>
                </td>
            </tr>
            {(isShowCancelButton || isShowConfirmButton || isShowTransferButton) && <tr className="no-border">
                <td colSpan={3}>
                    <div className="value-button">
                        {isShowCancelButton && submitting && cancellingCashoutId === id
                            ? <SmartLoader />
                            : isShowCancelButton && <a
                                href="#"
                                className="button-adaptive button-adaptive_transparent"
                                onClick={this.handleCancelClick}
                            >
                                {I18n.t('user.cancel_label')}
                            </a>}

                        {isShowConfirmButton && <a
                            href="#"
                            className="button-adaptive button-adaptive_transparent"
                            onClick={this.handleConfirmClick}
                        >{I18n.t('confirm')}</a>}
                        {isShowTransferButton && <a
                            href="#"
                            className="button-adaptive button-adaptive_link"
                            onClick={this.handleTransferNotReceivedClick}
                        >{I18n.t('transfer_not_received')}</a>}
                    </div>
                </td>
            </tr>}
        </Fragment>;
    }
}
