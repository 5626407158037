import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {fixed3} from 'app/utils/fixed';
import {coefColorClass} from 'app/lines/coefColorClass';
import BetSlipController from 'app/components/bet_slip/BetSlipController';

interface BetSlipMinimizeProps {
    coef: number|string
    coefIncreased: boolean
    controller?: BetSlipController
    handleMaximize: () => void
    count: number
    single: boolean
    warning: boolean
}

export default class BetSlipMinimize extends PureComponent<BetSlipMinimizeProps> {
    render(): ReactNode {
        const {coef, coefIncreased, count, single, warning, handleMaximize} = this.props;
        return <div className="maximize-bet-slip">
            <div className="maximize-bet-slip-draggable draggable-coupon">
                <span className="number-bets">{count}</span>
                <span className="bet-placer">{I18n.t('bet_slip')}</span>
                <span className={`type-bet ${warning ? 'canceled-coefficient' : ''}${coefColorClass(coefIncreased)}`}>
                    {`${I18n.t(single ? 'single' : 'user_menu_express')}`} <b>{fixed3(coef)}</b>
                </span>
            </div>
            <button className="button-maximize" onClick={handleMaximize}>
                <svg className="svg-default">
                    <use xlinkHref="#svg-button-maximize"/>
                </svg>
            </button>
        </div>;
    }
}
