import React, {ReactNode} from 'react';
import {selectCard} from 'app/utils/poker/selectCard';

interface Props {
    cards: string[]
}

export default class PokerBoard extends React.Component<Props> {
    render(): ReactNode {
        const {cards} = this.props;

        return <div className="poker-board">
            {cards && cards.map(card => selectCard(card))}
        </div>;
    }
}
