import {hideModalWithFade} from 'app/utils/modals/ModalsService';
import React from 'react';
import {modalsStore} from 'app/store/global';
import {deleteObjectById, getObjectById, hasElementInObject, updateOrAddObject} from 'app/utils/updateArraysObject';
import CollectionStore from 'stores/CollectionStore';
import {action, makeObservable} from 'mobx';

interface ModalsInterface {
    id: string
    Component: React.FC<unknown>;
    visible?: boolean
    setFocus?: boolean
    cancelClick?(): void
    key: string
}

export class ModalsStore extends CollectionStore<ModalsInterface> {
    constructor() {
        super();
        makeObservable(this);
    }

    feedUrl = null;

    channel = null;

    @action
    isModalOpen(item_id: string): boolean {
        return hasElementInObject(this, 'collection', item_id);
    }

    @action
    hideModal(item_id: string): void {
        const item = {...getObjectById(this.collection, item_id), visible: false};
        updateOrAddObject(this, 'collection', item);
        hideModalWithFade(item_id);
    }

    @action
    deleteModal(item_id: string): void {
        deleteObjectById(this, 'collection', item_id);
    }

    @action
    showModal(item: ModalsInterface): void {
        updateOrAddObject(this, 'collection', item);
    }

    getById(item_id: string): ModalsInterface {
        return getObjectById(this.collection, item_id);
    }

    lastModal(): ModalsInterface {
        return this.collection[modalsStore.collection.length - 1];
    }
}
