import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {toastSuccess} from 'app/components/toasts/liteToast';

interface CopyButtonProps {
    value: string
}

export default class CopyButton extends PureComponent<CopyButtonProps> {
    showMessage = ():void => {
        toastSuccess(`${I18n.t('copied_data', {data: this.props.value})}`);
    };

    render(): ReactNode {
        const {value} = this.props;
        return <div className="copy-button">
            <svg className="icon">
                <use xlinkHref="#svg-copy-icon" fill="none"/>
            </svg>
            <CopyToClipboard text={value} onCopy={this.showMessage}>
                <span className="name">{I18n.t('copy')}</span>
            </CopyToClipboard>
        </div>;
    }
}
