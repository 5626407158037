import React, {PureComponent, Component} from 'react';
import isEqual from 'lodash/isEqual';
import Prize from './prize';
import {getDistinctRandomIndex} from 'app/utils/lootbox_open/random_index';
import {inject, observer} from 'mobx-react';

@inject('shopLootboxStore')
@observer
export default class LootboxItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            changePrizeAnimation: false,
            randomPrize: null
        };
        this.prizes = Array(this.props.loot.length);
    }

    componentDidUpdate() {
        const {lootboxOpeningProcess} = this.props;
        const {changePrizeAnimation} = this.state;

        if (lootboxOpeningProcess && !changePrizeAnimation) {
            this.setState({changePrizeAnimation: true});
            this.props.shopLootboxStore.updateAnimationStatus(false);
            this.changePrizeClass();
        }

        if (!lootboxOpeningProcess && changePrizeAnimation) {
            this.setState({changePrizeAnimation: false});
            this.props.shopLootboxStore.updateAnimationStatus(true);
        }
    }

    changePrizeClass = (oldIndex = 0) => {
        const elements = this.prizes;
        const elementOld = elements[oldIndex];
        const elementNewIndex = getDistinctRandomIndex(elements, oldIndex);

        if (this.props.shopLootboxStore.animationOpenEnd) {
            this.props.shopLootboxStore.updateAnimationStatus(false);
            if (elementOld) {
                elementOld.classList.remove('active');
            }
            return;
        }
        this.classNamesPrize(elementOld, elements[elementNewIndex]);

        setTimeout(() => this.changePrizeClass(elementNewIndex), 500);
    }

    classNamesPrize = (elementOld, elementNew) => {
        if (elementOld && elementNew) {
            elementOld.classList.remove('active');
            elementNew.classList.add('active');
        }
    }

    changePrizeArray = (element, key) => {
        if (element) {
            this.prizes[key] = element;
        }
    }

    render() {
        const {loot, animationOpenLootboxInvEnd, extra_data = []} = this.props;
        const {randomPrize} = this.state;

        return <div className="lootbox__slots hero__slot-wrap">
            {loot.map((loot, i) => {
                const options = {loot};
                return <PrizeWrapper
                    key={i}
                    {...options}
                    extra_data={extra_data}
                    animationOpenLootboxInvEnd={animationOpenLootboxInvEnd}
                    randomPrize={randomPrize}
                >
                    <Prize
                        {...options}
                        keyArray={i}
                        changePrizeArray={this.changePrizeArray}
                    />
                </PrizeWrapper>;
            })}
        </div>;
    }
}

export class PrizeWrapper extends PureComponent {
    static PRIZES_TO_SHOW_COUNT = 1;

    get classNames() {
        const classes = ['hero__slot'];

        this.prizeDroppedCount > 0 && classes.push('hero__slot--win');

        return classes.join(' ');
    }

    get prizeDroppedCount() {
        const {loot, extra_data, randomPrize, animationOpenLootboxInvEnd} = this.props;

        if (animationOpenLootboxInvEnd) {
            return extra_data
                .filter(prize => isEqual(loot, prize))
                .length;
        }

        return isEqual(randomPrize, loot) && 1 || 0;
    }

    get isPrizeShowable() {
        const {animationOpenLootboxInvEnd} = this.props;

        return animationOpenLootboxInvEnd && this.prizeDroppedCount > this.constructor.PRIZES_TO_SHOW_COUNT;
    }

    render() {
        return <div className={this.classNames}>
            {this.props.children}
            {this.isPrizeShowable && <div className="hero__slot--count">x{this.prizeDroppedCount}</div>}
        </div>;
    }
}
