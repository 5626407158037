import Checkbox from 'app/components/auth/Checkbox';
import React, {ChangeEvent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {modalConfirm} from 'app/utils/modals/popups';
import RequestPermissionButton from './request_permissions_button';
import DefaultRegistration from './DefaultRegistration';
import {tableStore, userStore} from 'app/store/global';
import Patch from 'app/utils/Patch';
import {toastError, toastSuccess} from 'app/components/toasts/liteToast';
import UserInterface from 'app/interfaces/UserInterface';

interface EgbUserInterface {
    [key: string]: string | boolean | number | FormDataEntryValue | {[key: string]: string | number | File };
}

interface UpdateProfileResponseInterface {
    status: boolean
    user: UserInterface
}

export default class ChangedProfileFields extends DefaultRegistration {
    passwordInput: React.RefObject<HTMLInputElement> = React.createRef();

    filterParams(egb_user: EgbUserInterface): EgbUserInterface {
        const result = {...egb_user};
        const unpermittedParams = [
            'deposit_limit_amount',
            'deposit_limit_period',
            'self_exclusion_period'
        ];
        unpermittedParams.forEach(prop => {
            delete result[prop];
        });
        return result;
    }

    saveHandler = (e: ChangeEvent<HTMLFormElement>): void => {
        const user: EgbUserInterface = Object.fromEntries(new FormData(e.target).entries());
        const {deposit_limit_amount, deposit_limit_period} = Object.fromEntries(new FormData(e.target).entries());
        user.deposit_limit_attributes = {
            amount: deposit_limit_amount,
            period: deposit_limit_period
        };

        if (this.state.submitting) {
            return;
        }
        this.setState({
            errors: {},
            submitting: true
        });
        if (this.changedEmail(user)) {
            this.askPassword(this.filterParams(user));
        } else {
            this.update(this.filterParams(user));
        }
        e && e.preventDefault();
    };

    changedEmail = (user: EgbUserInterface): boolean => this.props.email !== user.email;

    askPassword(egb_user: EgbUserInterface): void {
        modalConfirm({
            element: <form >
                <div>{I18n.t('enter_password')}</div>
                <div>
                    <input
                        ref={this.passwordInput}
                        type="password"
                        id="confirm_password"
                        name="confirm_password"
                        className="field__input"
                    />
                </div>
            </form>,
            react: true
        },
        () => this.update(egb_user, this.passwordInput.current.value),
        () => this.setState({submitting: false}),
        {setFocus: true});
    }


    updateSettings(data: UpdateProfileResponseInterface): void {
        window.settings.user.time_zone = data.user.time_zone;
        window.settings.user.notifications = data.user.notifications;
        userStore.update({user: data.user});
        this.reloadLines(data.user.light_mode);
        this.setState({
            deposit_limit: data.user.deposit_limit,
            deposit_limit_period: data.user.deposit_limit_period,
            submitting: false
        });
    }

    update(egb_user: EgbUserInterface, password = ''): void {
        const params = {
            confirm_password: password,
            egb_user,
            email: this.props.email
        };

        new Patch({
            params,
            url: '/egb_users'
        })
            .execute()
            .then(response => response.json())
            .then(data => {
                if (data.status) {
                    this.updateSettings(data);
                    toastSuccess(I18n.t('user.profile_has_changed_successfully'));
                    if (this.props.depositsStore) {
                        this.props.depositsStore.updateSystemsAndWallets(data);
                    }
                } else {
                    this.setState({submitting: false});
                    if (data.errors?.errors?.length > 0 || data.errors) {
                        this.errorHandler(data);
                    } else {
                        toastError(data.error);
                    }
                }
            }).finally(() => {
                this.setState({submitting: false});
            });
    }

    reloadLines = (light_mode: boolean): void => {
        if (!light_mode) {
            tableStore.reloadLines();
        }
    };

    fields = (): ReactNode[] => {
        const {
            day_of_birth: {day, month, year},
            chat_name_color_enabled,
            coupon_close,
            accept_any_coef,
            notifications,
            marketing_consent,
            light_mode,
            full_registered,
            confirmed,
            verified_status
        } = this.props;

        return [
            this.rowEmail(),
            this.rowPhone(),
            this.rowFirstName(),
            this.rowLastName(),
            this.rowCountryCode(),
            full_registered
                ? this.rowDayOfBirth(
                    {day, month, year}, confirmed && verified_status === 'verified'
                )
                : this.rowDayOfBirth(),
            this.rowTradeLink(),
            this.rowTimezone(),
            <Checkbox
                value={accept_any_coef} key="aac"
                name="accept_any_coef"
                label={I18n.t('accept_bet_when_coeff_changing')}
            />,
            <Checkbox
                value={coupon_close}
                key="coupon_close"
                name="coupon_close"
                label={I18n.t('betslip_close')}
            />,
            <Checkbox
                value={chat_name_color_enabled}
                key="chat_name_color_enabled"
                name="chat_name_color_enabled"
                label={I18n.t('chat_name_color_enabled')}
                questionMark
            />,
            <Checkbox value={notifications} key="nt" name="notifications" label={I18n.t('site_notifications')}/>,
            <RequestPermissionButton key="request-permission-button"/>,
            this.rowDepositLimits(),
            this.rowSelfExclusion(),
            <Checkbox value={marketing_consent} key="mc" name="marketing_consent" label={I18n.t('marketing_consent')}/>,
            <Checkbox value={light_mode} key="light_mode" name="light_mode" label={I18n.t('light_mode_description')}/>
        ];
    };

    render(): ReactNode {
        return <form ref={this.rootForm} onSubmit={this.saveHandler} className="form-settings popup-form--error">
            {this.fields()}
            <div className="popup-form__submit-row">
                {this.renderRegisterButton(I18n.t('user.save'))}
            </div>
        </form>;
    }
}
