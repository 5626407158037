import PaymentSystemInterface from 'app/interfaces/PaymentSystemInterface';
import React, {PureComponent} from 'react';
import {goToRoute} from 'app/utils/link_helper';
import SystemCard from 'app/components/deposit/SystemCard';
import {scrollToTop} from 'app/utils';

interface PropsInterface {
    active: boolean
    slug: string
    system: PaymentSystemInterface
}

export default class CashoutSystemTab extends PureComponent<PropsInterface> {
    selectSystem = (): void => {
        goToRoute(`/cashouts/${this.props.slug}`);
        scrollToTop();
    };

    render(): React.ReactNode {
        const {system, active} = this.props;
        return <div
            className={`field ${active ? '' : 'payment-systems__cashout__item__disabled'} ` +
            'payment-systems__cashout__item'}
            onClick={active ? this.selectSystem : null}
        >
            <div className="field__choose">
                <input type="radio" name="cashout" className="field__invisible"/>
                <div className="field__choose-item">
                    <div className="cashout__system-content">
                        <SystemCard system={system}/>
                    </div>
                </div>
            </div>
        </div>;
    }
}
