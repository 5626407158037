import React from 'react';

export default class TableEmptyState extends React.PureComponent {
    render() {
        const {text, buttonText, seo, buttonClick, isDisabled, loading} = this.props;
        const buttonClass = `btn${isDisabled ? ' btn-disabled' : ''}`;
        return this.props.arrayLength === 0 && !loading
            ? <div className="empty-table">
                {text ? <span className="empty-table__text">{text}</span> : null}
                {buttonText && !seo && <button disabled={isDisabled} className={buttonClass} onClick={buttonClick}>
                    {buttonText}
                </button>}
            </div>
            : null;
    }
}
