import {action, makeObservable, observable} from 'mobx';
import {NotificationSettingsType} from 'app/interfaces/stores/NotificationsSettingsStoreInterface';
import Patch from 'app/utils/Patch';

export class NotificationSettingsStore {
    @observable notificationSettings: NotificationSettingsType = {};

    constructor() {
        makeObservable(this);
    }

    @action init(data: NotificationSettingsType): void {
        this.update(data);
    }

    @action update(notificationSettings: NotificationSettingsType): void {
        if (typeof notificationSettings !== 'undefined') {
            if (this.notificationSettings) {
                Object.keys(notificationSettings).forEach(key => {
                    this.notificationSettings[key] = notificationSettings[key];
                });
            } else {
                this.notificationSettings = notificationSettings;
            }
        }
    }

    @action saveSettings(params: NotificationSettingsType): Promise<boolean | void> {
        return new Patch({
            params: {
                user_notification_setting: params
            },
            url: '/my_profile/notification_settings'
        })
            .execute()
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    this.init(params);
                }
                return data.success;
            });
    }
}
