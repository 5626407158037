import React, {PureComponent} from 'react';
import I18n from 'app/i18n';
import CurrentP2pDepositInterface from 'app/interfaces/CurrentP2pDepositInterface';

interface PropsInterface {
    deposit: CurrentP2pDepositInterface,
    fixP2pDeposit: (deposit: CurrentP2pDepositInterface) => void
}

export default class FixedButton extends PureComponent<PropsInterface> {
    handleClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
        e.preventDefault();
        const {deposit, fixP2pDeposit} = this.props;
        fixP2pDeposit(deposit);
    };

    render(): React.ReactNode {
        const {deposit: {not_valid}} = this.props;
        return <div className="value-button">
            {not_valid && <a
                onClick={this.handleClick}
                href="#"
                className={'button-adaptive button-adaptive_transparent'}
            >
                {I18n.t('i_fixed_mistake')}
            </a>}
        </div>;
    }
}
