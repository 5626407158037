import React, {PureComponent, ReactNode} from 'react';

interface Props {
    className?: string
    errorHint?: ReactNode;
    children?: React.ReactNode
}

export class ElementWithError extends PureComponent<Props> {
    render(): React.ReactNode {
        return <div className={`field-validation-container${this.props.className ? ` ${this.props.className}` : ''}`}>
            {this.props.children}
            <div className="select-validation">
                <span className="select-validation__icon">
                    <svg className="svg-default">
                        <use xlinkHref="#svg-icon-error"/>
                    </svg>
                </span>
                <span className="select-validation__text">{this.props.errorHint}</span>
            </div>
        </div>;
    }
}
