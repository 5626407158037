import React, {ReactElement, useCallback} from 'react';
import {openStream} from 'app/components/streams';
import StreamCreationPopup from 'app/components/streams/stream_creation_popup';
import I18n from 'app/i18n';
import {isDesktop} from 'app/utils';
import {isFlashScoreStream, isTwitchStream} from 'app/utils/stream/CheckPlatformStream';
import LineInterface from 'app/interfaces/LineInterface';
import {goToIncubatorLineEdit} from 'app/utils/app_links';

interface IconOpenStreamProps {
    incubator_stream: string
    className: string
    white: boolean
    esports?: boolean
    bet: LineInterface
    showMobilePencilEdit?: boolean
    profitMobile?: ReactElement
}

export const IconOpenStream = (props: IconOpenStreamProps): ReactElement => {
    const openStreamIncubator = useCallback((): void => {
        const result = props.incubator_stream.split('?')[0]
            .replace(StreamCreationPopup.channelPattern, '$1$2').toLowerCase();
        const isTwitch = isTwitchStream(props.incubator_stream);

        openStream({
            stream_url: isTwitch ? null : props.incubator_stream,
            target_blank: !isTwitch,
            title: result,
            twitch_channel: result
        }, openMinimize());
    }, [props, openStream]);

    const openMinimize = (): boolean => {
        const {pathname} = window.location;

        return (pathname.includes('/play/incubator') ||
            pathname.includes('/play/results')) && isDesktop() && props.white;
    };

    const svg_by_stream_platform = isFlashScoreStream(props.incubator_stream)
        ? <use xlinkHref="#svg-movie-flashscore"/>
        : <use xlinkHref="#svg-movie"/>;

    const handleEdit = (): void => {
        const {bet} = props;
        goToIncubatorLineEdit(bet.id);
    };

    return isDesktop()
        ? <div className={props.className}>
            <svg height="14px" width="19px" onClick={openStreamIncubator}>
                {svg_by_stream_platform}
            </svg>
        </div>
        : <div className="wrapper-watch-buttons-mobile">
            <span className="watch-button-mobile live" onClick={openStreamIncubator}>
                <svg className="svg-default">
                    {svg_by_stream_platform}
                </svg>
                <b>{I18n.t('stream.name')}</b>
            </span>
            {props.showMobilePencilEdit
                ? <span className="watch-button-mobile edit" onClick={handleEdit}
                    id="mobile_line_edit">
                    <svg className="svg-default">
                        <use xlinkHref="#svg-edit-pencil"/>
                    </svg>
                    <b>{I18n.t('incubator_edit')}</b>
                </span>
                : props.profitMobile}
        </div>;
};
