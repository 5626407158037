import React, {Component} from 'react';
import {artifactStore} from 'app/store/global';
import {SimpleArtifactImage} from 'app/components/my_profile/artifacts/image';
import {artifactName, artifactShopLink} from 'app/components/my_profile/artifacts/utils';
import I18n from 'app/i18n';
import {Link} from 'react-router-dom';

export default class ComponentRecipe extends Component {
    render() {
        const {recipe} = this.props;
        const missing = artifactStore.hasArtifact(recipe.id) === undefined;
        return <div className={`crafting__item--parts__item${ missing ? ' item-missing' : ''}`}>
            <div className="crafting__item--parts__item--img-wrap">
                <div className="crafting__item--parts__item--img">
                    <SimpleArtifactImage artifact={recipe}/>
                </div>
            </div>
            <div className="crafting__item--parts__item--title">
                <div className="crafting__item--parts__item--title-value">{artifactName(recipe)}</div>
                {missing && <Link
                    to={artifactShopLink(recipe)}>
                    {I18n.t('buy_shop_item')}
                </Link>}
            </div>
        </div>;
    }
}
