import firebase from 'firebase/app';
import 'firebase/messaging';
import {post} from 'app/utils/http_request_helper';

const getCsrfToken = () => self.csrf_token || document.head.querySelector('[name=csrf-token]').content;

const getFirebaseToken = () => firebase.messaging().getToken();

export const sendNotificationLog = (browser_notification_id, topic, event_type) => {
    getFirebaseToken().then(token => {
        if (!token) {
            return console.log(`Sending notification log topic: ${topic} event_type: ${event_type}`);
        }
        return post(`/devices/${token}/notification_log`, {
            browser_notification_id,
            event_type
        }, {'X-CSRF-Token': getCsrfToken()});
    });
};
