import LineInterface from 'app/interfaces/LineInterface';
import {extractNumericValue} from './extractNumeric';

export const sortTotals = (nestedBets: LineInterface[]): LineInterface[] => nestedBets.sort((a, b) => {
    if (a.type === 'NestedBet' && b.type === 'NestedBet') {
        const aValue = extractNumericValue(a);
        const bValue = extractNumericValue(b);
        return aValue - bValue;
    }
    return 0;
});
