import React, {Component, ReactNode, RefObject} from 'react';
import I18n from 'app/i18n';


interface RowNameTypeProps {
    verifiedStatus: string;
    name: string;
    nameType: string;
    inputAttrs: {[key: string]: string};
    rowClass: string;
    errorHint: ReactNode;
    cleanError: (field: string) => void;
    onChangeHandler: () => void;
    reference: RefObject<HTMLInputElement>;
}

export class RowNameType extends Component<RowNameTypeProps> {
    cleanError = (): void => {
        const {nameType, cleanError} = this.props;
        cleanError(nameType);
    };

    render(): ReactNode {
        const {verifiedStatus, name, nameType, rowClass, inputAttrs,
            onChangeHandler, errorHint, reference} = this.props;
        const verified = verifiedStatus === 'verified';
        return <div className={rowClass}>
            <label className="field-label" htmlFor={`field-${nameType}`}>{I18n.t(nameType)}</label>
            <div className="field">
                <div className="field__wrap">
                    <input
                        {...inputAttrs}
                        ref={reference}
                        id={`field-${nameType}`}
                        className={`field__input${verified ? ' field__input--exist-state' : ''}`}
                        name={`${nameType}`}
                        type="text"
                        defaultValue={name}
                        onKeyUp={this.cleanError}
                        onChange={onChangeHandler}
                    />
                    {verified &&
                        <span className="field__state" title={I18n.t('user.verified_account')}>
                            <svg height="12px" width="12px"><use xlinkHref="#svg-check"/></svg>
                        </span>
                    }
                </div>
            </div>
            {errorHint}
        </div>;
    }
}
