import React, {ReactNode} from 'react';
import DepositSystemForm from 'app/components/deposit/form/DepositSystemForm';
import DepositWallets from 'app/components/deposit/form/DepositWallets';
import DepositSystem from 'app/components/deposit/form/DepositSystem';
import DepositForm from 'app/components/deposit/form/DepositForm';

export default class DepositRedirectSystemClass extends DepositForm {
    onSubmit = (): void => {
        const {route_path} = this.props.depositsStore.currentPaymentSystem;
        this.submitRedirect(`/deposits/${route_path}`, this.preparedPath());
    };

    preparedPath = (): string => {
        const {route_path} = this.props.depositsStore.currentPaymentSystem;
        return `/deposits/${route_path}/prepared`;
    };

    render(): ReactNode {
        const {wallets_enabled} = this.props.depositsStore.currentPaymentSystem;
        return <DepositSystem>
            {wallets_enabled && <DepositWallets/>}
            <DepositSystemForm onSubmit={this.onSubmit}>
                {null}
            </DepositSystemForm>
        </DepositSystem>;
    }
}
