import React from 'react';
import I18n from 'app/i18n';

import {goToRoute} from 'app/utils';
import {addGaEvent} from 'app/ga_methods';

export class TimerPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {seconds: this.props.seconds || 5};
        this.timerHandler = this.timerHandler.bind(this);
    }
    componentDidMount() {
        setTimeout(this.timerHandler, 1000);
    }
    timerHandler() {
        if (this.state.seconds !== 1) {
            this.setState({seconds: this.state.seconds - 1});
            setTimeout(this.timerHandler, 1000);
        } else {
            goToRoute('/');
        }
    }
    render() {
        return <h3 className="title center">
            <p>{this.props.message}</p>
            <p>{I18n.t('your_will_be_redirected', {seconds: this.state.seconds})}</p>
        </h3>;
    }
}

export class SuccessfulRegistration extends React.PureComponent {
    componentDidMount() {
        addGaEvent('Sign up', 'confirmed');
    }
    render() {
        return <TimerPage message={I18n.t('account_has_been_successfully_activated')}/>;
    }
}
