import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import {artifactDescription, artifactShopLink} from 'app/components/my_profile/artifacts/utils';
import {SimpleArtifactImage} from 'app/components/my_profile/artifacts/image';

export default class ArtifactImage extends PureComponent {
    render() {
        const {recipe} = this.props;

        return <Link
            className="compendium-leaders-table-icon-wrap"
            to={artifactShopLink(recipe)}
        >
            <span className="compendium-leaders-table-icon">
                <SimpleArtifactImage
                    artifact={recipe}
                    imgAttributes={{
                        height: '18',
                        title: artifactDescription(recipe)
                    }}
                />
            </span>
        </Link>;
    }
}
