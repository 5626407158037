import React, {PureComponent} from 'react';
import Progress from './progress';
import I18n from 'app/i18n';
import {getPromoLinkPath, isGuest} from 'app/utils';
import {Link} from 'react-router-dom';

export default class FinishedComponent extends PureComponent {
    get isRenderable() {
        const {finished} = this.props;

        return finished;
    }

    render() {
        if (!this.isRenderable) {
            return null;
        }

        return <div className="compendium-aside__content">
            <div className="compendium-aside__row compendium-aside__row--with-table">
                <div className="compendium-aside__centered">
                    <Progress
                        {...this.props}
                        styleTop={0}
                    />
                    <div className="compendium-aside__text">
                        {I18n.t('compendium.has_finished')}
                    </div>
                    <Link
                        className="btn btn--invert"
                        to={getPromoLinkPath('/rating')}
                    >
                        {I18n.t('compendium.results_table')}
                    </Link>

                    <Links/>
                </div>
            </div>
        </div>;
    }
}

class Links extends PureComponent {
    render() {
        if (isGuest()) {
            return null;
        }

        return <div className="compendium-aside__links">
            <Link to={getPromoLinkPath('/my_predictions')}>
                {I18n.t('user.promo_action.compendium.my_predictions_title')}
            </Link>
        </div>;
    }
}
