import React, {PureComponent, ReactNode} from 'react';
import I18n from 'app/i18n';
import {getLinkPath} from 'app/utils';
import {Link} from 'react-router-dom';
import ArticleInterface from 'app/interfaces/articles/ArticleInterface';

interface ArticleWithTypeProps {
    data: ArticleInterface
    type?: string
}

export default class SmartPromotionItem extends PureComponent<ArticleWithTypeProps> {
    getLabelByLocale = (column = 'label'): string => {
        const {data} = this.props;
        return data[`promo_${column}_langs`] &&
            data[`promo_${column}_langs`][I18n.locale] ||
            data[`promo_${column}`] || data[column];
    };

    render(): ReactNode {
        const {main_image, title, slug} = this.props.data;
        return <div className="smart-promotion-item">
            <span className="smart-promotion-item__image">
                <img src={main_image} alt={title} className="smart-promotion-item-image"/>
            </span>
            <div className="smart-promotion-item__description">
                <span
                    className="smart-promotion-item-title"
                    dangerouslySetInnerHTML={{__html: this.getLabelByLocale('title')}}
                />
                <Link to={getLinkPath(`/${this.props.type || 'promotions'}/${slug}`)}
                    className="button-adaptive button-adaptive_instyle"
                >
                    {I18n.t('read_more_u')}
                </Link>
            </div>
        </div>;
    }
}
