import $ from 'jquery';
import React, {Component} from 'react';
import {LoginSignUpButtons} from './log_in_sign_up_button';
import {UserMenu} from '../user/menu';
import ResetPassword from './reset_password';
import {isGuest, userSignedIn, isDesktop} from 'app/utils';
import {showPopup} from 'app/utils/modals/ModalsService';
import {Auth, RegistrationPopup} from '../auth/auth_popup';
import MenuAlertPoint from 'app/components/MenuAlertPoint';
import {inject, observer} from 'mobx-react';
import {locationQuery} from 'app/utils/navigate/location';
import {useLocation} from 'react-router-dom';
import {getElement} from 'app/utils/getElement';
import {isElementVisible} from 'app/utils/isElementVisible';
import {UserStore} from 'app/store/UserStore';

export const openAuth = (redirectUrl?: string, selectedTab?: string): void => {
    showPopup('new-auth', props => <Auth redirectUrl={redirectUrl} selectedTab={selectedTab} popupId={props.popupId}/>);
};

const ELEMENT_HEIGHT = 55;

export const openRegistration = (usingOnTab?: string, redirectUrl?: string, selectedTab?: string): void => {
    showPopup('new-auth',
        props => <RegistrationPopup
            popupId={props.popupId}
            redirectUrl={redirectUrl}
            selectedTab={selectedTab}
            usingOnTab={usingOnTab}
        />);
};

export const showAuthForm = (): void => {
    const location = useLocation();

    const hasQueryParam = key => ({...locationQuery(location)}.hasOwnProperty(key));
    const ELEMENT_NOT_FOUND = -1;

    if (location.hash.indexOf('#auth') !== ELEMENT_NOT_FOUND || hasQueryParam('sign_in')) {
        if (isGuest()) {
            openAuth();
        }
        return;
    }

    if (hasQueryParam('sign_up')) {
        if (isGuest()) {
            openRegistration('BET_POP_UP');
        }
        return;
    }

    const resetPasswordToken = $.getUrlVar('reset_password_token');

    if (resetPasswordToken) {
        showPopup('reset-password', props => <ResetPassword token={resetPasswordToken} popupId={props.popupId}/>);
    }
};


interface AuthMenuProps {
    userStore?: UserStore;
    link?: string;
}

@inject('userStore')
@observer
export class AuthMenu extends Component<AuthMenuProps> {
    opener: React.RefObject<HTMLAnchorElement> = React.createRef();

    container: React.RefObject<HTMLDialogElement> = React.createRef();


    componentDidMount(): void {
        window.addEventListener('click', this.onClick);
    }

    componentWillUnmount(): void {
        window.removeEventListener('click', this.onClick);
    }

    onClick = (e: Event): void => {
        const element = getElement('.user-menu') as HTMLElement;
        const target = e.target as HTMLElement;

        if (target.closest('.user__row.cursor-pointer') === null &&
                isElementVisible(element) && target.id !== 'prevent-close') {
            this.onClose();
        }
    };

    onClose = (): void => {
        $(this.opener.current).removeClass('js-opener-active');
        $(this.container.current).slideUp();
    };

    onOpen = (): void => {
        $(this.opener.current).toggleClass('js-opener-active');
        $(this.container.current).slideToggle();
        $('.user-menu').css('max-height', `${$(window).height() - ELEMENT_HEIGHT}px`);
    };

    render(): React.ReactNode {
        const {name} = this.props.userStore.user;
        return userSignedIn()
            ? <div>
                {isDesktop() &&
                    <div className="user">
                        <a ref={this.opener} className="user__row cursor-pointer" onClick={this.onOpen}>
                            <span className="user__name">{name}</span>
                            <span className="user__opener-icon">
                                <svg height="6px" width="8px">
                                    <use xlinkHref="#svg-arr-select-small"/>
                                </svg>
                            </span>
                            <MenuAlertPoint/>
                        </a>
                        <dialog ref={this.container} className="user-menu js-custom-scroll" onClose={this.onClose}>
                            <UserMenu/>
                        </dialog>
                    </div>}
            </div>
            : <LoginSignUpButtons usingOn="MAIN_PAGE" className="header__auth-links"/>;
    }
}
