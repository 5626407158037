import React, {ReactNode, PureComponent} from 'react';

interface Props{
    condition: boolean
    children?: React.ReactNode
    className: string
}

const EMDASH = 8212;

export default class DefaultSign extends PureComponent<Props> {
    render = (): ReactNode => {
        const {className, condition, children} = this.props;

        return <div className={className}>
            {condition ? children : String.fromCharCode(EMDASH)}
        </div>;
    };
}
