import React, {Component, ReactNode, RefObject} from 'react';
import I18n from 'app/i18n';

interface RowNameProps {
    rowClass: string;
    errorHint: ReactNode;
    cleanError: (field: string) => void;
    onChangeHandler: () => void;
    reference: RefObject<HTMLInputElement>;
}

export class RowName extends Component<RowNameProps> {
    cleanError = (): void => {
        this.props.cleanError('name');
    };

    render(): ReactNode {
        const {rowClass, errorHint, onChangeHandler, reference} = this.props;

        return <div className={rowClass}>
            <label className="field-label" htmlFor="field-login">{I18n.t('nickname')}</label>
            <div className="field">
                <input
                    ref={reference}
                    className="field__input"
                    id="field-login"
                    maxLength={32}
                    name="name"
                    onKeyUp={this.cleanError}
                    onChange={onChangeHandler}
                />
            </div>
            {errorHint}
        </div>;
    }
}
