import $ from 'jquery';
import {action, makeObservable, observable} from 'mobx';

export class SmileStore {
    @observable smiles = [];

    @observable recent_smiles = [];

    constructor() {
        makeObservable(this);
    }

    @action updateSmiles({smiles, recent_smiles}) {
        this.smiles = smiles;
        this.updateRecentSmiles(recent_smiles);
    }

    @action updateRecentSmiles(recent_smiles) {
        this.recent_smiles = recent_smiles;
    }

    loadRecentSmiles(successHandler) {
        $.ajax('/smiles/recent_smiles', {success: response => successHandler(response)});
    }

    loadSmiles() {
        $.ajax('/smiles', {success: response => this.updateSmiles(response)});
    }
}
