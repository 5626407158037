import {LOCALES} from 'app/consts/locales';
import {navigateGoTo} from './navigate/navigateGoTo';
import {AVAILABLE_LOCALES_LANGS} from './Language';

export const goToRoute = (pathname, params = {}, e) => {
    e && e.preventDefault();
    navigateGoTo(getLinkPath(pathname), params);
};

export const simpleGoToRoute = (pathname, params = {}, e) => {
    e && e.preventDefault();
    navigateGoTo(pathname, params);
};

export const getLinkPath = path => {
    const locale = window.CURRENT_LOCALE === window.DEFAULT_LOCALE ? '' : window.CURRENT_LOCALE;

    return `${locale && !hasLocaleLangPrefix(path) ? `/${locale}` : ''}${path}`;
};

export const getTableLinkPath = path => {
    const locale = window.CURRENT_LOCALE === window.DEFAULT_LOCALE ? '' : window.CURRENT_LOCALE;
    return `${locale ? `/${locale}` : ''}${path}${location.search}`;
};

export const getPath = url => {
    const parsedUrl = new URL(url);
    return parsedUrl.pathname + parsedUrl.search;
};

const hasLocaleLangPrefix = url => {
    if (typeof url !== 'string') {
        return false;
    }
    return AVAILABLE_LOCALES_LANGS.includes(url.split('/')[1]);
};

export const getPathWithoutLocale = url => getPath(url).replace(new RegExp(`/(${LOCALES.join('|')})`), '');

export const parameterize = str => str.trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9 -]/u, '')
    .replace(/\s/gu, '-');

export const getClassNameActiveLink = ({isActive}) => `tabs__link tabs__link--btn ${isActive ? 'active-link' : ''}`;
